<template>
	<div class="auth-card">
		<h1 class="auth-title">
			Login
		</h1>

		<b-form
			class="form-body"
			@submit.prevent="submit"
		>
			<notification />

			<b-form-group>
				<label
					class="sr-only"
					for="email"
				>Email Address</label>
				<b-form-input
					id="email"
					ref="email"
					v-model="email"
					:class="{ 'is-invalid': errors.has('email') || failed }"
					type="text"
					name="email"
					placeholder="Email Address"
					size="lg"
					@focus.native="() => focusField('email')"
				/>

				<b-form-invalid-feedback v-show="errors.has('email')">
					{{ errors.first("email") }}
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group>
				<label
					class="sr-only"
					for="password"
				>Password</label>
				<b-form-input
					id="password"
					v-model="password"
					:class="{ 'is-invalid': errors.has('password') || failed }"
					type="password"
					name="password"
					placeholder="Password"
					size="lg"
					@focus.native="() => focusField('password')"
				/>
				<b-form-invalid-feedback
					v-show="errors.has('password')"
					@keyup.enter="submit"
				>
					{{ errors.first("password") }}
				</b-form-invalid-feedback>
			</b-form-group>

			<b-row class="justify-content-md-center">
				<b-col
					md="12"
					class="text-center"
				>
					<div>
						<router-link
							:to="{ name: 'recover-password' }"
							exact
						>
							Forgot your password?
						</router-link>
					</div>
				</b-col>
			</b-row>
			<b-row class="justify-content-md-center pt-4">
				<b-col
					md="8"
					class="mb-4"
				>
					<button
						type="submit"
						class="btn btn-primary btn-block"
						:disabled="loading"
					>
						<b-spinner
							v-if="loading"
							small
							variant="light"
							type="grow"
							label="Spinning"
						/>
						<span v-else>Login</span>
					</button>
				</b-col>
			</b-row>
			<div>
				You don't have an account?
				<router-link
					:to="{ name: 'register' }"
					exact
					data-login="register"
					@click.native="trackRegisterLink"
				>
					Register
				</router-link>
			</div>
			<div v-if="WHITELABEL == 'supersonicz'">
				Go back
				<a href="https://www.wisebanq.com">
					Home
				</a>
			</div>
		</b-form>
		<!-- Temporary message -->
		<small
			v-if="WHITELABEL == 'verifo'"
			style="
                padding: 5px;
                border-radius: 5px;
                border: 1px solid #ccc;
                display: inline-block;
                margin-top: 15px;
                color: #555;
            "
		>
			We have noticed activity from fraudsters, who want to obtain private
			data from customers. We remind you to never use suspicious links in
			SMS or e-mail, not to disclose login details to your account and to
			contact {{ siteName }} in case of doubt.
		</small>
		<!-- Temporary message -->
		<vue-recaptcha
			id="recaptcha"
			ref="recaptcha"
			:sitekey="recapthaSitekey"
			:load-recaptcha-script="true"
			size="invisible"
			@verify="onVerify"
		/>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { VueRecaptcha }  from 'vue-recaptcha';
import config, { siteName } from '~/config';

export default {
	name: 'LoginForm',
	components: {
		VueRecaptcha,
	},
	data() {
		return {
			c: '',
			email: null,
			password: null,
			recapthaSitekey: config.recaptchaSitekey,
			recaptchaResponse: null,
			WHITELABEL,
			siteName
		};
	},
	computed: {
		...mapState('auth', {
			failed: state => state.status.login.failed,
			loading: state => state.status.login.loading || state.status.login.success
		}),
	},
	created() {
		this.attachFormFieldValidation();
	},
	mounted() {
		this.$nextTick(() => {
			this.$refs.email.focus();
			this.logout();
		});
	},
	methods: {
		...mapActions('auth', ['login', 'logout']),
		...mapActions('notifications', ['pushError', 'pushSuccess']),
		async submit() {
			const validatedEmail = await this.$validator.validate('email', this.email);
			const validatedPassword = await this.$validator.validate('password', this.password);
			if (validatedEmail && validatedPassword) {
				const id = this.email.split('@');
				const profileId = `${id[0]}.${id[1].split('.')[0]}`;
				this.$gtag.set({
					'user_id': profileId,
					'crm_id': profileId
				});
				this.$gtag.event('login_button');
				this.$refs.recaptcha.execute();
			}
		},
		async onVerify(recaptchaResponse) {
			const loginData = {
				clientId: 'frontend',
				username: this.email,
				password: this.password,
				recaptchaResponse
			};
			try {
				await this.login(loginData);
				this.$router.push({ name: 'confirm' });
				// this.pushSuccess({ title: 'Login successful' });
			} catch (error) {
				this.$refs.recaptcha.reset();
				this.pushError({ title: error });
			}
		},
		onExpired: function () {
			this.recaptchaResponse = null;
			this.$refs.recaptcha.reset();
		},
		attachFormFieldValidation() {
			this.$validator.attach({ name: 'email', rules: 'email|required' });
			this.$validator.attach({ name: 'password', rules: 'required' });
		},
		handleError(data) {
			if (data.error) {
				this.$store.dispatch('showErrorNotification', data.error);
			} else {
				this.$store.dispatch('showErrorNotification', { message: 'Something went wrong. Try again later' });
			}
		},
		trackRegisterLink() {
			this.$gtag.event('register_link');
		},
		focusField(name) {
			this.$gtag.event(`login_focus_${name}`);
		}
	},
};
</script>
