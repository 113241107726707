var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dashboard"
  }, [_c('div', {
    staticClass: "page-title-container"
  }, [_c('b-row', {
    staticClass: "justify-content-between align-items-center"
  }, [_c('b-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('h2', {
    staticClass: "username"
  }, [_vm._v(" Hi, " + _vm._s(_vm.setUsername(_vm.user)) + "! ")]), !_vm.log.lastlogLoaded ? _c('p', {
    staticClass: "last-activity"
  }, [_c('preloader-text')], 1) : _c('p', {
    staticClass: "last-activity"
  }, [_c('span', {
    staticClass: "mr-3"
  }, [_vm._v(" Last activity: " + _vm._s(_vm._f("timestampToLocalDateTime")(_vm.log.lastLogdateCreated)) + " from " + _vm._s(_vm.log.lastLog.ip) + " " + _vm._s(_vm.log.lastLog.browser))]), _c('router-link', {
    staticClass: "text-nowrap",
    attrs: {
      "to": {
        name: 'log'
      },
      "exact": ""
    }
  }, [_vm._v(" View all activity ")])], 1)]), _c('b-col', {
    staticClass: "ml-auto",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "logout d-none d-lg-block"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'login'
      },
      "exact": ""
    }
  }, [_c('ion-icon', {
    attrs: {
      "name": "log-out-outline"
    }
  })], 1)], 1)])], 1)], 1), _vm.successRegistration ? _c('div', [_c('b-alert', {
    staticClass: "text-center",
    attrs: {
      "show": "",
      "variant": "success"
    }
  }, [_vm._v(" Registration complete for "), _c('strong', [_vm._v(_vm._s(_vm.$store.state.authUser.email))]), _vm._v("! ")])], 1) : _vm._e(), _vm.WHITELABEL == 'verifo' ? _c('vueper-slides', {
    staticClass: "no-shadow slider",
    attrs: {
      "visible-slides": 2,
      "gap": 2,
      "autoplay": "",
      "dragging-distance": 200,
      "breakpoints": {
        1200: {
          fixedHeight: '200px'
        },
        768: {
          gap: 0,
          fixedHeight: '210px'
        },
        650: {
          fixedHeight: '220px'
        },
        520: {
          visibleSlides: 1,
          bullets: false
        }
      },
      "arrows": false,
      "fixed-height": "204px"
    }
  }, _vm._l(_vm.slides, function (slide, i) {
    return _c('vueper-slide', {
      key: i,
      "class": {
        'vueperslide--dark': slide.dark
      },
      attrs: {
        "image": slide.image,
        "title": slide.title,
        "content": slide.content,
        "link": slide.link,
        "target": slide.link && !slide.samePage && '_blank'
      },
      scopedSlots: _vm._u([{
        key: "content",
        fn: function fn() {
          return [_c('div', {
            staticClass: "vueperslide__content-wrapper"
          }, [slide["new"] ? _c('div', {
            staticClass: "vueperslide__new-badge"
          }, [_vm._v(" New ")]) : _vm._e(), _c('div', {
            staticClass: "vueperslide__title",
            domProps: {
              "innerHTML": _vm._s(slide.title)
            }
          }), slide.button && slide.action ? _c('div', {
            staticClass: "vueperslide__content"
          }, [slide.button ? _c('b-button', {
            attrs: {
              "size": "sm",
              "variant": slide.buttonVariant ? slide.buttonVariant : 'primary'
            },
            on: {
              "click": slide.action
            }
          }, [_vm._v(" " + _vm._s(slide.button) + " ")]) : _vm._e()], 1) : _vm._e()])];
        },
        proxy: true
      }], null, true)
    });
  }), 1) : _vm._e(), _c('NewsHomepage', {
    key: _vm.newsKey,
    attrs: {
      "extra-fields": [].concat(_vm.cardsNotifications, _vm.oddNotifications)
    }
  }), _c('verification-card'), _c('transactions', {
    attrs: {
      "short": ""
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }