var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "profile-password-change p-0",
    attrs: {
      "id": "profile-password-change"
    }
  }, [_vm._m(0), _c('b-card', {
    staticClass: "mb-4"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "6"
    }
  }, [_c('b-row', [_c('b-col', [_c('small', {
    staticClass: "mr-2 d-inline-block"
  }, [_vm._v(" Current user: ")]), _c('small', {
    staticClass: "user-wrap"
  }, [_c('span', [_vm._v(_vm._s(_vm.email))])])])], 1)], 1)], 1)], 1), _c('b-card', [_c('notification'), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('form', {
    attrs: {
      "autocomplete": "disable"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.updatePassword();
      }
    }
  }, [_c('fieldset', {
    attrs: {
      "disabled": _vm.submitted || _vm.success
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('b-row', [_c('b-col', [_c('form-error-field', {
    attrs: {
      "name": "oldPassword"
    }
  }, [_c('label', [_vm._v("Current password")]), _c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    "class": {
      'is-invalid': _vm.errors.has('oldPassword')
    },
    attrs: {
      "id": "current-password",
      "type": "password",
      "name": "oldPassword",
      "placeholder": "Current password",
      "data-vv-as": "Current password"
    },
    model: {
      value: _vm.currentPassword,
      callback: function callback($$v) {
        _vm.currentPassword = $$v;
      },
      expression: "currentPassword"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('form-error-field', {
    attrs: {
      "name": "newPassword"
    }
  }, [_c('label', [_vm._v("New password")]), _c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|verify_password|min:8',
      expression: "'required|verify_password|min:8'"
    }],
    ref: "newPassword",
    "class": {
      'is-invalid': _vm.errors.has('newPassword')
    },
    attrs: {
      "id": "newPassword",
      "type": "password",
      "name": "newPassword",
      "placeholder": "New password",
      "data-vv-as": "New password"
    },
    model: {
      value: _vm.newPassword,
      callback: function callback($$v) {
        _vm.newPassword = $$v;
      },
      expression: "newPassword"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('form-error-field', {
    attrs: {
      "name": "confirmNewPassword"
    }
  }, [_c('label', [_vm._v("Confirm new password")]), _c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|min:8|confirmed:newPassword',
      expression: "'required|min:8|confirmed:newPassword'"
    }],
    "class": {
      'is-invalid': _vm.errors.has('confirmNewPassword')
    },
    attrs: {
      "id": "confirm-new-password",
      "type": "password",
      "name": "confirmNewPassword",
      "data-vv-as": "Confirm new password",
      "placeholder": "Confirm new password"
    },
    model: {
      value: _vm.confirmNewPassword,
      callback: function callback($$v) {
        _vm.confirmNewPassword = $$v;
      },
      expression: "confirmNewPassword"
    }
  })], 1)], 1)], 1)], 1)], 1), !(_vm.submitted || _vm.success) ? _c('b-row', {
    staticClass: "form-group mt-4"
  }, [_c('b-col', [_c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "primary",
      "type": "submit"
    }
  }, [_vm._v(" Submit ")]), _c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push('/home');
      }
    }
  }, [_vm._v(" Cancel ")])], 1)], 1) : _vm._e()], 1)])]), _vm.submitted && !_vm.success ? _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('div', {
    staticClass: "password-change-confirm"
  }, [_c('div', {
    staticClass: "page-title-container text-center"
  }, [_c('h2', [_vm._v("Confirm password change")])]), _c('div', {
    staticClass: "text-center"
  }, [_c('authenticator', {
    attrs: {
      "show-back-button": true,
      "on-back": _vm.handleBack,
      "on-submit": _vm.handleCode,
      "type": "change_password"
    }
  })], 1)])]) : _vm._e()], 1)], 1), _vm.success ? _c('div', {
    staticClass: "step3"
  }, [_c('div', {
    staticClass: "page-title-container"
  }, [_c('div', {
    staticClass: "password-change__success"
  }, [_c('span', [_vm._v("Password changed successfully")]), _c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'home'
        });
      }
    }
  }, [_vm._v(" Back ")])], 1)])]) : _vm._e()], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-title-container"
  }, [_c('h2', [_vm._v("Change your password")])]);
}]

export { render, staticRenderFns }