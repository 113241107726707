var render = function () {
  var _vm$item$paymentMetho, _vm$item$paymentMetho2, _vm$item$paymentMetho3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "transaction-item-container",
    "class": _vm.showCollapse ? 'expanded' : ''
  }, [_c('div', {
    staticClass: "transaction-item",
    "class": _vm.currentItem ? 'transaction-item--selected' : '',
    on: {
      "click": function click($event) {
        return _vm.selectItem(_vm.item);
      }
    }
  }, [_c('b-row', {
    staticClass: "align-items-center",
    attrs: {
      "align-h": "between"
    }
  }, [_c('b-col', [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "avatar d-flex align-items-center justify-content-center"
  }, [!['declined', 'failed', 'refund'].includes(_vm.item.status) ? _c('img', {
    attrs: {
      "src": "images/img/income.svg"
    }
  }) : _vm._e(), ['declined', 'failed'].includes(_vm.item.status) ? _c('ion-icon', {
    staticStyle: {
      "color": "red"
    },
    attrs: {
      "name": "close-outline"
    }
  }) : _vm._e(), ['refund'].includes(_vm.item.status) ? _c('ion-icon', {
    attrs: {
      "name": "refresh-outline"
    }
  }) : _vm._e()], 1), _c('div', [_c('h4', [_vm._v(" " + _vm._s(_vm.item.id) + " ")]), _c('div', {
    staticClass: "date"
  }, [_vm._v(" " + _vm._s(_vm._f("timestampToLocalDateTime")(_vm.item.dateCreated)) + " ")])])])]), _c('b-col', {
    staticClass: "d-none d-xl-block"
  }, [_c('div', {
    staticClass: "summary"
  }, [_vm._v(" " + _vm._s((_vm$item$paymentMetho = _vm.item.paymentMethodType) === null || _vm$item$paymentMetho === void 0 ? void 0 : _vm$item$paymentMetho.name) + " " + _vm._s((_vm$item$paymentMetho2 = _vm.item.paymentMethodType) !== null && _vm$item$paymentMetho2 !== void 0 && _vm$item$paymentMetho2.countryId ? _vm.item.paymentMethodType.countryId.toUpperCase() : '') + " " + _vm._s((_vm$item$paymentMetho3 = _vm.item.paymentMethodType) !== null && _vm$item$paymentMetho3 !== void 0 && _vm$item$paymentMetho3.name ? '/' : '') + " " + _vm._s(_vm.item.details) + " "), _vm.item.remoteIdentifier ? _c('span', [_vm._v(" / "), _c('b', [_vm._v(" " + _vm._s(_vm.item.remoteIdentifier) + " ")])]) : _vm._e()])]), _c('b-col', {
    staticClass: "text-right"
  }, [_vm.item.status === 'completed' ? _c('div', {
    staticClass: "greenStatus amount"
  }, [_vm._v(" + " + _vm._s(_vm._f("normalizeAmount")(_vm.item.amount)) + " " + _vm._s(_vm.item.currencyId.toUpperCase()) + " ")]) : _c('div', {
    staticClass: "amount",
    "class": {
      'amount--failed': ['declined', 'failed'].includes(_vm.item.status)
    }
  }, [_vm._v(" " + _vm._s(_vm._f("normalizeAmount")(_vm.item.amount)) + " " + _vm._s(_vm.item.currencyId.toUpperCase()) + " ")])])], 1), _vm._m(0)], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "current-item-arrow d-none d-xl-flex"
  }, [_c('i', {
    staticClass: "fas fa-arrow-right"
  })]);
}]

export { render, staticRenderFns }