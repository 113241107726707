export default {
	currentRouteName: (state) => {
		if (!state.current) return null;
		return state.current.name;
	},
	previousRouteName: (state) => {
		if (state.history.length > 0) {
			return state.history[0].name;
		}
	}
};