<template>
	<form 
		v-show="initialized" 
		method="post" 
		class="form-body" 
		@submit.prevent="onSubmit"
	>
		<div class="auth-card">
			<h1 class="auth-title">
				Recover account password
			</h1>

			<div class="form-group">
				<label class="w-100 text-left">New password:</label>
				<password-input 
					id="password" 
					v-model="password" 
					validation-rules="verify_password|min:8"
					:req="true"
					name="password"
					input-ref="password"
					@validated="validated"
				/>
			</div>
			<div class="form-group">
				<label class="w-100 text-left">Repeat new password:</label>
				<password-input 
					id="confirmPassword" 
					v-model="confirmPassword" 
					validation-rules="min:8|confirmed:password" 
					:req="false"
					name="confirmPassword"
					@validated="confirmValidated"
				/>
			</div>
			<div class="form-group">
				Only complicated password which is at least 8 characters and contains no less than 1 capital letter and
				1 digit

				<notification />
			</div>
			<div v-if="formValid"
				class="row justify-content-center pt-4"
			>
				<div class="col-md-8">
					<input 
						type="submit" 
						class="btn btn-primary btn-block" 
						value="Next"
					/>
				</div>
			</div>
		</div>
	</form>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
	name: 'RecoverChangePassword',
	userFrom: null,
	props: {
		hash: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			password: '',
			confirmPassword: '',
			valid: {
				password: false,
				confirm: false
			}
		};
	},
	computed: {
		...mapState('passwordRecovery', {
			initialized: state => state.status.initialized
		}),
		formValid() {
			return this.valid.password && this.valid.confirm && this.password == this.confirmPassword;
		}
	},
	methods: {
		...mapActions('passwordRecovery', ['recoverPasswordVerify', 'recoverPasswordChange']),
		...mapActions('notifications', ['pushError']),
		hashValid() {
			this.showForm = 1;
		},
		validated(value) {
			return this.valid.password = value;
		},
		confirmValidated(value) {
			return this.valid.confirm = value;
		},
		async onSubmit() {
			try {
				const result = await this.$validator.validateAll();
				if (result && this.confirmPassword === this.password) {
					var payload = {
						'password': this.password,
						'confirmPassword': this.confirmPassword
					};
					await this.recoverPasswordChange({ ...payload, hash: this.hash });
					this.$router.push({ name: 'recover-password-finished' });
				}
			} catch (err) {
				this.pushError('Please check the fields');
			}
		},
	},
	async mounted() {
		try {
			await this.recoverPasswordVerify(this.hash);
			this.showForm = 1;
		} catch (err) {
			this.$router.push({ name: 'recover-password' });
			this.pushError('Link is not valid anymore');
		}
	},
	provide() {
		return {
			$refs: this.$refs
		};
	}
};
</script>
