<template>
	<div class="card-jwk-form">
		<h2>Create PIN code</h2>
		<img
			src="/images/icons/card-pin.svg"
			alt="Card PIN"
		>
		<p class="mt-4 mb-4">
			Create a PIN code for your card.<br />Make sure to keep it secure.
		</p>
		<label class="card-input-label">
			<span v-if="!showRepeat">Create PIN</span>
			<span v-if="showRepeat">Repeat PIN</span>
			<input 
				v-if="!showRepeat"
				ref="pin"
				v-model="data.pin"
				v-validate="'min:4|required'"
				type="password"
				name="pin"
				pattern="\d*"
				maxlength="4"
				data-vv-validate-on="blur"
				placeholder="Enter 4-digit PIN"
				:class="errors.has('pin') ? 'card-input-field-error' : ''"
			>
			<div
				v-if="!showRepeat && errors.has('pin')"
				class="card-input-error"
			>
				{{ errors.first('pin') }}
			</div>

			<input 
				v-if="showRepeat"
				ref="repeat"
				v-model="repeat"
				v-validate="'min:4|required'"
				type="password"
				name="repeat"
				pattern="\d*"
				maxlength="4"
				data-vv-validate-on="blur"
				placeholder="Repeat 4-digit PIN"
				:class="errors.has('repeat') ? 'card-input-field-error' : ''"
			>
			<div
				v-if="showRepeat && errors.has('repeat')"
				class="card-input-error"
			>
				{{ errors.first('repeat') }}
			</div>
		</label>
	</div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
	name: 'CardSetPinForm',
	data() {
		return {
			data: {
				pin: null,
			},
			repeat: null
		};
	},
	computed: {
		showRepeat() {
			return this.data.pin && this.data.pin.length == 4;
		}
	},
	watch: {
		repeat: {
			handler: function () {
				if (this.repeat) this.repeat = this.repeat.replace(/[^0-9]/g, '');
				if (this.repeat == this.data.pin) {
					this.$emit('input', this.data);
				} else if (this.repeat && this.repeat.length == 4) {
					this.data.pin = null;
					this.repeat = null;
					this.pushError('PIN code did not match. Try again.');
				} else {
					this.$emit('input', null);
				}
			}
		},
		'data.pin': {
			handler: function() {
				if (this.data.pin) this.data.pin = this.data.pin.replace(/[^0-9]/g, '');
				if (this.data.pin && this.data.pin.length == 4) {
					this.$nextTick(() => {
						this.$refs.repeat.focus();
					});
				}
				if (!this.data.pin) {
					this.$nextTick(() => {
						this.$refs.pin.focus();
					});
				}
			}
		}
	},
	methods: {
		...mapActions('notifications', ['pushError']),
		focus() {
			this.$nextTick(() => {
				this.$refs.pin.focus();
			});
		}
	}
};
</script>
<style lang="scss">
	
</style>