<template v-cloak>
	<div>
		<div class="search-block transactions-search">
			<b-row>
				<b-col
					sm="6"
					md="12"
					lg="12"
					xl="5"
					class="mb-4 mb-sm-0 mb-md-4 mb-xl-0"
				>
					<div class="form-group mb-0">
						<custom-select
							:items="statuses"
							:hide-front="false"
							:modify-bg="true"
							@select="filterStatuses($event)"
						/>
					</div>
				</b-col>
				<b-col 
					sm="6" 
					md="9" 
					lg="8" 
					xl="4"
				>
					<date-range-select 
						:date-range="dateRange" 
						@dateRangeChanged="dateRangeChanged"
					/>
				</b-col>
				<b-col 
					cols="3" 
					lg="4"
					xl="3"
					class="d-none d-md-block"
				>
					<div class="d-flex align-items-center">
						<p class="mr-3 mb-0 text-muted align-self-center text-nowrap items-per-page-label">
							Items <br> per page
						</p>
						<custom-select
							:items="pages"
							:hide-front="false"
							:modify-bg="true"
							@select="filterPages($event)"
						/>
					</div>
				</b-col>
			</b-row>
		</div>
		<div
			v-if="total"
			class="transactions"
		>
			<div class="transactions-list-wrapper">
				<div class="transactions-list">
					<product-list-item
						v-for="(tr, i) in list"
						:id="'id' + i"
						:key="tr.id"
						:item="tr"
						:selected="selectedItem"
						@select="item => selectItem(item)"
					/>
				</div>
			</div>

			<div
				v-show="limit * 1 < total"
			>
				<pagination
					:total="total"
					:per-page="limit"
					:current-page="currentPage"
					@pagechanged="onPageChange"
				/>
			</div>
		</div>

		<div
			v-else-if="!listLoaded"
			class="empty-list"
		>
			<preloader />
		</div>

		<div
			v-else
			class="empty-list"
		>
			<NoProducts />
		</div>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import debounce from 'lodash/debounce';
import ProductListItem from './ProductListItem';
import DateRangeSelect from '@/utils/DateRangeSelect';
import NoProducts from './NoProducts';
import Preloader from '@/utils/Preloader';
import { endpointsGateway, siteName } from '~/config';
import Pagination from '@/utils/Pagination';
import CustomSelect from '@/utils/CustomSelect';
import axios from 'axios';
import moment from 'moment';

export default {
	name: 'DashboardPaymentRequest',
	components: {
		ProductListItem,
		Preloader,
		DateRangeSelect,
		NoProducts,
		Pagination,
		CustomSelect,
	},
	data() {
		return {
			statuses: {
				empty: '--',
				created: 'Created',
				verified: 'Verified',
				rejected: 'Rejected',
				disabled: 'Disabled',
			},
			pages: {
				10: '10',
				20: '20',
				50: '50',
			},
			windowWidth: window.innerWidth,
			limit: '10',
			total: 0,
			allTransactions: false,
			currentPage: 1,
			selectedPage: 1,
			list: [],
			listLoaded: false,
			status: '',
			dateRange: {
				startDate: '',
				endDate: new Date()
			},
			selectedItem: null,
			siteName,
		};
	},
	computed: {
		...mapGetters('user', [
			'isVerified'
		]),
	},
	created() {
		this.debouncedGetTransactions = debounce(this.getProductList, 500);
	},
	mounted() {
		this.getProductList();
		this.allTransactions = window.location.pathname.replace('/', '') === 'products';
	},
	methods: {
		...mapActions('notifications', [
			'pushError'
		]),
		selectItem(item) {
			this.selectedItem = this.selectedItem && item && this.selectedItem.id == item.id ? null : item;
		},
		dateRangeChanged(dateRange) {
			let startDate = new Date(dateRange.startDate);
			let endDate = new Date(dateRange.endDate);

			this.dateRange.startDate = startDate.toISOString();
			this.dateRange.endDate = endDate.toISOString();
			this.getProductList();
		},
		filterPages(event) {
			this.limit = event;
			this.getProductList();
		},
		filterStatuses(event) {
			this.status = event;
			this.getProductList();
		},
		getPaymentRequestWithDebounce() {
			this.debouncedGetTransactions();
		},
		onPageChange(page) {
			this.currentPage = page;
			this.getProductList();
		},
		getProductList() {
			this.selectedItem = null;

			let url = endpointsGateway.product.page;

			let params = {
				dateCreatedFrom: this.dateRange.startDate
					? moment(this.dateRange.startDate).format('YYYY-MM-DD 00:00:00')
					: moment().subtract(10, 'years').format('YYYY-MM-DD 00:00:00'),
				dateCreatedTo: moment(this.dateRange.endDate).format('YYYY-MM-DD 23:59:59'),
				orderBy: 'dateCreated',
				page: this.currentPage,
				limit: this.limit,
				orderDirection: 'desc'
			};

			if (this.status.length && this.status !== 'empty') {
				params.status = this.status;
			}

			axios
				.get(url, {
					params: params
				})
				.then(response => {
					this.list = response.data.rows;
					this.total = response.data.count;
					this.listLoaded = true;
				})
				.catch((error) => {
					this.listLoaded = true;
					this.pushError(error.message);
				});
		}
	}
};
</script>

<style lang="scss" scoped>
	@import './Products.scss';
</style>