var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.initialized,
      expression: "initialized"
    }],
    staticClass: "form-body",
    attrs: {
      "method": "post"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSubmit.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "auth-card"
  }, [_c('h1', {
    staticClass: "auth-title"
  }, [_vm._v(" Recover account password ")]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    staticClass: "w-100 text-left"
  }, [_vm._v("New password:")]), _c('password-input', {
    attrs: {
      "id": "password",
      "validation-rules": "verify_password|min:8",
      "req": true,
      "name": "password",
      "input-ref": "password"
    },
    on: {
      "validated": _vm.validated
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  })], 1), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    staticClass: "w-100 text-left"
  }, [_vm._v("Repeat new password:")]), _c('password-input', {
    attrs: {
      "id": "confirmPassword",
      "validation-rules": "min:8|confirmed:password",
      "req": false,
      "name": "confirmPassword"
    },
    on: {
      "validated": _vm.confirmValidated
    },
    model: {
      value: _vm.confirmPassword,
      callback: function callback($$v) {
        _vm.confirmPassword = $$v;
      },
      expression: "confirmPassword"
    }
  })], 1), _c('div', {
    staticClass: "form-group"
  }, [_vm._v(" Only complicated password which is at least 8 characters and contains no less than 1 capital letter and 1 digit "), _c('notification')], 1), _vm.formValid ? _c('div', {
    staticClass: "row justify-content-center pt-4"
  }, [_vm._m(0)]) : _vm._e()])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-md-8"
  }, [_c('input', {
    staticClass: "btn btn-primary btn-block",
    attrs: {
      "type": "submit",
      "value": "Next"
    }
  })]);
}]

export { render, staticRenderFns }