<template>
	<div class="odd-form-footer py-2 d-flex flex-column justify-content-between">
		<b-row>
			<b-col class="mt-4 d-flex justify-content-between">
				<b-button v-if="!submitHidden"
					variant="primary"
					:disabled="submitDisabled"
					class="mr-2"
					@click="submit()"
				>
					{{ submitText }}
				</b-button>
				<b-button v-if="!cancelHidden"
					variant="outline-primary"
					@click="onCancel"
				>
					{{ cancelText }}
				</b-button>
			</b-col>
		</b-row>
	</div>
</template>
<script>
import { mapActions } from 'vuex';
import { handleApiFormErrors } from '~/helpers';

export default {
	name: 'OddFormFooter',
	inject: ['$validator'],
	props: {
		onSuccess: {
			type: Function,
			default: () => {},
			required: true
		},
		onFailure: {
			type: Function,
			default: () => {},
		},
		onCancel: {
			type: Function,
			default: () => {}
		},
		submitUrl: {
			type: String,
			default: '',
			required: true
		},
		formData: {
			type: Object,
			default: () => {},
			required: true
		},
		submitText: {
			type: String,
			default: 'Submit'
		},
		cancelText: {
			type: String,
			default: 'I will fill this later'
		},
		submitDisabled: {
			type: Boolean,
			default: false
		},
		submitHidden: {
			type: Boolean,
			default: false
		},
		cancelHidden: {
			type: Boolean,
			default: false
		},
		skipSubmit: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		...mapActions('notifications', [
			'pushError'
		]),
		validateChildren(component) {
			const validators = [];
			const children = [];

			function collectChildren(c) {
				if (c.$children?.length > 0) {
					children.push(...c.$children);
					c.$children.forEach(child => collectChildren(child));
				}
			}
			collectChildren(component);

			children.forEach(child => {
				const items = child.$validator._base.fields.items;
				const scopes = items.map(i => i.scope).reduce((a, b) => { if (!a.includes(b)) a.push(b); return a; }, []);
				if (scopes && scopes.length > 0) {
					scopes.forEach(scope => validators.push(child.$validator.validate(`${scope}.*`)));
				}
				validators.push(child.$validator.validateAll());});
			validators.push(this.$validator.validate());
			return validators;
		},
		async submit() {
			const validators = this.validateChildren(this.$parent);
			const validatorsResult = await Promise.all(validators);
			const valid = validatorsResult.reduce((a, b) => a && b, true);
			if (valid && !this.skipSubmit) {
				const options = {
					method: 'PUT',
					url: this.submitUrl,
					data: this.formData
				};
				try {
					await axios(options);
					this.onSuccess();
				} catch (err) {
					this.onFailure(err);
					this.$nextTick(() => {
						handleApiFormErrors(err, this.$validator.errors);
					});

				}
			} else if (valid && this.skipSubmit) {
				this.onSuccess();
			} else if (!valid && !this.skipSubmit) {
				this.pushError('Please check form and provide correct information.');
			}
		}
	}
};
</script>
<style lang="scss">
	
</style>