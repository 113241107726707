<template>
	<div class="authenticator">
		<form 
			class="form-body" 
			@submit.prevent="submit"
		>
			<div 
				v-if="error" 
				class="alert alert-danger" 
				@click="hideErrorNotification()"
			>
				<span>{{ error }}</span>
			</div>

			<b-form-group class="text-center">
				<label 
					v-if="title" 
					class="d-block" 
					for="code"
				>{{ title }}</label>
				<label 
					v-else 
					class="d-block" 
					for="code"
				>Code from {{ AuthenticatorHelper.name[currentAuthenticator] }}
					<span v-if="phoneNumberUsed && selectedProperties?.phoneNumber">
						sent to {{ selectedProperties.phoneNumber }}</span></label>
				<CodeInput
					ref="code"
					v-model="code"
					name="code"
					:numbers-only="phoneNumberUsed"
					:digits="6"
					:disabled="verifying"
				/>
				<!-- <SmscodeInput
					id="code"
					ref="code"
					v-model="code"
					:length="6"
					name="code"
					placeholder="0"
				/> -->
			</b-form-group>

			<template v-if="type">
				<p class="text-center text-muted">
					<template v-if="!resending">
						<div v-if="!showSelector">
							<span v-if="phoneNumberUsed">Did not get {{ AuthenticatorHelper.name[currentAuthenticator] }} with confirmation code?</span>
							<span v-if="!phoneNumberUsed">Code did not work?</span>
							<b-link
								v-if="!hideResend"
								class="ml-1"
								@click="Resend"
							>
								<span v-if="phoneNumberUsed">Resend</span>
								<span v-if="!phoneNumberUsed">Refresh</span>
							</b-link>
							<b-link 
								v-if="hideResend"
								target="_blank"
								:href="`mailto:${supportEmail}`"
								class="ml-1"
							>
								<span>Contact support</span>
							</b-link>
						</div>
						<div v-if="showSelector">
							<b-spinner v-if="loading" />
							<authenticator-selector v-if="!loading"
								:list="authenticatorList"
								@onSelect="selectResendMethod"
							/>
						</div>
					</template>
					<preloader 
						v-else 
						title="Sending..."
					/>
				</p>
			</template>

			<div 
				v-show="fields.code && fields.code.dirty && errors.has('code')" 
				class="errormsg"
			>
				<span>{{ errors.first('code') }}</span>
			</div>


			<b-row v-if="showRecaptcha">
				<b-col>
					<vue-recaptcha
						id="recap"
						ref="recaptcha"
						:sitekey="sitekey"
						size="invisible"
						@verify="onRecaptchaVerify"
					/>
				</b-col>
			</b-row>
			<b-row class="justify-content-md-center">
				<b-col 
					md="8" 
					class="text-center mt-3 mt-sm-4"
				>
					<button 
						v-if="action" 
						:disabled="verifying" 
						type="submit" 
						class="btn btn-primary btn-block"
					>
						<b-spinner 
							v-if="loading" 
							small 
							variant="light" 
							type="grow" 
							label="Spinning"
						/>
						<span v-else>{{ buttonText }}</span>
					</button>
					<div 
						v-if="showBackButton" 
						class="mt-2"
					>
						or <a
							href="#"
							@click.prevent="handleBack()"
						>Go back</a>
					</div>
				</b-col>
			</b-row>
		</form>
	</div>
</template>

<script>
import Preloader from '@/utils/Preloader';
import CodeInput from './CodeInput';
import { mapActions, mapGetters } from 'vuex';
import { supportEmail } from '~/config';
import AuthenticatorHelper from '~/helpers/authenticator.helper';
import AuthenticatorSelector from './AuthenticatorSelector';

export default {
	name: 'Authenticator',
	components: {
		Preloader,
		CodeInput,
		AuthenticatorSelector
	},
	props: {
		onSuccess: {
			type: Function,
			default: () => {}
		},
		onFailure: {
			type: Function,
			default: () => {}
		},
		onBack: {
			type: Function,
			default: () => {
				this.$router.go(-1);
			}
		},
		onSubmit: {
			type: Function,
			default: () => {}
		},
		onResend: {
			type: Function,
			default: null
		},
		showBackButton: {
			type: Boolean,
			default: false
		},
		buttonText: {
			type: String,
			default: 'Authenticate'
		},
		data: {
			type: Object,
			default: () => {
				return {};
			}
		},
		resendData: {
			type: Object,
			default: () => {
				return {};
			}
		},
		action: {
			type: String,
			default: '',
			required: false
		},
		type: {
			type: String,
			default: ''
		},
		hash: {
			type: String,
			default: ''
		},
		title: {
			type: String,
			default: null
		},
		showRecaptcha: {
			type: Boolean,
			default: false
		},
		recaptchaResponse: {
			type: String,
			default: ''
		},
		hideResend: {
			type: Boolean,
			default: false
		}
	},
	data: function () {
		return {
			error: '',
			code: '',
			loading: false,
			resending: false,
			verifying: false,
			supportEmail,
			AuthenticatorHelper,
			showSelector: false,
			resendProperties: null,
			typeLoading: false,
		};
	},
	computed: {
		...mapGetters('user', {
			authenticator: 'authenticatorType',
			authenticatorList: 'authenticatorList'
		}),
		authenticatorProperties: (vm) => vm.authenticatorList.find(v => v.authenticator == vm.authenticator),
		selectedProperties() {
			return this.resendProperties || this.authenticatorProperties;
		},
		currentAuthenticator() {
			return this.selectedProperties?.authenticator || this.authenticator;
		},
		phoneNumberUsed() {
			const authenticator = this.currentAuthenticator;
			return authenticator == 'sms' || authenticator == 'whats_app';
		}

	},
	watch: {
		code(val) {
			this.code = val;
			if(val.length >= 6) {
				this.submit();
			}
		}
	},
	methods: {
		...mapActions('notifications', ['pushError']),
		...mapActions('auth', ['init2fa']),
		async Resend() {
			if (this.authenticatorList.length > 1) {
				this.showSelector = true;
			} else {
				this.resending = true;
				this.verifying = true;
				this.reset();
				if (this.onResend) {
					try {
						await this.onResend(this.currentAuthenticator);
					} catch(err) {
						this.pushError(err);
					}
					this.resending = false;
					this.verifying = false;
					return;
				} else {
					await this.selectResendMethod(this.currentAuthenticator);
				}
			}
		},
		async submit() {
			const result = await this.$validator.validateAll();
			if (result) {
				this.onSubmit(this.code);
				this.verifying = true;
				if (this.action) {
					this.loading = true;
					try {
						const { data } = await this.$store.dispatch(this.action, { code: this.code }); 
						this.onSuccess(data);
					} catch (error) {
						this.verifying = false;
						this.loading = false;
						this.pushError(error);
					}
				}
			}
		},
		handleBack() {
			this.onBack();
		},
		reset() {
			this.$refs.code.reset();
			this.verifying = false;
			this.code = '';
		},
		async selectResendMethod(value) {
			this.loading = true;
			if (this.onResend) {
				try {
					await this.onResend(value.authenticator);
				} catch(err) {
					//
				}
			} else {
				try {
					await this.init2fa({
						type: this.type,
						provider: value.authenticator,
						data: this.resendData,
					});
				} catch (err) {
					this.pushError(err);
				}
			}
			this.resending = false;
			this.verifying = false;

			if (typeof value == 'string') {
				this.resendProperties = this.authenticatorList.find(auth => auth.authenticator == value);
			} else {
				this.resendProperties = value;
			}
			this.showSelector = false;
			this.loading = false;
		}

	}
};
</script>
<style lang="scss">
	@import './Authenticator.scss';
</style>