var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('form-error-field', {
    attrs: {
      "name": _vm.name || _vm.id + _vm.document.type,
      "scope": _vm.validationScope
    }
  }, [_c('b-form-file', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: _vm.required && "fileRequired:".concat(_vm.fileData || _vm.value || _vm.state ? true : ''),
      expression: "required && `fileRequired:${(fileData || value || state) ? true : ''}`"
    }],
    "class": {
      'is-invalid': _vm.errors.has(_vm.name || _vm.id + _vm.document.type, _vm.validationScope)
    },
    attrs: {
      "id": _vm.name || _vm.id + _vm.document.type,
      "accept": "image/*, .pdf",
      "placeholder": _vm.state ? 'Supplied' : 'Choose a file or drop it here...',
      "drop-placeholder": "Drop file here...",
      "name": _vm.name || _vm.id + _vm.document.type,
      "disabled": _vm.disabled,
      "state": _vm.state || (_vm.errors.has(_vm.name || _vm.id + _vm.document.type, _vm.validationScope) ? false : null)
    },
    on: {
      "change": _vm.onFileChange
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }