var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    attrs: {
      "id": "session-end-modal",
      "adaptive": true,
      "name": "idle-message",
      "height": "auto"
    },
    on: {
      "before-close": _vm.beforeClose,
      "before-open": _vm.beforeOpen
    }
  }, [_c('div', {
    staticClass: "session-end-modal-container"
  }, [_c('b-row', {
    staticClass: "justify-content-md-center"
  }, [_c('b-col', {
    staticClass: "timeout-text text-right"
  }, [_vm._v(" " + _vm._s(_vm.timeoutText1)), _c('br'), _vm._v(_vm._s(_vm.timeoutText2) + " ")]), _c('b-col', {
    staticClass: "timeout-time"
  }, [_vm._v(" " + _vm._s(_vm.timeoutTime) + " ")])], 1), _c('b-row', {
    staticClass: "session-end-modal-buttons pt-5"
  }, [_c('b-col', [_c('button', {
    staticClass: "btn btn-info btn-sm btn-block",
    on: {
      "click": _vm.conti
    }
  }, [_vm._v(" Continue ")])]), _c('b-col', [_c('button', {
    staticClass: "btn btn-primary btn-sm btn-block",
    on: {
      "click": _vm.logout
    }
  }, [_vm._v(" Logout ")])])], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }