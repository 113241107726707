var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "nano-transfer"
  }, [_c('b-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.currentStep == 1,
      expression: "currentStep == 1"
    }]
  }, [_c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "xl": "7",
      "cols": "12"
    }
  }, [_c('b-card', [_c('product-form', {
    ref: "transferForm",
    attrs: {
      "form-fields": _vm.formFields,
      "loading": _vm.loading,
      "data-vv-scope": "form"
    },
    on: {
      "change": _vm.formChange
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "xl": "5",
      "cols": "12"
    }
  }, [_c('b-card', [_c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', [_c('span', {
    staticClass: "notes",
    domProps: {
      "innerHTML": _vm._s(_vm.notes)
    }
  })])], 1)], 1), _c('b-row', {
    staticClass: "mt-3"
  }, [_c('b-col', [_c('button', {
    staticClass: "btn btn-primary btn-block",
    on: {
      "click": _vm.startTransfer
    }
  }, [_vm._v(" " + _vm._s(_vm.loading === true ? 'Loading...' : 'Next') + " ")])])], 1)], 1)], 1), _vm.currentStep === 2 ? _c('div', {
    staticClass: "justify-content-md-center"
  }, [_c('div', {
    staticClass: "text-center py-4"
  }, [_c('h3', {
    staticClass: "text-success mb-4"
  }, [_vm._v(" Choose payment method ")]), _c('hr'), _c('form', {
    attrs: {
      "action": ""
    },
    on: {
      "submit": function submit($event) {
        return _vm.submit($event);
      }
    }
  }, [_c('fieldset', [_vm._l(_vm.paymentMethodList, function (paymentMethod) {
    return _c('b-row', {
      key: paymentMethod.id,
      staticClass: "form-group"
    }, [_c('b-col', [_c('div', {
      staticStyle: {
        "background-color": "rgb(235, 236, 239)",
        "color": "rgb(33, 37, 41)"
      }
    }, [_c('img', {
      attrs: {
        "src": "https://demo.kevin.eu/static/bank-payment.c7214bce.png",
        "alt": "Bank"
      }
    }), _c('p', [_vm._v(_vm._s(paymentMethod.name))]), _c('b-form-checkbox', {
      attrs: {
        "type": "text"
      },
      on: {
        "change": function change($event) {
          return _vm.select(paymentMethod, $event);
        }
      }
    })], 1)])], 1);
  }), _c('b-row', {
    staticClass: "mt-3"
  }, [_c('b-col', [_c('button', {
    staticClass: "btn btn-primary btn-block"
  }, [_vm._v(" Next ")])])], 1)], 2)])])]) : _vm._e(), _vm.currentStep === 3 ? _c('div', {
    staticClass: "justify-content-md-center"
  }, [_c('div', {
    staticClass: "text-center py-4"
  }, [_c('h3', {
    staticClass: "text-success mb-4"
  }, [_vm._v(" Wait confirmation. Product ID " + _vm._s(_vm.productId) + " ")]), _c('router-link', {
    staticClass: "btn btn-outline-dark px-5",
    attrs: {
      "to": {
        name: 'products'
      },
      "exact": ""
    }
  }, [_vm._v(" Check products ")])], 1)]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }