<template>
	<div class="news-homepage">
		<h3 
			v-if="shownItems.length > 0"
			class="mb-4"
		>
			Latest updates
		</h3>
		<div
			v-for="(item, i) in shownItems"
			:key="i"
			class="news-item my-3"
			:class="{
				'news-item--clickable': item.link || item.action
			}"
			:style="{
				'background-color': item.color && (item.link || item.action) ? item.color : '',
				'border-color': item.color ? item.color : '',
				'color': item.color && item.link ? item.textColor || '#000' : item.textColor || '',
			}"
			@click="() => item.link ? $router.push(item.link) : item.action ? item.action() : null"
		>
			<span
				v-if="item.icon"
				class="news-item__icon"
				v-html="item.icon"
			></span>
			<span
				class="news-item__text"
				v-html="item.html"
			></span>
			<span
				v-if="!item.keep"
				class="news-item__close"
				@click.stop="hide(item)"
			>
				<i class="fas fa-times"></i>
			</span>
		</div>
	</div>
</template>
<script>
import moment from 'moment';
export default {
	name: 'NewsHomepage',
	props: {
		extraFields: {
			type: Array
		}
	},
	data() {
		return {
			storageKey: 'hnews',
			hidden: [],
			items: [
				{
					html: 'Dear customer, we would like to inform you that due to the bank holiday on Tuesday (July 6) no payments will be processed. All initiated payments will be processed the next working day. To avoid any delays, we encourage you to make payments before Tuesday. We apologize for any inconvenience.',
					icon: '<i class="far fa-bell"></i>',
					expire: '2021-07-07',
					visible: true,
					id: '06off'
				},
			]
		};
	},
	computed: {
		shownItems() {
			return this.items.filter(i => {
				let expired = false;
				if (i.expire) {
					const expireDate = moment(i.expire, 'YYYY-MM-DD');
					expired = moment().isAfter(expireDate);
				}
				const hidden = this.hidden.includes(i.id);
				const isExtra = i.extra;
				if (isExtra) {
					const exists = this.extraFields.find(f => f.id == i.id); //todo rewrite this to make more sense in the future
					if (!exists) return false;
				}
				return i.visible && !expired && !hidden;
			});
		}
	},
	watch: {
		extraFields: {
			handler() {
				this.addExtraFields();
			},
			deep: true
		}
	},
	mounted() {
		const storedData = localStorage.getItem(this.storageKey);
		storedData ? this.hidden = JSON.parse(storedData) : null;
		this.addExtraFields();
	},
	methods: {
		hide(item) {
			this.hidden.push(item.id);
			localStorage.setItem(this.storageKey, JSON.stringify(this.hidden));
		},
		addExtraFields() {
			this.items = [
				...this.items,
				...(this.extraFields || []).filter(field => {
					return !this.items.map(i => i.id).includes(field.id);
				}).map(f => {
					f.extra = true;
					return f;
				})
			];
		}
	}
};
</script>
<style lang="scss" scoped>
	.news-homepage {
		margin-bottom: 40px;
	}

	.news-item {
		border-radius: 5px;
		background-color: #fff;
		color: #14325c;
		padding: 0 15px;
		transition: transform .3s ease;
		position: relative;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		border: 1px solid #14325c;

		&--clickable {
			background-color: #14325c;
			color: #fff;

			&:hover {
				cursor: pointer;

				.news-item {
					&__text {
						text-decoration: underline;
					}
				}
			}
		}

		&:hover {
			transform: scale(1.01);
		}

		&__text {
			padding: 10px 20px;
			flex-grow: 1;
		}

		&__close {
			height: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
			transition: transform .3s ease;
			cursor: pointer;

			&:hover {
				transform: scale(1.3);
			}
		}
	}
</style>