var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "search-block"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-3 mb-md-0",
    attrs: {
      "md": "8"
    }
  }, [_c('date-range-select', {
    attrs: {
      "date-range": _vm.dateRange,
      "side": "right"
    },
    on: {
      "dateRangeChanged": _vm.getLogList
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('p', {
    staticClass: "mr-3 mb-0 text-muted align-self-center text-nowrap"
  }, [_vm._v(" Items per page ")]), _c('CustomDropdown', {
    attrs: {
      "items": _vm.pages
    },
    on: {
      "select": function select($event) {
        _vm.filterPerPage = $event;
      }
    }
  })], 1)])], 1)], 1), _vm.total ? _c('div', {
    staticClass: "table-content activity-table"
  }, [_vm._l(_vm.list, function (item, index) {
    return _c('activity-table-item', {
      key: index,
      attrs: {
        "table-item": item
      }
    });
  }), _c('pagination', {
    attrs: {
      "total": _vm.total,
      "per-page": _vm.filterPerPage,
      "current-page": _vm.currentPage
    },
    on: {
      "pagechanged": _vm.onPageChange
    }
  })], 2) : !_vm.listLoaded ? _c('div', {
    staticClass: "empty-list"
  }, [_c('preloader')], 1) : _c('div', {
    staticClass: "empty-list"
  }, [_vm._v(" No activity ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }