<template>
	<div>
		<div class="affiliate-dashboard">
			<div class="d-sm-flex justify-content-between align-items-center mb-4 mb-lg-4">
				<h3 class="mb-3 mb-sm-0 font-weight-bold">
					Selected period:
				</h3>
				<div class="d-flex align-items-center">
					<label class="mb-0 mr-3">Period:</label>
					<div>
						<v-multiselect
							v-model="period"
							v-validate="'required'"
							:options="periodOptions"
							:show-labels="false"
							:allow-empty="false"
							:searchable="false"
							name="period"
							label="text"
							open-direction="bottom"
							return="value"
							track-by="value"
							placeholder="Select period"
							:preselect-first="true"
						/>
					</div>
				</div>
			</div>

			<div class="card mb-5">
				<div v-if="!graphData"
					class="text-center my-2"
				>
					<b-spinner variant="primary" />
				</div>
				<apexchart 
					v-if="graph.options && graph.series"
					height="300"
					:options="graph.options" 
					:series="graph.series" 
					type="line"
				/>
			</div>

			<div v-if="!reportData"
				class="text-center my-2"
			>
				<b-spinner variant="primary" />
				<hr>
			</div>
			<div v-if="reportData"
				class="mb-5"
			>
				<div class="table-responsive">
					<table class="table mb-0">
						<tbody v-for="data in reportData.currencies"
							:key="data.currencyId"
						>
							<tr>
								<td width="40%">
									Commission
								</td>
								<td>{{ data.totalAmount }} <span class="uppercase">{{ data.currencyId }}</span></td>
							</tr>
							<tr>
								<td>Standard commission</td>
								<td>{{ data.standardAmount }} <span class="uppercase">{{ data.currencyId }}</span></td>
							</tr>
							<tr>
								<td>Markup commission</td>
								<td>{{ data.markupAmount }} <span class="uppercase">{{ data.currencyId }}</span></td>
							</tr>
						</tbody>
						<tbody>
							<tr>
								<td>Referrals started registration</td>
								<td>{{ reportData.registrationCount }}</td>
							</tr>
							<tr>
								<td>Referrals completed registration</td>
								<td>{{ reportData.referredIbanCount }}</td>
							</tr>
							<tr>
								<td>Eligible referrals</td>
								<td>{{ reportData.eligibleReferrerCount }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>


			<div v-if="!walletData"
				class="text-center my-2"
			>
				<b-spinner variant="primary" />
				<hr>
			</div>
			<div v-if="walletData && walletData.length"
				class="mb-5"
			>
				<h3 class="mb-3 mb-lg-4 font-weight-bold">
					Since the last payout:
				</h3>
				<div v-for="row in walletData"
					:key="row.id"
					class="row"
				>
					<div class="col-md-4 col-lg-12 col-xl-4 mb-4 mb-md-0 mb-lg-4 mb-xl-0">
						<div class="card h-100">
							<div class="card-body text-center d-flex justify-content-center align-items-center flex-column">
								<p class="mb-2">
									Balance
								</p>
								<p class="lead font-weight-bold mb-0">
									{{ row.balance }} {{ row.currencyId }}
								</p>
							</div>
						</div>
					</div>
					<div class="col-md-4 col-lg-12 col-xl-4 mb-4 mb-md-0 mb-lg-4 mb-xl-0">
						<div class="card h-100">
							<div class="card-body text-center d-flex justify-content-center align-items-center flex-column">
								<p class="w-100 d-flex justify-content-between mb-0">
									Standard <span>{{ row.standardTotal }} {{ row.currencyId }}</span>
								</p>
								<hr class="w-100 border-top">
								<p class="w-100 d-flex justify-content-between mb-0">
									Markup <span>{{ row.markupTotal }} {{ row.currencyId }}</span>
								</p>
							</div>
						</div>
					</div>
					<div class="col-md-4 col-lg-12 col-xl-4">
						<div class="card h-100">
							<div class="card-body text-center d-flex justify-content-center align-items-center flex-column">
								<p class="mb-2">
									Eligible referrals since the last payout
								</p>
								<p class="lead font-weight-bold mb-0">
									{{ row.eligibleReferrer }}
								</p>
							</div>
						</div>
					</div>
				</div>
				<div v-if="walletData && !walletData.length"
					class="row"
				>
					<div class="col-md-4 col-lg-12 col-xl-4 mb-4 mb-md-0 mb-lg-4 mb-xl-0">
						<div class="card h-100">
							<div class="card-body text-center d-flex justify-content-center align-items-center flex-column">
								<p class="mb-2">
									Balance
								</p>
								<p class="lead font-weight-bold mb-0">
									-
								</p>
							</div>
						</div>
					</div>
					<div class="col-md-4 col-lg-12 col-xl-4 mb-4 mb-md-0 mb-lg-4 mb-xl-0">
						<div class="card h-100">
							<div class="card-body text-center d-flex justify-content-center align-items-center flex-column">
								<p class="w-100 d-flex justify-content-between mb-0">
									Standard <span>-</span>
								</p>
								<hr class="w-100 border-top">
								<p class="w-100 d-flex justify-content-between mb-0">
									Markup <span>-</span>
								</p>
							</div>
						</div>
					</div>
					<div class="col-md-4 col-lg-12 col-xl-4">
						<div class="card h-100">
							<div class="card-body text-center d-flex justify-content-center align-items-center flex-column">
								<p class="mb-2">
									Number of eligible referrals
								</p>
								<p v-if="reportData"
									class="lead font-weight-bold mb-0"
								>
									{{ reportData.walletIssuedCount }}
								</p>
								<p v-if="!reportData"
									class="lead font-weight-bold mb-0"
								>
									<b-spinner small
										variant="primary"
									/>
								</p>
							</div>
						</div>
					</div>
				</div>
				<div v-if="walletData && walletData.length"
					class="text-center pt-4"
				>
					<button v-b-modal.payout-modal
						class="btn btn-primary px-5"
					>
						Payout
					</button>
				</div>
				<b-modal
					v-cloak
					id="payout-modal"
					size="sm"
					centered
					hide-header
					:ok-only="true"
				>
					<div class="text-center">
						<p>
							Contact <a :href="`mailto:${supportEmail}`"
								target="_blank"
							>{{ supportEmail }}</a> for commission payouts
						</p>
					</div>
				</b-modal>
			</div>
			
			<div v-if="!commissionData"
				class="text-center my-2"
			>
				<b-spinner variant="primary" />
				<hr>
			</div>
			<div v-if="commissionData && commissionData.rows && commissionData.rows.length"
				class="mb-5"
			>
				<h3 class="mb-3 mb-lg-4 font-weight-bold">
					Payout history
				</h3>
				<div class="table-responsive">
					<table class="table mb-0">
						<thead>
							<tr>
								<!-- <th>Transaction</th> -->
								<th>Date</th>
								<th>Amount</th>
								<!-- <th width="1%"></th> -->
							</tr>
						</thead>
						<tbody>
							<tr v-for="row in commissionData.rows"
								:key="row.id"
							>
								<!-- <td class="align-middle">{{row.status}}</td> -->
								<td class="align-middle">
									{{ moment(row.dateComplete).format('YYYY-MM-DD') }}
								</td>
								<td class="align-middle">
									{{ row.totalAmount }} {{ row.currencyId }}
								</td>
								<!-- <td class="text-nowrap"><button class="btn btn-sm btn-outline-dark">Download invoice</button></td> -->
							</tr>
							<!-- <tr>
								<td class="align-middle">Withdrawal</td>
								<td class="align-middle">2020-09-11</td>
								<td class="align-middle">15 000.00 €</td>
								<td class="text-nowrap"><button class="btn btn-sm btn-outline-dark">Download invoice</button></td>
							</tr>
							<tr>
								<td class="align-middle">Withdrawal</td>
								<td class="align-middle">2020-09-11</td>
								<td class="align-middle">15 000.00 €</td>
								<td class="text-nowrap"><button class="btn btn-sm btn-outline-dark">Download invoice</button></td>
							</tr>
							<tr>
								<td class="align-middle">Withdrawal</td>
								<td class="align-middle">2020-09-11</td>
								<td class="align-middle">15 000.00 €</td>
								<td class="text-nowrap"><button class="btn btn-sm btn-outline-dark">Download invoice</button></td>
							</tr> -->
						</tbody>
					</table>
				</div>
			</div>
			<div class="mb-0">
				<h3 class="mb-3 mb-lg-4 font-weight-bold">
					Referrals
				</h3>
				<div class="table-responsive">
					<table class="table mb-0">
						<thead>
							<tr>
								<th>Name</th>
								<th>Registration date</th>
								<th>Status</th>
							</tr>
						</thead>
						<tbody>
							<tr v-if="!referralsLoaded">
								<td colspan="3"
									class="text-center"
								>
									<b-spinner variant="primary" />
									<hr>
								</td>
							</tr>
							<tr v-if="referralsLoaded && referrals.length == 0">
								<td colspan="3"
									class="text-center"
								>
									There are no referrals to show.
								</td>
							</tr>
							<tr
								v-for="ref in referrals"
								:key="ref.id"
							>
								<td>{{ ref.fullName }}</td>
								<td>{{ formatReferralDate(ref.dateCreated) }}</td>
								<td>
									<span
										class="badge"
										:class="getStatus(ref.verification, ref.status).class"
									>{{ getStatus(ref.verification, ref.status).label }}</span>
								</td>
							</tr>
							<!-- <tr>
								<td>Name</td>
								<td>2020-09-11</td>
								<td><span class="badge badge-warning">In progress</span></td>
								<td><span class="badge badge-secondary">Eligible</span></td>
								<td><span class="badge badge-success">Completed</span></td>
							</tr> -->
						</tbody>
					</table>
				</div>
			</div>
		</div>		
	</div>
</template>
<script>
import { endpoints } from '~/config';
import moment from 'moment';
import { mapActions } from 'vuex';
import { siteName, supportEmail } from '~/config';

export default {
	data() {
		return {
			moment,
			referrals: [],
			referralsLoaded: false,
			walletsLoaded: false,
			reportData: null,
			graphData: null,
			walletData: null,
			commissionData: null,
			supportEmail,
			siteName,
			period: 'week',
			periodOptions: [
				{
					value: 'week',
					text: 'Week'
				},
				{
					value: 'month',
					text: 'Month'
				},
				{
					value: 'year',
					text: 'Year'
				},
			],
			graph: {
				options: {
					chart: {
						toolbar: {
							show: false
						},
						height: '300px'
					},
					colors: ['#000'],
					stroke: {
						width: 1,
						curve: 'smooth'
					},
					xaxis: {
						labels: {
							rotate: 0
						},
						axisTicks: {
							show: true,
						},
						tickAmount: 5,
						tickPlacement: 'between'
					},
					yaxis: {
						labels: {
							formatter: (value) => `${value.toFixed(2)} EUR`
						}
					}
				},
				series: null
			},
			dateFrom: moment().subtract(1, 'week').format('YYYY-MM-DD')
		};
	},
	watch: {
		'graphData': function(v) {
			if (!v) {
				this.graph.series = null;
				return;
			}
			this.graph.series = [
				{
					type: 'line',
					name: 'Amount',
					data: v.map((data) => {
						return {
							x: data.date,
							y: data.value
						};
					})
				}
			];
		},
		'period': function() {
			switch (this.period) {
			case 'week':
				this.dateFrom = moment().subtract(1, 'week').format('YYYY-MM-DD');
				break;
			case 'month':
				this.dateFrom = moment().subtract(1, 'month').format('YYYY-MM-DD');
				break;
			case 'year':
				this.dateFrom = moment().subtract(1, 'year').format('YYYY-MM-DD');
				break;
			default:
				this.dateFrom = moment().subtract(1, 'week').format('YYYY-MM-DD');
			}

			this.getGraph();
			this.getReferrals();
			this.getCommission();
			this.getReport();
		}
	},
	methods: {
		...mapActions('notifications', ['pushError']),
		async getReferrals() {
			this.referrals = [];
			this.referralsLoaded = false;
			const options = {
				method: 'GET',
				url: endpoints.referral.referralList,
				params: {
					'createdFrom': moment(this.dateFrom).format('YYYY-MM-DD HH:mm:ss'),
					'orderBy': 'timestampCreated',
					'page': 1,
					'limit': 100,
					'orderDirection': 'desc'
				}
			};
			try {
				const { data } = await axios(options);
				const { rows } = data;
				this.referrals = rows;
			} catch (err) {
				this.pushError(err.message);
			}
			this.referralsLoaded = true;
		},
		getStatus(verification, status) {
			switch(verification) {
			case 'unverified':
				return {
					class: 'badge-warning',
					label: 'Pending'
				};
			case 'pending':
			case 'rejected':
				return {
					class: 'badge-warning',
					label: 'In progress'
				};
			case 'closed':
				return {
					class: 'badge-danger',
					label: 'Rejected'
				};
			case 'verified':
				if (status && status == 'disabled') {
					return {
						class: 'badge-danger',
						label: 'Closed'
					};
				}
				return {
					class: 'badge-success',
					label: 'Onboarded'
				};
			default: 
				return {
					class: 'badge-danger',
					label: 'Status unknown'
				};
			}
		},
		formatReferralDate(date) {
			return moment(date).format('YYYY-MM-DD');
		},
		async getCommission() {
			this.commissionData = null;
			const options = {
				method: 'GET',
				url: endpoints.referral.commission,
				params: {
					'createdFrom': moment(this.dateFrom).format('YYYY-MM-DD HH:mm:ss'),
					'direction': 'outgoing',
					'orderBy': 'timestampCreated',
					'page': 1,
					'limit': 10,
					'orderDirection': 'asc',
					'status': 'completed'
				}
			};
			try {
				const { data } = await axios(options);
				this.commissionData = data;
			} catch (err) {
				this.commissionData = [];
			}
		},
		async getReferralWallet() {
			this.walletsLoaded = false;

			const options = {
				method: 'GET',
				url: endpoints.referral.wallet
			};

			try {
				const { data } = await axios(options);
				this.walletData = data;

				this.walletsLoaded = true;
			} catch (err) {
				this.walletData = [];
			}
		},
		async getReport() {
			this.reportData = null;
			const options = {
				method: 'GET',
				url: endpoints.referral.report,
				params: {
					'createdFrom': moment(this.dateFrom).format('YYYY-MM-DD HH:mm:ss'),
					'createdTo': moment().format('YYYY-MM-DD HH:mm:ss'),
				}
			};
			try {
				const { data } = await axios(options);
				this.reportData = data;
			} catch (err) {
				this.reportData = [];
			}
		},
		async getGraph() {
			this.graphData = null;
			const options = {
				method: 'GET',
				url: endpoints.referral.graph,
				params: {
					'createdFrom': this.dateFrom,
					'createdTo': moment().format('YYYY-MM-DD'),
					'currencyId': 'eur'
				}
			};
			try {
				const { data } = await axios(options);
				this.graphData = data;
			} catch (err) {
				this.graphData = {};
			}
		},
	},
	mounted() {
		this.getReferrals();
		this.getReferralWallet();
		this.getCommission();
		this.getReport();
		this.getGraph();
	}
};
</script>
<style lang="scss">
	span.uppercase {
		text-transform: uppercase;
	}
	#payout-modal {
		.modal-body {
			padding-bottom: 5px;
		}
		.modal-footer {
			justify-content: center;
		}
	}
</style>