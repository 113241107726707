var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.iframeLoaded ? _c('iframe', {
    staticStyle: {
      "width": "100%",
      "height": "800px",
      "border": "none"
    },
    attrs: {
      "id": "iframe",
      "src": _vm.iframeUrl,
      "allow": "camera;microphone"
    }
  }) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }