<template>
	<div class="odd-form">
		<b-form>
			<b-row>
				<b-col>
					<h2>Update your company identity</h2>
				</b-col>
			</b-row>
			<b-row v-if="!identified">
				<b-col>
					<div class="text-center my-4">
						<b-spinner v-if="!iframeLoaded" />
					</div>
					<iframe
						v-if="iframeLoaded && isDesktop"
						id="iframe"
						:src="iframeUrl"
						style="width:100%; height:800px; border: none;"
						data-registration="iframe"
						allow="camera;microphone"
					></iframe>
					<div v-if="iframeLoaded && !isDesktop"
						class="text-center my-4"
					>
						<b-button
							variant="primary"
							:href="iframeUrl"
							target="_blank"
							class="mt-4 mb-4"
						>
							Verify documents and photo
						</b-button>
					</div>
				</b-col>
			</b-row>
			<b-row v-if="identified">
				<b-col class="text-center my-4">
					<b-badge variant="success">
						Identification complete
					</b-badge>
				</b-col>
			</b-row>
		</b-form>
		<odd-form-footer 
			v-bind="properties"
			ref="footer"
			:skip-submit="true"
			cancel-text="I will do this later"
			submit-text="Continue"
			:submit-hidden="!identified"
			:cancel-hidden="identified"
		/>
	</div>
</template>
<script>
import { mapActions } from 'vuex';
import { endpoints } from '~/config';
import OddFormFooter from '../OddFormFooter';
export default {
	name: 'ODDCompanyIdentity',
	components: {
		OddFormFooter
	},
	data() {
		return {
			formData: {},
			iframeLoaded: false,
			iframeUrl: null,
			isDesktop: false,
			identified: false,
			interval: null,
		};
	},
	computed: {
		properties() {
			return {
				...this._props, 
				formData: this.formData,
			};
		}
	},
	props: {
		onSuccess: {
			type: Function,
			default: () => {},
			required: true
		},
		onFailure: {
			type: Function,
			default: () => {},
		},
		onCancel: {
			type: Function,
			default: () => {}
		},
		submitUrl: {
			type: String,
			default: '',
			required: true
		}
	},
	methods: {
		...mapActions('notifications', [
			'pushError'
		]),
		async getIframeUrl() {
			const options = {
				method: 'GET',
				url: endpoints.odd.companyIdentity
			};
			const { data } = await axios(options);
			return data;
		},
		async loadIframe() {
			try {
				const { url } = await this.getIframeUrl();
				this.iframeUrl = url;
				this.iframeLoaded = true;
				this.initCheckStatus();
			} catch (err) {
				this.pushError(err);
			}
		},
		checkUserAgent() {
			const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
			this.isDesktop = !isMobile;
		},
		initCheckStatus() {
			this.interval = setTimeout(async () => {
				try {
					const { data } = await axios.get(endpoints.user.setting);
					const popups = data.popups.map(p => {
						return typeof p == 'string' ? p : p.type;
					});
					if (popups && !popups.includes('odd_company_identity')) {
						this.identified = true;
						this.iframeLoaded = false;
						clearTimeout(this.interval);
					} else {
						this.initCheckStatus();
					}
				} catch (err) {
					this.pushError(err);

				}
			}, 10000);
		}
	},
	async mounted() {
		await this.loadIframe();
		this.checkUserAgent();
	},
	beforeDestroy() {
		clearTimeout(this.interval);
	}
};
</script>
<style lang="scss">
	
</style>