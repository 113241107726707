var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrapper-verification"
  }, [_vm.isRegistration ? _c('verification-steps', {
    attrs: {
      "steps": _vm.registrationSteps
    }
  }) : _vm._e(), _c('div', {
    staticClass: "main"
  }, [_c('transition', {
    attrs: {
      "name": "fade",
      "mode": "out-in"
    }
  }, [_c('router-view')], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }