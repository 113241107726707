var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "account-item d-flex align-items-center"
  }, [_c('div', {
    staticClass: "avatar"
  }, [_c('span', [_vm._v(_vm._s(_vm.data.name ? _vm.data.name.charAt(0) : _vm.data.walletId.charAt(0)))])]), _c('div', {
    staticClass: "current-item-details align-self-center"
  }, [_c('div', {
    staticClass: "name"
  }, [_vm._v(" " + _vm._s(_vm.data.name) + " "), _c('span', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal",
      value: _vm.editWalletModalId,
      expression: "editWalletModalId"
    }],
    staticClass: "edit"
  }, [_vm._v("Edit alias")])]), _vm.isVerifiedAddress ? _c('small', {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.data.iban))]) : _vm._e()]), _c('div', {
    staticClass: "wallet-balance align-self-center ml-auto text-uppercase"
  }, [+_vm.data.walletCurrencyList[0].balance ? [_vm.hasOtherCurrencies ? _c('span', {
    staticClass: "approxSign"
  }, [_vm._v("≈")]) : _vm._e()] : _vm._e(), _c('span', [_vm._v(_vm._s(_vm.data.walletCurrencyList[0].balance))]), _vm._v(" " + _vm._s(_vm.data.walletCurrencyList[0].currencyId) + " ")], 2), _c('b-modal', {
    attrs: {
      "id": _vm.editWalletModalId,
      "size": "sm",
      "centered": "",
      "hide-header": "",
      "hide-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hide = _ref.hide;
        return [_c('p', [_vm._v("Account alias")]), _c('b-form-input', {
          directives: [{
            name: "validate",
            rawName: "v-validate",
            value: 'required|max:60',
            expression: "'required|max:60'"
          }],
          "class": {
            'is-invalid': _vm.errors.has('name')
          },
          attrs: {
            "type": "text",
            "name": "name",
            "placeholder": "New name",
            "data-vv-validate-on": "change"
          },
          model: {
            value: _vm.editInfo.name,
            callback: function callback($$v) {
              _vm.$set(_vm.editInfo, "name", $$v);
            },
            expression: "editInfo.name"
          }
        }), _vm.editWalletSuccess ? _c('b-alert', {
          staticClass: "mt-3",
          attrs: {
            "show": "",
            "variant": "success"
          }
        }, [_vm._v(" Account successfully updated. ")]) : _vm._e(), _vm.editWalletError ? _c('b-alert', {
          staticClass: "mt-3",
          attrs: {
            "show": "",
            "variant": "danger"
          }
        }, [_vm._v(" Something went wrong, please try again later. ")]) : _vm._e(), _c('div', {
          staticClass: "mt-4 d-flex"
        }, [_c('b-button', {
          attrs: {
            "variant": "info"
          },
          on: {
            "click": function click($event) {
              return _vm.$bvModal.hide(_vm.editWalletModalId);
            }
          }
        }, [_vm._v(" Cancel ")]), _c('button', {
          staticClass: "btn btn-primary px-5 ml-3",
          on: {
            "click": _vm.editWallet
          }
        }, [_vm._v(" Set ")])], 1)];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }