var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    staticClass: "form-body margintop30",
    attrs: {
      "action": "#",
      "autocomplete": "disable"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onsubmit();
      }
    }
  }, [_c('fieldset', {
    attrs: {
      "disabled": _vm.details.verification == 'pending'
    }
  }, [_c('b-row', [_c('b-col', [_c('form-error-field', {
    attrs: {
      "name": "name"
    }
  }, [_c('label', [_vm._v(" Company name ")]), _c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "id": "name",
      "type": "text",
      "name": "name",
      "placeholder": "Enter company name",
      "debounce": "500",
      "data-registration": "company-name"
    },
    model: {
      value: _vm.data.name,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "name", $$v);
      },
      expression: "data.name"
    }
  })], 1)], 1)], 1), !_vm.isInitialCapital ? _c('b-row', [_c('b-col', [_c('form-error-field', {
    attrs: {
      "name": "code"
    }
  }, [_c('label', [_vm._v(" Legal entity code ")]), _c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "id": "code",
      "type": "text",
      "name": "code",
      "placeholder": "Enter legal entity code",
      "debounce": "500",
      "data-registration": "legal-entity-node"
    },
    model: {
      value: _vm.data.registrationCode,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "registrationCode", $$v);
      },
      expression: "data.registrationCode"
    }
  })], 1)], 1)], 1) : _vm._e(), _c('b-row', [_c('b-col', [_c('phone-input', {
    attrs: {
      "data-registration": "phone"
    },
    model: {
      value: _vm.data.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "phone", $$v);
      },
      expression: "data.phone"
    }
  })], 1)], 1), _c('b-row', [_c('b-col', [_c('form-error-field', {
    attrs: {
      "name": "email"
    }
  }, [_c('label', [_vm._v(" Email (for communication during onboarding process) ")]), _c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|email',
      expression: "'required|email'"
    }],
    attrs: {
      "id": "email",
      "type": "email",
      "name": "email",
      "placeholder": "Enter company email address",
      "debounce": "500",
      "data-registration": "email",
      "novalidate": ""
    },
    model: {
      value: _vm.data.email,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "email", $$v);
      },
      expression: "data.email"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('form-error-field', {
    staticClass: "text-left mb-12",
    attrs: {
      "name": "legalForm"
    }
  }, [_c('label', [_vm._v(" Legal form ")]), _c('v-multiselect', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "options": _vm.legalOptions,
      "show-labels": false,
      "allow-empty": false,
      "name": "legalForm",
      "label": "name",
      "placeholder": "Select your legal form",
      "track-by": "name",
      "return": "value",
      "data-registration": "legal-form"
    },
    model: {
      value: _vm.data.legalForm,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "legalForm", $$v);
      },
      expression: "data.legalForm"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('form-error-field', {
    attrs: {
      "name": "website"
    }
  }, [_c('label', [_vm._v(" Website ")]), _c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    staticClass: "form-control",
    attrs: {
      "id": "website",
      "data-vv-validate-on": "blur",
      "type": "text",
      "name": "website",
      "placeholder": "Enter website",
      "debounce": "500",
      "data-registration": "website"
    },
    model: {
      value: _vm.data.website,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "website", $$v);
      },
      expression: "data.website"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('form-error-field', {
    attrs: {
      "name": "numberOfEmployees"
    }
  }, [_c('label', [_vm._v(" Number of employees ")]), _c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|min_value:1',
      expression: "'required|min_value:1'"
    }],
    staticClass: "form-control",
    attrs: {
      "id": "number_of_employees",
      "data-vv-validate-on": "blur",
      "data-vv-as": "Number of employees",
      "type": "number",
      "name": "numberOfEmployees",
      "placeholder": "Enter number of employees",
      "debounce": "500",
      "data-registration": "number-of-employees"
    },
    model: {
      value: _vm.data.numberOfEmployees,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "numberOfEmployees", $$v);
      },
      expression: "data.numberOfEmployees"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-form-group', {
    "class": {
      'input-invalid': _vm.errors.has('vat_number')
    }
  }, [_c('label', [_vm._v(" VAT Number ")]), _c('b-form-input', {
    staticClass: "form-control",
    attrs: {
      "id": "vat_number",
      "type": "text",
      "name": "vat_number",
      "placeholder": "Enter VAT number",
      "debounce": "500",
      "data-registration": "vat"
    },
    model: {
      value: _vm.data.vatNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "vatNumber", $$v);
      },
      expression: "data.vatNumber"
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.errors.has('vat_number'),
      expression: "errors.has('vat_number')"
    }],
    staticClass: "errormsg"
  }, [_c('span', [_vm._v(_vm._s(_vm.errors.first("vat_number")))])])], 1)], 1), !_vm.isInitialCapital ? _c('b-row', [_c('b-col', [_c('form-error-field', {
    attrs: {
      "name": "registrationDate"
    }
  }, [_c('label', [_vm._v(" Date of incorporation ")]), _c('app-datepicker', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "name": "registrationDate",
      "data-registration": "date-of-incorporation"
    },
    model: {
      value: _vm.data.registrationDate,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "registrationDate", $$v);
      },
      expression: "data.registrationDate"
    }
  })], 1)], 1)], 1) : _vm._e(), _c('multi-address', {
    attrs: {
      "addresses": _vm.data.addresses
    }
  })], 1), _c('notification'), _c('b-row', [_c('b-col', {
    staticClass: "text-center mb-4"
  }, [_c('input', {
    staticClass: "btn btn-primary btn-rounded btn-upper w-100",
    "class": _vm.buttonClass,
    attrs: {
      "type": "submit",
      "data-registration": "next"
    },
    domProps: {
      "value": _vm.buttonText
    }
  })])], 1), _vm.allowSkip ? _c('b-row', [_c('b-col', {
    staticClass: "text-center"
  }, [_c('router-link', {
    staticClass: "framelink skiplink",
    attrs: {
      "to": {
        name: 'home'
      },
      "tag": "a",
      "exact": "",
      "data-registration": "skip"
    }
  }, [_vm.details.verification !== 'pending' ? _c('span', [_vm._v("Skip verification")]) : _vm._e(), _vm.details.verification == 'pending' ? _c('span', [_vm._v("Back to main page")]) : _vm._e()])], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }