var render = function () {
  var _vm$data$contacts, _vm$data$contacts2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "account-details profile-business-info",
    attrs: {
      "id": "profile-business-info"
    }
  }, [_c('h3', {
    staticClass: "mb-4 mb-lg-5 font-weight-bold"
  }, [_vm._v(" Business information ")]), _c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('label', {
    staticClass: "col-6 col-sm-5 col-xl-4 col-form-label"
  }, [_vm._v("Company name")]), _c('div', {
    staticClass: "col-6 col-sm-7 col-xl-6"
  }, [_c('strong', [_vm._v(_vm._s(_vm.data.name))])])])]), _c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('label', {
    staticClass: "col-6 col-sm-5 col-xl-4 col-form-label"
  }, [_vm._v("Company code")]), _c('div', {
    staticClass: "col-6 col-sm-7 col-xl-6"
  }, [_c('strong', [_vm._v(_vm._s(_vm.data.registrationCode))])])])]), _c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('label', {
    staticClass: "col-6 col-sm-5 col-xl-4 col-form-label"
  }, [_vm._v("VAT number")]), _c('div', {
    staticClass: "col-6 col-sm-7 col-xl-6"
  }, [_vm.data.vatNumber ? _c('strong', [_vm._v(_vm._s(_vm.data.vatNumber))]) : _c('strong', [_vm._v("-")])])])]), _c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('label', {
    staticClass: "col-6 col-sm-5 col-xl-4 col-form-label"
  }, [_vm._v("Number of employees")]), _c('div', {
    staticClass: "col-6 col-sm-7 col-xl-6"
  }, [_c('strong', [_vm._v(_vm._s(_vm.data.numberOfEmployees))])])])]), _c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('label', {
    staticClass: "col-6 col-sm-5 col-xl-4 col-form-label"
  }, [_vm._v("Website")]), _c('div', {
    staticClass: "col-6 col-sm-7 col-xl-6"
  }, [(_vm$data$contacts = _vm.data.contacts) !== null && _vm$data$contacts !== void 0 && _vm$data$contacts.website ? _c('strong', [_vm._v(_vm._s((_vm$data$contacts2 = _vm.data.contacts) === null || _vm$data$contacts2 === void 0 ? void 0 : _vm$data$contacts2.website))]) : _c('strong', [_vm._v("-")])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }