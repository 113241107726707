var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card-jwk-form"
  }, [_c('img', {
    attrs: {
      "src": "/images/icons/card-cvv.svg",
      "alt": "Card CVV"
    }
  }), _c('h2', {
    staticClass: "mt-4"
  }, [_vm._v(" Enter card CVV code to activate it ")]), _c('p', {
    staticClass: "mt-4 mb-4"
  }, [_vm._v(" You should find the code on the back of your card. ")]), _c('label', {
    staticClass: "card-input-label"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.data.cvv,
      expression: "data.cvv"
    }, {
      name: "validate",
      rawName: "v-validate",
      value: "min:".concat(_vm.inputLength, "|required"),
      expression: "`min:${inputLength}|required`"
    }],
    ref: "cvv",
    "class": _vm.errors.has('cvv') ? 'card-input-field-error' : '',
    attrs: {
      "type": "text",
      "name": "cvv",
      "pattern": "\\d*",
      "maxlength": _vm.inputLength,
      "data-vv-validate-on": "blur",
      "placeholder": "Enter CVV code"
    },
    domProps: {
      "value": _vm.data.cvv
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.data, "cvv", $event.target.value);
      }
    }
  }), _vm.errors.has('cvv') ? _c('div', {
    staticClass: "card-input-error"
  }, [_vm._v(" " + _vm._s(_vm.errors.first('cvv')) + " ")]) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }