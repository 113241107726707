<template>
	<div
		v-if="permissions.create && permissions.get && currentWallet"
		class="cards-menu"
	>
		<div
			v-if="noCards"
			class="cards-menu__menu-item"
		>
			<b-row @click="() => $router.push({ name: 'new-card' })">
				<b-col
					cols="auto"
					class="d-flex align-items-center"
				>
					<div class="cards-menu__card-image">
						<card-image :show-details="false" />
					</div>
				</b-col>
				<b-col>
					<div class="d-flex flex-column">
						<strong>Order your Verifo Card now</strong>
						<small>Virtual and physical cards available</small>
					</div>
				</b-col>
			</b-row>
		</div>
	</div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import CardImage from '@/LayoutDashboard/Cards/Partials/CardImage';
import moment from 'moment';

export default {
	name: 'CardsMenu',
	components: {
		CardImage
	},
	data() {
		return {
			moment,
		};
	},
	computed: {
		...mapGetters('wallet', [
			'currentWallet'
		]),
		...mapState('cards', [
			'list',
			'listLoaded'
		]),
		...mapGetters('user', [
			'isGranted'
		]),
		noCards() {
			return this.listLoaded && this.list.length == 0;
		},
		permissions() {
			return {
				create: this.isGranted('front_create_card_order'),
				get: this.isGranted('front_get_card')
			};
		}
	},
	methods: {
		formatCardNumber(card) {
			const numArr = card.cardNumber.split('*');
			return numArr[numArr.length - 1];
		}
	}
};
</script>
<style lang="scss">
	.cards-menu {
		&__menu-item {
			border: 1px solid var(--sidebar-divider);
			padding: 10px 17px;
			border-radius: 3px;
			cursor: pointer;
			background-color: transparent;
			transition: all .5s ease;

			&:hover {
				background-color: var(--switcher-item-bg-hover);
			}
		}

		&__card-name {
			margin-bottom: -5px;
		}

		&__menu-item {
			img {
				display: inline-block;
			}
			strong {
				line-height: 16px;
			}
			small {
				color: var(--current-balance-text);
			}
		}

		&__card-image {
			width: 49px;
		}
	}
</style>