var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('form', {
    attrs: {
      "action": ""
    }
  }, [_c('fieldset', {
    attrs: {
      "disabled": _vm.loading
    }
  }, [_vm._l(_vm.populatedFields, function (field) {
    return _c('b-row', {
      key: field.name,
      staticClass: "form-group"
    }, [_c('b-col', [_c('label', [_vm._v(_vm._s(field.label) + " "), field.required ? _c('span', {
      staticClass: "label-red"
    }, [_vm._v("*")]) : _vm._e()]), field.type == 'text' ? _c('b-form-input', {
      directives: [{
        name: "validate",
        rawName: "v-validate",
        value: [field.required && 'required', field.validation].filter(function (v) {
          return !!v;
        }).join('|'),
        expression: "[field.required && 'required', field.validation].filter(v => !!v).join('|')"
      }],
      "class": {
        'is-invalid': _vm.errors.has(field.name)
      },
      attrs: {
        "type": "text",
        "name": field.name,
        "placeholder": field.placeholder || field.label,
        "data-vv-validate-on": "change"
      },
      on: {
        "blur": _vm.onBlur
      },
      model: {
        value: _vm.formData[field.key || field.name],
        callback: function callback($$v) {
          _vm.$set(_vm.formData, field.key || field.name, $$v);
        },
        expression: "formData[field.key || field.name]"
      }
    }) : _vm._e(), field.type == 'amount' ? _c('b-input-group', {
      "class": {
        'is-invalid': _vm.errors.has(field.name) || _vm.errors.has(field.currencyName)
      }
    }, [_c('b-form-input', {
      directives: [{
        name: "filter-input",
        rawName: "v-filter-input",
        value: 'float',
        expression: "'float'"
      }, {
        name: "validate",
        rawName: "v-validate",
        value: "".concat(field.required && 'required'),
        expression: "`${field.required && 'required'}`"
      }],
      "class": {
        'is-invalid': _vm.errors.has(field.name)
      },
      attrs: {
        "name": "amount",
        "placeholder": "0.00"
      },
      on: {
        "blur": _vm.onBlur
      },
      model: {
        value: _vm.formData[field.key || field.name],
        callback: function callback($$v) {
          _vm.$set(_vm.formData, field.key || field.name, $$v);
        },
        expression: "formData[field.key || field.name]"
      }
    }), _c('b-input-group-append', [_c('v-multiselect', {
      directives: [{
        name: "validate",
        rawName: "v-validate",
        value: field.required && 'required',
        expression: "field.required && 'required'"
      }],
      "class": {
        'is-invalid': _vm.errors.has(field.currencyName)
      },
      attrs: {
        "options": _vm.currencies,
        "show-labels": false,
        "allow-empty": false,
        "searchable": false,
        "name": field.currencyName,
        "label": "text",
        "return": "value",
        "open-direction": "bottom",
        "placeholder": field.currencyPlaceholder
      },
      on: {
        "blur": _vm.onBlur
      },
      model: {
        value: _vm.formData[field.currencyKey],
        callback: function callback($$v) {
          _vm.$set(_vm.formData, field.currencyKey, $$v);
        },
        expression: "formData[field.currencyKey]"
      }
    })], 1)], 1) : _vm._e(), field.type == 'textarea' ? _c('b-form-textarea', {
      directives: [{
        name: "validate",
        rawName: "v-validate",
        value: field.required && 'required',
        expression: "field.required && 'required'"
      }],
      "class": {
        'is-invalid': _vm.errors.has(field.name)
      },
      attrs: {
        "rows": 3,
        "max-rows": 6,
        "name": field.name,
        "placeholder": field.placeholder || field.label,
        "maxlength": field.maxLength || 124
      },
      on: {
        "blur": _vm.onBlur
      },
      model: {
        value: _vm.formData[field.key || field.name],
        callback: function callback($$v) {
          _vm.$set(_vm.formData, field.key || field.name, $$v);
        },
        expression: "formData[field.key || field.name]"
      }
    }) : _vm._e(), _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.errors.has(field.name),
        expression: "errors.has(field.name)"
      }],
      staticClass: "invalid-feedback"
    }, [_vm._v(" " + _vm._s(_vm.errors.first(field.name)) + " ")])], 1)], 1);
  }), _c('b-row', [_c('b-col', [_c('small', {
    staticClass: "label-red"
  }, [_vm._v("* Required fields")])])], 1)], 2)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }