<template>
	<div class="affiliate-programme">
		<div class="mb-5">
			<h3 class="mb-3 mb-lg-4 font-weight-bold">
				Who can join the program?
			</h3>
			<div class="row">
				<div class="col-md-4 col-lg-12 col-xl-4 mb-4 mb-md-0 mb-lg-4 mb-xl-0">
					<div class="mb-2">
						<img
							v-if="WHITELABEL == 'verifo'"
							src="/images/icons/verifo-customer-icon.svg"
							width="40"
							height="40"
						>
						<img
							v-if="WHITELABEL !== 'verifo'"
							src="/images/icons/customer-icon.svg"
							width="40"
							height="40"
						>
					</div>
					<p><strong>{{ siteName }} Customers</strong></p>
					<p class="text-muted">
						If you are a regular {{ siteName }} customer or just enjoyed our services, you can recommend us to your colleagues, clients, or partners and generate extra income.
					</p>
				</div>
				<div class="col-md-4 col-lg-12 col-xl-4 mb-4 mb-md-0 mb-lg-4 mb-xl-0">
					<div class="mb-2">
						<img
							src="/images/icons/agent-icon.svg"
							width="40"
							height="40"
						>
					</div>
					<p><strong>Agents / Partners</strong></p>
					<p class="text-muted">
						If your client is looking for a reliable place to open a business or personal account, you may refer them to us and earn competitive referral commissions.
					</p>
				</div>
				<div class="col-md-4 col-lg-12 col-xl-4">
					<div class="mb-2">
						<img
							src="/images/icons/professional-affiliates-icon.svg"
							width="40"
							height="40"
						>
					</div>
					<p><strong>Professional Affiliates</strong></p>
					<p class="text-muted">
						Content creators, business educators, influencers, and review sites can monetize their content by joining our afﬁliate program and introducing {{ siteName }} services to their audiences.
					</p>
				</div>
			</div>
		</div>
		<div class="mb-5">
			<h3
				v-if="WHITELABEL !== 'bainal'"
				class="mb-3 mb-lg-4 font-weight-bold"
			>
				What is the commission structure?
			</h3>
			<p v-if="WHITELABEL !== 'bainal'">
				The commission starts at <strong>{{ commission.value }}</strong> of standard fees <span v-if="!commissionSwift.hidden">(except for <strong>{{ commissionSwift.value }}</strong> for SWIFT transactions)</span> that a referred customer has paid for Verifo services <span v-if="!markup.hidden">and <strong>{{ markup.value }}</strong> for any markup on top of our buy rates provided</span>, as per our referral agreement. You can track your progress and success on your personal affiliate dashboard.
			</p>
			<p>You are eligible to receive a commission for every customer who has registered with {{ siteName }} through your affiliate link. There's no cap on how many referrals you can make or how much recurring revenue you can earn! Your success is our success, and we want you to strive with us. </p>
		</div>
		<div>
			<h3 class="mb-3 mb-lg-4 font-weight-bold">
				How does the tracking work?
			</h3>
                

			<p>Tracking helps identify affiliates and reward them accordingly. You can find your unique tracking link at the top of your personal affiliate dashboard page. Use it to share it across all your networks.</p>

			<p v-if="WHITELABEL == 'verifo'">
				When an individual clicks on your link and comes from the verifo.com website, our system automatically identifies them as your referrals.
			</p>

			<p>You can use the {{ siteName }} Partner Platform to see and track the progress of your referrals. If they become a {{ siteName }} customer, you will also be able to keep track of your recurring commission payments. <em>The commission dashboard is updated every month.</em></p>
		</div>
	</div>
</template>
<script>import config, { siteName } from '~/config';

export default {
	name: 'AffiliateProgramme',
	data() {
		return {
			WHITELABEL,
			siteName: siteName,
			commission: config.referral.commission,
			commissionSwift: config.referral.commissionSwift,
			markup: config.referral.markup
		};
	}
};
</script>
<style lang="scss" scoped src="./AffiliateProgramme.scss">
</style>