import {
	REGISTER_LOADING,
	REGISTER_SUCCESS,
	REGISTER_FAILURE,
	CLEAR_REGISTER_ERRORS,
	SET_REGISTER_ACCOUNT_ROLE,
	REGISTER_CHANGE_EMAIL,
	UPDATE_LOGIN_INFO_CODE,
	UPDATE_LOGIN_INFO_PASSWORD,
	UPDATE_LOGIN_INFO_PASSWORD_CONFIRM,
	SET_REGISTER_DATA,
	SET_REGISTER_AUTHENTICATOR
} from '~/store/mutation-types';

export default {
	[REGISTER_LOADING] (state, payload) {
		state.status.loading = payload;
	},
	[REGISTER_SUCCESS] (state, payload) {
		state.email = payload.email;
		state.accountType = payload.accountType;
		state.showForm = 0;
		state.showSuccess = 1;
		state.status.loading = false;
	},
	[REGISTER_FAILURE] (state) {
		state.status.loading = false;
	},
	[CLEAR_REGISTER_ERRORS] (state) {
		state.errors.email = null;
		state.errors.recaptchaResponse = null;
	},
	[REGISTER_CHANGE_EMAIL] (state) {
		state.showForm = 1;
		state.showSuccess = 0;
		state.status.loading = false;
	},
	[UPDATE_LOGIN_INFO_CODE] (state, payload) {
		state.code = payload.value;
	},
	[UPDATE_LOGIN_INFO_PASSWORD] (state, payload) {
		state.password = payload;
	},
	[UPDATE_LOGIN_INFO_PASSWORD_CONFIRM] (state, payload) {
		state.passwordConfirmation = payload.value;
	},
	[SET_REGISTER_ACCOUNT_ROLE](state, payload) {
		state.role = payload;
	},
	[SET_REGISTER_DATA](state, payload) {
		state.registerId = payload.id;
		state.authenticator = payload.authenticator;
		state.authenticatorOptions = payload.availableAuthenticatorList;
	},
	[SET_REGISTER_AUTHENTICATOR](state, payload) {
		state.authenticator = payload;
	}
};