var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.iframeLoaded && _vm.isDesktop ? _c('iframe', {
    staticStyle: {
      "width": "100%",
      "height": "800px",
      "border": "none"
    },
    attrs: {
      "id": "iframe",
      "allow": "camera;microphone",
      "src": _vm.iframeUrl
    }
  }) : _vm._e(), _vm.iframeLoaded && !_vm.isDesktop ? _c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "verify-photo__notification"
  }, [_c('ion-icon', {
    attrs: {
      "name": "camera-outline"
    }
  }), _c('span', [_vm._v("Press on a button to finish your verification.")])], 1), _c('div', [_c('b-button', {
    staticClass: "mt-4 mb-4",
    attrs: {
      "variant": "primary",
      "href": _vm.iframeUrl,
      "target": "_blank"
    }
  }, [_vm._v(" Verify documents and photo ")])], 1)]) : _vm._e(), _vm.error ? _c('div', {
    staticClass: "alert alert-danger"
  }, [_c('span', [_vm._v("Link not found")])]) : _vm._e(), _vm.success ? _c('div', {
    staticClass: "alert alert-success"
  }, [_c('span', [_vm._v("Identification successful")])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }