var render = function () {
  var _vm$authenticatorProp;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-container', [_c('b-row', [_c('b-col', [_c('div', {
    staticClass: "page-title-container"
  }, [_c('h2', [_vm._v("Two-factor Authentication")])])])], 1), _vm.step === 0 ? _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', {
    staticClass: "mb-3"
  }, [_c('b-row', [_c('b-col', [_c('p', [_vm._v("Two-factor authentication is an extra layer of security. It requires two separate, distinct forms of identification to access " + _vm._s(_vm.siteName) + " internet banking. Current method of two-factor authentication for your account is "), _c('strong', [_vm._v(_vm._s(_vm.currentTitle))]), _vm._v(".")]), _c('p', [_vm._v("Both SMS and Google authentication methods provide a high level of protection for your account. Although, we recommend using Google Authenticator. It is fast and does not require internet connection.")])]), _c('b-col', [_c('div', {
    staticClass: "image-wrapper"
  }, [_c('div', {
    staticClass: "image-wrapper__layer image-wrapper__layer--1st"
  }, [_c('span', {
    staticClass: "image-password"
  }, [_vm._v("Your password")]), _c('div', {
    staticClass: "image-wrapper__layer image-wrapper__layer--2nd"
  }, [_c('strong', {
    staticClass: "image-2step"
  }, [_vm._v("2-Step verification")]), _c('div', {
    staticClass: "image-wrapper__layer image-wrapper__layer--3rd"
  }, [_c('span', {
    staticClass: "image-data"
  }, [_vm._v("Your account data")])])])])])])], 1)], 1), _c('b-card', [_c('b-row', [_c('b-col', [_c('h3', {
    staticClass: "authenticator-subtitle"
  }, [_vm._v(" How does it work? ")])])], 1), _vm._l(_vm.shownOptions, function (option, i) {
    return _c('b-row', {
      key: i,
      staticClass: "my-3"
    }, [_c('b-col', [_c('strong', [_vm._v(_vm._s(option.title))]), _c('b-row', [_c('b-col', [_c('p', {
      domProps: {
        "innerHTML": _vm._s(option.description)
      }
    })]), option.value === 'google' ? _c('b-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('div', {
      staticClass: "authenticator-appstores mb-3"
    }, [_c('a', {
      attrs: {
        "href": "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en",
        "target": "_blank"
      }
    }, [_c('img', {
      attrs: {
        "alt": "Get it on Google Play",
        "src": "/images/google-play-store.svg"
      }
    })]), _c('a', {
      attrs: {
        "href": "https://apps.apple.com/us/app/google-authenticator/id388497605",
        "target": "_blank"
      }
    }, [_c('img', {
      attrs: {
        "src": "/images/apple-store.svg",
        "alt": "apple store"
      }
    })])])]) : _vm._e()], 1), _c('b-button', {
      staticClass: "mt-1",
      attrs: {
        "variant": "primary",
        "disabled": _vm.currentValue == option.value
      },
      on: {
        "click": function click($event) {
          return _vm.select(option.value);
        }
      }
    }, [option.iconClass ? _c('span', [_c('i', {
      staticClass: "mr-3",
      "class": option.iconClass
    })]) : _vm._e(), _c('span', {
      directives: [{
        name: "new-badge",
        rawName: "v-new-badge",
        value: {
          expire: '2021-06-28',
          hidden: option.value !== 'google',
          id: 'gauth'
        },
        expression: "{expire: '2021-06-28', hidden: option.value !== 'google', id: 'gauth'}"
      }]
    }, [_vm._v(_vm._s(option.title))])])], 1)], 1);
  })], 2)], 1), _vm.currentValue == 'google' ? _c('b-col', {
    staticClass: "mt-3 text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', {
    staticClass: "text-center"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.regenerateGoogle();
      }
    }
  }, [_vm._v(" Regenerate google recovery codes ")]), _vm.google.recoveryCodes && _vm.google.recoveryCodes.length > 0 ? _c('div', {
    staticClass: "mt-3"
  }, [_c('div', {
    staticClass: "mb-3"
  }, [_vm._v(" Copy or download recovery codes. You won't be able to see it after leaving this page. ")]), _c('google-recovery-codes', {
    attrs: {
      "codes": _vm.google.recoveryCodes,
      "loading": _vm.loading
    }
  })], 1) : _vm._e()], 1)], 1) : _vm._e()], 1) : _vm._e(), _vm.step === 1 ? _c('b-row', {
    staticClass: "mb-3 text-center"
  }, [_c('b-col', [_c('h3', [_vm._v(_vm._s(_vm.currentSelectionTitle))])])], 1) : _vm._e(), _vm.step === 1 ? _c('b-row', {
    attrs: {
      "align-v": "stretch"
    }
  }, [_vm.selection == 'google' ? _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "cols": "12",
      "md": !_vm.google.manual ? 'auto' : '12'
    }
  }, [_c('b-card', {
    staticClass: "text-center card-content"
  }, [_c('b-card-body', [_c('b-row', [!_vm.google.manual ? _c('b-col', {
    attrs: {
      "align-self": "center"
    }
  }, [_c('div', {
    staticClass: "qr-wrapper"
  }, [!_vm.loading ? _c('div', [_vm.google.qrImage ? _c('img', {
    attrs: {
      "src": _vm.google.qrImage,
      "alt": "Google QR"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "mt-3"
  }, [_c('div', {
    staticClass: "mb-3"
  }, [_vm._v(" Unable to scan QR code? ")]), _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.toggleGoogleManual
    }
  }, [_vm._v(" Click here ")])], 1)]) : _vm._e(), !_vm.google.qrImage || _vm.loading ? _c('b-spinner') : _vm._e()], 1)]) : _vm._e(), _vm.google.manual ? _c('b-col', [_c('div', {
    staticClass: "mb-3 text-left"
  }, [_c('ol', [_c('li', [_vm._v(" Download the Google Authenticator app from "), _c('a', {
    attrs: {
      "href": "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en",
      "target": "_blank"
    }
  }, [_vm._v("Play Store")]), _vm._v(" or "), _c('a', {
    attrs: {
      "href": "https://apps.apple.com/us/app/google-authenticator/id388497605",
      "target": "_blank"
    }
  }, [_vm._v("App Store")]), _vm._v(". ")]), _c('li', [_vm._v(" Open the app and click “+” at the bottom of your smartphone screen. ")]), _c('li', [_vm._v(" Select “Enter a setup key”. ")]), _c('li', [_vm._v(" Fill in the following fields and click “Add”. ")]), _c('li', [_vm._v(" The app will generate a 6-figure code for your " + _vm._s(_vm.siteName) + " account. ")]), _c('li', [_vm._v(" Enter the code from Google Authenticator. ")])]), _c('div', {
    staticClass: "text-center"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.toggleGoogleManual
    }
  }, [_vm._v(" Show QR code ")])], 1)])]) : _vm._e(), _vm.google.manual ? _c('b-col', [_c('div', {
    staticClass: "mb-3"
  }, [_c('div', {
    staticClass: "mb-2"
  }, [_c('small', [_vm._v("Account name:")]), _c('br'), _c('strong', [_vm._v(_vm._s(_vm.owner.email))])]), _c('div', {
    staticClass: "mb-2"
  }, [_c('small', [_vm._v("Your key:")]), _c('br'), !_vm.loading ? _c('strong', [_vm._v(_vm._s(_vm.google.authenticatorCode))]) : _vm._e(), _vm.loading ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "mb-2"
  }, [_c('small', [_vm._v("Time based:")]), _c('br'), _c('strong', [_vm._v("Yes")])])]), _c('div', [_c('b-button', {
    attrs: {
      "variant": "outline-primary",
      "target": "_blank",
      "href": "https://support.google.com/accounts/answer/1066447"
    }
  }, [_c('i', {
    staticClass: "fab fa-google mr-2"
  }), _vm._v(" Support ")])], 1)]) : _vm._e()], 1)], 1)], 1)], 1) : _vm._e(), _c('b-col', {
    staticClass: "mb-3"
  }, [_c('b-card', {
    staticClass: "card-content card-content--code",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-row', {
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', [_c('div', {
    staticClass: "mb-3"
  }, [_vm.selection !== 'google' ? _c('div', {
    staticClass: "text-center"
  }, [_vm._v(" Enter code from " + _vm._s(_vm.AuthenticatorHelper.name[_vm.selection]) + " "), (_vm$authenticatorProp = _vm.authenticatorProperties) !== null && _vm$authenticatorProp !== void 0 && _vm$authenticatorProp.phoneNumber ? _c('span', [_vm._v(" sent to " + _vm._s(_vm.authenticatorProperties.phoneNumber))]) : _vm._e()]) : _vm._e(), _vm.selection == 'google' && !_vm.google.manual ? _c('div', [_c('ol', [_c('li', [_vm._v(" Download the Google Authenticator app from "), _c('a', {
    attrs: {
      "href": "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en",
      "target": "_blank"
    }
  }, [_vm._v("Play Store")]), _vm._v(" or "), _c('a', {
    attrs: {
      "href": "https://apps.apple.com/us/app/google-authenticator/id388497605",
      "target": "_blank"
    }
  }, [_vm._v("App Store")]), _vm._v(". ")]), _c('li', [_vm._v(" Open the app and click “+” at the bottom of your smartphone screen. ")]), _c('li', [_vm._v(" Scan the QR code on this screen. ")]), _c('li', [_vm._v(" The app will generate a 6-figure code for your " + _vm._s(_vm.siteName) + " account. ")]), _c('li', [_vm._v(" Enter the code from Google Authenticator. ")])])]) : _vm._e()]), _c('CodeInput', {
    ref: "code",
    attrs: {
      "name": "code",
      "digits": 6,
      "disabled": _vm.loading
    },
    model: {
      value: _vm.code,
      callback: function callback($$v) {
        _vm.code = $$v;
      },
      expression: "code"
    }
  })], 1)], 1), _c('div', {
    staticClass: "mt-3"
  }, [_vm.selection !== 'google' ? _c('div', {
    staticClass: "text-center"
  }, [_vm._v(" Did not get " + _vm._s(_vm.AuthenticatorHelper.name[_vm.selection]) + " with confirmation code? ")]) : _vm._e(), _vm.selection == 'google' ? _c('div', {
    staticClass: "text-center"
  }, [_vm._v(" If the code did not work, click refresh and repeat the process. In an unlikely event that the problem persists, please contact our support team: "), _c('a', {
    attrs: {
      "href": 'mailto:' + _vm.supportEmail,
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.supportEmail))]), _vm._v(". ")]) : _vm._e()]), _c('div', {
    staticClass: "mt-3 text-center"
  }, [_c('b-button', {
    staticClass: "mx-1",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.select(_vm.selection);
      }
    }
  }, [_vm.selection !== 'google' ? _c('span', [_vm._v("Resend")]) : _vm._e(), _vm.selection == 'google' ? _c('span', [_vm._v("Refresh")]) : _vm._e()]), _c('b-button', {
    staticClass: "mx-1",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.restart
    }
  }, [_vm._v(" Cancel ")])], 1)], 1)], 1)], 1) : _vm._e(), _vm.step === 3 ? _c('b-row', [_c('b-col', [_c('b-card', {
    staticClass: "text-center mb-3"
  }, [_c('div', [_c('span', [_vm._v(" Authenticator successfully changed to "), _c('strong', [_vm._v(_vm._s(_vm.currentSelectionTitle))])])]), _vm.selection == 'google' ? _c('div', [_c('div', {
    staticClass: "mt-3 mb-3"
  }, [_c('span', [_vm._v("Copy or download recovery codes. You won't be able to see it after leaving this page.")])]), _c('google-recovery-codes', {
    attrs: {
      "codes": _vm.google.recoveryCodes,
      "loading": _vm.loading
    }
  })], 1) : _vm._e()]), _c('div', {
    staticClass: "text-center"
  }, [_c('b-button', {
    staticClass: "mx-1",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.restart
    }
  }, [_vm._v(" Back ")])], 1)], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }