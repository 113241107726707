<template>
	<div class="nano-transfer">
		<b-row v-show="currentStep == 1">
			<b-col
				xl="7"
				cols="12"
				class="mb-3"
			>
				<b-card>
					<transfer-form 
						ref="transferForm" 
						:form-fields="formFields" 
						:loading="loading" 
						data-vv-scope="form"
						@change="formChange"
						@onBlur="onBlur"
					/>
				</b-card>
				<b-row>
					<b-col>
						<b-collapse
							id="swift-message"
							visible
						>
							<div
								class="nano-transfer__notification"
								:class="swiftMessageClass"
							>
								<span v-if="!access.swift">
									<ion-icon
										name="globe-outline"
									/>
									<span>
										SWIFT (International) payments disabled.
										<a
											:href="`mailto:${supportEmail}?subject=Request Swift`"
											target="_blank"
										>
											<strong>Request swift</strong>
										</a>
									</span>
								</span>
								<span v-if="access.swift">
									<ion-icon name="globe-outline" />
									<span>SWIFT (International) payments enabled.</span>
								</span>
								<span v-b-toggle.swift-message>
									<ion-icon name="close-outline" />
								</span>
							</div>
						</b-collapse>
					</b-col>
				</b-row>
			</b-col>
			<b-col
				xl="5"
				cols="12"
				class="mb-3"
			>
				<b-card>
					<b-row>
						<b-col>
							<span>Amount:</span>
						</b-col>
						<b-col class="text-right">
							<span>{{ formData.amount ? parseFloat(formData.amount).toFixed(2) : '0.00' }} {{ formData.currencyId && formData.currencyId.toUpperCase() }}</span>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<span>Transaction fee:</span>
						</b-col>
						<b-col class="text-right">
							<span>{{ fee || '0.00' }} {{ formData.currencyId && formData.currencyId.toUpperCase() }}</span>
							<div v-if="fee && currentFeeWalletAmount">
								<b-badge variant="primary">
									Uses fee wallet
								</b-badge>
							</div>
						</b-col>
					</b-row>
					<b-row
						v-if="!fee"
						class="mt-2"
					>
						<b-col>
							<span class="notes">
								{{ notes }}
							</span>
						</b-col>
					</b-row>
					<hr>
					<b-row>
						<b-col>
							<strong>Estimated total:</strong>
						</b-col>
						<b-col class="text-right">
							<strong>{{ estimatedTotal }} {{ formData.currencyId && formData.currencyId.toUpperCase() }}</strong>
						</b-col>
					</b-row>
					<b-row v-if="insufficientFunds">
						<b-col class="text-right">
							<b-badge variant="danger">
								Insufficient Funds
							</b-badge>
						</b-col>
					</b-row>
				</b-card>
				<template v-if="user.role === 'user'">
					<b-row class="mt-3">
						<b-col class="col-12">
							<button
								v-if="isWalletGranted('approve_transaction', currentWallet.walletId)"
								:disabled="loading || insufficientFunds || fee == null
									|| (typeData?.serviceId == 'nano_swift_transfer' && !access.swift)"
								class="btn btn-primary btn-block"
								@click="startTransfer('approve')"
							>
								{{ loading === true ? 'Loading...' : 'Next' }}
							</button>
						</b-col>
					</b-row>
				</template>
				<template v-else>
					<b-row v-if="isWalletGranted('initiate_transaction', currentWallet.walletId)"
						class="mt-3"
					>
						<b-col>
							<button
								:disabled="loading || insufficientFunds || fee == null
									|| (typeData?.serviceId == 'nano_swift_transfer' && !access.swift)"
								class="btn btn-outline-primary btn-block"
								@click="startTransfer('initiate')"
							>
								{{ loading === true ? 'Loading...' : 'Save for confirmation' }}
							</button>
						</b-col>
					</b-row>
					<b-row v-if="isWalletGranted('approve_transaction', currentWallet.walletId)"
						class="mt-3"
					>
						<b-col>
							<button
								:disabled="loading || insufficientFunds || fee == null
									|| (typeData?.serviceId == 'nano_swift_transfer' && !access.swift)"
								class="btn btn-primary btn-block"
								@click="startTransfer('approve')"
							>
								{{ loading === true ? 'Loading...' : 'Next' }}
							</button>
						</b-col>
					</b-row>
				</template>
			</b-col>
		</b-row>
		<div v-if="currentStep == 2">
			<b-card>
				<b-row class="form-group mb-lg-0 pt-3">
					<label class="col-lg-5 col-xl-3 col-form-label">Wallet send from:</label>
					<b-col 
						lg="7" 
						xl="6" 
						class="font-weight-bold"
					>
						{{ walletId }} (Balance {{ currentWalletAmount.balance }} {{ formData.currencyId.toUpperCase() }} )
					</b-col>
				</b-row>

				<b-row class="form-group mb-lg-0">
					<label class="col-lg-5 col-xl-3 col-form-label">Wallet send to:</label>
					<b-col 
						lg="7" 
						xl="6" 
						class="font-weight-bold"
					>
						{{ formData.accountNumber }}
					</b-col>
				</b-row>

				<b-row 
					v-show="!!formData.name" 
					class="form-group mb-lg-0"
				>
					<label class="col-lg-5 col-xl-3 col-form-label">Recipient name:</label>
					<b-col 
						lg="7" 
						xl="6" 
						class="font-weight-bold"
					>
						{{ formData.name }} {{ formData.surname }}
					</b-col>
				</b-row>

				<b-row 
					v-show="formData.companyName !== ''" 
					class="form-group mb-lg-0"
				>
					<label class="col-lg-5 col-xl-3 col-form-label">Recipient:</label>
					<b-col 
						lg="7" 
						xl="6" 
						class="font-weight-bold"
					>
						{{ formData.companyName }}
					</b-col>
				</b-row>

				<b-row class="form-group mb-lg-0">
					<label class="col-lg-5 col-xl-3 col-form-label">Amount:</label>
					<b-col 
						lg="7" 
						xl="6" 
						class="font-weight-bold"
					>
						{{ formData.amount }} {{ formData.currencyId.toUpperCase() }}
					</b-col>
				</b-row>

				<b-row class="form-group mb-4">
					<label class="col-lg-5 col-xl-3 col-form-label">Description:</label>
					<b-col 
						lg="7" 
						xl="6" 
						class="font-weight-bold"
					>
						{{ formData.details }}
					</b-col>
				</b-row>
			</b-card>
			<b-row class="mt-3">
				<b-col>
					<div class="card bg-white mb-4 mb-lg-5">
						<div class="card-body py-4 py-sm-5">
							<authenticator
								v-if="isWalletGranted('approve_transaction', currentWallet.walletId) && transferAction === 'approve'"
								ref="sms"
								:data="formData"
								:resend-data="authenticatorData"
								:show-back-button="false"
								:on-back="handleBack"
								:on-submit="submit"
								type="transfer"
								button-text="Confirm transfer"
							/>
							<authenticator
								v-if="isWalletGranted('initiate_transaction', currentWallet.walletId) && transferAction === 'initiate'"
								ref="sms"
								:data="formData"
								:resend-data="authenticatorData"
								:show-back-button="false"
								:on-back="handleBack"
								:on-submit="submitInitiate"
								type="transfer"
								button-text="Save for confirmation"
							/>
						</div>
					</div>
				</b-col>
			</b-row>
			<b-row>
				<b-col 
					class="text-center"
				>
					<input 
						type="button" 
						class="btn btn-outline-dark px-5" 
						value="Edit transfer" 
						@click="handleBack"
					/>
				</b-col>
			</b-row>
		</div>
		<div 
			v-if="currentStep === 3" 
			class="justify-content-md-center"
		>
			<div class="text-center py-4">
				<h3 class="text-success mb-4">
					Your transfer #{{ transactionId }} has been sent
				</h3>
				<router-link 
					:to="{ name: 'transactions' }" 
					class="btn btn-outline-dark px-5" 
					exact
				>
					Check transactions
				</router-link>
			</div>
		</div>
		<div
			v-if="currentStep === 4"
			class="justify-content-md-center"
		>
			<div class="text-center py-4">
				<h3 class="text-success mb-4">
					Your transfer saved for confirmation later
				</h3>
				<div
					class="btn btn-outline-dark px-5"
					@click="goToPending"
				>
					Check for confirmation
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import TransferForm from './TransferForm';
import fieldsNano from './FieldsData/fieldsNano';
import debounce from 'lodash/debounce';
import { endpoints, supportEmail } from '~/config';
import { mapActions, mapGetters, mapState } from 'vuex';
import Authenticator from '@/shared/Authenticator';

export default {
	name: 'NanoTransfer',
	components: {
		Authenticator,
		TransferForm,
	},
	props: {
		transferData: {
			type: Object,
			default: () => ({}),
			required: false,
		},
		transactionTemplate: {
			type: Object,
			default: null,
			required: false,
		},
	},
	data() {
		return {
			formFields: [],
			formData: {},
			typeData: {},
			loading: false,
			transactionType: null,
			notes: 'Transaction fee will be estimated after filling in the payment details.',
			fee: null,
			currentStep: 1,
			transactionId: null,
			swiftMessageClass: '',
			supportEmail,
			serviceId: null,
			transferAction: null,
		};
	},
	computed: {
		...mapState('wallet', {
			wallet: (state) => state,
		}),
		...mapGetters('wallet', [
			'provider',
			'currentWallet',
			'currentWalletAmount',
			'currentFeeWalletAmount',
		]),
		...mapGetters('user', ['isGranted', 'isWalletGranted']),
		...mapState({
			user: (state) => state.user.selected,
		}),
		access: function () {
			return {
				swift:
					this.isGranted('front_post_wallet.index.active-nano.:id.transaction.transfer-nano-swift') &&
					this.provider &&
					this.provider === 'nano',
			};
		},

		walletId() {
			let id = null;
			if (this.currentWallet) {
				id = this.currentWallet.walletId;
			}
			return id;
		},
		estimatedTotal() {
			return this.formData.amount
				? (
					parseFloat(this.formData.amount) + parseFloat(this.fee || 0)
				).toFixed(2)
				: (0).toFixed(2);
		},
		insufficientFunds() {
			if (this.currentFeeWalletAmount) {
				return (
					this.currentWallet &&
					(parseFloat(this.currentWalletAmount.balance) <
						parseFloat(this.formData.amount) ||
						parseFloat(this.currentFeeWalletAmount.balance) <
							parseFloat(this.fee || 0).toFixed(2))
				);
			} else {
				return (
					this.currentWallet &&
					parseFloat(this.currentWalletAmount.balance) <
						this.estimatedTotal
				);
			}
		},
		authenticatorData() {
			return {
				walletId: this.walletId,
				amount: this.formData.amount,
				currencyId: this.formData.currencyId,
				beneficiaryAccountNumber: this.formData.accountNumber,
			};
		},
		submittedData() {
			return {
				beneficiaryRequest: {
					name: this.formData.companyName,
					countryId: this.formData.countryId,
					accountNumber: this.formData.accountNumber,
					address: this.formData.beneficiaryAddress,
					bankRequest: {
						name: this.formData.bankName,
						bic: this.formData.bic,
						address: this.formData.bankAddress,
						identifier: this.formData.bankIdentifier,
					},
				},
				amount: this.formData.amount,
				currencyId: this.formData.currencyId,
				details: this.formData.details,
				files: this.formData.files,
				transactionTemplateId: this.transactionTemplate?.id,
				templateName: this.formData.templateName,
				changeTemplate: this.formData.changeTemplate,
			};
		},
	},
	methods: {
		...mapActions('notifications', ['pushError']),
		...mapActions('auth', ['init2fa']),
		...mapActions('transfer', ['sepaPrepare', 'swiftPrepare']),
		formChange(value) {
			this.formData = { ...value };
		},
		handleBack() {
			this.currentStep = this.currentStep - 1;
		},
		async submit(code) {
			const url =
				this.typeData.serviceId == 'nano_swift_transfer' ? endpoints.transfer.nanoSwiftTransfer({ walletId: this.walletId }) : endpoints.transfer.sepa({ walletId: this.walletId });
			const options = {
				method: 'POST',
				url,
				data: {
					code,
					...this.submittedData,
				},
			};
			try {
				const response = await axios(options);
				const { data } = response;
				this.transactionId = data.id;
				this.handleSuccess();
				this.$refs.sms.reset();
			} catch (err) {
				this.pushError(err.message);
				this.$refs.sms.reset();
			}
		},
		async submitInitiate(code) {
			const url =
				this.typeData.serviceId == 'nano_swift_transfer' ? endpoints.transfer.initiateSwift({ walletId: this.walletId }) : endpoints.transfer.initiateSepa({ walletId: this.walletId });

			const options = {
				method: 'POST',
				url,
				data: {
					code,
					...this.submittedData,
				},
			};
			try {
				await axios(options);
				this.handleInitiationSuccess();
				this.$refs.sms.reset();
			} catch (err) {
				this.pushError(err.message);
				this.$refs.sms.reset();
			}
		},
		handleSuccess() {
			this.currentStep = 3;
			this.$store.dispatch('wallet/updateWalletList');
		},
		handleInitiationSuccess() {
			this.currentStep = 4;
		},
		async getType() {
			if (
				!this.formData.accountNumber ||
				(this.formData.accountNumber &&
					this.formData.accountNumber.length < 6)
			) {
				this.transactionType = null;
				this.setLockState(0);
				this.setFieldProperty('countryId', 'hidden', true);
				this.setFieldProperty('bic', 'hidden', true);
				this.setSwiftFieldsHidden(true);
				return;
			}
			if (
				this.formData.accountNumber &&
				this.formData.accountNumber.length >= 6
			) {
				this.loading = true;
				const accountNumber = this.formData.accountNumber.replace(
					/\s+/g,
					''
				);
				this.updateFormValue('accountNumber', accountNumber);

				const options = {
					method: 'GET',
					url: endpoints.transfer.type({ walletId: this.walletId }),
					params: {
						type: 'nano',
						currencyId: this.formData.currencyId,
						countryId: this.formData.countryId,
						accountNumber,
					},
				};

				try {
					const { data } = await axios(options);
					this.serviceId = data.serviceId;
					this.typeData = data;

					if (data.beneficiaryCountryId) {
						this.updateFormValue(
							'countryId',
							data.beneficiaryCountryId
						);
						this.setFieldProperty('countryId', 'hidden', false);
					} else {
						this.updateFormValue('countryId', null);
						this.setFieldProperty('countryId', 'hidden', true);
					}

					if (data.beneficiaryBic) {
						this.updateFormValue('bic', data.beneficiaryBic);
						this.setFieldProperty('bic', 'hidden', false);
					} else {
						this.updateFormValue(
							'bic',
							this.transferData?.beneficiary?.bank?.bic || null
						);
						this.setFieldProperty('bic', 'hidden', true);
					}

					if (
						data.serviceId !== 'inside' &&
						data.serviceId !== 'inner'
					) {
						this.setLockState(0);
						if (
							data.beneficiaryBic !== '' &&
							data.beneficiaryCountryId !== ''
						) {
							this.setLockState(1);
						}
					}

					if (!data.isIbanValid) {
						this.setLockState(0);
					}

					this.formFields.map((f) => {
						if (f.name == 'bic') f.hidden = data.isIbanValid;
						return f;
					});

					const { requiredFields } = data;
					Object.keys(requiredFields).forEach((name) => {
						const isRequired = requiredFields[name];
						if (!isRequired && this.formData[name])
							this.formData[name] = null;
						this.formFields = this.formFields.map((f) => {
							if (f.name == name) f.hidden = !isRequired;
							return f;
						});
					});

					const swiftTransfer =
						data.serviceId == 'nano_swift_transfer';
					this.swiftMessageClass =
						!this.access.swift && swiftTransfer ? 'alert-danger' : '';
					this.setSwiftFieldsHidden(!swiftTransfer);

					this.getFee();
				} catch (err) {
					this.pushError(err.message);
				}
				setTimeout(() => {
					this.loading = false;
				}, 200);
			}
		},
		setSwiftFieldsHidden(value) {
			[
				'beneficiaryAddress',
				'bankAddress',
				'bankName',
				'bankIdentifier',
			].forEach((name) => {
				if (value) this.formData[name] = null;
				this.formFields.map((f) => {
					if (f.name == name) f.hidden = value;
					return f;
				});
			});
		},
		getDebounceType: debounce(function () {
			this.getType();
		}, 200),
		getDebounceFee: debounce(function () {
			!!this.formData.amount &&
				this.updateFormValue('amount', parseFloat(this.formData.amount).toFixed(2));
			this.getFee();
		}, 200),

		async getFee() {
			const fd = this.formData;
			const td = this.typeData;
			if (fd.amount && fd.amount > 0 && fd.countryId && td.serviceId) {
				this.loading = true;
				const options = {
					method: 'GET',
					url: endpoints.transfer.fee({ walletId: this.walletId }),
					params: {
						serviceId: td.serviceId,
						direction: 'outgoing',
						currencyId: fd.currencyId,
						amount: parseFloat(fd.amount).toFixed(2),
						countryId: fd.countryId,
					},
				};

				try {
					const { data } = await axios(options);
					this.fee = data.amount;
				} catch (err) {
					const message = err.message;
					this.fee = null;
					if (err.fields) {
						Object.entries(err.fields).forEach(([field, msg]) => {
							this.errors.add({
								field,
								msg,
							});
						});
					}
					this.pushError(message);
				}
				this.loading = false;
			}
		},
		async startTransfer(transferAction = 'approve') {
			const result = await this.$validator.validate('*');
			if (result) {
				this.transferAction = transferAction;
				this.prepareTransfer();
			}
		},
		async prepare2fa() {
			try {
				await this.init2fa({
					type: 'transfer',
					data: {
						walletId: this.walletId,
						amount: this.formData.amount,
						currencyId: this.formData.currencyId,
						beneficiaryAccountNumber: this.formData.accountNumber,
					},
				});
				this.currentStep = 2;
				setTimeout(() => {
					this.loading = false;
				}, 200);
			} catch (err) {
				//
			}
			setTimeout(() => {
				this.loading = false;
			}, 150);
		},
		async prepareTransfer() {
			this.loading = true;
			try {
				if (this.serviceId !== 'nano_swift_transfer') {
					await this.sepaPrepare({
						data: this.submittedData,
						walletId: this.walletId,
					});
				} else {
					await this.swiftPrepare({
						data: this.submittedData,
						walletId: this.walletId,
					});
				}
				await this.prepare2fa();
			} catch (err) {
				this.pushError(err?.message);
			}
			this.loading = false;
		},

		onBlur(name) {
			if (name == 'accountNumber') this.getDebounceType();
			if (name == 'amount') this.getDebounceFee();
		},
		updateFormValue(name, value, fields) {
			this.$refs.transferForm.updateFormValue(name, value, fields);
		},
		setLockState(value) {
			if (this.$refs.transferForm)
				this.$refs.transferForm.lockState = value;
		},
		setFieldProperty(name, key, value) {
			this.formFields = this.formFields.map((f) => {
				if (f.name == name) f[key] = value;
				return f;
			});
		},
		goToPending() {
			this.$emit('go-to-pending');
		},
		changeTemplate() {
			this.setFieldProperty(
				'templateName',
				'hidden',
				!this.formData.changeTemplate
			);
		},
	},
	watch: {
		formData: {
			handler: function (nVal, oVal) {
				function haveChanged(key) {
					return nVal[key] !== oVal[key];
				}
				if (haveChanged('countryId')) this.getDebounceType();
				if (haveChanged('changeTemplate')) this.changeTemplate();
			},
			deep: true,
		},
	},
	mounted() {
		this.formFields = fieldsNano;
		this.getDebounceType();

		if (this.user.role === 'user') {
			this.setFieldProperty('changeTemplate', 'hidden', true);
		}

		this.updateFormValue('changeTemplate', false, fieldsNano);
		this.setFieldProperty('changeTemplate', 'label', 'Add to my templates');

		//Copy payment
		if (this.transactionTemplate) {
			const td = this.transactionTemplate.transactionData;
			this.updateFormValue(
				'accountNumber',
				td.beneficiaryRequest?.accountNumber,
				fieldsNano
			);
			this.updateFormValue(
				'companyName',
				td.beneficiaryRequest?.name,
				fieldsNano
			);

			this.updateFormValue(
				'countryId',
				td.beneficiaryRequest?.countryId,
				fieldsNano
			);
			this.updateFormValue(
				'bic',
				td.beneficiaryRequest?.bankRequest?.bic,
				fieldsNano
			);
			this.updateFormValue(
				'bankAddress',
				td.beneficiaryRequest?.bankRequest?.address,
				fieldsNano
			);
			this.updateFormValue(
				'bankName',
				td.beneficiaryRequest?.bankRequest?.name,
				fieldsNano
			);
			this.updateFormValue(
				'bankIdentifier',
				td.beneficiaryRequest?.bankRequest?.identifier,
				fieldsNano
			);

			this.updateFormValue(
				'beneficiaryAddress',
				td.beneficiaryRequest?.address,
				fieldsNano
			);

			this.updateFormValue('amount', td.amount, fieldsNano);
			this.updateFormValue('details', td.details, fieldsNano);
			this.updateFormValue(
				'templateName',
				this.transactionTemplate.name,
				fieldsNano
			);
			this.setFieldProperty('changeTemplate', 'label', 'Change template');
		} else if (this.transferData.id) {
			const td = this.transferData;
			if (td.serviceId.includes('nano')) {
				this.updateFormValue(
					'accountNumber',
					td.beneficiary?.accountNumber,
					fieldsNano
				);
				this.updateFormValue(
					'companyName',
					td.beneficiary?.accountHolder,
					fieldsNano
				);

				this.updateFormValue('countryId', td.countryId, fieldsNano);

				this.updateFormValue(
					'bic',
					td.beneficiary?.bank?.bic,
					fieldsNano
				);
				this.updateFormValue(
					'bankAddress',
					td.beneficiary?.bank?.address,
					fieldsNano
				);
				this.updateFormValue(
					'bankName',
					td.beneficiary?.bank?.name,
					fieldsNano
				);
				this.updateFormValue(
					'bankIdentifier',
					td.beneficiary?.bank?.identifier,
					fieldsNano
				);

				this.updateFormValue(
					'beneficiaryAddress',
					td.beneficiary?.address?.address,
					fieldsNano
				);

				this.updateFormValue('amount', td.amount, fieldsNano);
				this.updateFormValue('details', td.details, fieldsNano);
			}
		}
	},
};
</script>
<style lang="scss" scoped>
.nano-transfer {
	&__notification {
		background-color: #e3f3f4;
		padding: 16px 20px;
		display: flex;
		align-items: center;
		margin-top: 20px;
		border-radius: 3px;
		justify-content: space-between;

		> span {
			display: flex;
			align-items: center;
		}

		ion-icon {
			color: $primary;
			margin-right: 10px;
		}

		&.alert-danger {
			background-color: #f4dddd;
		}
		&.alert-danger ion-icon {
			color: #491217;
		}
		&.alert-danger a {
			color: #491217;
		}
	}
}
</style>