<template>
	<div id="profile-accounts">
		<div>
			<modal 
				:width="'40%'" 
				:height="'275px'" 
				name="deleteAcc"
			>
				<div class="faq-modal">
					<span 
						class="close-btn" 
						@click="$modal.hide('deleteAcc')"
					>&times;</span>
					<div class="modalw-content">
						<p>Sorry, you can only delete an empty account!</p>
						<div class="text-center buttons">
							<button
								class="white text-uppercase"
								@click="$modal.hide('deleteAcc')"
							>
								Cancel
							</button>
							<button
								class="text-uppercase"
								@click="$modal.hide('deleteAcc')"
							>
								Transfer funds
							</button>
						</div>
					</div>
				</div>
			</modal>
		</div>
		<div class="account-settings-list">
			<account-wallet-item
				v-for="account in walletList"
				:key="account.wallet_id"
				:total="walletList.length"
				:data="account"
			/>
		</div>
	</div>
</template>

<script>
import AccountWalletItem from './AccountWalletItem.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
export default {
	name: 'AccountWalletNameSettings',
	components: {
		AccountWalletItem
	},
	data() {
		return {
			step: 1,
			currency_id: 'eur',
			buttonAddClicked: false
		};
	},
	computed: {
		...mapGetters('user', [
			'defaultCurrency'
		]),
		...mapGetters('wallet', [
			'walletCurrenciesOptions'
		]),
		...mapGetters([
			'currenciesOptions',
		]),
		...mapState('wallet', {
			walletList: state => state.list
		}),
		...mapState({
			nextWalletId: state => state.wallet.nextWalletId,
			showForm: state => state.wallet.create.showForm,
			showSuccess: state => state.wallet.create.showSuccess,
			user: state => state.authUser
		})
	},
	mounted() {
		if (this.$route.params.o) {
			this.step = 2;
		}
		this.resetWalletCreate();
	},
	created() {
		this.$store.dispatch('getCurrencyList').then(() => {
			this.currency_id = this.defaultCurrency;
		});
	},
	methods: {
		...mapActions('wallet', [
			'resetWalletCreate'
		]),
		create() {
			this.buttonAddClicked = true;
			this.$store
				.dispatch('wallet/createWallet', {
					currency_id: this.currency_id
				})
				.then(() => {
					this.buttonAddClicked = false;
				})
				.catch(error => {
					throw new Error(error);
				});
		},
		showNewForm() {
			this.step = 2;
			this.$store.dispatch('wallet/resetWalletCreate');
		}
	},
};
</script>
<style lang="scss">
	@import './AccountWalletNameSettings.scss';
</style>