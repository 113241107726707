var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "layout-bank"
    }
  }, [_c('idle-notification'), _c('div', {
    staticClass: "main-content-wrapper"
  }, [_c('div', {
    staticClass: "sidebar-spacer"
  }, [_c('sidebar')], 1), _c('main', {
    staticClass: "main"
  }, [_c('transition', {
    attrs: {
      "name": "fade",
      "mode": "out-in"
    }
  }, [_c('router-view', {
    key: _vm.refreshKey
  })], 1)], 1), _c('popups')], 1), _c('agreements', {
    ref: "agreements",
    attrs: {
      "on-close": function onClose() {
        return _vm.$router.push({
          name: 'login'
        });
      },
      "close-label": "Logout"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }