var render = function () {
  var _vm$selectedPropertie;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "auth-card"
  }, [!_vm.expiredRoute ? _c('h1', {
    staticClass: "auth-title"
  }, [_vm._v(" Two-Factor Authentication ")]) : _c('h1', {
    staticClass: "auth-title"
  }, [_vm._v(" Session expired ")]), _vm.showCodeInput ? _c('div', {
    staticClass: "code-wrap"
  }, [_c('label', {
    staticClass: "d-block",
    attrs: {
      "for": "code"
    }
  }, [_vm._v(" Code from " + _vm._s(_vm.AuthenticatorHelper.name[_vm.currentAuthenticator]) + " "), _vm.phoneNumberUsed && (_vm$selectedPropertie = _vm.selectedProperties) !== null && _vm$selectedPropertie !== void 0 && _vm$selectedPropertie.phoneNumber ? _c('span', [_vm._v(" sent to " + _vm._s(_vm.selectedProperties.phoneNumber))]) : _vm._e()]), _c('CodeInput', {
    ref: "code",
    attrs: {
      "id": "code",
      "digits": 6,
      "disabled": _vm.loading,
      "numbers-only": _vm.phoneNumberUsed
    },
    on: {
      "keydown": function keydown($event) {
        _vm.error = false;
      }
    },
    model: {
      value: _vm.code,
      callback: function callback($$v) {
        _vm.code = $$v;
      },
      expression: "code"
    }
  }), !_vm.showSelector ? _c('div', {
    staticClass: "text-center text-muted"
  }, [_vm.phoneNumberUsed ? _c('span', [_vm._v("Did not get " + _vm._s(_vm.AuthenticatorHelper.name[_vm.currentAuthenticator]) + " with confirmation code?")]) : _vm._e(), !_vm.phoneNumberUsed ? _c('span', [_vm._v("Code did not work?")]) : _vm._e(), _c('span', {
    staticClass: "ml-1"
  }, [_vm.phoneNumberUsed ? _c('a', {
    attrs: {
      "href": "#",
      "target": "_self"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.resend.apply(null, arguments);
      }
    }
  }, [_vm._v("Resend")]) : _vm._e(), !_vm.phoneNumberUsed ? _c('a', {
    attrs: {
      "href": "#",
      "target": "_self"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.resend.apply(null, arguments);
      }
    }
  }, [_vm._v("Refresh")]) : _vm._e()])]) : _vm._e(), _vm.showSelector ? _c('div', {
    staticClass: "mt-4"
  }, [_vm.typeLoading ? _c('b-spinner') : _vm._e(), !_vm.typeLoading ? _c('authenticator-selector', {
    attrs: {
      "list": _vm.authenticatorList
    },
    on: {
      "onSelect": _vm.selectResendMethod
    }
  }) : _vm._e()], 1) : _vm._e(), _c('div', {
    staticClass: "row justify-content-md-center"
  }, [_c('div', {
    staticClass: "col-md-6 mt-3"
  }, [_c('button', {
    staticClass: "btn btn-primary btn-block",
    attrs: {
      "disabled": _vm.loading
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.userLogout.apply(null, arguments);
      }
    }
  }, [_vm.loading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "variant": "light",
      "type": "grow",
      "label": "Spinning"
    }
  }) : _vm._e(), !_vm.loading ? _c('span', [_vm._v("Back to login page")]) : _vm._e()], 1)])])], 1) : _vm._e(), _vm.showExpireSelection ? _c('div', [_c('div', {
    staticClass: "row justify-content-md-center"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('button', {
    staticClass: "btn btn-primary btn-block",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.verifyExpiration.apply(null, arguments);
      }
    }
  }, [_vm._v(" Verify by code from SMS ")])])]), _c('div', {
    staticClass: "row justify-content-md-center pt-4"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('button', {
    staticClass: "btn btn-primary btn-block",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.userLogout.apply(null, arguments);
      }
    }
  }, [_vm._v(" Logout ")])])])]) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }