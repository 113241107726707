var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    staticClass: " form-body",
    attrs: {
      "action": "#"
    }
  }, [!_vm.showAuthenticator ? [_c('notification'), _c('form-error-field', {
    attrs: {
      "name": "email"
    }
  }, [_c('label', {
    attrs: {
      "for": "email"
    }
  }, [_vm._v("Email Address")]), _c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|email',
      expression: "'required|email'"
    }],
    ref: "email",
    attrs: {
      "id": "email",
      "type": "text",
      "name": "email",
      "placeholder": "Email Address"
    },
    model: {
      value: _vm.data.email,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "email", $$v);
      },
      expression: "data.email"
    }
  })], 1), _c('phone-input', {
    model: {
      value: _vm.data.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "phone", $$v);
      },
      expression: "data.phone"
    }
  }), _c('b-row', {
    staticClass: "justify-content-center pt-4"
  }, [_c('b-col', {
    attrs: {
      "md": "8"
    }
  }, [_c('input', {
    staticClass: "btn btn-primary btn-block",
    attrs: {
      "type": "submit",
      "value": "Reset password"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.submit();
      }
    }
  })])], 1)] : _vm.showAuthenticator ? _c('div', [_c('authenticator', {
    attrs: {
      "data": _vm.data,
      "show-back-button": true,
      "on-back": _vm.handleBack,
      "on-error": _vm.handleError,
      "on-resend": _vm.handleResend,
      "type": "recover_password",
      "action": 'passwordRecovery/recoverPasswordConfirm'
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "pt-4 text-center"
  }, [_vm._v(" Go back to "), _c('router-link', {
    attrs: {
      "to": {
        name: 'login'
      },
      "exact": ""
    }
  }, [_vm._v(" Login ")])], 1), _c('vue-recaptcha', {
    ref: "recaptcha",
    attrs: {
      "sitekey": _vm.recapthaSitekey,
      "load-recaptcha-script": true,
      "size": "invisible"
    },
    on: {
      "verify": _vm.onVerify
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }