var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.showSteps() ? _c('div', {
    staticClass: "sidebar d-flex align-items-start flex-column"
  }, [_c('div', {
    staticClass: "steps-header w-100"
  }, [_c('b-row', {
    staticClass: "align-items-center justify-content-between"
  }, [_c('b-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('a', {
    staticClass: "logo",
    attrs: {
      "href": ""
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.$router.push({
          name: 'home'
        });
      }
    }
  })])], 1)], 1), _c('div', {
    staticClass: "steps-wrapper mb-auto w-100"
  }, [_c('ul', {
    staticClass: "steps director d-none d-lg-block"
  }, _vm._l(_vm.steps, function (step, key, index) {
    return _c('li', {
      key: key,
      "class": _vm.calculateComplete(key),
      on: {
        "click": function click($event) {
          _vm.calculateComplete(key) === 'complete' && _vm.$router.push({
            name: key
          });
        }
      }
    }, [_c('div', {
      staticClass: "circle"
    }, [_vm._v(" " + _vm._s(index + 1) + " ")]), _c('div', {
      staticClass: "steptext",
      domProps: {
        "innerHTML": _vm._s(step)
      }
    })]);
  }), 0), _vm._l(_vm.steps, function (step, key, index) {
    return _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.calculateComplete(key) === 'next',
        expression: "calculateComplete(key) === 'next'"
      }],
      key: key
    }, [_c('div', {
      staticClass: "d-lg-none steps-mobile d-flex align-items-center w-100"
    }, [_c('div', {
      staticClass: "steps-progress d-flex align-items-center justify-content-center flex-shrink-1"
    }, [_c('span', [_vm._v(_vm._s(index + 1) + " step ")])]), _c('div', {
      staticClass: "steps-info w-100"
    }, [_c('strong', [_vm._v(_vm._s(step))])])])]);
  })], 2), _c('VerificationTimer', {
    ref: "timer"
  }), _vm.history[0].name !== 'registration' ? _c('div', {
    staticClass: "back"
  }, [_c('a', {
    staticClass: "btn btn-outline-default btn-block",
    on: {
      "click": function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('span', {
    staticClass: "backarrow"
  }), _vm._v("Back")])]) : _vm._e()], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }