<template>
	<div class="auth-card">
		<h1 class="auth-title">
			Checking registration link...
		</h1>
		<p v-show="!registerId">
			<b-spinner 
				small 
				variant="dark" 
				type="grow" 
				label="Spinning"
			/>
		</p>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
	name: 'LoginInfo',
	props: {
		hash: {
			type: String,
			default: ''
		}
	},
	computed: {
		...mapState('register', ['registerId'])
	},
	methods: {
		...mapActions('register', ['verifyEmail']),
		...mapActions('notifications', ['pushError']),
		...mapActions('user', ['setAuthenticatorList']),
		onSuccess(response) {
			const { data } = response;
			const authList = data.availableAuthenticatorList;
			this.setAuthenticatorList(authList);
			this.$router.push({ name: 'registration', params: { hash: this.hash, role: data.roleId } });
		},
		onFailure(err) {
			this.pushError(err || 'Link is not valid anymore. Please register again.');
			this.$router.push({ name: 'register' });
		}
	},
	async mounted() {
		try {
			const approval = await this.verifyEmail(this.hash);
			this.onSuccess(approval);
		} catch (err) {
			this.onFailure(err);
		}
	},
};
</script>
