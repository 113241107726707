var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.permissions.create && _vm.permissions.get && _vm.currentWallet ? _c('div', {
    staticClass: "cards-menu"
  }, [_vm.noCards ? _c('div', {
    staticClass: "cards-menu__menu-item"
  }, [_c('b-row', {
    on: {
      "click": function click() {
        return _vm.$router.push({
          name: 'new-card'
        });
      }
    }
  }, [_c('b-col', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "cards-menu__card-image"
  }, [_c('card-image', {
    attrs: {
      "show-details": false
    }
  })], 1)]), _c('b-col', [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('strong', [_vm._v("Order your Verifo Card now")]), _c('small', [_vm._v("Virtual and physical cards available")])])])], 1)], 1) : _vm._e()]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }