<template>
	<div class="code-input">
		<div class="code-input__wrapper">
			<div
				v-for="(v, id) in digits"
				:key="id"
				class="code-input__single"
				:data-code="`digit-${id}`"
				:class="{
					'code-input__single-disabled': disabled
				}"
				@click="$refs.input.focus()"
			>
				<span 
					contenteditable="true"
					:class="{
						'code-input__char-placeholder': !(code && code.toString().split('')[id]),
						'code-input__char-active': (codeString.split('').length == id || (codeString.length == digits && codeString.length == id + 1)) && focused,
					}"
					@paste.prevent="onPaste"
				>
					{{ code && code.toString().split('')[id] || placeholder }}
				</span>
			</div>
		</div>
		<div class="code-input__hidden-field">
			<input 
				ref="input"
				:type="numbersOnly ? 'number' : 'text'"
				:value="code"
				:disabled="disabled"
				@focus="() => focused = true"
				@blur="() => focused = false"
				@input="setCode($event.target.value)"
			>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		disabled: {
			type: Boolean,
			default: false,
			required: false
		},
		digits: {
			type: Number,
			default: 6
		},
		placeholder: {
			type: String,
			required: false,
			default: '0'
		},
		value: {
			type: String,
			required: true,
		},
		numbersOnly: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data() {
		return {
			code: '',
			focused: false
		};
	},
	computed: {
		codeString: function() {
			return this.code.toString();
		}
	},
	watch: {
		code: function(value) {
			if (value && value.length == this.digits) {
				this.$emit('input', value);
			}
		},
		disabled: function(v) {
			if (!v) {
				this.$nextTick(this.focus);
			}
		}
	},
	mounted() {
		this.focus();
	},
	methods: {
		reset() {
			this.code = '';
		},
		validChar(char) {
			const re = this.numbersOnly ? /^\d$/ : /\w$/;
			return re.test(char) && `${char}`.length == 1;
		},
		setCode(value) {
			const valueToApply = value
				.split('')
				.filter(c => this.validChar(c))
				.map(c => c.toUpperCase())
				.join('');
			this.code = valueToApply;
			if (this.codeString && this.codeString.length > this.digits) {
				this.code = this.codeString.split('').slice(0, this.digits).join('');
			}
		},
		onPaste(e) {
			const value = e.clipboardData.getData('text');
			this.setCode(value);
		},
		focus() {
			this.$refs.input.focus();
		}
	}
};
</script>
<style lang="scss" scoped>
    @import './CodeInput.scss';
</style>