<template>
	<div class="margintop30">
		<div class="max440 ">
			<div class="check"></div>
			<div class="textblock  lightext">
				Confirmation link has been sent to
			</div>
			<p class="textblock  boldtext">
				{{ email }}
				<br />
				Click the link to confirm your email and continue registration.
			</p>

			<div class="textblock margintop30 font15">
				<p>
					If you haven't received the confirmation link within 5 minutes, please check your spam box or <a
						href="#"
						class="framelink bluelink"
						@click.prevent="initResend"
					>click here</a> to receive a new link.
				</p>
			</div>

			<div class="textblock margintop30 font15">
				<p>
					Verification link is valid for <b>15 minutes</b>. If link has expired, you will need to receive a new link, by <a
						href=""
						class="framelink bluelink"
						@click.prevent="initResend"
					>clicking here</a>.
				</p>
				<br />
				<vue-countdown-timer
					v-if="startsAt !== null && endsAt !== null"
					:start-time="startsAt"
					:end-time="endsAt"
					:interval="1000"
					:start-label="'Until start'"
					:end-label="'Time left'"
					:end-text="'Confirmation link expired!'"
					:day-txt="' days'"
					:hour-txt="' hours'"
					:minutes-txt="' minutes '"
					:seconds-txt="' seconds'"
					label-position="begin"
					@start_callback="startCallBack()"
					@end_callback="endCallBack()"
				>
					<template
						slot="countdown"
						slot-scope="scope"
					>
						<b>
							<span>{{ scope.props.minutes }}{{ scope.props.minutesTxt }}</span>
							<span>{{ scope.props.seconds }}{{ scope.props.secondsTxt }}</span>
						</b>
					</template>
				</vue-countdown-timer>
			</div>

			<div
				v-show="resent"
				class="confirm margintop30"
			>
				<span>Confirmation email re-sent</span>
			</div>

			<notification align="center" />

			<div class="textblock margintop30 font15">
				<p>
					Have you entered a wrong email address? <a
						href=""
						class="framelink bluelink"
						@click.prevent="backToRegistrationForm()"
					>Change it</a>
				</p>
			</div>
		</div>
		<vue-recaptcha
			id="recaptcha"
			ref="recaptcha"
			:sitekey="recapthaSitekey"
			:load-recaptcha-script="true"
			size="invisible"
			@verify="resendRegistrationEmail"
		/>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import config from '~/config';
import { VueRecaptcha }  from 'vue-recaptcha';

export default {
	name: 'RegisterSuccess',
	components: {
		VueRecaptcha
	},
	data() {
		return {
			resent: false,
			timerExpired: false,
			startsAt: null,
			endsAt: null,
			timerNotSet: true,
			recapthaSitekey: config.recaptchaSitekey,
			recaptchaResponse: null,
		};
	},
	computed: {
		...mapState('register', ['email'])
	},
	mounted() {
		const self = this;
		this.resetTimer();
		setInterval(function(){
			if (self.timerNotSet && self.$store.state.register.showSuccess) {
				self.resetTimer();
			}
		}, 1000);
	},
	methods: {
		...mapActions('register', ['changeEmail', 'resendEmail']),
		...mapActions('notifications', ['pushError']),
		backToRegistrationForm() {
			this.$emit('changeEmailClicked');
			this.changeEmail();
			this.resent = false;
		},
		initResend() {
			this.$refs.recaptcha.execute();
		},
		async resendRegistrationEmail(recaptchaResponse) {
			try {
				await this.resendEmail({ 
					email: this.email,
					recaptchaResponse
				});
				this.resent = true;
				this.resetTimer();
			} catch (err) {
				this.resent = false;
				this.pushError(err);
			}
		},
		startCallBack: function () {
			// do nothing
		},
		endCallBack: function () {
			this.timerExpired = true;
		},
		resetTimer: function () {
			this.startsAt = new Date();
			this.endsAt = new Date(this.startsAt.getTime() + 15*60000).getTime();
			this.startsAt = this.startsAt.getTime();
			this.timerNotSet = false;
		},
	},
};
</script>
