var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card-jwk-content"
  }, [_c('h2', [_vm._v("Card Details")]), _c('img', {
    attrs: {
      "src": "/images/icons/card-details.svg",
      "alt": "Card Details"
    }
  }), _c('div', {
    staticClass: "mt-4 text-left"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "card-jwk-content__info-bar"
  }, [_c('small', [_vm._v("Card number")]), _c('div', [_vm._v(_vm._s(_vm.cardNumber))])])], 1), _c('b-row', [_c('b-col', {
    staticClass: "card-jwk-content__info-bar"
  }, [_c('small', [_vm._v("Valid until")]), _c('div', [_vm._v(_vm._s(_vm.cardExpireDate))])])], 1), _c('b-row', [_c('b-col', {
    staticClass: "card-jwk-content__info-bar"
  }, [_c('small', [_vm._v("CVV")]), !_vm.hideCvv ? _c('div', [_vm._v(" " + _vm._s(_vm.content.cardCvv) + " ")]) : _vm._e(), _vm.hideCvv ? _c('div', [_vm._v(" ••• ")]) : _vm._e()])], 1), _c('b-row', [_c('b-col', {
    staticClass: "card-jwk-content__info-bar"
  }, [_c('small', [_vm._v("Card holder")]), _c('div', [_vm._v(_vm._s(_vm.content.embossedName))])])], 1), _c('b-row', [_c('b-col', {
    staticClass: "card-jwk-content__info-bar"
  }, [_c('small', [_vm._v("IBAN account")]), _c('div', [_vm._v(_vm._s(_vm.content.accountNumber))])])], 1)], 1), !_vm.hideCvv ? _c('div', {
    staticClass: "card-jwk-content__notification"
  }, [_c('span', [_vm._v("CVV will disappear in " + _vm._s(_vm.counter - _vm.timePassed) + " seconds")])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }