var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._m(0), _c('div', {
    staticClass: "transactions-full"
  }, [_c('payment-request')], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-title-container"
  }, [_c('h2', [_vm._v("Payment Request History")])]);
}]

export { render, staticRenderFns }