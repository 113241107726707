<template>
	<div
		class="card-order__card-img"
		:class="virtual ? 'card-order__card-img--virtual' : 'card-order__card-img--plastic'"
	>
		<span
			v-if="showDetails && card && card.cardNumber"
			class="card-order__card-number"
		>{{ cardNumber }}</span>
		<span
			v-if="showDetails"
			class="card-order__card-name"
		>{{ cardHolderName }}</span>
		<span
			v-if="showDetails"
			class="card-order__card-expiration"
		>{{ expirationDate }}</span>
		<img
			v-if="virtual"
			src="/images/card-virtual.svg"
			alt="visa virtual debit"
			:width="width"
		>
		<img
			v-if="!virtual"
			src="/images/card-plastic.svg"
			alt="visa debit"
			:width="width"
		>
	</div>
</template>
<script>
import moment from 'moment';
import { mapState } from 'vuex';
export default {
	name: 'CardImage',
	props: {
		virtual: {
			type: Boolean,
			default: false
		},
		showDetails: {
			type: Boolean,
			default: true
		},
		width: {
			type: String,
			default: ''
		},
		card: {
			type: Object,
			default: () => {}
		}
	},
	computed: {
		...mapState('user', {
			user: state => state.selected
		}),
		expirationDate() {
			if (this.card) return moment(this.card.validTo).format('MM/YY');
			return moment().add(4, 'years').format('MM/YY');
		},
		cardHolderName() {
			let name = this.card ? this.card.cardHolderName : this.user.fullName;
			if (24 < name.length) {
				const nameParts = name.split(' ');
				const firstNameLetter = nameParts[0][0] ?? '';

				nameParts.shift();

				name = `${firstNameLetter} ${nameParts.slice(-1)}`;
			}
			return name.toUpperCase();
		},
		cardNumber() {
			const num = this.card && this.card.cardNumber;
			if (!num) return '';
			const arr = num.match(/.{1,4}/g);
			return arr.join(' ');
		}
	},
};
</script>
<style lang="scss">
	.card-order {
		&__card-img {
			position: relative;	
			display: inline-block;
			
			&--plastic {
				color: #fff;
			}
			
			img {
				max-width: 100%;
			}
		}

		&__card-name {
			position: absolute;
			top: 75%;
			left: 10%;
			font-weight: 500;
		}

		&__card-expiration {
			position: absolute;
			top: 75%;
			right: 30%;
			font-family: Monospace;
		}

		&__card-number {
			position: absolute;
			top: 62%;
			left: 10%;
			font-family: Monospace;
		}

	}
</style>