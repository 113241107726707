<template>
	<div>
		<form action="">
			<fieldset :disabled="loading">
				<b-row
					v-for="field in populatedFields"
					:key="field.name"
					class="form-group"
				>
					<b-col>
						<label>{{ field.label }} <span
							v-if="field.required"
							class="label-red"
						>*</span></label>
						<!-- text -->
						<b-form-input
							v-if="field.type == 'text'"
							v-model="formData[field.key || field.name]"
							v-validate="[field.required && 'required', field.validation].filter(v => !!v).join('|')"
							:class="{'is-invalid' : errors.has(field.name)}"
							type="text"
							:name="field.name"
							:placeholder="field.placeholder || field.label"
							data-vv-validate-on="change"
							@blur="onBlur"
						/>
						<!-- ammount -->
						<b-input-group 
							v-if="field.type == 'amount'"
							:class="{'is-invalid' : errors.has(field.name) || errors.has(field.currencyName)}"
						>
							<b-form-input
								v-model="formData[field.key || field.name]"
								v-filter-input="'float'"
								v-validate="`${field.required && 'required'}`"
								:class="{'is-invalid' : errors.has(field.name)}"
								name="amount"
								placeholder="0.00"
								@blur="onBlur"
							/>
							<b-input-group-append>
								<v-multiselect
									v-model="formData[field.currencyKey]"
									v-validate="field.required && 'required'"
									:options="currencies"
									:show-labels="false"
									:allow-empty="false"
									:searchable="false"
									:class="{'is-invalid' : errors.has(field.currencyName)}"
									:name="field.currencyName"
									label="text"
									return="value"
									open-direction="bottom"
									:placeholder="field.currencyPlaceholder"
									@blur="onBlur"
								/>
							</b-input-group-append>
						</b-input-group>
						<!-- textarea -->
						<b-form-textarea
							v-if="field.type == 'textarea'"
							v-model="formData[field.key || field.name]"
							v-validate="field.required && 'required'"
							:rows="3"
							:max-rows="6"
							:class="{'is-invalid' : errors.has(field.name)}"
							:name="field.name"
							:placeholder="field.placeholder || field.label"
							:maxlength="field.maxLength || 124"
							@blur="onBlur"
						/>
						<!-- default error -->
						<div 
							v-show="errors.has(field.name)" 
							class="invalid-feedback"
						>
							{{ errors.first(field.name) }}
						</div>
						<!-- currency error -->
						<div 
							v-if="field.type == 'amount'"
							v-show="errors.has(field.currencyName)" 
							class="invalid-feedback"
						>
							Currency is required
						</div>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<small class="label-red">* Required fields</small>
					</b-col>
				</b-row>
			</fieldset>
		</form>
	</div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
	name: 'PaymentRequestForm',
	inject: ['$validator'],
	data() {
		return {
			formData: {},
			lockState: 0
		};
	},
	computed: {
		...mapState('countries', {
			countryOptions: state => state.list
		}),
		...mapGetters('wallet', [
			'currentWallet'
		]),
		populatedFields() {
			return this.formFields.filter(f => !f.hidden);
		},
		currencies() {
			const mappedCurrencies = [];
			if (this.currentWallet) {
				if (!this.currentWallet) return mappedCurrencies;
				this.currentWallet.walletCurrencyList.forEach(({ currencyId }) => mappedCurrencies.push({
					value: currencyId,
					text: `${currencyId.toUpperCase()}`,
				}));

				return mappedCurrencies;
			} else {
				return [];
			}
		},
		...mapGetters('user', [
			'defaultCurrency'
		]),
		walletId() {
			let id = null;
			if (this.currentWallet) {
				id = this.currentWallet.walletId;
			}
			return id;
		}
	},
	watch: {
		formData: {
			handler: function(v) {
				this.$emit('change', v);
			},
			deep: true
		},
		walletId: {
			handler: function() {
				this.setDefaultCurrency();
			}
		}
	},
	props: {
		loading: { 
			type: Boolean,
			default: false,
			required: false
		},
		formFields: {
			type: Array,
			required: true
		}
	},
	async mounted() {
		await this.getCountries();
		this.setDefaultCurrency();
	},
	methods: {
		...mapActions('countries', ['getCountries']),
		getFee() {
			this.$emit('getFee');
		},
		setDefaultCurrency() {
			let currency;
			if (this.currencies.length == 0) {currency = this.defaultCurrency;} else {
				currency = !this.currencies.find(currency => currency.value === this.defaultCurrency) ? this.currencies[0].value : this.defaultCurrency;
			}
			this.$set(this.formData, 'currencyId', currency);
		},
		onBlur(e) {
			const name = e.target.getAttribute('name');
			this.$emit('onBlur', name);
		},
		updateFormValue(name, value, fields) {
			const fieldNames = (fields || this.formFields).map(f => f.name);
			if (name && fieldNames.includes(name)) {
				this.$set(this.formData, name, value);
			} else if (!name && typeof value == 'object') {
				let newValues = {};
				Object.keys(value).forEach(key => {
					if (fieldNames.includes(key)) newValues[key] = value[key];
				});
				this.formData = {
					...this.formData,
					...newValues
				};
			}
		}
	},
	provide() {
		return {
			$validator: this.$validator,
		};
	}
};
</script>
<style lang="scss">
.label-red {
	color: $red;
}
.form-group {
	label {
		font-size: .8rem;
		color: $gray;
	}
}
.notes {
	font-size: 0.8rem;
	color: $gray;

	&--red {
		color: $red;
	}
}
</style>