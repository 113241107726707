<template>
	<div 
		v-if="title" 
		class="preloader-txt"
	>
		{{ title }}
	</div>
	<div 
		v-else 
		class="preloader-txt"
	>
		Loading...
	</div>
</template>

<script>
export default {
	name: 'Preloadertext',
	props : {
		title: {
			type: String,
			default: ''
		}
	}
};
</script>
<style lang="scss" scoped>

    .preloader-txt {
        animation: fadeinout 1s linear forwards infinite;
        opacity: 0.7;
        font-weight: 400;
    }

    @-webkit-keyframes fadeinout {
        50% { opacity: 1; }
    }

    @keyframes fadeinout {
        50% { opacity: 1; }
    }
</style>
