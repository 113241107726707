import { SET_CARDS_LIST, SET_CARDS_ORDERS_LIST, SET_CARDS_COUNTRIES, RESET_CARDS } from '~/store/mutation-types';

export default {
	[SET_CARDS_LIST](state, list) {
		state.list = list;
		state.listLoaded = true;
	},
	[SET_CARDS_ORDERS_LIST](state, list) {
		state.ordersList = list;
		state.ordersListLoaded = true;
	},
	[SET_CARDS_COUNTRIES](state, countries) {
		state.countries = countries;
		state.countriesLoaded = true;
	},
	[RESET_CARDS](state) {
		state.list = [];
		state.countries = [];
		state.listLoaded = false;
		state.countriesLoaded = false;
	}
};
