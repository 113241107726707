<template>
	<form @submit.prevent="transferMoney">
		<fieldset :disabled="currentlyLoading === true">
			<!-- @todo giedrius remote same logic from transfer -->
			<div v-if="isNotVerified">
				<b-row class="mb-3">
					<b-col md="12">
						<b-alert 
							show 
							variant="danger"
						>
							External transactions can only be done by verified users
							<router-link 
								:to="verificationLink" 
								class="ml-3 alert-link"
							>
								Verify now
							</router-link>
						</b-alert>
					</b-col>
				</b-row>
			</div>
			<div 
				v-else 
				v-show="currentAction ==='step1'"
			>
				<b-row>
					<b-col
						xl="7"
						cols="12"
						class="mb-3"
					>				
						<b-card>
							<b-row class="form-group">
								<b-col>
									<label>Type</label>
									<div class="d-flex">
										<div class="radio mr-4">
											<input 
												id="radio-2" 
												v-model="data.transferType" 
												name="transferType"
												type="radio" 
												value="company"
											>
											<label 
												for="radio-2" 
												class="radio-label"
											>To business</label>
										</div>
										<div class="radio">
											<input 
												id="radio-1" 
												v-model="data.transferType" 
												name="transferType"
												type="radio" 
												value="individual"
											>
											<label 
												for="radio-1" 
												class="radio-label"
											>To person</label>
										</div>
									</div>
								</b-col>
							</b-row>

							<b-row 
								v-if="data.transferType === 'individual'" 
								class="form-group"
							>
								<b-col>
									<label>First and middle names <span class="label-red">*</span></label>
									<b-form-input
										key="first-name"
										v-model="data.name"
										v-validate="'required|min:3'"
										:class="{'is-invalid' : errors.has('firstName')}"
										type="text"
										name="firstName"
										placeholder="First and middle names"
										data-vv-validate-on="change"
									/>
									<div 
										v-show="errors.has('firstName')" 
										class="invalid-feedback"
									>
										{{ errors.first('firstName') }}
									</div>
								</b-col>
							</b-row>

							<b-row 
								v-if="data.transferType === 'individual'" 
								class="form-group"
							>
								<b-col>
									<label>Last name <span class="label-red">*</span></label>

									<b-form-input
										key="last-name"
										v-model="data.surname"
										v-validate="'required|min:3'"
										:class="{'is-invalid' : errors.has('lastName')}"
										type="text"
										name="lastName"
										placeholder="Last name"
										data-vv-validate-on="change"
									/>
									<div 
										v-show="errors.has('lastName')" 
										class="invalid-feedback"
									>
										{{ errors.first('lastName') }}
									</div>
								</b-col>
							</b-row>

							<b-row 
								v-if="data.transferType === 'company'" 
								class="form-group"
							>
								<b-col>
									<label>Beneficiary <span class="label-red">*</span></label>

									<b-form-input
										v-model="data.companyName"
										v-validate="'required|min:3'"
										:class="{'is-invalid' : errors.has('companyName')}"
										type="text"
										name="companyName"
										placeholder="Beneficiary"
										data-vv-validate-on="change"
									/>
									<div 
										v-show="errors.has('companyName')" 
										class="invalid-feedback"
									>
										{{ errors.first('companyName') }}
									</div>
								</b-col>
							</b-row>

							<b-row class="form-group">
								<b-col>
									<label>Account number or IBAN <span class="label-red">*</span></label>
									<b-form-input
										v-model="accountNumber"
										v-validate="'required|currencyCloud:accountNumber'"
										:class="{'is-invalid' : errors.has('accountNumber')}"
										placeholder="Account number or IBAN"
										type="text"
										name="accountNumber"
										@blur="getTypeWithDebounce"
									/>
									<div 
										v-show="errors.has('accountNumber')" 
										class="invalid-feedback"
									>
										<span>{{ errors.first('accountNumber') }}</span>
									</div>
								</b-col>
							</b-row>
							<b-row class="form-group">
								<b-col>
									<label>Buy amount <span class="label-red">*</span></label>
									<b-input-group
										v-show="!buyDisabled"
										:class="{'input-invalid is-invalid' : errors.has('buy_amount') || errors.has('buy_currency_id')}"
									>
										<b-form-input
											v-model="data.buyAmount"
											v-filter-input="'float'"
											v-validate="'min_value:1'"
											:class="{'input-invalid' : errors.has('buy_amount')}"
											name="amount"
											placeholder="0.00"
											track-by="buy_amount"
											return="buy_amount"
											:disabled="rateLoading"
											@blur="getDebouncedFee"
											@change="handleBuyChange"
										/>
										<b-input-group-append>
											<v-multiselect
												v-model="data.buyCurrencyId"
												v-validate="'required'"
												:options="currencyOptions"
												:show-labels="false"
												:allow-empty="false"
												:searchable="true"
												:class="{'is-invalid' : errors.has('buy_currency_id')}"
												name="buy_currency_id"
												:custom-label="currencyLabel"
												track-by="currencyId"
												return="currencyId"
												open-direction="bottom"
												placeholder="Select currency"
												:disabled="rateLoading"
												@input="handleCurrencyChange"
											/>
										</b-input-group-append>
									</b-input-group>
									<div v-show="buyDisabled">
										<b-spinner
											v-if="rateLoading"
											variant="primary"
										/>
										<b-button
											v-if="!rateLoading"
											variant="primary"
											class="amount-button"
											@click="resetDisabled"
										>
											<span class="mr-1">{{ data.buyAmount }} {{ data.buyCurrencyId && data.buyCurrencyId.toUpperCase() }}</span>
											<ion-icon name="close-circle-outline" />
										</b-button>
									</div>

									<div 
										v-show="errors.has('buy_amount')" 
										class="invalid-feedback"
									>
										<span>{{ errors.first('buy_amount') }}</span>
									</div>
									<div 
										v-show="errors.has('buy_currency_id')" 
										class="invalid-feedback"
									>
										<span>Currency is required</span>
									</div>
								</b-col>
							</b-row>

							<b-row class="form-group">
								<b-col>
									<label>Sell amount</label>
									<b-input-group
										v-show="!sellDisabled"
										:class="{'is-invalid' : errors.has('sell_amount') || errors.has('sell_currency_id')}"
									>
										<b-form-input
											v-model="data.sellAmount"
											v-filter-input="'float'"
											v-validate="'min_value:1'"
											:class="{'is-invalid' : errors.has('sell_amount')}"
											name="amount"
											placeholder="0.00"
											track-by="sell_amount"
											return="sell_amount"
											:disabled="rateLoading"
											@blur="getDebouncedFee"
											@change="handleSellChange"
										/>
										<b-input-group-append>
											<v-multiselect
												v-model="data.sellCurrencyId"
												v-validate="'required'"
												:options="mappedCurrencies"
												:show-labels="false"
												:allow-empty="false"
												:searchable="false"
												:preselect-first="true"
												:class="{'is-invalid' : errors.has('sell_currency_id')}"
												name="sell_currency_id"
												label="text"
												return="value"
												open-direction="bottom"
												placeholder="Select currency"
												:disabled="rateLoading"
												:track-by="'value'"
												@input="handleCurrencyChange"
											/>
										</b-input-group-append>
									</b-input-group>
									<div v-show="sellDisabled">
										<b-spinner
											v-if="rateLoading"
											variant="primary"
										/>
										<b-button
											v-if="!rateLoading"
											variant="primary"
											class="amount-button"
											@click="resetDisabled"
										>
											<span class="mr-1">{{ data.sellAmount }} {{ data.sellCurrencyId && data.sellCurrencyId.toUpperCase() }}</span>
											<ion-icon name="close-circle-outline" />
										</b-button>
									</div>
									<div 
										v-show="errors.has('sell_amount')" 
										class="invalid-feedback"
									>
										<span>{{ errors.first('sell_amount') }}</span>
									</div>
									<div 
										v-show="errors.has('sell_currency_id')" 
										class="invalid-feedback"
									>
										<span>Currency is required</span>
									</div>
								</b-col>
							</b-row>
							<b-row 
								v-if="countryFieldNeeded" 
								class="form-group"
							>
								<b-col>
									<label>Country <span class="label-red">*</span></label>
									<v-multiselect
										v-model="data.countryId"
										v-validate="'required'"
										:options="countryOptions"
										:show-labels="false"
										:allow-empty="false"
										:class="{'is-invalid' : errors.has('countryId')}"
										:disabled="lockState === 1"
										name="countryId"
										label="name"
										placeholder="Select bank country"
										track-by="countryId"
										return="countryId"
										open-direction="bottom"
									/>
									<div 
										v-show="errors.has('countryId')" 
										class="invalid-feedback"
									>
										<span>{{ errors.first('countryId') }}</span>
									</div>
								</b-col>
							</b-row>

							<b-row 
								v-if="sortCodeNeeded" 
								class="form-group"
							>
								<b-col>
									<label>Sort code <span class="label-red">*</span></label>
									<b-form-input
										id="sort-code"
										key="sort-code"
										v-model="data.sortCode"
										v-validate="'required|currencyCloud:sortCode'"
										:class="{'is-invalid' : errors.has('sort-code')}"
										type="text"
										name="sort-code"
										class="form-control"
										data-vv-validate-on="change"
									/>
									<div 
										v-show="errors.has('sort-code')" 
										class="invalid-feedback"
									>
										<span>{{ errors.first('sort-code') }}</span>
									</div>
								</b-col>
							</b-row>

							<b-row 
								v-if="bsbCodeNeeded" 
								class="form-group"
							>
								<b-col>
									<label>BSB code <span class="label-red">*</span></label>
									<b-form-input
										id="bsb-code"
										key="bsb-code"
										v-model="data.bsbCode"
										v-validate="'required|currencyCloud:bsbCode'"
										:class="{'is-invalid' : errors.has('bsb-code')}"
										type="text"
										name="bsb-code"
										class="form-control"
										data-vv-validate-on="change"
									/>
									<div 
										v-show="errors.has('bsb-code')" 
										class="invalid-feedback"
									>
										<span>{{ errors.first('bsb-code') }}</span>
									</div>
								</b-col>
							</b-row>

							<b-row 
								v-if="bankCodeNeeded" 
								class="form-group"
							>
								<b-col>
									<label>Bank code <span class="label-red">*</span></label>
									<b-form-input
										id="bank-code"
										key="bank-code"
										v-model="data.bankCode"
										v-validate="'required|currencyCloud:bankCode'"
										:class="{'is-invalid' : errors.has('bank-code')}"
										type="text"
										name="bank-code"
										class="form-control"
										data-vv-validate-on="change"
									/>
									<div 
										v-show="errors.has('bank-code')" 
										class="invalid-feedback"
									>
										<span>{{ errors.first('bank-code') }}</span>
									</div>
								</b-col>
							</b-row>

							<b-row
								v-if="branchCodeNeeded"
								class="form-group"
							>
								<b-col>
									<label>Branch code <span class="label-red">*</span></label>
									<b-form-input
										id="branch-code"
										key="branch-code"
										v-model="data.branchCode"
										v-validate="'required|currencyCloud:branchCode'"
										:class="{'is-invalid' : errors.has('branch-code')}"
										type="text"
										name="branch-code"
										class="form-control"
										data-vv-validate-on="change"
									/>
									<div
										v-show="errors.has('branch-code')"
										class="invalid-feedback"
									>
										<span>{{ errors.first('branch-code') }}</span>
									</div>
								</b-col>
							</b-row>

							<b-row 
								v-if="abaNeeded" 
								class="form-group"
							>
								<b-col>
									<label>Aba routing number <span class="label-red">*</span></label>
									<b-form-input
										id="aba"
										key="aba"
										v-model="data.aba"
										v-validate="'required|currencyCloud:aba'"
										:class="{'is-invalid' : errors.has('aba')}"
										type="text"
										name="aba"
										class="form-control"
										data-vv-validate-on="change"
									/>
									<div 
										v-show="errors.has('aba')" 
										class="invalid-feedback"
									>
										<span>{{ errors.first('aba') }}</span>
									</div>
								</b-col>
							</b-row>

							<b-row 
								v-if="bicNeeded" 
								class="form-group"
							>
								<b-col>
									<label>BIC/SWIFT code <span class="label-red">*</span></label>
									<b-form-input
										id="bic"
										key="bic"
										v-model="data.bic"
										v-validate="'required|currencyCloud:bic'"
										:class="{'is-invalid' : errors.has('bic')}"
										:readonly="lockState === 1"
										type="text"
										name="bic"
										class="form-control"
										data-vv-validate-on="change"
									/>
									<div 
										v-show="errors.has('bic')" 
										class="invalid-feedback"
									>
										<span>{{ errors.first('bic') }}</span>
									</div>
								</b-col>
							</b-row>

							<b-row 
								v-if="stateNeeded" 
								class="form-group"
							>
								<b-col>
									<label>State <span class="label-red">*</span></label>
									<b-form-input
										id="state"
										key="state"
										v-model="data.state"
										v-validate="'required'"
										:class="{'is-invalid' : errors.has('state')}"
										type="text"
										name="state"
										class="form-control"
										data-vv-validate-on="change"
									/>
									<div 
										v-show="errors.has('state')" 
										class="invalid-feedback"
									>
										<span>{{ errors.first('state') }}</span>
									</div>
								</b-col>
							</b-row>
							<b-row 
								v-if="addressNeeded" 
								class="form-group"
							>
								<b-col>
									<label>City <span class="label-red">*</span></label>
									<b-form-input
										id="city"
										key="city"
										v-model="data.city"
										v-validate="'required'"
										:class="{'is-invalid' : errors.has('city')}"
										type="text"
										name="city"
										class="form-control"
										data-vv-validate-on="change"
									/>
									<div 
										v-show="errors.has('city')" 
										class="invalid-feedback"
									>
										This field is required
									</div>
								</b-col>
							</b-row>
							<b-row 
								v-if="addressNeeded" 
								class="form-group"
							>
								<b-col>
									<label>Address <span class="label-red">*</span></label>
									<b-form-input
										id="address"
										key="address"
										v-model="data.address"
										v-validate="'required'"
										:class="{'is-invalid' : errors.has('address')}"
										type="text"
										name="address"
										class="form-control"
										data-vv-validate-on="change"
									/>
									<div 
										v-show="errors.has('address')" 
										class="invalid-feedback"
									>
										<span>{{ errors.first('address') }}</span>
									</div>
								</b-col>
							</b-row>
							<b-row 
								v-if="postCodeNeeded" 
								class="form-group"
							>
								<b-col>
									<label>Postcode/zipcode <span class="label-red">*</span></label>
									<b-form-input
										id="postcode"
										key="postcode"
										v-model="data.postcode"
										v-validate="'required'"
										:class="{'is-invalid' : errors.has('postcode')}"
										type="text"
										name="postcode"
										class="form-control"
										data-vv-validate-on="change"
									/>
									<div 
										v-show="errors.has('postcode')" 
										class="invalid-feedback"
									>
										<span>{{ errors.first('postcode') }}</span>
									</div>
								</b-col>
							</b-row>

							<b-row 
								v-if="purposeNeeded && purposeList[data.countryId]" 
								class="form-group"
							>
								<b-col>
									<label>Purpose <span class="label-red">*</span></label>
									<v-multiselect
										v-model="data.purpose"
										v-validate="'required'"
										class="swift-form__purpose"
										:options="purposeList[data.countryId]"
										:show-labels="false"
										:allow-empty="false"
										:class="{'is-invalid' : errors.has('purpose')}"
										name="purpose"
										label="name"
										placeholder="Select purpose"
										track-by="value"
										return="value"
										open-direction="bottom"
									/>
									<div 
										v-show="errors.has('purpose')" 
										class="invalid-feedback"
									>
										<span>{{ errors.first('purpose') }}</span>
									</div>
								</b-col>
							</b-row>

							<b-row class="form-group">
								<b-col>
									<label>Payment details <span class="label-red">*</span></label>
									<b-form-textarea
										v-model="data.details"
										v-validate="'required'"
										:rows="3"
										:max-rows="6"
										:class="{'is-invalid' : errors.has('details')}"
										name="details"
										maxlength="124"
									/>
									<div 
										v-show="errors.has('details')" 
										class="invalid-feedback"
									>
										<span>{{ errors.first('details') }}</span>
									</div>
								</b-col>
							</b-row>
							<b-row class="form-group">
								<b-col>
									<label>Files</label>
									<file-upload multiple
										add-more-button
										file-list
										accept=".jpg, .jpeg, .gif, .png, .pdf, .eml"
										@onChange="files => data.files = files.map(f => ({ file: f.content, fileName: f.name }))"
									/>
								</b-col>
							</b-row>
							<b-row
								v-if="user.role === 'business'"
								class="form-group"
							>
								<b-col>
									<b-form-checkbox
										v-model="data.changeTemplate"
										type="checkbox"
										name="changeTemplate"
									>
										<span v-if="transactionTemplate?.id">
											Change template
										</span>
										<span v-else>
											Add to my templates
										</span>
									</b-form-checkbox>
								</b-col>
							</b-row>
							<b-row
								v-if="data.changeTemplate"
								class="form-group"
							>
								<b-col>
									<label>Template name <span class="label-red">*</span></label>
									<b-form-input
										id="templateName"
										key="templateName"
										v-model="data.templateName"
										v-validate="'required'"
										:class="{'is-invalid' : errors.has('templateName')}"
										type="text"
										name="templateName"
										class="form-control"
										data-vv-validate-on="change"
									/>
									<div
										v-show="errors.has('templateName')"
										class="invalid-feedback"
									>
										<span>{{ errors.first('templateName') }}</span>
									</div>
								</b-col>
							</b-row>
							<b-row class="form-group">
								<b-col 
									v-if="apiError !== null" 
									md="12"
								>
									<b-alert 
										variant="alert" 
										class="text-center mb-4" 
										show
									>
										{{ apiError }}
									</b-alert>
								</b-col>
							</b-row>
							<b-row>
								<b-col>
									<small class="label-red">* Required fields</small>
								</b-col>
							</b-row>
						</b-card>
					</b-col>
					<b-col
						xl="5"
						cols="12"
						class="mb-3"
					>
						<b-card>
							<b-row>
								<b-col>
									Exchange rate: 
								</b-col>
								<b-col class="text-right">
									<span v-if="data.buyCurrencyId && data.sellCurrencyId && data.rate">
										1 {{ data.sellCurrencyId.toUpperCase() }} = {{ data.rate }} {{ data.buyCurrencyId.toUpperCase() }}
									</span>
									<span v-if="!data.rate"> - </span>
								</b-col>
							</b-row>
							<b-row>
								<b-col>
									Transaction fee: 
								</b-col>
								<b-col class="text-right">
									<span>{{ fee || '0.00' }} {{ data.sellCurrencyId.toUpperCase() }}</span>
									<div v-if="fee && currentFeeWalletAmount">
										<b-badge variant="primary">
											Uses fee wallet
										</b-badge>
									</div>
								</b-col>
							</b-row>
							<b-row class="mt-3">
								<b-col>
									<span class="notes">
										{{ notes }}
									</span>
								</b-col>
							</b-row>
							<hr>
							<b-row>
								<b-col>
									<strong>Estimated total:</strong>
								</b-col>
								<b-col class="text-right">
									<strong>{{ estimatedTotal }} {{ data.sellCurrencyId && data.sellCurrencyId.toUpperCase() }}</strong>
								</b-col>
							</b-row>
							<b-row v-if="insufficientFunds">
								<b-col class="text-right">
									<b-badge variant="danger">
										Insufficient Funds
									</b-badge>
								</b-col>
							</b-row>
						</b-card>
						<b-row
							class="form-group mt-3 mb-0"
							align-h="center"
						>
							<b-col v-if="user.role === 'business' && isWalletGranted('initiate_transaction', currentWallet.walletId)">
								<button
									:disabled="isSubmitting || insufficientFunds || (!data.buyAmount && !data.sellAmount) || rateLoading"
									type="submit"
									class="btn btn-outline-primary btn-block"
									@click="transferMoney('initiate')"
								>
									{{ currentlyLoading === true || rateLoading ? 'Loading...' : 'Save for confirmation' }}
								</button>
							</b-col>
						</b-row>
						<b-row
							class="form-group mt-3 mb-0"
							align-h="center"
						>
							<b-col v-if="isWalletGranted('approve_transaction', currentWallet.walletId)">
								<button
									:disabled="isSubmitting || insufficientFunds || (!data.buyAmount && !data.sellAmount) || rateLoading"
									type="submit"
									class="btn btn-primary btn-block"
									@click="transferMoney('approve')"
								>
									{{ currentlyLoading === true || rateLoading ? 'Loading...' : 'Next' }}
								</button>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</div>


			<div v-if="currentAction === 'step2' && successAction !== 1 && successAction !== 2">
				<b-card>
					<b-row class="form-group mb-lg-0 pt-3">
						<label class="col-lg-5 col-xl-3 col-form-label">Wallet send from:</label>
						<b-col 
							lg="7" 
							xl="6" 
							class="font-weight-bold"
						>
							{{ walletId }} (Balance {{ currentWalletAmount.balance }} {{ data.sellCurrencyId.toUpperCase() }} )
						</b-col>
					</b-row>

					<b-row class="form-group mb-lg-0">
						<label class="col-lg-5 col-xl-3 col-form-label">Account number:</label>
						<b-col 
							lg="7" 
							xl="6" 
							class="font-weight-bold"
						>
							{{ data.accountNumber }}
						</b-col>
					</b-row>

					<b-row 
						v-show="data.name && data.name !== ''" 
						class="form-group mb-lg-0"
					>
						<label class="col-lg-5 col-xl-3 col-form-label">Recipient name:</label>
						<b-col 
							lg="7" 
							xl="6" 
							class="font-weight-bold"
						>
							{{ data.name }} {{ data.surname }}
						</b-col>
					</b-row>

					<b-row 
						v-show="data.companyName && data.companyName !== ''" 
						class="form-group mb-lg-0"
					>
						<label class="col-lg-5 col-xl-3 col-form-label">Recipient:</label>
						<b-col 
							lg="7" 
							xl="6" 
							class="font-weight-bold"
						>
							{{ data.companyName }}
						</b-col>
					</b-row>

					<b-row class="form-group mb-lg-0">
						<label class="col-lg-5 col-xl-3 col-form-label">Buy amount:</label>
						<b-col 
							lg="7" 
							xl="6" 
							class="font-weight-bold"
						>
							{{ data.buyAmount }} {{ data.buyCurrencyId.toUpperCase() }}
						</b-col>
					</b-row>

					<b-row class="form-group mb-lg-0">
						<label class="col-lg-5 col-xl-3 col-form-label">Sell amount:</label>
						<b-col 
							lg="7" 
							xl="6" 
							class="font-weight-bold"
						>
							{{ data.sellAmount }} {{ data.sellCurrencyId.toUpperCase() }} (2% of sell amount will be reserved for rate changes)
						</b-col>
					</b-row>
					
					<b-row v-if="fee"
						class="form-group mb-lg-0"
					>
						<label class="col-lg-5 col-xl-3 col-form-label">Fee:</label>
						<b-col 
							lg="7" 
							xl="6" 
							class="font-weight-bold"
						>
							{{ fee }} {{ data.sellCurrencyId.toUpperCase() }}
						</b-col>
					</b-row>

					<b-row class="form-group mb-lg-0">
						<label class="col-lg-5 col-xl-3 col-form-label">Rate:</label>
						<b-col 
							lg="7" 
							xl="6" 
							class="font-weight-bold"
						>
							{{ data.rate }}
						</b-col>
					</b-row>
					<b-row class="form-group mb-4">
						<label class="col-lg-5 col-xl-3 col-form-label">Description:</label>
						<b-col 
							lg="7" 
							xl="6" 
							class="font-weight-bold"
						>
							{{ data.details }}
						</b-col>
					</b-row>
				</b-card>
				<b-row class="mt-3">
					<b-col>
						<b-card class="mb-4 mb-lg-5">
							<b-row>
								<b-col>
									<authenticator
										v-if="isWalletGranted('approve_transaction', currentWallet.walletId) && transferAction === 'approve'"
										ref="sms"
										:show-back-button="false"
										:on-back="handleBack"
										:on-submit="submit"
										type="transfer"
										button-text="Confirm transfer"
										:resend-data="authenticatorData"
										data="data"
									/>
									<authenticator
										v-if="isWalletGranted('initiate_transaction', currentWallet.walletId) && transferAction === 'initiate'"
										ref="sms"
										:show-back-button="false"
										:on-back="handleBack"
										:on-submit="submitInitiation"
										type="transfer"
										button-text="Save for confirmation"
										:resend-data="authenticatorData"
										data="data"
									/>
								</b-col>
							</b-row>
						</b-card>
					</b-col>
				</b-row>

				<b-row>
					<b-col 
						class="text-center"
					>
						<input 
							type="button" 
							class="btn btn-outline-dark px-5" 
							value="Edit transfer"
							@click="handleBack"
						/>
					</b-col>
				</b-row>
			</div>
			<div 
				v-if="successAction === 1" 
				class="justify-content-md-center"
			>
				<div class="text-center py-4">
					<h3 class="text-success mb-4">
						Your transfer #{{ transactionId }} has been sent
					</h3>
					<router-link 
						:to="{ name: 'transactions' }" 
						class="btn btn-outline-dark px-5" 
						exact
					>
						Check
						transactions
					</router-link>
				</div>
			</div>
			<div
				v-if="successAction === 2"
				class="justify-content-md-center"
			>
				<div class="text-center py-4">
					<h3 class="text-success mb-4">
						Your transfer saved for confirmation later
					</h3>
					<div
						class="btn btn-outline-dark px-5"
						@click="goToPending"
					>
						Check for confirmation
					</div>
				</div>
			</div>
		</fieldset>
	</form>
</template>
<script>
import * as types from '~/store/mutation-types';
import { currency as CurrencyHelper } from '~/helpers';
import axios from 'axios';
import Authenticator from '@/shared/Authenticator.vue';
import debounce from 'lodash/debounce';
import FileUpload from '@/shared/FileUpload.vue';
import { Validator } from 'vee-validate';
import purposeList from './swiftPurpose';

import { mapActions, mapGetters, mapState } from 'vuex';
import { endpoints } from '~/config';

export default {
	name: 'CurrencyCloudTransfer',
	components: {
		Authenticator,
		FileUpload
	},
	props: {
		transferData: {
			type: Object,
			default: () => ({}),
			required: false
		},
		transactionTemplate: {
			type: Object,
			default: null,
			required: false
		},
	},
	data() {
		return {
			currentlyLoading: true,
			currentAction: 'step1',
			successAction: 0,
			currencyOptions: [],
			accountNumber: '',
			lockState: 0,
			fee: null,
			error: 0,
			transactionType: '',
			apiError: null,
			isFirstInvalid: true,
			isLastInvalid: true,
			isAccNumInvalid: true,
			isAmountInvalid: true,
			isIbanValid: false,
			ibanNeeded: false,
			sortCodeNeeded: false,
			bsbCodeNeeded: false,
			bankCodeNeeded: false,
			branchCodeNeeded: false,
			abaNeeded: false,
			countryNeeded: true,
			isSubmitting: false,
			data: {
				type: '',
				name: '',
				surname: '',
				companyName: '',
				bic: '',
				accountNumber: '',
				buyAmount: '',
				sellAmount: '',
				fixedSide: '',
				rate: '',
				sellCurrencyId: '',
				buyCurrencyId: '',
				details: '',
				transferType: 'company',
				countryId: '',
				state: '',
				postcode: '',
				city: '',
				address: '',
				sortCode: '',
				bsbCode: '',
				bankCode: '',
				branchCode: '',
				purpose: '',
				files: []
			},
			sellDisabled: false,
			buyDisabled: false,
			rateLoading: false,
			purposeList,
			transactionId: null,
			notes: 'Global Swift transfers are provided by a third party company and certain restrictions may apply. Please note Gambling, Crypto Currency and Third Party (B2C) payments are not currently supported. Rejected payments will be returned in Euro currency less the transaction fees.',
			transferAction: null
		};
	},
	async mounted() {
		this.addCustomValidation();
		this.debouncedGetType = debounce(this.getType, 200);
		await this.getSwiftCountries();
		this.currentlyLoading = false;
		if (this.currencyOptions.length < 1) {
			this.currencyOptions = await CurrencyHelper.getSwiftList();
			setTimeout(() => {
				this.currentlyLoading = false;
			}, 300);
		}

		if (this.transactionTemplate) {
			const td = this.transactionTemplate.transactionData;
			this.data = {
				...this.data,
				name: td.beneficiaryRequest?.name,
				surname: td.beneficiaryRequest?.surname,
				transferType: td.beneficiaryRequest?.type === 'company' ? 'company' : 'individual',
				companyName: td.beneficiaryRequest?.companyName,
				bic: td.beneficiaryRequest?.bic,
				sellAmount: td.amount,
				sellCurrencyId: td.currencyId,
				buyCurrencyId: td.buyCurrencyId,
				details: td.details,
				countryId: td.beneficiaryRequest?.countryId,
				city: td.beneficiaryRequest?.city,
				address: td.beneficiaryRequest?.address,
				state: td.beneficiaryRequest?.state,
				postcode: td.beneficiaryRequest?.zipCode,
				sortCode: td.beneficiaryRequest?.sortCode,
				bsbCode: td.beneficiaryRequest?.bsbCode,
				bankCode: td.beneficiaryRequest?.bankCode,
				branchCode: td.beneficiaryRequest?.branchCode,
				aba: td.beneficiaryRequest?.aba,
				purpose: td.purpose,
				fixedSide: 'sell',
				changeTemplate: false,
				templateName: td.templateName
			};
			this.$forceUpdate();

			this.accountNumber = td.beneficiaryRequest?.accountNumber;

			this.buyDisabled = true;
			this.getRate();
		} else if  (this.transferData && this.transferData.transferType == 'swift') {
			const td = this.transferData;
			this.data = {
				...this.data,
				name: td.extraDetails?.beneficiaryFirstName,
				surname: td.extraDetails?.beneficiaryLastName,
				transferType: td.extraDetails && td.extraDetails.entityType == 'company' ? 'company' : 'individual',
				companyName: td.extraDetails.beneficiaryCompanyName,
				bic: td.beneficiary.bank?.bic,
				sellAmount: td.amount,
				sellCurrencyId: td.currencyId,
				buyCurrencyId: td.extraDetails.buyCurrencyId,
				details: td.details,
				countryId: td.countryId,
				city: td.beneficiary.address.city,
				address: td.beneficiary.address.address,
				state: td.beneficiary.address.state,
				postcode: td.beneficiary.address.zipCode,
				sortCode: td.extraDetails.routingCodeType == 'sort_code' ? td.extraDetails.routingCodeValue : undefined,
				bsbCode: td.extraDetails.routingCodeType == 'bsb_code' ? td.extraDetails.routingCodeValue : undefined,
				bankCode: td.extraDetails.routingCodeType == 'bank_code' ? td.extraDetails.routingCodeValue : undefined,
				branchCode: td.extraDetails.routingCodeType2 == 'branch_code' ? td.extraDetails.routingCodeValue2 : undefined,
				aba: td.extraDetails.routingCodeType == 'aba' ? td.extraDetails.routingCodeValue : undefined,
				purpose: td.extraDetails.purpose,
				fixedSide: 'sell',
				changeTemplate: false
			};
			this.$forceUpdate();

			this.accountNumber = td.extraDetails?.accountNumber || td.extraDetails?.iban;

			this.buyDisabled = true;
			this.getRate();
		}
	},
	computed: {
		...mapState({
			errors: state => state.transfer.errors
		}),
		...mapState('user', {
			user: state => state.selected
		}),
		...mapState('wallet', {
			wallet: state => state,
		}),
		...mapState('countries', {
			countryOptions: state => state.listSwift
		}),
		...mapGetters('user', [
			'isVerified',
			'verificationLink',
			'defaultCurrency',
			'isWalletGranted'
		]),
		...mapGetters('wallet', [
			'currentWallet',
			'currentWalletAmount',
			'currencies',
			'currentFeeWalletAmount'
		]),
		walletDefaultCurrency: {
			get() {
				if (this.mappedCurrencies.length > 0) {
					return !this.mappedCurrencies.find(currency => currency.value === this.defaultCurrency) 
						? this.mappedCurrencies[0].value 
						: this.defaultCurrency;
				} else {
					return {};
				}
			}
		},
		walletId: {
			get() {
				let id = null;
				if (this.currentWallet) {
					id = this.currentWallet.walletId;
				}
				return id;
			},
			set(wallet_id) {
				this.$store.dispatch('wallet/changeCurrentWallet', wallet_id);
				this.data.walletId = wallet_id;
			}
		},
		mappedCurrencies() {
			const mappedCurrencies = [];
			if (this.currencies?.length) {
				this.currencies.forEach(({ currencyId, balance }) => mappedCurrencies.push({
					value: currencyId,
					text: `${currencyId.toUpperCase()} (${balance})`,
				}));
				return mappedCurrencies;
			} else {
				return [];
			}
		},
		sell_amount_watcher() {
			return [this.data.sellAmount, this.data.sellCurrencyId];
		},
		buy_amount_watcher() {
			return [this.data.buyAmount, this.data.buyCurrencyId];
		},
		addressNeeded() {
			const countries = ['us', 'hk', 'sg', 'pl', 'au', 'ca'];

			return !this.countryNeeded
					&& (this.transactionType.indexOf('swift') !== -1
						|| countries.indexOf(this.data.countryId) !== -1
					);
		},
		stateNeeded() {
			const countries = ['ca', 'mx', 'us'];

			return countries.indexOf(this.data.countryId) !== -1;
		},
		postCodeNeeded() {
			const countries = ['ca', 'mx', 'us', 'au'];

			return countries.indexOf(this.data.countryId) !== -1;
		},
		purposeNeeded() {
			const countries = ['ae', 'au'];
			return countries.indexOf(this.data.countryId) !== -1;
		},
		//@todo find better way
		bicNeeded() {
			const countries = ['hk', 'sg'];
			const currencies = {
				hk: 'hkd'
			};

			const selectedCountry = countries.find(c => c == this.data.countryId);
			return (
				this.transactionType.indexOf('swift') !== -1
					&& !this.countryNeeded
					&& !this.sortCodeNeeded
					&& !this.bsbCodeNeeded
					&& !this.bankCodeNeeded
					&& !this.branchCodeNeeded
					&& !this.abaNeeded
					|| (this.transactionType.indexOf('local') !== -1
						&& countries.indexOf(this.data.countryId) !== -1
						&& !this.countryNeeded
						&& !this.sortCodeNeeded
						&& !this.bsbCodeNeeded
						&& !this.bankCodeNeeded
						&& !this.branchCodeNeeded
						&& !this.abaNeeded
					) ||
					(selectedCountry && currencies[selectedCountry])
			);
		},
		countryFieldNeeded() {
			return this.transactionType;
		},
		isNotVerified() {
			return !this.isVerified;
		},
		computedData() {
			return { ...this.data };
		},
		computedRateData() {
			return { ...this.data };
		},
		buyWatcher() {
			return [this.data.buyAmount, this.data.buyCurrencyId];
		},
		sellWatcher() {
			return [this.data.sellAmount, this.data.sellCurrencyId];
		},
		insufficientFunds() {
			if (this.data.sellCurrencyId !== 'eur') {
				return false;
			} else {
				if (this.currentFeeWalletAmount) {
					return this.currentWallet && (parseFloat(this.currentWalletAmount.balance) < parseFloat(this.data.sellAmount) || (parseFloat(this.currentFeeWalletAmount.balance) < parseFloat(this.fee || 0).toFixed(2)));
				} else {
					return this.currentWallet && parseFloat(this.currentWalletAmount.balance) < this.estimatedTotal;
				}
			}
		},
		estimatedTotal() {
			return this.data.sellAmount ? (parseFloat(this.data.sellAmount) + parseFloat(this.fee || 0)).toFixed(2) : (0).toFixed(2);
		},
		authenticatorData() {
			return {
				walletId: this.walletId,
				amount: this.data.buyAmount,
				currencyId: this.data.buyCurrencyId,
				beneficiaryAccountNumber: this.data.accountNumber,
			};
		},
		submittedData() {
			const d = this.data;
			return {
				beneficiaryRequest: {
					type: d.transferType,
					name: d.name,
					surname: d.surname,
					companyName: d.companyName,
					countryId: d.countryId,
					address: d.address,
					city: d.city,
					state: d.state,
					zipCode: d.postcode,
					accountNumber: d.accountNumber,
					bic: d.bic,
					sortCode: d.sortCode,
					bsbCode: d.bsbCode,
					bankCode: d.bankCode,
					branchCode: d.branchCode,
					aba: d.aba
				},
				serviceId: d.type,
				currencyId: d.sellCurrencyId,
				buyCurrencyId: d.buyCurrencyId,
				amount: d.sellAmount,
				buyAmount: d.buyAmount,
				fixedSide: d.fixedSide,
				details: d.details,
				purpose: d.purpose,
				files: d.files,
				transactionTemplateId: this.transactionTemplate?.id,
				templateName: d.templateName,
				changeTemplate: d.changeTemplate,
			};
		}
	},
	watch: {
		computedData: {
			handler(nVal, oVal) {
				//Type change watcher
				const getTypeValues = ['countryId', 'sellCurrencyId', 'buyCurrencyId'];
				const changed = getTypeValues.map(v => nVal[v] && nVal[v] !== oVal[v]).reduce((a, b) => a || b, false);
				if (changed && !this.lockState) {
					this.getType();
				}
			},
			deep: true
		},
		'data.buyAmount': function(amount) {
			if (amount && !this.sellDisabled && !this.buyDisabled) {
				this.data.sellAmount = '';
			}
		},
		'data.sellAmount': function(amount) {
			if (amount && !this.sellDisabled && !this.buyDisabled) {
				this.data.buyAmount = '';
			}
		},

		walletId(next, previous) {
			if (next !== '' && next !== previous) {
				this.data.sellCurrencyId = '';
				setTimeout(() => {
					this.data.sellCurrencyId = this.walletDefaultCurrency;
				}, 10);
			}
		}
	},
	methods: {
		...mapActions('countries', [
			'getSwiftCountries'
		]),
		...mapActions('notifications', [
			'pushError'
		]),
		...mapActions('auth', ['init2fa']),
		...mapActions('transfer', ['currencyCloudPrepare']),
		handleCurrencyChange() {
			if (this.data.buyCurrencyId && this.data.sellCurrencyId) {
				if (this.data.buyAmount) {
					this.sellDisabled = true;
					this.data.fixedSide = 'buy';
				} else if (this.data.sellAmount) {
					this.buyDisabled = true;
					this.data.fixedSide = 'sell';
				}
				this.getRate();
			}
		},
		handleBuyChange() {
			if (this.data.buyAmount && this.data.buyCurrencyId && this.data.sellCurrencyId) {
				this.sellDisabled = true;
				this.data.fixedSide = 'buy';
				this.getRate();
			} else {
				this.sellDisabled = false;
			}
			
		},
		handleSellChange() {
			if (this.data.sellAmount && this.data.buyCurrencyId && this.data.sellCurrencyId) {
				this.buyDisabled = true;
				this.data.fixedSide = 'sell';
				this.getRate();
			} else {
				this.buyDisabled = false;
			}
		},
		handleRateError() {
			this.resetDisabled();
			this.data.rate = null;
			this.buyAmount = '';
			this.sellAmount = '';
		},
		resetDisabled() {
			this.sellDisabled = false;
			this.buyDisabled = false;
		},
		async getRate() {
			if (this.rateLoading) return;
			const options = {
				method: 'GET',
				url: endpoints.transfer.currencyCloudRate({ walletId: this.walletId }),
				params: {
					currencyId: this.data.sellCurrencyId,
					buyCurrencyId: this.data.buyCurrencyId,
					amount: this.data.fixedSide == 'buy' ? `${Number(this.data.buyAmount).toFixed(2)}` : `${Number(this.data.sellAmount).toFixed(2)}`,
					fixedSide: this.data.fixedSide
				}
			};
			if (Object.entries(options.params).map(v => v[1]).reduce((a, b) => !!a && !!b, true)) {
				try {
					this.rateLoading = true;
					const { data } = await axios(options);
					this.data = {
						...this.data,
						...data
					};
				} catch (err) {
					this.pushError(err?.message);
					this.handleRateError();
				}
				this.rateLoading = false;
			}


		},
		getTypeWithDebounce() {
			if (this.accountNumber.length < 6) {
				this.transactionType = '';
				this.lockState = 0;
			} else {
				this.data.bic = '';
				this.data.countryId = '';
				this.debouncedGetType();
			}
		},
		getType() {
			if (this.accountNumber.length >= 6 && this.data.buyCurrencyId) {
				return new Promise((resolve, reject) => {
					this.currentlyLoading = true;
					this.accountNumber = this.accountNumber.replace(/\s+/g, '');
					const url = endpoints.transfer.type({ walletId: this.walletId });
					const params = {
						type: 'currency_cloud',
						currencyId: this.data.sellCurrencyId,
						buyCurrencyId: this.data.buyCurrencyId,
						accountNumber: this.accountNumber,
						bic: this.data.bic
					};

					if (this.data.countryId !== null) {
						params.countryId = this.data.countryId;
					}

					axios.get(url, { params })
						.then(response => {
							if (response.data) {
								const { data } = response;

								this.transactionType = data.serviceId;
								this.data.accountNumber = data.beneficiaryAccountNumber;
								// this.processing_time = data.processing_time;
								this.isIbanValid = data.isIbanValid;
								this.ibanNeeded = data.requiredFields.iban || false;
								this.sortCodeNeeded = data.requiredFields.sortCode || false;
								this.bsbCodeNeeded = data.requiredFields.bsbCode || false;
								this.bankCodeNeeded = data.requiredFields.bankCode || false;
								this.branchCodeNeeded = data.requiredFields.branchCode || false;
								this.abaNeeded = data.requiredFields.aba || false;
								this.countryNeeded = data.requiredFields.countryId || false;
								
								this.lockState = 0;

								if (data.beneficiaryBic && data.beneficiaryCountryId) {
									this.lockState = 1;
								}

								if (data.beneficiaryCountryId) {
									this.data.countryId = data.beneficiaryCountryId.toLowerCase();
								}
								if (data.beneficiaryBic) {
									this.data.bic = data.beneficiaryBic;
								}

								this.getFee();
								resolve(data);
							} else {
								this.getFee();
								resolve(response);
							}
							setTimeout(() => {
								this.currentlyLoading = false;
							}, 150);
						})
						.catch(error => {
							this.pushError(error?.message);
							setTimeout(() => {
								this.currentlyLoading = false;
							}, 150);
							reject(error);
						});
				});
			}
		},
		getDebouncedFee: debounce(function () {
			if (this.data.buyAmount) {
				this.data.buyAmount = Number(this.data.buyAmount).toFixed(2);
			}

			if (this.data.sellAmount) {
				this.data.sellAmount = Number(this.data.sellAmount).toFixed(2);
			}

			this.apiError = null;
			this.getFee();
		}, 200),
		getFee() {
			if (((this.data.buyAmount && this.data.buyAmount > 0) || (this.data.sellAmount && this.data.sellAmount > 0))
					&& this.accountNumber
					&& this.transactionType
					&& this.data.countryId
			) {
				return new Promise((resolve, reject) => {
					this.currentlyLoading = true;
					const params = {
						'direction': 'outgoing',
						'serviceId': this.transactionType,
						'currencyId': this.data.sellCurrencyId,
						'buyCurrencyId': this.data.buyCurrencyId,
						'amount': this.data.sellAmount,
						'buyAmount': this.data.buyAmount,
						'fixedSide': this.data.fixedSide
					};

					if (this.data.countryId) {
						params.countryId = this.data.countryId;
					}
					const url = endpoints.transfer.fee({ walletId: this.walletId });

					axios.get(url + '?' + global.encodeQueryData(params))
						.then(response => {
							if (response.data) {
								resolve(response.data.amount);
								this.fee = Number(response.data.amount).toFixed(2);
							} else {
								resolve(response);
							}
							setTimeout(() => {
								this.currentlyLoading = false;
							}, 150);
						})
						.catch(error => {
							this.fee = null;
							this.apiError = error.response.data.error.message;
							setTimeout(() => {
								this.currentlyLoading = false;
							}, 150);
							reject(error);
						});
				});
			}
		},
		async transferMoney(action) {
			this.apiError = null;

			this.$store.commit({
				type: types.HIDE_ERROR_NOTIFICATION
			});

			const result = await this.$validator.validateAll();
			if (result) {
				this.transferAction = action;
				this.prepareTransfer();
			}
		},
		async prepare2fa() {
			try {
				await this.init2fa({
					type: 'transfer',
					data: {
						walletId: this.walletId,
						amount: this.data.buyAmount,
						currencyId: this.data.buyCurrencyId,
						beneficiaryAccountNumber: this.data.accountNumber,
					}
				});
				this.currentAction = 'step2';
			} catch (error) {
				//
			}
			setTimeout(() => {
				this.isSubmitting = false;
				this.currentlyLoading = false;
			}, 150);
		},
		async prepareTransfer() {
			this.currentlyLoading = true;
			this.data.type = this.transactionType;
			this.isSubmitting = true;
			try {
				await this.currencyCloudPrepare({
					data: this.submittedData, 
					walletId: this.walletId 
				});
				await this.prepare2fa();
			} catch (err) {
				this.pushError(err?.message);
				this.isSubmitting = false;
				this.currentlyLoading = false;
			}
		},
		async submit(code) {
			const options = {
				method: 'POST',
				url: endpoints.transfer.currencyCloud({ walletId: this.walletId }),
				data: {
					code,
					...this.submittedData
				}
			};
			try {
				const response = await axios(options);
				const { data } = response;
				this.transactionId = data.id;
				this.handleSuccess();
				this.$refs.sms.reset();
			} catch(err) {
				this.pushError(err.message);
				this.$refs.sms.reset();
			}
		},
		async submitInitiation(code) {
			const options = {
				method: 'POST',
				url: endpoints.transfer.initiateCurrencyCloud({ walletId: this.walletId }),
				data: {
					code,
					...this.submittedData
				}
			};
			try {
				await axios(options);
				this.handleSuccessInitiate();
				this.$refs.sms.reset();
			} catch(err) {
				this.pushError(err.message);
				this.$refs.sms.reset();
			}
		},
		async handleBack() {
			this.getRate();

			this.currentAction = 'step1';

			this.apiError = null;

			this.$store.commit({
				type: types.HIDE_ERROR_NOTIFICATION
			});
		},
		handleSuccess() {
			this.successAction = 1;
			this.$store.dispatch('wallet/updateWalletList');
		},
		handleSuccessInitiate() {
			this.successAction = 2;
			this.$store.dispatch('wallet/updateWalletList');
		},

		doAction() {
			if (this.isFirstInvalid || this.isLastInvalid || this.isAccNumInvalid || this.isAmountInvalid) {
				this.error = 1;
			} else {
				this.error = 2;
			}
		},
		checkIsFirstInvalid() {
			if (this.firstName < 3) {
				this.isFirstInvalid = true;
			} else {
				this.isFirstInvalid = false;
			}
		},
		checkIsLastInvalid() {
			if (this.lastName < 3) {
				this.isLastInvalid = true;
			} else {
				this.isLastInvalid = false;
			}
		},
		checkIsAccNumInvalid() {
			if (this.accountNumber < 3) {
				this.isAccNumInvalid = true;
			} else {
				this.isAccNumInvalid = false;
			}
		},
		checkIsAmountInvalid() {
			if (this.buyAmount < 20 || this.sellAmount < 20) {
				this.isAmountInvalid = true;
			} else {
				this.isAmountInvalid = false;
			}
		},
		capitalizeFirstLetter(string) {
			return string.charAt(0).toUpperCase() + string.slice(1);
		},
		currencyLabel({ currencyId }) {
			return currencyId.toUpperCase();
		},
		addCustomValidation() {
			const variants = [
				{
					currency: 'GBP',
					country: 'GB',
					rules: {
						sortCode: {
							validate: value => value && value.length == 6,
							message: 'This field must be 6 characters long.'
						},
						accountNumber: {
							validate: value => value && value.length == 8,
							message: 'This field must be 8 characters long.'
						}
					}
				},
				{
					currency: 'USD',
					country: 'US',
					rules: {
						aba: {
							validate: value => value && value.length == 9,
							message: 'This field must be 9 characters long.'
						},
						accountNumber: {
							validate: value => value && value.length >= 1 && value.length <= 17 && /^[A-Z0-9]+$/.test(value),
							message: 'This field must be between 1 and 17 characters long. Numbers and uppercase letters only.'
						}
					}
				},
				{
					currency: 'NOK',
					country: 'NO',
					rules: {
						bankCode: {
							validate: value => value && value.length == 4 && /^[0-9]+$/.test(value),
							message: 'This field must have 4 numbers.'
						},
						accountNumber: {
							validate: value => value && value.length == 7,
							message: 'This field must be 7 characters long.'
						}
					}
				},
				{
					currency: 'DKK',
					country: 'DK',
					rules: {
						bankCode: {
							validate: value => value && value.length == 4 && /^[0-9]+$/.test(value),
							message: 'This field must have 4 numbers.'
						},
						accountNumber: {
							validate: value => value && value.length >= 4 && value.length <= 10,
							message: 'This field must be between 4 and 10 characters long.'
						}
					}
				},
				{
					currency: 'CAD',
					country: 'CA',
					rules: {
						bankCode: {
							validate: value => value && value.length == 3 && /^[0-9]+$/.test(value),
							message: 'This field must have 3 numbers.'
						},
						branchCode: {
							validate: value => value && value.length == 5 && /^[0-9]+$/.test(value),
							message: 'This field must have 5 numbers.'
						},
						accountNumber: {
							validate: value => value && value.length >= 7 && value.length <= 35 && /^[0-9]+$/.test(value),
							message: 'This field must be between 7 and 35 characters long and contain numbers only.'
						}
					}
				},
				{
					currency: 'AUD',
					country: 'AU',
					rules: {
						bsbCode: {
							validate: value => value && value.length == 6 && /^[A-Z0-9]+$/.test(value),
							message: 'This field must be 6 characters long and container numbers only.'
						},
						accountNumber: {
							validate: value => value && value.length >= 5 && value.length <= 20 && /^[0-9]+$/.test(value),
							message: 'This field must be between 5 and 20 characters long. Numbers and uppercase letters only.'
						}
					}
				},
				{
					currency: 'SEK',
					country: 'SE',
					rules: {
						bankCode: {
							validate: value => value && (value.length == 4 || value.length == 5),
							message: 'This field must be 4-5 characters long.'
						},
						accountNumber: {
							validate: value => value && value.length >= 1 && value.length <= 15 && /^[0-9]+$/.test(value),
							message: 'This field must be between 1 and 15 characters long. Numbers only.'
						}
					}
				},
				{
					currency: 'HKD',
					country: 'HK',
					rules: {
						bankCode: {
							validate: value => value && /^[0-9A-Za-z]{3}$/.test(value),
							message: 'This field must be 3 characters long.'
						},
					}
				},
			];
			
			function selectedVariant({ currency, country }) {
				const variant = variants.find(v => 
					v.currency.toUpperCase() == currency.toUpperCase() 
					&& v.country.toUpperCase() == country.toUpperCase()
				);
				return variant;
			}

			Validator.extend('currencyCloud', {
				getMessage: (field, params) => {
					const variant = selectedVariant({
						currency: this.data.buyCurrencyId,
						country: this.data.countryId
					});
					const fieldName = params[0];
					if (!variant && fieldName == 'accountNumber') return 'This field must be at least 6 characters long.';
					const rule = variant.rules[fieldName];
					if (rule) return rule.message;
					return `The ${field} is not valid.`;
				},
				validate: (value, params) => {
					const variant = selectedVariant({
						currency: this.data.buyCurrencyId,
						country: this.data.countryId
					});
					const fieldName = params[0];
					if (!variant && fieldName == 'accountNumber') return value && value.length >= 6;
					if (!variant || !fieldName) return true;

					const rules = variant.rules;
					const rule = rules[fieldName];

					if (!rule) return true;
					return rule.validate(value);
				}
			},
			[
				'field',
				'currency',
				'country'
			]);
		},
		goToPending() {
			this.$emit('go-to-pending');
		},
	}
};
</script>

<style lang="scss" scoped>
	@import './CurrencyCloudTransfer.scss';
</style>

<style lang="scss">
	.swift-form {
		&__purpose {
			.multiselect {
				&__content {
					width: 100%;
				}

				&__option {
					white-space: normal;
				}

				&__single {
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}
		}
	}
	.amount-button {
		display: flex !important;
		justify-content: center;
		align-items: center;
	}
</style>