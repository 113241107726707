var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    staticClass: "form-body persons-form",
    attrs: {
      "action": "#",
      "autocomplete": "disable",
      "disabled": _vm.formDisabled
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onsubmit();
      },
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        $event.preventDefault();
      }
    }
  }, [_vm.isPersonal ? _c('h2', {
    staticClass: "page-title section-title"
  }, [_vm._v(" Personal information ")]) : _vm._e(), _vm.isBusiness ? _c('h2', {
    staticClass: "page-title section-title"
  }, [_vm._v(" Your information ")]) : _vm._e(), _c('fieldset', {
    attrs: {
      "disabled": _vm.details.verification == 'pending'
    }
  }, [_vm.isBusiness ? _c('div', [_c('b-row', [_c('b-col', [_c('h3', [_vm._v("Your role")]), _c('b-row', {
    attrs: {
      "align-v": "stretch"
    }
  }, _vm._l(_vm.roleOptions, function (option) {
    return _c('b-col', {
      key: option.value,
      staticClass: "role-options",
      attrs: {
        "sm": "6",
        "cols": "12",
        "data-registration": "use-of-account"
      },
      on: {
        "click": function click($event) {
          return _vm.setRole(option.value);
        }
      }
    }, [_c('div', {
      staticClass: "role-options__box",
      "class": {
        'role-options__box--active': _vm.data.roleType == option.value
      }
    }, [_c('div', {
      staticClass: "role-options__text"
    }, [_c('h3', [_c('span', {
      staticClass: "radio"
    }), _vm._v(_vm._s(option.text))]), _c('p', {
      domProps: {
        "innerHTML": _vm._s(option.description)
      }
    })])])]);
  }), 1)], 1)], 1)], 1) : _vm._e(), _c('div', [!_vm.owner || _vm.owner && _vm.owner.verification !== 'verified' ? _c('div', {
    staticClass: "verified-owner"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "pr-sm-0",
    attrs: {
      "sm": "6"
    }
  }, [_c('form-error-field', {
    attrs: {
      "name": "name"
    }
  }, [_c('label', {
    staticClass: "label"
  }, [_vm._v("First name (and middle names)")]), _c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "id": "name",
      "type": "text",
      "name": "name",
      "autocomplete": "off_name",
      "placeholder": "Enter your name",
      "debounce": "500",
      "data-registration": "first-name",
      "disabled": _vm.formDisabled,
      "data-vv-as": "First name (and middle names)"
    },
    model: {
      value: _vm.data.name,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "name", $$v);
      },
      expression: "data.name"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('form-error-field', {
    attrs: {
      "name": "surname"
    }
  }, [_c('label', {
    staticClass: "label"
  }, [_vm._v("Last name")]), _c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "id": "surname",
      "type": "text",
      "name": "surname",
      "autocomplete": "off_surname",
      "placeholder": "Enter your last name",
      "debounce": "500",
      "data-registration": "last-name",
      "disabled": _vm.formDisabled,
      "data-vv-as": "Last name"
    },
    model: {
      value: _vm.data.surname,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "surname", $$v);
      },
      expression: "data.surname"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('form-error-field', {
    attrs: {
      "name": "placeOfBirth"
    }
  }, [_c('label', {
    staticClass: "label"
  }, [_vm._v("Place of birth")]), _c('v-multiselect', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "options": _vm.countryOptions,
      "show-labels": false,
      "allow-empty": false,
      "name": "placeOfBirth",
      "label": "name",
      "placeholder": "Select country of birth",
      "track-by": "countryId",
      "return": "countryId",
      "data-registration": "place-of-birth",
      "disabled": _vm.formDisabled,
      "data-vv-as": "Place of birth"
    },
    model: {
      value: _vm.data.placeOfBirth,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "placeOfBirth", $$v);
      },
      expression: "data.placeOfBirth"
    }
  })], 1)], 1)], 1), _vm.isBusiness ? _c('b-row', [_c('b-col', [_c('form-error-field', {
    attrs: {
      "name": "dateOfBirth"
    }
  }, [_c('label', {
    staticClass: "label"
  }, [_vm._v("Date of birth")]), _c('app-datepicker', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "adult": true,
      "name": 'dateOfBirth',
      "data-registration": "date-of-birth",
      "disabled": _vm.formDisabled,
      "data-vv-as": "Date of birth"
    },
    model: {
      value: _vm.data.dateOfBirth,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "dateOfBirth", $$v);
      },
      expression: "data.dateOfBirth"
    }
  })], 1)], 1)], 1) : _vm._e(), _c('multi-address', {
    attrs: {
      "addresses": _vm.data.addresses
    }
  }), _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.isBusiness && _vm.data.roleType == 'representative' ? _c('div', _vm._l(_vm.documents, function (doc) {
    return _c('b-row', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: doc.displayName,
        expression: "doc.displayName"
      }],
      key: doc.type
    }, [_c('b-col', [_c('div', {
      staticClass: "form-group"
    }, [_c('label', [_vm._v(" " + _vm._s(doc.displayName) + " ")]), _c('document-input', {
      attrs: {
        "document": doc,
        "required": true,
        "state": _vm.documentId(doc.type) || !!_vm.data.proofOfResidenceDocument ? true : null,
        "data-registration": "proof-of-residence",
        "disabled": _vm.formDisabled,
        "name": "proofOfResidenceDocumentId",
        "data-vv-as": doc.displayName
      },
      on: {
        "onChange": function onChange(e) {
          return _vm.onDocumentChange(e, doc);
        }
      },
      model: {
        value: _vm.data[doc.id],
        callback: function callback($$v) {
          _vm.$set(_vm.data, doc.id, $$v);
        },
        expression: "data[doc.id]"
      }
    })], 1)])], 1);
  }), 1) : _vm._e()]), _vm.isPersonal ? _c('b-row', [_c('b-col', {
    staticClass: "pt-4"
  }, _vm._l(_vm.documents, function (doc) {
    return _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: doc.displayName,
        expression: "doc.displayName"
      }],
      key: doc.displayName,
      staticClass: "content-item form-group"
    }, [_c('label', [_vm._v(" " + _vm._s(doc.displayName) + " ")]), _c('document-input', {
      attrs: {
        "document": doc,
        "required": true,
        "state": _vm.documentId(doc.type) || !!_vm.data.proofOfResidenceDocument ? true : null,
        "data-registration": "document-".concat(doc.field),
        "disabled": _vm.formDisabled
      },
      on: {
        "onChange": function onChange(e) {
          return _vm.onDocumentChange(e, doc);
        }
      },
      model: {
        value: _vm.data[doc.id],
        callback: function callback($$v) {
          _vm.$set(_vm.data, doc.id, $$v);
        },
        expression: "data[doc.id]"
      }
    })], 1);
  }), 0)], 1) : _vm._e(), _c('b-row', [_c('b-col', [_c('form-error-field', {
    attrs: {
      "name": "countryForTax"
    }
  }, [_c('label', {
    staticClass: "label"
  }, [_vm._v(" Country of residence for tax purpose ")]), _c('v-multiselect', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "options": _vm.countryOptions,
      "show-labels": false,
      "allow-empty": false,
      "name": "countryForTax",
      "label": "name",
      "placeholder": "Select country of residence for tax purpose",
      "track-by": "countryId",
      "return": "countryId",
      "data-registration": "tax-country",
      "disabled": _vm.formDisabled,
      "data-vv-as": "Country of residence for tax purpose"
    },
    model: {
      value: _vm.data.countryForTax,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "countryForTax", $$v);
      },
      expression: "data.countryForTax"
    }
  })], 1)], 1)], 1), _vm.isBusiness ? _c('b-row', [_c('b-col', [_c('form-error-field', {
    staticClass: "text-left mb-12",
    attrs: {
      "name": "politicalExposure"
    }
  }, [_c('label', [_vm._v(" Political exposure "), _c('i', {
    staticClass: "fa fa-question-circle-o pull-right",
    attrs: {
      "aria-hidden": "true"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.$modal.show('political_exposure');
      }
    }
  })]), _c('v-multiselect', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "options": _vm.politicalOptions,
      "show-labels": false,
      "allow-empty": false,
      "name": "politicalExposure",
      "data-vv-as": "Political exposure",
      "label": "name",
      "placeholder": "Select Your political exposure",
      "track-by": "value",
      "return": "value",
      "data-registration": "political-exposure",
      "disabled": _vm.formDisabled
    },
    model: {
      value: _vm.data.politicalExposure,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "politicalExposure", $$v);
      },
      expression: "data.politicalExposure"
    }
  })], 1)], 1)], 1) : _vm._e()], 1) : _vm._e(), _vm.isBusiness ? _c('div', [_c('form-error-field', {
    staticClass: "position-relative",
    attrs: {
      "name": "keyPersons",
      "wrapper": "div"
    }
  }, [_c('b-card', {
    staticClass: "mb-4"
  }, [_c('b-row', {
    attrs: {
      "align-h": "between",
      "align-v": "center"
    }
  }, [_c('b-col', [_c('h3', {
    staticClass: "registration-section-heading"
  }, [_c('strong', [_vm._v("Key persons information")])]), _c('p', [_vm._v("Please provide all of the persons who play role in the company. If person takes couple of roles, select appropriate ones.")])])], 1), _c('b-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.data.mySelfTypes.length > 0 || _vm.data.keyPersons.length > 0,
      expression: "data.mySelfTypes.length > 0 || data.keyPersons.length > 0"
    }],
    staticClass: "key-persons-content mb-4"
  }, [_c('b-col', [_c('b-list-group', [_vm.data.mySelfTypes.length > 0 ? _c('b-list-group-item', {
    key: 'myself'
  }, [_c('b-row', {
    staticClass: "person"
  }, [_c('b-col', {
    staticClass: "person-avatar"
  }, [_c('b-avatar', {
    attrs: {
      "variant": "primary",
      "text": _vm.data.name ? _vm.data.name.charAt(0) : null
    }
  })], 1), _c('b-col', {
    staticClass: "person-name"
  }, [_c('div', [_c('small', [_vm._v(_vm._s(_vm.data.mySelfTypes.join(', ').toUpperCase()))])]), _c('span', [_vm._v(_vm._s(_vm.data.name) + " " + _vm._s(_vm.data.surname))])]), _c('b-col', {
    staticClass: "person-buttons"
  }, [!_vm.formDisabled ? _c('a', {
    staticClass: "btn btn-primary person-button",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.$set(_vm.addPersonData, 'type', _vm.addPersonData.type !== 'myself' ? 'myself' : null);
      }
    }
  }, [_c('i', {
    staticClass: "fas fa-user-edit"
  })]) : _vm._e(), !_vm.formDisabled ? _c('a', {
    staticClass: "btn btn-danger person-button",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.removeItemMain();
      }
    }
  }, [_c('i', {
    staticClass: "fas fa-user-minus"
  })]) : _vm._e()])], 1)], 1) : _vm._e(), _vm._l(_vm.data.keyPersons, function (person, i) {
    return _c('b-list-group-item', {
      key: i + 1
    }, [_c('b-row', {
      staticClass: "person"
    }, [_c('b-col', {
      staticClass: "person-avatar"
    }, [_c('b-avatar', {
      attrs: {
        "variant": "primary",
        "text": person.name ? person.name.charAt(0) : ''
      }
    })], 1), _c('b-col', {
      staticClass: "person-name"
    }, [_c('div', [_c('small', [_vm._v(" " + _vm._s(_vm.displayPersonTypes(person)) + " ")])]), _c('span', [_vm._v(_vm._s(person.name) + " " + _vm._s(person.surname))])]), _c('b-col', {
      staticClass: "person-buttons"
    }, [!person._completed ? _c('a', {
      staticClass: "btn btn-primary person-button",
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.editPerson(person, i);
        }
      }
    }, [_c('i', {
      staticClass: "fas fa-user-edit"
    })]) : _vm._e(), _c('a', {
      staticClass: "btn btn-danger person-button",
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.removeItem(person, i);
        }
      }
    }, [_c('i', {
      staticClass: "fas fa-user-minus"
    })])])], 1)], 1);
  })], 2)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-row', {
    staticClass: "person-add-buttons"
  }, [_c('b-col', [_c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.data.roleType !== 'agent' && _vm.data.name && _vm.data.surname ? _c('b-button', {
    staticClass: "mr-4",
    attrs: {
      "variant": "primary",
      "pressed": _vm.addPersonData.type == 'myself',
      "data-registration": "add-person-myself",
      "disabled": _vm.formDisabled
    },
    on: {
      "click": function click($event) {
        return _vm.$set(_vm.addPersonData, 'type', _vm.addPersonData.type !== 'myself' ? 'myself' : null);
      }
    }
  }, [_vm._v(" Add myself ")]) : _vm._e()], 1), _c('b-button', {
    attrs: {
      "variant": "primary",
      "pressed": _vm.addPersonData.type == 'person',
      "data-registration": "add-person",
      "disabled": _vm.formDisabled
    },
    on: {
      "click": function click($event) {
        return _vm.$set(_vm.addPersonData, 'type', _vm.addPersonData.type !== 'person' ? 'person' : null);
      }
    }
  }, [_vm._v(" Add person ")])], 1)], 1)], 1)], 1)], 1)], 1), _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.addPersonData.type ? _c('div', [_c('b-row', [_c('b-col', {
    staticClass: "text-center mb-4"
  }, [_c('h3', [_vm._v("Add " + _vm._s(_vm.addPersonData.type))]), _c('small', [_vm._v("Select one or multiple options below")])])], 1), _c('checkbox-list', {
    staticClass: "mb-4",
    attrs: {
      "items": _vm.personOptions,
      "value": _vm.addPersonData.type == 'myself' && _vm.data.mySelfTypes || _vm.addPersonData.type == 'person' && _vm.addPersonData.options,
      "option-title": "title",
      "data-registration": "person-options"
    },
    on: {
      "change": function change(v) {
        return _vm.addPersonData.type == 'myself' && _vm.toggleMyselfOption(v) || _vm.addPersonData.type == 'person' && _vm.togglePersonOption(v);
      }
    }
  })], 1) : _vm._e()]), _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.addPersonData.type == 'person' && Object.keys(_vm.addPersonData.options).map(function (o) {
    return _vm.addPersonData.options[o];
  }).reduce(function (a, b) {
    return a || b;
  }, false) ? _c('div', [_c('b-row', [_c('b-col', {
    staticClass: "text-center mb-4"
  }, [_c('h3', [_vm._v("Add person details")])])], 1), _c('b-card', {
    staticClass: "mb-4",
    attrs: {
      "id": "person-input"
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "text-right"
        }, [_c('b-button', {
          attrs: {
            "variant": "primary",
            "disabled": _vm.formDisabled
          },
          on: {
            "click": function click($event) {
              return _vm.saveNewPerson();
            }
          }
        }, [_c('i', {
          staticClass: "fas fa-save mr-1"
        }), _vm._v(" Save this person ")])], 1)];
      },
      proxy: true
    }], null, false, 217938237)
  }, [_c('b-row', [_c('b-col', [_c('person-input', {
    ref: "person",
    attrs: {
      "i-am": false,
      "person-type-options": _vm.addPersonData.options,
      "proof-of-residence": true,
      "allowtypes": "",
      "data-registration": "key-person",
      "field-prefix": "keyPersons"
    },
    model: {
      value: _vm.addPersonData.person,
      callback: function callback($$v) {
        _vm.$set(_vm.addPersonData, "person", $$v);
      },
      expression: "addPersonData.person"
    }
  })], 1)], 1)], 1)], 1) : _vm._e()])], 1) : _vm._e(), _c('b-row', [_c('b-col', [_c('b-input-group', {
    staticClass: "text-left"
  }, [_c('div', {
    staticClass: "custom-controls-stacked",
    attrs: {
      "role": "group"
    }
  }, [_c('div', {
    staticClass: "custom-control custom-checkbox",
    "class": {
      'has-error': _vm.errors.has('us_citizen')
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.data.isUsCitizen,
      expression: "data.isUsCitizen"
    }],
    staticClass: "custom-control-input",
    attrs: {
      "id": "us_citizen",
      "type": "checkbox",
      "name": "us_citizen",
      "autocomplete": "off",
      "value": "0",
      "data-registration": "us-citizen",
      "disabled": _vm.formDisabled
    },
    domProps: {
      "checked": Array.isArray(_vm.data.isUsCitizen) ? _vm._i(_vm.data.isUsCitizen, "0") > -1 : _vm.data.isUsCitizen
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.data.isUsCitizen,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = "0",
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.data, "isUsCitizen", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.data, "isUsCitizen", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.data, "isUsCitizen", $$c);
        }
      }
    }
  }), _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": "us_citizen"
    }
  }, [_vm._v("I am US citizen")])]), _vm.data.roleType !== 'agent' ? _c('div', {
    staticClass: "custom-control custom-checkbox",
    "class": {
      'has-error': _vm.errors.has('service_agreement')
    },
    on: {
      "click": function click(e) {
        if (!_vm.data.service_agreement) {
          e.preventDefault();

          _vm.$refs.agreements.open('service_agreement');
        }
      }
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.data.service_agreement,
      expression: "data.service_agreement"
    }, {
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    staticClass: "custom-control-input",
    attrs: {
      "id": "service_agreement",
      "type": "checkbox",
      "name": "service_agreement",
      "autocomplete": "off",
      "data-registration": "service-agreement",
      "disabled": _vm.formDisabled
    },
    domProps: {
      "checked": Array.isArray(_vm.data.service_agreement) ? _vm._i(_vm.data.service_agreement, null) > -1 : _vm.data.service_agreement
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.data.service_agreement,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.data, "service_agreement", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.data, "service_agreement", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.data, "service_agreement", $$c);
        }
      }
    }
  }), _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": "service_agreement"
    }
  }, [_vm._v("I accept "), _c('a', {
    attrs: {
      "href": "",
      "target": "_blank"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.$refs.agreements.open('service_agreement');
      }
    }
  }, [_vm._v("General service agreement")])])]) : _vm._e(), _vm.data.roleType !== 'agent' ? _c('div', {
    staticClass: "custom-control custom-checkbox",
    "class": {
      'has-error': _vm.errors.has('privacy_policy')
    },
    on: {
      "click": function click(e) {
        if (_vm.data.privacy_policy !== '1') {
          e.preventDefault();

          _vm.$refs.agreements.open('privacy_policy');
        }
      }
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.data.privacy_policy,
      expression: "data.privacy_policy"
    }, {
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    staticClass: "custom-control-input",
    attrs: {
      "id": "privacy_policy",
      "type": "checkbox",
      "name": "privacy_policy",
      "autocomplete": "off",
      "data-registration": "privacy-policy",
      "disabled": _vm.formDisabled
    },
    domProps: {
      "checked": Array.isArray(_vm.data.privacy_policy) ? _vm._i(_vm.data.privacy_policy, null) > -1 : _vm.data.privacy_policy
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.data.privacy_policy,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.data, "privacy_policy", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.data, "privacy_policy", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.data, "privacy_policy", $$c);
        }
      }
    }
  }), _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": "privacy_policy"
    }
  }, [_vm._v("I accept "), _c('a', {
    attrs: {
      "href": "",
      "target": "_blank"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.$refs.agreements.open('privacy_policy');
      }
    }
  }, [_vm._v("Privacy Policy")])])]) : _vm._e(), _c('div', {
    staticClass: "custom-control custom-checkbox",
    "class": {
      'has-error': _vm.errors.has('newsletter_subscribed')
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.data.isNewsletterSubscribed,
      expression: "data.isNewsletterSubscribed"
    }],
    staticClass: "custom-control-input",
    attrs: {
      "id": "newsletter_subscribed",
      "type": "checkbox",
      "name": "newsletter_subscribed",
      "autocomplete": "off",
      "value": "1",
      "data-registration": "newsletter",
      "disabled": _vm.formDisabled
    },
    domProps: {
      "checked": Array.isArray(_vm.data.isNewsletterSubscribed) ? _vm._i(_vm.data.isNewsletterSubscribed, "1") > -1 : _vm.data.isNewsletterSubscribed
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.data.isNewsletterSubscribed,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = "1",
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.data, "isNewsletterSubscribed", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.data, "isNewsletterSubscribed", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.data, "isNewsletterSubscribed", $$c);
        }
      }
    }
  }), _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": "newsletter_subscribed"
    }
  }, [_vm._v("I subscribe for newsletters")])])])])], 1)], 1)], 1)]), _c('b-row', [_c('b-col', {
    staticClass: "mt-4 mb-4 text-center"
  }, [_c('notification'), _c('input', {
    staticClass: "btn btn-small btn-primary w-100",
    "class": _vm.buttonClass,
    attrs: {
      "disabled": _vm.nextEnabled == false,
      "type": "submit",
      "data-registration": "next"
    },
    domProps: {
      "value": _vm.buttonText
    }
  })], 1)], 1), _vm.allowSkip ? _c('b-row', [_c('b-col', {
    staticClass: "text-center"
  }, [_c('router-link', {
    staticClass: "framelink skiplink",
    attrs: {
      "to": {
        name: 'home'
      },
      "tag": "a",
      "exact": "",
      "data-registration": "skip"
    },
    on: {
      "click": function click($event) {
        return _vm.$forceUpdate();
      }
    }
  }, [_vm.details.verification !== 'pending' ? _c('span', [_vm._v("Skip verification")]) : _vm._e(), _vm.details.verification == 'pending' ? _c('span', [_vm._v("Back to main page")]) : _vm._e()])], 1)], 1) : _vm._e(), _c('modal', {
    attrs: {
      "adaptive": true,
      "name": "political_exposure",
      "height": "auto"
    }
  }, [_c('div', {
    staticClass: "close",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('political_exposure');
      }
    }
  }), _c('div', {
    staticClass: "ml-4 mr-4 mt-4 mb-4"
  }, [_vm._v(" Politically exposed person – private individual who is or has been entrusted with prominent public functions mentioned below including the functions in the Republic of Lithuania, European Union, international or foreign state institutions no more than one year ago, and immediate family members or persons known to be close associates of such person: "), _c('br'), _c('br'), _vm._v("1) Head of State, Heads of Government, minister, vice minister and deputy minister, secretary of the State, chancellor of Parliament, Government or Ministry; 2) Member of parliament; "), _c('br'), _vm._v("3) Member of the Supreme Court, of the Constitutional Court or of other high-level judicial bodies whose decisions are not subject to further appeal; "), _c('br'), _vm._v("4) Member of the managing body of the Lithuanian Chamber of Auditors or of the board of the central bank; "), _c('br'), _vm._v("5) The ambassador, chargés d’affaires and high-ranking officer in the armed forces; "), _c('br'), _vm._v("6) Member of the management or supervisory body of the publicly administered undertaking; "), _c('br'), _vm._v("7) Director, deputy director or member of the management or supervisory body of the international intergovernmental organization; :sunglasses: Head, deputy head or member of the management body of political party. ")])]), _c('modal', {
    attrs: {
      "adaptive": true,
      "name": "phone",
      "height": "auto"
    }
  }, [_c('div', {
    staticClass: "close",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('phone');
      }
    }
  }), _c('div', {
    staticClass: "ml-4 mr-4 mt-4 mb-4"
  }, [_vm._v(" Person will receive SMS to this phone number to verify their identity. ")])]), _c('agreements', {
    ref: "agreements",
    attrs: {
      "on-success": _vm.submitAgreement,
      "on-close": _vm.cancelAgreement,
      "submit-on-click": false
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }