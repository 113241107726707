import { endpoints } from '~/config';
import { CLEAR_TRANSFER_ERRORS } from '~/store/mutation-types';

export default {
	transferSuccess: () => {
		alert('success');
	},
	transferFailure: () => {
		alert('failure');
	},
	clearTransferErrors: ({ commit }) => {
		commit({
			type: CLEAR_TRANSFER_ERRORS
		});
	},
	async sepaPrepare(props, { data, walletId }) {
		const options = {
			method: 'POST',
			url: endpoints.transfer.sepaPrepare({ walletId }),
			data
		};
		try {
			const { data } = await axios(options);
			return Promise.resolve(data);
		} catch (err) {
			return Promise.reject(err);
		}
	},
	async swiftPrepare(props, { data, walletId }) {
		const options = {
			method: 'POST',
			url: endpoints.transfer.swiftPrepare({ walletId }),
			data
		};
		try {
			const { data } = await axios(options);
			return Promise.resolve(data);
		} catch (err) {
			return Promise.reject(err);
		}
	},
	async currencyCloudPrepare(props, { data, walletId }) {
		const options = {
			method: 'POST',
			url: endpoints.transfer.currencyCloudPrepare({ walletId }),
			data
		};
		try {
			const { data } = await axios(options);
			return Promise.resolve(data);
		} catch (err) {
			return Promise.reject(err);
		}
	}
};