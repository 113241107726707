export default {
	errors: {
		picked: null,
		fromWallet: null,
		fromBalance: null,
		toUser: null,
		transferAmount: null,
		transferCurrency: null,
		description: null,
		recipientCountry: null,
		recipient: null,
		receiversIBAN: null,
		transferType: null
	}
};