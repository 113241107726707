import * as types from '../mutation-types';

export default {
	state: {
		name: null,
		surname: null,
		nationality: null,
		country_id: null,
		city: null,
		state: null,
		address: null,
		zip_code: null,
		currency_id: 'eur',
		terms_accepted: null,
		newsletter_subscribed: null,
		street: null,
		street_no: null,
		flat_no: null,
		correspondence_country_id: null,
		correspondence_city: null,
		correspondence_state: null,
		correspondence_address: null,
		correspondence_zip_code: null,
		correspondence_street: null,
		correspondence_street_no: null,
		correspondence_flat_no: null,
		country_for_tax_purpose: null,
		us_citizen: 0,
		political_exposure: null,

		place_of_birth: null,

		same_as_registration_address: 0,

		director_of_company: 0,
		is_shareholder: 0,
		is_representative: 0,

		proof_of_residence_document: null,

		persons: [],
		permissions: [],
		currentPerson: null,
		currentUser: null,
		code: null,
	},
	mutations: {
		[types.UPDATE_PROFILE](state, payload) {
			for (let key in payload.data) {
				if (Object.prototype.hasOwnProperty.call(payload.data, key)) {
					if (state[key] !== payload.data[key]) {
						state[key] = payload.data[key];
					}
				}
			}
		},
		[types.NORMALIZE_PERSONS](state, payload) {
			let records = [];
			let key = 1;
			payload.data.forEach(function (item) {
				records.push({
					key: key,
					type: item.type,
					name: item.name,
					surname: item.surname,
					code: item.code,
					date_of_birth: item.date_of_birth,
					address: {
						country_id: item.country_id,
						country: item.country,
						city: item.city,
						flat_no: item.flat_no,
						state: item.state,
						address: item.address,
						zip_code: item.zip_code,
						street: item.street,
						street_no: item.street_no,
					},
					phone: item.phone,
					email: item.email,
					political_exposure: item.political_exposure,
					proof_of_residence_document: item.proof_of_residence_document ? item.proof_of_residence_document : null,
					proof_of_residence_document_id: item.proof_of_residence_document_id ? item.proof_of_residence_document_id : null,
					person_id: item.person_id,
					user_person_data_id: item.user_person_data_id,
					idenfy_completed: item.idenfy_completed
				});
				key++;
			});
			state['persons'] = records;
		},
		[types.NORMALIZE_CURRENT_PERSON](state, { data }) {
			state.currentPerson = data;
		},
		[types.UPDATE_PERSONS](state, payload) {
			const lastItem = state['persons'][state['persons'].length - 1];
			const key = lastItem ? lastItem.key + 1 : 1;
			state['persons'].push({
				key: key,
				type: payload.data,
				name: '',
				surname: '',
				code: '',
				date_of_birth: '',
				address: {
					country_id: null,
					country: null,
					city: null,
					flat_no: null,
					state: null,
					address: null,
					zip_code: null,
					street: null,
					street_no: null,
				},
				phone: '',
				email: '',
				political_exposure: '',
				proof_of_residence_document: null,
				proof_of_residence_document_id: null,
			});
		},
		[types.DELETE_PERSON](state, payload) {
			for (let i = 0; i < state['persons'].length; i++) {
				if (state['persons'][i].key === payload.data) {
					state['persons'].splice(i, 1);
					break;
				}
			}
		},
		[types.SET_PERSON_VERIFIED](state) {
			state['currentPerson']['idenfy_completed'] = 1;
		},
		[types.SET_CURRENT_USER](state, user) {
			state.currentUser = user;
		},
		[types.SET_USER_PERMISSIONS](state, permissions) {
			state.permissions = permissions;
		},
		[types.SET_WALLET_PERMISSIONS](state, permissions) {
			state.walletPermissions = permissions;
		},
	},
	getters: {
		profileFilled: state => {
			return state.surname !== null;
		},
		currentUser: state => {
			return state.currentUser;
		},
		isGranted(state) {
			return role => {
				return state.permissions.data.includes(role);
			};
		}
	},
};
