var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    staticClass: "person-input-form",
    attrs: {
      "data-vv-scope": "person"
    }
  }, [_vm.allowtypes ? _c('b-row', [_c('b-col', [_c('b-form-group', [_c('label', {
    attrs: {
      "for": _vm.id + 'personType'
    }
  }, [_vm._v(" Type ")]), _c('b-input-group', {
    staticClass: "text-left mb-12"
  }, [_c('v-multiselect', {
    attrs: {
      "options": _vm.typeOptions,
      "show-labels": false,
      "allow-empty": false,
      "name": _vm.id + 'personType',
      "label": "name",
      "placeholder": "Select type",
      "track-by": "name",
      "return": "value",
      "disabled": _vm.isNotCompany,
      "data-person": "entity-type"
    },
    model: {
      value: _vm.person.entityType,
      callback: function callback($$v) {
        _vm.$set(_vm.person, "entityType", $$v);
      },
      expression: "person.entityType"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _c('div', [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": _vm.person.entityType !== 'person' || _vm.fullWidth ? 12 : 6
    }
  }, [_c('form-error-field', {
    attrs: {
      "name": _vm.fieldName('name'),
      "scope": "person"
    }
  }, [_c('label', {
    attrs: {
      "for": _vm.fieldName('name')
    }
  }, [_vm._v(" " + _vm._s(_vm.person.entityType === 'person' ? 'Name (and middle names)' : 'Company Name') + " ")]), _c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "id": _vm.fieldName('name'),
      "name": _vm.fieldName('name'),
      "type": "text",
      "autocomplete": "off_autocomplete",
      "placeholder": "Enter name",
      "data-person": "name",
      "data-vv-as": "Name"
    },
    model: {
      value: _vm.person.name,
      callback: function callback($$v) {
        _vm.$set(_vm.person, "name", $$v);
      },
      expression: "person.name"
    }
  })], 1)], 1), _vm.person.entityType === 'person' ? _c('b-col', {
    attrs: {
      "sm": _vm.fullWidth ? 12 : 6
    }
  }, [_c('form-error-field', {
    attrs: {
      "name": _vm.fieldName('surname'),
      "scope": "person"
    }
  }, [_c('label', {
    attrs: {
      "for": _vm.fieldName('surname')
    }
  }, [_vm._v(" Last name ")]), _c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "id": _vm.fieldName('surname'),
      "name": _vm.fieldName('surname'),
      "type": "text",
      "autocomplete": "off_autocomplete",
      "placeholder": "Enter last name",
      "debounce": "500",
      "data-person": "last-name",
      "data-vv-as": "Last name"
    },
    model: {
      value: _vm.person.surname,
      callback: function callback($$v) {
        _vm.$set(_vm.person, "surname", $$v);
      },
      expression: "person.surname"
    }
  })], 1)], 1) : _vm._e()], 1), _vm.person.entityType === 'company' ? _c('b-row', [_c('b-col', [_c('form-error-field', {
    attrs: {
      "name": _vm.fieldName('registrationCode'),
      "scope": "person"
    }
  }, [_c('p', {
    staticClass: "label"
  }, [_vm._v(" Legal entity code ")]), _c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    "class": {
      'is-invalid': _vm.errors.has(_vm.id + 'registrationCode', 'person')
    },
    attrs: {
      "name": _vm.fieldName('registrationCode'),
      "type": "text",
      "autocomplete": "off_autocomplete",
      "placeholder": "Enter legal entity code",
      "debounce": "500",
      "data-person": "legal-entity-code",
      "data-vv-as": "Legal entity code"
    },
    model: {
      value: _vm.person.registrationCode,
      callback: function callback($$v) {
        _vm.$set(_vm.person, "registrationCode", $$v);
      },
      expression: "person.registrationCode"
    }
  })], 1)], 1)], 1) : _vm._e(), _vm.person.entityType === 'person' ? _c('b-row', [_c('b-col', [_c('form-error-field', {
    attrs: {
      "name": _vm.fieldName('placeOfBirth'),
      "scope": "person"
    }
  }, [_c('label', {
    staticClass: "label"
  }, [_vm._v("Place of birth")]), _c('v-multiselect', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    "class": {
      'is-invalid': _vm.errors.has(_vm.id + 'place_of_birth', 'person')
    },
    attrs: {
      "options": _vm.countryOptions,
      "show-labels": false,
      "allow-empty": false,
      "name": _vm.fieldName('placeOfBirth'),
      "label": "name",
      "placeholder": "Select country of birth",
      "track-by": "countryId",
      "return": "countryId",
      "data-person": "place-of-birth",
      "data-vv-as": "Place of birth"
    },
    model: {
      value: _vm.person.placeOfBirth,
      callback: function callback($$v) {
        _vm.$set(_vm.person, "placeOfBirth", $$v);
      },
      expression: "person.placeOfBirth"
    }
  })], 1)], 1)], 1) : _vm._e(), _vm.person.entityType === 'person' ? _c('b-row', {
    staticClass: "mb-3"
  }, [_c('b-col', [_c('div', {
    staticClass: "custom-control custom-checkbox",
    "class": {
      'has-error': _vm.errors.has(_vm.id + 'us_citizen', 'person')
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.person.isUsCitizen,
      expression: "person.isUsCitizen"
    }],
    staticClass: "custom-control-input",
    attrs: {
      "id": _vm.id + 'us_citizen',
      "type": "checkbox",
      "name": _vm.id + 'us_citizen',
      "autocomplete": "off",
      "data-person": "us-citizen"
    },
    domProps: {
      "checked": Array.isArray(_vm.person.isUsCitizen) ? _vm._i(_vm.person.isUsCitizen, null) > -1 : _vm.person.isUsCitizen
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.person.isUsCitizen,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.person, "isUsCitizen", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.person, "isUsCitizen", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.person, "isUsCitizen", $$c);
        }
      }
    }
  }), _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": _vm.id + 'us_citizen'
    }
  }, [_vm._v("This person is US citizen")])])])], 1) : _vm._e(), _vm.person.entityType === 'person' ? _c('b-row', [_c('b-col', [_c('form-error-field', {
    attrs: {
      "name": _vm.fieldName('dateOfBirth'),
      "scope": "person"
    }
  }, [_c('label', {
    attrs: {
      "for": _vm.fieldName('dateOfBirth')
    }
  }, [_vm._v(" Date of birth ")]), _c('app-datepicker', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "adult": true,
      "name": _vm.fieldName('dateOfBirth'),
      "data-person": "date-of-birth",
      "data-vv-as": "Date of birth"
    },
    model: {
      value: _vm.person.dateOfBirth,
      callback: function callback($$v) {
        _vm.$set(_vm.person, "dateOfBirth", $$v);
      },
      expression: "person.dateOfBirth"
    }
  })], 1)], 1)], 1) : _vm._e(), _c('b-row', [_c('b-col', [_c('div', {
    "class": {
      'input-invalid': _vm.errors.has(_vm.id + 'phone', 'person')
    }
  }, [_c('div', {
    staticClass: "form-group mb-0"
  }, [_c('label', [_vm._v(" Phone number "), _c('i', {
    staticClass: "fa fa-question-circle-o pull-right",
    attrs: {
      "aria-hidden": "true"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.$modal.show('phone');
      }
    }
  })])]), _c('phone-input', {
    attrs: {
      "scope": "person",
      "data-person": "phone",
      "hide-label": ""
    },
    model: {
      value: _vm.person.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.person, "phone", $$v);
      },
      expression: "person.phone"
    }
  })], 1)])], 1), _c('b-row', [_c('b-col', [_c('form-error-field', {
    attrs: {
      "name": _vm.fieldName('email'),
      "scope": "person"
    }
  }, [_c('label', {
    attrs: {
      "for": _vm.id + 'email'
    }
  }, [_vm._v(" Email ")]), _c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'email|required',
      expression: "'email|required'"
    }],
    "class": {
      'is-invalid': _vm.errors.has(_vm.id + 'email', 'person')
    },
    attrs: {
      "id": "email",
      "name": _vm.fieldName('email'),
      "type": "text",
      "placeholder": "Enter your email",
      "debounce": "500",
      "data-person": "email",
      "data-vv-as": "Email"
    },
    model: {
      value: _vm.person.email,
      callback: function callback($$v) {
        _vm.$set(_vm.person, "email", $$v);
      },
      expression: "person.email"
    }
  })], 1)], 1)], 1), _vm.person.entityType === 'person' ? _c('b-row', [_c('b-col', [_c('form-error-field', {
    staticClass: "text-left mb-12",
    attrs: {
      "name": _vm.fieldName('politicalExposure'),
      "scope": "person"
    }
  }, [_c('label', [_vm._v(" Political exposure "), _c('i', {
    staticClass: "fa fa-question-circle-o pull-right",
    attrs: {
      "aria-hidden": "true"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.$modal.show('political_exposure');
      }
    }
  })]), _c('v-multiselect', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    "class": {
      'is-invalid': _vm.errors.has(_vm.id + 'political_exposure', 'person')
    },
    attrs: {
      "name": _vm.fieldName('politicalExposure'),
      "options": _vm.politicalOptions,
      "show-labels": false,
      "label": "name",
      "placeholder": "Select political exposure",
      "track-by": "name",
      "return": "value",
      "data-person": "political-exposure",
      "data-vv-as": "Political exposure"
    },
    model: {
      value: _vm.person.politicalExposure,
      callback: function callback($$v) {
        _vm.$set(_vm.person, "politicalExposure", $$v);
      },
      expression: "person.politicalExposure"
    }
  })], 1)], 1)], 1) : _vm._e(), _vm.person.entityType === 'person' ? _c('b-row', [_c('b-col', [_c('form-error-field', {
    staticClass: "text-left mb-12",
    attrs: {
      "name": _vm.fieldName('countryForTax'),
      "scope": "person"
    }
  }, [_c('label', {
    attrs: {
      "for": _vm.id + 'country_for_tax_purpose'
    }
  }, [_vm._v(" Country of residence for tax purpose ")]), _c('v-multiselect', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    "class": {
      'is-invalid': _vm.errors.has(_vm.id + 'country_for_tax_purpose', 'person')
    },
    attrs: {
      "options": _vm.countryOptions,
      "show-labels": false,
      "allow-empty": false,
      "name": _vm.fieldName('countryForTax'),
      "label": "name",
      "placeholder": "Select country for tax purpose",
      "track-by": "countryId",
      "return": "countryId",
      "data-person": "tax-country",
      "data-vv-as": "Country of residence for tax purpose"
    },
    model: {
      value: _vm.person.countryForTax,
      callback: function callback($$v) {
        _vm.$set(_vm.person, "countryForTax", $$v);
      },
      expression: "person.countryForTax"
    }
  })], 1)], 1)], 1) : _vm._e(), _c('multi-address', {
    attrs: {
      "addresses": _vm.person.addresses,
      "scope": "person",
      "label": _vm.person.entityType === 'person' ? 'Residential address' : 'Registration address',
      "field-prefix": _vm.prefix
    }
  }), _vm.proofOfResidence && _vm.person.entityType !== 'company' ? _c('div', {
    staticClass: "form-group"
  }, _vm._l(_vm.documents, function (doc) {
    return _c('b-row', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: doc.displayName,
        expression: "doc.displayName"
      }],
      key: doc.type
    }, [_c('b-col', [_c('label', [_vm._v(" " + _vm._s(doc.displayName) + " "), _c('small', [_vm._v("(" + _vm._s(doc.notes) + ")")])]), _c('document-input', {
      attrs: {
        "id": "".concat(_vm.id),
        "document": doc,
        "required": true,
        "name": _vm.fieldName('proofOfResidenceDocument'),
        "validation-scope": 'person',
        "data-person": "document-".concat(doc.field),
        "data-vv-as": doc.displayName
      },
      on: {
        "onChange": function onChange(e) {
          return _vm.onDocumentChange(e, doc);
        }
      },
      model: {
        value: _vm.person[doc.field],
        callback: function callback($$v) {
          _vm.$set(_vm.person, doc.field, $$v);
        },
        expression: "person[doc.field]"
      }
    })], 1)], 1);
  }), 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }