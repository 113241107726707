var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card-settings"
  }, [!_vm.card ? _c('div', {
    staticClass: "text-center py-4"
  }, [_c('b-spinner', {
    attrs: {
      "variant": "primary"
    }
  })], 1) : _vm._e(), _vm.card ? _c('b-container', [_c('b-row', [_c('b-col', [_c('div', {
    staticClass: "page-title-container"
  }, ['active' === _vm.currentWallet.status ? _c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('h2', [_vm._v("Visa " + _vm._s(_vm.card.isVirtual ? 'virtual' : 'debit') + " card " + _vm._s(_vm.shortenedCardNumber))]), _c('new-card-button')], 1) : _vm._e(), _c('b-row', {
    staticClass: "my-4",
    attrs: {
      "align-content": "center"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    "class": ['closed', 'suspended_client', 'suspended_system', 'suspended_admin'].includes(_vm.card.status) ? 'card-settings__card-closed' : ''
  }, [_c('card-image', {
    attrs: {
      "card": _vm.card,
      "virtual": _vm.card.isVirtual
    }
  })], 1), ['closed', 'suspended_admin', 'suspended_client', 'suspended_system'].includes(_vm.card.status) ? _c('div', {
    staticClass: "card-settings__closed-card-overlay"
  }, [_vm.card.status == 'closed' ? _c('strong', [_vm._v("Card is Blocked")]) : _vm._e(), _vm.card.status == 'suspended_client' || _vm.card.status == 'suspended_system' || _vm.card.status == 'suspended_admin' ? _c('strong', [_vm._v("Card is Temporary Blocked")]) : _vm._e()]) : _vm._e()])], 1), _vm.loading ? _c('div', {
    staticClass: "text-center py-4"
  }, [_vm.loadingMessage ? _c('div', {
    staticClass: "mb-4"
  }, [_vm._v(" " + _vm._s(_vm.loadingMessage) + " ")]) : _vm._e(), _c('b-spinner', {
    attrs: {
      "variant": "primary"
    }
  })], 1) : _vm._e(), !_vm.loading ? _c('div', [_c('b-row', [_c('b-col', [(_vm.card.status == 'sent' || _vm.card.status == 'waiting_cvv') && _vm.permissions.activate ? _c('div', {
    staticClass: "card-settings__notification"
  }, [_vm._v(" Card is ordered. Activate it using CVV when it arrives. ")]) : _vm._e()])], 1), _vm._l(Object.entries(_vm.menu), function (entry, i) {
    return _c('b-row', {
      key: i,
      staticClass: "mb-4"
    }, [_c('b-col', [_c('span', {
      staticClass: "card-settings__menu-title mb-2"
    }, [_vm._v(_vm._s(entry[0]))]), _c('ul', {
      staticClass: "card-menu"
    }, _vm._l(entry[1].filter(function (e) {
      return !e.hidden;
    }), function (item, ix) {
      return _c('li', {
        key: ix
      }, [_c('a', {
        attrs: {
          "href": ""
        },
        on: {
          "click": function click($event) {
            $event.preventDefault();
            return item.action.apply(null, arguments);
          }
        }
      }, [_c('span', {
        staticClass: "icon"
      }, [_c('ion-icon', {
        attrs: {
          "name": item.icon
        }
      })], 1), _c('span', {
        staticClass: "menu-text"
      }, [_vm._v(_vm._s(item.title))])]), item.children ? _c('div', {
        staticClass: "card-settings-submenu"
      }, [_c('ul', _vm._l(item.children.filter(function (e) {
        return !e.hidden;
      }), function (child, iy) {
        return _c('li', {
          key: iy,
          "class": child.topDivider ? 'top-divider' : ''
        }, [_c('a', {
          attrs: {
            "href": "#"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return child.action.apply(null, arguments);
            }
          }
        }, [_c('ion-icon', {
          attrs: {
            "name": child.icon
          }
        }), _c('span', {
          staticClass: "menu-text"
        }, [_vm._v(_vm._s(child.title))])], 1)]);
      }), 0)]) : _vm._e()]);
    }), 0)])], 1);
  })], 2) : _vm._e()], 1)])], 1), _vm.list.length > 1 ? _c('b-row', [_c('b-col', [_c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click() {
        return _vm.$router.push({
          name: 'cards'
        });
      }
    }
  }, [_vm._v(" Back ")])], 1)], 1) : _vm._e()], 1) : _vm._e(), _vm.card ? _c('div', [_c('card-auth-modal', {
    attrs: {
      "id": _vm.detailsAuthModalId,
      "authenticator": "sms",
      "refresh-fn": _vm.requestDetailsCode,
      "on-submit": _vm.getDetails
    }
  }), _c('card-auth-modal', {
    attrs: {
      "id": _vm.pinAuthModalId,
      "authenticator": "sms",
      "refresh-fn": _vm.requestPinCode,
      "on-submit": _vm.getPin
    }
  }), _c('card-prompt-modal', {
    ref: "card-prompt-modal",
    attrs: {
      "on-close": _vm.getCard
    }
  }), _c('card-jwt-modal', {
    ref: "card-jwt-modal",
    attrs: {
      "id": _vm.jwtModalId,
      "card-id": _vm.cardId
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }