var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "faq-container",
    attrs: {
      "id": "faq-layout"
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "faq-search"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.search,
      expression: "search"
    }],
    staticClass: "form-control",
    attrs: {
      "placeholder": "Type to search",
      "type": "text"
    },
    domProps: {
      "value": _vm.search
    },
    on: {
      "keyup": _vm.searchAction,
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.search = $event.target.value;
      }
    }
  })]), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('faq-nav', {
    attrs: {
      "click-callback": _vm.disableSearch
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "9"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.searchSuccess,
      expression: "searchSuccess"
    }],
    staticClass: "search-results",
    attrs: {
      "role": "tablist"
    }
  }, [_vm._l(_vm.filteredList, function (faq, index) {
    return _c('div', {
      key: faq.title + faq.key,
      staticClass: "card"
    }, [_c('b-card-header', {
      staticClass: "accHeader",
      attrs: {
        "header-tag": "header"
      }
    }, [_c('div', {
      staticClass: "btc"
    }, [_c('h5', [_vm._v(_vm._s(faq.question))])])]), _c('div', {
      attrs: {
        "id": faq.title + index
      }
    }, [_c('b-card-body', {
      staticClass: "inner"
    }, [_c('p', {
      staticClass: "card-text",
      domProps: {
        "innerHTML": _vm._s(faq.text)
      }
    })])], 1)], 1);
  }), _c('br')], 2), _c('router-view', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.searchSuccess,
      expression: "!searchSuccess"
    }],
    attrs: {
      "data": _vm.Faq
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-title-container"
  }, [_c('h2', [_vm._v("FAQ")])]);
}]

export { render, staticRenderFns }