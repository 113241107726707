<template>
	<div class="auth-card">
		<div v-if="!initialized">
			<h1 class="auth-title">
				Recover account password
			</h1>

			<p class="text-center mb-5">
				Begin the password reset process by requesting an email
				to the account email address. You will receive an email
				with details for completing the reset.
			</p>

			<recover-password-form />
		</div>
		<div v-else>
			<h1 class="auth-title text-success">
				Your password reset request has been initialized.
			</h1>
			<hr />
			<div class="pt-4">
				<p>
					<strong>The recovery email was sent to your email.</strong>
				</p>
				<p class="mb-0">
					Click the link in your email to continue password reset process.
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import RecoverPasswordForm from './RecoverPasswordForm.vue';

export default {
	name: 'RecoverPassword',
	components: {
		RecoverPasswordForm
	},
	computed: {
		...mapState('passwordRecovery', {
			initialized: state => state.status.initialized
		})
	}
};
</script>
