<template>
	<div
		v-if="title"
		class="preloader"
	>
		{{ title }}
	</div>
	<div
		v-else
		class="preloader"
	>
		<div class="preloader-inside">
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
		<!--Loading...-->
	</div>
</template>

<script>
export default {
	name: 'Preloader',
	props : {
		title: {
			type: String,
			default: ''
		}
	}
};
</script>
<style lang="scss" scoped>
	@import './Preloader.scss';
</style>