import { endpoints } from '~/config';
import { UPDATE_LAST_LOG } from '~/store/mutation-types';

export default {
	async getLastLog({ commit }) {
		const options = {
			method: 'GET',
			url: endpoints.log.userLog,
			params: {
				ascending : 0,
				byColumn : 1,
				limit : 1,
				orderBy : 'timestampCreated',
				page : 1
			}
		};
		try {
			const response = await axios(options);
			const { data } = response;
			commit(UPDATE_LAST_LOG, data);

			return Promise.resolve(data);
		} catch (error) {
			return Promise.reject(error);
		}
	}
};