var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-list-group', _vm._l(_vm.list, function (item, i) {
    return _c('b-list-group-item', {
      key: i,
      attrs: {
        "href": "#"
      },
      on: {
        "click": function click($event) {
          return _vm.select(item);
        }
      }
    }, [_vm._v(" Send code by " + _vm._s(_vm.AuthenticatorHelper.name[item.authenticator]) + " ")]);
  }), 1), _c('div', {
    staticClass: "my-4"
  }, [_c('a', {
    attrs: {
      "href": "mailto:".concat(_vm.config.supportEmail),
      "target": "_blank"
    }
  }, [_vm._v("Did you lose an access to your device? Contact us.")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }