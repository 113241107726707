var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "tabs-bar"
  }, _vm._l(_vm.tabs, function (tab, i) {
    return _c('div', {
      key: i,
      staticClass: "tab-title",
      attrs: {
        "active": _vm.selectedIndex === i,
        "disabled": tab.disabled
      },
      on: {
        "click": function click($event) {
          return _vm.selectTab(i, tab);
        }
      }
    }, [_vm._v(" " + _vm._s(tab.title) + " ")]);
  }), 0), _vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }