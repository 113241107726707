<template>
	<b-row class="checkbox-list">
		<b-col 
			v-for="(item, i) in items"
			:key="i"
			class="checkbox-list__column"
			:cols="cols"
		>
			<div 
				class="checkbox-list__item" 
				:class="{'checkbox-list__item--active' : value.includes(item[optionValue])}"
				@click="() => select(item[optionValue])"
			>
				<div class="checkbox-list__item__text">
					<h3>
						<span>
							<i class="fas fa-check"></i>
						</span>
						{{ item[optionTitle] }}
					</h3>
					<p v-if="item[optionDescription]">
						{{ item[optionDescription] }}
					</p>
					<div class="checkbox-list__item__extra">
						<slot :name="item[optionValue]"></slot>
					</div>
				</div>
			</div>
		</b-col>
	</b-row>
</template>
<script>
export default {
	name: 'CheckboxList',
	props: {
		value: { 
			type: Array,
			default: function() {return [];},
			required: true
		},
		items: { 
			type: Array,
			default: function() {return [];},
			required: true
		},
		cols: {
			type: Number,
			default: 12
		},
		optionTitle: {
			type: String,
			default: 'name'
		},
		optionValue: {
			type: String,
			default: 'value'
		},
		optionDescription: {
			type: String,
			default: 'description'
		}
	},
	methods: {
		select: function(value) {
			this.$emit('change', value);
		}
	}
};
</script>
<style lang="scss">
	.checkbox-list {

		&__column {
			&:first-child {
				.checkbox-list__item {
					border-top: 1px solid var(--input-border);
					padding-top: 30px !important;
				}
			}

			&:last-child {
				.checkbox-list__item {
					border-bottom: 1px solid var(--input-border);
					padding-bottom: 30px !important;
				}
			}
		}

		&__item {
			background-color: #fff;
			padding-left: 15px;
			padding-right: 15px;
			padding-top: 15px;
			padding-bottom: 15px;
			overflow: hidden;
			position: relative;
			transition: all .3s ease;
			height: 100%;
			display: flex;
			transition: all .3s ease;
			border-left: 1px solid var(--input-border);
			border-right: 1px solid var(--input-border);
			cursor: pointer;

			&:hover {
				&:not(.checkbox-list__item--active) {
					h3 {
						span {
							background-color: var(--input-border);
						}
					}
				}
			}

			&--active {
				h3 {
					span {
						border: 1px solid var(--primary) !important;
						background-color: var(--primary);
					}
				}
			}


			&__text {
				h3 {
					display: flex;
					margin-bottom: 0;
					font-weight: 500;
					align-items: center;

					span {
						margin-right: 15px;
						margin-left: 10px;
						display: flex;
						justify-content: center;
						align-items: center;
						width: 30px;
						height: 30px;
						border: 1px solid var(--input-border);
						transition: all .3s ease;
						border-radius: 2px;
						flex-shrink: 0;

						i {
							font-size: 0.8rem;
							color: #fff;
						}
					}
				}

				p {
					margin-top: 10px;
					padding-left: 55px;
					margin-bottom: 0;
				}
			}

			&__extra {
				padding-left: 55px;
			}
		}
	}
</style>