var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('datepicker', {
    attrs: {
      "id": _vm.id,
      "disabled-dates": _vm.disabledDates,
      "open-date": _vm.openDate,
      "monday-first": true,
      "name": _vm.name,
      "input-class": "form-control datepicker",
      "type": "text",
      "format": "yyyy-MM-dd",
      "placeholder": "Please select date",
      "disabled": _vm.disabled
    },
    on: {
      "opened": function opened($event) {
        return _vm.openedCallback(_vm.id);
      }
    },
    model: {
      value: _vm.selectedDate,
      callback: function callback($$v) {
        _vm.selectedDate = $$v;
      },
      expression: "selectedDate"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }