<template>
	<transition 
		name="fade" 
		mode="out-in"
	>
		<div
			v-if="item"
			class="transactions-list-item-details"
		>
			<i
				class="fas fa-times transactions-list-item-details__close"
				@click="close"
			></i>
			<b-card class="transactions-list-item-details__card">
				<div class="transactions-list-item-details__icon">
					<img
						v-if="!['declined', 'failed', 'refund'].includes(item.publicStatus)"
						:src="
							item.direction === 'outgoing'
								? 'images/img/outcome.svg'
								: 'images/img/income.svg'
						"
					/>
					<ion-icon
						v-if="['declined', 'failed'].includes(item.publicStatus)"
						name="close-outline"
						style="color: red"
					/>
					<ion-icon
						v-if="['refund'].includes(item.publicStatus)"
						name="refresh-outline"
					/>
				</div>
				<div 
					class="transactions-list-item-details__amount"
					:class="{
						'transactions-list-item-details__amount--failed': ['declined', 'failed'].includes(item.publicStatus)
					}"
				>
					<span
						:class="{
							'greenStatus': item.publicStatus === 'completed' && item.direction === 'incoming',
							'redStatus': item.publicStatus === 'completed' && item.direction === 'outgoing'
						}" 
					>
						{{ item.publicStatus == 'completed' ? item.direction === 'outgoing' ? '-' : '+' : '' }}{{ item.amount }}
						<span class="transactions-list-item-details__currency">{{ item.currencyId }}</span>
					</span>
				</div>
				<div
					v-if="parseFloat(item.fee) > 0"
					class="transactions-list-item-details__fees"
				>
					<span>Fees: {{ item.fee }}</span>
					<span class="transactions-list-item-details__currency">{{ item.currencyId }}</span>
				</div>
				<div class="transactions-list-item-details__recipient">
					{{
						item.direction === 'outgoing'
							? item.beneficiary?.accountHolder
							: item.sender?.accountHolder
					}}
				</div>
				<hr>
				<div class="transactions-list-item-details__additional-data">
					<div>Payment details:</div>
					<span>{{ item.details }}</span>
					<hr>
					<div v-if="item.id">
						<div
							v-for="details in list.common"
							:key="details.label"
						>
							<div
								v-if="details.value(item)"
								class="transactions-list-item-details__row"
							>
								<span>{{ details.label }}:</span>
								<span
									v-b-tooltip.hover.v-info.left
									v-clipboard="details.value(item)"
									v-clipboard:success="() => pushSuccess({title: 'Copied to clipboard:', text: details.value(item)})"
									title="Click to copy"
								>{{ details.value(item) }}</span>
							</div>
						</div>
						<div
							v-for="(details, i) in list[item.direction]"
							:key="`details-${i}`"
						>
							<div
								v-if="details.value(item)"
								class="transactions-list-item-details__row"
							>
								<span>{{ details.label }}:</span>
								<span
									v-b-tooltip.hover.v-info.left
									v-clipboard="details.value(item)"
									v-clipboard:success="() => pushSuccess({title: 'Copied to clipboard:', text: details.value(item)})"
									title="Click to copy"
								>{{ details.value(item) }}</span>
							</div>
						</div>
					</div>
					<hr>
					<div class="transactions-list-item-details__button-wrap">
						<button
							class="btn btn-primary"
							@click="pdfClick(item.id)"
						>
							<i class="fas fa-download"></i> 
							PDF download
						</button>
					</div>
					<div
						v-if="isWalletGranted('initiate_transaction', currentWallet.walletId) && item.direction === 'outgoing' && transferType && currentWallet && currentWallet.status === 'active'"
						class="transactions-list-item-details__button-wrap"
					>
						<button
							class="btn btn-primary"
							@click="copyTransfer(item)"
						>
							Copy payment
						</button>
					</div>
				</div>
			</b-card>
			<span class="transactions-list-item-details__id">Transaction ID: {{ item.id }}</span>
		</div>
	</transition>
</template>
<script>
import * as moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { endpoints } from '~/config';

export default {
	name: 'TransactionListItemDetails',
	props: {
		item: {
			type: Object,
			default: () => ({})
		}
	},
	data() {
		return {
			moment,
			list: {
				common: [
					{
						label: 'Date',
						value: item => moment(item.dateCreated).utcOffset(item.dateCreated).format('YYYY-MM-DD HH:mm')
					}
				],
				outgoing: [
					{
						label: 'Recipient',
						value: item => item.beneficiary?.accountHolder
					},
					{
						label: 'Account number',
						value: item => item.beneficiary?.accountNumber
					},
					{
						label: 'BIC',
						value: item => item.beneficiary?.bank?.bic
					},
					{
						label: 'Bank',
						value: item => item.beneficiary?.bank?.name
					},
				],
				incoming: [
					{
						label: 'Sender',
						value: item => item.sender?.accountHolder
					},
					{
						label: 'Account number',
						value: item => item.sender?.accountNumber
					},
					{
						label: 'BIC',
						value: item => item?.sender?.bank?.bic
					},
					{
						label: 'Bank',
						value: item => item.sender?.bank?.name
					},

				]
			},
			services: {
				sepa: [
					'nano_transfer',
					'nano_inner_transfer',
					'nano_inside_transfer',
					'nano_swift_transfer',
				],
				swift: [
					'cc_swift_sha',
					'cc_local',
				]
			}

		};
	},
	computed: {
		...mapGetters('user', [
			'isWalletGranted',
		]),
		...mapGetters('wallet', [
			'currentWallet'
		]),
		transferType() {
			return this.services.sepa.find(s => this.item.serviceId == s) ? 'sepa' : null || 
			this.services.swift.find(s => this.item.serviceId == s) ? 'swift' : null;
		}
	},
	methods: {
		...mapActions('notifications', ['pushSuccess', 'pushError']),
		close() {
			this.$emit('close');
		},
		copyTransfer(item) {
			this.$router.push({ 
				name: 'transfer', 
				params: { 
					transferData: { ...item, transferType: this.transferType },
					transferType: this.transferType
				} 
			});
		},
		async pdfClick() {
			// Todo: put to store actions
			const options = {
				method: 'GET',
				url: endpoints.transactions.statementSingle({ transactionId: this.item.id }),
				responseType: 'arraybuffer'
			};
			try {
				const response = await axios(options);
				const { data } = response;
				const fileUrl = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
				const fileLink = document.createElement('a');
				fileLink.href = fileUrl;
				fileLink.setAttribute('download', `${this.item.id}_statement.pdf`);
				document.body.appendChild(fileLink);
				fileLink.click();
			} catch (err) {
				this.pushError(err.message);
			}
		}
	}
};
</script>
<style lang="scss">
	@import './TransactionListItemDetails.scss';
</style>