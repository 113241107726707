import LayoutAuthentication from '@/LayoutAuthentication/LayoutAuthentication.vue';

import login from './loginRoutes';
import register from './registerRoutes';
import hash from './hashRoutes';

export default {
	path: '/',
	component: LayoutAuthentication,
	redirect: '/login',
	children: [
		...login,
		...register,
		...hash
	]
};