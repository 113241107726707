var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "bulk-payments"
  }, [_vm.step == 'transferSelection' ? _c('div', [_c('div', {
    staticClass: "bulk-items mt-3"
  }, [_c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "12",
      "xl": "12"
    }
  }, [_vm.items.rows && _vm.items.rows.length > 0 && _vm.isWalletGranted('initiate_transaction', _vm.currentWallet.walletId) ? _c('div', {
    staticClass: "text-center mt-3 mt-lg-0 mt-sm-0 mt-xl-0 text-sm-right text-lg-right text-xl-right"
  }, [_c('button', {
    staticClass: "btn btn-primary mb-3",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.selectAll(true);
      }
    }
  }, [_vm._v(" Select all ")]), _c('button', {
    staticClass: "btn btn-outline-primary mb-3 ml-3",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.selectAll(false);
      }
    }
  }, [_vm._v(" Deselect all ")])]) : _vm._e()])], 1), _vm.items.loading ? _c('b-card', {
    staticClass: "bulk-item text-center"
  }, [_c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  })], 1) : _vm._e(), _vm._l(_vm.items.rows, function (item) {
    return _c('b-card', {
      key: item.id,
      staticClass: "mb-3 bulk-item",
      "class": "".concat(_vm.isItemSelected(item) && _vm.isWalletGranted('initiate_transaction', _vm.currentWallet.walletId) ? 'bulk-item--selected' : '', " 'bulk-item--completed'")
    }, [_c('b-row', [_c('b-col', {
      staticClass: "check",
      attrs: {
        "md": "1",
        "cols": "12"
      }
    }, [_vm.isWalletGranted('initiate_transaction', _vm.currentWallet.walletId) ? _c('div', {
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.selectItem(item);
        }
      }
    }, [!_vm.isItemSelected(item) ? _c('i', {
      staticClass: "far fa-square"
    }) : _c('i', {
      staticClass: "far fa-check-square"
    })]) : _vm._e()]), _c('b-col', {
      attrs: {
        "md": "2",
        "cols": "12"
      }
    }, [_c('small', {
      staticClass: "item-label"
    }, [_vm._v("Name")]), _c('div', [_vm._v(" " + _vm._s(item.name) + " ")])]), _c('b-col', {
      attrs: {
        "md": "2",
        "cols": "12"
      }
    }, [_c('small', {
      staticClass: "item-label"
    }, [_vm._v("Beneficiary")]), item.transactionData.serviceId === 'cc_swift_sha' || item.transactionData.serviceId === 'cc_local' ? _c('div', [item.transactionData.beneficiaryRequest.type === 'company' ? _c('div', [_c('div', [_vm._v(_vm._s(item.transactionData.beneficiaryRequest.companyName) + " (Multicurrency)")])]) : _c('div', [_vm._v(" " + _vm._s(item.transactionData.beneficiaryRequest.name + ' ' + item.transactionData.beneficiaryRequest.surname) + " (Multicurrency) ")])]) : _c('div', [_vm._v(" " + _vm._s(item.transactionData.beneficiaryRequest.name) + " ")])]), _c('b-col', {
      attrs: {
        "md": "2",
        "cols": "12"
      }
    }, [_c('small', {
      staticClass: "item-label"
    }, [_vm._v("Account Number")]), _c('div', [_vm._v(_vm._s(item.transactionData.beneficiaryRequest.accountNumber))])]), _c('b-col', {
      attrs: {
        "md": "2",
        "cols": "12"
      }
    }, [_c('small', {
      staticClass: "item-label"
    }, [_vm._v("Amount")]), _c('div', {
      staticClass: "amount-label"
    }, [_c('b-badge', {
      staticClass: "amount-badge",
      attrs: {
        "variant": "primary"
      }
    }, [_vm._v(" " + _vm._s(item.transactionData.amount)), _c('small', [_vm._v(_vm._s(item.transactionData.currencyId))])])], 1)]), _c('b-col', {
      attrs: {
        "md": "2",
        "cols": "12"
      }
    }, [_c('small', {
      staticClass: "item-label"
    }, [_vm._v("Details")]), _c('div', [_vm._v(_vm._s(item.transactionData.details))])]), _c('b-col', {
      staticClass: "d-flex justify-content-center align-items-center",
      staticStyle: {
        "padding": "0 10px"
      },
      attrs: {
        "md": "1",
        "cols": "12"
      }
    }, [_c('ion-icon', {
      staticClass: "copy-transfer-icon",
      staticStyle: {
        "color": "var(--primary)"
      },
      attrs: {
        "name": "arrow-forward-circle-outline",
        "size": "large"
      },
      on: {
        "click": function click($event) {
          return _vm.copyTransfer(item);
        }
      }
    })], 1)], 1)], 1);
  }), !_vm.items.loading && (!_vm.items.rows || _vm.items.rows.length < 1) ? _c('div', {
    staticClass: "alert alert-info"
  }, [_vm._v(" No templates ")]) : _vm._e(), _c('div', {
    staticClass: "mb-3"
  }, [_vm.items.pages > 1 ? _c('pagination', {
    attrs: {
      "total": _vm.items.count,
      "per-page": _vm.perPage,
      "current-page": _vm.items.currentPage
    },
    on: {
      "pagechanged": _vm.getTransferList
    }
  }) : _vm._e()], 1)], 2), _vm.items.rows && _vm.items.rows.length > 0 ? _c('div', {
    staticClass: "text-center"
  }, [_vm.isGranted('front_transaction_template_delete') ? _c('button', {
    staticClass: "btn btn-danger ml-3",
    attrs: {
      "disabled": _vm.selectedItems.length == 0
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.deleteSelected.apply(null, arguments);
      }
    }
  }, [_vm._v(" Remove ")]) : _vm._e()]) : _vm._e()]) : _vm._e(), _vm.step == 'successDelete' ? _c('div', [_c('b-card', [_vm._v(" Template deleted successfully "), _c('button', {
    staticClass: "btn btn-primary ml-3",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        _vm.step = 'transferSelection';
      }
    }
  }, [_vm._v(" OK ")])])], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }