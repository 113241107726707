<template>
	<div 
		id="profile-business-info" 
		class="account-details profile-business-info"
	>
		<h3 class="mb-4 mb-lg-5 font-weight-bold">
			Business information
		</h3>
		<div class="form-group">
			<div class="row align-items-center">
				<label class="col-6 col-sm-5 col-xl-4 col-form-label">Company name</label>
				<div class="col-6 col-sm-7 col-xl-6">
					<strong>{{ data.name }}</strong>
				</div>
			</div>
		</div>
		<div class="form-group">
			<div class="row align-items-center">
				<label class="col-6 col-sm-5 col-xl-4 col-form-label">Company code</label>
				<div class="col-6 col-sm-7 col-xl-6">
					<strong>{{ data.registrationCode }}</strong>
				</div>
			</div>
		</div>
		<div class="form-group">
			<div class="row align-items-center">
				<label class="col-6 col-sm-5 col-xl-4 col-form-label">VAT number</label>
				<div class="col-6 col-sm-7 col-xl-6">
					<strong v-if="data.vatNumber">{{ data.vatNumber }}</strong>
					<strong v-else>-</strong>
				</div>
			</div>
		</div>
		<div class="form-group">
			<div class="row align-items-center">
				<label class="col-6 col-sm-5 col-xl-4 col-form-label">Number of employees</label>
				<div class="col-6 col-sm-7 col-xl-6">
					<strong>{{ data.numberOfEmployees }}</strong>
				</div>
			</div>
		</div>
		<div class="form-group">
			<div class="row align-items-center">
				<label class="col-6 col-sm-5 col-xl-4 col-form-label">Website</label>
				<div class="col-6 col-sm-7 col-xl-6">
					<strong v-if="data.contacts?.website">{{ data.contacts?.website }}</strong>
					<strong v-else>-</strong>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'ProfileBusinessInfo',
	props: {
		data: {
			type: Object,
			default: () => {}
		}
	},
};
</script>
