var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "auth-card"
  }, [_c('h1', {
    staticClass: "auth-title"
  }, [_vm._v(" Login information ")]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showForm,
      expression: "showForm"
    }],
    staticClass: "form-body"
  }, [_c('b-row', {
    staticClass: "justify-content-md-center"
  }, [_c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "textblock lightext"
  }, [_c('p', [_vm._v(" Please create your password ")])])])], 1), _c('login-info-form', {
    on: {
      "success": function success($event) {
        return _vm.onSuccess();
      },
      "failure": function failure($event) {
        return _vm.onFailure();
      }
    }
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }