<template>
	<div class="card-order">
		<b-container>
			<b-row>
				<b-col>
					<div class="page-title-container">
						<h2>Card details</h2>
					</div>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<card-image :virtual="isVirtual" />
				</b-col>
			</b-row>
			<b-row>
				<b-col class="card-order__page-subtitle">
					<span v-if="!isVirtual">New Visa Debit card</span>
					<span v-if="isVirtual">New Visa Virtual Debit card</span>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<div
						v-if="ordersCount > 0"
						class="card-order__notification"
					>
						<span v-if="ordersCount == 1">There is <strong>1</strong> {{ isVirtual ? 'virtual' : 'plastic' }} card order in progress.</span>
						<span v-if="ordersCount > 1">There are <strong>{{ ordersCount }}</strong> {{ isVirtual ? 'virtual' : 'plastic' }} card orders in progress.</span>
					</div>
				</b-col>
			</b-row>
			<card-account-details />
			<card-personal-details />
			<card-address-details />
			<div v-if="!isVirtual">
				<b-row class="mb-4">
					<b-col>
						<b-card
							class="card-order__address-switch"
							@click="() => params.useProfileAddress = !params.useProfileAddress"
						>
							<b-row>
								<b-col
									cols="auto"
									class="d-flex justify-content-center align-items-center"
								>
									<span 
										class="card-order__checkbox"
										:class="params.useProfileAddress ? 'card-order__checkbox--checked' : ''"
									></span>
								</b-col>
								<b-col>
									<div class="d-flex flex-column">
										<strong>Use the address above for delivery</strong>
										<small>The card will always be registered to the verified address of your account.</small>
									</div>
								</b-col>
							</b-row>
						</b-card>
					</b-col>
				</b-row>
				<div
					v-if="!params.useProfileAddress"
					class="mb-4"
				>
					<address-input v-model="params.deliveryAddress" />
				</div>
				<div
					v-if="!countryAllowed"
					class="card-order__warning mb-4"
				>
					<small>You can't order a card to this country.</small>
				</div>
			</div>
			<b-row>
				<b-col>
					<b-button
						variant="primary"
						class="mr-2"
						:disabled="!countryAllowed"
						@click="submit"
					>
						Order Card
					</b-button>
					<b-button
						variant="outline-primary"
						@click="$router.go(-1)"
					>
						Cancel
					</b-button>
				</b-col>
			</b-row>
		</b-container>
		<card-auth-modal 
			:id="confirmModalId" 
			:refresh-fn="prepare"
			:on-submit="confirm"
		/>
		<card-content-modal
			:id="successModalId"
			:on-close="() => $router.push({ name: 'cards' })"
		>
			<div class="text-center">
				<img
					src="/images/icons/card-success.png"
					width="78"
					class="mb-4"
				/>
				<h2><strong>Congratulations</strong></h2>
				<span v-if="!isVirtual">Your physical card has been ordered and will be shipped as soon as you set it up.</span>
				<span v-if="isVirtual">Your virtual card has been ordered.</span>
			</div>
		</card-content-modal>
	</div>
</template>
<script>
import CardAccountDetails from './Partials/CardAccountDetails';
import CardPersonalDetails from './Partials/CardPersonalDetails';
import CardAddressDetails from './Partials/CardAddressDetails';
import AddressInput from '~/components/shared/AddressInput';
import CardAuthModal from './Settings/CardAuthModal';
import CardContentModal from './Settings/CardContentModal';
import CardImage from './Partials/CardImage';
import { mapActions, mapGetters, mapState } from 'vuex';
import { endpoints } from '~/config';
export default {
	name: 'CardOrderPlastic',
	components: {
		CardAccountDetails,
		CardPersonalDetails,
		CardAddressDetails,
		CardAuthModal,
		CardContentModal,
		AddressInput,
		CardImage
	},
	data() {
		return {
			confirmModalId: 'card-confirm-modal',
			successModalId: 'card-success-modal',
			isVirtual: false,
			params: {
				deliveryMethod: 'post',
				useProfileAddress: true,
				deliveryAddress: {}
			}
		};
	},
	computed: {
		...mapState('user', {
			user: state => state.selected
		}),
		...mapState('cards', [
			'countries', 'countriesLoaded'
		]),
		...mapState('cards', {
			orders: state => state.ordersList
		}),
		...mapState('wallet', {
			wallet: state => state,
		}),
		...mapGetters('wallet', [
			'currentWallet'
		]),
		ordersCount() {
			if (!this.orders.length) return null;
			return this.orders.filter(order => ['created', 'failed', 'system_approved', 'manual_approved'].includes(order.status) && this.isVirtual == order.isVirtual).length;
		},
		countryAllowed() {
			const countryId = this.params.deliveryAddress && this.params.deliveryAddress.countryId;
			if (countryId && this.countriesLoaded) {
				return !!this.countries.find(c => c.countryId == countryId);
			}
			return true;
		}
	},
	methods: {
		...mapActions('notifications', ['pushError', 'pushSuccess']),
		...mapActions('cards', ['initCardData']),
		async submit() {
			const valid = await this.$validator.validate();
			if (valid) {
				const ready = await this.prepare();
				if (ready) {
					this.$bvModal.show(this.confirmModalId);
				}
			}
		},
		async prepare() {
			const options = {
				method: 'POST',
				url: endpoints.cards.prepare,
				data: {
					'type': 'card_order',
					'walletId': this.currentWallet.walletId,
				}
			};
			try {
				await axios(options);
				return true;
			} catch (err) {
				this.pushError(err.message || 'Card order failed. Please try again later.');
				return false;
			}
		},
		async confirm(code) {
			this.params.deliveryAddress = this.params.useProfileAddress ? undefined : this.params.deliveryAddress;
			let postData = {
				isVirtual: this.isVirtual
			};
			if (!this.isVirtual) {
				postData = {
					...postData,
					...this.params
				};
			}
			const options = {
				method: 'POST',
				url: endpoints.cards.order({ walletId: this.currentWallet.walletId }),
				data: {
					...postData,
					code
				}
			};
			try {
				await axios(options);
				await this.initCardData();
				this.$bvModal.hide(this.confirmModalId);
				this.$bvModal.show(this.successModalId);
				return true;
			} catch (err) {
				this.pushError(err.message || 'Card order failed. Please try again.');
				return false;
			}
		},
	},
	mounted() {
		this.isVirtual = this.$route.name == 'new-virtual-card';
	}
};
</script>
<style lang="scss">
	.card-order {
		&__page-subtitle {
			font-size: 16px;
			font-weight: 500;
			color: #353535;
			display: inline-block;
			margin-top: 20px;
			margin-bottom: 60px;
		}

		&__address-switch {
			cursor: pointer;
		}

		&__checkbox {
			display: inline-block;
			width: 16px;
			height: 16px;
			border-radius: 2px;
			border: 1px solid #ADB5BD;
			background-color: #fff;

			&--checked {
				position: relative;

				&::before,
				&::after {
					content: '';
					display: block;
					position: absolute;
					background-color: $primary;
					width: 2px;
				}

				&::before {
					height: 10px;
					left: 8px;
					top: 2px;
					transform: rotate(30deg);
				}

				&::after {
					height: 4px;
					left: 4px;
					top: 6px;
					transform: rotate(-30deg);
				}
			}
		}

		&__warning {
			color: $red;
		}

		&__notification {
			text-align: center;
			padding: 16px 20px;
			background-color: #e3f3f4;
			border-radius: 3px;
			margin: 0 0 20px;
		}

	}
</style>