import { render, staticRenderFns } from "./OddPersonDocuments.vue?vue&type=template&id=04efa42f&"
import script from "./OddPersonDocuments.vue?vue&type=script&lang=js&"
export * from "./OddPersonDocuments.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports