<template>
	<div>
		<div class="auth-card">
			<h1
				v-if="!expiredRoute"
				class="auth-title"
			>
				Two-Factor Authentication
			</h1>
			<h1
				v-else
				class="auth-title"
			>
				Session expired
			</h1>
			<div
				v-if="showCodeInput"
				class="code-wrap"
			>
				<label
					for="code"
					class="d-block"
				>
					Code from {{ AuthenticatorHelper.name[currentAuthenticator] }}
					<span v-if="phoneNumberUsed && selectedProperties?.phoneNumber">
						sent to {{ selectedProperties.phoneNumber }}</span>
				</label>
				<CodeInput
					id="code"
					ref="code"
					v-model="code"
					:digits="6"
					:disabled="loading"
					:numbers-only="phoneNumberUsed"
					@keydown="error = false"
				/>
	
				<div v-if="!showSelector"
					class="text-center text-muted"
				>
					<span v-if="phoneNumberUsed">Did not get {{ AuthenticatorHelper.name[currentAuthenticator] }} with confirmation code?</span>
					<span v-if="!phoneNumberUsed">Code did not work?</span>
					<span class="ml-1">
						<a
							v-if="phoneNumberUsed"
							href="#"
							target="_self"
							@click.prevent="resend"
						>Resend</a>
						<a
							v-if="!phoneNumberUsed"
							href="#"
							target="_self"
							@click.prevent="resend"
						>Refresh</a>
					</span>
				</div>
				<div v-if="showSelector"
					class="mt-4"
				>
					<b-spinner v-if="typeLoading" />
					<authenticator-selector v-if="!typeLoading"
						:list="authenticatorList"
						@onSelect="selectResendMethod"
					/>
				</div>
				<div class="row justify-content-md-center">
					<div class="col-md-6 mt-3">
						<button 
							class="btn btn-primary btn-block"
							:disabled="loading"
							@click.prevent="userLogout"
						>
							<b-spinner 
								v-if="loading"
								small 
								variant="light" 
								type="grow" 
								label="Spinning"
							/>
							<span v-if="!loading">Back to login page</span>
						</button>	
					</div>
				</div>
			</div>
			<div v-if="showExpireSelection">
				<div class="row justify-content-md-center">
					<div class="col-md-6">
						<button 
							class="btn btn-primary btn-block"
							@click.prevent="verifyExpiration"
						>
							Verify by code from SMS
						</button>	
					</div>
				</div>
				<div class="row justify-content-md-center pt-4">
					<div class="col-md-6">
						<button 
							class="btn btn-primary btn-block"
							@click.prevent="userLogout"
						>
							Logout
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CodeInput from '@/shared/CodeInput';
import AuthenticatorHelper from '~/helpers/authenticator.helper';
import AuthenticatorSelector from '@/shared/AuthenticatorSelector';
import { mapState, mapActions, mapGetters } from 'vuex';
export default {
	components: {
		CodeInput,
		AuthenticatorSelector
	},
	data () {
		return {
			typeLoading: false,
			error: false,
			code: '',
			AuthenticatorHelper,
			showSelector: false,
			resendProperties: null
		};
	},
	computed: {
		...mapState('auth', {
			token: state => state.token,
			loading: state => state.status.confirm.loading || state.status.confirm.success,
			success: state => state.status.confirm.success,
			created: state => state.status.confirm.created,
		}),
		...mapState('user', { 
			user: state => state.selected
		}),
		...mapGetters('routes', ['previousRouteName']),
		...mapGetters('user', {
			authenticator: 'authenticatorType',
			authenticatorList: 'authenticatorList'
		}),
		expiredRoute: (vm) => vm.$route.name == 'expired',
		showCodeInput: (vm) => !vm.expiredRoute || vm.created || vm.success && !vm.typeLoading,
		showExpireSelection: (vm) => vm.expiredRoute && !vm.created && !vm.success,
		authenticatorProperties: (vm) => vm.authenticatorList.find(v => v.authenticator == vm.authenticator),
		selectedProperties() {
			return this.resendProperties || this.authenticatorProperties;
		},
		currentAuthenticator() {
			return this.selectedProperties?.authenticator || this.authenticator;
		},
		phoneNumberUsed() {
			const authenticator = this.currentAuthenticator;
			return authenticator == 'sms' || authenticator == 'whats_app';
		}
	},
	methods: {
		...mapActions('auth', ['createConfirm', 'logout', 'confirm', 'confirmationReset', 'init2fa']),
		...mapActions('user', ['getUserList', 'selectUser', 'getSetting']),
		...mapActions('notifications', ['pushError']),
		async verifyExpiration() {
			this.createConfirm()
				.catch(err => {
					this.handleError(err);
					this.$router.replace({ name: 'login' });
				});
		},
		async goToSelectUser(list) {
			if (this.expiredRoute && !!this.user) {
				this.$router.replace({ name: this.previousRouteName || 'home' });
			}
			if (list && list.length > 1) {
				this.$router.replace({ name: 'select-user' });
			} else {
				try {
					const { profileId } = list[0];
					await this.selectUser(profileId);
					this.$router.replace({ name: 'home' });
				} catch (err) {
					this.handleError(err);
				}
			}
		},

		async resend() {
			const multi = this.authenticatorList?.length > 1;
			try {
				if (!multi) {
					await this.init2fa({
						type: 'login',
						provider: this.authenticator
					});
				} else {
					this.showSelector = true;
				}
			} catch (err) {
				this.handleError(err);
			}
		},

		userLogout() {
			this.logout();
			this.$router.replace({ name: 'login' });
		},

		handleError(err) {
			if (this.$refs.code) this.$refs.code.reset();
			if (err) {
				this.error = err.error;
				this.pushError(err);
			}
		},
		async selectResendMethod(value) {
			this.typeLoading = true;
			try {
				await this.init2fa({
					type: 'login',
					provider: value.authenticator
				});
			} catch (err) {
				this.pushError(err);
			}

			this.resendProperties = value;
			this.showSelector = false;
			this.typeLoading = false;
		}
	},
	watch: {
		async code(val) {
			if (val && val.length == 6) {
				try {
					const confirmRes = await this.confirm({ code: val });
					const list = await this.getUserList(confirmRes);
					this.goToSelectUser(list);
				} catch (err) {
					this.handleError(err);
				}
			}
		}
	},
	beforeMount() {
		this.confirmationReset();
		if (this.token == null) {
			return this.$router.push({ name: 'login' });
		} else if (this.user !== null) {
			return this.$router.push({ name: 'index' });
		}
	},
	async mounted() {
		this.typeLoading = true;
		try {
			await this.getSetting();
		} catch (err) {
			this.pushError(err.message);
			this.$router.push({ name: 'login' });
		}
		this.typeLoading = false;
	}
};
</script>
