var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.title ? _c('div', {
    staticClass: "preloader-txt"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]) : _c('div', {
    staticClass: "preloader-txt"
  }, [_vm._v(" Loading... ")]);
}
var staticRenderFns = []

export { render, staticRenderFns }