import $ from 'jquery';
import lodash from 'lodash';

import Vue from 'vue';
import Notifications from 'vue-notification';
import IdleVue from 'idle-vue';
import CripLoading from 'crip-vue-loading';
import vueDebounce from 'vue-debounce';
import axios from 'axios';
import * as Sentry from '@sentry/vue';

import './sentry';

import VueCountdownTimer from 'vuejs-countdown-timer';
import 'simplebar';
import 'simplebar/dist/simplebar.css';

Vue.use(CripLoading, { axios });
Vue.use(VueCountdownTimer);
Vue.use(Notifications);
Vue.use(vueDebounce);
window.$ = $;
window.axios = axios;
window.jQuery = $;
window._ = lodash;

import router from './routes';
import store from '~/store/index';
import App from './components/App.vue';
import VeeValidate from 'vee-validate';
import Icon from 'vue-awesome/components/Icon';
import Notification from './components/shared/Notification.vue';
import vMultiselect from './components/shared/Select.vue';
import PasswordInput from './components/shared/PasswordInput.vue';
import PhoneInput from './components/shared/PhoneInput.vue';
import {
	ServerTable,
	ClientTable
} from 'vue-tables-2';
import VueLocalStorage from 'vue-ls';
import VModal from 'vue-js-modal';
import 'vue-js-modal/dist/styles.css';
import Multiselect from 'vue-multiselect';
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import FilterInput from './directives/filterinput';
import NewBadge from './directives/newBadge';
import vueSmoothScroll from 'vue-smooth-scroll';
import IdleNotification from './components/shared/IdleNotification';

import { cookie } from '~/helpers';
import { refreshCookieName } from '~/config';

import VueApexCharts from 'vue-apexcharts';
Vue.use(VueApexCharts);

Vue.component('Apexchart', VueApexCharts);

import { Loader } from '@googlemaps/js-api-loader';

const loader = new Loader({
	apiKey: 'AIzaSyAr4tVgCcgvFm7BDCh3ExZfLCudjYWmol4',
	version: 'weekly',
	language: 'en',
	libraries: ['places']
});

loader.load();

import VueGtag from 'vue-gtag';

import VueGtm from '@gtm-support/vue2-gtm';
import errorHandler from './helpers/errorHandler.helper';

const gtagIds = {
	verifo: [
		DEV ? 'G-WYF22VY7TP' : 'G-Q70B4408HL',
	],
};

const gtagOptions = {};
if (gtagIds[WHITELABEL]) {
	const ids = gtagIds[WHITELABEL];
	if (ids[0]) {
		gtagOptions.config = {
			id: ids[0],
		};
		ids.shift();
		const includedIds = ids.filter(id => !!id);
		if (includedIds.length > 0) {
			gtagOptions.includes = includedIds.map(id => ({ id: id }));
		}
	}
}

Vue.use(VueGtag, gtagOptions);

const gtmIds = {
	verifo: [
		DEV ? null : 'GTM-MNHZJCC'  //Agency tag manager
	]
};

const gtmOptions = {};
if (gtmIds[WHITELABEL]) {
	const ids = gtmIds[WHITELABEL];
	if (ids[0]) {
		gtmOptions.id = ids[0];
	}
}

if (gtmOptions.id) {
	Vue.use(VueGtm, gtmOptions);
}



Vue.directive('filter-input', FilterInput);
Vue.directive('new-badge', NewBadge);

VeeValidate.Validator.extend('verify_password', {
	getMessage: () => 'The password does not meet the requirements',
	validate: value => {
		var strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
		return strongRegex.test(value);
	}
});

VeeValidate.Validator.dictionary.merge({
	en: {
		messages: {
			min: function (fieldName, params) {
				return `The field must be at least ${params[0]} characters.`;
			},
			required: function () {
				return 'The field is required';
			}
		},
		custom: {
			email: {
				email: 'Invalid email address'
			}
		}
	},
});

VeeValidate.Validator.extend('validate_iban', {
	getMessage: () => 'Please enter valid iban',
	validate: value => {
		const ibanRegex = new RegExp('[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[a-zA-Z0-9]{7}([a-zA-Z0-9]?){0,16}');
		return ibanRegex.test(value);
	}
});

const eventsHub = new Vue();

Vue.use(IdleVue, {
	eventEmitter: eventsHub,
	idleTime: 5 * 60 * 1000,
	adaptive: true,
	clickToClose: false,
});
Vue.use(vueSmoothScroll);
Vue.use(BootstrapVue);
Vue.use(VeeValidate);
Vue.use(VModal);
Vue.use(VueLocalStorage, {
	namespace: 'verifo__'
});

Vue.use(ClientTable, {}, true, 'bootstrap4', 'default');
Vue.use(ServerTable, {}, true, 'bootstrap4', 'default');

global.$ls = Vue.ls;
global.$router = router;

global.encodeQueryData = data => {
	let ret = [];
	for (let d in data)
		ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
	return ret.join('&');
};

global.prepareUrl = (template, data) => {
	let result = template;
	for (let key in data) {
		if (Object.prototype.hasOwnProperty.call(data, key)) {
			result = result.replace('{' + key + '}', data[key]);
		}
	}
	return result;
};

axios.interceptors.request.use(config => {
	config.headers['X-Requested-With'] = 'XMLHttpRequest';
	config.headers['Content-Type'] = 'application/json';
	config.withCredentials = true;
	return config;
}, error => {
	return Promise.reject(error);
});
axios.interceptors.response.use(response => {
	return response;
}, error => {
	const handledError = errorHandler(error);
	if (error?.response?.status == 401) {
		// Unauthorized
		store.dispatch('user/remove');
		if (cookie.exists(refreshCookieName)) {
			router.push({ name: 'expired' });
		} else {
			router.push({ name: 'login' });
		}
	} else {
		Sentry.captureException(error);
	}
	return Promise.reject(handledError);
});

Vue.component('App', App);
Vue.component('Notification', Notification);
Vue.component('Multiselect', Multiselect);
Vue.component('VMultiselect', vMultiselect);
Vue.component('PasswordInput', PasswordInput);
Vue.component('PhoneInput', PhoneInput);
Vue.component('Icon', Icon);
Vue.component('IdleNotification', IdleNotification);

Vue.config.ignoredElements = ['ion-icon'];

// reading active session authentication state before anything has started

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app');