var render = function () {
  var _vm$data$otherBankLis, _vm$data$transactionP, _vm$data$transactionP2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('form', {
    staticClass: "form-body margintop30",
    attrs: {
      "action": "#"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSubmit();
      }
    }
  }, [_c('h2', {
    staticClass: "page-title"
  }, [_vm._v(" Primary account information ")]), _c('fieldset', {
    attrs: {
      "disabled": _vm.details.verification == 'pending'
    }
  }, [_c('div', [_c('questionnaire-business-activities', {
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('label', [_vm._v(" Main business activity ")])];
      },
      proxy: true
    }, {
      key: "other-label",
      fn: function fn() {
        return [_c('label', [_vm._v(" Please specify other activities ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.data.businessActivities,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "businessActivities", $$v);
      },
      expression: "data.businessActivities"
    }
  })], 1), _c('div', [_c('b-row', [_c('b-col', [_c('form-error-field', {
    attrs: {
      "name": "sourceOfFunds.values"
    }
  }, [_c('label', [_vm._v(" Source of customer's funds "), _c('i', {
    staticClass: "fa fa-question-circle-o pull-right",
    attrs: {
      "aria-hidden": "true"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.$modal.show('source_of_funds');
      }
    }
  })]), _c('v-multiselect', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "options": _vm.options.sourceOfFundsTypes,
      "multiple": true,
      "allow-empty": false,
      "clear-on-select": true,
      "close-on-select": false,
      "name": "sourceOfFunds.values",
      "label": "name",
      "placeholder": "Select Your source of customer's funds",
      "track-by": "name",
      "return": "value",
      "data-registration": "source-of-funds",
      "data-vv-as": "Source of customer's funds"
    },
    model: {
      value: _vm.data.sourceOfFunds.values,
      callback: function callback($$v) {
        _vm.$set(_vm.data.sourceOfFunds, "values", $$v);
      },
      expression: "data.sourceOfFunds.values"
    }
  }, [_c('template', {
    slot: "selection"
  }, [_c('span', {
    staticClass: "multiselect__single"
  }, [_vm._v("options selected")])])], 2)], 1), _c('form-error-field', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.data.sourceOfFunds.values.includes('other'),
      expression: "data.sourceOfFunds.values.includes('other')"
    }],
    staticClass: "pt-2 mb-12",
    attrs: {
      "name": "sourceOfFunds.otherValue"
    }
  }, [_c('label', [_vm._v(" Please specify other sources of funds ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.data.sourceOfFunds.otherValue,
      expression: "data.sourceOfFunds.otherValue"
    }, {
      name: "validate",
      rawName: "v-validate",
      value: _vm.requiredSourceOfFunds,
      expression: "requiredSourceOfFunds"
    }],
    staticClass: "form-control",
    attrs: {
      "id": "source_of_funds_other",
      "type": "text",
      "name": "sourceOfFunds.otherValue",
      "placeholder": "Specify source of funds",
      "maxlength": "200",
      "data-registration": "source-of-funds-other",
      "data-vv-as": "Other source of funds"
    },
    domProps: {
      "value": _vm.data.sourceOfFunds.otherValue
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.data.sourceOfFunds, "otherValue", $event.target.value);
      }
    }
  })])], 1)], 1)], 1), _c('div', [_c('b-row', [_c('b-col', [_c('form-error-field', {
    attrs: {
      "name": "turnovers.count.incomingMonthly"
    }
  }, [_c('label', [_vm._v(" Monthly planned number of incoming transactions ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.data.turnovers.count.incomingMonthly,
      expression: "data.turnovers.count.incomingMonthly"
    }, {
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    staticClass: "form-control",
    attrs: {
      "id": "planned_number_of_incoming_transactions",
      "data-vv-as": "Monthly planned number of incoming transactions",
      "type": "number",
      "name": "turnovers.count.incomingMonthly",
      "placeholder": "Enter Your monthly planned number of incoming transactions",
      "min": "1",
      "max": "2147483647",
      "data-registration": "monthly-incoming-transactions"
    },
    domProps: {
      "value": _vm.data.turnovers.count.incomingMonthly
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.data.turnovers.count, "incomingMonthly", $event.target.value);
      }
    }
  })])], 1)], 1)], 1), _c('div', [_c('b-row', [_c('b-col', [_c('form-error-field', {
    attrs: {
      "name": "turnovers.amount.incomingMonthly"
    }
  }, [_c('label', [_vm._v(" Monthly planned turnover of incoming transactions, EUR ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.data.turnovers.amount.incomingMonthly,
      expression: "data.turnovers.amount.incomingMonthly"
    }, {
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    staticClass: "form-control",
    attrs: {
      "id": "planned_monthly_incoming_turnover",
      "data-vv-as": "Monthly planned turnover of incoming transactions",
      "type": "number",
      "name": "turnovers.amount.incomingMonthly",
      "placeholder": "Enter Your planned incoming monthly turnover",
      "min": "1",
      "max": "2147483647",
      "data-registration": "monthly-incoming-amount"
    },
    domProps: {
      "value": _vm.data.turnovers.amount.incomingMonthly
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.data.turnovers.amount, "incomingMonthly", $event.target.value);
      }
    }
  })])], 1)], 1)], 1), _c('div', [_c('b-row', [_c('b-col', [_c('form-error-field', {
    staticClass: "text-left mb-12",
    attrs: {
      "name": "countriesFundsOriginateFrom"
    }
  }, [_c('label', [_vm._v(" Countries, where funds are received from ")]), _c('v-multiselect', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "options": _vm.countryOptions,
      "multiple": true,
      "close-on-select": false,
      "clear-on-select": false,
      "preserve-search": true,
      "preselect-first": false,
      "name": "countriesFundsOriginateFrom",
      "placeholder": "Make selection",
      "label": "name",
      "track-by": "name",
      "return": "countryId",
      "data-registration": "origin-country",
      "data-vv-as": "Countries, where funds are received from"
    },
    model: {
      value: _vm.data.countriesFundsOriginateFrom,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "countriesFundsOriginateFrom", $$v);
      },
      expression: "data.countriesFundsOriginateFrom"
    }
  })], 1)], 1)], 1)], 1), _c('div', [_c('b-row', [_c('b-col', [_c('form-error-field', {
    attrs: {
      "name": "turnovers.count.outgoingMonthly"
    }
  }, [_c('label', [_vm._v(" Monthly planned number of outgoing transactions ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.data.turnovers.count.outgoingMonthly,
      expression: "data.turnovers.count.outgoingMonthly"
    }, {
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    staticClass: "form-control",
    attrs: {
      "id": "planned_number_of_outgoing_transactions",
      "data-vv-as": "Monthly planned number of outgoing transactions",
      "type": "number",
      "name": "turnovers.count.outgoingMonthly",
      "placeholder": "Enter Your monthly planned number of outgoing transactions",
      "min": "1",
      "max": "2147483647",
      "data-registration": "monthly-outgoing-transactions"
    },
    domProps: {
      "value": _vm.data.turnovers.count.outgoingMonthly
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.data.turnovers.count, "outgoingMonthly", $event.target.value);
      }
    }
  })])], 1)], 1)], 1), _c('div', [_c('b-row', [_c('b-col', [_c('form-error-field', {
    attrs: {
      "name": "turnovers.amount.outgoingMonthly"
    }
  }, [_c('label', [_vm._v(" Monthly planned turnover of outgoing transactions, EUR ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.data.turnovers.amount.outgoingMonthly,
      expression: "data.turnovers.amount.outgoingMonthly"
    }, {
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    staticClass: "form-control",
    attrs: {
      "id": "planned_monthly_outgoing_turnover",
      "data-vv-as": "Monthly planned turnover of outgoing transactions",
      "type": "number",
      "name": "turnovers.amount.outgoingMonthly",
      "placeholder": "Enter Your planned outgoing monthly turnover",
      "min": "1",
      "max": "2147483647",
      "data-registration": "monthly-outgoing-amount"
    },
    domProps: {
      "value": _vm.data.turnovers.amount.outgoingMonthly
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.data.turnovers.amount, "outgoingMonthly", $event.target.value);
      }
    }
  })])], 1)], 1)], 1), _c('div', [_c('b-row', [_c('b-col', [_c('form-error-field', {
    staticClass: "text-left mb-12",
    attrs: {
      "name": "countriesFundsOriginateTo"
    }
  }, [_c('label', [_vm._v(" Countries, where funds are sent to ")]), _c('v-multiselect', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "options": _vm.countryOptions,
      "multiple": true,
      "name": "countriesFundsOriginateTo",
      "close-on-select": false,
      "clear-on-select": false,
      "preserve-search": true,
      "preselect-first": false,
      "placeholder": "Make selection",
      "label": "name",
      "track-by": "name",
      "return": "countryId",
      "data-registration": "destination-country",
      "data-vv-as": "Countries, where funds are sent to"
    },
    model: {
      value: _vm.data.countriesFundsOriginateTo,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "countriesFundsOriginateTo", $$v);
      },
      expression: "data.countriesFundsOriginateTo"
    }
  })], 1)], 1)], 1)], 1), _c('div', [_c('b-row', [_c('b-col', [_c('form-error-field', {
    attrs: {
      "name": "turnovers.cash.outgoingMonthly"
    }
  }, [_c('label', [_vm._v(" The sum of monthly planned cash withdrawals, EUR ")]), _c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "name": "turnovers.cash.outgoingMonthly",
      "type": "number",
      "min": "0",
      "max": "2147483647",
      "placeholder": "Enter Your planned monthly cash withdrawals",
      "data-registration": "planned-cash",
      "data-vv-as": "The sum of monthly planned cash withdrawals, EUR"
    },
    model: {
      value: _vm.data.turnovers.cash.outgoingMonthly,
      callback: function callback($$v) {
        _vm.$set(_vm.data.turnovers.cash, "outgoingMonthly", $$v);
      },
      expression: "data.turnovers.cash.outgoingMonthly"
    }
  })], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "registration-section-banks"
  }, [_vm._m(0), _vm._l(_vm.data.otherBankList, function (bank, index) {
    return _c('div', {
      key: index
    }, [_c('b-row', [_c('b-col', {
      attrs: {
        "sm": "6"
      }
    }, [_c('form-error-field', {
      attrs: {
        "name": "otherBankList[".concat(index, "][name]")
      }
    }, [_c('label', {
      staticClass: "label"
    }, [_vm._v("Name of institution")]), _c('b-form-input', {
      attrs: {
        "id": bank.name,
        "name": "otherBankList[".concat(index, "][name]"),
        "type": "text",
        "autocomplete": "off_name",
        "placeholder": "Enter name of institution",
        "data-registration": "bank-name-".concat(index),
        "data-vv-as": "Name of institution"
      },
      model: {
        value: bank.name,
        callback: function callback($$v) {
          _vm.$set(bank, "name", $$v);
        },
        expression: "bank.name"
      }
    })], 1)], 1), _c('b-col', {
      attrs: {
        "sm": "6"
      }
    }, [_c('form-error-field', {
      attrs: {
        "name": "otherBankList[".concat(index, "][countryId]")
      }
    }, [_c('label', {
      staticClass: "label"
    }, [_vm._v("Country of institution")]), _c('v-multiselect', {
      attrs: {
        "name": "otherBankList[".concat(index, "][countryId]"),
        "options": _vm.countryOptions,
        "show-labels": false,
        "allow-empty": true,
        "label": "name",
        "placeholder": "Select country",
        "track-by": "countryId",
        "return": "countryId",
        "data-registration": "bank-country-".concat(index),
        "data-vv-as": "Country of institution"
      },
      model: {
        value: bank.countryId,
        callback: function callback($$v) {
          _vm.$set(bank, "countryId", $$v);
        },
        expression: "bank.countryId"
      }
    })], 1)], 1)], 1)], 1);
  }), _c('div', {
    staticClass: "btn btn-dark btn-sm px-5",
    attrs: {
      "data-registration": "add-bank"
    },
    on: {
      "click": function click($event) {
        return _vm.addInput('otherBankList');
      }
    }
  }, [_vm._v(" Add ")]), ((_vm$data$otherBankLis = _vm.data.otherBankList) === null || _vm$data$otherBankLis === void 0 ? void 0 : _vm$data$otherBankLis.length) > 0 ? _c('div', {
    staticClass: "btn btn-outline-danger btn-sm px-3 ml-2",
    attrs: {
      "data-registration": "remove-bank"
    },
    on: {
      "click": function click($event) {
        return _vm.removeInput('otherBankList');
      }
    }
  }, [_vm._v(" Remove ")]) : _vm._e()], 2), _c('div', {
    staticClass: "registration-section-partners mt-4"
  }, [_vm._m(1), _vm._l(_vm.data.transactionPartnerList, function (partner, i) {
    return _c('div', {
      key: i
    }, [_c('b-row', [_c('b-col', {
      attrs: {
        "sm": "6"
      }
    }, [_c('form-error-field', {
      attrs: {
        "name": "transactionPartnerList[".concat(i, "][name]")
      }
    }, [_c('label', {
      staticClass: "label"
    }, [_vm._v("Name of partner")]), _c('b-form-input', {
      directives: [{
        name: "validate",
        rawName: "v-validate",
        value: 'required',
        expression: "'required'"
      }],
      attrs: {
        "id": partner.name,
        "name": "transactionPartnerList[".concat(i, "][name]"),
        "type": "text",
        "autocomplete": "off_name",
        "placeholder": "Enter name of institution",
        "data-registration": "partner-name-".concat(i),
        "data-vv-as": "Name of partner"
      },
      model: {
        value: partner.name,
        callback: function callback($$v) {
          _vm.$set(partner, "name", $$v);
        },
        expression: "partner.name"
      }
    })], 1)], 1), _c('b-col', {
      attrs: {
        "sm": "6"
      }
    }, [_c('form-error-field', {
      attrs: {
        "name": "transactionPartnerList[".concat(i, "][countryId]")
      }
    }, [_c('label', {
      staticClass: "label"
    }, [_vm._v("Country of partner")]), _c('v-multiselect', {
      directives: [{
        name: "validate",
        rawName: "v-validate",
        value: 'required',
        expression: "'required'"
      }],
      attrs: {
        "name": "transactionPartnerList[".concat(i, "][countryId]"),
        "options": _vm.countryOptions,
        "show-labels": false,
        "allow-empty": true,
        "label": "name",
        "placeholder": "Select country",
        "track-by": "countryId",
        "return": "countryId",
        "data-registration": "partner-country-".concat(i),
        "data-vv-as": "Country of partner"
      },
      model: {
        value: partner.countryId,
        callback: function callback($$v) {
          _vm.$set(partner, "countryId", $$v);
        },
        expression: "partner.countryId"
      }
    })], 1)], 1)], 1)], 1);
  }), _c('form-error-field', {
    staticClass: "pb-3 position-relative",
    attrs: {
      "name": "transactionPartnerList"
    }
  }, [_c('div', {
    staticClass: "btn btn-sm px-5",
    "class": _vm.errorTransactionPartners && ((_vm$data$transactionP = _vm.data.transactionPartnerList) === null || _vm$data$transactionP === void 0 ? void 0 : _vm$data$transactionP.length) == 0 ? 'btn-danger' : 'btn-dark',
    attrs: {
      "data-registration": "add-partner"
    },
    on: {
      "click": function click($event) {
        return _vm.addInput('transactionPartnerList');
      }
    }
  }, [_vm._v(" Add ")]), ((_vm$data$transactionP2 = _vm.data.transactionPartnerList) === null || _vm$data$transactionP2 === void 0 ? void 0 : _vm$data$transactionP2.length) > 1 ? _c('div', {
    staticClass: "btn btn-outline-danger btn-sm px-3 ml-2",
    attrs: {
      "data-registration": "remove-partner"
    },
    on: {
      "click": function click($event) {
        return _vm.removeInput('transactionPartnerList');
      }
    }
  }, [_vm._v(" Remove ")]) : _vm._e()])], 2)]), _c('div', [_c('b-row', [_c('b-col', {
    staticClass: "mt-4 mb-4  text-center"
  }, [_c('notification'), _c('input', {
    staticClass: "btn btn-primary btn-rounded btn-upper w-100",
    "class": _vm.buttonClass,
    attrs: {
      "disabled": _vm.nextEnabled == false,
      "type": "submit",
      "data-registration": "next"
    },
    domProps: {
      "value": _vm.buttonText
    }
  })], 1)], 1), _vm.allowSkip ? _c('b-row', [_c('b-col', {
    staticClass: "text-center"
  }, [_c('router-link', {
    staticClass: "framelink skiplink",
    attrs: {
      "to": {
        name: 'home'
      },
      "tag": "a",
      "exact": "",
      "data-registration": "skip"
    }
  }, [_vm.details.verification !== 'pending' ? _c('span', [_vm._v("Skip verification")]) : _vm._e(), _vm.details.verification == 'pending' ? _c('span', [_vm._v("Back to main page")]) : _vm._e()])], 1)], 1) : _vm._e()], 1)]), _c('modal', {
    attrs: {
      "adaptive": true,
      "name": "source_of_funds",
      "height": "auto"
    }
  }, [_c('div', {
    staticClass: "close",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('source_of_funds');
      }
    }
  }), _c('div', {
    staticClass: "ml-4 mr-4 mt-4 mb-4"
  }, [_vm._v(" Please note that:"), _c('br'), _c('strong', [_vm._v("Direct clients funds")]), _vm._v(" - Money deposited by the client to their account that remains their property. For example, deposited to a trading account. "), _c('br'), _c('br'), _c('strong', [_vm._v("Business settlements")]), _vm._v(" - Daily operational settlements, fees, and payments implemented by your clients and/or partners. ")])])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-group mb-0"
  }, [_c('label', [_vm._v(" Accounts in other banks or payment/electronic money institutions ")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-group mb-0"
  }, [_c('label', [_vm._v(" Biggest transaction partners ")])]);
}]

export { render, staticRenderFns }