var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "multiselect",
    "class": {
      'multiselect--active': _vm.isOpen,
      'multiselect--disabled': _vm.disabled,
      'multiselect--above': _vm.isAbove
    },
    attrs: {
      "tabindex": _vm.searchable ? 0 : _vm.tabindex
    },
    on: {
      "focus": function focus($event) {
        return _vm.activate();
      },
      "blur": function blur($event) {
        _vm.searchable ? false : _vm.deactivate();
      },
      "keydown": [function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "down", 40, $event.key, ["Down", "ArrowDown"])) { return null; }
        if ($event.target !== $event.currentTarget) { return null; }
        $event.preventDefault();
        return _vm.pointerForward();
      }, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])) { return null; }
        if ($event.target !== $event.currentTarget) { return null; }
        $event.preventDefault();
        return _vm.pointerBackward();
      }, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter") && _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")) { return null; }
        $event.stopPropagation();
        if ($event.target !== $event.currentTarget) { return null; }
        return _vm.addPointerElement($event);
      }],
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) { return null; }
        return _vm.deactivate();
      }
    }
  }, [_vm._t("caret", function () {
    return [_c('div', {
      staticClass: "multiselect__select",
      on: {
        "mousedown": function mousedown($event) {
          $event.preventDefault();
          $event.stopPropagation();
          return _vm.toggle();
        }
      }
    })];
  }, {
    "toggle": _vm.toggle
  }), _vm._t("clear", null, {
    "search": _vm.search
  }), _c('div', {
    ref: "tags",
    staticClass: "multiselect__tags"
  }, [_vm.disableTags ? _c('span', [_c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isOpen && _vm.internalValue.length > 0,
      expression: "!isOpen && internalValue.length > 0"
    }],
    staticClass: "multiselect__single"
  }, [_vm._v(_vm._s(_vm.internalValue.length) + " options selected")])]) : _vm._e(), !_vm.disableTags ? _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.visibleValues.length > 0,
      expression: "visibleValues.length > 0"
    }],
    staticClass: "multiselect__tags-wrap"
  }, [_vm._l(_vm.visibleValues, function (option) {
    return [_vm._t("tag", function () {
      return [_c('span', {
        key: _vm.returnKey ? option[_vm.returnKey] : option,
        staticClass: "multiselect__tag"
      }, [_c('span', {
        domProps: {
          "textContent": _vm._s(_vm.getOptionLabel(option))
        }
      }), _c('i', {
        staticClass: "multiselect__tag-icon",
        attrs: {
          "aria-hidden": "true",
          "tabindex": "1"
        },
        on: {
          "keydown": function keydown($event) {
            if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
            $event.preventDefault();
            return _vm.removeElement(option);
          },
          "mousedown": function mousedown($event) {
            $event.preventDefault();
            return _vm.removeElement(option);
          }
        }
      })])];
    }, {
      "option": option,
      "search": _vm.search,
      "remove": _vm.removeElement
    })];
  })], 2) : _vm._e(), _vm.internalValue && _vm.internalValue.length > _vm.limit ? [_vm._t("limit", function () {
    return [_c('strong', {
      staticClass: "multiselect__strong",
      domProps: {
        "textContent": _vm._s(_vm.limitText(_vm.internalValue.length - _vm.limit))
      }
    })];
  })] : _vm._e(), _c('transition', {
    attrs: {
      "name": "multiselect__loading"
    }
  }, [_vm._t("loading", function () {
    return [_c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.loading,
        expression: "loading"
      }],
      staticClass: "multiselect__spinner"
    })];
  })], 2), _c('input', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isOpen && _vm.searchable,
      expression: "isOpen && searchable"
    }],
    ref: "search",
    staticClass: "multiselect__input",
    style: _vm.inputStyle,
    attrs: {
      "id": _vm.id,
      "name": _vm.name,
      "autocomplete": _vm.name,
      "placeholder": _vm.placeholder,
      "disabled": _vm.disabled,
      "tabindex": _vm.tabindex,
      "type": "text"
    },
    domProps: {
      "value": _vm.search
    },
    on: {
      "input": function input($event) {
        return _vm.updateSearch($event.target.value);
      },
      "focus": function focus($event) {
        $event.preventDefault();
        return _vm.activate();
      },
      "blur": function blur($event) {
        $event.preventDefault();
        return _vm.deactivate();
      },
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) { return null; }
        return _vm.deactivate();
      },
      "keydown": [function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "down", 40, $event.key, ["Down", "ArrowDown"])) { return null; }
        $event.preventDefault();
        return _vm.pointerForward();
      }, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])) { return null; }
        $event.preventDefault();
        return _vm.pointerBackward();
      }, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        $event.preventDefault();
        $event.stopPropagation();
        if ($event.target !== $event.currentTarget) { return null; }
        return _vm.addPointerElement($event);
      }, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "delete", [8, 46], $event.key, ["Backspace", "Delete", "Del"])) { return null; }
        $event.stopPropagation();
        return _vm.removeLastElement();
      }]
    }
  }), _vm.isSingleLabelVisible ? _c('span', {
    staticClass: "multiselect__single",
    on: {
      "mousedown": function mousedown($event) {
        $event.preventDefault();
        return _vm.toggle.apply(null, arguments);
      }
    }
  }, [_vm._t("singleLabel", function () {
    return [_c('div', [_vm._v(_vm._s(_vm.currentOptionLabel))])];
  }, {
    "option": _vm.singleValue
  })], 2) : _vm._e(), _vm.isPlaceholderVisible ? _c('span', {
    on: {
      "mousedown": function mousedown($event) {
        $event.preventDefault();
        return _vm.toggle.apply(null, arguments);
      }
    }
  }, [_vm._t("placeholder", function () {
    return [_c('span', {
      staticClass: "multiselect__single"
    }, [_vm._v(" " + _vm._s(_vm.placeholder) + " ")])];
  })], 2) : _vm._e()], 2), _c('transition', {
    attrs: {
      "name": "multiselect"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isOpen,
      expression: "isOpen"
    }],
    ref: "list",
    staticClass: "multiselect__content-wrapper",
    style: {
      maxHeight: _vm.optimizedHeight + 'px'
    },
    on: {
      "focus": _vm.activate,
      "mousedown": function mousedown($event) {
        $event.preventDefault();
      }
    }
  }, [_c('ul', {
    staticClass: "multiselect__content",
    style: _vm.contentStyle
  }, [_vm._t("beforeList"), _vm.multiple && _vm.max === _vm.internalValue.length ? _c('li', [_c('span', {
    staticClass: "multiselect__option"
  }, [_vm._t("maxElements", function () {
    return [_vm._v("Maximum of " + _vm._s(_vm.max) + " options selected. First remove a selected option to select another.")];
  })], 2)]) : _vm._e(), !_vm.max || _vm.internalValue.length < _vm.max ? _vm._l(_vm.filteredOptions, function (option, index) {
    return _c('li', {
      key: index,
      staticClass: "multiselect__element"
    }, [!(option && (option.$isLabel || option.$isDisabled)) ? _c('span', {
      staticClass: "multiselect__option",
      "class": _vm.optionHighlight(index, option),
      attrs: {
        "data-select": option && option.isTag ? _vm.tagPlaceholder : _vm.selectLabelText,
        "data-selected": _vm.selectedLabelText,
        "data-deselect": _vm.deselectLabelText
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.select(option);
        },
        "mouseenter": function mouseenter($event) {
          if ($event.target !== $event.currentTarget) { return null; }
          return _vm.pointerSet(index);
        }
      }
    }, [_vm._t("option", function () {
      return [_c('span', [_vm._v(_vm._s(_vm.getOptionLabel(option)))])];
    }, {
      "option": option,
      "search": _vm.search
    })], 2) : _vm._e(), option && (option.$isLabel || option.$isDisabled) ? _c('span', {
      staticClass: "multiselect__option",
      "class": _vm.groupHighlight(index, option),
      attrs: {
        "data-select": _vm.groupSelect && _vm.selectGroupLabelText,
        "data-deselect": _vm.groupSelect && _vm.deselectGroupLabelText
      },
      on: {
        "mouseenter": function mouseenter($event) {
          if ($event.target !== $event.currentTarget) { return null; }
          _vm.groupSelect && _vm.pointerSet(index);
        },
        "mousedown": function mousedown($event) {
          $event.preventDefault();
          return _vm.selectGroup(option);
        }
      }
    }, [_vm._t("option", function () {
      return [_c('span', [_vm._v(_vm._s(_vm.getOptionLabel(option)))])];
    }, {
      "option": option,
      "search": _vm.search
    })], 2) : _vm._e()]);
  }) : _vm._e(), _c('li', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showNoResults && _vm.filteredOptions.length === 0 && _vm.search && !_vm.loading,
      expression: "showNoResults && (filteredOptions.length === 0 && search && !loading)"
    }]
  }, [_c('span', {
    staticClass: "multiselect__option"
  }, [_vm._t("noResult", function () {
    return [_vm._v("No elements found. Consider changing the search query.")];
  })], 2)]), _vm._t("afterList")], 2)])])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }