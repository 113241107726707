var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('date-range-picker', {
    attrs: {
      "date-range": _vm.dateRange,
      "opens": _vm.windowWidth < 755 ? 'center' : _vm.side
    },
    on: {
      "update": _vm.updateRangePicker
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function fn(picker) {
        return [_c('div', {
          staticStyle: {
            "min-width": "350px"
          }
        }, [_c('img', {
          attrs: {
            "src": "/images/clear.svg"
          },
          on: {
            "click": _vm.clearRangePicker
          }
        }), _vm._v(" " + _vm._s(picker.startDate ? "".concat(_vm.pickerDateConvert(picker.startDate), " - ").concat(_vm.pickerDateConvert(picker.endDate)) : "Dates") + " ")])];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }