export default {
	list: [],
	listSwift: [],
	listPhone: [],
	status: {
		loading: false
	},
	promises: {
		list: null,
		listSwift: null,
		listPhone: null,
	}
};