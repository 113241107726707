import * as types from '../mutation-types';
import axios from 'axios/index';
import { endpoints } from '~/config';

export default {
	state: {
		currenciesLoaded: false,
		currencies: []
	},
	mutations: {
		[types.UPDATE_CURRENCIES](state, payload) {
			state.currencies = payload;
			state.currenciesLoaded = true;
		}
	},
	getters: {
		currenciesOptions: state => {
			const options = [];

			state.currencies.forEach(function ({ currency_id }) {
				let item = {
					value: currency_id,
					text: currency_id.toUpperCase(),
				};

				// Hardcode \o/
				if (currency_id === 'eur') { // move "eur" to first element
					options.splice(0, 0, item);
				} else if (currency_id === 'usd') { // move "usd" to second element
					options.splice(1, 0, item);
				} else {
					options.push(item);
				}
			});

			return options;
		},
		currencySymbol: function (currencyId) {
			if (currencyId === 'eur') {
				return '€';
			} else if (currencyId === 'usd') {
				return '$';
			} else {
				if (typeof currencyId !== 'string') return '';
				return currencyId.charAt(0).toUpperCase() + currencyId.slice(1);
			}
		}
	},
	actions: {
		async getCurrencyList({ state, commit }) {
			if (state.currenciesLoaded) return Promise.resolve(state.currencies);
			const params = {
				method: 'GET',
				url: endpoints.currency.list
			};

			try {
				const { data } = await axios(params);
				commit(types.UPDATE_CURRENCIES, data);
				return Promise.resolve(data);
			} catch (err) {
				return Promise.reject(err);
			}
		}
	}
};
