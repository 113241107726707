import moment from 'moment';
import loginCounter from '~/helpers/loginCounter.helper';

const sessionStorageKey = 'hiddenBadges';
const sessionStorageValue = sessionStorage.getItem(sessionStorageKey);
const hiddenBadges = sessionStorageValue ? JSON.parse(sessionStorageValue) : [];

const badgeCounterKey = 'bcount';

function shouldHide(binding) {
	const counter = new loginCounter();
	const loginCount = counter.getCount();
	const id = binding.value.id;

	let badgesCounter = localStorage.getItem(badgeCounterKey);
	if (!badgesCounter) {
		localStorage.setItem(badgeCounterKey, JSON.stringify({ [binding.value.id]: loginCount || 0 }));
	} else {
		const countObj = JSON.parse(badgesCounter);
		if (countObj[binding.value.id] == undefined) {
			countObj[binding.value.id] = loginCount || 0;
			localStorage.setItem(badgeCounterKey, JSON.stringify(countObj));
		}
	}
	badgesCounter = localStorage.getItem(badgeCounterKey);
	const exactBadgeCount = JSON.parse(badgesCounter)[binding.value.id];

	if (binding.value.hidden) return true; 
	if (binding.value.expire) {
		const expireDate = moment(binding.value.expire, 'YYYY-MM-DD');
		if (moment().isAfter(expireDate)) return true;
	}
	if (hiddenBadges.includes(id)) return true;
	if (loginCount > exactBadgeCount + (binding.value.counter || 3) ) return true;
	return false;
}

export default {
	bind(el, binding) {
		const id = binding.value.id;
		if (shouldHide(binding)) return;

		el.style.position = 'relative';

		const badge = document.createElement('div');
		const badgeIcon = document.createElement('span');
		badgeIcon.innerHTML = binding.value.title  || 'new';
		badge.append(badgeIcon);
		badge.className = 'new-badge';
		el.append(badge);

		el.addEventListener('mouseover', () => {
			badge.className = 'new-badge new-badge--fadeout';
			if (id && !hiddenBadges.includes(id)) {
				hiddenBadges.push(id);
				sessionStorage.setItem(sessionStorageKey, JSON.stringify(hiddenBadges));
			}
		});
	}
};
