import { UPDATE_ROUTE, SET_ROUTE_REFRESH_KEY } from '~/store/mutation-types';
import router from '~/routes';

export default {
	setRoute: ({ commit }, { from, to }) => {
		commit(UPDATE_ROUTE, { from, to });
	},
	pushRefresh: async ({ commit }, options) => {
		await commit(SET_ROUTE_REFRESH_KEY);
		return router.push(options);
	},
	replaceRefresh: async ({ commit }, options) => {
		await commit(SET_ROUTE_REFRESH_KEY);
		return router.replace(options);
	},
};