var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "wallet-switch-modal",
      "centered": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn(_ref) {
        var close = _ref.close;
        return undefined;
      }
    }, {
      key: "default",
      fn: function fn(_ref2) {
        var hide = _ref2.hide;
        return [_c('h5', {
          staticClass: "mb-4"
        }, [_vm._v(" Select account ")]), _c('div', {
          staticClass: "switch-wallet-wrapper"
        }, [_c('div', {
          staticClass: "wallets-list"
        }, [_c('div', {
          staticClass: "wallet-item wallet-item-current d-flex align-items-center"
        }, [_c('div', {
          staticClass: "avatar"
        }, [_c('span', [_vm._v(_vm._s(_vm.currentWallet.name ? _vm.currentWallet.name.charAt(0) : _vm.currentWallet.walletId.charAt(0)))])]), _c('div', {
          staticClass: "current-item-details align-self-center"
        }, [_c('div', {
          staticClass: "name"
        }, [_vm._v(" " + _vm._s(_vm.currentWallet.name) + " ")]), _c('small', {
          staticClass: "number"
        }, [_vm._v(_vm._s(_vm.currentWallet.iban))])]), _c('div', {
          staticClass: "wallet-balance align-self-center ml-auto"
        }, [_vm._v(" " + _vm._s(_vm.currentWalletAmount.balance) + " "), _c('small', [_vm._v(_vm._s(_vm.currentWalletAmount.currencyId ? _vm.currentWalletAmount.currencyId.toUpperCase() : ''))])]), _c('div', {
          staticClass: "current-icon"
        }, [_c('ion-icon', {
          staticClass: "check-icon",
          attrs: {
            "name": "checkmark-done-outline"
          }
        })], 1)]), _vm._l(_vm.walletList, function (wallet) {
          return _c('div', {
            key: wallet.walletId,
            staticClass: "wallet-item wallet-item-switch d-flex align-items-center",
            on: {
              "click": function click($event) {
                return _vm.switchWallet(wallet.walletId);
              }
            }
          }, [_c('div', {
            staticClass: "avatar"
          }, [_c('span', [_vm._v(_vm._s(wallet.name ? wallet.name.charAt(0) : wallet.walletId.charAt(0)))])]), _c('div', {
            staticClass: "current-item-details align-self-center"
          }, [_c('div', {
            staticClass: "name"
          }, [_vm._v(" " + _vm._s(wallet.name) + " ")]), _c('small', {
            staticClass: "number"
          }, [_vm._v(_vm._s(wallet.iban))])]), _c('div', {
            staticClass: "wallet-balance align-self-center ml-auto"
          }, [_vm._v(" " + _vm._s(_vm.getWalletAmount(wallet).balance) + " "), _c('small', [_vm._v(_vm._s(_vm.getWalletAmount(wallet).currencyId ? _vm.getWalletAmount(wallet).currencyId.toUpperCase() : ''))])]), _c('div', {
            staticClass: "switch-arrow"
          }, [_c('span', {
            staticClass: "switch-text",
            on: {
              "click": function click($event) {
                return _vm.switchWallet(wallet.walletId);
              }
            }
          }, [_vm._v("Select")])])]);
        })], 2)])];
      }
    }, {
      key: "modal-footer",
      fn: function fn(_ref3) {
        var hide = _ref3.hide;
        return [_c('b-button', {
          attrs: {
            "variant": "btn btn-info"
          },
          on: {
            "click": function click($event) {
              return hide('forget');
            }
          }
        }, [_vm._v(" Close ")])];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }