<template>
	<div 
		id="faq-layout" 
		class="faq-container"
	>
		<div class="page-title-container">
			<h2>FAQ</h2>
		</div>

		<div class="faq-search">
			<input
				v-model="search"
				placeholder="Type to search"
				type="text"
				class="form-control"
				@keyup="searchAction"
			>
		</div>
		<b-row>
			<b-col
				lg="3"
			>
				<faq-nav :click-callback="disableSearch" />
			</b-col>
			<b-col lg="9">
				<div
					v-show="searchSuccess"
					class="search-results"
					role="tablist"
				>
					<div 
						v-for="(faq, index) in filteredList"
						:key="faq.title + faq.key"
						class="card"
					>
						<!--<h4>{{ faq.title }}</h4>-->
						<b-card-header
							class="accHeader"
							header-tag="header"
						>
							<div class="btc">
								<h5>{{ faq.question }}</h5>
							</div>
						</b-card-header>
						<div :id="faq.title + index">
							<b-card-body class="inner">
								<p 
									class="card-text" 
									v-html="faq.text"
								></p>
							</b-card-body>
						</div>
					</div>
					<br />
				</div>
				<router-view
					v-show="!searchSuccess"
					:data="Faq"
				/>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import FaqNav from './FaqNav.vue';
import $ from 'jquery';

function setIndicatorClass(tabsList) {
	if (!tabsList) {
		this.indicatorPositionClass = null;
		return;
	}

	this.indicatorPositionClass = ['is-indicator-visible'];

	if (tabsList.offsetWidth + tabsList.scrollLeft < this.maxWidth) {
		this.indicatorPositionClass.push('to-right');
	}

	if (tabsList.scrollLeft > 0) {
		this.indicatorPositionClass.push('to-left');
	}
}

export default {
	name: 'FaqLayout',
	components: {
		'faq-nav': FaqNav,
	},
	data() {
		return {
			indicatorPositionClass: null,
			maxWidth: 0,
			search: '',
			text: '',
			searchSuccess: false,
		};
	},
	computed: {
		Faq() {
			const data = require('./data/' + WHITELABEL + '.json');
			return data;
		},
		filteredList() {
			return this.Faq.filter(post => {
				const searchValue = this.search.toLowerCase();

				if (post.question && post.question.toLowerCase().includes(searchValue)) {
					return true;
				}

				if (post.text) {
					return post.text.toLowerCase().includes(searchValue);
				}
			});
		}
	},
	mounted: function() {
		let tabsList = $('#faq-tabs');
		this.maxWidth = tabsList.find('.tab')
			.get()
			.map((item) => item.offsetWidth)
			.reduce((a, b) => a + b, 0);

		setIndicatorClass.call(this, tabsList[0]);
	},
	methods: {
		searchAction() {
			this.searchSuccess = !!this.search;
		},
		disableSearch() {
			this.searchSuccess = false;
			this.search = '';
		},
		onTabsListScroll(event) {
			setIndicatorClass.call(this, event.target);
		},
	},
};
</script>
<style lang="scss">
	@import './FaqLayout.scss';
</style>