<template>
	<div class="card-list">
		<b-container>
			<b-row
				v-if="'active' === currentWallet.status"
			>
				<b-col>
					<div class="page-title-container">
						<div class="d-flex justify-content-between">
							<h2>Cards</h2>
							<new-card-button />
						</div>
					</div>
				</b-col>
			</b-row>
			<b-row>
				<b-col 
					v-for="(card, i) in activeCards" 
					:key="`active-${i}`" 
					md="3" 
					sm="6"
					class="card-list__card mb-5"
					@click="$router.push({ path: `/cards/view/${card.id}`})"
				>
					<b-card class="card-list__item-wrap"
						no-body
					>
						<div>
							<card-image
								:virtual="card.isVirtual"
								:show-details="false"
								width="30%"
							/>
							<div class="mt-2">
								{{ `${card.isVirtual ? 'Virtual' : 'Plastic'} ${formatShortCardNumber(card.cardNumber)}` }}
							</div>
							<div class="mb-4">
								<small v-if="card.status == 'active'">Expires on {{ formatExpirationDate(card.validTo) }}</small>
								<small
									v-if="['created', 'waiting_pin'].includes(card.status)"
									class="card-list__pending"
								>Waiting for PIN</small>
								<small
									v-if="['sent', 'waiting_cvv'].includes(card.status)"
									class="card-list__pending"
								>Shipped, ready for activation</small>
								<small
									v-if="['suspended_client', 'suspended_system', 'suspended_admin'].includes(card.status)"
									class="card-list__pending"
								>
									Temporary blocked <span v-if="'suspended_client' !== card.status || 'active' !== currentWallet.status">(System)</span>
								</small>
							</div>
						</div>
						<div class="card-list__link">
							<span v-if="card.status === 'active'">Settings</span>
							<span v-if="['created', 'waiting_pin'].includes(card.status)">Enter PIN</span>
							<span v-if="card.status === 'suspended_client' && 'active' === currentWallet.status">Unblock</span>
							<span v-if="['sent', 'waiting_cvv'].includes(card.status)">Activate</span>
							<span
								v-if="['active', 'waiting_pin', 'suspended_client', 'waiting_cvv', 'sent'].includes(card.status) && 'active' === currentWallet.status"
								class="card-list__arrow"
							/>
						</div>
					</b-card>
				</b-col>
				<!-- Card orders -->
				<b-col 
					v-for="(card, i) in ordersList" 
					:key="`order-${i}`" 
					md="3" 
					sm="6"
					class="card-list__card card-list__card--nolink mb-5"
				>
					<b-card class="card-list__item-wrap"
						no-body
					>
						<div>
							<card-image
								class="card-list__innactive-img"
								:virtual="card.isVirtual"
								:show-details="false"
								width="30%"
							/>
							<div class="mt-2">
								{{ `${card.isVirtual ? 'Virtual' : 'Plastic'}` }}
							</div>
							<div class="mb-4">
								<small>Card order in progress</small>
							</div>
						</div>
					</b-card>
				</b-col>
			</b-row>
			<!-- Innactive cards -->
			<div
				v-if="!!innactiveCards.length"
				v-b-toggle.innactive-cards
				class="d-flex align-items-center mb-5 card-list__dropdown-button"
			>
				<ion-icon
					class="card-list__dropdown-arrow icon-closed"
					name="chevron-down-outline"
				/>
				<ion-icon
					class="card-list__dropdown-arrow icon-opened"
					name="chevron-up-outline"
				/>
				<strong class="ml-1">Innactive cards</strong>
				<span class="ml-1">({{ innactiveCards.length }})</span>
			</div>
			<b-collapse id="innactive-cards">
				<b-row>
					<b-col 
						v-for="(card, i) in innactiveCards" 
						:key="`innactive-${i}`" 
						md="3" 
						sm="6"
						class="card-list__card mb-5"
						@click="$router.push({ path: `/cards/view/${card.id}`})"
					>
						<b-card class="card-list__item-wrap"
							no-body
						>
							<div>
								<card-image
									class="card-list__innactive-img"
									:virtual="card.isVirtual"
									:show-details="false"
									width="30%"
								/>
								<div class="mt-2">
									{{ `${card.isVirtual ? 'Virtual' : 'Plastic'} ${formatShortCardNumber(card.cardNumber)}` }}
								</div>
								<div class="mb-4">
									<small
										v-if="card.status == 'closed'"
										class="card-list__warning"
									>Blocked or expired</small>
								</div>
							</div>
							<div class="card-list__link">
								<span v-if="card.status == 'closed'">View</span>
								<span class="card-list__arrow"></span>
							</div>
						</b-card>
					</b-col>
					<!-- Failed orders -->
					<b-col 
						v-for="(card, i) in failedOrdersList" 
						:key="`failed-${i}`" 
						md="3" 
						sm="6"
						class="card-list__card card-list__card--nolink mb-5"
					>
						<b-card class="card-list__item-wrap"
							no-body
						>
							<div>
								<card-image
									class="card-list__innactive-img"
									:virtual="card.isVirtual"
									:show-details="false"
									width="30%"
								/>
								<div class="mt-2">
									{{ `${card.isVirtual ? 'Virtual' : 'Plastic'}` }}
								</div>
								<div class="mb-4">
									<small class="card-list__warning">Card order rejected.</small>
								</div>
							</div>
						</b-card>
					</b-col>
				</b-row>
			</b-collapse>
		</b-container>
	</div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import CardImage from './Partials/CardImage';
import NewCardButton from './Partials/NewCardButton';
import moment from 'moment';
export default {
	name: 'CardSettings',
	components: {
		CardImage,
		NewCardButton
	},
	data() {
		return {
			moment,
			activeList: [
				'active', 'created', 'suspended_client', 'suspended_admin', 'suspended_system', 'waiting_pin', 'waiting_cvv', 'sent'
			]
		};
	},
	computed: {
		...mapState('cards', {
			list: state => state.list,
			orders: state => state.ordersList
		}),
		activeCards() {
			return this.list.filter(c => this.activeList.includes(c.status));
		},
		innactiveCards() {
			return this.list.filter(c => !this.activeList.includes(c.status));
		},
		ordersList() {
			return this.orders.filter(order => ['created', 'failed', 'system_approved', 'manual_approved'].includes(order.status));
		},
		failedOrdersList() {
			return this.orders.filter(order => ['manual_rejected'].includes(order.status));
		},
		...mapGetters('wallet', [
			'currentWallet'
		])
	},
	methods: {
		...mapActions('cards', ['setCardsList']),
		formatShortCardNumber(cardNumber) {
			return `••${cardNumber.slice(-4)}`;
		},
		formatExpirationDate(date) {
			return moment(date).format('MM/YY');
		}
	},
	mounted() {
		this.setCardsList(true);
	}
};
</script>
<style lang="scss">
	@import './CardList.scss';
</style>