var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "auth-card"
  }, [_c('h1', {
    staticClass: "auth-title"
  }, [_vm._v(" Login ")]), _c('b-form', {
    staticClass: "form-body",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_c('notification'), _c('b-form-group', [_c('label', {
    staticClass: "sr-only",
    attrs: {
      "for": "email"
    }
  }, [_vm._v("Email Address")]), _c('b-form-input', {
    ref: "email",
    "class": {
      'is-invalid': _vm.errors.has('email') || _vm.failed
    },
    attrs: {
      "id": "email",
      "type": "text",
      "name": "email",
      "placeholder": "Email Address",
      "size": "lg"
    },
    nativeOn: {
      "focus": function focus($event) {
        return function () {
          return _vm.focusField('email');
        }.apply(null, arguments);
      }
    },
    model: {
      value: _vm.email,
      callback: function callback($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  }), _c('b-form-invalid-feedback', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.errors.has('email'),
      expression: "errors.has('email')"
    }]
  }, [_vm._v(" " + _vm._s(_vm.errors.first("email")) + " ")])], 1), _c('b-form-group', [_c('label', {
    staticClass: "sr-only",
    attrs: {
      "for": "password"
    }
  }, [_vm._v("Password")]), _c('b-form-input', {
    "class": {
      'is-invalid': _vm.errors.has('password') || _vm.failed
    },
    attrs: {
      "id": "password",
      "type": "password",
      "name": "password",
      "placeholder": "Password",
      "size": "lg"
    },
    nativeOn: {
      "focus": function focus($event) {
        return function () {
          return _vm.focusField('password');
        }.apply(null, arguments);
      }
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }), _c('b-form-invalid-feedback', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.errors.has('password'),
      expression: "errors.has('password')"
    }],
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.errors.first("password")) + " ")])], 1), _c('b-row', {
    staticClass: "justify-content-md-center"
  }, [_c('b-col', {
    staticClass: "text-center",
    attrs: {
      "md": "12"
    }
  }, [_c('div', [_c('router-link', {
    attrs: {
      "to": {
        name: 'recover-password'
      },
      "exact": ""
    }
  }, [_vm._v(" Forgot your password? ")])], 1)])], 1), _c('b-row', {
    staticClass: "justify-content-md-center pt-4"
  }, [_c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "md": "8"
    }
  }, [_c('button', {
    staticClass: "btn btn-primary btn-block",
    attrs: {
      "type": "submit",
      "disabled": _vm.loading
    }
  }, [_vm.loading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "variant": "light",
      "type": "grow",
      "label": "Spinning"
    }
  }) : _c('span', [_vm._v("Login")])], 1)])], 1), _c('div', [_vm._v(" You don't have an account? "), _c('router-link', {
    attrs: {
      "to": {
        name: 'register'
      },
      "exact": "",
      "data-login": "register"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.trackRegisterLink.apply(null, arguments);
      }
    }
  }, [_vm._v(" Register ")])], 1), _vm.WHITELABEL == 'supersonicz' ? _c('div', [_vm._v(" Go back "), _c('a', {
    attrs: {
      "href": "https://www.wisebanq.com"
    }
  }, [_vm._v(" Home ")])]) : _vm._e()], 1), _vm.WHITELABEL == 'verifo' ? _c('small', {
    staticStyle: {
      "padding": "5px",
      "border-radius": "5px",
      "border": "1px solid #ccc",
      "display": "inline-block",
      "margin-top": "15px",
      "color": "#555"
    }
  }, [_vm._v(" We have noticed activity from fraudsters, who want to obtain private data from customers. We remind you to never use suspicious links in SMS or e-mail, not to disclose login details to your account and to contact " + _vm._s(_vm.siteName) + " in case of doubt. ")]) : _vm._e(), _c('vue-recaptcha', {
    ref: "recaptcha",
    attrs: {
      "id": "recaptcha",
      "sitekey": _vm.recapthaSitekey,
      "load-recaptcha-script": true,
      "size": "invisible"
    },
    on: {
      "verify": _vm.onVerify
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }