var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card-order"
  }, [_c('b-container', [_c('b-row', [_c('b-col', [_c('div', {
    staticClass: "page-title-container"
  }, [_c('h2', [_vm._v("New Card")])])])], 1), _c('card-account-details'), _vm.profileCountryAllowed && _vm.countriesLoaded ? _c('div', [_c('b-row', [_c('b-col', [_c('span', {
    staticClass: "card-order__type-title"
  }, [_vm._v("Select card type")])])], 1), _vm.permissions.createCardOrder ? _c('b-row', [_c('b-col', {
    staticClass: "text-center card-order__selection mb-4",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "mb-4"
  }, [_c('card-image')], 1), _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'new-plastic-card'
        });
      }
    }
  }, [_vm._v(" New Visa Debit card ")])], 1), _c('b-col', {
    staticClass: "text-center card-order__selection mb-4",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "mb-4"
  }, [_c('card-image', {
    attrs: {
      "virtual": ""
    }
  })], 1), _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'new-virtual-card'
        });
      }
    }
  }, [_vm._v(" New Visa Debit Virtual card ")])], 1)], 1) : _vm._e()], 1) : _vm._e(), !_vm.permissions.createCardOrder || !_vm.profileCountryAllowed && _vm.countriesLoaded ? _c('b-row', [_c('b-col', {
    staticClass: "text-center"
  }, [_c('b-card', [_vm._v(" You're not allowed to order the card. ")])], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }