export default class {
	constructor() {
		this.loginCounterKey = 'lcount',
		this.loginCounterValue = localStorage.getItem(this.loginCounterKey);
	}

	getCount() {
		if (!this.loginCounterValue) return 0;
		return Number(this.loginCounterValue);
	}

	addCount() {
		const count = this.getCount();
		localStorage.setItem(this.loginCounterKey, count + 1);
	}
}
