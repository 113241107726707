<template>
	<div class="odd-form">
		<b-form>
			<b-row>
				<b-col>
					<h2>Update your company relation.</h2>
				</b-col>
			</b-row>
			<b-card class="mb-4">
				<b-row v-if="persons.length > 0"
					class="mb-2"
				>
					<b-col>Key persons:</b-col>
				</b-row>
				<b-row v-for="(person, index) in filteredPersons"
					:key="index"
					class="mb-4"
				>
					<b-col>
						<div class="odd-person d-flex justify-content-between">
							<div class="d-flex">
								<b-avatar
									variant="primary"
									:text="person.name ? person.name.charAt(0) : ''"
									class="mr-2"
								/>
								<div>
									<div>{{ person.name }} {{ person.surname }}</div>
									<div class="odd-person__type">
										{{ person.types?.join(', ') }}
									</div>
								</div>
							</div>
							<div class="odd-person__buttons d-flex">
								<b-button v-if="person._new"
									variant="primary"
									size="sm"
									class="mx-1"
									@click="edit(person)"
								>
									<i class="fas fa-user-edit"></i>
								</b-button>
								<b-button v-if="!person.types?.includes('owner')"
									variant="danger"
									size="sm"
									class="mx-1"
									@click="remove(index)"
								>
									<i class="fas fa-user-minus"></i>
								</b-button>
							</div>
						</div>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<b-button variant="primary"
							@click="add()"
						>
							Add person
						</b-button>
					</b-col>
				</b-row>
			</b-card>
			<transition name="fade">
				<div v-if="edittablePerson">
					<b-row class="mb-4">
						<b-col>
							<b-card>
								<b-form-group>
									<label for="person-types">
										Select person types
									</label>
									<v-multiselect v-model="edittablePerson.types"
										v-validate="'required'"
										:options="types"
										:close-on-select="false"
										:clear-on-select="false"
										label="name"
										track-by="name"
										return="value"
										name="person-types"
										placeholder="Select person types"
										:multiple="true"
									/>
								</b-form-group>
							</b-card>
						</b-col>
					</b-row>
					<b-row v-if="edittablePerson?.types?.length > 0">
						<b-col>
							<b-card>
								<person-input ref="person"
									v-model="edittablePerson"
									:person-type-options="edittablePerson.types"
									full-width
									allowtypes
									proof-of-residence
									:i-am="false"
									field-prefix="keyPersons"
								/>
								<div class="mt-4">
									<b-button variant="primary"
										@click="save()"
									>
										Save person
									</b-button>
									<b-button variant="outline-primary"
										class="ml-2"
										@click="cancel()"
									>
										Cancel
									</b-button>
								</div>
							</b-card>
						</b-col>
					</b-row>
				</div>
			</transition>
			<div>
				<b-form-input v-validate="'required'"
					hidden
					:value="allTypesIncluded"
					:class="{'is-invalid': errors.has('All types included')}"
					name="All types included"
				/>
				<div v-if="errors.has('All types included')"
					class="invalid-feedback"
				>
					You must include all {{ types.length }} types ({{ types.map(t => t.name).join(', ') }}) for key persons.
				</div>
			</div>
		</b-form>
		<odd-form-footer 
			v-bind="properties"
		/>
		<modal
			:adaptive="true"
			name="phone"
			height="auto"
		>
			<div
				class="close"
				@click="$modal.hide('phone')"
			></div>
			<div class="ml-4 mr-4 mt-4 mb-4">
				Person will receive SMS to this phone number to verify their identity. 
			</div>
		</modal>
		<modal
			:adaptive="true"
			name="political_exposure"
			height="auto"
		>
			<div
				class="close"
				@click="$modal.hide('political_exposure')"
			></div>
			<div class="ml-4 mr-4 mt-4 mb-4">
				Politically exposed person – private individual who is or has
				been entrusted with prominent public functions mentioned below
				including the functions in the Republic of Lithuania, European
				Union, international or foreign state institutions no more than
				one year ago, and immediate family members or persons known to
				be close associates of such person:
				<br /><br />1) Head of State, Heads of Government, minister,
				vice minister and deputy minister, secretary of the State,
				chancellor of Parliament, Government or Ministry; 2) Member of
				parliament; <br />3) Member of the Supreme Court, of the
				Constitutional Court or of other high-level judicial bodies
				whose decisions are not subject to further appeal; <br />4)
				Member of the managing body of the Lithuanian Chamber of
				Auditors or of the board of the central bank; <br />5) The
				ambassador, chargés d’affaires and high-ranking officer in the
				armed forces; <br />6) Member of the management or supervisory
				body of the publicly administered undertaking; <br />7)
				Director, deputy director or member of the management or
				supervisory body of the international intergovernmental
				organization; :sunglasses: Head, deputy head or member of the
				management body of political party.
			</div>
		</modal>
	</div>
</template>
<script>
import { mapState } from 'vuex';
import PersonInput from '~/components/shared/PersonInput';
import OddFormFooter from '../OddFormFooter';
export default {
	name: 'ODDCompanyRelation',
	components: {
		OddFormFooter,
		PersonInput
	},
	data() {
		return {
			formData: { keyPersons: [] },
			edittablePerson: null,
			edittableOwner: false,
			types: [
				{ value: 'representative', name: 'Representative' },
				{ value: 'shareholder', name: 'Shareholder' },
				{ value: 'director', name: 'Director' },
				{ value: 'ubo', name: 'UBO' }
			]
		};
	},
	computed: {
		...mapState('user', {
			persons: state => state.selected?.keyPersonsList
		}),
		properties() {
			return {
				...this._props, 
				formData: this.formData
			};
		},
		filteredPersons() {
			const filtered = {};
			this.persons.forEach(person => {
				filtered[person.relatedProfileId] = { ...person, types: [...filtered[person.relatedProfileId]?.types || [], person.type] };
			});
			const mapped = Object.entries(filtered).map(v => v[1]);
			return mapped;
		},
		allTypesIncluded() {
			const types = [];
			this.formData.keyPersons.forEach(p => {
				if (!types.includes(p.type)) types.push(p.type);
			});
			return this.types.length == types.length ? 'valid' : null;
		},
		keyPersonIndexErrors() {
			return this.errors;
		}
	},
	props: {
		onSuccess: {
			type: Function,
			default: () => {},
			required: true
		},
		onFailure: {
			type: Function,
			default: () => {},
		},
		onCancel: {
			type: Function,
			default: () => {}
		},
		submitUrl: {
			type: String,
			default: '',
			required: true
		}
	},
	watch: {
		'edittablePerson.types': {
			handler(value, old) {
				if (JSON.stringify(value) !== JSON.stringify(old) && !!this.edittablePerson?.entityType) {
					this.$set(this.edittablePerson, 'entityType', null);
				}
			}
		},
		edittablePerson: {
			handler() {
				if (this.edittableOwner && !this.edittablePerson.types.includes('owner')) {
					this.edittablePerson.types.push('owner');
				}
			},
			deep: true
		},
		filteredPersons: {
			handler() {
				this.updateFormData();
			},
			deep: true
		}
	},
	methods: {
		add() {
			this.edittablePerson = { _new: true };
		},
		remove(index) {
			if (window.confirm('Do you really want to remove it?')) {
				this.filteredPersons.splice(index, 1);
				this.$forceUpdate();
			}
		},
		edit(person) {
			if (!!this.edittablePerson && this.edittablePerson?.relatedProfileId == person?.relatedProfileId) {
				this.edittablePerson = null;
			} else {
				this.edittableOwner = person.types?.includes('owner');
				this.edittablePerson = { ...person, _edit: true };
			}
		},
		async save() {
			const valid = await this.$refs['person'].$validator.validate('person.*');
			if (!valid) return;
			const id = this.edittablePerson.relatedProfileId;
			const edit = this.edittablePerson._edit;
			if (id || edit) {
				const current = this.filteredPersons.find(p => p.relatedProfileId == id || p._edit);
				const index = this.filteredPersons.indexOf(current);
				this.filteredPersons[index] = { ...this.edittablePerson, _edit: null, index: this.edittablePerson.index };
			} else {
				this.filteredPersons.push({ ...this.edittablePerson, index: this.filteredPersons.filter(p => p._new).length });
			}
			this.edittableOwner = false;
			this.edittablePerson = null;
			this.updateFormData();
		},
		cancel() {
			this.edittableOwner = false;
			this.edittablePerson = null;
		},
		updateFormData() {
			this.formData.keyPersons = [];
			this.filteredPersons.forEach(person => {
				const types = person.types;
				const { entityType, name, surname, registrationCode, placeOfBirth, dateOfBirth, phone, email, countryForTax, politicalExposure, isUsCitizen, addresses, proofOfResidenceDocumentId, proofOfResidenceDocument } = person;
				types.forEach(type => {
					if (type == 'owner') return;
					this.formData.keyPersons.push({
						type,
						entityType,
						name,
						surname,
						registrationCode,
						placeOfBirth,
						dateOfBirth,
						phone,
						email,
						countryForTax,
						politicalExposure,
						isUsCitizen,
						addresses,
						proofOfResidenceDocument,
						proofOfResidenceDocumentId
					});
				});
			});
		},
		checkForInvalidPerson(errors) {
			const fieldNames = errors?.items?.map(error => error.field);
			const keyPersonFields = fieldNames.filter(f => f.indexOf('keyPersons[') !== -1);
			if (keyPersonFields?.length > 0) {
				const kpField = keyPersonFields[0];
				const personIndex = kpField.match(/keyPersons\[([0-9]+)\]/)[1];
				const person = this.filteredPersons.find(p => p.index == personIndex);
				if (person) {
					this.edit(person);
				}
			}
		}
	},
	mounted() {
		this.updateFormData();
	}
};
</script>
<style lang="scss">
	.odd-person {
		padding: 15px;
		border: 1px solid $primary;

		&__avatar {
			margin-right: 10px;

			span {
				display: inline-block;
				height: 50px;
				width: 50px;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 999px;
				background-color: red;
			}
		}

		&__type {
			text-transform: uppercase;
			font-size: 10px;
			color: $primary
		}
	}
</style>