// authentication
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';
export const CONFIRMATION_REQUEST = 'CONFIRMATION_REQUEST';
export const CONFIRMATION_SUCCESS = 'CONFIRMATION_SUCCESS';
export const CONFIRMATION_FAILURE = 'CONFIRMATION_FAILURE';
export const CONFIRMATION_RESET = 'CONFIRMATION_RESET';
export const CONFIRMATION_CREATE_SUCCESS = 'CONFIRMATION_CREATE_SUCCESS';

//cards
export const SET_CARDS_LIST = 'SET_CARDS_LIST';
export const SET_CARDS_ORDERS_LIST = 'SET_CARDS_ORDERS_LIST';
export const SET_CARDS_COUNTRIES = 'SET_CARDS_COUNTRIES';
export const RESET_CARDS = 'RESET_CARDS';
// countries
export const SET_COUNTRIES = 'SET_COUNTRIES';
export const SET_COUNTRIES_PROMISE = 'SET_COUNTRIES_PROMISE';

// identity
export const UPDATE_IDENTITY = 'UPDATE_IDENTITY';
export const UPDATE_REMOTE_IDENTITY = 'UPDATE_REMOTE_IDENTITY';

//register
export const CLEAR_REGISTER_ERRORS = 'CLEAR_REGISTER_ERRORS';
export const REGISTER_LOADING = 'REGISTER_LOADING';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const SET_REGISTER_ACCOUNT_ROLE = 'SET_REGISTER_ACCOUNT_ROLE';
export const SET_REGISTER_DATA = 'SET_REGISTER_DATA';
export const SET_REGISTER_AUTHENTICATOR = 'SET_REGISTER_AUTHENTICATOR';
export const REGISTER_CHANGE_EMAIL = 'REGISTER_CHANGE_EMAIL';
export const UPDATE_LOGIN_INFO = 'UPDATE_LOGIN_INFO';
export const UPDATE_LOGIN_INFO_PASSWORD_CONFIRM = 'UPDATE_LOGIN_INFO_PASSWORD_CONFIRM';
export const UPDATE_LOGIN_INFO_PASSWORD = 'UPDATE_LOGIN_INFO_PASSWORD';
export const UPDATE_LOGIN_INFO_CODE = 'UPDATE_LOGIN_INFO_CODE';


//routes
export const UPDATE_ROUTE = 'UPDATE_ROUTE';
export const SET_ROUTE_REFRESH_KEY = 'SET_ROUTE_REFRESH_KEY';

// user
export const SET_USER = 'SET_USER';
export const SET_USER_LIST = 'SET_USER_LIST';
export const UNSET_USER = 'UNSET_USER';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const SET_USER_VERIFIED = 'SET_USER_VERIFIED';
export const SET_USER_PERMISSIONS = 'SET_USER_PERMISSIONS';
export const SET_WALLET_PERMISSIONS = 'SET_WALLET_PERMISSIONS';
export const SET_USER_AUTHENTICATOR = 'SET_USER_AUTHENTICATOR';
export const SET_USER_AUTHENTICATOR_LIST = 'SET_USER_AUTHENTICATOR_LIST';
export const SET_USER_CUSTOMER_AGREEMENT = 'SET_USER_CUSTOMER_AGREEMENT';
export const SET_USER_POPUPS = 'SET_USER_POPUPS';
// export const UPDATE_COMPANY = 'UPDATE_COMPANY';

//login
export const CLEAR_LOGIN_ERRORS = 'CLEAR_LOGIN_ERRORS';

// notification
export const SHOW_SUCCESS_NOTIFICATION = 'SHOW_SUCCESS_NOTIFICATION';
export const HIDE_SUCCESS_NOTIFICATION = 'HIDE_SUCCESS_NOTIFICATION';
export const SHOW_ERROR_NOTIFICATION = 'SHOW_ERROR_NOTIFICATION';
export const HIDE_ERROR_NOTIFICATION = 'HIDE_ERROR_NOTIFICATION';
export const SHOW_INFO_NOTIFICATION = 'SHOW_INFO_NOTIFICATION';
export const HIDE_INFO_NOTIFICATION = 'HIDE_INFO_NOTIFICATION';
export const HIDE_ALL_NOTIFICATION = 'HIDE_ALL_NOTIFICATION';

// passwordRecovery
export const RECOVER_PASSWORD_LOADING = 'RECOVER_PASSWORD_LOADING';
export const RECOVER_PASSWORD_SUCCESS = 'RECOVER_PASSWORD_SUCCESS';
export const RECOVER_PASSWORD_FAILURE = 'RECOVER_PASSWORD_FAILURE';
export const RECOVER_PASSWORD_INITIALIZED = 'RECOVER_PASSWORD_INITIALIZED';
export const SET_RECOVER_PASSWORD_DATA = 'SET_RECOVER_PASSWORD_DATA';

// edit profile
export const CLEAR_EDIT_PROFILE_ERRORS = 'CLEAR_EDIT_PROFILE_ERRORS';
export const UPDATE_AUTH_USER_NAME = 'UPDATE_AUTH_USER_NAME';
export const UPDATE_AUTH_USER_EMAIL = 'UPDATE_AUTH_USER_EMAIL';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';

// edit password
export const CLEAR_EDIT_PASSWORD_ERRORS = 'CLEAR_EDIT_PASSWORD_ERRORS';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE';

export const LOGIN_INFO_GENERATE_PASSWORD = 'LOGIN_INFO_GENERATE_PASSWORD';

export const UPDATE_PERSONS = 'UPDATE_PERSONS';
export const NORMALIZE_PERSONS = 'NORMALIZE_PERSONS';
export const NORMALIZE_CURRENT_PERSON = 'NORMALIZE_CURRENT_PERSON';
export const DELETE_PERSON = 'DELETE_PERSON';
export const SET_PERSON_VERIFIED = 'SET_PERSON_VERIFIED';

export const UPDATE_QUESTIONNAIRE = 'UPDATE_QUESTIONNAIRE';
export const UPDATE_PHOTO = 'UPDATE_PHOTO';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

export const SET_ODD_INCOMPLETE = 'SET_ODD_INCOMPLETE';

export const SET_FEATURE_FLAG = 'SET_FEATURE_FLAG';

export const UPDATE_BUSINESS_INFORMATION = 'UPDATE_BUSINESS_INFORMATION';

export const CLEAR_RECOVER_PASSWORD_ERRORS = 'CLEAR_RECOVER_PASSWORD_ERRORS';

export const SET_TWO_FACTOR_AUTHENTICATED = 'SET_TWO_FACTOR_AUTHENTICATED';
export const SET_VERIFIED = 'SET_VERIFIED';
export const SET_AUTH_CURRENT_USER = 'SET_AUTH_CURRENT_USER';
export const SET_CURRENT_WALLET = 'SET_CURRENT_WALLET';
export const UPDATE_WALLET = 'UPDATE_WALLET';

export const UPDATE_LAST_LOG = 'UPDATE_LAST_LOG';

export const UPDATE_WALLET_LIST = 'UPDATE_WALLET_LIST';
export const CLEAR_WALLET_LIST = 'CLEAR_WALLET_LIST';
export const SET_WALLET_LIST_LOADING = 'SET_WALLET_LIST_LOADING';
export const SET_WALLET_NEXT_ID = 'SET_WALLET_NEXT_ID';
export const UPDATE_CREATE_WALLET_ID = 'UPDATE_CREATE_WALLET_ID';
export const UPDATE_CREATE_WALLET_SHOW_FORM = 'UPDATE_CREATE_WALLET_SHOW_FORM';
export const UPDATE_CREATE_WALLET_SHOW_SUCCESS = 'UPDATE_CREATE_WALLET_SHOW_SUCCESS';

export const UPDATE_TABLE = 'UPDATE_TABLE';

//transfer
export const CLEAR_TRANSFER_ERRORS = 'CLEAR_TRANSFER_ERRORS';

//deposit
export const CLEAR_DEPOSIT_ERRORS = 'CLEAR_DEPOSIT_ERRORS';
export const SET_DEPOSIT_ERROR = 'SET_DEPOSIT_ERROR';
export const SET_BENEFICIARY = 'SET_BENEFICIARY';
export const SET_CLIENT = 'SET_CLIENT';

// transactions
export const UPDATE_TRANSACTION_LIST = 'UPDATE_TRANSACTION_LIST';
export const UPDATE_TRANSACTION_TABLE = 'UPDATE_TRANSACTION_TABLE';

// currencies
export const UPDATE_CURRENCIES = 'UPDATE_CURRENCIES';