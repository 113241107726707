<template>
	<div>
		<b-modal :id="modalName"
			centered
			hide-footer
			hide-header
			no-close-on-backdrop
			@hidden="next()"
		>
			<component :is="currentComponent"
				v-bind="currentProperties"
				v-if="currentComponent"
			/>
		</b-modal>
	</div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import popupTypes from './popupTypes';
export default {
	name: 'Popups',
	data() {
		return {
			modalName: 'popup-modal',
			types: popupTypes,
			queue: [],
			currentComponent: null,
			currentProperties: {}
		};
	},
	computed: {
		...mapState('user', [
			'popups'
		])
	},
	watch: {
		popups: {
			handler()  {
				this.initQueue();
			},
			deep: true
		}
	},
	methods: {
		...mapActions('auth', [
			'logout'
		]),
		...mapActions('notifications', [
			'pushError'
		]),
		...mapActions('user', [
			'setOddIncomplete'
		]),
		initQueue() {
			const popups = this.popups.map(p => typeof p == 'string' ? p : p.type);
			popups.forEach(popup => {
				if (this.types[popup]) this.queue.push(popup);
			});
			this.openModals();
		},
		openModals() {
			if (this.queue.length == 0) return;
			const typeProp = this.types[this.queue[0]];
			this.currentProperties = { 
				onSuccess: this.onSuccess,
				onCancel: this.onCancel,
				onFailure: this.onFailure,
				submitUrl: typeProp.url
			};
			this.currentComponent = typeProp.component;
			this.$bvModal.show(this.modalName);
		},
		async onSuccess() {
			if (this.currentComponent) {
				this.$bvModal.hide(this.modalName);
				this.currentComponent = null;
				this.currentProperties = {};
			}
		},
		async onCancel() {
			this.setOddIncomplete(true);
			this.queue = [];
			this.$bvModal.hide(this.modalName);
		},
		next() {
			this.queue.shift();
			this.openModals();
		},
		async onFailure(err) {
			this.pushError(err);
		}
	},
	mounted() {
		this.initQueue();
	}
};
</script>
<style lang="scss">
</style>