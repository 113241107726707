<template>
	<div>
		<component :is="wrapper"
			:style="{'margin-bottom': wrapper !== 'b-form-group' ? mb : ''}"
			:class="{ 'input-invalid': showError }"
			:label="label"
			:label-for="labelFor"
		>
			<slot></slot>
		</component>
		<div
			v-show="showError"
			class="errormsg"
		>
			<span>{{ errorMessage }}</span>
		</div>
	</div>
</template>
<script>

export default {
	name: 'FormErrorField',
	inject: ['$validator'],
	props: {
		name: {
			type: [String, Array],
			default: '',
			required: true
		},
		scope: {
			type: String,
			default: undefined
		},
		wrapper: {
			type: String,
			default: 'b-form-group'
		},
		label: {
			type: String
		},
		labelFor: {
			type: String
		},
		mb: {
			type: String,
			default: '26px'
		}
	},
	computed: {
		showError() {
			if (typeof this.name == 'string') {
				return this.errors.has(this.name, this.scope);
			} else if (typeof this.name == 'object'){
				const valids = [];
				this.name.forEach(name => {
					const valid = this.errors.has(name, this.scope);
					valids.push(valid);
				});			
				return valids.reduce((a, b) => a || b, false);
			}
			return false;
		},
		errorMessage() {
			if (typeof this.name == 'string') {
				return this.errors.first(this.name, this.scope);
			} else if (typeof this.name == 'object'){
				const messages = [];
				this.name.forEach(name => {
					const msg = this.errors.first(name, this.scope);
					messages.push(msg);
				});
				return messages.filter(m => !!m)[0];
			}
			return '';
		}
	}
};
</script>
<style lang="scss">
	.errormsg {
		position: relative;
		animation: fadeIn .5s ease;
		display: flex;
		margin-bottom: 5px;

		span {
			padding: 1px 10px;
			background-color: #ff6161;
			display: inline-block;
			color: white;
			border-radius: 0 0 6px 6px;
			font-size: 12px;
			position: relative;
		}
	}

</style>