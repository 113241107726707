var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', [_c('b-col', [_vm._t("label")], 2)], 1), !_vm.dropdown ? _c('b-row', {
    attrs: {
      "align-v": "stretch"
    }
  }, _vm._l(_vm.options, function (option) {
    var _vm$innerValue;

    return _c('b-col', {
      key: option.value,
      staticClass: "account-info-option",
      attrs: {
        "sm": _vm.fullWidth ? 12 : 6,
        "cols": "12",
        "data-registration": "use-of-account"
      },
      on: {
        "click": function click($event) {
          return _vm.setAccountInfoUse(option.value);
        }
      }
    }, [_c('div', {
      staticClass: "account-info-option__box",
      "class": {
        'account-info-option__box--active': (_vm$innerValue = _vm.innerValue) === null || _vm$innerValue === void 0 ? void 0 : _vm$innerValue.values.includes(option.value)
      }
    }, [_c('div', {
      staticClass: "account-info-option__text"
    }, [_c('h3', [_c('span', {
      staticClass: "radio",
      "class": {
        'radio--check': _vm.multiple
      }
    }, [_c('i', {
      staticClass: "fas fa-check"
    })]), _vm._v(_vm._s(option.name) + " ")]), _c('p', {
      domProps: {
        "innerHTML": _vm._s(option.description)
      }
    })])])]);
  }), 1) : _vm._e(), _vm.dropdown ? _c('b-row', [_c('b-col', [_c('form-error-field', {
    attrs: {
      "name": "accountInfoUse.values"
    }
  }, [_c('v-multiselect', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "options": _vm.options,
      "multiple": true,
      "allow-empty": true,
      "close-on-select": false,
      "clear-on-select": true,
      "data-vv-as": "Use of account",
      "name": "accountInfoUse.values",
      "label": "name",
      "placeholder": "Select Your use of account",
      "track-by": "name",
      "return": "value",
      "max-height": 9999
    },
    model: {
      value: _vm.innerValue.values,
      callback: function callback($$v) {
        _vm.$set(_vm.innerValue, "values", $$v);
      },
      expression: "innerValue.values"
    }
  })], 1)], 1)], 1) : _vm._e(), _c('b-row', [_vm.innerValue.values.includes('other') ? _c('b-col', [_c('form-error-field', {
    attrs: {
      "name": "accountInfoUse.otherValue"
    }
  }, [_vm._t("other-label"), _c('b-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "type": "text",
      "placeholder": "Specify other use of account",
      "data-vv-as": "Other use of account",
      "name": "accountInfoUse.otherValue"
    },
    model: {
      value: _vm.innerValue.otherValue,
      callback: function callback($$v) {
        _vm.$set(_vm.innerValue, "otherValue", $$v);
      },
      expression: "innerValue.otherValue"
    }
  })], 2)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }