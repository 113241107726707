<template>
	<div>
		<notifications-wrapper />
		<router-view />
		<cookies-notification />
		<info-message-popoup />
		<div
			v-if="DEV"
			class="web-status"
		>
			<div>{{ env }}</div>
			<pre>{{ api }}</pre>
		</div>
	</div>
</template>
<script>
import NotificationsWrapper from '@/Notifications/NotificationsWrapper.vue';
import CookiesNotification from '@/shared/CookiesNotification.vue';
import config from '~/config';
import InfoMessagePopoup from '@/shared/InfoMessagePopoup.vue';

export default {
	name: 'App',
	components: {
		NotificationsWrapper,
		CookiesNotification,
		InfoMessagePopoup
	},
	data() {
		return {
			DEV,
			env: process.env.NODE_ENV,
			api: config.api
		};
	}
};
</script>

<style lang="scss">
	@import '../assets/sass/app.scss';
	.web-status {
		position: fixed;
		bottom: 0;
		left: 0;
		font-weight: bold;
		pointer-events: none;
		opacity: 0.2;
		padding: 10px;
		text-transform: uppercase;

		pre {
			text-transform: none;
			font-size: 10px;
			margin-bottom: 0;
		}
	}
</style>