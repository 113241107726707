export default [
	{
		name: 'productId',
		type: 'text',
		label: 'Product ID',
		placeholder: 'Select product',
		required: true,
	},
	{
		name: 'countryId',
		type: 'countrySelector',
		label: 'Country',
		placeholder: 'Select bank country',
		required: true,
		hidden: true,
	},
	{
		name: 'amount',
		type: 'amount',
		label: 'Amount',
		validation: 'min_value:0.01',
		placeholder: '0.00',
		required: true,
		currencyKey: 'currencyId',
		currencyName: 'currency',
		currencyPlaceholder: 'Select currency',
	},
	{
		name: 'details',
		type: 'textarea',
		label: 'Payment details',
		required: true,
		maxLength: 120
	}
];