<template>
	<div>
		<b-card>
			<h3 class="mb-3">
				Incoming transaction complete
			</h3>
			<hr>
			<div v-if="personList.length < 1">
				There are no users to select from.
			</div>
			<b-input-group
				v-for="person in personList"
				:key="person.id"
				class="radioblock text-left mb-3"
			>
				<b-form-checkbox
					v-model="data.incomingTransactionComplete"
					name="transaction_complete"
					:value="person.id"
				>
					{{ `${person.name} ${person.surname} (${person.email})` }}
				</b-form-checkbox>
			</b-input-group>
			<hr>
			<b-row align-v="center">
				<b-col>
					<b-button
						class="w-100"
						:disabled="personList.length == 0"
						variant="primary"
						@click="saveIncomingTransactionComplete"
					>
						<span v-if="!loading">Save</span>
						<b-spinner
							v-if="loading"
							small
						/>
					</b-button>
				</b-col>
			</b-row>
		</b-card>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { endpoints } from '~/config';
export default {
	name: 'AccountEmailSettings',
	data() {
		return {
			loading: false,
			data: {
				incomingTransactionComplete: []
			}
		};
	},
	computed: {
		...mapGetters('user', [
			'persons',
			'isPersonal'
		]),
		personList() {
			if (this.isPersonal) {
				return this.persons.filter(p => p.type === 'owner' && !!p.email);
			}
			return this.persons.filter(p => p.type === 'representative' && !!p.email);
		}
	},
	methods: {
		...mapActions('notifications', [
			'pushError',
			'pushSuccess'
		]),
		async saveIncomingTransactionComplete() {
			this.loading = true;
			const options = {
				method: 'PUT',
				url: endpoints.email.incomingTransactionComplete,
				data: { profileRelationIds: this.data.incomingTransactionComplete }
			};
			try {
				await axios(options);
				this.pushSuccess('Saved successfully');
			} catch (err) {
				this.pushError(err.message);
			}
			this.loading = false;
		}
	},
	mounted() {
		const incomingTransactionSelected = this.persons.filter(p => {
			if (this.isPersonal) {
				return p.isReceiveCompletedIncomingTransactionEmail && p.type === 'owner';
			}
			return p.isReceiveCompletedIncomingTransactionEmail && p.type === 'representative';
		}).map(p => p.id);
		this.$set(this.data, 'incomingTransactionComplete', incomingTransactionSelected);
	}
};
</script>
<style lang="scss">
	
</style>