<template>
	<div>
		<iframe
			v-if="iframeLoaded && isDesktop"
			id="iframe"
			allow="camera;microphone"
			:src="iframeUrl"
			style="width:100%; height:800px; border: none;"
		></iframe>
		<div
			v-if="iframeLoaded && !isDesktop"
			class="text-center"
		>
			<div class="verify-photo__notification">
				<ion-icon name="camera-outline" />
				<span>Press on a button to finish your verification.</span>
			</div>
			<div>
				<b-button
					variant="primary"
					:href="iframeUrl"
					target="_blank"
					class="mt-4 mb-4"
				>
					Verify documents and photo
				</b-button>
			</div>
		</div>

		<div
			v-if="error"
			class="alert alert-danger"
		>
			<span>Link not found</span>
		</div>
		<div
			v-if="success"
			class="alert alert-success"
		>
			<span>Identification successful</span>
		</div>
	</div>
</template>


<script>
import { mapActions } from 'vuex';
export default {
	name: 'IdenfyUnauthenticated',
	props: {
		hash: {
			type: String,
			default: ''
		}
	},
	data: function () {
		return {
			iframeLoaded: false,
			iframeUrl: null,
			error: false,
			success: false,
			isDesktop: false
		};
	},
	mounted() {
		this.publicGetIframeUrl(this.hash).then(response => {
			if (response && response.url) {
				this.iframeUrl = response.url;
				this.iframeLoaded = true;
				this.checkUserAgent();
			}
		}).catch(error => {
			this.error = true;
			throw new Error(error);
		});

		this.checkStatus();
	},
	methods: {
		...mapActions('identity', [
			'publicGetIframeUrl', 
			'publicCheckStatus'
		]),
		...mapActions('notifications', [
			'pushError'
		]),
		checkUserAgent() {
			const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
			this.isDesktop = !isMobile;
		},
		checkStatus() {
			setInterval(async () => {
				if (this.iframeLoaded) {
					try {
						await this.publicCheckStatus(this.hash);
					} catch (error) {
						this.success = true;
						this.iframeLoaded = false;
						this.pushError(error);
					}
				}
			}, 10000);
		},
	}, };
</script>

<style lang="scss">
	.verify-photo {
		&__notification {
			background-color: #e3f3f4;
			padding: 16px 20px;
			display: inline-flex;
			align-items: center;
			margin-top: 20px;
			border-radius: 3px;
			justify-content: space-between;
			color: $primary;

			ion-icon {
				padding-right: 15px;
			}
		}
	}
</style>