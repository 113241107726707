<template>
	<!--TABLE-->
	<div>
		<!--Filter-->
		<div class="search-block">
			<b-row>
				<!-- <div class="d-flex">
                    <div class="filter-block d-flex">
                        <p>Date from </p>
                        <app-datepicker v-model="filterDateFrom"/>
                    </div>
                    <div class="filter-block d-flex">
                        <p>Date to </p>
                        <app-datepicker v-model="filterDateTo"/>
                    </div>

                    <button @click="getLogList">Filter</button>
                </div> -->
				<b-col 
					md="8" 
					class="mb-3 mb-md-0"
				>
					<date-range-select
						:date-range="dateRange"
						side="right"
						@dateRangeChanged="getLogList"
					/>
				</b-col>
				<b-col md="4">
					<div class="d-flex align-items-center">
						<p class="mr-3 mb-0 text-muted align-self-center text-nowrap">
							Items per page
						</p>
						<CustomDropdown 
							:items="pages"
							@select="filterPerPage=$event"
						/>
					</div>
				</b-col>
				<!--<b-col cols="6" md="4" lg="3" xl="2">
                <button
                    class="btn btn-primary btn-block"
                    @click="getLogList"
                >
                    Filter
                </button>
                </b-col>-->
			</b-row>
		</div>
		<!--Content-->
		<div
			v-if="total"
			class="table-content activity-table"
		>
			<!--Table body-->
			<activity-table-item
				v-for="(item, index) in list"
				:key="index"
				:table-item="item"
			/>
			<!--Table footer-->

			<pagination
				:total="total"
				:per-page="filterPerPage"
				:current-page="currentPage"
				@pagechanged="onPageChange"
			/>
		</div>


		<div
			v-else-if="!listLoaded"
			class="empty-list"
		>
			<preloader />
		</div>

		<div
			v-else
			class="empty-list"
		>
			No activity
		</div>
	</div>
</template>

<script>
import ActivityTableItem from './ActivityTableItem.vue';
import CustomDropdown from '@/utils/CustomSelect';
import Pagination from '@/utils/Pagination';
import Preloader from '@/utils/Preloader';
import axios from 'axios';
import moment from 'moment';
import DateRangeSelect from '@/utils/DateRangeSelect';
import { endpoints } from '~/config';
import { mapActions } from 'vuex';

export default {
	name: 'ActivityTable',
	components: {
		ActivityTableItem,
		CustomDropdown,
		Pagination,
		Preloader,
		DateRangeSelect
	},
	data() {
		return {
			pages: {
				10: '10',
				20: '20',
				50: '50',
			},
			total: 0,
			list: [],
			listLoaded: false,
			filterPerPage: '10',
			currentPage: 1,
			windowWidth: window.innerWidth,
			dateRange: {
				startDate: '',
				endDate: ''
			},
			defaultDateFormat: 'YYYY-MM-DD HH:mm:ss'
		};
	},
	mounted() {
		this.getLogList();
	},
	methods: {
		...mapActions('notifications', ['pushError']),
		onPageChange(page) {
			this.currentPage = page;
			this.getLogList();
		},
		async getLogList(dates) {
		
			if (dates?.startDate) {
				this.dateRange.dateCreatedFrom = moment(dates.startDate).hour(0).format(this.defaultDateFormat);
				this.dateRange.dateCreatedTo = moment(dates.startDate).add(1, 'day').hour(0).format(this.defaultDateFormat);
				if (dates.endDate && JSON.stringify(dates.startDate) !== JSON.stringify(dates.endDate)) {
					this.dateRange.dateCreatedTo = moment(dates.endDate).hour(23).minute(59).format(this.defaultDateFormat);
				}
			}

			const options = {
				method: 'GET',
				url: endpoints.log.userLog,
				params: {
					orderDirection: 'desc',
					limit : this.filterPerPage,
					orderBy : 'timestampCreated',
					page : this.currentPage,
					...this.dateRange
				}
			};

			try {
				const { data } = await axios(options);
				this.list = data.rows;
				this.total = data.count;
				this.listLoaded = true;
			} catch (err) {
				this.pushError(err);
			}
		},
		pickerDateConvert(e) {
			const monthNames = [
				'Jan',
				'Feb',
				'Mar',
				'Apr',
				'May',
				'Jun',
				'Jul',
				'Aug',
				'Sep',
				'Oct',
				'Nov',
				'Dec'
			];
			let newDate = `${monthNames[e.getMonth()]} ${e.getDate()}`;
			return newDate;
		}
	}
};
</script>
