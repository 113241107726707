<template>
	<div class="card-jwk-content">
		<h2>Card Details</h2>
		<img
			src="/images/icons/card-details.svg"
			alt="Card Details"
		>
		<div class="mt-4 text-left">
			<b-row>
				<b-col class="card-jwk-content__info-bar">
					<small>Card number</small>
					<div>{{ cardNumber }}</div>
				</b-col>
			</b-row>
			<b-row>
				<b-col class="card-jwk-content__info-bar">
					<small>Valid until</small>
					<div>{{ cardExpireDate }}</div>
				</b-col>
			</b-row>
			<b-row>
				<b-col class="card-jwk-content__info-bar">
					<small>CVV</small>
					<div v-if="!hideCvv">
						{{ content.cardCvv }}
					</div>
					<div v-if="hideCvv">
						•••
					</div>
				</b-col>
			</b-row>
			<b-row>
				<b-col class="card-jwk-content__info-bar">
					<small>Card holder</small>
					<div>{{ content.embossedName }}</div>
				</b-col>
			</b-row>
			<b-row>
				<b-col class="card-jwk-content__info-bar">
					<small>IBAN account</small>
					<!--					<div v-if="content.embossedName">-->
					<!--						{{ content.embossedName }}-->
					<!--					</div>-->
					<div>{{ content.accountNumber }}</div>
				</b-col>
			</b-row>
		</div>
		<div
			v-if="!hideCvv"
			class="card-jwk-content__notification"
		>
			<span>CVV will disappear in {{ counter - timePassed }} seconds</span>
		</div>
	</div>
</template>
<script>
import moment from 'moment';
export default {
	name: 'CardDetailsView',
	props: {
		content: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			startTime: new Date(),
			timePassed: 0,
			counter: 20,
			hideCvv: false,
		};
	},
	computed: {
		cardNumber() {
			if (!this.content.cardPan) {
				return '';
			}
			const arr = this.content.cardPan.match(/.{1,4}/g);
			return arr.join(' ');
		},
		cardExpireDate() {
			if (!this.content.cardExpiry) {
				return '';
			}
			const arr = this.content.cardExpiry.match(/.{1,2}/g);
			return arr.join('/') ?? '';
		}
	},
	mounted() {
		this.startCount();
	},
	methods: {
		startCount() {
			const start = moment(this.startTime);
			
			const count = () => {
				setTimeout(() => {
					const difference = moment(new Date()).diff(start, 'seconds');
					this.timePassed = difference;
					if (difference >= this.counter) {
						this.hideCvv = true;
					} else {
						count();
					}
				}, 1000);
			};
			count();
		}
	}
};
</script>
<style lang="scss">
	
</style>