<template>
	<date-range-picker
		:date-range="dateRange"
		:opens="windowWidth < 755 ? 'center' : side"
		@update="updateRangePicker"
	>
		<template
			#input="picker"
		>
			<div style="min-width: 350px;">
				<img 
					src="/images/clear.svg" 
					@click="clearRangePicker"
				/>
				{{
					picker.startDate
						? `${pickerDateConvert(
							picker.startDate
						)} - ${pickerDateConvert(picker.endDate)}`
						: "Dates"
				}}
			</div>
		</template>
	</date-range-picker>
</template>
<script>
import DateRangePicker from 'vue2-daterange-picker';

export default {
	name: 'DropdownTemplate',
	components: {
		DateRangePicker
	},
	props: {
		dateRange: {
			type: Object,
			default: () => ({ startDate: '', endDate: '' })
		},
		side: {
			type: String,
			default: 'left'
		}
	},
	data() {
		return {
			windowWidth: window.innerWidth
		};
	},
	methods: {
		trigger() {
			this.showBody = !this.showBody;
		},
		pickerDateConvert(e) {
			const monthNames = [
				'Jan',
				'Feb',
				'Mar',
				'Apr',
				'May',
				'Jun',
				'Jul',
				'Aug',
				'Sep',
				'Oct',
				'Nov',
				'Dec'
			];
			let newDate = `${monthNames[e.getMonth()]} ${e.getDate()}`;
			return newDate;
		},
		updateRangePicker(e) {
			this.$emit('dateRangeChanged', { startDate: e.startDate, endDate: e.endDate });
		},
		clearRangePicker() {
			this.$emit('dateRangeChanged', { startDate: null, endDate: null });
		}
	}
};
</script>
<style src="vue2-daterange-picker/dist/vue2-daterange-picker.css"></style>