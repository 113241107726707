<template>
	<div>
		<div
			v-if="!listLoaded"
			class="d-flex justify-content-center align-items-center py-4"
		>
			<b-spinner small />
		</div>
		<div v-if="listLoaded && ordersListLoaded">
			<router-view />
		</div>
	</div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';

export default {
	name: 'CardsTemplate',
	computed: {
		...mapState('user', {
			user: state => state.selected
		}),
		...mapState('cards', [
			'list', 'listLoaded', 'countries', 'countriesLoaded', 'ordersListLoaded', 'ordersList'
		]),
		...mapGetters('user', [
			'isGranted',
			'userAddress',
		]),
		profileCountryAllowed() {
			if (!this.countries.length || !this.user.userProfile) return false;
			return this.countries.map(c => c.countryId).includes(this.userAddress.countryId);
		},

	},
	watch: {
		$route(to) {
			if (to.name == 'cards') {
				this.redirectCardsPage();
			}
			if (['new-virtual-card', 'new-plastic-card'].includes(to.name)) {
				this.verifyNewCardAllowedCountry();
			}
		},
		listLoaded(loaded) {
			if (loaded && this.$route.name == 'cards') {
				this.redirectCardsPage();
			}
		},
		countriesLoaded(loaded) {
			if (loaded && ['new-virtual-card', 'new-plastic-card'].includes(this.$route.name)) {
				this.verifyNewCardAllowedCountry();
			}
		}
	},
	async mounted() {
		this.redirectCardsPage();
	},
	methods: {
		redirectCardsPage() {
			if (!this.isGranted('front_card_list')) return this.$router.push( { path: '/home' } );
			if (this.ordersListLoaded && this.listLoaded) {
				if (this.ordersList.length == 0) return this.$router.push({ name: 'new-card' });
				if (this.list.length == 1 && this.ordersList == 0) return this.$router.push({ path: `/cards/view/${this.list[0].id}` });
				if (this.ordersList.length >= 1) return this.$router.push({ path: '/cards/list' });
			}
		},
		verifyNewCardAllowedCountry() {
			if (
				this.countriesLoaded &&
				!this.profileCountryAllowed
			) {
				this.$router.push( { name: 'new-card' });
			}
		}
	}
};
</script>
<style lang="scss">
	
</style>