import { endpoints } from '~/config';
import { SET_CARDS_LIST, SET_CARDS_ORDERS_LIST, SET_CARDS_COUNTRIES, RESET_CARDS } from '~/store/mutation-types';

export default {
	setCardsList,
	setCardsCountries,
	initCardData,
	reset
};

async function setCardsList({ state, commit, rootGetters }, force) {
	const isGranted = rootGetters['user/isGranted'];
	if (!isGranted('front_get_card')) {
		return Promise.resolve('Not allowed');
	}
	if (state.list.length && !force) return;
	const options = {
		method: 'GET',
		url: endpoints.cards.cardsList
	};
	const ordersOptions = {
		method: 'GET',
		url: endpoints.cards.cardsOrdersList
	};
	try {
		(async () => {
			const { data } = await axios(options);
			commit(SET_CARDS_LIST, data.length > 0 ? data : []);	
		})();
		
		(async () => {
			const { data } = await axios(ordersOptions);
			commit(SET_CARDS_ORDERS_LIST, data.length > 0 ? data : []);	
		})();
		
		return Promise.resolve();
	} catch (err) {
		return Promise.reject(err.message);
	}
}

async function setCardsCountries({ commit, rootGetters }) {
	const isGranted = rootGetters['user/isGranted'];
	if (!isGranted('front_get_country_list_card')) {
		return Promise.resolve('Not allowed');
	}
	const options = {
		method: 'GET',
		url: endpoints.cards.countryList
	};
	try {
		const { data } = await axios(options);
		if (data.length > 0) commit(SET_CARDS_COUNTRIES, data);
		return Promise.resolve(data);
	} catch (err) {
		return Promise.reject(err.message);
	}
}

async function initCardData({ dispatch }, force) {
	dispatch('setCardsList', force);
	dispatch('setCardsCountries');
}

function reset({ commit }) {
	commit(RESET_CARDS);
}