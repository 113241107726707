var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-form-group', {
    attrs: {
      "id": _vm.id,
      "label": _vm.label,
      "label-for": "".concat(_vm.id, "-input")
    }
  }, [_c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: _vm.vValidate,
      expression: "vValidate"
    }],
    "class": {
      'input-invalid': _vm.errors.has(_vm.name)
    },
    attrs: {
      "id": "".concat(_vm.id, "-input"),
      "name": _vm.name,
      "type": _vm.type,
      "placeholder": _vm.placeholder || _vm.label,
      "state": !_vm.errors.has(_vm.name) ? null : false
    },
    model: {
      value: _vm.inputValue,
      callback: function callback($$v) {
        _vm.inputValue = $$v;
      },
      expression: "inputValue"
    }
  }), _c('b-form-invalid-feedback', {
    attrs: {
      "id": "".concat(_vm.id, "-input-feedback")
    }
  }, [_vm._v(" " + _vm._s(_vm.errors.first(_vm.name)) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }