import Login from '@/LayoutAuthentication/Login/Login.vue';
import Confirm from '@/LayoutAuthentication/Login/Confirm.vue';
import UserSelection from '@/LayoutAuthentication/Login/UserSelection.vue';
import RecoverPassword from '@/LayoutAuthentication/RecoverPassword/RecoverPassword.vue';
import RecoverPasswordChange from '@/LayoutAuthentication/RecoverPassword/RecoverPasswordChange.vue';
import RecoverPasswordComplete from '@/LayoutAuthentication/RecoverPassword/RecoverPasswordFinish.vue';

export default [
	{
		path: 'login',
		name: 'login',
		component: Login,
	},
	{
		path: 'confirm',
		name: 'confirm',
		component: Confirm
	},			
	{
		path: 'select-user',
		name: 'select-user',
		component: UserSelection
	},
	{
		path: 'expired',
		name: 'expired',
		component: Confirm
	},
	{
		path: 'recover-password',
		name: 'recover-password',
		component: RecoverPassword,
		meta: {
			requiresGuest: true
		}
	},
	{
		path: 'recover/:hash',
		name: 'recover',
		component: RecoverPasswordChange,
		props: true,
		meta: {
			requiresGuest: true
		}
	},
	{
		path: 'recover-password-finished',
		name: 'recover-password-finished',
		component: RecoverPasswordComplete,
		props: true,
		meta: {
			requiresGuest: true
		}
	}
];