<template>
	<form 
		action="#" 
		class=" form-body" 
	>
		<template v-if="!showAuthenticator">
			<notification />
			<form-error-field name="email">
				<label 
					for="email"
				>Email Address</label>
				<b-form-input 
					id="email"
					ref="email"
					v-model="data.email"
					v-validate="'required|email'"
					type="text"
					name="email"
					placeholder="Email Address"
				/>
			</form-error-field>
			<phone-input 
				v-model="data.phone" 
			/>
			
			<b-row class="justify-content-center pt-4">
				<b-col md="8">
					<input 
						type="submit" 
						class="btn btn-primary btn-block" 
						value="Reset password"
						@click.prevent="submit()"
					/>
				</b-col>
			</b-row>
		</template>

		<div v-else-if="showAuthenticator">
			<authenticator
				:data="data"
				:show-back-button="true"
				:on-back="handleBack"
				:on-error="handleError"
				:on-resend="handleResend"
				type="recover_password"
				:action="'passwordRecovery/recoverPasswordConfirm'"
			/>
		</div>

		<div class="pt-4 text-center">
			Go back to
			<router-link
				:to="{ name: 'login' }"
				exact
			>
				Login
			</router-link>
		</div>
		<vue-recaptcha
			ref="recaptcha"
			:sitekey="recapthaSitekey"
			:load-recaptcha-script="true"
			size="invisible"
			@verify="onVerify"
		/>
	</form>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Authenticator from '@/shared/Authenticator';
import { VueRecaptcha } from 'vue-recaptcha';
import config from '~/config';
import FormErrorField from '@/shared/Form/FormErrorField';

export default {
	name: 'RecoverPasswordForm',

	components: { 
		Authenticator,
		VueRecaptcha,
		FormErrorField
	},

	data() {
		return {
			showAuthenticator: false,
			data: {},
			recapthaSitekey: config.recaptchaSitekey
		};
	},
	computed: {
		...mapState('passwordRecovery', ['email']),
		...mapState({
			errors: state => state.email.errors
		}),
	},
	created() {
		this.attachFormFieldValidation();
	},
	mounted() {
		this.$nextTick(() => this.$refs.email.focus());
	},
	methods: {
		...mapActions('passwordRecovery', ['recoverPassword', 'recoverPasswordResend']),
		...mapActions('notifications', ['pushError']),
		...mapActions('user', ['setAuthenticatorList']),
		async submit() {
			const validatedEmail = await this.$validator.validate('email', this.data.email);
			const validatedPhone = await this.$validator.validate('phone', this.data.phone);
			if (validatedEmail && validatedPhone) {
				this.nextEnabled = false;
				this.$refs.recaptcha.execute();
			}
		},
		async onVerify(recaptchaResponse) {
			const payload = {
				email: this.data.email,
				phone: this.data.phone,
				recaptchaResponse
			};
			try {
				const { data } = await this.recoverPassword(payload);
				this.setAuthenticatorList(data.availableAuthenticatorList);
				this.showAuthenticator = true;
			} catch (err) {
				this.$refs.recaptcha.reset();
				this.pushError(err);
			}
		},
		handleError(data) {
			this.pushError(data.error);
		},
		handleBack() {
			this.showAuthenticator = false;
		},
		onExpired: function () {
			this.$refs.recaptcha.reset();
		},
		async handleResend(provider) {
			try {
				await this.recoverPasswordResend({
					email: this.data.email,
					phone: this.data.phone,
					provider
				});
			} catch (err) {
				this.pushError(err);
			}
			return;
		},
		clearForm() {
			this.data.email = null;
			this.data.phone = null;
		},
		attachFormFieldValidation() {
			this.$validator.attach({ name: 'email', rules: 'email|required' });
			this.$validator.attach({ name: 'phone', rules: 'required' });
		},
	},
	provide() {
		return {
			$validator: this.$validator
		};
	}
};
</script>
