import { UPDATE_BUSINESS_INFORMATION } from '~/store/mutation-types';

export default {
	[UPDATE_BUSINESS_INFORMATION] (state, payload) {
		for (let key in payload.data) {
			if (Object.prototype.hasOwnProperty.call(payload.data, key)) {
				if (state[key] !== payload.data[key]) {
					if (key === 'account_info_use' && typeof payload.data['account_info_use'] === 'string') {
						state[key] = payload.data['account_info_use'].split(',');
					} else if (key === 'account_info_scope' && typeof payload.data['account_info_scope'] === 'string') {
						state[key] = payload.data['account_info_scope'].split(',');
					} else if (key === 'vat_country_id') {
						state['country_of_incorporation'] = payload.data[key];
					} else if (key === 'registration_date' && payload.data[key]) {
						state['date_of_incorporation'] = payload.data[key].slice(0, 10);
					} else {
						state[key] = payload.data[key];
					}
				}
			}
		}
	}
};