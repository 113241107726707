<template>
	<b-modal
		v-cloak
		:id="id"
		size="sm"
		centered
		hide-header
		hide-footer
		no-close-on-backdrop
		@hidden="reset"
		@shown="focusForm"
	>
		<div class="text-center card-jwk-modal card-modal">
			<span
				class="card-settings__modal-close"
				@click="closeModal"
			></span>
			<card-set-pin-form
				v-if="type === 'setPin'"
				ref="form"
				v-model="formData"
			/>
			<card-activate-form
				v-if="type == 'activate'"
				ref="form"
				v-model="formData"
			/>
			<card-3d-password-form
				v-if="type === 'set3d'"
				ref="form"
				v-model="formData"
			/>
			<card-details-view
				v-if="type === 'details'"
				:content="content"
			/>
			<card-pin-view
				v-if="type === 'pin'"
				:content="content"
			/>
			<b-button
				v-if="onSubmit"
				class="mt-4"
				variant="primary"
				:disabled="!formData"
				@click="submit"
			>
				Submit
			</b-button>
		</div>
	</b-modal>
</template>
<script>
import CardSetPinForm from './CardSetPinForm';
import CardActivateForm from './CardActivateForm';
import Card3dPasswordForm from './Card3dPasswordForm';
import CardDetailsView from './CardDetailsView';
import CardPinView from './CardPinView';
import { mapActions } from 'vuex';

export default {
	name: 'CardJwtModal',
	components: {
		CardSetPinForm,
		CardActivateForm,
		Card3dPasswordForm,
		CardDetailsView,
		CardPinView
	},
	props: {
		onClose: {
			type: Function
		},
		cardId: {
			type: String
		}
	},
	data() {
		return {
			id: 'card-jwt-modal',
			data: {
				key: null,
				token: null
			},
			content: {},
			type: null,
			onSubmit: null,
			formData: null,
		};
	},
	watch: {
		data: {
			handler: function(value) {
				this.content = value;
			},
			deep: true
		}
	},
	methods: {
		...mapActions('notifications', ['pushError']),
		closeModal() {
			this.$bvModal.hide(this.id);
		},
		reset() {
			if (this.onClose) this.onClose();
			this.data = {};
			this.content = {};
			this.type = null;
			this.onSubmit = null;
			this.formData = null;
		},
		async submit() {
			const valid = await this.$refs['form'].$validator.validate();
			if (valid) {
				if (this.onSubmit) {
					try {
						await this.onSubmit(this.formData);
						this.closeModal();
					} catch (err) {
						this.resetForm(true);
						this.pushError('Something went wrong, please try again.');
					}
				}
			}
		},
		focusForm() {
			this.$refs.form && this.$refs.form.focus && this.$refs.form.focus();
		},
		resetForm(focus) {
			this.$refs.form && this.$refs.form.reset && this.$refs.form.reset();
			focus && this.focusForm();
		}
	}
};
</script>
<style lang="scss">
</style>