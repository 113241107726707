var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', [_c('b-col', [_c('span', {
    staticClass: "card-order__subtitle"
  }, [_vm._v("Personal Details")])])], 1), _c('b-row', [_c('b-col', {
    staticClass: "card-order__account-info"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_vm._v(" Legal Name: ")]), _c('b-col', {
    attrs: {
      "md": "9"
    }
  }, [_vm._v(" " + _vm._s(_vm.owner.name) + " " + _vm._s(_vm.owner.surname) + " ")])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_vm._v(" Email: ")]), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_vm._v(" " + _vm._s(_vm.owner.email) + " ")])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_vm._v(" Phone number: ")]), _c('b-col', {
    attrs: {
      "md": "9"
    }
  }, [_vm._v(" " + _vm._s(_vm.owner.phone) + " ")])], 1), _c('div', [_c('b-row', [_c('b-col', {
    staticClass: "card-order__details-note"
  }, [_vm._v(" You will receive your authentication codes to this phone number. ")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }