var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": _vm.id,
      "size": "sm",
      "centered": "",
      "hide-header": "",
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "shown": function shown($event) {
        return _vm.$refs.codeInput.focus();
      }
    }
  }, [_c('div', {
    staticClass: "card-modal text-center"
  }, [_c('span', {
    staticClass: "card-settings__modal-close",
    on: {
      "click": _vm.closeModal
    }
  }), _c('h2', [_vm._v(_vm._s(_vm.title))]), _vm._v(" Enter code from " + _vm._s(_vm.authType[_vm.authenticator || _vm.userAuthenticator]) + " "), _c('code-input', {
    ref: "codeInput",
    attrs: {
      "disabled": _vm.loading
    },
    model: {
      value: _vm.code,
      callback: function callback($$v) {
        _vm.code = $$v;
      },
      expression: "code"
    }
  }), _vm.refreshFn ? _c('div', [_vm._v(" Code did not work? "), _c('a', {
    attrs: {
      "href": ""
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.refreshCode.apply(null, arguments);
      }
    }
  }, [_vm._v("Refresh")])]) : _vm._e()], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }