<template>
	<div class="alert alert-info mb-5">
		No transactions
	</div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
	name: 'NoTransactions',
	data() {
		return {
			limit: 10,
			total: 0,
			list: [],
			listLoaded: false,
			searchString: '',
			showSearch: true,
			dateRange: {
				startDate: null,
				endDate: null
			}
		};
	},
	computed: {
		...mapGetters('user', ['isVerified'])
	}
};
</script>
