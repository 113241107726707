export default { 
	status: {
		login: {
			loading: false,
			success: false,
			failed: false
		},
		confirm: {
			loading: false,
			success: false,
			failed: false,
			created: false
		}
	},
	token: null
};