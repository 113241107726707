var render = function () {
  var _vm$transactionTempla;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.transferMoney.apply(null, arguments);
      }
    }
  }, [_c('fieldset', {
    attrs: {
      "disabled": _vm.currentlyLoading === true
    }
  }, [_vm.isNotVerified ? _c('div', [_c('b-row', {
    staticClass: "mb-3"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-alert', {
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_vm._v(" External transactions can only be done by verified users "), _c('router-link', {
    staticClass: "ml-3 alert-link",
    attrs: {
      "to": _vm.verificationLink
    }
  }, [_vm._v(" Verify now ")])], 1)], 1)], 1)], 1) : _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.currentAction === 'step1',
      expression: "currentAction ==='step1'"
    }]
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "xl": "7",
      "cols": "12"
    }
  }, [_c('b-card', [_c('b-row', {
    staticClass: "form-group"
  }, [_c('b-col', [_c('label', [_vm._v("Type")]), _c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "radio mr-4"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.data.transferType,
      expression: "data.transferType"
    }],
    attrs: {
      "id": "radio-2",
      "name": "transferType",
      "type": "radio",
      "value": "company"
    },
    domProps: {
      "checked": _vm._q(_vm.data.transferType, "company")
    },
    on: {
      "change": function change($event) {
        return _vm.$set(_vm.data, "transferType", "company");
      }
    }
  }), _c('label', {
    staticClass: "radio-label",
    attrs: {
      "for": "radio-2"
    }
  }, [_vm._v("To business")])]), _c('div', {
    staticClass: "radio"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.data.transferType,
      expression: "data.transferType"
    }],
    attrs: {
      "id": "radio-1",
      "name": "transferType",
      "type": "radio",
      "value": "individual"
    },
    domProps: {
      "checked": _vm._q(_vm.data.transferType, "individual")
    },
    on: {
      "change": function change($event) {
        return _vm.$set(_vm.data, "transferType", "individual");
      }
    }
  }), _c('label', {
    staticClass: "radio-label",
    attrs: {
      "for": "radio-1"
    }
  }, [_vm._v("To person")])])])])], 1), _vm.data.transferType === 'individual' ? _c('b-row', {
    staticClass: "form-group"
  }, [_c('b-col', [_c('label', [_vm._v("First and middle names "), _c('span', {
    staticClass: "label-red"
  }, [_vm._v("*")])]), _c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|min:3',
      expression: "'required|min:3'"
    }],
    key: "first-name",
    "class": {
      'is-invalid': _vm.errors.has('firstName')
    },
    attrs: {
      "type": "text",
      "name": "firstName",
      "placeholder": "First and middle names",
      "data-vv-validate-on": "change"
    },
    model: {
      value: _vm.data.name,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "name", $$v);
      },
      expression: "data.name"
    }
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.errors.has('firstName'),
      expression: "errors.has('firstName')"
    }],
    staticClass: "invalid-feedback"
  }, [_vm._v(" " + _vm._s(_vm.errors.first('firstName')) + " ")])], 1)], 1) : _vm._e(), _vm.data.transferType === 'individual' ? _c('b-row', {
    staticClass: "form-group"
  }, [_c('b-col', [_c('label', [_vm._v("Last name "), _c('span', {
    staticClass: "label-red"
  }, [_vm._v("*")])]), _c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|min:3',
      expression: "'required|min:3'"
    }],
    key: "last-name",
    "class": {
      'is-invalid': _vm.errors.has('lastName')
    },
    attrs: {
      "type": "text",
      "name": "lastName",
      "placeholder": "Last name",
      "data-vv-validate-on": "change"
    },
    model: {
      value: _vm.data.surname,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "surname", $$v);
      },
      expression: "data.surname"
    }
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.errors.has('lastName'),
      expression: "errors.has('lastName')"
    }],
    staticClass: "invalid-feedback"
  }, [_vm._v(" " + _vm._s(_vm.errors.first('lastName')) + " ")])], 1)], 1) : _vm._e(), _vm.data.transferType === 'company' ? _c('b-row', {
    staticClass: "form-group"
  }, [_c('b-col', [_c('label', [_vm._v("Beneficiary "), _c('span', {
    staticClass: "label-red"
  }, [_vm._v("*")])]), _c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|min:3',
      expression: "'required|min:3'"
    }],
    "class": {
      'is-invalid': _vm.errors.has('companyName')
    },
    attrs: {
      "type": "text",
      "name": "companyName",
      "placeholder": "Beneficiary",
      "data-vv-validate-on": "change"
    },
    model: {
      value: _vm.data.companyName,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "companyName", $$v);
      },
      expression: "data.companyName"
    }
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.errors.has('companyName'),
      expression: "errors.has('companyName')"
    }],
    staticClass: "invalid-feedback"
  }, [_vm._v(" " + _vm._s(_vm.errors.first('companyName')) + " ")])], 1)], 1) : _vm._e(), _c('b-row', {
    staticClass: "form-group"
  }, [_c('b-col', [_c('label', [_vm._v("Account number or IBAN "), _c('span', {
    staticClass: "label-red"
  }, [_vm._v("*")])]), _c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|currencyCloud:accountNumber',
      expression: "'required|currencyCloud:accountNumber'"
    }],
    "class": {
      'is-invalid': _vm.errors.has('accountNumber')
    },
    attrs: {
      "placeholder": "Account number or IBAN",
      "type": "text",
      "name": "accountNumber"
    },
    on: {
      "blur": _vm.getTypeWithDebounce
    },
    model: {
      value: _vm.accountNumber,
      callback: function callback($$v) {
        _vm.accountNumber = $$v;
      },
      expression: "accountNumber"
    }
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.errors.has('accountNumber'),
      expression: "errors.has('accountNumber')"
    }],
    staticClass: "invalid-feedback"
  }, [_c('span', [_vm._v(_vm._s(_vm.errors.first('accountNumber')))])])], 1)], 1), _c('b-row', {
    staticClass: "form-group"
  }, [_c('b-col', [_c('label', [_vm._v("Buy amount "), _c('span', {
    staticClass: "label-red"
  }, [_vm._v("*")])]), _c('b-input-group', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.buyDisabled,
      expression: "!buyDisabled"
    }],
    "class": {
      'input-invalid is-invalid': _vm.errors.has('buy_amount') || _vm.errors.has('buy_currency_id')
    }
  }, [_c('b-form-input', {
    directives: [{
      name: "filter-input",
      rawName: "v-filter-input",
      value: 'float',
      expression: "'float'"
    }, {
      name: "validate",
      rawName: "v-validate",
      value: 'min_value:1',
      expression: "'min_value:1'"
    }],
    "class": {
      'input-invalid': _vm.errors.has('buy_amount')
    },
    attrs: {
      "name": "amount",
      "placeholder": "0.00",
      "track-by": "buy_amount",
      "return": "buy_amount",
      "disabled": _vm.rateLoading
    },
    on: {
      "blur": _vm.getDebouncedFee,
      "change": _vm.handleBuyChange
    },
    model: {
      value: _vm.data.buyAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "buyAmount", $$v);
      },
      expression: "data.buyAmount"
    }
  }), _c('b-input-group-append', [_c('v-multiselect', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    "class": {
      'is-invalid': _vm.errors.has('buy_currency_id')
    },
    attrs: {
      "options": _vm.currencyOptions,
      "show-labels": false,
      "allow-empty": false,
      "searchable": true,
      "name": "buy_currency_id",
      "custom-label": _vm.currencyLabel,
      "track-by": "currencyId",
      "return": "currencyId",
      "open-direction": "bottom",
      "placeholder": "Select currency",
      "disabled": _vm.rateLoading
    },
    on: {
      "input": _vm.handleCurrencyChange
    },
    model: {
      value: _vm.data.buyCurrencyId,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "buyCurrencyId", $$v);
      },
      expression: "data.buyCurrencyId"
    }
  })], 1)], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.buyDisabled,
      expression: "buyDisabled"
    }]
  }, [_vm.rateLoading ? _c('b-spinner', {
    attrs: {
      "variant": "primary"
    }
  }) : _vm._e(), !_vm.rateLoading ? _c('b-button', {
    staticClass: "amount-button",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.resetDisabled
    }
  }, [_c('span', {
    staticClass: "mr-1"
  }, [_vm._v(_vm._s(_vm.data.buyAmount) + " " + _vm._s(_vm.data.buyCurrencyId && _vm.data.buyCurrencyId.toUpperCase()))]), _c('ion-icon', {
    attrs: {
      "name": "close-circle-outline"
    }
  })], 1) : _vm._e()], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.errors.has('buy_amount'),
      expression: "errors.has('buy_amount')"
    }],
    staticClass: "invalid-feedback"
  }, [_c('span', [_vm._v(_vm._s(_vm.errors.first('buy_amount')))])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.errors.has('buy_currency_id'),
      expression: "errors.has('buy_currency_id')"
    }],
    staticClass: "invalid-feedback"
  }, [_c('span', [_vm._v("Currency is required")])])], 1)], 1), _c('b-row', {
    staticClass: "form-group"
  }, [_c('b-col', [_c('label', [_vm._v("Sell amount")]), _c('b-input-group', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.sellDisabled,
      expression: "!sellDisabled"
    }],
    "class": {
      'is-invalid': _vm.errors.has('sell_amount') || _vm.errors.has('sell_currency_id')
    }
  }, [_c('b-form-input', {
    directives: [{
      name: "filter-input",
      rawName: "v-filter-input",
      value: 'float',
      expression: "'float'"
    }, {
      name: "validate",
      rawName: "v-validate",
      value: 'min_value:1',
      expression: "'min_value:1'"
    }],
    "class": {
      'is-invalid': _vm.errors.has('sell_amount')
    },
    attrs: {
      "name": "amount",
      "placeholder": "0.00",
      "track-by": "sell_amount",
      "return": "sell_amount",
      "disabled": _vm.rateLoading
    },
    on: {
      "blur": _vm.getDebouncedFee,
      "change": _vm.handleSellChange
    },
    model: {
      value: _vm.data.sellAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "sellAmount", $$v);
      },
      expression: "data.sellAmount"
    }
  }), _c('b-input-group-append', [_c('v-multiselect', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    "class": {
      'is-invalid': _vm.errors.has('sell_currency_id')
    },
    attrs: {
      "options": _vm.mappedCurrencies,
      "show-labels": false,
      "allow-empty": false,
      "searchable": false,
      "preselect-first": true,
      "name": "sell_currency_id",
      "label": "text",
      "return": "value",
      "open-direction": "bottom",
      "placeholder": "Select currency",
      "disabled": _vm.rateLoading,
      "track-by": 'value'
    },
    on: {
      "input": _vm.handleCurrencyChange
    },
    model: {
      value: _vm.data.sellCurrencyId,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "sellCurrencyId", $$v);
      },
      expression: "data.sellCurrencyId"
    }
  })], 1)], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.sellDisabled,
      expression: "sellDisabled"
    }]
  }, [_vm.rateLoading ? _c('b-spinner', {
    attrs: {
      "variant": "primary"
    }
  }) : _vm._e(), !_vm.rateLoading ? _c('b-button', {
    staticClass: "amount-button",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.resetDisabled
    }
  }, [_c('span', {
    staticClass: "mr-1"
  }, [_vm._v(_vm._s(_vm.data.sellAmount) + " " + _vm._s(_vm.data.sellCurrencyId && _vm.data.sellCurrencyId.toUpperCase()))]), _c('ion-icon', {
    attrs: {
      "name": "close-circle-outline"
    }
  })], 1) : _vm._e()], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.errors.has('sell_amount'),
      expression: "errors.has('sell_amount')"
    }],
    staticClass: "invalid-feedback"
  }, [_c('span', [_vm._v(_vm._s(_vm.errors.first('sell_amount')))])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.errors.has('sell_currency_id'),
      expression: "errors.has('sell_currency_id')"
    }],
    staticClass: "invalid-feedback"
  }, [_c('span', [_vm._v("Currency is required")])])], 1)], 1), _vm.countryFieldNeeded ? _c('b-row', {
    staticClass: "form-group"
  }, [_c('b-col', [_c('label', [_vm._v("Country "), _c('span', {
    staticClass: "label-red"
  }, [_vm._v("*")])]), _c('v-multiselect', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    "class": {
      'is-invalid': _vm.errors.has('countryId')
    },
    attrs: {
      "options": _vm.countryOptions,
      "show-labels": false,
      "allow-empty": false,
      "disabled": _vm.lockState === 1,
      "name": "countryId",
      "label": "name",
      "placeholder": "Select bank country",
      "track-by": "countryId",
      "return": "countryId",
      "open-direction": "bottom"
    },
    model: {
      value: _vm.data.countryId,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "countryId", $$v);
      },
      expression: "data.countryId"
    }
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.errors.has('countryId'),
      expression: "errors.has('countryId')"
    }],
    staticClass: "invalid-feedback"
  }, [_c('span', [_vm._v(_vm._s(_vm.errors.first('countryId')))])])], 1)], 1) : _vm._e(), _vm.sortCodeNeeded ? _c('b-row', {
    staticClass: "form-group"
  }, [_c('b-col', [_c('label', [_vm._v("Sort code "), _c('span', {
    staticClass: "label-red"
  }, [_vm._v("*")])]), _c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|currencyCloud:sortCode',
      expression: "'required|currencyCloud:sortCode'"
    }],
    key: "sort-code",
    staticClass: "form-control",
    "class": {
      'is-invalid': _vm.errors.has('sort-code')
    },
    attrs: {
      "id": "sort-code",
      "type": "text",
      "name": "sort-code",
      "data-vv-validate-on": "change"
    },
    model: {
      value: _vm.data.sortCode,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "sortCode", $$v);
      },
      expression: "data.sortCode"
    }
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.errors.has('sort-code'),
      expression: "errors.has('sort-code')"
    }],
    staticClass: "invalid-feedback"
  }, [_c('span', [_vm._v(_vm._s(_vm.errors.first('sort-code')))])])], 1)], 1) : _vm._e(), _vm.bsbCodeNeeded ? _c('b-row', {
    staticClass: "form-group"
  }, [_c('b-col', [_c('label', [_vm._v("BSB code "), _c('span', {
    staticClass: "label-red"
  }, [_vm._v("*")])]), _c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|currencyCloud:bsbCode',
      expression: "'required|currencyCloud:bsbCode'"
    }],
    key: "bsb-code",
    staticClass: "form-control",
    "class": {
      'is-invalid': _vm.errors.has('bsb-code')
    },
    attrs: {
      "id": "bsb-code",
      "type": "text",
      "name": "bsb-code",
      "data-vv-validate-on": "change"
    },
    model: {
      value: _vm.data.bsbCode,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "bsbCode", $$v);
      },
      expression: "data.bsbCode"
    }
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.errors.has('bsb-code'),
      expression: "errors.has('bsb-code')"
    }],
    staticClass: "invalid-feedback"
  }, [_c('span', [_vm._v(_vm._s(_vm.errors.first('bsb-code')))])])], 1)], 1) : _vm._e(), _vm.bankCodeNeeded ? _c('b-row', {
    staticClass: "form-group"
  }, [_c('b-col', [_c('label', [_vm._v("Bank code "), _c('span', {
    staticClass: "label-red"
  }, [_vm._v("*")])]), _c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|currencyCloud:bankCode',
      expression: "'required|currencyCloud:bankCode'"
    }],
    key: "bank-code",
    staticClass: "form-control",
    "class": {
      'is-invalid': _vm.errors.has('bank-code')
    },
    attrs: {
      "id": "bank-code",
      "type": "text",
      "name": "bank-code",
      "data-vv-validate-on": "change"
    },
    model: {
      value: _vm.data.bankCode,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "bankCode", $$v);
      },
      expression: "data.bankCode"
    }
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.errors.has('bank-code'),
      expression: "errors.has('bank-code')"
    }],
    staticClass: "invalid-feedback"
  }, [_c('span', [_vm._v(_vm._s(_vm.errors.first('bank-code')))])])], 1)], 1) : _vm._e(), _vm.branchCodeNeeded ? _c('b-row', {
    staticClass: "form-group"
  }, [_c('b-col', [_c('label', [_vm._v("Branch code "), _c('span', {
    staticClass: "label-red"
  }, [_vm._v("*")])]), _c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|currencyCloud:branchCode',
      expression: "'required|currencyCloud:branchCode'"
    }],
    key: "branch-code",
    staticClass: "form-control",
    "class": {
      'is-invalid': _vm.errors.has('branch-code')
    },
    attrs: {
      "id": "branch-code",
      "type": "text",
      "name": "branch-code",
      "data-vv-validate-on": "change"
    },
    model: {
      value: _vm.data.branchCode,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "branchCode", $$v);
      },
      expression: "data.branchCode"
    }
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.errors.has('branch-code'),
      expression: "errors.has('branch-code')"
    }],
    staticClass: "invalid-feedback"
  }, [_c('span', [_vm._v(_vm._s(_vm.errors.first('branch-code')))])])], 1)], 1) : _vm._e(), _vm.abaNeeded ? _c('b-row', {
    staticClass: "form-group"
  }, [_c('b-col', [_c('label', [_vm._v("Aba routing number "), _c('span', {
    staticClass: "label-red"
  }, [_vm._v("*")])]), _c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|currencyCloud:aba',
      expression: "'required|currencyCloud:aba'"
    }],
    key: "aba",
    staticClass: "form-control",
    "class": {
      'is-invalid': _vm.errors.has('aba')
    },
    attrs: {
      "id": "aba",
      "type": "text",
      "name": "aba",
      "data-vv-validate-on": "change"
    },
    model: {
      value: _vm.data.aba,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "aba", $$v);
      },
      expression: "data.aba"
    }
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.errors.has('aba'),
      expression: "errors.has('aba')"
    }],
    staticClass: "invalid-feedback"
  }, [_c('span', [_vm._v(_vm._s(_vm.errors.first('aba')))])])], 1)], 1) : _vm._e(), _vm.bicNeeded ? _c('b-row', {
    staticClass: "form-group"
  }, [_c('b-col', [_c('label', [_vm._v("BIC/SWIFT code "), _c('span', {
    staticClass: "label-red"
  }, [_vm._v("*")])]), _c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|currencyCloud:bic',
      expression: "'required|currencyCloud:bic'"
    }],
    key: "bic",
    staticClass: "form-control",
    "class": {
      'is-invalid': _vm.errors.has('bic')
    },
    attrs: {
      "id": "bic",
      "readonly": _vm.lockState === 1,
      "type": "text",
      "name": "bic",
      "data-vv-validate-on": "change"
    },
    model: {
      value: _vm.data.bic,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "bic", $$v);
      },
      expression: "data.bic"
    }
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.errors.has('bic'),
      expression: "errors.has('bic')"
    }],
    staticClass: "invalid-feedback"
  }, [_c('span', [_vm._v(_vm._s(_vm.errors.first('bic')))])])], 1)], 1) : _vm._e(), _vm.stateNeeded ? _c('b-row', {
    staticClass: "form-group"
  }, [_c('b-col', [_c('label', [_vm._v("State "), _c('span', {
    staticClass: "label-red"
  }, [_vm._v("*")])]), _c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    key: "state",
    staticClass: "form-control",
    "class": {
      'is-invalid': _vm.errors.has('state')
    },
    attrs: {
      "id": "state",
      "type": "text",
      "name": "state",
      "data-vv-validate-on": "change"
    },
    model: {
      value: _vm.data.state,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "state", $$v);
      },
      expression: "data.state"
    }
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.errors.has('state'),
      expression: "errors.has('state')"
    }],
    staticClass: "invalid-feedback"
  }, [_c('span', [_vm._v(_vm._s(_vm.errors.first('state')))])])], 1)], 1) : _vm._e(), _vm.addressNeeded ? _c('b-row', {
    staticClass: "form-group"
  }, [_c('b-col', [_c('label', [_vm._v("City "), _c('span', {
    staticClass: "label-red"
  }, [_vm._v("*")])]), _c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    key: "city",
    staticClass: "form-control",
    "class": {
      'is-invalid': _vm.errors.has('city')
    },
    attrs: {
      "id": "city",
      "type": "text",
      "name": "city",
      "data-vv-validate-on": "change"
    },
    model: {
      value: _vm.data.city,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "city", $$v);
      },
      expression: "data.city"
    }
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.errors.has('city'),
      expression: "errors.has('city')"
    }],
    staticClass: "invalid-feedback"
  }, [_vm._v(" This field is required ")])], 1)], 1) : _vm._e(), _vm.addressNeeded ? _c('b-row', {
    staticClass: "form-group"
  }, [_c('b-col', [_c('label', [_vm._v("Address "), _c('span', {
    staticClass: "label-red"
  }, [_vm._v("*")])]), _c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    key: "address",
    staticClass: "form-control",
    "class": {
      'is-invalid': _vm.errors.has('address')
    },
    attrs: {
      "id": "address",
      "type": "text",
      "name": "address",
      "data-vv-validate-on": "change"
    },
    model: {
      value: _vm.data.address,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "address", $$v);
      },
      expression: "data.address"
    }
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.errors.has('address'),
      expression: "errors.has('address')"
    }],
    staticClass: "invalid-feedback"
  }, [_c('span', [_vm._v(_vm._s(_vm.errors.first('address')))])])], 1)], 1) : _vm._e(), _vm.postCodeNeeded ? _c('b-row', {
    staticClass: "form-group"
  }, [_c('b-col', [_c('label', [_vm._v("Postcode/zipcode "), _c('span', {
    staticClass: "label-red"
  }, [_vm._v("*")])]), _c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    key: "postcode",
    staticClass: "form-control",
    "class": {
      'is-invalid': _vm.errors.has('postcode')
    },
    attrs: {
      "id": "postcode",
      "type": "text",
      "name": "postcode",
      "data-vv-validate-on": "change"
    },
    model: {
      value: _vm.data.postcode,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "postcode", $$v);
      },
      expression: "data.postcode"
    }
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.errors.has('postcode'),
      expression: "errors.has('postcode')"
    }],
    staticClass: "invalid-feedback"
  }, [_c('span', [_vm._v(_vm._s(_vm.errors.first('postcode')))])])], 1)], 1) : _vm._e(), _vm.purposeNeeded && _vm.purposeList[_vm.data.countryId] ? _c('b-row', {
    staticClass: "form-group"
  }, [_c('b-col', [_c('label', [_vm._v("Purpose "), _c('span', {
    staticClass: "label-red"
  }, [_vm._v("*")])]), _c('v-multiselect', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    staticClass: "swift-form__purpose",
    "class": {
      'is-invalid': _vm.errors.has('purpose')
    },
    attrs: {
      "options": _vm.purposeList[_vm.data.countryId],
      "show-labels": false,
      "allow-empty": false,
      "name": "purpose",
      "label": "name",
      "placeholder": "Select purpose",
      "track-by": "value",
      "return": "value",
      "open-direction": "bottom"
    },
    model: {
      value: _vm.data.purpose,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "purpose", $$v);
      },
      expression: "data.purpose"
    }
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.errors.has('purpose'),
      expression: "errors.has('purpose')"
    }],
    staticClass: "invalid-feedback"
  }, [_c('span', [_vm._v(_vm._s(_vm.errors.first('purpose')))])])], 1)], 1) : _vm._e(), _c('b-row', {
    staticClass: "form-group"
  }, [_c('b-col', [_c('label', [_vm._v("Payment details "), _c('span', {
    staticClass: "label-red"
  }, [_vm._v("*")])]), _c('b-form-textarea', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    "class": {
      'is-invalid': _vm.errors.has('details')
    },
    attrs: {
      "rows": 3,
      "max-rows": 6,
      "name": "details",
      "maxlength": "124"
    },
    model: {
      value: _vm.data.details,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "details", $$v);
      },
      expression: "data.details"
    }
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.errors.has('details'),
      expression: "errors.has('details')"
    }],
    staticClass: "invalid-feedback"
  }, [_c('span', [_vm._v(_vm._s(_vm.errors.first('details')))])])], 1)], 1), _c('b-row', {
    staticClass: "form-group"
  }, [_c('b-col', [_c('label', [_vm._v("Files")]), _c('file-upload', {
    attrs: {
      "multiple": "",
      "add-more-button": "",
      "file-list": "",
      "accept": ".jpg, .jpeg, .gif, .png, .pdf, .eml"
    },
    on: {
      "onChange": function onChange(files) {
        return _vm.data.files = files.map(function (f) {
          return {
            file: f.content,
            fileName: f.name
          };
        });
      }
    }
  })], 1)], 1), _vm.user.role === 'business' ? _c('b-row', {
    staticClass: "form-group"
  }, [_c('b-col', [_c('b-form-checkbox', {
    attrs: {
      "type": "checkbox",
      "name": "changeTemplate"
    },
    model: {
      value: _vm.data.changeTemplate,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "changeTemplate", $$v);
      },
      expression: "data.changeTemplate"
    }
  }, [(_vm$transactionTempla = _vm.transactionTemplate) !== null && _vm$transactionTempla !== void 0 && _vm$transactionTempla.id ? _c('span', [_vm._v(" Change template ")]) : _c('span', [_vm._v(" Add to my templates ")])])], 1)], 1) : _vm._e(), _vm.data.changeTemplate ? _c('b-row', {
    staticClass: "form-group"
  }, [_c('b-col', [_c('label', [_vm._v("Template name "), _c('span', {
    staticClass: "label-red"
  }, [_vm._v("*")])]), _c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    key: "templateName",
    staticClass: "form-control",
    "class": {
      'is-invalid': _vm.errors.has('templateName')
    },
    attrs: {
      "id": "templateName",
      "type": "text",
      "name": "templateName",
      "data-vv-validate-on": "change"
    },
    model: {
      value: _vm.data.templateName,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "templateName", $$v);
      },
      expression: "data.templateName"
    }
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.errors.has('templateName'),
      expression: "errors.has('templateName')"
    }],
    staticClass: "invalid-feedback"
  }, [_c('span', [_vm._v(_vm._s(_vm.errors.first('templateName')))])])], 1)], 1) : _vm._e(), _c('b-row', {
    staticClass: "form-group"
  }, [_vm.apiError !== null ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-alert', {
    staticClass: "text-center mb-4",
    attrs: {
      "variant": "alert",
      "show": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.apiError) + " ")])], 1) : _vm._e()], 1), _c('b-row', [_c('b-col', [_c('small', {
    staticClass: "label-red"
  }, [_vm._v("* Required fields")])])], 1)], 1)], 1), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "xl": "5",
      "cols": "12"
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', [_vm._v(" Exchange rate: ")]), _c('b-col', {
    staticClass: "text-right"
  }, [_vm.data.buyCurrencyId && _vm.data.sellCurrencyId && _vm.data.rate ? _c('span', [_vm._v(" 1 " + _vm._s(_vm.data.sellCurrencyId.toUpperCase()) + " = " + _vm._s(_vm.data.rate) + " " + _vm._s(_vm.data.buyCurrencyId.toUpperCase()) + " ")]) : _vm._e(), !_vm.data.rate ? _c('span', [_vm._v(" - ")]) : _vm._e()])], 1), _c('b-row', [_c('b-col', [_vm._v(" Transaction fee: ")]), _c('b-col', {
    staticClass: "text-right"
  }, [_c('span', [_vm._v(_vm._s(_vm.fee || '0.00') + " " + _vm._s(_vm.data.sellCurrencyId.toUpperCase()))]), _vm.fee && _vm.currentFeeWalletAmount ? _c('div', [_c('b-badge', {
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(" Uses fee wallet ")])], 1) : _vm._e()])], 1), _c('b-row', {
    staticClass: "mt-3"
  }, [_c('b-col', [_c('span', {
    staticClass: "notes"
  }, [_vm._v(" " + _vm._s(_vm.notes) + " ")])])], 1), _c('hr'), _c('b-row', [_c('b-col', [_c('strong', [_vm._v("Estimated total:")])]), _c('b-col', {
    staticClass: "text-right"
  }, [_c('strong', [_vm._v(_vm._s(_vm.estimatedTotal) + " " + _vm._s(_vm.data.sellCurrencyId && _vm.data.sellCurrencyId.toUpperCase()))])])], 1), _vm.insufficientFunds ? _c('b-row', [_c('b-col', {
    staticClass: "text-right"
  }, [_c('b-badge', {
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v(" Insufficient Funds ")])], 1)], 1) : _vm._e()], 1), _c('b-row', {
    staticClass: "form-group mt-3 mb-0",
    attrs: {
      "align-h": "center"
    }
  }, [_vm.user.role === 'business' && _vm.isWalletGranted('initiate_transaction', _vm.currentWallet.walletId) ? _c('b-col', [_c('button', {
    staticClass: "btn btn-outline-primary btn-block",
    attrs: {
      "disabled": _vm.isSubmitting || _vm.insufficientFunds || !_vm.data.buyAmount && !_vm.data.sellAmount || _vm.rateLoading,
      "type": "submit"
    },
    on: {
      "click": function click($event) {
        return _vm.transferMoney('initiate');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.currentlyLoading === true || _vm.rateLoading ? 'Loading...' : 'Save for confirmation') + " ")])]) : _vm._e()], 1), _c('b-row', {
    staticClass: "form-group mt-3 mb-0",
    attrs: {
      "align-h": "center"
    }
  }, [_vm.isWalletGranted('approve_transaction', _vm.currentWallet.walletId) ? _c('b-col', [_c('button', {
    staticClass: "btn btn-primary btn-block",
    attrs: {
      "disabled": _vm.isSubmitting || _vm.insufficientFunds || !_vm.data.buyAmount && !_vm.data.sellAmount || _vm.rateLoading,
      "type": "submit"
    },
    on: {
      "click": function click($event) {
        return _vm.transferMoney('approve');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.currentlyLoading === true || _vm.rateLoading ? 'Loading...' : 'Next') + " ")])]) : _vm._e()], 1)], 1)], 1)], 1), _vm.currentAction === 'step2' && _vm.successAction !== 1 && _vm.successAction !== 2 ? _c('div', [_c('b-card', [_c('b-row', {
    staticClass: "form-group mb-lg-0 pt-3"
  }, [_c('label', {
    staticClass: "col-lg-5 col-xl-3 col-form-label"
  }, [_vm._v("Wallet send from:")]), _c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "lg": "7",
      "xl": "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.walletId) + " (Balance " + _vm._s(_vm.currentWalletAmount.balance) + " " + _vm._s(_vm.data.sellCurrencyId.toUpperCase()) + " ) ")])], 1), _c('b-row', {
    staticClass: "form-group mb-lg-0"
  }, [_c('label', {
    staticClass: "col-lg-5 col-xl-3 col-form-label"
  }, [_vm._v("Account number:")]), _c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "lg": "7",
      "xl": "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.accountNumber) + " ")])], 1), _c('b-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.data.name && _vm.data.name !== '',
      expression: "data.name && data.name !== ''"
    }],
    staticClass: "form-group mb-lg-0"
  }, [_c('label', {
    staticClass: "col-lg-5 col-xl-3 col-form-label"
  }, [_vm._v("Recipient name:")]), _c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "lg": "7",
      "xl": "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.name) + " " + _vm._s(_vm.data.surname) + " ")])], 1), _c('b-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.data.companyName && _vm.data.companyName !== '',
      expression: "data.companyName && data.companyName !== ''"
    }],
    staticClass: "form-group mb-lg-0"
  }, [_c('label', {
    staticClass: "col-lg-5 col-xl-3 col-form-label"
  }, [_vm._v("Recipient:")]), _c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "lg": "7",
      "xl": "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.companyName) + " ")])], 1), _c('b-row', {
    staticClass: "form-group mb-lg-0"
  }, [_c('label', {
    staticClass: "col-lg-5 col-xl-3 col-form-label"
  }, [_vm._v("Buy amount:")]), _c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "lg": "7",
      "xl": "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.buyAmount) + " " + _vm._s(_vm.data.buyCurrencyId.toUpperCase()) + " ")])], 1), _c('b-row', {
    staticClass: "form-group mb-lg-0"
  }, [_c('label', {
    staticClass: "col-lg-5 col-xl-3 col-form-label"
  }, [_vm._v("Sell amount:")]), _c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "lg": "7",
      "xl": "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.sellAmount) + " " + _vm._s(_vm.data.sellCurrencyId.toUpperCase()) + " (2% of sell amount will be reserved for rate changes) ")])], 1), _vm.fee ? _c('b-row', {
    staticClass: "form-group mb-lg-0"
  }, [_c('label', {
    staticClass: "col-lg-5 col-xl-3 col-form-label"
  }, [_vm._v("Fee:")]), _c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "lg": "7",
      "xl": "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.fee) + " " + _vm._s(_vm.data.sellCurrencyId.toUpperCase()) + " ")])], 1) : _vm._e(), _c('b-row', {
    staticClass: "form-group mb-lg-0"
  }, [_c('label', {
    staticClass: "col-lg-5 col-xl-3 col-form-label"
  }, [_vm._v("Rate:")]), _c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "lg": "7",
      "xl": "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.rate) + " ")])], 1), _c('b-row', {
    staticClass: "form-group mb-4"
  }, [_c('label', {
    staticClass: "col-lg-5 col-xl-3 col-form-label"
  }, [_vm._v("Description:")]), _c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "lg": "7",
      "xl": "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.details) + " ")])], 1)], 1), _c('b-row', {
    staticClass: "mt-3"
  }, [_c('b-col', [_c('b-card', {
    staticClass: "mb-4 mb-lg-5"
  }, [_c('b-row', [_c('b-col', [_vm.isWalletGranted('approve_transaction', _vm.currentWallet.walletId) && _vm.transferAction === 'approve' ? _c('authenticator', {
    ref: "sms",
    attrs: {
      "show-back-button": false,
      "on-back": _vm.handleBack,
      "on-submit": _vm.submit,
      "type": "transfer",
      "button-text": "Confirm transfer",
      "resend-data": _vm.authenticatorData,
      "data": "data"
    }
  }) : _vm._e(), _vm.isWalletGranted('initiate_transaction', _vm.currentWallet.walletId) && _vm.transferAction === 'initiate' ? _c('authenticator', {
    ref: "sms",
    attrs: {
      "show-back-button": false,
      "on-back": _vm.handleBack,
      "on-submit": _vm.submitInitiation,
      "type": "transfer",
      "button-text": "Save for confirmation",
      "resend-data": _vm.authenticatorData,
      "data": "data"
    }
  }) : _vm._e()], 1)], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "text-center"
  }, [_c('input', {
    staticClass: "btn btn-outline-dark px-5",
    attrs: {
      "type": "button",
      "value": "Edit transfer"
    },
    on: {
      "click": _vm.handleBack
    }
  })])], 1)], 1) : _vm._e(), _vm.successAction === 1 ? _c('div', {
    staticClass: "justify-content-md-center"
  }, [_c('div', {
    staticClass: "text-center py-4"
  }, [_c('h3', {
    staticClass: "text-success mb-4"
  }, [_vm._v(" Your transfer #" + _vm._s(_vm.transactionId) + " has been sent ")]), _c('router-link', {
    staticClass: "btn btn-outline-dark px-5",
    attrs: {
      "to": {
        name: 'transactions'
      },
      "exact": ""
    }
  }, [_vm._v(" Check transactions ")])], 1)]) : _vm._e(), _vm.successAction === 2 ? _c('div', {
    staticClass: "justify-content-md-center"
  }, [_c('div', {
    staticClass: "text-center py-4"
  }, [_c('h3', {
    staticClass: "text-success mb-4"
  }, [_vm._v(" Your transfer saved for confirmation later ")]), _c('div', {
    staticClass: "btn btn-outline-dark px-5",
    on: {
      "click": _vm.goToPending
    }
  }, [_vm._v(" Check for confirmation ")])])]) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }