<template>
	<div
		v-if="user && user.verification !== 'verified'"
		class="text-left"
	>
		<b-card
			v-if="user.verification === 'pending'"
			border-variant="warning"
			class="card-verification pending"
		>
			<b-card-text>
				<b-row class="align-items-center">
					<b-col
						cols="auto"
						class="pr-0"
					>
						<img
							src="/images/img/pending-verification.svg"
							width="54"
							height="54"
						/>
					</b-col>
					<b-col>
						<h3>Pending verification check!</h3>
						<p class="mb-0">
							You can update your data while verification is
							pending.
						</p>
					</b-col>
					<b-col
						cols="12"
						sm="auto"
						class="ml-sm-auto pt-3 pt-sm-0 text-center text-sm-left"
					>
						<router-link
							:to="verificationLink"
							class="btn btn-warning"
							exact
						>
							Review your data
						</router-link>
					</b-col>
				</b-row>
			</b-card-text>
		</b-card>
		<b-card
			v-else
			border-variant="danger"
			class="card-verification not-verified"
		>
			<b-card-text>
				<b-row class="align-items-center">
					<b-col
						cols="auto"
						class="pr-0"
					>
						<img
							src="/images/img/not-verified.svg"
							width="54"
							height="54"
						/>
					</b-col>
					<b-col>
						<h3>You're not verified yet!</h3>
						<p class="mb-0">
							Please verify your identity to access all features.
						</p>
					</b-col>
					<b-col
						cols="12"
						sm="auto"
						class="ml-sm-auto pt-3 pt-sm-0 text-center text-sm-left"
					>
						<router-link
							:to="verificationLink"
							class="btn btn-danger"
							exact
						>
							Verify now
						</router-link>
					</b-col>
				</b-row>
			</b-card-text>
		</b-card>
	</div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';

export default {
	computed: {
		...mapState({
			user: (state) => state.user.selected
		}),
		...mapGetters('user', ['verificationLink']),
	}
};
</script>