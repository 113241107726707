<template>
	<div>
		<div class="page-title-container">
			<h2>Account settings</h2>
		</div>

		<b-card>
			<b-row>
				<b-col v-if="walletList.length > 0"
					md="6"
					sm="12"
					class="mb-4 mb-md-0"
				>
					<div
						v-b-toggle.account-wallet-settings
						class="settings-button"
						:class="{'settings-button__active' : visibleTab[0]}"
					>
						<span class="icon icon__cash"></span>
						<span>Account Wallet Settings</span>
					</div>
				</b-col>
				<b-col md="6"
					sm="12"
				>
					<div
						v-b-toggle.email-settings
						class="settings-button"
						:class="{'settings-button__active' : visibleTab[1]}"
					>
						<span class="icon icon__mail"></span>

						Email Settings
					</div>
				</b-col>
			</b-row>
		</b-card>
		<b-card v-show="visible"
			class="mt-4"
		>
			<b-collapse
				id="account-wallet-settings"
				v-model="visibleTab[0]"
				accordion="account-settings"
				role="tabpanel"
			>
				<account-wallet-name-settings />
			</b-collapse>
			<b-collapse
				id="email-settings"
				v-model="visibleTab[1]"
				accordion="account-settings"
				role="tabpanel"
			>
				<account-email-settings />
			</b-collapse>
		</b-card>
	</div>
</template>
<script>
import AccountWalletNameSettings from './AccountWalletNameSettings';
import AccountEmailSettings from './AccountEmailSettings';
import { mapState } from 'vuex';

export default {
	components: {
		AccountWalletNameSettings,
		AccountEmailSettings
	},
	data() {
		return {
			visibleTab: []
		};
	},
	computed: {
		...mapState('wallet', {
			walletList: state => state.list
		}),
		visible() {
			return this.visibleTab.reduce((a, b) => a || b, false);
		}
	}
};
</script>

<style lang="scss" scoped>
	.settings-button {
		background-color: $primary;
		color: #fff;
		padding: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: background-color .3s ease;

		&:hover, &__active {
			background-color: #343a40;
		}
		

		.icon {
			width: 24px;
			height: 24px;
			display: inline-block;
			background-color: #fff;
			margin-right: 5px;

			&__cash {
				mask: url('/images/icons/app/cash.svg');
			}
			&__mail {
				mask: url('/images/icons/app/mail.svg');
			}
		}
	}
</style>