let allowedTypes = {
	'alpha': '[a-zA-Z]+',
	'unicode': '\\p{L}+', //'[\u00C0-\u017F]+',
	'space': '\\s+',
	'dash': '[-_]+',
	'dot': '[.]+',
	'email': '[a-zA-Z@.-_+]+',
	'apostrophe': '[\']+',
	'number': '[0-9]+',
	'float': '[0-9.]+',
};

var XRegExp = require('xregexp');

export default {
	bind(el, binding) {
		let typeParam = binding.expression,
			types = typeParam.replace(/'/gm, '').split('|');

		var handler = function(e) {
			let input = e.code ? e.key : String.fromCharCode(e.keyCode),
				prevent = true;

			for (let i=0; i<types.length; i++) {
				let type = types[i];

				if (!Object.keys(allowedTypes).includes(type)) {
					throw new Error('Type is not defined: ' + type);
				}

				if (XRegExp(allowedTypes[type]).test(input)) {
					prevent = false;
				}
			}

			if (prevent) {
				e.preventDefault();
			}
		};
		el.addEventListener('keypress', handler);
	}
};
