
export default {
	username: (state) => {
		return state.selected?.fullName;
	},
	isBusiness: state => {
		return state.selected?.role.includes('business');
	},
	isVerifiedAddress: () => {
		let status = true;
		// Old - used to show IBAN with address. Change if needed.
		return status;
	},
	isVerified: state => {
		return state.selected?.verification === 'verified';
	},
	isPersonal: state => {
		return state.selected?.role.includes('user');
	},
	isGranted(state, getters) {
		return role => {
			return getters.profileDefined ? state.permissions.includes(role) : false;
		};
	},
	isWalletGranted(state, getters) {
		return (role, walletId) => {
			if (!getters.profileDefined || state.walletPermissions.length < 1) {
				return false;
			}

			let walletPermissions = [];
			state.walletPermissions.forEach(permission => {
				if (walletId === permission.walletId) {
					walletPermissions = permission.permissions;
				}
			});

			return walletPermissions.includes(role);
		};
	},
	userAddress: state => {
		const addr = state.selected.userProfile.addresses;
		return addr.main;
	},
	isVerificationFilled: (state, getters) => {
		let filled = getters.profileFilled && getters.questionnaireFilled && getters.photoFilled;
		if (getters.isBusiness) {
			filled = filled && getters.businessFilled;
		}
		return filled;
	},
	verificationLink: (state, getters) => {
		function getRouteName() {
			const details = state.selected;
			if (details.verification == 'pending') {
				return getters.isBusiness ? 'verify-account' : 'verify-person';
			}
			if (getters.isBusiness) {
				const company = details.companyProfile;
				const q = company.questionnaire;
				const p = details.keyPersonsList;
				if (!q.accountInfoScope?.values?.length || !q.accountInfoUse?.values?.length) return 'verify-account';
				if (!company.name) return 'verify-business';
				if (p.length == 0 || (p && !p[0].name)) return 'verify-person';
				if (!q.businessActivities?.values?.length) return 'business-questionnaire';
				if (!company.documentsList?.length && details.verification !== 'pending') return 'verify-business-documents';
				return 'verify-photo';
			} else {
				return 'verify-person';
			}
		}
		return { name: getRouteName() };
	},
	profileDefined: state => {
		return !!(state.selected && state.selected.userProfile);
	},
	defaultCurrency: (state, getters) => {
		if (getters.profileDefined) {
			return state.selected.userProfile.currency_id;
		}
		return 'eur';
	},
	profileFilled: (state, getters) => {
		return getters.profileDefined ? !!state.selected.userProfile.surname : false;
	},
	owner: state => {
		const persons = state.selected.keyPersonsList;
		return persons ? persons.find(p => p.type == 'owner') : null;
	},
	persons: state => {
		return state.selected.keyPersonsList;
	},
	currentPerson: state => {
		return state.selected.userProfile;
	},
	authenticatorType: state => {
		return state.authenticator.type;
	},
	authenticatorList: state => {
		return state.authenticator.list;
	},
	accountInfoUse: state => {
		return state.selected?.companyProfile?.questionnaire?.accountInfoUse?.values;
	},
	email: state => {
		return state.selected?.userProfile?.contacts?.mainEmail;
	}
};
