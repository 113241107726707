<template>
	<form
		action="#"
		class="form-body margintop30 account-info-form"
		autocomplete="disable"
		:disabled="details.verification == 'pending'"
		@submit.prevent="onsubmit()"
	>
		<h2 class="page-title">
			Account Information
		</h2>
		<form-error-field name="accountInfoUse.values"
			wrapper="div"
			class="position-relative"
		>
			<verify-account-info-use v-model="data.accountInfoUse"
				class="mb-4"
			>
				<template #label>
					<p class="label">
						<b>Use of Account</b>
					</p>
				</template>			
				<template #other-label>
					<p class="label">
						<b>Other use of Account</b>
					</p>
				</template>
			</verify-account-info-use>
		</form-error-field>
		<b-row>
			<b-col>
				<p class="label">
					<b>Scope of services</b>
				</p>
			</b-col>
		</b-row>
		<form-error-field name="accountInfoScope.values"
			wrapper="div"
		>
			<checkbox-list
				:items="accountInfoScopeOptions"
				:value="data.accountInfoScope.values"
				data-registration="scope-of-services"
				@change="v => toggleScopeValue(v)"
			>
				<template #swift_multi_currency>
					<a
						v-b-toggle.currencies
						href=""
						@click.prevent.stop
					><small>List of supported currencies</small></a>
				</template>
			</checkbox-list>
		</form-error-field>
		<b-row class="mt-4">
			<b-col class="text-center">
				<b-collapse id="currencies">
					<b-card class="mt-4">
						<b-row>
							<b-col
								v-for="c in currencies"
								:key="c.currencyId"
								cols="3"
								class="flag-image"
							>
								<img
									:src="flagImg(c.country)"
									:alt="c"
								/>
								<div>
									{{ c.name }} ({{ c.currencyId.toUpperCase() }})
								</div>
							</b-col>
						</b-row>
						<b-row>
							<b-col>
								<i
									v-b-toggle.currencies
									class="fas fa-chevron-up close-icon"
								></i>
							</b-col>
						</b-row>
					</b-card>
				</b-collapse>
			</b-col>
		</b-row>
		<b-row>
			<b-col class="mt-4 mb-4 text-center">
				<notification />
				<input
					:class="buttonClass"
					:value="buttonText"
					:disabled="!formFilled"
					type="submit"
					data-registration="next"
				/>
			</b-col>
		</b-row>
		<b-row v-if="allowSkip">
			<b-col class="text-center">
				<router-link 
					:to="{name: 'home'}" 
					tag="a" 
					class="framelink skiplink" 
					exact
				>
					<span v-if="details.verification !== 'pending'">Skip verification</span>
					<span v-if="details.verification == 'pending'">Back to main page</span>
				</router-link>
			</b-col>
		</b-row>
	</form>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import countryCurrency from 'country-currency-map';
import CheckboxList from '~/components/shared/CheckboxList.vue';
import VerifyAccountInfoUse from './VerifyAccountInfoUse';
import { accountInfoScopeOptions, currencies } from './VerifyAccountInfoOptions';
import FormErrorField from '@/shared/Form/FormErrorField';
import { handleApiFormErrors } from '~/helpers';

export default {
	name: 'BusinessInformationForm',
	components: {
		CheckboxList,
		VerifyAccountInfoUse,
		FormErrorField
	},
	props: {
		buttonClass: {
			type: String,
			default: 'btn btn-small btn-primary w-100',
		},
		buttonText: {
			type: String,
			default: 'Next',
		},
		allowSkip: {
			type: Boolean,
			default: true,
		},
	},
	data: function () {
		return {
			accountInfoScopeOptions,
			currencies,
			data: {
				accountInfoUse: {
					values: []
				},
				accountInfoScope: {
					values: []
				},
			}, // set from $store on mounted() event
		};
	},
	
	computed: {
		...mapState('user', {
			details: (state) => state.selected,
			company: (state) => state.selected.companyProfile,
		}),
		...mapGetters('user', [
			'isPersonal',
			'isBusiness'
		]),
		formFilled() {
			return (
				this.data.accountInfoUse?.values?.length &&
				this.data.accountInfoScope?.values?.length
			);
		},
	},
	watch: {
		data: {
			// if any element is modified in data - update $store accordingly
			handler() {
				if (
					(this.data.accountInfoUse?.values?.length > 0) ||
					(this.data.accountInfoScope?.values?.length)
				) {
					this.updateCompany(this.data);
				}
			},
			deep: true,
		},
	},
	mounted() {
		this.data = {
			accountInfoUse: { 
				values: this.company?.questionnaire?.accountInfoUse?.values, 
				otherValue: ''
			},
			accountInfoScope: { 
				values: this.company?.questionnaire?.accountInfoScope?.values, 
				otherValue: '' 
			}
		};
		this.$gtag.event(`register_verification_account_info_${this.isBusiness ? 'business' : 'personal'}`);
	},
	methods: {
		...mapActions('user', ['updateCompany', 'submitAccountInfo']),
		...mapActions('notifications', ['pushError']),
		flagImg(id) {
			return require(`svg-country-flags/svg/${id}.svg`);
		},
		currencyName(id) {
			let currency;
			if (id == 'gb') id = 'uk';
			if (id == 'eu') {
				currency = 'EUR';
			} else {
				const countryName = countryCurrency.getCountryByAbbreviation(
					id.toUpperCase()
				);
				currency = countryCurrency.getCountry(countryName).currency;
			}
			return countryCurrency.getCurrency(currency).name;
		},
		reset() {
			this.data.accountInfoUse.values = [];
			this.data.accountInfoUse.otherValue = '';
			this.data.accountInfoScope.values = [];
			this.data.accountInfoScope.otherValue = '';
		},
		async onsubmit() {
			if (this.details.verification == 'pending') {
				this.$router.push({ name: 'verify-business' });
				return;
			}
			const valid = await this.$validator.validate();
			if (
				valid &&
				this.data.accountInfoUse?.values?.length > 0 &&
				this.data.accountInfoScope?.values?.length > 0
			) {
				try {
					await this.submitAccountInfo(this.data);
					this.$router.push({ name: 'verify-business' });
				} catch (err) {
					handleApiFormErrors(err, this.errors);


					this.pushError(err.message);
				}
			} else {
				this.pushError('Please check at least one box each.');
			}
		},
		toggleScopeValue(value) {
			if (this.details.verification == 'pending') return;
			const scope = this.data.accountInfoScope.values;
			let newValue = scope || [];
			if (scope && scope.includes(value)) {
				newValue = scope.filter(v => v !== value);
			} else {
				newValue.push(value);
			}
			this.$set(this.data.accountInfoScope, 'values', newValue);
		}
	},
	provide() {
		return {
			$validator: this.$validator
		};
	}
};
</script>
<style lang="scss">
	@import "./VerifyAccountInfo.scss";
</style>