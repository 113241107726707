var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h2', [_vm._v("Enter online transaction password")]), _c('img', {
    attrs: {
      "src": "/images/icons/card-password.svg",
      "alt": "Card password"
    }
  }), _c('p', {
    staticClass: "my-4"
  }, [_vm._v(" Create password for online transactions ")]), _c('label', {
    staticClass: "card-input-label"
  }, [_c('span', [_vm._v("Password")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.data.password,
      expression: "data.password"
    }, {
      name: "validate",
      rawName: "v-validate",
      value: 'min:6|max:32|required',
      expression: "'min:6|max:32|required'"
    }],
    "class": _vm.errors.has('password') ? 'card-input-field-error' : '',
    attrs: {
      "type": "password",
      "name": "password",
      "pattern": "\\d*",
      "minlength": "6",
      "placeholder": "Enter min. 6 symbol password"
    },
    domProps: {
      "value": _vm.data.password
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.data, "password", $event.target.value);
      }
    }
  }), _vm.errors.has('password') ? _c('div', {
    staticClass: "card-input-error"
  }, [_vm._v(" " + _vm._s(_vm.errors.first('password')) + " ")]) : _vm._e()]), _c('label', {
    staticClass: "card-input-label"
  }, [_c('span', [_vm._v("Password")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.data.confirmPassword,
      expression: "data.confirmPassword"
    }, {
      name: "validate",
      rawName: "v-validate",
      value: 'min:6|max:32|required',
      expression: "'min:6|max:32|required'"
    }],
    "class": _vm.errors.has('confirmPassword') ? 'card-input-field-error' : '',
    attrs: {
      "type": "password",
      "name": "confirmPassword",
      "pattern": "\\d*",
      "minlength": "6",
      "placeholder": "Repeat password"
    },
    domProps: {
      "value": _vm.data.confirmPassword
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.data, "confirmPassword", $event.target.value);
      }
    }
  }), _vm.errors.has('confirmPassword') ? _c('div', {
    staticClass: "card-input-error"
  }, [_vm._v(" " + _vm._s(_vm.errors.first('confirmPassword')) + " ")]) : _vm._e()]), _vm.passwordsMatchError ? _c('div', {
    staticClass: "match-error"
  }, [_vm._v(" " + _vm._s(_vm.passwordsMatchError) + " ")]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }