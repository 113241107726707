var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    attrs: {
      "id": _vm.modalName,
      "centered": "",
      "hide-footer": "",
      "hide-header": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": function hidden($event) {
        return _vm.next();
      }
    }
  }, [_vm.currentComponent ? _c(_vm.currentComponent, _vm._b({
    tag: "component"
  }, 'component', _vm.currentProperties, false)) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }