<template>
	<div class="odd-form">
		<b-form>
			<b-row>
				<b-col>
					<h2>Update your company questionnaire</h2>
				</b-col>
			</b-row>

			<b-form-group>
				<questionnaire-business-activities 
					v-model="formData.businessActivities"
					odd
				>
					<template #label>
						<label for="business_activity">
							Main business activity
						</label>
					</template>
					<template #other-label>
						<label for="business_activity_other">
							Please specify other activities
						</label>
					</template>
				</questionnaire-business-activities>
			</b-form-group>

			<b-form-group>
				<verify-account-info-use 
					v-model="formData.accountInfoUse"
					full-width
					dropdown
				>
					<template #label>
						<label>Use of account</label>
					</template>
					<template #other-label>
						<label>
							Other use of account
						</label>
					</template>
				</verify-account-info-use>
			</b-form-group>
			<b-row>
				<b-col>
					<form-error-field v-for="(field, index) in commentFields"
						:key="'comment-' + index"
						:name="field.value"
						:label="field.label"
						:label-for="formatFieldId(field.name)"
					>
						<b-form-textarea :id="formatFieldId(field.name)"
							v-model="formData[field.value]"
							v-validate="'required|max:10000'"
							rows="2"
							max-rows="10"
							type="text"
							:name="field.value"
							:data-vv-as="field.name"
							:placeholder="field.name"
						/>
					</form-error-field>
				</b-col>
			</b-row>
			<b-form-group>
				<label>Attach registration document</label>
				<file-upload
					accept=".jpg, .jpeg, .gif, .png, .pdf, .eml"
					@onChange="e => setRegistrationDocument(e)"
				/>
			</b-form-group>
		</b-form>
		<odd-form-footer 
			v-bind="properties"
		/>
	</div>
</template>
<script>
import { mapState } from 'vuex';
import QuestionnaireBusinessActivities from '~/components/LayoutVerification/QuestionnaireBusiness/QuestionnaireBusinessActivities';
import VerifyAccountInfoUse from '~/components/LayoutVerification/VerifyAccountInfo/VerifyAccountInfoUse';
import FileUpload from '~/components/shared/FileUpload';
import OddFormFooter from '../OddFormFooter';
import FormErrorField from '@/shared/Form/FormErrorField';

export default {
	name: 'ODDCompanyQuestionnaire',
	components: {
		OddFormFooter,
		QuestionnaireBusinessActivities,
		VerifyAccountInfoUse,
		FileUpload,
		FormErrorField
	},
	data() {
		return {
			formData: {
				businessActivities: { values: [], otherValue: null },
				accountInfoUse: { values: [], otherValue: null },
				files: []
			}
		};
	},
	computed: {
		...mapState('user', {
			questionnaire: state => state.selected.companyProfile?.questionnaire
		}),
		properties() {
			return {
				...this._props, 
				formData: this.formData
			};
		},
		commentFields() {
			return [
				{ value: 'businessModelComment', name: 'Business Model Comment', label: 'Were there any changes in company\'s business model? If yes, please elaborate:' },
				{ value: 'productsComment', name: 'Products Comment', label: 'Were there any changes in company\'s provided products and services?' },
				{ value: 'licenseComment', name: 'License Comment', label: 'Were there any changes in company\'s licencing (if applicable)? If yes, please elaborate:' },
				{ value: 'regulatorComment', name: 'Regulatory Actions Comment', label: 'Has the company been recently the subject of regulatory warning/fine/investigation?' },
				{ value: 'amlProcedureComment', name: 'AML Procedure Comment', label: 'Were there any changes in company\'s AML procedures (if applicable)? If yes, please elaborate and provide a new AML/KYC policy' },
				{ value: 'auditComment', name: 'Audit Comment', label: 'Has the company been recently audited by internal auditor or external party? If yes, please provide annual report/annual audit report.' },
			];
		},
		fileUploaded() {
			return this.formData.files.length > 0;
		}
	},
	props: {
		onSuccess: {
			type: Function,
			default: () => {},
			required: true
		},
		onFailure: {
			type: Function,
			default: () => {},
		},
		onCancel: {
			type: Function,
			default: () => {}
		},
		submitUrl: {
			type: String,
			default: '',
			required: true
		}
	},
	methods: {
		setRegistrationDocument(file) {
			this.$set(this.formData, 'files', [{
				type: 'registration_document',
				fileName: file[0].name,
				file: file[0].content
			}]);
		},
		formatFieldId(name) {
			return name.toLowerCase().replace(' ', '-');
		}
	},
	mounted() {
		this.formData = {
			...this.formData,
			accountInfoUse: this.questionnaire.accountInfoUse,
			businessActivities: this.questionnaire.businessActivities
		};
	},
	provide() {
		return {
			$validator: this.$validator,
		};
	}
};
</script>
<style lang="scss">
	
</style>