<template>
	<div class="verification-timer text-center w-100 my-4">
		Time Left: <br />
		<strong>{{ timeLeft }}</strong>
		<b-button variant="outline-primary"
			title="Reset timer"
			class="ml-2 refresh-button"
			@click="refresh()"
		>
			<i class="fas fa-sync-alt"
				:class="loading ? 'refresh-loading' : ''"
			/>
		</b-button>
	</div>
</template>
<script>
import moment from 'moment';
import { mapActions } from 'vuex';
export default {
	data() {
		return {
			TOTAL_TIME: (15 * 60 * 1000) - 1000, // 15min,
			expiryTime: null,
			moment,
			currentTime: moment(),
			loading: false,
		};
	},
	computed: {
		timeDifferenceInSeconds() {
			return this.expiryTime ? moment(this.expiryTime.diff(this.currentTime, 'seconds')) : null;
		},
		timeLeft() {
			if (this.timeDifferenceInSeconds && this.timeDifferenceInSeconds > 60) {
				const minutes = moment(this.expiryTime.diff(this.currentTime)).format('m');
				return `${parseInt(minutes) + 1} minutes`;
			} else if (this.timeDifferenceInSeconds && this.timeDifferenceInSeconds > 0) {
				return `${moment(this.expiryTime.diff(this.currentTime)).format('ss')} seconds`;	
			}
			return 'Expired';
		}
	},

	methods: {
		...mapActions('user', ['getUserProfile']),
		...mapActions('auth', ['logout']),
		reset() {
			this.currentTime = moment();
			this.expiryTime = moment(this.currentTime).add(this.TOTAL_TIME, 'milliseconds');
			return;
		},
		async refresh() {
			try {
				this.loading = true;
				await this.getUserProfile();
				this.reset();
				this.loading = false;
			} catch (err) {
				this.onExpire();
			}
		},
		async onExpire() {
			await this.logout();
			return this.$router.replace({ name: 'login' });
		},
	},
	created() {
		setInterval(() => {
			this.currentTime = moment();
			if (this.timeDifferenceInSeconds == 0 && this.expiryTime) {
				this.onExpire();
			}
		}, 1000);
	},
	mounted() {
		this.reset();
	}
};
</script>
<style lang="scss" scoped>
	@keyframes rotate {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
	.verification-timer {
		background: var(--body-bg);
		padding: 10px;
		border-radius: 5px;
		.refresh-button {
			display: inline-flex;
			justify-content: center;
			align-items: center;
			padding: 4px;
			transition: all .3s ease;
			transform: rotate(0deg);
		}
		.refresh-loading {
			animation: rotate 1s infinite linear;
			transition: all .3s ease;
		}
	}

</style>