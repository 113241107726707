<template>
	<div>
		<div class="page-title-container">
			<h2>Product list</h2>
		</div>
		<div class="transactions-full">
			<products />
		</div>
	</div>
</template>

<script>
import Products from './Products.vue';
export default {
	name: 'PaymentRequestTableLayout',
	components: {
		Products
	}
};
</script>
