var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "auth-card"
  }, [_c('h1', {
    staticClass: "auth-title"
  }, [_vm._v(" Registration ")]), _vm.showForm ? _c('register-form') : _vm._e(), _vm.showSuccess ? _c('register-success') : _vm._e()], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }