<template>
	<div>
		<form-error-field :name="name || id + document.type"
			:scope="validationScope"
		>
			<b-form-file
				:id="name || id + document.type"
				v-validate="required && `fileRequired:${(fileData || value || state) ? true : ''}`"
				accept="image/*, .pdf"
				:placeholder="state ? 'Supplied' : 'Choose a file or drop it here...'"
				drop-placeholder="Drop file here..."
				:name="name || id + document.type"
				:disabled="disabled"
				:state="state || (errors.has(
					name || id + document.type,
					validationScope
				) ? false : null)"
				:class="{'is-invalid': errors.has(name || id + document.type, validationScope)}"
				@change="onFileChange"
			/>
		</form-error-field>
	</div>
</template>
<script>
import { mapActions } from 'vuex';
import { fileUploadSizeLimit } from '~/config';
import { Validator } from 'vee-validate';
import FormErrorField from '@/shared/Form/FormErrorField';

export default {
	name: 'DocumentInput',
	components: {
		FormErrorField
	},
	inject: ['$validator'],
	props: {
		document: { 
			type: Object,
			default: function() { return {}; },
			required: true
		},
		state: {
			type: Boolean,
			default: false,
			required: false
		},
		required: {
			type: Boolean,
			default: false
		},
		id: {
			type: String,
			default: ''
		},
		validationScope: {
			type: String,
			default: null
		},
		value: {
			type: [String, Object]
		},
		disabled: {
			type: Boolean,
			default: false
		},
		name: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			fileData: null
		};
	},
	computed: {
		fileValue() {
			return this.document;
		}
	},
	methods: {
		...mapActions('notifications', ['pushError']),
		async onFileChange(e) {
			const files = e.target.files || e.dataTransfer.files;
			if (!files.length) return;
			if (files[0].size > fileUploadSizeLimit) {
				this.pushError(
					'File size is too big. Try to upload a smaller one.'
				);
			}
			const file = files[0];
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = (e) => {
				this.fileData = {
					fileName: file.name,
					type: this.document.type,
					file: e.target.result,
				};
				this.$emit('onChange', this.fileData);
			};

		},
	},
	mounted() {
		Validator.extend('fileRequired', 
			{
				validate(value, args) {
					return {
						valid: !!value || !!args[0],
						data: {
							required: true
						}
					};
				},
				getMessage() {
					return 'This document is required.';
				}
			},
			{
				computesRequired: true
			}
		);
	},
	provide() {
		return {
			$validator: this.$validator
		};
	}
};
</script>
<style lang="scss" scoped>
	.errormsg {
		bottom: -5px !important;
	}
</style>
