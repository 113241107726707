var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "verification-timer text-center w-100 my-4"
  }, [_vm._v(" Time Left: "), _c('br'), _c('strong', [_vm._v(_vm._s(_vm.timeLeft))]), _c('b-button', {
    staticClass: "ml-2 refresh-button",
    attrs: {
      "variant": "outline-primary",
      "title": "Reset timer"
    },
    on: {
      "click": function click($event) {
        return _vm.refresh();
      }
    }
  }, [_c('i', {
    staticClass: "fas fa-sync-alt",
    "class": _vm.loading ? 'refresh-loading' : ''
  })])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }