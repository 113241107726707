export default {
	selected: null,
	list: [],
	permissions: [],
	walletPermissions: [],
	authenticator: {
		type: 'sms',
		list: []
	},
	customerAgreement: {},
	popups: [],
	oddIncomplete: false,
	availableAuthenticatorList: []
};