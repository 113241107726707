<template>
	<b-modal
		v-cloak
		id="export-statements-modal"
		centered
		hide-header
		hide-footer
	>
		<!-- eslint-disable-next-line vue/no-unused-vars -->
		<template #default="{ hide }">
			<h5 class="mb-4">
				Export statement
			</h5>
			<statements />
		</template>
	</b-modal>
</template>
<script>
import Statements from '../Transaction/Statements';

export default {
	components: {
		Statements
	}
};
</script>
