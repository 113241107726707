var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "odd-form-footer py-2 d-flex flex-column justify-content-between"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mt-4 d-flex justify-content-between"
  }, [!_vm.submitHidden ? _c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "primary",
      "disabled": _vm.submitDisabled
    },
    on: {
      "click": function click($event) {
        return _vm.submit();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.submitText) + " ")]) : _vm._e(), !_vm.cancelHidden ? _c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.onCancel
    }
  }, [_vm._v(" " + _vm._s(_vm.cancelText) + " ")]) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }