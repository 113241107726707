var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sidebar-navigation"
  }, [_c('div', {
    staticClass: "sidebar-navigation-link"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'home'
      },
      "active": _vm.$route.matched.some(function (_ref) {
        var name = _ref.name;
        return name === 'home';
      }),
      "exact": ""
    }
  }, [_c('span', {
    staticClass: "mobile-home-icon  mobile-icon"
  }), _vm._v(" Home "), _vm.$route.matched.some(function (_ref2) {
    var name = _ref2.name;
    return name === 'home';
  }) ? _c('i', {
    staticClass: "fas fa-long-arrow-alt-right"
  }) : _vm._e()])], 1), _vm.currentWallet && _vm.currentWallet.status === 'active' && _vm.isWalletGranted('initiate_transaction', _vm.currentWallet.walletId) ? _c('div', {
    staticClass: "sidebar-navigation-link"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'transfer'
      },
      "active": _vm.$route.matched.some(function (_ref3) {
        var name = _ref3.name;
        return name === 'transfer' || name === 'transfer-type';
      })
    }
  }, [_c('span', {
    staticClass: "mobile-transfer-icon mobile-icon"
  }), _c('span', [_vm._v("Transfer")]), _vm.$route.matched.some(function (_ref4) {
    var name = _ref4.name;
    return name === 'transfer' || name === 'transfer-type';
  }) ? _c('i', {
    staticClass: "fas fa-long-arrow-alt-right"
  }) : _vm._e()])], 1) : _vm._e(), _vm.currentWallet ? _c('div', {
    staticClass: "sidebar-navigation-link"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'transactions'
      },
      "active": _vm.$route.matched.some(function (_ref5) {
        var name = _ref5.name;
        return name === 'transactions';
      }),
      "exact": ""
    }
  }, [_c('span', {
    staticClass: "mobile-transactions-icon mobile-icon"
  }), _vm._v(" Transactions "), _vm.$route.matched.some(function (_ref6) {
    var name = _ref6.name;
    return name === 'transactions';
  }) ? _c('i', {
    staticClass: "fas fa-long-arrow-alt-right"
  }) : _vm._e()])], 1) : _vm._e(), _vm.currentWallet && _vm.cardsEnabled ? _c('div', {
    staticClass: "sidebar-navigation-link"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'cards'
      },
      "active": _vm.$route.matched.some(function (_ref7) {
        var path = _ref7.path;
        return path.includes('/cards');
      }),
      "exact": ""
    }
  }, [_c('span', {
    staticClass: "mobile-icon"
  }, [_c('ion-icon', {
    attrs: {
      "name": "card-outline"
    }
  })], 1), _c('span', {
    staticStyle: {
      "position": "relative"
    }
  }, [_vm._v("Cards "), _vm.waitingCardsUpdate ? _c('span', {
    staticClass: "sidebar-navigation__notification"
  }, [_vm._v("!")]) : _vm._e()]), _c('i', {
    staticClass: "fas fa-long-arrow-alt-right"
  })])], 1) : _vm._e(), _vm.paymentRequestEnabled ? _c('div', {
    staticClass: "sidebar-navigation-link"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'payment-requests'
      },
      "active": _vm.$route.matched.some(function (_ref8) {
        var path = _ref8.path;
        return path.includes('/payment-requests');
      }),
      "exact": ""
    }
  }, [_c('span', {
    staticClass: "mobile-icon"
  }, [_c('ion-icon', {
    attrs: {
      "name": "card-outline"
    }
  })], 1), _c('span', {
    staticStyle: {
      "position": "relative"
    }
  }, [_vm._v("Payment request")]), _c('i', {
    staticClass: "fas fa-long-arrow-alt-right"
  })])], 1) : _vm._e(), _vm.paymentRequestCreateEnabled ? _c('div', {
    staticClass: "sidebar-navigation-link"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'payment-request-create'
      },
      "active": _vm.$route.matched.some(function (_ref9) {
        var name = _ref9.name;
        return name === 'payment-request-create';
      })
    }
  }, [_c('span', {
    staticClass: "mobile-transfer-icon mobile-icon"
  }), _c('span', [_vm._v("Payment request create")]), _vm.$route.matched.some(function (_ref10) {
    var name = _ref10.name;
    return name === 'payment-request-create';
  }) ? _c('i', {
    staticClass: "fas fa-long-arrow-alt-right"
  }) : _vm._e()])], 1) : _vm._e(), _vm.productEnabled ? _c('div', {
    staticClass: "sidebar-navigation-link"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'products'
      },
      "active": _vm.$route.matched.some(function (_ref11) {
        var path = _ref11.path;
        return path.includes('/products');
      }),
      "exact": ""
    }
  }, [_c('span', {
    staticClass: "mobile-icon"
  }, [_c('ion-icon', {
    attrs: {
      "name": "card-outline"
    }
  })], 1), _c('span', {
    staticStyle: {
      "position": "relative"
    }
  }, [_vm._v("Products")]), _c('i', {
    staticClass: "fas fa-long-arrow-alt-right"
  })])], 1) : _vm._e(), _vm.productCreateEnabled ? _c('div', {
    staticClass: "sidebar-navigation-link"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'product-create'
      },
      "active": _vm.$route.matched.some(function (_ref12) {
        var name = _ref12.name;
        return name === 'product-create';
      })
    }
  }, [_c('span', {
    staticClass: "mobile-transfer-icon mobile-icon"
  }), _c('span', [_vm._v("Product create")]), _vm.$route.matched.some(function (_ref13) {
    var name = _ref13.name;
    return name === 'product-create';
  }) ? _c('i', {
    staticClass: "fas fa-long-arrow-alt-right"
  }) : _vm._e()])], 1) : _vm._e(), _vm.referralEnabled ? _c('div', {
    staticClass: "sidebar-navigation-link"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'referrals'
      },
      "active": _vm.$route.matched.some(function (_ref14) {
        var name = _ref14.name;
        return name === 'referrals';
      })
    }
  }, [_c('span', {
    staticClass: "mobile-icon"
  }, [_c('ion-icon', {
    attrs: {
      "name": "people-circle-outline"
    }
  })], 1), _c('span', {
    directives: [{
      name: "new-badge",
      rawName: "v-new-badge",
      value: {
        expire: '2022-03-02',
        id: 'referral'
      },
      expression: "{expire: '2022-03-02', id: 'referral'}"
    }]
  }, [_vm._v("Referral "), _c('span', {
    staticClass: "d-none d-sm-inline-block"
  }, [_vm._v("Program")])]), _vm.$route.matched.some(function (_ref15) {
    var name = _ref15.name;
    return name === 'referrals';
  }) ? _c('i', {
    staticClass: "fas fa-long-arrow-alt-right"
  }) : _vm._e()])], 1) : _vm._e(), _c('div', {
    staticClass: "sidebar-navigation-link"
  }, [_vm.WHITELABEL !== 'verifo' ? _c('router-link', {
    attrs: {
      "to": {
        name: 'faq'
      },
      "active": _vm.$route.matched.some(function (_ref16) {
        var name = _ref16.name;
        return name === 'faq';
      })
    }
  }, [_c('span', {
    staticClass: "mobile-faq-icon mobile-icon"
  }), _vm._v(" FAQ "), _vm.$route.matched.some(function (_ref17) {
    var name = _ref17.name;
    return name === 'faq';
  }) ? _c('i', {
    staticClass: "fas fa-long-arrow-alt-right"
  }) : _vm._e()]) : _vm._e(), _vm.WHITELABEL == 'verifo' ? _c('a', {
    attrs: {
      "href": "https://verifo.zendesk.com/hc/en-us",
      "target": "_blank"
    }
  }, [_c('span', {
    staticClass: "mobile-faq-icon mobile-icon"
  }), _vm._v(" FAQ ")]) : _vm._e()], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }