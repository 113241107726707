<template>
	<div>
		<b-row>
			<b-col>
				<slot name="label"></slot>
			</b-col>
		</b-row>
		<b-row v-if="!dropdown"
			align-v="stretch"
		>
			<b-col
				v-for="option in options"
				:key="option.value"
				:sm="fullWidth ? 12 : 6"
				cols="12"
				class="account-info-option"
				data-registration="use-of-account"
				@click="setAccountInfoUse(option.value)"
			>
				<div
					class="account-info-option__box"
					:class="{'account-info-option__box--active' : innerValue?.values.includes(option.value)}"
				>
					<div class="account-info-option__text">
						<h3>
							<span class="radio"
								:class="{'radio--check' : multiple}"
							>
								<i class="fas fa-check"></i>
							</span>{{ option.name }}
						</h3>
						<p v-html="option.description"></p>
					</div>
				</div>
			</b-col>
		</b-row>
		<b-row v-if="dropdown">
			<b-col>
				<form-error-field name="accountInfoUse.values">
					<v-multiselect
						v-model="innerValue.values"
						v-validate="'required'"
						:options="options"
						:multiple="true"
						:allow-empty="true"
						:close-on-select="false"
						:clear-on-select="true"
						data-vv-as="Use of account"
						name="accountInfoUse.values"
						label="name"
						placeholder="Select Your use of account"
						track-by="name"
						return="value"
						:max-height="9999"
					/>
				</form-error-field>
			</b-col>
		</b-row>
		<b-row>
			<b-col v-if="innerValue.values.includes('other')">
				<form-error-field name="accountInfoUse.otherValue">
					<slot name="other-label"></slot>
					<b-input
						v-model="innerValue.otherValue"
						v-validate="'required'"
						type="text"
						placeholder="Specify other use of account"
						data-vv-as="Other use of account"
						name="accountInfoUse.otherValue"
					/>
				</form-error-field>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { accountInfoUseOptions } from './VerifyAccountInfoOptions';
import FormErrorField from '@/shared/Form/FormErrorField';

export default {
	name: 'VerifyAccountInfoUse',
	inject: ['$validator'],
	components: {
		FormErrorField
	},
	computed: {
		...mapState('user', {
			details: (state) => state.selected,
		}),
		options() {
			return accountInfoUseOptions;
		},
		innerValue() {
			return this.value;
		}
	},
	props: {
		value: {
			type: Object,
			required: true
		},
		multiple: {
			type: Boolean,
			default: true
		},
		dropdown: {
			type: Boolean,
			default: false
		},
		fullWidth: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		setAccountInfoUse(value) {
			if (this.details.verification == 'pending') return;
			if (this.multiple) {
				if (this.innerValue.values.includes(value)) {
					this.innerValue.values.splice(this.innerValue.values.indexOf(value), 1);
				} else {
					this.innerValue.values.push(value);
				}
			} else {
				this.$set(this.innerValue, 'values', [value]);
			}
		}
	}
};
</script>
<style lang="scss">
	
</style>