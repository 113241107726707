import LoginInfo from '@/LayoutAuthentication/Register/LoginInfo.vue';
import IdenfyUnauthenticated from '@/LayoutAuthentication/Idenfy/IdenfyUnauthenticated';

export default [
	{
		path: 'login-info/:hash',
		name: 'login-info',
		component: LoginInfo,
		props: true,
		meta: {
			requiresGuest: true
		}
	},
	{
		path: 'identification/:hash',
		name: 'idenfy-unauthenticated',
		component: IdenfyUnauthenticated,
		props: true,
		meta: {
			requiresGuest: true
		}
	}
];