<template>
	<div>
		<b-row>
			<b-col>
				<span class="card-order__subtitle">Personal Details</span>
			</b-col>
		</b-row>
		<b-row>
			<b-col class="card-order__account-info">
				<b-row>
					<b-col md="3">
						Legal Name:
					</b-col>
					<b-col md="9">
						{{ owner.name }} {{ owner.surname }}
					</b-col>
				</b-row>
				<b-row>
					<b-col md="3">
						Email:
					</b-col>
					<b-col md="3">
						{{ owner.email }}
					</b-col>
				</b-row>
				<b-row>
					<b-col md="3">
						Phone number:
					</b-col>
					<b-col md="9">
						{{ owner.phone }}
					</b-col>
				</b-row>
				<div>
					<b-row>
						<b-col class="card-order__details-note">
							You will receive your authentication codes to this phone number.
						</b-col>
					</b-row>
				</div>
			</b-col>
		</b-row>
	</div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
export default {
	name: 'CardPersonalDetails',
	computed: {
		...mapState('user', {
			user: state => state.selected
		}),
		...mapGetters('user', [
			'owner'
		]),
		...mapGetters('wallet', [
			'currentWallet'
		])
	},
	methods: {
	}
};
</script>
<style lang="scss">
	.card-order {
		&__account-info {
			margin-bottom: 50px;
			> .row {
				padding: 10px 0;

				> div {
					border-bottom: 1px solid rgba(0, 0, 0, .03);

					&:first-child {
						font-size: 13px;
						color: #6B7280;
						font-weight: normal;
					}

					font-size: 16px;
					color: #212529;
					font-weight: 500;
				}
			}
		}
		
		&__subtitle {
			color: #6B7280;
			font-weight: 600;
			margin-bottom: 22px;
			display: inline-block;
		}

		&__details-note {
			font-size: 14px;
		}
	}
</style>