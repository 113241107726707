<template>
	<div class="nano-transfer">
		<b-row v-show="currentStep == 1">
			<b-col
				xl="7"
				cols="12"
				class="mb-3"
			>
				<b-card>
					<payment-request-form
						ref="transferForm"
						:form-fields="formFields"
						:loading="loading"
						data-vv-scope="form"
						@change="formChange"
						@onBlur="onBlur"
					/>
				</b-card>
			</b-col>
			<b-col
				xl="5"
				cols="12"
				class="mb-3"
			>
				<b-card>
					<b-row
						class="mt-2"
					>
						<b-col>
							<span
								class="notes"
								v-html="notes"
							/>
						</b-col>
					</b-row>
				</b-card>

				<b-row class="mt-3">
					<b-col>
						<button
							class="btn btn-primary btn-block"
							@click="startTransfer"
						>
							{{ loading === true ? 'Loading...' : 'Next' }}
						</button>
					</b-col>
				</b-row>
			</b-col>
		</b-row>
		<div
			v-if="currentStep === 3"
			class="justify-content-md-center"
		>
			<div class="text-center py-4">
				<h3 class="text-success mb-4">
					Your payment request <b>{{ transactionId }}</b> has been sent
				</h3>
				<hr />
				Checkout url:<br /><br /> {{ redirectUrl }} <br /><br />
				<router-link
					:to="{ name: 'payment-requests' }"
					class="btn btn-outline-dark px-5"
					exact
				>
					Check payment requests
				</router-link>
			</div>
		</div>
	</div>
</template>
<script>
import PaymentRequestForm from './PaymentRequestForm';
import fields from './FieldsData/fields';
import debounce from 'lodash/debounce';
import { endpointsGateway } from '~/config';
import { mapActions, mapGetters } from 'vuex';

export default {
	name: 'PaymentRequest',
	components: {
		PaymentRequestForm,
	},
	props: {
		transferData: {
			type: Object,
			default: () => ({}),
			required: false
		},
	},
	data() {
		return {
			formFields: [],
			formData: {},
			loading: false,
			notes: 'Payment request, can be confirmed before money reach account. Money can reach account up two 2 working days. <b>On weekends money not reach account.</b>',
			currentStep: 1,
			transactionId: null,
			redirectUrl: null,
		};
	},
	computed: {
		...mapGetters('user', [
			'isGranted',
		]),
	},
	methods: {
		...mapActions('notifications', ['pushError']),
		formChange(value) {
			this.formData = { ...value };
		},
		handleBack() {
			this.currentStep = this.currentStep - 1;
		},
		async submit() {
			const url = endpointsGateway.paymentRequests.create({ productId: this.formData.productId });

			const options = {
				method: 'POST',
				url,
				data: {
					amount: this.formData.amount,
					currencyId: this.formData.currencyId,
					details: this.formData.details,
				}
			};
			try {
				const response = await axios(options);
				const { data } = response;
				this.transactionId = data.id;
				this.redirectUrl = data.redirectUrl;
				this.handleSuccess();
			} catch (err) {
				this.pushError(err.message);
			}
		},
		handleSuccess() {
			this.currentStep = 3;
		},
		getDebounceAmount: debounce(function () {
			!!this.formData.amount && this.updateFormValue('amount', parseFloat(this.formData.amount).toFixed(2));
		}, 200),
		async startTransfer() {
			const result = await this.$validator.validate('*');
			if (result) {
				this.submit();
			}
		},
		onBlur(name) {
			if (name == 'amount') this.getDebounceAmount();
		},
		updateFormValue(name, value, fields) {
			this.$refs.transferForm.updateFormValue(name, value, fields);
		},
		setLockState(value) {
			if (this.$refs.transferForm) this.$refs.transferForm.lockState = value;
		},
		setFieldProperty(name, key, value) {
			this.formFields = this.formFields.map(f => {
				if (f.name == name) f[key] = value;
				return f;
			});
		}
	},
	mounted() {
		this.formFields = fields;
	},
};
</script>
<style lang="scss">
.nano-transfer {
	&__notification {
		background-color: #e3f3f4;
		padding: 16px 20px;
		display: flex;
		align-items: center;
		margin-top: 20px;
		border-radius: 3px;
		justify-content: space-between;

		> span {
			display: flex;
			align-items: center;
		}

		ion-icon {
			color: $primary;
			margin-right: 10px;
		}
	}
}
</style>