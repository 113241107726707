<template>
	<div>
		<div class="page-title-container">
			<h2>Payment Request History</h2>
		</div>
		<div class="transactions-full">
			<payment-request />
		</div>
	</div>
</template>

<script>
import PaymentRequest from './PaymentRequest.vue';
export default {
	name: 'PaymentRequestTableLayout',
	components: {
		PaymentRequest
	}
};
</script>
