<template>
	<form data-vv-scope="person"
		class="person-input-form"
	>
		<b-row v-if="allowtypes">
			<b-col>
				<b-form-group>
					<label :for="id + 'personType'">
						Type
					</label>
					<b-input-group class="text-left mb-12">
						<v-multiselect
							v-model="person.entityType"
							:options="typeOptions"
							:show-labels="false"
							:allow-empty="false"
							:name="id + 'personType'"
							label="name"
							placeholder="Select type"
							track-by="name"
							return="value"
							:disabled="isNotCompany"
							data-person="entity-type"
						/>
					</b-input-group>
				</b-form-group>
			</b-col>
		</b-row>

		<div>
			<b-row>
				<b-col :sm="person.entityType !== 'person' || fullWidth ? 12 : 6">
					<form-error-field :name="fieldName('name')"
						scope="person"
					>
						<label :for="fieldName('name')">
							{{ person.entityType === 'person' ? 'Name (and middle names)' : 'Company Name' }}
						</label>
						<b-form-input
							:id="fieldName('name')"
							v-model="person.name"
							v-validate="'required'"
							:name="fieldName('name')"
							type="text"
							autocomplete="off_autocomplete"
							placeholder="Enter name"
							data-person="name"
							data-vv-as="Name"
						/>
					</form-error-field>
				</b-col>

				<b-col
					v-if="person.entityType === 'person'"
					:sm="fullWidth ? 12 : 6"
				>
					<form-error-field :name="fieldName('surname')"
						scope="person"
					>
						<label :for="fieldName('surname')">
							Last name
						</label>
						<b-form-input
							:id="fieldName('surname')"
							v-model="person.surname"
							v-validate="'required'"
							:name="fieldName('surname')"
							type="text"
							autocomplete="off_autocomplete"
							placeholder="Enter last name"
							debounce="500"
							data-person="last-name"
							data-vv-as="Last name"
						/>
					</form-error-field>
				</b-col>
			</b-row>

			<b-row v-if="person.entityType === 'company'">
				<b-col>
					<form-error-field :name="fieldName('registrationCode')"
						scope="person"
					>
						<p class="label">
							Legal entity code
						</p>
						<b-form-input
							v-model="person.registrationCode"
							v-validate="'required'"
							:name="fieldName('registrationCode')"
							type="text"
							autocomplete="off_autocomplete"
							placeholder="Enter legal entity code"
							debounce="500"
							data-person="legal-entity-code"
							:class="{'is-invalid': errors.has(id + 'registrationCode', 'person')}"
							data-vv-as="Legal entity code"
						/>
					</form-error-field>
				</b-col>
			</b-row>
			<b-row v-if="person.entityType === 'person'">
				<b-col>
					<form-error-field :name="fieldName('placeOfBirth')"
						scope="person"
					>
						<label class="label">Place of birth</label>
						<v-multiselect
							v-model="person.placeOfBirth"
							v-validate="'required'"
							:options="countryOptions"
							:show-labels="false"
							:allow-empty="false"
							:name="fieldName('placeOfBirth')"
							label="name"
							placeholder="Select country of birth"
							track-by="countryId"
							return="countryId"
							data-person="place-of-birth"
							:class="{'is-invalid': errors.has(id + 'place_of_birth', 'person')}"
							data-vv-as="Place of birth"
						/>
					</form-error-field>
				</b-col>
			</b-row>
			<b-row
				v-if="person.entityType === 'person'"
				class="mb-3"
			>
				<b-col>
					<div
						:class="{
							'has-error': errors.has( id + 'us_citizen', 'person'),
						}"
						class="custom-control custom-checkbox"
					>
						<input
							:id="id + 'us_citizen'"
							v-model="person.isUsCitizen"
							type="checkbox"
							:name="id + 'us_citizen'"
							autocomplete="off"
							class="custom-control-input"
							data-person="us-citizen"
						/>
						<label
							:for=" id + 'us_citizen'"
							class="custom-control-label"
						>This person is US citizen</label>
					</div>
				</b-col>
			</b-row>
			<b-row v-if="person.entityType === 'person'">
				<b-col>
					<form-error-field :name="fieldName('dateOfBirth')"
						scope="person"
					>
						<label :for="fieldName('dateOfBirth')">
							Date of birth
						</label>
						<app-datepicker
							v-model="person.dateOfBirth"
							v-validate="'required'"
							:adult="true"
							:name="fieldName('dateOfBirth')"
							data-person="date-of-birth"
							data-vv-as="Date of birth"
						/>
					</form-error-field>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<div
						:class="{'input-invalid' : errors.has(id + 'phone', 'person')}"
					>
						<div class="form-group mb-0">
							<label>
								Phone number <i
									class="fa fa-question-circle-o pull-right"
									aria-hidden="true"
									@click.prevent="$modal.show('phone')"
								></i>
							</label>
						</div>
						<phone-input
							v-model="person.phone"
							scope="person"
							data-person="phone"
							hide-label
						/>
					</div>
				</b-col>
			</b-row>

			<b-row>
				<b-col>
					<form-error-field :name="fieldName('email')"
						scope="person"
					>
						<label :for="id + 'email'">
							Email
						</label>
						<b-form-input
							id="email"
							v-model="person.email"
							v-validate="'email|required'"
							:name="fieldName('email')"
							type="text"
							placeholder="Enter your email"
							debounce="500"
							data-person="email"
							:class="{'is-invalid': errors.has(id + 'email', 'person')}"
							data-vv-as="Email"
						/>
					</form-error-field>
				</b-col>
			</b-row>
			<b-row v-if="person.entityType === 'person'">
				<b-col>
					<form-error-field :name="fieldName('politicalExposure')"
						scope="person"
						class="text-left mb-12"
					>
						<label>
							Political exposure <i
								class="fa fa-question-circle-o pull-right"
								aria-hidden="true"
								@click.prevent="$modal.show('political_exposure')"
							></i>
						</label>
						
						<v-multiselect
							v-model="person.politicalExposure"
							v-validate="'required'"
							:name="fieldName('politicalExposure')"
							:options="politicalOptions"
							:show-labels="false"
							label="name"
							placeholder="Select political exposure"
							track-by="name"
							return="value"
							data-person="political-exposure"
							:class="{'is-invalid': errors.has(id + 'political_exposure', 'person')}"
							data-vv-as="Political exposure"
						/>
					</form-error-field>
				</b-col>
			</b-row>



			<b-row v-if="person.entityType === 'person'">
				<b-col>
					<form-error-field :name="fieldName('countryForTax')"
						scope="person"
						class="text-left mb-12"
					>
						<label :for="id + 'country_for_tax_purpose'">
							Country of residence for tax purpose
						</label>
						<v-multiselect
							v-model="person.countryForTax"
							v-validate="'required'"
							:options="countryOptions"
							:show-labels="false"
							:allow-empty="false"
							:name="fieldName('countryForTax')"
							label="name"
							placeholder="Select country for tax purpose"
							track-by="countryId"
							return="countryId"
							data-person="tax-country"
							:class="{'is-invalid': errors.has(id + 'country_for_tax_purpose', 'person')}"
							data-vv-as="Country of residence for tax purpose"
						/>
					</form-error-field>
				</b-col>
			</b-row>
			<multi-address :addresses="person.addresses"
				scope="person"
				:label="person.entityType === 'person' ? 'Residential address' : 'Registration address'"
				:field-prefix="prefix"
			/>
			<div v-if="proofOfResidence && person.entityType !== 'company'"
				class="form-group"
			>
				<b-row
					v-for="doc in documents"
					v-show="doc.displayName"
					:key="doc.type"
				>
					<b-col>
						<label>
							{{ doc.displayName }} <small>({{ doc.notes }})</small>
						</label>
						<document-input
							:id="`${id}`"
							v-model="person[doc.field]"
							:document="doc"
							:required="true"
							:name="fieldName('proofOfResidenceDocument')"
							:validation-scope="'person'"
							:data-person="`document-${doc.field}`"
							:data-vv-as="doc.displayName"
							@onChange="e => onDocumentChange(e, doc)"
						/>
					</b-col>
				</b-row>
			</div>
		</div>
	</form>
</template>
<script>
import PhoneInput from '@/shared/PhoneInput';
import AppDatepicker from '@/shared/AppDatepicker';
import DocumentInput from '@/shared/DocumentInput';
import { mapActions, mapState } from 'vuex';
import MultiAddress from '@/shared/MultiAddress';
import FormErrorField from '@/shared/Form/FormErrorField';

const typeOptions = [
	{ value: 'person',  name: 'Person'  },
	{ value: 'company',  name: 'Company'  }
];
export default {
	name: 'PersonInput',
	components: { PhoneInput, AppDatepicker, DocumentInput, MultiAddress, FormErrorField },
	inject: ['$validator'],
	props: {
		value: {
			type: Object,
			default: () => {}
		},
		iAm: {
			type: Boolean,
			default: true
		},
		allowtypes: {
			type: Boolean,
			default: false
		},
		proofOfResidence: {
			type: Boolean,
			default: false
		},
		personTypeOptions: {
			type: Array,
			default: function() {return [];}
		},
		fullWidth: {
			type: Boolean,
			default: false
		},
		forcePerson: {
			type: Boolean,
			default: false
		},
		entityType: {
			type: String,
			default: null,
			validator(value) {
				return typeOptions.map(o => o.value).includes(value);
			}
		},
		fieldPrefix: {
			type: String,
			default: 'person'
		}
	},
	data() {
		return {
			documents: [
				{
					displayName: 'Proof of residence',
					notes: 'Not older than 3 months',
					field: 'proofOfResidenceDocument',
					type: 'proof_of_address_document',
					required: true,
				},
			],
			politicalOptions: [
				{ value: 'none',  name: (this.iAm ? 'I am n' : 'N') + 'ot a politically exposed person'  },
				{ value: 'exposed',  name:  (this.iAm ? 'I am a' : 'A') + ' politically exposed person'  },
				{ value: 'family_member',  name:  (this.iAm ? 'I am i' : 'I') + 'mmediate family member of a politically exposed person'  },
				{ value: 'associate',  name:  (this.iAm ? 'I am a' : 'A') + ' close associate of a politically exposed person'  }
			],
			typeOptions,
			valid: false,
			id: Date.now(), //unique number to generate unique html elements
		};
	},
	computed: {
		...mapState('countries', {
			countryOptions: state => state.list
		}),
		isNotCompany() {
			const p = this.personTypeOptions;
			return p && (p.includes('director') || p.includes('ubo') || p.includes('representative'));
		},
		person: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('update:value', value);
			}
		},
		prefix() {
			return typeof this.value.index !== 'undefined' ? `${this.fieldPrefix}[${this.value.index}]` : '';
		}
	},
	watch: {
		person: {
			handler(value) {
				this.errors.clear();
				if (this.person.email) {
					this.$set(this.person, 'email', value.email && value.email.toLowerCase().replace(/\s/g, ''));
				}
			},
			deep: true
		},
		personTypeOptions: {
			handler(value) {
				if (value && value.length > 0) {
					this.$set(this.person, 'entityType', 'person');
				}
			},
			deep: true
		}
	},

	async mounted() {
		if (this.countryOptions.length == 0) {
			await this.getCountries();
		}
		if (!this.entityType) {
			if(!this.value.entityType || this.value.type == 'director') {
				this.$set(this.person, 'entityType', 'person');
			}
			if (!this.value.surname && !this.value.dateOfBirth && this.value.name) {
				this.$set(this.person, 'entityType', 'company');
			}
		} else {
			this.$set(this.person, 'entityType', this.entityType || 'person');
		}

		if (!this.person.addresses) {
			this.$set(this.person, 'addresses', { main: null, correspondence: null });
		}

		this.$nextTick(() => {
			this.errors.clear();
		});
	},
	methods: {
		...mapActions('countries', ['getCountries']),
		onDocumentChange(value, document) {
			this.$set(this.person, document.field, value);
		},
		fieldName(fieldName) {
			if (typeof this.value.index == 'undefined') {
				return this.id + fieldName;
			} else {
				return `${this.fieldPrefix}[${this.value.index}].${fieldName}`;
			}
		}
	},
	provide() {
		return {
			$validator: this.$validator,
		};
	}
};
</script>

<style lang="scss" scoped>
.label {
	i {
		cursor: pointer;
	}
}
</style>