var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "margintop30"
  }, [_c('div', {
    staticClass: "max440 "
  }, [_c('div', {
    staticClass: "check"
  }), _c('div', {
    staticClass: "textblock  lightext"
  }, [_vm._v(" Confirmation link has been sent to ")]), _c('p', {
    staticClass: "textblock  boldtext"
  }, [_vm._v(" " + _vm._s(_vm.email) + " "), _c('br'), _vm._v(" Click the link to confirm your email and continue registration. ")]), _c('div', {
    staticClass: "textblock margintop30 font15"
  }, [_c('p', [_vm._v(" If you haven't received the confirmation link within 5 minutes, please check your spam box or "), _c('a', {
    staticClass: "framelink bluelink",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.initResend.apply(null, arguments);
      }
    }
  }, [_vm._v("click here")]), _vm._v(" to receive a new link. ")])]), _c('div', {
    staticClass: "textblock margintop30 font15"
  }, [_c('p', [_vm._v(" Verification link is valid for "), _c('b', [_vm._v("15 minutes")]), _vm._v(". If link has expired, you will need to receive a new link, by "), _c('a', {
    staticClass: "framelink bluelink",
    attrs: {
      "href": ""
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.initResend.apply(null, arguments);
      }
    }
  }, [_vm._v("clicking here")]), _vm._v(". ")]), _c('br'), _vm.startsAt !== null && _vm.endsAt !== null ? _c('vue-countdown-timer', {
    attrs: {
      "start-time": _vm.startsAt,
      "end-time": _vm.endsAt,
      "interval": 1000,
      "start-label": 'Until start',
      "end-label": 'Time left',
      "end-text": 'Confirmation link expired!',
      "day-txt": ' days',
      "hour-txt": ' hours',
      "minutes-txt": ' minutes ',
      "seconds-txt": ' seconds',
      "label-position": "begin"
    },
    on: {
      "start_callback": function start_callback($event) {
        return _vm.startCallBack();
      },
      "end_callback": function end_callback($event) {
        return _vm.endCallBack();
      }
    },
    scopedSlots: _vm._u([{
      key: "countdown",
      fn: function fn(scope) {
        return [_c('b', [_c('span', [_vm._v(_vm._s(scope.props.minutes) + _vm._s(scope.props.minutesTxt))]), _c('span', [_vm._v(_vm._s(scope.props.seconds) + _vm._s(scope.props.secondsTxt))])])];
      }
    }], null, false, 1603354203)
  }) : _vm._e()], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.resent,
      expression: "resent"
    }],
    staticClass: "confirm margintop30"
  }, [_c('span', [_vm._v("Confirmation email re-sent")])]), _c('notification', {
    attrs: {
      "align": "center"
    }
  }), _c('div', {
    staticClass: "textblock margintop30 font15"
  }, [_c('p', [_vm._v(" Have you entered a wrong email address? "), _c('a', {
    staticClass: "framelink bluelink",
    attrs: {
      "href": ""
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.backToRegistrationForm();
      }
    }
  }, [_vm._v("Change it")])])])], 1), _c('vue-recaptcha', {
    ref: "recaptcha",
    attrs: {
      "id": "recaptcha",
      "sitekey": _vm.recapthaSitekey,
      "load-recaptcha-script": true,
      "size": "invisible"
    },
    on: {
      "verify": _vm.resendRegistrationEmail
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }