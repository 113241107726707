<template>
	<div>
		<b-row>
			<b-col>
				<form-error-field :name="fieldNames.countryId"
					:scope="scope"
				>
					<label class="label">{{ countryName }}</label>
					<v-multiselect
						v-model="selectedCountryId"
						v-validate="'required'"
						:name="fieldNames.countryId"
						:options="countryOptions"
						:show-labels="false"
						:allow-empty="false"
						label="name"
						placeholder="Select Your Country"
						track-by="countryId"
						return="countryId"
						data-address="country"
						:disabled="disabled"
						:class="{'is-invalid': errors.has(fieldNames.countryId, scope)}"
					/>
				</form-error-field>
			</b-col>
		</b-row>
		<b-row>
			<b-col>
				<form-error-field :name="id + '_autocomplete'"
					:scope="scope"
				>
					<label class="label">Address</label>
					<b-form-input
						:id="id + '_autocomplete'"
						ref="autocomplete"
						v-model="address.address"
						v-validate="'required|max:255'"
						data-vv-as="Address"
						:name="id + '_autocomplete'"
						:placeholder="!value?.countryId ? 'Select Your Country First' : 'Enter Your Address'"
						:disabled="!value?.countryId || disabled"
						:country="value?.countryId"
						type="text"
						autocomplete="off_autocomplete"
						debounce="500"
						data-address="address"
						:class="{'is-invalid': errors.has(id + '_autocomplete', scope)}"
						@keyup="showAddressFields"
						@change="addressChange('address')"
					/>
				</form-error-field>
			</b-col>
		</b-row>
		<div v-show="manual">
			<b-row>
				<b-col>
					<form-error-field :name="fieldNames.street"
						:scope="scope"
					>
						<label class="label">Street name</label>
						<b-form-input
							v-model="address.street"
							v-validate="'required|max:50'"
							data-vv-validate-on="blur"
							:name="fieldNames.street"
							type="text"
							class="form-control"
							autocomplete="off_street"
							debounce="500"
							data-address="street"
							data-vv-as="Street name"
							:disabled="disabled"
							:class="{'is-invalid': errors.has(fieldNames.street, scope)}"
							@change="addressChange('street')"
						/>
					</form-error-field>
				</b-col>
			</b-row>
			<b-row>
				<b-col
					cols="6"
					class="pr-0"
				>
					<form-error-field :name="fieldNames.streetNo"
						:scope="scope"
					>
						<label class="label">House number</label>
						<b-form-input
							v-model="address.streetNo"
							v-validate="'required|max:50'"
							:name="fieldNames.streetNo"
							type="text"
							autocomplete="off_street_no"
							debounce="500"
							data-address="house"
							data-vv-as="House number"
							:disabled="disabled"
							:class="{'is-invalid': errors.has(fieldNames.streetNo, scope)}"
							@change="addressChange('street_no')"
						/>
					</form-error-field>
				</b-col>
				<b-col cols="6">
					<form-error-field :name="fieldNames.flatNo"
						:scope="scope"
					>
						<label class="label">Flat number</label>
						<b-form-input
							v-model="address.flatNo"
							v-validate="'max:50'"
							:name="fieldNames.flatNo"
							type="text"
							class="form-control"
							autocomplete="off_flat_no"
							debounce="500"
							data-address="flat"
							data-vv-as="Flat number"
							:class="{'is-invalid': errors.has(fieldNames.flatNo, scope)}"
							:disabled="disabled"
							@change="addressChange('flat_no')"
						/>
					</form-error-field>
				</b-col>
			</b-row>
			<b-row>
				<b-col
					cols="4"
					lg="3"
					xl="2"
					class="pr-0"
				>
					<form-error-field :name="fieldNames.zipCode"
						:scope="scope"
					>
						<label class="label">ZIP</label>
						<b-form-input
							v-model="address.zipCode"
							v-validate="'required|max:50'"
							:name="fieldNames.zipCode"
							type="text"
							class="form-control"
							autocomplete="off_zip"
							debounce="500"
							data-address="zip"
							data-vv-as="ZIP"
							:class="{'is-invalid': errors.has(fieldNames.zipCode, scope)}"
							:disabled="disabled"
							@change="addressChange('zip_code')"
						/>
					</form-error-field>
				</b-col>
				<b-col
					cols="8"
					md="4"
					lg="5"
					xl="6"
					class="pr-md-0"
				>
					<form-error-field :name="fieldNames.city"
						:scope="scope"
					>
						<label class="label">City</label>
						<b-form-input
							v-model="address.city"
							v-validate="'required|max:50'"
							:name="fieldNames.city"
							type="text"
							class="form-control"
							autocomplete="off_city"
							debounce="500"
							data-address="city"
							data-vv-as="City"
							:class="{'is-invalid': errors.has(fieldNames.city, scope)}"
							:disabled="disabled"
							@change="addressChange('city')"
						/>
					</form-error-field>
				</b-col>
				<b-col
					md="4"
					xl="4"
				>
					<form-error-field :name="fieldNames.state"
						:scope="scope"
					>
						<label class="label">State</label>
						<b-form-input
							v-model="address.state"
							v-validate="'max:50'"
							:name="fieldNames.state"
							type="text"
							class="form-control"
							autocomplete="off_state"
							debounce="500"
							data-address="state"
							data-vv-as="State"
							:disabled="disabled"
							:class="{'is-invalid': errors.has(fieldNames.state, scope)}"
							@change="addressChange('state')"
						/>
					</form-error-field>
				</b-col>
			</b-row>
		</div>
	</div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import FormErrorField from '@/shared/Form/FormErrorField';

export default {
	name: 'AddressInput',
	inject: ['$validator'],
	components: {
		FormErrorField
	},
	props: {
		value: {
			type: Object,
			default: function() {
				return {};
			}
		},
		countryName: {
			type: String,
			default: 'Country'
		},
		scope: {
			type: String,
			default: undefined
		},
		disabled: {
			type: Boolean,
			default: false
		},
		type: {
			type: String,
			default: null
		},
		fieldPrefix: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			address: { ...(this.value || {}) },
			selectedCountryId: null,
			autocomplete: Object,
			componentForm : {
				street_number: {
					google: 'short_name',
					name: 'streetNo'
				},
				route: {
					google: 'long_name',
					name: 'street'
				},
				locality: {
					google: 'long_name',
					name: 'city'
				},
				administrative_area_level_1: {
					google: 'short_name',
					name: 'state'
				},
				country: {
					google: 'short_name',
					name: 'country'
				},
				postal_code: {
					google: 'short_name',
					name: 'zipCode'
				}
			},
			id: uuidv4(),
			manual: false,
			optionPromises: [],
		};
	},
	computed: {
		...mapState('countries', {
			countryOptions: state => state.list
		}),
		fieldNames() {
			const type = `${this.type ? `${this.type}.` : ''}`;
			const prefix = this.fieldPrefix ? `${this.fieldPrefix}.` : '';
			const start = prefix + type;
			return {
				countryId: `${start}countryId` || this.id + 'countryId',
				streetNo: `${start}streetNo` || this.id + 'streetNo',
				flatNo: `${start}flatNo` || this.id + 'flatNo',
				street: `${start}street` || this.id + 'street',
				state: `${start}state` || this.id + 'state',
				zipCode: `${start}zipCode` || this.id + 'zipCode',
				city: `${start}city` || this.id + 'city',
			};
		}
	},
	watch: {
		address: { 
			handler(value) {
				this.$emit('input', value);
			},
			deep: true
		},
		value: {
			handler(value) {
				this.address = value || {};
			},
			deep: true
		},
		'value.countryId': function(value) {
			if (value) {
				this.setCountry(value);
			}
		},
		//if changed from parent then show full info
		'value.city': function(newVal) {
			if (newVal) {
				this.manual = true;
			}
		},
		selectedCountryId: function(value) {

			if (this.address && this.address.countryId !== value) {
				this.clearAddress();
				this.manual = false;
			}
			this.$set(this.address, 'countryId', value);
		},
		errors: {
			handler(value) {
				const addressFields = [
					'city',
					'street',
					'streetNo',
					'zipCode',
				];
				if (value?.items?.find(err => addressFields.map(f => `${this.type}.${f}`).includes(err.field)) && this.selectedCountryId) {
					this.manual = true;
				}
			},
			deep: true
		}
	},

	async mounted() {
		await this.getCountries();
		this.$nextTick(function () {
			this.initAutocomplete();
			if (this.value?.countryId) {
				this.setCountry(this.value.countryId);
				this.selectedCountryId = this.value.countryId;
				if (this.value.address) {
					this.manual = true;
				}
			}
		});
	},
	methods: {
		...mapActions('countries', ['getCountries']),
		handleError(error) {
			this.$store.dispatch('showErrorNotification', error);
		},
		clearAddress() {
			this.address = {
				...this.address,
				address: null,
				state: null,
				city: null,
				street: null,
				streetNo: null,
				flatNo: null,
				zipCode: null,
			};
		},
		async setCountry(value) {
			if (Object.keys(this.autocomplete).length > 0) {
				this.autocomplete.setComponentRestrictions({ 'country': value });
				await Promise.all(this.optionPromises);
				this.selectedCountryId = value;
			}
		},
		initAutocomplete() {
			// eslint-disable-next-line no-undef
			this.autocomplete = new google.maps.places.Autocomplete((document.getElementById(this.id + '_autocomplete')), { types: ['geocode'] });
			this.autocomplete.addListener('place_changed', this.fillInAddress);
			setTimeout(() => {
				if (this.$refs.autocomplete) {
					this.$refs.autocomplete.$el.setAttribute('autocomplete', 'off_autocomplete');
				}
			}, 1000);
		},
		fillInAddress() {
			this.clearAddress();
			this.manual = true;
			const place = this.autocomplete.getPlace();
			if (place.address_components) {
				this.address['address'] = place.formatted_address;
				for (let i = 0; i < place.address_components.length; i++) {
					const addressComponent = place.address_components[i];
					const addressType = addressComponent.types[0];
					const component = this.componentForm[addressType];
					if (component) {
						let value = addressComponent[component.google];
						if (component.name == 'country') value = value.toLowerCase();
						this.$set(this.address, component.name, value);
					}
				}
			}
		},
		geolocate() {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(function (position) {
					var geolocation = {
						lat: position.coords.latitude,
						lng: position.coords.longitude
					};
					// eslint-disable-next-line no-undef
					var circle = new google.maps.Circle({
						center: geolocation,
						radius: position.coords.accuracy
					});
					this.autocomplete.setBounds(circle.getBounds());
				});
			}
		},
		getAddressData: function (addressData) {
			this.address = addressData;
		},

		showAddressFields() {
			this.manual = true;
		},
		generateAddress(obj) {
			const country = this.countryOptions.find(c => c.countryId == obj.countryId);
			const generated = [
				[obj.street, obj.streetNo, obj.flatNo ? `- ${obj.flatNo}` : ''],
				[obj.state],
				[obj.city, obj.zipCode],
				[country && country.name]
			]
				.map(part => part.filter(v => !!v))
				.filter(part => part.length > 0)
				.map(part => part.join(' '))
				.join(', ');
			this.$set(this.address, 'address', generated);
		},
		addressChange(field) {
			const manualChange = !['address'].includes(field);
			if (manualChange) {
				this.generateAddress(this.address);
			}
		}
	},
	provide() {
		return {
			$validator: this.$validator,
		};
	}
};
</script>
