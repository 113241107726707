<template>
	<div :class="alignClass">
		<transition 
			name="fade" 
			mode="in-out"
		>
			<div 
				v-if="type != 'bootstrap' && error" 
				:class="alignClass" 
				class="alert alert-danger" 
				@click="hideErrorNotification()"
			>
				<span>{{ error }}</span>
			</div>
			<div 
				v-if="type != 'bootstrap' && success" 
				:class="alignClass" 
				class="alert alert-success successmsg" 
				@click="hideSuccessNotification()"
			>
				<span>{{ success }}</span>
			</div>
			<div 
				v-if="type == 'bootstrap' && (success || info || error)" 
				class="notification"
			>
				<div 
					v-if="error" 
					class="alert alert-danger" 
					@click="hideErrorNotification()"
				>
					<div class="container">
						{{ error }}
					</div>
				</div>

				<div 
					v-if="success" 
					class="alert alert-success" 
					@click="hideSuccessNotification()"
				>
					<div class="container">
						{{ success }}
					</div>
				</div>

				<div 
					v-if="info" 
					class="alert alert-info" 
					@click="hideInfoNotification()"
				>
					<div class="container">
						{{ info }}
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
	name: 'Notification',
	props: {
		type: {
			type: String,
			default: ''
		},
		align: {
			type: String,
			default: ''
		}
	},
	computed: {
		...mapState({
			success: state => state.notification.success,
			error: state => state.notification.error,
			info: state => state.notification.info
		}),
		alignClass: {
			get() {
				let className = '';
				if (this.align) {
					className = 'text-' + this.align;
				}
				return className;
			}
		}
	},
	methods: {
		...mapActions([
			'hideSuccessNotification',
			'hideErrorNotification',
			'hideInfoNotification'
		])
	}
};
</script>
