var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "odd-form"
  }, [_c('b-form', [_c('b-row', [_c('b-col', [_c('h2', [_vm._v("Update your documents.")])])], 1), _vm._l(_vm.documents, function (doc, index) {
    return _c('b-row', {
      key: index
    }, [_c('b-col', [_c('b-form-group', [_c('label', [_vm._v(" " + _vm._s(doc.name) + " ")]), _c('file-upload', {
      attrs: {
        "name": doc.value
      },
      on: {
        "onChange": function onChange(e) {
          return _vm.setDocument(e, doc.value);
        }
      }
    })], 1)], 1)], 1);
  })], 2), _c('odd-form-footer', _vm._b({
    attrs: {
      "submit-disabled": !_vm.filesUploaded
    }
  }, 'odd-form-footer', _vm.properties, false))], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }