import { CLEAR_TRANSFER_ERRORS } from '~/store/mutation-types';

export default {
	[CLEAR_TRANSFER_ERRORS] (state) {
		state.errors.picked = null;
		state.errors.fromWallet = null;
		state.errors.fromBalance = null;
		state.errors.toUser = null;
		state.errors.transferAmount = null;
		state.errors.transferCurrency = null;
		state.errors.recipient = null;
		state.errors.receiversIBAN = null;
		state.errors.transferType = null;
	}
};