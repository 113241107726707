var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sidebar-header"
  }, [_c('div', {
    staticClass: "sidebar-header-logo"
  }, [_c('router-link', {
    staticClass: "logo",
    attrs: {
      "to": {
        name: 'home'
      },
      "exact": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.siteName) + " ")])], 1), _c('div', {
    staticClass: "sidebar-header-user"
  }, [_c('div', {
    staticClass: "user-icon-wrap dropdown",
    attrs: {
      "id": "dropdownMenuButton",
      "data-toggle": "dropdown",
      "aria-haspopup": "true",
      "aria-expanded": "false"
    }
  }, [_c('span', [_c('ion-icon', {
    attrs: {
      "name": "settings-outline"
    }
  })], 1), _c('div', {
    staticClass: "dropdown-menu dropdown-menu-right",
    attrs: {
      "aria-labelledby": "dropdownMenuButton"
    }
  }, [_c('router-link', {
    staticClass: "dropdown-item",
    attrs: {
      "to": {
        name: 'profile'
      },
      "exact": ""
    }
  }, [_vm._v(" Profile Information ")]), _c('router-link', {
    staticClass: "dropdown-item",
    attrs: {
      "to": {
        name: 'profile-accounts-page'
      },
      "exact": ""
    }
  }, [_vm._v(" Account Settings ")]), _c('router-link', {
    staticClass: "dropdown-item logout-menu-item",
    attrs: {
      "to": {
        name: 'authenticator-settings'
      },
      "exact": ""
    }
  }, [_vm._v(" Two-factor Authentication ")]), _c('router-link', {
    staticClass: "dropdown-item",
    attrs: {
      "to": {
        name: 'log'
      },
      "exact": ""
    }
  }, [_vm._v(" Profile Activity ")]), _c('router-link', {
    staticClass: "dropdown-item",
    attrs: {
      "to": {
        name: 'change-password'
      },
      "exact": ""
    }
  }, [_vm._v(" Change Password ")]), _c('router-link', {
    staticClass: "dropdown-item logout-menu-item",
    attrs: {
      "to": {
        name: 'login'
      },
      "exact": ""
    }
  }, [_vm._v(" Logout "), _c('ion-icon', {
    staticClass: "logout-icon",
    attrs: {
      "name": "log-out-outline"
    }
  })], 1)], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }