import { SET_COUNTRIES_PROMISE, SET_COUNTRIES } from '~/store/mutation-types';

export default {
	[SET_COUNTRIES](state, { value, type }) {
		state[type] = value;
	},
	[SET_COUNTRIES_PROMISE](state, { value, type }) {
		state.promises = {
			...state.promises,
			[type]: value
		};
	}
};