var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "alert alert-info mb-5"
  }, [_vm._v(" No payment request ")]);
}
var staticRenderFns = []

export { render, staticRenderFns }