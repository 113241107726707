<template>
	<div class="bulk-payments">
		<!-- File selection step -->
		<div v-if="step == 'fileSelection'">
			<b-row
				align-v="center"
				class="mb-4"
			>
				<b-col>
					<small class="notes text-wrap">CSV file for bulk payments must contain the name of the beneficiary, IBAN, currency, amount, and description. We recommend using the example.csv file as a template. Please note that only EUR payments are currently supported.</small>
				</b-col>
				<b-col
					md="auto"
					class="text-md-right text-center"
				>
					<b-button
						size="sm"
						variant="outline-primary"
						class="my-3 text-nowrap"
						href="/files/example.csv"
					>
						<i class="fas fa-download mr-3"></i>Download example.csv
					</b-button>
				</b-col>
			</b-row>
			<b-row
				v-if="!files.loading"
				class="mb-4"
			>
				<b-col>
					<file-upload
						v-if="isWalletGranted('initiate_transaction', currentWallet.walletId)"
						ref="file-upload"
						:accept="'.csv'"
						expandable
						placeholder="Press to choose CSV file or drop it here"
						@onChange="uploadFile"
					/>
				</b-col>
			</b-row>
			<b-card
				v-if="files.loading"
				class="file-item text-center mt-3"
			>
				<b-spinner label="Loading..." />
			</b-card>
			<div class="mb-3">
				<b-card
					v-for="file in files.rows"
					:key="file.id"
					class="file-item mt-3"
					:class="{'file-item--deleted' : file.status == 'deleted'}"
					@click="openFile(file)"
				>
					<b-row align-v="center">
						<b-col
							md="10"
							cols="12"
							class="text-sm-left text-center"
						>
							<b-row align-v="center">
								<b-col
									sm="5"
									cols="12"
									class="py-1"
								>
									{{ moment(file.dateCreated).format('YYYY-MM-DD HH:mm') }}
								</b-col>
								<b-col
									sm="7"
									cols="12"
									class="py-1"
								>
									<b-badge
										pill
										class="file-item__filename mr-2"
										:variant="statusVariant('file', file.status)"
									>
										<i
											:class="statusVariant('icon', file.status)"
										></i>
									</b-badge>
									<span>{{ file.fileName }}</span>
								</b-col>
							</b-row>
						</b-col>
						<b-col class="text-center text-sm-right my-sm-0 my-3">
							<b-button 
								class="delete-button"
								:disabled="!isWalletGranted('initiate_transaction', currentWallet.walletId) || !['created', 'imported'].includes(file.status)"
								size="sm"
								variant="outline-primary"
								@click.stop.self="deleteFile(file)"
							>
								<i class="fas fa-trash-alt"></i> Delete
							</b-button>
						</b-col>
					</b-row>
				</b-card>
			</div>
			<div
				v-if="!files.loading && files.rows && files.rows.length > 0"
				class="mb-3 text-sm-left text-center"
			>
				<div>
					<span>Status:</span>
				</div>
				<b-badge
					variant="primary"
					class="mr-1 status-badge my-1"
				>
					Imported
				</b-badge>
				<b-badge
					variant="warning"
					class="mr-1 status-badge my-1"
				>
					Processing
				</b-badge>
				<b-badge
					variant="success"
					class="mr-1 status-badge my-1"
				>
					Processed
				</b-badge>
				<b-badge
					variant="danger"
					class="status-badge my-1"
				>
					Deleted
				</b-badge>
			</div>
			<pagination
				v-if="files.pages > 1"
				:total="files.count"
				:per-page="filterPerPage"
				:current-page="files.currentPage"
				@pagechanged="getFileList"
			/>
		</div>

		<!-- Record selection step -->
		<div v-if="step == 'transferSelection'">
			<div class="bulk-items mt-3">
				<b-row
					align-v="center"
					class="mb-3"
				>
					<b-col
						sm="12"
						md="9"
						lg="9"
						xl="9"
					/>
					<b-col
						sm="12"
						md="3"
						lg="3"
						xl="3"
						class="mt-3 mt-lg-0 mt-sm-0 mt-xl-0"
					>
						<div class="d-flex align-items-center">
							<p class="mr-3 mb-0 text-muted align-self-center text-nowrap items-per-page-label">
								Items <br> per page
							</p>
							<custom-select
								:items="pages"
								:hide-front="false"
								:modify-bg="true"
								@select="filterPages($event)"
							/>
						</div>
					</b-col>
					<b-col
						md="6"
						cols="12"
					>
						<div class="text-center text-md-left mt-3 mt-lg-0 mt-sm-0 mt-xl-0">
							<b-badge
								pill
								class="file-item__filename mr-2"
								:variant="statusVariant('file', selectedFile.status)"
							>
								<i
									:class="statusVariant('icon', selectedFile.status)"
								></i>
							</b-badge>
							<span>{{ selectedFile.fileName }}</span>
							<b-button
								v-if="isWalletGranted('initiate_transaction', currentWallet.walletId) && selectedFile.status === 'imported'"
								class="ml-3"
								size="sm"
								variant="outline-primary"
								@click.stop.self="deleteFile(selectedFile)"
							>
								<i class="fas fa-trash-alt"></i> Delete
							</b-button>
						</div>
					</b-col>
					<b-col
						md="6"
						cols="12"
					>
						<div
							v-if="items.rows && items.rows.length > 0 && selectedFile.status === 'imported' && isWalletGranted('initiate_transaction', currentWallet.walletId)"
							class="text-center text-md-right mt-3 mt-lg-3 mt-sm-3 mt-xl-3"
						>
							<button
								class="btn btn-primary mb-3"
								@click.prevent="selectAll(true)"
							>
								Select all
							</button>
							<button
								class="btn btn-outline-primary mb-3 ml-3"
								@click.prevent="selectAll(false)"
							>
								Deselect all
							</button>
						</div>
					</b-col>
				</b-row>

				<b-card
					v-if="items.loading"
					class="bulk-item text-center"
				>
					<b-spinner label="Loading..." />
				</b-card>
				<b-card
					v-for="item in items.rows" 
					:key="item.id" 
					class="mb-3 bulk-item" 
					:class="`${isItemSelected(item) ? 'bulk-item--selected' : ''} ${item.status !== 'created' ? 'bulk-item--completed' : ''}`" 
					@click.prevent="selectItem(item)"
				>
					<b-row>
						<b-col
							v-if="selectedFile.status == 'imported'"
							md="1"
							cols="12"
							class="check"
						>
							<div>
								<i
									v-if="!isItemSelected(item)"
									class="far fa-square"
								></i>
								<i
									v-else
									class="far fa-check-square"
								></i>
							</div>
						</b-col>
						<b-col
							md="3"
							cols="12"
						>
							<small class="item-label">Beneficiary</small>
							<div>{{ item.beneficiary.accountHolder }}</div>
						</b-col>
						<b-col
							md="3"
							cols="12"
						>
							<small class="item-label">Account Number</small>
							<div>{{ item.beneficiary.accountNumber }}</div>
						</b-col>
						<b-col
							md="2"
							cols="12"
						>
							<small class="item-label">Amount</small>
							<div class="amount-label">
								<b-badge
									class="amount-badge"
									:variant="statusVariant('item', item.status)"
								>
									{{ item.amount }}<small>{{ item.currencyId }}</small>
								</b-badge>
							</div>
						</b-col>
						<b-col
							:md="selectedFile.status == 'imported' ? 3 : 4"
							cols="12"
						>
							<small class="item-label">Details</small>
							<div>{{ item.details ? item.details : '-' }}</div>
						</b-col>
					</b-row>
				</b-card>
				<div
					v-if="!items.loading && items.rows && items.rows.length > 0"
					class="mb-3 text-sm-left text-center"
				>
					<div>
						<span>Status:</span>
					</div>
					<b-badge
						variant="primary"
						class="mr-1 status-badge my-1"
					>
						Ready
					</b-badge>
					<b-badge
						variant="info"
						class="mr-1 status-badge my-1"
					>
						Skipped
					</b-badge>
					<b-badge
						variant="success"
						class="mr-1 status-badge my-1"
					>
						Processed
					</b-badge>
					<b-badge
						variant="danger"
						class="status-badge my-1"
					>
						Failed or deleted
					</b-badge>
				</div>
				<div class="mb-3">
					<pagination
						v-if="items.pages > 1"
						:total="items.count"
						:per-page="filterPerPage"
						:current-page="items.currentPage"
						@pagechanged="getTransferList"
					/>
				</div>
			</div>
			<div
				v-if="items.rows && items.rows.length > 0"
				class="text-center"
			>
				<button
					class="btn btn-outline-primary"
					@click.prevent="step = 'fileSelection'"
				>
					Back
				</button>
				<button
					v-if="isWalletGranted('approve_transaction', currentWallet.walletId) && selectedFile.status === 'imported'"
					class="btn btn-primary ml-3"
					:disabled="selectedItems.length == 0"
					@click.prevent="step = 'confirmation'"
				>
					Next
				</button>
				<button
					v-if="isGranted('front_payment_file_delete_row')"
					class="btn btn-danger ml-3"
					:disabled="selectedItems.length == 0"
					@click.prevent="deleteSelected"
				>
					Remove
				</button>
			</div>
		</div>

		<!-- Confirmation step -->
		<div
			v-if="step == 'confirmation' || step == 'transfer'"
			class="selected-items"
		>
			<b-card>
				<b-row
					v-for="item in selectedItems"
					:key="item.id"
					class="selected-item"
				>
					<b-col
						md="3"
						sm="12"
					>
						<small class="item-label">Beneficiary</small>
						<div>{{ item.beneficiary.accountHolder }}</div>
					</b-col>
					<b-col
						md="3"
						sm="12"
					>
						<small class="item-label">Account Number</small>
						<div>{{ item.beneficiary.accountNumber }}</div>
					</b-col>
					<b-col
						md="3"
						sm="12"
					>
						<small class="item-label">Amount</small>
						<div>{{ item.amount }}<small>{{ item.currencyId }}</small></div>
					</b-col>
					<b-col
						md="3"
						sm="12"
					>
						<small class="item-label">Details</small>
						<div>{{ item.details ? item.details : '-' }}</div>
					</b-col>
				</b-row>
				<b-row
					class="selected-item"
					align-v="center"
				>
					<b-col
						md="6"
						sm="12"
					>
						<strong>Number of payments: {{ selectedItems.length }}</strong>
					</b-col>
					<b-col
						md="6"
						sm="12"
					>
						<strong>Total: {{ selectedTotal.sum }}{{ selectedTotal.currency }}</strong>
						<div>
							<b-badge
								v-if="exceededBalance(selectedTotal.sum)"
								variant="danger"
							>
								Insufficient Funds
							</b-badge>
						</div>
					</b-col>
				</b-row>
			</b-card>
			<div
				v-if="step == 'confirmation'"
				class="text-center mt-3"
			>
				<button
					class="btn btn-outline-primary"
					@click.prevent="step = 'transferSelection'"
				>
					Back
				</button>
				<button
					v-if="isWalletGranted('approve_transaction', currentWallet.walletId)"
					class="btn btn-primary ml-3"
					:disabled="exceededBalance(selectedTotal.sum)"
					@click.prevent="confirm"
				>
					Confirm
				</button>
			</div>
			<div
				v-if="step == 'transfer'"
				class="transfer"
			>
				<b-card class="mt-3">
					<authenticator
						ref="authenticator"
						:show-back-button="false"
						:on-submit="transfer"
						type="transfer"
						:on-resend="confirm"
					/>
				</b-card>
				<div class="text-center mt-3">
					<button
						class="btn btn-outline-primary"
						@click.prevent="step = 'confirmation'"
					>
						Back
					</button>
				</div>
			</div>
		</div>

		<!-- Success step -->
		<div v-if="step == 'success'">
			<b-card>
				Transfer from file {{ successData ? `${successData.fileName} ` : '' }}successful. <button
					class="btn btn-primary ml-3"
					@click.prevent="step = 'fileSelection'"
				>
					OK
				</button>
			</b-card>
		</div>

		<div v-if="step == 'successDelete'">
			<b-card>
				Transfers deleted successfully
				<button
					class="btn btn-primary ml-3"
					@click.prevent="step = 'transferSelection'"
				>
					OK
				</button>
			</b-card>
		</div>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import FileUpload from '~/components/shared/FileUpload';
import Authenticator from '~/components/shared/Authenticator';
import { endpoints } from '~/config';
import moment from 'moment';
import Pagination from '~/components/utils/Pagination';
import CustomSelect from '@/utils/CustomSelect';

export default {
	name: 'BulkPayments',
	components: {
		CustomSelect,
		FileUpload,
		Authenticator,
		Pagination,
	},
	data() {
		return {
			moment,
			fileContent: null,
			items: {},
			files: {},
			fileId: null,
			step: 'fileSelection',
			successData: null,
			selectedFile: null,
			selectedItems: [],
			pages: {
				10: '10',
				20: '20',
				50: '50',
				100: '100',
			},
			filterPerPage: '10',
		};
	},
	computed: {
		...mapGetters('user', [
			'isGranted',
			'isWalletGranted',
		]),
		...mapGetters('wallet', [
			'currentWallet',
			'currentWalletAmount'
		]),
		selectedIds() {
			return this.selectedItems.map(i => i.id);
		},
		selectedTotal() {
			if (this.selectedItems.length > 0) {
				const currency = this.selectedItems[0].currencyId;
				const sum = this.selectedItems.map(i => i.amount).reduce((a, b) => parseFloat(a) + parseFloat(b), 0).toFixed(2);
				return { sum, currency };
			} else {
				return null;
			}
		}
	},
	methods: {
		...mapActions('notifications', [
			'pushError'
		]),
		...mapActions('auth', ['init2fa']),
		resetFile() {
			this.$refs['file-upload'].reset();
		},
		statusVariant(type = 'file', status) {
			if (type == 'item') {
				switch(status) {
				case 'created':
					return 'primary';
				case 'skipped':
					return 'info';
				case 'processed':
					return 'success';
				case 'failed':
					return 'danger';
				case 'deleted':
					return 'danger';
				default:
					return 'primary';
				}
			} else if (type == 'file') {
				switch(status) {
				case 'created':
					return 'warning';
				case 'imported':
					return 'primary';
				case 'processing':
					return 'warning';
				case 'processed':
					return 'success';
				case 'deleted':
					return 'danger';
				default:
					return 'primary';
				}
			} else if (type == 'icon') {
				switch(status) {
				case 'created':
					return 'fas fa-spinner';
				case 'imported':
					return 'far fa-folder-open';
				case 'processing':
					return 'fas fa-spinner';
				case 'processed':
					return 'far fa-folder';
				case 'deleted':
					return 'fas fa-trash';
				default:
					return 'far fa-folder';
				}
			}
			return 'primary';
		},
		async uploadFile(file) {
			const options = {
				method: 'POST',
				url: endpoints.transfer.bulkUpload({
					walletId: this.currentWallet.walletId,
				}),
				data: {
					type: 'sepa', //todo: update types
					fileContent: file[0].content,
					fileName: file[0].name,
				},
			};
			try {
				const response = await axios(options);
				this.fileContent = response.data;
				this.getFileList();
			} catch (err) {
				this.pushError(err.message);
			}
			this.resetFile();
		},
		async deleteFile(file) {
			const options = {
				method: 'DELETE',
				url: endpoints.transfer.bulkFileDelete({ fileId: file.id })
			};
			const response = confirm(`Remove file ${file.fileName}?`);
			if (response) {
				try {
					await axios(options);
				} catch (err) {
					this.pushError(err.message);
				}
				this.step = 'fileSelection';
				this.getFileList();
			}
		},
		async getFileList(page = 1) {
			this.files = { 
				currentPage: page,
				loading: true
			};
			const options = {
				method: 'GET',
				url: endpoints.transfer.bulkFileList,
				params: {
					page: page,
					limit: 10,
					orderBy: 'id',
					orderDirection: 'desc'
				}
			};
			try {
				const response = await axios(options);
				this.files = {
					...this.files,
					...response.data,
					loading: false
				};
			} catch (err) {
				this.files.loading = false;
				this.pushError(err.message);
			}
		},

		async getTransferList(page = 1) {
			this.items = {
				currentPage: page,
				loading: true
			};
			const options = {
				method: 'GET',
				url: endpoints.transfer.bulkItemsList( { fileId: this.files.selectedId } ),
				params: {
					page: page,
					limit: this.filterPerPage,
					orderBy: 'id',
					orderDirection: 'desc',
					statusIn: ['created', 'skipped', 'processed', 'failed']
				}
			};
			try {
				const response = await axios(options);
				this.items = {
					...this.items,
					...response.data,
					loading: false
				};
				return this.items;
			} catch (err) {
				this.items.loading = false;
				this.pushError(err.message);
			}
		},
		async openFile(file) {
			if (['deleted', 'created'].includes(file.status)) return;
			this.files.selectedId = file.id;
			this.selectedFile = file;
			this.selectedItems = [];
			try {
				const items = await this.getTransferList();
				if (items.rows.length > 0) {
					this.step = 'transferSelection';
					this.fileId = file.id;
				} else {
					this.pushError('Something wrong with the file.');
				}
			} catch (err) {
				//
			}
		},
		selectItem(item) {
			const selectedItem = this.selectedItems.find(i => i.id == item.id);
			if (selectedItem) {
				this.selectedItems = this.selectedItems.filter(i => i.id !== item.id && i.status == 'created');
			} else if (item.status == 'created') {
				this.selectedItems.push(item);
			}
		},
		isItemSelected(item) {
			const id = item.id;
			return !!this.selectedItems.find(i => i.id == id);
		},
		filterPages(event) {
			this.filterPerPage = event.toString();
			this.limit = event;
			this.getTransferList(1);
		},
		selectAll(select) {
			const items = this.items.rows;
			if (select) {
				items.forEach(i => {
					if (!this.selectedItems.find(si => si.id == i.id)) {
						this.selectedItems.push(i);
					}
				});
			} else {
				this.selectedItems = this.selectedItems.filter(si => {
					const found = items.find(i => si.id == i.id);
					return !found;
				});
			}

		},
		async confirm() {
			try {
				await this.init2fa({
					type: 'payment_file',
					data: {
						fileId: this.fileId
					},
				});
				this.step = 'transfer';
			} catch (err) {
				this.pushError(err.message);
			}
		},
		async transfer(code) {
			this.successData = null;
			const options = {
				method: 'PUT',
				url: endpoints.transfer.bulkTransfer( { fileId: this.fileId } ),
				data: {
					code,
					paymentIds: this.selectedIds
				}
			};
			try {
				const response = await axios(options);
				const { data } = response;
				this.successData = data;
				this.step = 'success';
			} catch (err) {
				this.$refs.authenticator.reset();
				this.pushError(err.message);
			}
		},
		async deleteSelected() {
			this.successData = null;
			const options = {
				method: 'DELETE',
				url: endpoints.transfer.bulkFileDeleteRow({ fileId: this.fileId }),
				data: {
					ids: this.selectedIds
				}
			};
			try {
				const response = await axios(options);
				const { data } = response;
				this.successData = data;
				this.step = 'successDelete';
				this.selectedItems = [];
				await this.getTransferList();
			} catch (err) {
				this.$refs.authenticator.reset();
				this.pushError(err.message);
			}
		},
		exceededBalance(amount) {
			const currentBalance =  parseFloat(this.currentWalletAmount.balance);
			return parseFloat(amount) > currentBalance;
		}
	},
	watch: {
		step(step) {
			if (step == 'fileSelection') {
				this.getFileList();
			}
		}
	},
	mounted() {
		this.getFileList();
	}
};
</script>
<style lang="scss" src="./BulkPayments.scss">
</style>
