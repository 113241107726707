<template>
	<form
		action="#"
		class="form-body persons-form"
		autocomplete="disable"
		:disabled="formDisabled"
		@submit.prevent="onsubmit()"
		@keydown.enter.prevent
	>
		<h2
			v-if="isPersonal"
			class="page-title section-title"
		>
			Personal information
		</h2>
		<h2
			v-if="isBusiness"
			class="page-title section-title"
		>
			Your information
		</h2>

		<fieldset
			:disabled="details.verification == 'pending'"
		>
			<div v-if="isBusiness">
				<b-row>
					<b-col>
						<h3>Your role</h3>
						<b-row align-v="stretch">
							<b-col
								v-for="option in roleOptions"
								:key="option.value"
								sm="6"
								cols="12"
								class="role-options"
								data-registration="use-of-account"
								@click="setRole(option.value)"
							>
								<div
									class="role-options__box"
									:class="{'role-options__box--active' : data.roleType == option.value}"
								>
									<div class="role-options__text">
										<h3><span class="radio"></span>{{ option.text }}</h3>
										<p v-html="option.description"></p>
									</div>
								</div>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</div>
			<div>
				<div
					v-if="!owner || (owner && owner.verification !== 'verified')"
					class="verified-owner"
				>
					<b-row>
						<b-col
							sm="6"
							class="pr-sm-0"
						>
							<form-error-field name="name">
								<label class="label">First name (and middle names)</label>
								<b-form-input
									id="name"
									v-model="data.name"
									v-validate="'required'"
									type="text"
									name="name"
									autocomplete="off_name"
									placeholder="Enter your name"
									debounce="500"
									data-registration="first-name"
									:disabled="formDisabled"
									data-vv-as="First name (and middle names)"
								/>
							</form-error-field>
						</b-col>
						<b-col sm="6">
							<form-error-field name="surname">
								<label class="label">Last name</label>
								<b-form-input
									id="surname"
									v-model="data.surname"
									v-validate="'required'"
									type="text"
									name="surname"
									autocomplete="off_surname"
									placeholder="Enter your last name"
									debounce="500"
									data-registration="last-name"
									:disabled="formDisabled"
									data-vv-as="Last name"
								/>
							</form-error-field>
						</b-col>
					</b-row>

					<b-row>
						<b-col>
							<form-error-field name="placeOfBirth">
								<label class="label">Place of birth</label>
								<v-multiselect
									v-model="data.placeOfBirth"
									v-validate="'required'"
									:options="countryOptions"
									:show-labels="false"
									:allow-empty="false"
									name="placeOfBirth"
									label="name"
									placeholder="Select country of birth"
									track-by="countryId"
									return="countryId"
									data-registration="place-of-birth"
									:disabled="formDisabled"
									data-vv-as="Place of birth"
								/>
							</form-error-field>
						</b-col>
					</b-row>

					<!-- Make sure dateOfBirth is neccessary -->
					<b-row v-if="isBusiness">
						<b-col>
							<form-error-field name="dateOfBirth">
								<label class="label">Date of birth</label>
								<app-datepicker
									v-model="data.dateOfBirth"
									v-validate="'required'"
									:adult="true"
									:name="'dateOfBirth'"
									data-registration="date-of-birth"
									:disabled="formDisabled"
									data-vv-as="Date of birth"
								/>
							</form-error-field>
						</b-col>
					</b-row>

					<!-- <b-row>
						<b-col>
							<p class="label">
								<b>Registered residential address</b>
							</p>
						</b-col>
					</b-row> -->
					<multi-address :addresses="data.addresses" />
					<transition name="fade">
						<div v-if="isBusiness && data.roleType == 'representative'">
							<b-row
								v-for="doc in documents"
								v-show="doc.displayName"
								:key="doc.type"
							>
								<b-col>
									<div class="form-group">
										<label>
											{{ doc.displayName }}
										</label>
										<document-input
											v-model="data[doc.id]"
											:document="doc"
											:required="true"
											:state="documentId(doc.type) || !!data.proofOfResidenceDocument ? true : null"
											data-registration="proof-of-residence"
											:disabled="formDisabled"
											name="proofOfResidenceDocumentId"
											:data-vv-as="doc.displayName"
											@onChange="e => onDocumentChange(e, doc)"
										/>
									</div>
								</b-col>
							</b-row>
						</div>
					</transition>

					<b-row v-if="isPersonal">
						<b-col class="pt-4">
							<div
								v-for="doc in documents"
								v-show="doc.displayName"
								:key="doc.displayName"
								class="content-item form-group"
							>
								<label>
									{{ doc.displayName }}
								</label>
								<document-input
									v-model="data[doc.id]"
									:document="doc"
									:required="true"
									:state="documentId(doc.type) || !!data.proofOfResidenceDocument ? true : null"
									:data-registration="`document-${doc.field}`"
									:disabled="formDisabled"
									@onChange="e => onDocumentChange(e, doc)"
								/>
							</div>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<form-error-field name="countryForTax">
								<label class="label">
									Country of residence for tax purpose
								</label>
								<v-multiselect
									v-model="data.countryForTax"
									v-validate="'required'"
									:options="countryOptions"
									:show-labels="false"
									:allow-empty="false"
									name="countryForTax"
									label="name"
									placeholder="Select country of residence for tax purpose"
									track-by="countryId"
									return="countryId"
									data-registration="tax-country"
									:disabled="formDisabled"
									data-vv-as="Country of residence for tax purpose"
								/>
							</form-error-field>
						</b-col>
					</b-row>

					<b-row v-if="isBusiness">
						<b-col>
							<form-error-field class="text-left mb-12"
								name="politicalExposure"
							>
								<label>
									Political exposure
									<i
										class="fa fa-question-circle-o pull-right"
										aria-hidden="true"
										@click.prevent="
											$modal.show('political_exposure')
										"
									></i>
								</label>
							
								<v-multiselect
									v-model="data.politicalExposure"
									v-validate="'required'"
									:options="politicalOptions"
									:show-labels="false"
									:allow-empty="false"
									name="politicalExposure"
									data-vv-as="Political exposure"
									label="name"
									placeholder="Select Your political exposure"
									track-by="value"
									return="value"
									data-registration="political-exposure"
									:disabled="formDisabled"
								/>
							</form-error-field>
						</b-col>
					</b-row>
				</div>
				<div v-if="isBusiness">
					<form-error-field name="keyPersons"
						wrapper="div"
						class="position-relative"
					>
						<b-card class="mb-4">
							<b-row
								align-h="between"
								align-v="center"
							>
								<b-col>
									<h3 class="registration-section-heading">
										<strong>Key persons information</strong>
									</h3>
									<p>Please provide all of the persons who play role in the company. If person takes couple of roles, select appropriate ones.</p>
								</b-col>
							</b-row>
							<b-row
								v-show="data.mySelfTypes.length > 0 || data.keyPersons.length > 0"
								class="key-persons-content mb-4"
							>
								<b-col>
									<b-list-group>
										<b-list-group-item
											v-if="data.mySelfTypes.length > 0"
											:key="'myself'"
										>
											<b-row class="person">
												<b-col class="person-avatar">
													<b-avatar 
														variant="primary" 
														:text="data.name ? data.name.charAt(0) : null"
													/>
												</b-col>
												<b-col class="person-name">
													<div>
														<small>{{ data.mySelfTypes.join(', ').toUpperCase() }}</small>
													</div>
													<span>{{ data.name }} {{ data.surname }}</span>
												</b-col>
												<b-col class="person-buttons">
													<a
														v-if="!formDisabled"
														class="btn btn-primary person-button"
														@click.prevent="$set(addPersonData, 'type', addPersonData.type !== 'myself' ? 'myself' : null)"
													>
														<i class="fas fa-user-edit"></i>
													</a>
													<a
														v-if="!formDisabled"
														class="btn btn-danger person-button"
														@click.prevent="removeItemMain()"
													>
														<i class="fas fa-user-minus"></i>
													</a>
												</b-col>
											</b-row>
										</b-list-group-item>

										<b-list-group-item
											v-for="(person, i) in data.keyPersons"
											:key="i+1"
										>
											<b-row class="person">
												<b-col class="person-avatar">
													<b-avatar
														variant="primary"
														:text="person.name ? person.name.charAt(0) : ''"
													/>
												</b-col>
												<b-col class="person-name">
													<div>
														<small>
															{{ displayPersonTypes(person) }}
														</small>
													</div>
													<span>{{ person.name }} {{ person.surname }}</span>
												</b-col>
												<b-col class="person-buttons">
													<a
														v-if="!person._completed"
														class="btn btn-primary person-button"
														@click.prevent="editPerson(person, i)"
													>
														<i class="fas fa-user-edit"></i>
													</a>
													<a
														class="btn btn-danger person-button"
														@click.prevent="removeItem(person, i)"
													>
														<i class="fas fa-user-minus"></i>
													</a>
												</b-col>
											</b-row>
										</b-list-group-item>
									</b-list-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col>
									<b-row class="person-add-buttons">
										<b-col>
											<transition
												name="fade"
											>
												<b-button
													v-if="data.roleType !== 'agent' && data.name && data.surname"
													variant="primary"
													class="mr-4"
													:pressed="addPersonData.type == 'myself'"
													data-registration="add-person-myself"
													:disabled="formDisabled"
													@click="$set(addPersonData, 'type', addPersonData.type !== 'myself' ? 'myself' : null)"
												>
													Add myself
												</b-button>
											</transition>
											<!-- <b-dropdown class="persons-dropdown" id="key-myself" text="Add myself" variant="primary" right>
											<b-dropdown-form>
												<b-form-checkbox-group
													v-model="data.mySelfTypes"
													:options="myselfOptions"
													name="myself-options"
												/>
											</b-dropdown-form>
										</b-dropdown> -->
											<b-button
												variant="primary" 
												:pressed="addPersonData.type == 'person'"
												data-registration="add-person"
												:disabled="formDisabled"
												@click="$set(addPersonData, 'type', addPersonData.type !== 'person' ? 'person' : null)"
											>
												Add person
											</b-button>
										</b-col>
									</b-row>
								</b-col>
							</b-row>
						</b-card>
					</form-error-field>
					<transition name="fade">
						<div v-if="addPersonData.type">
							<b-row>
								<b-col class="text-center mb-4">
									<h3>Add {{ addPersonData.type }}</h3>
									<small>Select one or multiple options below</small>
								</b-col>
							</b-row>
							<checkbox-list
								class="mb-4"
								:items="personOptions" 
								:value="(addPersonData.type == 'myself' && data.mySelfTypes) || (addPersonData.type == 'person' && addPersonData.options)"
								option-title="title"
								data-registration="person-options"
								@change="v => (addPersonData.type == 'myself' && toggleMyselfOption(v)) || (addPersonData.type == 'person' && togglePersonOption(v))"
							/>
						</div>
					</transition>
					<transition name="fade">
						<div v-if="addPersonData.type == 'person' && Object.keys(addPersonData.options).map(o => addPersonData.options[o]).reduce((a, b) => a || b, false)">
							<b-row>
								<b-col class="text-center mb-4">
									<h3>Add person details</h3>
								</b-col>
							</b-row>
							<b-card id="person-input"
								class="mb-4"
							>
								<b-row>
									<b-col>
										<person-input
											ref="person"
											v-model="addPersonData.person"
											:i-am="false"
											:person-type-options="addPersonData.options"
											:proof-of-residence="true"
											allowtypes
											data-registration="key-person"
											field-prefix="keyPersons"
										/>
									</b-col>
								</b-row>
								<template #footer>
									<div class="text-right">
										<b-button
											variant="primary"
											:disabled="formDisabled"
											@click="saveNewPerson()"
										>
											<i class="fas fa-save mr-1"></i> Save this person
										</b-button>
									</div>
								</template>
							</b-card>
						</div>
					</transition>
				</div>
				<b-row>
					<b-col>
						<b-input-group class="text-left">
							<div
								role="group"
								class="custom-controls-stacked"
							>
								<div
									:class="{
										'has-error': errors.has('us_citizen'),
									}"
									class="custom-control custom-checkbox"
								>
									<input
										id="us_citizen"
										v-model="data.isUsCitizen"
										type="checkbox"
										name="us_citizen"
										autocomplete="off"
										class="custom-control-input"
										value="0"
										data-registration="us-citizen"
										:disabled="formDisabled"
									/>
									<label
										for="us_citizen"
										class="custom-control-label"
									>I am US citizen</label>
								</div>

								<div
									v-if="data.roleType !== 'agent'"
									:class="{
										'has-error': errors.has(
											'service_agreement'
										),
									}"
									class="custom-control custom-checkbox"
									@click="e => { if (!data.service_agreement) {e.preventDefault(); $refs.agreements.open('service_agreement')}}"
								>
									<input
										id="service_agreement"
										v-model="data.service_agreement"
										v-validate="'required'"
										type="checkbox"
										name="service_agreement"
										autocomplete="off"
										class="custom-control-input"
										data-registration="service-agreement"
										:disabled="formDisabled"
									/>
									<label
										for="service_agreement"
										class="custom-control-label"
									>I accept
										<a
											href=""
											target="_blank"
											@click.prevent="$refs.agreements.open('service_agreement')"
										>General service agreement</a>
									</label>
								</div>

								<div
									v-if="data.roleType !== 'agent'"
									:class="{
										'has-error': errors.has(
											'privacy_policy'
										),
									}"
									class="custom-control custom-checkbox"
									@click="e => { if (data.privacy_policy !== '1') {e.preventDefault(); $refs.agreements.open('privacy_policy')}}"
								>
									<input
										id="privacy_policy"
										v-model="data.privacy_policy"
										v-validate="'required'"
										type="checkbox"
										name="privacy_policy"
										autocomplete="off"
										class="custom-control-input"
										data-registration="privacy-policy"
										:disabled="formDisabled"
									/>
									<label
										for="privacy_policy"
										class="custom-control-label"
									>I accept
										<a
											href=""
											target="_blank"
											@click.prevent="$refs.agreements.open('privacy_policy')"
										>Privacy Policy</a>
									</label>
								</div>

								<div
									:class="{
										'has-error': errors.has(
											'newsletter_subscribed'
										),
									}"
									class="custom-control custom-checkbox"
								>
									<input
										id="newsletter_subscribed"
										v-model="data.isNewsletterSubscribed"
										type="checkbox"
										name="newsletter_subscribed"
										autocomplete="off"
										class="custom-control-input"
										value="1"
										data-registration="newsletter"
										:disabled="formDisabled"
									/>
									<label
										for="newsletter_subscribed"
										class="custom-control-label"
									>I subscribe for newsletters</label>
								</div>
							</div>
						</b-input-group>
					</b-col>
				</b-row>
			</div>
		</fieldset>

		<b-row>
			<b-col class="mt-4 mb-4 text-center">
				<notification />
				<input
					:class="buttonClass"
					:value="buttonText"
					:disabled="nextEnabled == false"
					type="submit"
					class="btn btn-small btn-primary w-100"
					data-registration="next"
				/>
			</b-col>
		</b-row>
		<b-row v-if="allowSkip">
			<b-col class="text-center">
				<router-link
					:to="{ name: 'home' }"
					tag="a"
					class="framelink skiplink"
					exact
					data-registration="skip"
					@click="$forceUpdate()"
				>
					<span v-if="details.verification !== 'pending'">Skip verification</span>
					<span v-if="details.verification == 'pending'">Back to main page</span>
				</router-link>
			</b-col>
		</b-row>
		<modal
			:adaptive="true"
			name="political_exposure"
			height="auto"
		>
			<div
				class="close"
				@click="$modal.hide('political_exposure')"
			></div>
			<div class="ml-4 mr-4 mt-4 mb-4">
				Politically exposed person – private individual who is or has
				been entrusted with prominent public functions mentioned below
				including the functions in the Republic of Lithuania, European
				Union, international or foreign state institutions no more than
				one year ago, and immediate family members or persons known to
				be close associates of such person:
				<br /><br />1) Head of State, Heads of Government, minister,
				vice minister and deputy minister, secretary of the State,
				chancellor of Parliament, Government or Ministry; 2) Member of
				parliament; <br />3) Member of the Supreme Court, of the
				Constitutional Court or of other high-level judicial bodies
				whose decisions are not subject to further appeal; <br />4)
				Member of the managing body of the Lithuanian Chamber of
				Auditors or of the board of the central bank; <br />5) The
				ambassador, chargés d’affaires and high-ranking officer in the
				armed forces; <br />6) Member of the management or supervisory
				body of the publicly administered undertaking; <br />7)
				Director, deputy director or member of the management or
				supervisory body of the international intergovernmental
				organization; :sunglasses: Head, deputy head or member of the
				management body of political party.
			</div>
		</modal>
		<modal
			:adaptive="true"
			name="phone"
			height="auto"
		>
			<div
				class="close"
				@click="$modal.hide('phone')"
			></div>
			<div class="ml-4 mr-4 mt-4 mb-4">
				Person will receive SMS to this phone number to verify their identity. 
			</div>
		</modal>
		<agreements
			ref="agreements"
			:on-success="submitAgreement"
			:on-close="cancelAgreement"
			:submit-on-click="false"
		/>
	</form>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import PersonInput from '@/shared/PersonInput.vue';
import MultiAddress from '@/shared/MultiAddress';
import AppDatepicker from '@/shared/AppDatepicker';
import DocumentInput from '@/shared/DocumentInput.vue';
import CheckboxList from '~/components/shared/CheckboxList.vue';
import Agreements from '@/Agreements/Agreements';
import { endpoints } from '~/config';
import { handleApiFormErrors } from '~/helpers';

import FormErrorField from '@/shared/Form/FormErrorField';

let personOptions = [
	{
		value: 'representative',
		title: 'Account user',
		description: 'A person who will have the permissions to use this account to make payments, order services, and similar.'
	},
	{
		value: 'director',
		title: 'Director',
		description: 'A person who is responsible for the management of the company and they may exercise all the powers of the company.'
	},
	{
		value: 'shareholder',
		title: 'Shareholder',
		description: 'A private person or legal entity that owns one or more shares of the company.'
	},
];

export default {
	name: 'VerifyPersonForm',
	components: { 
		MultiAddress, 
		PersonInput,
		AppDatepicker,
		DocumentInput,
		CheckboxList,
		Agreements,
		FormErrorField,
	},
	props: {
		buttonClass: {
			type: String,
			default: '',
		},
		buttonText: {
			type: String,
			default: 'Next',
		},
		allowSkip: {
			type: Boolean,
			default: true,
		},
		onSuccess: {
			type: Function,
			default: () => {},
		},
	},
	data() {
		return {
			nextEnabled: true,
			documents: [
				{
					displayName: 'Proof of residence',
					field: 'proofOfResidenceDocument',
					id: 'proofOfResidenceDocumentId',
					type: 'proof_of_residence_document',
					required: true,
				},
			],
			politicalOptions: [
				{
					value: 'none',
					name: 'I am not a politically exposed person',
				},
				{ 
					value: 'exposed', 
					name: 'I am a politically exposed person' 
				},
				{
					value: 'family_member',
					name: 'I am immediate family member of a politically exposed person',
				},
				{
					value: 'associate',
					name: 'I am a close associate of a politically exposed person',
				},
			],
			roleOptions: [
				{
					text: 'Company representative',
					value: 'representative',
					description: 'An authorized person who has prior approval to open an account on behalf of the company (i.e., CEO/Chairman).'
				},
				{
					text: 'Agent',
					value: 'agent',
					description: 'Any legal company or individual who is directing or referring clients to Verifo.'
				}
			],
			personOptions,
			registrationCityOptions: [],
			correspondenceCityOptions: [],
			optionPromises: [],
			data: {
				name: '',
				surname: '',
				keyPersons: [],
				mySelfTypes: [],
				countryForTax: '',
				placeOfBirth: '',
				addresses: {
					main: {},
					correspondence: {},
					useAddressForCorrespondence: true
				}
			},
			addPersonData: {
				type: null,
				options: [],
				person: {},
				index: null
			},
			formFilled: false,
		};
	},
	computed: {
		...mapGetters('user', ['isPersonal', 'isBusiness', 'owner']),
		...mapState('user', {
			details: (state) => state.selected,
			profile: (state) => state.selected.userProfile,
			persons: (state) => state.selected.keyPersonsList,
			company: (state) => state.selected.companyProfile
		}),
		...mapState('countries', {
			countryOptions: state => state.list
		}),
		formReady() {
			return Object.keys(this.data).length > 0;
		},
		isRepresentative() {
			return Boolean(this.data.is_representative == 1 ||
			this.data.director_of_company == 1 ||
			this.data.is_shareholder == 1);
		},
		formDisabled() {
			return this.details.verification == 'pending';
		}
	},
	watch: {
		'data.roleType': {
			handler(value) {
				if(value == 'agent' && this.data.mySelfTypes.length > 0) {
					let result = confirm(
						'Are you sure you want to delete yourself from key persons by selecting agent role?'
					);
					if (result) {
						this.data.mySelfTypes = [];
					} else {
						this.data.roleType = 'representative';
					}	
				}
			},
			deep: true
		},
		'addPersonData.type' : {
			handler(value) {
				if (!value) {
					this.resetAddPersonData();
				}
			},
		},
		'addPersonData.options' : {
			handler() {
				this.$set(this.addPersonData.person, 'entityType', null);
			},
			deep: true
		},
		details: {
			handler(details) {
				if (details.verification == 'pending') {
					this.$set(this.data, 'service_agreement', true);
					this.$set(this.data, 'privacy_policy', true);
				}
			},
			deep: true
		}
	},
	async mounted() {
		await this.getCountries();
		this.$gtag.event(`register_verification_person_information_${this.isBusiness ? 'business' : 'personal'}`);
		this.data = {
			...this.data,
			...this.profile,
			politicalExposure: this.profile.questionnaire.politicalExposure.value || null
		};

		if (this.isBusiness && this.persons.length > 0) {
			const relatedProfileId = this.persons.find(p => p.type == 'owner').relatedProfileId;
			const mySelfTypes = this.persons.filter(p => p.relatedProfileId == relatedProfileId && p.type !== 'owner').map(u => u.type);
			const otherPersons = this.persons.filter(p => p.relatedProfileId !== relatedProfileId).map(p => ({ ...p, _completed: true }));
			this.data.keyPersons = otherPersons;
			this.data.mySelfTypes = mySelfTypes;

			if (!!otherPersons.length || !!mySelfTypes.length) this.formFilled = true;
		}
		if (this.isBusiness && !this.data.roleType) {
			this.$set(this.data, 'roleType', this.company.isFromIntroducer ? 'agent' : 'representative');
		}

		setTimeout(() => this.errors.clear());
	},

	methods: {
		...mapActions('notifications', ['pushError']),
		...mapActions('user', ['submitVerifyPerson', 'updateProfile']),
		...mapActions('countries', ['getCountries']),
		setRole(value) {
			if (this.formDisabled) return;
			this.$set(this.data, 'roleType', value);
			// this.$forceUpdate();
		},
		displayPersonTypes(person) {
			if (Array.isArray(person.type)) {
				return person.type.map(v => v.toUpperCase()).join(', ');
			} else if  (typeof person.type == 'string') {
				return person.type.toUpperCase();
			}
		},
		validateChildren(component) {
			const validators = [];
			const children = [];

			function collectChildren(c) {
				if (c.$children?.length > 0) {
					children.push(...c.$children);
					c.$children.forEach(child => collectChildren(child));
				}
			}
			collectChildren(component);

			children.forEach(child => {
				const items = child.$validator._base.fields.items;
				const scopes = items.map(i => i.scope).reduce((a, b) => { if (!a.includes(b)) a.push(b); return a; }, []);
				if (scopes && scopes.length > 0) {
					scopes.forEach(scope => validators.push(child.$validator.validate(`${scope}.*`)));
				}
				validators.push(child.$validator.validateAll());});
			validators.push(this.$validator.validate());
			return validators;
		},
		validateKeyPersons() {
			//Validate yourself as representative
			if (this.isBusiness && this.data.roleType == 'representative' && this.data.mySelfTypes.length == 0) {
				this.pushError('You must add yourself as representative');
				return false;
			}
			
			//Validate key persons length
			if (this.isBusiness) {
				const typesMyself = _.uniq(this.data.mySelfTypes);
				const typesPersons = this.data.keyPersons.map(p => p.type).reduce((a, b) => a.concat(b), []);
				if (_.uniq([...typesMyself, ...typesPersons]).length < 3) {
					this.pushError('You must add all 3 person types to your key persons.');
					return false;
				}
			}

			return true;
		},
		async onsubmit() {
			if (this.formDisabled) {
				if (this.isBusiness) {
					this.$router.push({ name: 'business-questionnaire' });
				} else {
					this.$router.push({ name: 'person-questionnaire' });
				}

				return;
			}
			
			const docList = this.data.documentList;
			if (docList?.length > 0) {
				const doc = docList.find(d => {
					return d.type == this.documents[0].type;
				});
				if (doc) {
					this.data[this.documents[0].id] = doc.id;
				}
			}

			if (this.data.addresses.useAddressForCorrespondence) this.$set(this.data.addresses, 'correspondence', this.data.addresses.main);

			this.data.keyPersons.forEach(p => {
				if (p.addresses.useAddressForCorrespondence) this.$set(p.addresses, 'correspondence', p.addresses.main);
			});

			//Validate key persons
			if (this.isBusiness && this.$refs.persons) {
				let canProceed = true;
				const validArr = await this.$refs.persons.map(async (ref) => {
					return await ref.$validator.validate();
				});
				const valid = await Promise.all(validArr);
				canProceed = valid.reduce((a, b) => a && b, true);
				if (!canProceed) {
					this.pushError(
						'Please check the red fields.'
					);
					return;
				}
			}

			const personData = { ...this.data };

			let persons = [];

			personData.keyPersons && Array.isArray(personData.keyPersons) && personData.keyPersons.forEach(person => {
				if (typeof person.type == 'string') {
					persons.push(person);
				} else if (Array.isArray(person.type)) {
					person.type.forEach(type => {
						persons.push({
							...person,
							type
						});
					});
				}
			});

			personData.keyPersons = persons;

			this.nextEnabled = false;
			setTimeout(async () => {
				const keyPersonsValid = this.validateKeyPersons();
				const validators = this.validateChildren(this);
				const validatorsResult = await Promise.all(validators);
				const valid = validatorsResult.reduce((a, b) => a && b, true); //todo: find better way to validate
				if (valid && keyPersonsValid) {
					personData.isAgent = personData.roleType == 'agent' ? 1 : 0;

					let payload = personData;

					if (this.isBusiness) {
						payload = _.pick(personData, [
							'roleType',
							'name',
							'surname',
							'placeOfBirth',
							'dateOfBirth',
							'countryForTax',
							'politicalExposure',
							'isUsCitizen',
							'isNewsletterSubscribed',
							'addresses',
							'mySelfTypes',
							'keyPersons',
							'proofOfResidenceDocumentId',
							'proofOfResidenceDocument',
						]);
					} else {
						payload = _.pick(personData, [
							'name',
							'surname',
							'placeOfBirth',
							'countryForTax',
							'isUsCitizen',
							'isNewsletterSubscribed',
							'addresses',
							'proofOfResidenceDocumentId',
							'proofOfResidenceDocument',
						]);

					}

					try {
						await this.submitVerifyPerson(payload);
						if (personData.roleType !== 'agent') {
							await this.submitAgreements();
						}
						this.nextEnabled = true;

						if (this.isBusiness) {
							this.$router.push({ name: 'business-questionnaire' });
						} else {
							this.$router.push({ name: 'person-questionnaire' });
						}
					} catch (err) {
						this.nextEnabled = true;
						this.handleErrors(err, this.$validator.errors);
						this.pushError(err.message);
					}
				} else {
					this.nextEnabled = true;
					this.pushError('Please check the red fields');
				}
			});
		},
		async handleErrors(response, errors) {
			const fields = Object.keys(response.fields);
			const keyPersonFields = fields.filter(f => f.indexOf('keyPersons[') !== -1);
			if (keyPersonFields?.length > 0) {
				const kpField = keyPersonFields[0];
				const personIndex = kpField.match(/keyPersons\[([0-9]+)\]/)[1];
				await this.editPerson(this.data.keyPersons[personIndex], personIndex);
			}
			this.$nextTick(() => {
				handleApiFormErrors(response, errors);
			});

		},
		removeItem(item, index) {
			let result = confirm(
				'Are you sure you want to delete this ' + item.type + '?'
			);
			if (result) {
				this.data.keyPersons.splice(index, 1);
			}
		},
		async editPerson(person, i) {
			await this.$set(this.data, 'keyPersons', this.data.keyPersons.filter((v, index) => i !== index));
			this.addPersonData = {
				person,
				options: person.type,
				type: 'person',
				index: i
			};
			this.$nextTick(() => {
				document.getElementById('person-input').scrollIntoView({
					behavior: 'smooth',
					block: 'start'
				});
			});
		},
		removeItemMain() {
			let result = confirm(
				'Are you sure you want to delete yourself?'
			);
			if (result) {
				this.data.mySelfTypes = [];
			}
		},
		onDocumentChange(value, document) {
			return this.$set(this.data, document.field, value);
		},
		toggleMyselfOption(option) {
			let value = this.data.mySelfTypes;
			if (value.includes(option)) {
				value = value.filter(o => o !== option);
			} else {
				value.push(option);
			}
			this.$set(this.data, 'mySelfTypes', value);
		},
		togglePersonOption(option) {
			const exists = this.addPersonData.options.includes(option);
			if (exists) {
				this.$set(this.addPersonData, 'options', this.addPersonData.options.filter(v => v !== option));
			} else {
				this.addPersonData.options.push(option);
			}
		},
		async saveNewPerson() {
			const personTypes = this.addPersonData.options;
			const index = this.addPersonData.index;
			if (personTypes && personTypes.length > 0) {
				const ref = this.$refs.person;
				const valid = await ref.$validator.validate('person.*');
				if (valid) {
					const currentPersons = this.data.keyPersons;
					if (index) {
						currentPersons[index] = { ...this.addPersonData.person, type: personTypes };
					} else {
						currentPersons.push({ ...this.addPersonData.person, type: personTypes, index: this.data.keyPersons.length });
					}
					this.data.keyPersons = currentPersons;
					this.resetAddPersonData();
				}
			}
		},
		resetAddPersonData() {
			this.addPersonData = {
				type: null,
				options: [],
				person: {}
			};
			this.$forceUpdate();
		},
		submitAgreement(value) {
			this.$set(this.data, value, '1');
		},
		cancelAgreement(value) {
			this.$set(this.data, value, '0');
		},
		async submitAgreements() {
			const agreementTypes = [
				this.data.service_agreement && 'service_agreement',
				this.data.privacy_policy && 'privacy_policy',
			].filter(t => t);
			const options = {
				method: 'POST',
				url: endpoints.verification.customerAgreement,
				data: { 
					types: agreementTypes
				},
			};
			try {
				await axios(options);
			} catch (err) {
				this.pushError(err.message);
			}
		},
		documentId(type) {
			const doc = this.data.documentList?.find(d => d.type == type);
			return doc ? doc.id : false;
		}
	},
	provide() {
		return {
			$validator: this.$validator,
		};
	}
};
</script>

<style lang="scss" scoped>
	@import "./VerifyPerson.scss";
</style>

<style lang="scss">
	.persons-dropdown {
		& + .persons-dropdown {
			margin-left: 10px;
		}
		.dropdown-item {
			&.disabled {
				background-color: $primary;
				color: #fff;
				padding-top: 3px;
				padding-bottom: 3px;
			}
		}
	}
	#person-role {
	width: 100%;

	> .btn {
		width: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
</style>