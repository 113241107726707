<template>
	<form
		action="#"
		class="form-body margintop30"
		autocomplete="disable"
		
		@submit.prevent="onsubmit()"
	>
		<fieldset :disabled="details.verification == 'pending'">
			<b-row>
				<b-col>
					<form-error-field name="name">
						<label>
							Company name
						</label>
						<b-form-input
							id="name"
							v-model="data.name"
							v-validate="'required'"
							type="text"
							name="name"
							placeholder="Enter company name"
							debounce="500"
							data-registration="company-name"
						/>
					</form-error-field>
				</b-col>
			</b-row>
			<b-row v-if="!isInitialCapital">
				<b-col>
					<form-error-field name="code">
						<label>
							Legal entity code
						</label>
						<b-form-input
							id="code"
							v-model="data.registrationCode"
							v-validate="'required'"
							type="text"
							name="code"
							placeholder="Enter legal entity code"
							debounce="500"
							data-registration="legal-entity-node"
						/>
					</form-error-field>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<phone-input
						v-model="data.phone"
						data-registration="phone"
					/>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<form-error-field name="email">
						<label>
							Email (for communication during onboarding process)
						</label>
						<b-form-input
							id="email"
							v-model="data.email"
							v-validate="'required|email'"
							type="email"
							name="email"
							placeholder="Enter company email address"
							debounce="500"
							data-registration="email"
							novalidate
						/>
					</form-error-field>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<form-error-field name="legalForm"
						class="text-left mb-12"
					>
						<label>
							Legal form
						</label>
						<v-multiselect
							v-model="data.legalForm"
							v-validate="'required'"
							:options="legalOptions"
							:show-labels="false"
							:allow-empty="false"
							name="legalForm"
							label="name"
							placeholder="Select your legal form"
							track-by="name"
							return="value"
							data-registration="legal-form"
						/>
					</form-error-field>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<form-error-field name="website">
						<label>
							Website
						</label>
						<b-form-input
							id="website"
							v-model="data.website"
							v-validate="'required'"
							data-vv-validate-on="blur"
							type="text"
							class="form-control"
							name="website"
							placeholder="Enter website"
							debounce="500"
							data-registration="website"
						/>
					</form-error-field>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<form-error-field name="numberOfEmployees">
						<label>
							Number of employees
						</label>
						<b-form-input
							id="number_of_employees"
							v-model="data.numberOfEmployees"
							v-validate="'required|min_value:1'"
							data-vv-validate-on="blur"
							data-vv-as="Number of employees"
							type="number"
							class="form-control"
							name="numberOfEmployees"
							placeholder="Enter number of employees"
							debounce="500"
							data-registration="number-of-employees"
						/>
					</form-error-field>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<b-form-group
						:class="{ 'input-invalid': errors.has('vat_number') }"
					>
						<label>
							VAT Number
						</label>
						<b-form-input
							id="vat_number"
							v-model="data.vatNumber"
							type="text"
							class="form-control"
							name="vat_number"
							placeholder="Enter VAT number"
							debounce="500"
							data-registration="vat"
						/>
					</b-form-group>
					<div
						v-show="errors.has('vat_number')"
						class="errormsg"
					>
						<span>{{ errors.first("vat_number") }}</span>
					</div>
				</b-col>
			</b-row>
			<b-row v-if="!isInitialCapital">
				<b-col>
					<form-error-field name="registrationDate">
						<label>
							Date of incorporation
						</label>
						<app-datepicker
							v-model="data.registrationDate"
							v-validate="'required'"
							name="registrationDate"
							data-registration="date-of-incorporation"
						/>
					</form-error-field>
				</b-col>
			</b-row>
			<multi-address :addresses="data.addresses" />
		</fieldset>

		<notification />
		<b-row>
			<b-col class="text-center mb-4">
				<input
					:class="buttonClass"
					:value="buttonText"
					type="submit"
					class="btn btn-primary btn-rounded btn-upper w-100"
					data-registration="next"
				/>
			</b-col>
		</b-row>
		<b-row v-if="allowSkip">
			<b-col class="text-center">
				<router-link 
					:to="{name: 'home'}" 
					tag="a" 
					class="framelink skiplink" 
					exact
					data-registration="skip"
				>
					<span v-if="details.verification !== 'pending'">Skip verification</span>
					<span v-if="details.verification == 'pending'">Back to main page</span>
				</router-link>
			</b-col>
		</b-row>
	</form>
</template>
<script>
import MultiAddress from '@/shared/MultiAddress';
import AppDatepicker from '@/shared/AppDatepicker';
import { mapActions, mapGetters, mapState } from 'vuex';
import PhoneInput from '@/shared/PhoneInput';
import FormErrorField from '@/shared/Form/FormErrorField';
import { handleApiFormErrors } from '~/helpers';

export default {
	name: 'BusinessInformationForm',
	components: { MultiAddress, AppDatepicker, PhoneInput, FormErrorField },
	props: {
		buttonClass: {
			type: String,
			default: '',
		},
		buttonText: {
			type: String,
			default: 'Next',
		},
		allowSkip: {
			type: Boolean,
			default: true,
		},
		onSuccess: {
			type: Function,
			default: () => {},
		},
	},
	data: function () {
		return {
			legalOptions: [
				{ value: 'sole_individual', name: 'Sole individual' },
				{ value: 'partnership', name: 'Partnership' },
				{ value: 'holding_company', name: 'Holding company' },
				{ value: 'non_profit', name: 'Non-profit organization' },
				{
					value: 'limited_liability',
					name: 'Limited Liability Company',
				},
				{ value: 'other', name: 'Other' },
			],
			data: {
				addresses: {},
				legalForm: ''
			},
		};
	},
	computed: {
		...mapState('user', {
			company: (state) => state.selected.companyProfile,
			details: (state) => state.selected
		}),
		...mapState('countries', {
			countryOptions: state => state.list
		}),
		...mapGetters('user', [
			'isPersonal',
			'isBusiness',
			'accountInfoUse'
		]),
		formValid() {
			return this.errors.items.length == 0;
		},
		isInitialCapital() {
			return !!this.accountInfoUse?.includes('initial_capital');
		}
	},
	watch: {
		data: {
			handler(value, oldValue) {
				this.$set(this.data, 'email', value.email && value.email.toLowerCase().replace(/\s/g, ''));
				if (Object.keys(oldValue).length !== 0) {
					this.$validator.reset();
					this.updateCompany(value);
				}
			},
			deep: true,
		},
	},
	async mounted() {
		await this.getCountries();
		this.$gtag.event(`register_verification_company_info_${this.isBusiness ? 'business' : 'personal'}`);
		this.data = { 
			...this.company,
			phone: this.company.contacts?.mainPhone,
			email: this.company.contacts?.mainEmail,
			website: this.company.contacts?.website
		};
		this.data.name =
			this.data.name === 'to be filled' ? '' : this.data.name;
		this.data.code =
			this.data.code === 'to be filled' ? '' : this.data.code;
	},
	
	methods: {
		...mapActions('user', ['updateCompany', 'submitCompanyInfo']),
		...mapActions('countries', ['getCountries']),
		...mapActions('notifications', ['pushError']),
		validateChildren(component) {
			const validators = [];
			const children = [];

			function collectChildren(c) {
				if (c.$children?.length > 0) {
					children.push(...c.$children);
					c.$children.forEach(child => collectChildren(child));
				}
			}
			collectChildren(component);

			children.forEach(child => {
				const items = child.$validator._base.fields.items;
				const scopes = items.map(i => i.scope).reduce((a, b) => { if (!a.includes(b)) a.push(b); return a; }, []);
				if (scopes && scopes.length > 0) {
					scopes.forEach(scope => validators.push(child.$validator.validate(`${scope}.*`)));
				}
				validators.push(child.$validator.validateAll());});
			validators.push(this.$validator.validate());
			return validators;
		},

		onsubmit() {
			if (this.details.verification == 'pending') {
				this.$router.push({ name: 'verify-person' });
				return;
			}
			setTimeout(async () => {
				try {
					const validators = this.validateChildren(this);
					const validatorsResult = await Promise.all(validators);
					const valid = validatorsResult.reduce((a, b) => a && b, true); //todo: find better way to validate
					if (!valid) return this.pushError('Please check the red fields');
					const payload = _.pick(this.data, [
						'name',
						'registrationCode',
						'registrationDate',
						'legalForm',
						'website',
						'numberOfEmployees',
						'vatNumber',
						'addresses',
						'email',
						'phone'
					]);
					await this.submitCompanyInfo(payload);

					this.$router.push({ name: 'verify-person' });
				} catch (err) {
					handleApiFormErrors(err, this.errors);
					this.pushError(err.message);
				}
			});

		},
	},
	provide() {
		return {
			$validator: this.$validator,
		};
	}
};
</script>