var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "acc-info-modal",
      "centered": "",
      "hide-header": "",
      "size": "sm"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hide = _ref.hide;
        return [_c('div', {
          staticClass: "account-info-wrapper"
        }, [_c('div', {
          staticClass: "account-info-header"
        }, [_c('div', [_c('h5', [_vm._v("Account information")])])]), _c('div', {
          ref: "accountInfo",
          staticClass: "account-information-items"
        }, [_c('div', {
          staticClass: "account-information-item"
        }, [_c('h5', {
          staticClass: "account-information-title"
        }, [_vm._v(" Beneficiary ")]), _c('div', {
          staticClass: "account-information-text-wrapper"
        }, [_c('h4', {
          staticClass: "account-information-text"
        }, [_vm._v(" " + _vm._s(_vm.username) + " ")]), _c('ion-icon', {
          staticClass: "copy-icon",
          attrs: {
            "name": "copy-outline"
          },
          on: {
            "click": function click($event) {
              return _vm.copy(_vm.username);
            }
          }
        })], 1)]), _c('div', {
          staticClass: "account-information-item"
        }, [_c('h5', {
          staticClass: "account-information-title"
        }, [_vm._v(" IBAN ")]), _c('div', {
          staticClass: "account-information-text-wrapper"
        }, [_c('h4', {
          staticClass: "account-information-text"
        }, [_vm._v(" " + _vm._s(_vm.currentWallet.iban) + " ")]), _c('ion-icon', {
          staticClass: "copy-icon",
          attrs: {
            "name": "copy-outline"
          },
          on: {
            "click": function click($event) {
              return _vm.copy(_vm.currentWallet.iban);
            }
          }
        })], 1)]), _c('div', {
          staticClass: "account-information-item"
        }, [_c('h5', {
          staticClass: "account-information-title"
        }, [_vm._v(" BIC ")]), _c('div', {
          staticClass: "account-information-text-wrapper"
        }, [_c('h4', {
          staticClass: "account-information-text"
        }, [_vm._v(" " + _vm._s(_vm.bic) + " ")]), _c('ion-icon', {
          staticClass: "copy-icon",
          attrs: {
            "name": "copy-outline"
          },
          on: {
            "click": function click($event) {
              return _vm.copy(_vm.bic);
            }
          }
        })], 1)]), _c('div', {
          staticClass: "account-information-item"
        }, [_c('h5', {
          staticClass: "account-information-title"
        }, [_vm._v(" Account type ")]), _c('div', {
          staticClass: "account-information-text-wrapper"
        }, [_c('h4', {
          staticClass: "account-information-text"
        }, [_vm._v(" " + _vm._s(_vm.currentWalletType) + " ")]), _c('ion-icon', {
          staticClass: "copy-icon",
          attrs: {
            "name": "copy-outline"
          },
          on: {
            "click": function click($event) {
              return _vm.copy(_vm.currentWalletType);
            }
          }
        })], 1)])])])];
      }
    }, {
      key: "modal-footer",
      fn: function fn(_ref2) {
        var hide = _ref2.hide;
        return [_c('b-button', {
          attrs: {
            "variant": "btn btn-info"
          },
          on: {
            "click": function click($event) {
              return hide('forget');
            }
          }
        }, [_vm._v(" Close ")])];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }