<template>
	<div>
		<iframe
			v-if="iframeLoaded && isDesktop && isVerified === false"
			id="iframe"
			:src="iframeUrl"
			style="width:100%; height:800px; border: none;"
			data-registration="iframe"
			allow="camera;microphone"
		></iframe>
		<div
			v-if="iframeLoaded && !isDesktop && isVerified === false"
			class="text-center"
		>
			<div class="verify-photo__notification">
				<ion-icon name="camera-outline" />
				<span>Press on a button to finish your verification.</span>
			</div>
			<div>
				<b-button
					variant="primary"
					:href="iframeUrl"
					target="_blank"
					class="mt-4 mb-4"
				>
					Verify documents and photo
				</b-button>
			</div>
		</div>
		<b-container>
			<b-row class="first-button mt-2">
				<b-col
					cols="10"
					offset="1"
				>
					<notification />
					<input
						:class="buttonClass"
						:disabled="finishedEnable === false"
						:value="buttonText"
						class="btn btn-primary w-100"
						type="submit"
						data-registration="next"
						@click="finishedRoute()"
					/>
				</b-col>
			</b-row>
			<b-row
				v-if="allowSkip"
				style="text-align: center;"
			>
				<b-col
					cols="10"
					offset="1"
					class="mt-3 align-middle"
				>
					<div>
						<div class="mb-2">
							or
						</div>
						<router-link
							:to="{ name: 'home' }"
							tag="a"
							class="framelink skiplink"
							exact
							data-registration="skip"
							@click="$forceUpdate()"
						>
							Skip verification
						</router-link>
					</div>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
export default {
	name: 'VerifyPhoto',
	props: {
		buttonClass: {
			type: String,
			default: ''
		},
		buttonText: {
			type: String,
			default: 'Finish'
		},
		allowSkip: {
			type: Boolean,
			default: true
		},
	},
	data: function () {
		return {
			iframeLoaded: false,
			iframeUrl: null,
			finishedEnable: false,
			interval: null,
			isDesktop: false,
			isVerified: false,
		};
	},
	computed: {
		...mapGetters('user', ['isPersonal', 'isBusiness', 'owner']),
		...mapState('user', {
			details: state => state.selected
		}),
	},
	mounted() {
		this.getIframeUrl()
			.then(response => {
				if (response) {
					this.iframeUrl = response.url;
					this.iframeLoaded = true;
					this.isVerified = response.verified;
					this.initCheckStatus();
					this.checkUserAgent();
				}
			}).catch(err => {
				this.pushError(err);
			});
		this.$gtag.event(`register_verification_identity_${this.isBusiness ? 'business' : 'personal'}`);
	},
	methods: {
		...mapActions('user', ['setVerified', 'getCurrentProfile']),
		...mapActions('notifications', ['pushError']),
		...mapActions('identity', ['checkStatus', 'getIframeUrl']),
		checkUserAgent() {
			const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
			this.isDesktop = !isMobile;
		},
		async initCheckStatus() {
			if (this.isVerified) {
				await this.getCurrentProfile();
				this.iframeLoaded = false;
				this.finishedEnable = true;
			} else {
				this.interval = setInterval(async () => {
					if (this.iframeLoaded) {
						try {
							const response = await this.checkStatus();
							//If remote user id is set that means user identification is finished (status could be pending) and waiting results
							if (response && response.verification === 'pending') {
								await this.getCurrentProfile();
								this.iframeLoaded = false;
								this.finishedEnable = true;
							}
						} catch (err) {
							this.pushError(err);
						}
					}
				}, 10000);
			}
		},
		async finishedRoute() {
			this.$gtag.event(`register_verification_success_${this.isBusiness ? 'business' : 'personal'}`);
			this.$router.push({ name: 'home' });
		}
	},
	beforeDestroy() {
		clearInterval(this.interval);
	}
};
</script>

<style lang="scss">
	.verify-photo {
		&__notification {
			background-color: #e3f3f4;
			padding: 16px 20px;
			display: inline-flex;
			align-items: center;
			margin-top: 20px;
			border-radius: 3px;
			justify-content: space-between;
			color: $primary;

			ion-icon {
				padding-right: 15px;
			}
		}
	}
</style>