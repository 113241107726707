var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('notifications-wrapper'), _c('router-view'), _c('cookies-notification'), _c('info-message-popoup'), _vm.DEV ? _c('div', {
    staticClass: "web-status"
  }, [_c('div', [_vm._v(_vm._s(_vm.env))]), _c('pre', [_vm._v(_vm._s(_vm.api))])]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }