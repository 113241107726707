<template>
	<div>
		<iframe
			v-if="iframeLoaded"
			id="iframe"
			:src="iframeUrl"
			style="width:100%; height:800px; border: none;"
			allow="camera;microphone"
		></iframe>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
	name: 'Idenfy',
	props: {},
	data: function () {
		return {
			iframeLoaded: false,
			iframeUrl: null,
			finished: false,
		};
	},
	mounted() {
		this.getIframeUrl().then(response => {
			if (response && response.url) {
				this.iframeUrl = response.url;
				this.iframeLoaded = true;
			}
		});

		this.initCheckStatus();
	},
	methods: {
		...mapActions('identity', ['getIframeUrl', 'checkStatus']),
		initCheckStatus() {
			setInterval(() => {
				if (this.iframeLoaded) {
					this.checkStatus().then(response => {
						//If remote user id is set that means user identification is finished (status could be pending) and waiting results
						if (response && response.verification === 'pending') {
							this.finishedEnable = true;
							this.$router.push({ name: 'home' });
						}
					});
				}
			}, 10000);
		}
	},
};
</script>


