import LayoutVerification from '@/LayoutVerification/LayoutVerification.vue';
import VerifyPerson from '@/LayoutVerification/VerifyPerson/VerifyPerson.vue';
import VerifyAccountInfo from '@/LayoutVerification/VerifyAccountInfo/VerifyAccountInfo.vue';
import VerifyBusiness from '@/LayoutVerification/VerifyBusiness/VerifyBusiness.vue';
import VerifyBusinessDocuments from '@/LayoutVerification/VerifyBusinessDocuments/VerifyBusinessDocuments.vue';
import VerifyPhoto from '@/LayoutVerification/VerifyPhoto/VerifyPhoto.vue';
import QuestionnaireBusiness from '@/LayoutVerification/QuestionnaireBusiness/QuestionnaireBusiness.vue';
import QuestionnairePerson from '@/LayoutVerification/QuestionnairePerson/QuestionnairePerson.vue';

export default {
	path: '/verify',
	name: 'verify',
	redirect: '/home',
	component: LayoutVerification,
	meta: {
		requiresAuth: true
	},
	children: [
		{
			path: 'person',
			name: 'verify-person',
			component: VerifyPerson,
			meta: {
				requiresAuth: true
			}
		},
		{
			path: 'questionnaire',
			name: 'person-questionnaire',
			component: QuestionnairePerson,
			meta: {
				requiresAuth: true
			}
		},
		{
			path: 'questionnaire-business',
			name: 'business-questionnaire',
			component: QuestionnaireBusiness,
			meta: {
				requiresAuth: true
			}
		},
		{
			path: 'photo',
			name: 'verify-photo',
			component: VerifyPhoto,
			meta: {
				requiresAuth: true
			}
		},
		{
			path: 'documents',
			name: 'verify-business-documents',
			component: VerifyBusinessDocuments,
			meta: {
				requiresAuth: true
			}
		},
		{
			path: 'business',
			name: 'verify-business',
			component: VerifyBusiness,
			meta: {
				requiresAuth: true
			}
		},
		{
			path: 'account',
			name: 'verify-account',
			component: VerifyAccountInfo,
			meta: {
				requiresAuth: true
			}
		}
	]
};
