<template>
	<div>
		<div class="tabs-bar">
			<div
				v-for="(tab, i) in tabs" 
				:key="i" 
				class="tab-title" 
				:active="selectedIndex === i" 
				:disabled="tab.disabled"
				@click="selectTab(i, tab)"
			>
				{{ tab.title }}
			</div>
		</div>
		<slot></slot>
	</div>
</template>
<script>
export default {
	name: 'Tabs',
	props: {
	},
	data() {
		return {
			selectedTitle: null,
			selectedIndex: 0,
			tabs: []
		};
	},
	mounted() {
		// const activeTab = this.tabs.find(tab => tab.active);
		if (this.tabs.length > 0) {
			let tabSelectIndex = 0;
			const activeTab = this.tabs.findIndex(t => ({ ...t }._props.active));
			if (activeTab > -1) {
				tabSelectIndex = activeTab;
			}
			this.selectTab(tabSelectIndex);
		}
	},
	created() {
		this.tabs = this.$children;
	},
	methods: {
		selectTab(i, tab) {
			if ( !tab || (tab && !tab.disabled)) {
				this.selectedIndex = i;
				this.tabs.forEach((tab, index) => {
					tab.isActive = index === i;
				});
				tab && tab.$listeners.click && tab.$listeners.click();
			}
		}
	}
};
</script>
<style lang="scss" src="./Tabs.scss">
	
</style>