var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._m(0), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "9"
    }
  }, [_vm.user.userProfile ? _c('profile-account-owner', {
    attrs: {
      "data": _vm.user.userProfile
    }
  }) : _vm._e(), _vm.isBusiness ? _c('profile-business-info', {
    attrs: {
      "data": _vm.user.companyProfile
    }
  }) : _vm._e(), _vm.isBusiness ? _c('profile-business-adresses', {
    attrs: {
      "data": _vm.user.companyProfile
    }
  }) : _vm._e(), _c('edit-info')], 1)], 1)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-title-container"
  }, [_c('h2', [_vm._v("Profile")])]);
}]

export { render, staticRenderFns }