var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isActive && !_vm.disabled ? _c('div', {
    staticClass: "tab"
  }, [_vm._t("default")], 2) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }