var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    attrs: {
      "id": "body"
    }
  }, [_c('div', {
    staticClass: "auth-container"
  }, [_c('div', {
    staticClass: "auth-form"
  }, [_c('div', {
    staticClass: "logo"
  }, [_c('a', {
    attrs: {
      "href": _vm.logoUri
    }
  }, [_vm._v(_vm._s(_vm.siteName))])]), _c('transition', {
    attrs: {
      "name": "contract",
      "mode": "out-in"
    }
  }, [_c('router-view')], 1), _c('div', {
    staticClass: "auth-footer"
  }, [_vm.WHITELABEL == 'verifo' ? _c('span', [_vm._v("© 2017-" + _vm._s(_vm.currentYear))]) : _vm._e(), _vm.WHITELABEL !== 'verifo' ? _c('span', [_vm._v("© " + _vm._s(_vm.currentYear))]) : _vm._e(), _vm._v(" " + _vm._s(_vm.siteName) + " ")])], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }