var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('transition', {
    attrs: {
      "name": "fade",
      "mode": "out-in"
    }
  }, [_vm.item ? _c('div', {
    staticClass: "transactions-list-item-details"
  }, [_c('i', {
    staticClass: "fas fa-times transactions-list-item-details__close",
    on: {
      "click": _vm.close
    }
  }), _c('b-card', {
    staticClass: "transactions-list-item-details__card"
  }, [_c('div', {
    staticClass: "transactions-list-item-details__icon"
  }, [!['declined', 'failed', 'refund'].includes(_vm.item.status) ? _c('img', {
    attrs: {
      "src": "images/img/income.svg"
    }
  }) : _vm._e(), ['declined', 'failed'].includes(_vm.item.status) ? _c('ion-icon', {
    staticStyle: {
      "color": "red"
    },
    attrs: {
      "name": "close-outline"
    }
  }) : _vm._e(), ['refund'].includes(_vm.item.status) ? _c('ion-icon', {
    attrs: {
      "name": "refresh-outline"
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "transactions-list-item-details__amount",
    "class": {
      'transactions-list-item-details__amount--failed': ['declined', 'failed'].includes(_vm.item.status)
    }
  }, [_c('span', {
    "class": {
      'greenStatus': _vm.item.status === 'completed'
    }
  }, [_vm._v(" " + _vm._s(_vm.item.status == 'completed' ? '+' : '') + _vm._s(_vm.item.amount) + " "), _c('span', {
    staticClass: "transactions-list-item-details__currency"
  }, [_vm._v(_vm._s(_vm.item.currencyId))])])]), _c('hr'), _c('div', {
    staticClass: "transactions-list-item-details__additional-data"
  }, [_c('div', [_vm._v("Payment details:")]), _c('span', [_vm._v(_vm._s(_vm.item.details))]), _c('hr'), _vm.item.id ? _c('div', _vm._l(_vm.list.common, function (details) {
    return _c('div', {
      key: details.label
    }, [details.value(_vm.item) ? _c('div', {
      staticClass: "transactions-list-item-details__row"
    }, [_c('span', [_vm._v(_vm._s(details.label) + ":")]), _c('span', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover.v-info.left",
        modifiers: {
          "hover": true,
          "v-info": true,
          "left": true
        }
      }, {
        name: "clipboard",
        rawName: "v-clipboard",
        value: details.value(_vm.item),
        expression: "details.value(item)"
      }, {
        name: "clipboard",
        rawName: "v-clipboard:success",
        value: function value() {
          return _vm.pushSuccess({
            title: 'Copied to clipboard:',
            text: details.value(_vm.item)
          });
        },
        expression: "() => pushSuccess({title: 'Copied to clipboard:', text: details.value(item)})",
        arg: "success"
      }],
      attrs: {
        "title": "Click to copy"
      }
    }, [_vm._v(_vm._s(details.value(_vm.item)))])]) : _vm._e()]);
  }), 0) : _vm._e()])]), _c('span', {
    staticClass: "transactions-list-item-details__id"
  }, [_vm._v("Payment Request ID: " + _vm._s(_vm.item.id))])], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }