<template>
	<div>
		<div class="auth-card">
			<h1 class="auth-title">
				Registration
			</h1>
			<register-form v-if="showForm" />
			<register-success 
				v-if="showSuccess" 
			/>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import RegisterForm from './RegisterForm.vue';
import RegisterSuccess from './RegisterSuccess.vue';

export default {
	name: 'Register',
	components: {
		RegisterForm, 
		RegisterSuccess
	},
	data: function() {
		return {
			WHITELABEL
		};
	},
	computed: {
		...mapState('register', ['showSuccess', 'showForm']),
	},
	methods: {
		...mapActions('register', ['showErrorNotification']),
		download(path) {
			window.open(path, '_blank');
		},
	},
	mounted() {
		if (window.sessionMessage) {
			let msg = window.sessionMessage.slice(0);
			delete window.sessionMessage;
			this.showErrorNotification(msg);
		}
	},
};
</script>
