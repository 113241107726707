var render = function () {
  var _vm$data$otherBankLis;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('form', {
    staticClass: "form-body margintop30",
    attrs: {
      "action": "#"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSubmit();
      }
    }
  }, [_c('h2', {
    staticClass: "page-title"
  }, [_vm._v(" Primary account information ")]), _c('fieldset', {
    attrs: {
      "disabled": _vm.details.verification == 'pending'
    }
  }, [_c('label', {
    staticClass: "registration-section-heading font-weight-bold mb-3"
  }, [_vm._v(" What is your occupation? ")]), _c('div', [_vm._l(_vm.data.occupationList, function (occupation, occupationIndex) {
    return _c('b-card', {
      key: occupationIndex,
      staticClass: "mb-4"
    }, [_c('div', [_c('b-row', [_c('b-col', [_c('form-error-field', {
      attrs: {
        "name": "occupationList[".concat(occupationIndex, "].value"),
        "wrapper": "b-row",
        "mb": "0"
      }
    }, _vm._l(_vm.options.occupationTypes, function (occupationItem, index) {
      return _c('b-col', {
        key: occupationItem.value,
        staticClass: "mb-2",
        attrs: {
          "sm": "6"
        }
      }, [_c('b-form-radio', {
        directives: [{
          name: "validate",
          rawName: "v-validate",
          value: 'required',
          expression: "'required'"
        }],
        attrs: {
          "name": "occupationList[".concat(occupationIndex, "].value"),
          "value": occupationItem.value,
          "data-registration": "occupation-".concat(index, "-").concat(occupationIndex)
        },
        on: {
          "change": function change($event) {
            return _vm.checkOccupationFields(occupationIndex);
          }
        },
        model: {
          value: occupation.value,
          callback: function callback($$v) {
            _vm.$set(occupation, "value", $$v);
          },
          expression: "occupation.value"
        }
      }, [_vm._v(" " + _vm._s(occupationItem.name) + " ")])], 1);
    }), 1), _c('form-error-field', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: occupation.value === 'other',
        expression: "occupation.value === 'other'"
      }],
      staticClass: "pt-2",
      attrs: {
        "name": "occupationList[".concat(occupationIndex, "].otherValue")
      }
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: occupation.otherValue,
        expression: "occupation.otherValue"
      }, {
        name: "validate",
        rawName: "v-validate",
        value: _vm.requiredOtherOccupation(occupationIndex),
        expression: "requiredOtherOccupation(occupationIndex)"
      }],
      staticClass: "form-control",
      attrs: {
        "id": "occupation_other",
        "type": "text",
        "name": "occupationList[".concat(occupationIndex, "].otherValue"),
        "placeholder": "Specify other occupation",
        "maxlength": "200",
        "data-registration": "occupation-other"
      },
      domProps: {
        "value": occupation.otherValue
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) { return; }

          _vm.$set(occupation, "otherValue", $event.target.value);
        }
      }
    })])], 1)], 1)], 1), _vm.isRequiredCompanyActivity(occupationIndex) ? _c('div', [_c('div', [_c('b-row', {
      staticClass: "mt-4"
    }, [_c('b-col', [_c('div', {
      staticClass: "form-group mb-0"
    }, [_c('label', [_vm._v(" Activity of the company/institution: ")])]), _c('form-error-field', {
      attrs: {
        "name": "occupationList[".concat(occupationIndex, "].companyActivities.values"),
        "wrapper": "b-row",
        "mb": "0"
      }
    }, _vm._l(_vm.options.companyActivityTypes, function (companyActivityItem, index) {
      return _c('b-col', {
        key: companyActivityItem.value,
        staticClass: "mb-2",
        attrs: {
          "sm": "6",
          "md": "4"
        }
      }, [_c('b-form-checkbox', {
        directives: [{
          name: "validate",
          rawName: "v-validate",
          value: _vm.isRequiredCompanyActivity(occupationIndex) ? 'required' : '',
          expression: "isRequiredCompanyActivity(occupationIndex) ?'required' : ''"
        }],
        attrs: {
          "name": "occupationList[".concat(occupationIndex, "].companyActivities.values"),
          "value": companyActivityItem.value,
          "data-registration": "company-activity-".concat(index, "-").concat(occupationIndex)
        },
        model: {
          value: occupation.companyActivities.values,
          callback: function callback($$v) {
            _vm.$set(occupation.companyActivities, "values", $$v);
          },
          expression: "occupation.companyActivities.values"
        }
      }, [_vm._v(" " + _vm._s(companyActivityItem.name) + " ")])], 1);
    }), 1), _c('form-error-field', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: occupation.companyActivities.values.includes('other'),
        expression: "occupation.companyActivities.values.includes('other')"
      }],
      attrs: {
        "name": "occupationList[".concat(occupationIndex, "].companyActivities.otherValue")
      }
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: occupation.companyActivities.otherValue,
        expression: "occupation.companyActivities.otherValue"
      }, {
        name: "validate",
        rawName: "v-validate",
        value: _vm.requiredOtherCompanyActivity(occupationIndex),
        expression: "requiredOtherCompanyActivity(occupationIndex)"
      }],
      staticClass: "form-control",
      attrs: {
        "id": "company_activity_other",
        "type": "text",
        "name": "occupationList[".concat(occupationIndex, "].companyActivities.otherValue"),
        "placeholder": "Specify other company activity",
        "maxlength": "200",
        "data-registration": "company-activity-other"
      },
      domProps: {
        "value": occupation.companyActivities.otherValue
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) { return; }

          _vm.$set(occupation.companyActivities, "otherValue", $event.target.value);
        }
      }
    })])], 1)], 1)], 1)]) : _vm._e(), _vm.isRequiredCompanyPosition(occupationIndex) ? _c('div', [_c('b-row', {
      staticClass: "mt-4"
    }, [_c('b-col', {
      staticClass: "mb-4"
    }, [_c('div', {
      staticClass: "form-group mb-0"
    }, [_c('label', [_vm._v(" Your position in the Company: ")])]), _c('form-error-field', {
      attrs: {
        "name": "occupationList[".concat(occupationIndex, "].companyPosition.values"),
        "wrapper": "b-row",
        "mb": "0"
      }
    }, _vm._l(_vm.options.companyPositions, function (companyPosition, index) {
      return _c('b-col', {
        key: companyPosition.value,
        staticClass: "mb-2",
        attrs: {
          "sm": "6"
        }
      }, [_c('b-form-radio', {
        directives: [{
          name: "validate",
          rawName: "v-validate",
          value: _vm.isRequiredCompanyPosition(occupationIndex) ? 'required' : '',
          expression: "isRequiredCompanyPosition(occupationIndex) ? 'required' : ''"
        }],
        attrs: {
          "name": "occupationList[".concat(occupationIndex, "].companyPosition.values"),
          "value": companyPosition.value,
          "data-registration": "position-".concat(index, "-").concat(occupationIndex)
        },
        model: {
          value: occupation.companyPosition.value,
          callback: function callback($$v) {
            _vm.$set(occupation.companyPosition, "value", $$v);
          },
          expression: "occupation.companyPosition.value"
        }
      }, [_vm._v(" " + _vm._s(companyPosition.name) + " ")])], 1);
    }), 1), _c('form-error-field', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: occupation.companyPosition.value === 'other',
        expression: "occupation.companyPosition.value === 'other'"
      }],
      staticClass: "pt-2",
      attrs: {
        "name": "occupationList[".concat(occupationIndex, "].companyPosition.otherValue")
      }
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: occupation.companyPosition.otherValue,
        expression: "occupation.companyPosition.otherValue"
      }, {
        name: "validate",
        rawName: "v-validate",
        value: _vm.requiredOtherPosition(occupationIndex),
        expression: "requiredOtherPosition(occupationIndex)"
      }],
      staticClass: "form-control",
      attrs: {
        "id": "occupation_other",
        "type": "text",
        "name": "occupationList[".concat(occupationIndex, "].companyPosition.otherValue"),
        "placeholder": "Specify other position",
        "maxlength": "200",
        "data-registration": "position-other"
      },
      domProps: {
        "value": occupation.companyPosition.otherValue
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) { return; }

          _vm.$set(occupation.companyPosition, "otherValue", $event.target.value);
        }
      }
    })])], 1)], 1), _c('b-row', [_c('b-col', [_c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.errors.has("position-".concat(occupationIndex)),
        expression: "errors.has(`position-${occupationIndex}`)"
      }],
      staticClass: "errormsg"
    }, [_c('span', [_vm._v("Please select your position in the Company")])])])], 1)], 1) : _vm._e(), _vm.isRequiredInstitutionPosition(occupationIndex) ? _c('div', [_c('div', {
      staticClass: "form-group mb-0"
    }, [_c('label', [_vm._v(" Your position in the Institution: ")])]), _c('div', [_c('b-row', [_c('b-col', [_c('form-error-field', {
      attrs: {
        "name": "occupationList[".concat(occupationIndex, "].institutionPosition")
      }
    }, [_c('b-form-input', {
      directives: [{
        name: "validate",
        rawName: "v-validate",
        value: 'required',
        expression: "'required'"
      }],
      ref: "autocomplete",
      refInFor: true,
      attrs: {
        "id": "position_of_the_institution",
        "type": "text",
        "name": "occupationList[".concat(occupationIndex, "].institutionPosition"),
        "placeholder": "Position of the institution",
        "data-registration": "institution-position"
      },
      model: {
        value: occupation.institutionPosition,
        callback: function callback($$v) {
          _vm.$set(occupation, "institutionPosition", $$v);
        },
        expression: "occupation.institutionPosition"
      }
    })], 1)], 1)], 1)], 1)]) : _vm._e(), _vm.isRequiredCompanyName(occupationIndex) ? _c('div', [_c('div', {
      staticClass: "form-group mb-0"
    }, [_c('label', [_vm._v(" Name of the company/institution: ")])]), _c('div', [_c('b-row', [_c('b-col', [_c('form-error-field', {
      attrs: {
        "name": "occupationList[".concat(occupationIndex, "].institutionName")
      }
    }, [_c('b-form-input', {
      directives: [{
        name: "validate",
        rawName: "v-validate",
        value: 'required',
        expression: "'required'"
      }],
      ref: "autocomplete",
      refInFor: true,
      attrs: {
        "id": "name_of_company",
        "type": "text",
        "name": "occupationList[".concat(occupationIndex, "].institutionName"),
        "placeholder": "Name of the company/institution",
        "data-registration": "company-name"
      },
      model: {
        value: occupation.institutionName,
        callback: function callback($$v) {
          _vm.$set(occupation, "institutionName", $$v);
        },
        expression: "occupation.institutionName"
      }
    })], 1)], 1)], 1)], 1)]) : _vm._e(), occupationIndex > 0 ? _c('div', {
      staticClass: "occupation-remove-button mt-0"
    }, [_c('b-button', {
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function click() {
          return _vm.removeOccupation(occupationIndex);
        }
      }
    }, [_vm._v(" ✕ Remove ")])], 1) : _vm._e()]);
  }), _c('b-button', {
    staticClass: "mb-4",
    attrs: {
      "variant": "primary",
      "disabled": !_vm.data.occupationList[0].value
    },
    on: {
      "click": _vm.addOccupation
    }
  }, [_vm._v(" Add extra occupation ")])], 2), _c('div', [_c('b-row', [_c('b-col', [_c('form-error-field', {
    staticClass: "text-left mb-12",
    attrs: {
      "name": "sourceOfIncome.values"
    }
  }, [_c('label', [_vm._v(" Source of income: ")]), _c('v-multiselect', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "options": _vm.options.sourceOfIncome,
      "multiple": true,
      "close-on-select": false,
      "clear-on-select": false,
      "preserve-search": true,
      "preselect-first": false,
      "placeholder": "Make selection",
      "name": "sourceOfIncome.values",
      "label": "name",
      "track-by": "name",
      "return": "value",
      "data-registration": "source-of-income"
    },
    model: {
      value: _vm.data.sourceOfIncome.values,
      callback: function callback($$v) {
        _vm.$set(_vm.data.sourceOfIncome, "values", $$v);
      },
      expression: "data.sourceOfIncome.values"
    }
  })], 1), _c('form-error-field', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.data.sourceOfIncome.values.includes('other'),
      expression: "data.sourceOfIncome.values.includes('other')"
    }],
    staticClass: "text-left mb-12",
    attrs: {
      "name": "sourceOfIncome.otherValue"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.data.sourceOfIncome.otherValue,
      expression: "data.sourceOfIncome.otherValue"
    }, {
      name: "validate",
      rawName: "v-validate",
      value: _vm.data.sourceOfIncome.values.includes('other') ? 'required' : '',
      expression: "data.sourceOfIncome.values.includes('other') ? 'required' : ''"
    }],
    staticClass: "form-control",
    attrs: {
      "id": "sourceOfIncome.otherValue",
      "type": "text",
      "name": "sourceOfIncome.otherValue",
      "placeholder": "Specify source of income",
      "maxlength": "200",
      "data-registration": "purpose-other"
    },
    domProps: {
      "value": _vm.data.sourceOfIncome.otherValue
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.data.sourceOfIncome, "otherValue", $event.target.value);
      }
    }
  })])], 1)], 1)], 1), _c('div', [_c('b-row', [_c('b-col', [_c('form-error-field', {
    staticClass: "text-left mb-12",
    attrs: {
      "name": "accountOpeningPurposes.values"
    }
  }, [_c('label', [_vm._v(" What is the purpose of the account opening? ")]), _c('v-multiselect', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "options": _vm.options.purposeForOpeningTypes,
      "multiple": true,
      "close-on-select": false,
      "clear-on-select": false,
      "preserve-search": true,
      "preselect-first": false,
      "placeholder": "Make selection",
      "name": "accountOpeningPurposes.values",
      "label": "name",
      "track-by": "name",
      "return": "value",
      "data-registration": "purpose"
    },
    model: {
      value: _vm.data.accountOpeningPurposes.values,
      callback: function callback($$v) {
        _vm.$set(_vm.data.accountOpeningPurposes, "values", $$v);
      },
      expression: "data.accountOpeningPurposes.values"
    }
  })], 1), _c('form-error-field', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.data.accountOpeningPurposes.values.includes('other'),
      expression: "data.accountOpeningPurposes.values.includes('other')"
    }],
    staticClass: "text-left mb-12",
    attrs: {
      "name": "accountOpeningPurposes.otherValue"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.data.accountOpeningPurposes.otherValue,
      expression: "data.accountOpeningPurposes.otherValue"
    }, {
      name: "validate",
      rawName: "v-validate",
      value: _vm.requiredOtherPurposeForOpening,
      expression: "requiredOtherPurposeForOpening"
    }],
    staticClass: "form-control",
    attrs: {
      "id": "accountOpeningPurposes.otherValue",
      "type": "text",
      "name": "accountOpeningPurposes.otherValue",
      "placeholder": "Specify purpose for opening",
      "maxlength": "200",
      "data-registration": "purpose-other"
    },
    domProps: {
      "value": _vm.data.accountOpeningPurposes.otherValue
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.data.accountOpeningPurposes, "otherValue", $event.target.value);
      }
    }
  })])], 1)], 1)], 1), _c('div', [_c('b-row', [_c('b-col', [_c('form-error-field', {
    attrs: {
      "name": "turnovers.amount.incomingMonthly"
    }
  }, [_c('label', [_vm._v(" Monthly planned turnover, EUR (incoming transactions) ")]), _c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "data-vv-as": "Monthly planned turnover",
      "name": "turnovers.amount.incomingMonthly",
      "type": "number",
      "min": "1",
      "max": "2147483647",
      "placeholder": "Enter Your planned monthly turnover",
      "data-registration": "planned-incoming-turnover"
    },
    model: {
      value: _vm.data.turnovers.amount.incomingMonthly,
      callback: function callback($$v) {
        _vm.$set(_vm.data.turnovers.amount, "incomingMonthly", $$v);
      },
      expression: "data.turnovers.amount.incomingMonthly"
    }
  })], 1)], 1)], 1)], 1), _c('div', [_c('b-row', [_c('b-col', [_c('form-error-field', {
    attrs: {
      "name": "turnovers.amount.outgoingMonthly"
    }
  }, [_c('label', [_vm._v(" Monthly planned turnover, EUR (outgoing transactions) ")]), _c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "data-vv-as": "Monthly planned turnover, EUR",
      "name": "turnovers.amount.outgoingMonthly",
      "type": "number",
      "min": "1",
      "max": "2147483647",
      "placeholder": "Enter Your planned monthly turnover",
      "data-registration": "planned-outgoing-turnover"
    },
    model: {
      value: _vm.data.turnovers.amount.outgoingMonthly,
      callback: function callback($$v) {
        _vm.$set(_vm.data.turnovers.amount, "outgoingMonthly", $$v);
      },
      expression: "data.turnovers.amount.outgoingMonthly"
    }
  })], 1)], 1)], 1)], 1), _c('div', [_c('b-row', [_c('b-col', [_c('form-error-field', {
    attrs: {
      "name": "turnovers.cash.outgoingMonthly"
    }
  }, [_c('label', [_vm._v(" The sum of monthly planned cash withdrawals, EUR ")]), _c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "data-vv-as": "The sum of monthly planned cash withdrawals, EUR",
      "name": "planned_monthly_cash",
      "type": "number",
      "min": "0",
      "max": "2147483647",
      "placeholder": "Enter Your planned monthly cash withdrawals",
      "data-registration": "planned-cash"
    },
    model: {
      value: _vm.data.turnovers.cash.outgoingMonthly,
      callback: function callback($$v) {
        _vm.$set(_vm.data.turnovers.cash, "outgoingMonthly", $$v);
      },
      expression: "data.turnovers.cash.outgoingMonthly"
    }
  })], 1)], 1)], 1)], 1), _c('div', [_c('b-row', [_c('b-col', [_c('form-error-field', {
    attrs: {
      "name": "turnovers.count.incomingMonthly"
    }
  }, [_c('label', [_vm._v(" Monthly planned number of incoming transactions ")]), _c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "data-vv-as": "Monthly planned number of incoming transactions",
      "name": "turnovers.count.incomingMonthly",
      "type": "number",
      "min": "1",
      "max": "2147483647",
      "placeholder": "Enter Your planned monthly turnover",
      "data-registration": "planned-incoming-transactions"
    },
    model: {
      value: _vm.data.turnovers.count.incomingMonthly,
      callback: function callback($$v) {
        _vm.$set(_vm.data.turnovers.count, "incomingMonthly", $$v);
      },
      expression: "data.turnovers.count.incomingMonthly"
    }
  })], 1)], 1)], 1)], 1), _c('div', [_c('b-row', [_c('b-col', [_c('form-error-field', {
    attrs: {
      "name": "turnovers.count.outgoingMonthly"
    }
  }, [_c('label', [_vm._v(" Monthly planned number of outgoing transactions ")]), _c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "data-vv-as": "Monthly planned number of outgoing transactions",
      "name": "turnovers.count.outgoingMonthly",
      "type": "number",
      "min": "1",
      "max": "2147483647",
      "placeholder": "Enter Your planned monthly turnover",
      "data-registration": "planned-outgoing-transactions"
    },
    model: {
      value: _vm.data.turnovers.count.outgoingMonthly,
      callback: function callback($$v) {
        _vm.$set(_vm.data.turnovers.count, "outgoingMonthly", $$v);
      },
      expression: "data.turnovers.count.outgoingMonthly"
    }
  })], 1)], 1)], 1)], 1), _c('div', [_c('b-row', [_c('b-col', [_c('form-error-field', {
    staticClass: "text-left mb-12",
    attrs: {
      "name": "countriesFundsOriginateFrom"
    }
  }, [_c('label', [_vm._v(" Countries, where funds are received from ")]), _c('v-multiselect', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "options": _vm.countryOptions,
      "multiple": true,
      "close-on-select": false,
      "clear-on-select": false,
      "preserve-search": true,
      "preselect-first": false,
      "name": "countriesFundsOriginateFrom",
      "placeholder": "Make selection",
      "label": "name",
      "track-by": "name",
      "return": "countryId",
      "data-registration": "origin-country"
    },
    model: {
      value: _vm.data.countriesFundsOriginateFrom,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "countriesFundsOriginateFrom", $$v);
      },
      expression: "data.countriesFundsOriginateFrom"
    }
  })], 1)], 1)], 1)], 1), _c('div', [_c('b-row', [_c('b-col', [_c('form-error-field', {
    staticClass: "text-left mb-12",
    attrs: {
      "name": "countriesFundsOriginateTo"
    }
  }, [_c('label', [_vm._v("Countries, where funds are sent to")]), _c('v-multiselect', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "options": _vm.countryOptions,
      "multiple": true,
      "name": "countriesFundsOriginateTo",
      "close-on-select": false,
      "clear-on-select": false,
      "preserve-search": true,
      "preselect-first": false,
      "placeholder": "Make selection",
      "label": "name",
      "track-by": "name",
      "return": "countryId",
      "data-registration": "destination-country"
    },
    model: {
      value: _vm.data.countriesFundsOriginateTo,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "countriesFundsOriginateTo", $$v);
      },
      expression: "data.countriesFundsOriginateTo"
    }
  })], 1)], 1)], 1)], 1), _c('div', [_c('b-row', [_c('b-col', {
    staticClass: "mb-4"
  }, [_c('form-error-field', {
    attrs: {
      "name": "typeOfPayments",
      "wrapper": "div",
      "mb": "0"
    }
  }, [_c('div', {
    staticClass: "form-group mb-0"
  }, [_c('label', [_vm._v("What type of payments are you planning to make?")])]), _vm._l(_vm.options.serviceToUseOptionTypes, function (paymentType) {
    return _c('b-input-group', {
      key: paymentType.value,
      staticClass: "radioblock text-left mb-12",
      attrs: {
        "data-registration": "planned-payment-types"
      }
    }, [_c('b-form-checkbox', {
      directives: [{
        name: "validate",
        rawName: "v-validate",
        value: 'required',
        expression: "'required'"
      }],
      attrs: {
        "name": "typeOfPayments",
        "value": paymentType.value
      },
      model: {
        value: _vm.data.typeOfPayments,
        callback: function callback($$v) {
          _vm.$set(_vm.data, "typeOfPayments", $$v);
        },
        expression: "data.typeOfPayments"
      }
    }, [_vm._v(" " + _vm._s(paymentType.name) + " ")])], 1);
  })], 2)], 1)], 1)], 1), _vm._m(0), _vm._l(_vm.data.otherBankList, function (bank, index) {
    return _c('div', {
      key: "bank-".concat(index)
    }, [_c('b-row', [_c('b-col', {
      attrs: {
        "sm": "6"
      }
    }, [_c('form-error-field', {
      attrs: {
        "name": "otherBankList[".concat(index, "][name]")
      }
    }, [_c('label', {
      staticClass: "label"
    }, [_vm._v("Name of institution")]), _c('b-form-input', {
      directives: [{
        name: "validate",
        rawName: "v-validate",
        value: 'required',
        expression: "'required'"
      }],
      attrs: {
        "id": bank.nameId,
        "name": "otherBankList[".concat(index, "][name]"),
        "type": "text",
        "autocomplete": "off_name",
        "placeholder": "Enter name of institution",
        "data-registration": "bank-name-".concat(index)
      },
      model: {
        value: bank.name,
        callback: function callback($$v) {
          _vm.$set(bank, "name", $$v);
        },
        expression: "bank.name"
      }
    })], 1)], 1), _c('b-col', {
      attrs: {
        "sm": "6"
      }
    }, [_c('form-error-field', {
      attrs: {
        "name": "otherBankList[".concat(index, "][countryId]")
      }
    }, [_c('label', {
      staticClass: "label"
    }, [_vm._v("Country of institution")]), _c('v-multiselect', {
      directives: [{
        name: "validate",
        rawName: "v-validate",
        value: 'required',
        expression: "'required'"
      }],
      attrs: {
        "name": "otherBankList[".concat(index, "][countryId]"),
        "options": _vm.countryOptions,
        "show-labels": false,
        "allow-empty": true,
        "label": "name",
        "placeholder": "Select country",
        "track-by": "countryId",
        "return": "countryId",
        "data-registration": "bank-country-".concat(index)
      },
      model: {
        value: bank.countryId,
        callback: function callback($$v) {
          _vm.$set(bank, "countryId", $$v);
        },
        expression: "bank.countryId"
      }
    })], 1)], 1)], 1)], 1);
  }), _c('div', {
    staticClass: "btn btn-dark btn-sm px-5",
    attrs: {
      "data-registration": "add-bank"
    },
    on: {
      "click": function click($event) {
        return _vm.addBankInput();
      }
    }
  }, [_vm._v(" Add ")]), ((_vm$data$otherBankLis = _vm.data.otherBankList) === null || _vm$data$otherBankLis === void 0 ? void 0 : _vm$data$otherBankLis.length) > 0 ? _c('div', {
    staticClass: "btn btn-outline-danger btn-sm px-3 ml-2",
    attrs: {
      "data-registration": "remove-bank"
    },
    on: {
      "click": function click($event) {
        return _vm.removeBankInput();
      }
    }
  }, [_vm._v(" Remove ")]) : _vm._e(), _c('div', {
    staticClass: "mt-4"
  }, [_c('b-row', [_c('b-col', [_c('form-error-field', {
    attrs: {
      "name": "politicalExposure.value"
    }
  }, [_c('label', [_vm._v("Political exposure")]), _c('v-multiselect', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "options": _vm.options.politicalExposureTypes,
      "show-labels": false,
      "allow-empty": false,
      "name": "politicalExposure.value",
      "label": "name",
      "placeholder": "Select Your political exposure",
      "track-by": "name",
      "return": "value",
      "data-registration": "political-exposure"
    },
    model: {
      value: _vm.data.politicalExposure.value,
      callback: function callback($$v) {
        _vm.$set(_vm.data.politicalExposure, "value", $$v);
      },
      expression: "data.politicalExposure.value"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('form-error-field', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: ['family_member', 'associate'].includes(_vm.data.politicalExposure.value),
      expression: "['family_member', 'associate'].includes(data.politicalExposure.value)"
    }],
    staticClass: "text-left mb-12",
    attrs: {
      "name": "politicalExposure.name"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.data.politicalExposure.name,
      expression: "data.politicalExposure.name"
    }, {
      name: "validate",
      rawName: "v-validate",
      value: _vm.requiredPoliticalExposureName,
      expression: "requiredPoliticalExposureName"
    }],
    staticClass: "form-control",
    attrs: {
      "id": "political_exposure_name",
      "type": "text",
      "name": "politicalExposure.name",
      "placeholder": "Specify full name",
      "data-registration": "political-exposure-name"
    },
    domProps: {
      "value": _vm.data.politicalExposure.name
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.data.politicalExposure, "name", $event.target.value);
      }
    }
  })])], 1)], 1), _c('b-row', [_c('b-col', [_c('form-error-field', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: ['family_member', 'associate', 'exposed'].includes(_vm.data.politicalExposure.value),
      expression: "['family_member', 'associate', 'exposed'].includes(data.politicalExposure.value)"
    }],
    staticClass: "text-left mb-12",
    attrs: {
      "name": "politicalExposure.position"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.data.politicalExposure.position,
      expression: "data.politicalExposure.position"
    }, {
      name: "validate",
      rawName: "v-validate",
      value: _vm.requiredPoliticalExposurePosition,
      expression: "requiredPoliticalExposurePosition"
    }],
    staticClass: "form-control",
    attrs: {
      "id": "political_exposure_position",
      "type": "text",
      "name": "politicalExposure.position",
      "placeholder": "Specify position",
      "data-registration": "political-exposure-position"
    },
    domProps: {
      "value": _vm.data.politicalExposure.position
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.data.politicalExposure, "position", $event.target.value);
      }
    }
  })])], 1)], 1)], 1), _c('div', [_vm._m(1), _c('b-row', [_c('b-col', [_c('form-error-field', {
    attrs: {
      "name": "isBeneficialOwner",
      "wrapper": "div",
      "mb": "0"
    }
  }, [_c('b-input-group', {
    staticClass: "radioblock text-left mb-12"
  }, [_c('b-form-checkbox', {
    attrs: {
      "name": "isBeneficialOwner",
      "value": "yes",
      "data-registration": "account-owner-yes"
    },
    model: {
      value: _vm.data.isBeneficialOwner,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "isBeneficialOwner", $$v);
      },
      expression: "data.isBeneficialOwner"
    }
  }, [_vm._v(" Yes ")])], 1), _c('b-input-group', {
    staticClass: "radioblock text-left mb-12"
  }, [_c('b-form-checkbox', {
    attrs: {
      "name": "isBeneficialOwner",
      "value": "no",
      "data-registration": "account-owner-no"
    },
    model: {
      value: _vm.data.isBeneficialOwner,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "isBeneficialOwner", $$v);
      },
      expression: "data.isBeneficialOwner"
    }
  }, [_vm._v(" No ")])], 1)], 1)], 1)], 1)], 1)], 2), _c('div', [_c('b-row', [_c('b-col', {
    staticClass: "mt-4 mb-4  text-center"
  }, [_c('notification'), _c('input', {
    staticClass: "btn btn-primary btn-rounded btn-upper w-100",
    "class": _vm.buttonClass,
    attrs: {
      "disabled": _vm.nextEnabled == false,
      "type": "submit",
      "data-registration": "next"
    },
    domProps: {
      "value": _vm.buttonText
    }
  })], 1)], 1), _vm.allowSkip ? _c('b-row', [_c('b-col', {
    staticClass: "text-center"
  }, [_c('router-link', {
    staticClass: "framelink skiplink",
    attrs: {
      "to": {
        name: 'home'
      },
      "tag": "a",
      "exact": "",
      "data-registration": "skip"
    }
  }, [_vm.details.verification !== 'pending' ? _c('span', [_vm._v("Skip verification")]) : _vm._e(), _vm.details.verification == 'pending' ? _c('span', [_vm._v("Back to main page")]) : _vm._e()])], 1)], 1) : _vm._e()], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-group mb-0"
  }, [_c('label', [_vm._v("Accounts in other banks or payment/electronic money institutions")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-group mb-0"
  }, [_c('label', [_vm._v(" I confirm that I am the ultimate beneficial owner of the account ")])]);
}]

export { render, staticRenderFns }