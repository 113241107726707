var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "odd-form"
  }, [_c('b-form', [_c('b-row', [_c('b-col', [_c('h2', [_vm._v("Update your turnover information")])])], 1), _c('b-row', [_c('b-col', _vm._l(_vm.formFields, function (field, i) {
    var _vm$countryOptions;

    return _c('form-error-field', {
      key: 'field-' + i,
      attrs: {
        "id": field.id,
        "name": field.valuePath,
        "label": field.type !== 'hidden' ? field.label : '',
        "label-for": "".concat(field.id, "-input")
      }
    }, [['number', 'text'].includes(field.type) ? _c('b-form-input', {
      directives: [{
        name: "validate",
        rawName: "v-validate",
        value: field.validation,
        expression: "field.validation"
      }],
      attrs: {
        "id": "".concat(field.id, "-input"),
        "value": _vm.getValue(field.valuePath),
        "data-vv-as": field.name,
        "name": field.valuePath,
        "type": field.type,
        "placeholder": field.label,
        "aria-describedby": "".concat(field.id, "-feedback")
      },
      on: {
        "input": function input($event) {
          return _vm.setValue(field.valuePath, $event);
        }
      }
    }) : _vm._e(), field.type == 'countries' && (_vm$countryOptions = _vm.countryOptions) !== null && _vm$countryOptions !== void 0 && _vm$countryOptions.length ? _c('v-multiselect', {
      directives: [{
        name: "validate",
        rawName: "v-validate",
        value: field.validation,
        expression: "field.validation"
      }],
      staticClass: "countries-select",
      attrs: {
        "id": field.id,
        "value": _vm.getValue(field.valuePath),
        "options": _vm.countryOptions,
        "multiple": true,
        "close-on-select": false,
        "clear-on-select": false,
        "preserve-search": true,
        "preselect-first": false,
        "data-vv-as": field.name,
        "name": field.valuePath,
        "placeholder": field.label,
        "label": "name",
        "track-by": "name",
        "return": "countryId"
      },
      on: {
        "input": function input($event) {
          return _vm.setValue(field.valuePath, $event);
        }
      }
    }) : _vm._e()], 1);
  }), 1)], 1)], 1), _c('odd-form-footer', _vm._b({}, 'odd-form-footer', _vm.properties, false))], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }