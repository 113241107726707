<template>
	<div class="account-item d-flex align-items-center">
		<div class="avatar">
			<span>{{
				data.name ? data.name.charAt(0) : data.walletId.charAt(0)
			}}</span>
		</div>
		<div class="current-item-details align-self-center">
			<div class="name">
				{{ data.name }}
				<span
					v-b-modal="editWalletModalId"
					class="edit"
				>Edit alias</span>
			</div>
			<small
				v-if="isVerifiedAddress"
				class="number"
			>{{ data.iban }}</small>
		</div>

		<div class="wallet-balance align-self-center ml-auto text-uppercase">
			<template 
				v-if="+data.walletCurrencyList[0].balance"
			>
				<span 
					v-if="hasOtherCurrencies" 
					class="approxSign"
				>&#8776;</span>
			</template><span>{{ data.walletCurrencyList[0].balance }}</span> {{ data.walletCurrencyList[0].currencyId }}
		</div>
		<b-modal
			v-cloak
			:id="editWalletModalId"
			size="sm"
			centered
			hide-header
			hide-footer
		>
			<!-- eslint-disable-next-line vue/no-unused-vars -->
			<template #default="{ hide }">
				<p>Account alias</p>
				<b-form-input
					v-model="editInfo.name"
					v-validate="'required|max:60'"
					:class="{ 'is-invalid': errors.has('name') }"
					type="text"
					name="name"
					placeholder="New name"
					data-vv-validate-on="change"
				/>
				<b-alert
					v-if="editWalletSuccess"
					show
					variant="success"
					class="mt-3"
				>
					Account successfully updated.
				</b-alert>
				<b-alert
					v-if="editWalletError"
					show
					variant="danger"
					class="mt-3"
				>
					Something went wrong, please try again later.
				</b-alert>
				<div class="mt-4 d-flex">
					<b-button
						variant="info"
						@click="$bvModal.hide(editWalletModalId)"
					>
						Cancel
					</b-button>
					<button
						class="btn btn-primary px-5 ml-3"
						@click="editWallet"
					>
						Set
					</button>
				</div>
			</template>
		</b-modal>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import axios from 'axios';
import { endpoints } from '~/config';

export default {
	name: 'AccountWalletItem',
	props: {
		data: {
			type: Object,
			default: () => {},
		},
		total: {
			type: Number
		},
	},
	data() {
		return {
			isOpened: false,
			editInfo: {
				name: this.data.name,
			},
			editWalletError: false,
			editWalletSuccess: false,
		};
	},
	computed: {
		...mapState({
			nextWalletId: (state) => state.wallet.nextWalletId,
			showForm: (state) => state.wallet.create.showForm,
			showSuccess: (state) => state.wallet.create.showSuccess,
		}),
		...mapGetters('user', ['isVerifiedAddress']),
		...mapGetters('wallet', ['currentWalletAmount']),
		hasOtherCurrencies() {
			if (
				this.data.walletCurrencyList.length === 1 &&
				this.data.walletCurrencyList[0].currencyId === this.currentWalletAmount.currencyId
			) {
				return false;
			}
		
			return this.data.walletCurrencyList.length > 0;
		},
		editWalletModalId() {
			return 'edit-wallet-modal-' + this.data.walletId;
		},
	},
	methods: {
		async editWallet() {
			this.editWalletError = false;
			this.editWalletSuccess = false;

			try {
				const { data } = await axios.put(endpoints.wallet.update({ walletId: this.data.walletId }),
					this.editInfo,
					{
						headers: { 'Content-Type': 'application/json' }
					}
				);
				if (data) {
					await this.$store.dispatch('wallet/updateWalletName', {
						id: this.data.walletId,
						name: data.name,
					});

					this.$bvModal.hide(this.editWalletModalId);
				} else {
					this.editWalletError = true;
				}

			} catch (err) {
				this.editWalletError = true;
				throw new Error(err);
			}
		},
	},
};
</script>
