var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card-modal card-jwk-content"
  }, [_c('h2', [_vm._v("PIN")]), _c('img', {
    attrs: {
      "src": "/images/icons/card-pin.svg",
      "alt": "Card PIN"
    }
  }), _c('p', {
    staticClass: "mt-4 mb-4"
  }, [_vm._v(" Your card PIN ")]), !_vm.hidePin ? _c('span', {
    staticClass: "pin-view"
  }, [_vm._v(_vm._s(_vm.content))]) : _vm._e(), _vm.hidePin ? _c('span', {
    staticClass: "pin-view"
  }, [_vm._v("••••")]) : _vm._e(), !_vm.hidePin ? _c('div', {
    staticClass: "card-jwk-content__notification"
  }, [_c('span', [_vm._v("PIN will disappear in " + _vm._s(_vm.counter - _vm.timePassed) + " seconds")])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }