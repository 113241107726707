<template>
	<div class="card-modal card-jwk-content">
		<h2>PIN</h2>
		<img
			src="/images/icons/card-pin.svg"
			alt="Card PIN"
		>
		<p class="mt-4 mb-4">
			Your card PIN
		</p>
		<span
			v-if="!hidePin"
			class="pin-view"
		>{{ content }}</span>
		<span
			v-if="hidePin"
			class="pin-view"
		>••••</span>
		<div
			v-if="!hidePin"
			class="card-jwk-content__notification"
		>
			<span>PIN will disappear in {{ counter - timePassed }} seconds</span>
		</div>
	</div>
</template>
<script>
import moment from 'moment';
export default {
	name: 'CardPinView',
	props: {
		content: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			hidePin: false,
			timePassed: 0,
			counter: 10,
		};
	},
	mounted() {
		this.startCount();
	},
	methods: {
		startCount() {
			const start = moment(this.startTime);
			
			const count = () => {
				setTimeout(() => {
					const difference = moment(new Date()).diff(start, 'seconds');
					this.timePassed = difference;
					if (difference >= this.counter) {
						this.hidePin = true;
					} else {
						count();
					}
				}, 1000);
			};
			count();
		}
	}

};
</script>
<style lang="scss" scoped>
	.pin-view {
		font-size: 48px;
		font-weight: 500;
	}
</style>