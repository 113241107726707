var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "user-switch-modal",
      "centered": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn(_ref) {
        var close = _ref.close;
        return undefined;
      }
    }, {
      key: "default",
      fn: function fn(_ref2) {
        var hide = _ref2.hide;
        return [_vm.state == 'selection' ? _c('div', {
          staticClass: "switch-account-wrapper"
        }, [_c('h5', {
          staticClass: "mb-4"
        }, [_vm._v(" Select customer ")]), _c('div', {
          staticClass: "accounts-list"
        }, [_c('div', {
          staticClass: "account-item account-item-current d-flex align-items-center"
        }, [_c('div', {
          staticClass: "avatar"
        }, [_c('span', [_vm._v(_vm._s(_vm.setUsername(_vm.user) ? _vm.setUsername(_vm.user).charAt(0) : '-'))])]), _c('div', {
          staticClass: "current-item-details align-self-center"
        }, [_c('div', {
          staticClass: "name"
        }, [_vm._v(" " + _vm._s(_vm.setUsername(_vm.user)) + " ")])]), _c('div', {
          staticClass: "verification-status align-self-center ml-auto"
        }, [_vm.user.verification === 'verified' ? _c('div', {
          staticClass: "d-flex text-success"
        }, [_c('span', {
          staticClass: "status-text"
        }, [_vm._v("Verified")]), _c('span', {
          staticClass: "status-icon verified"
        })]) : _vm.user.verification === 'pending' ? _c('div', {
          staticClass: "d-flex text-warning"
        }, [_c('span', {
          staticClass: "status-text"
        }, [_vm._v("Pending")]), _c('span', {
          staticClass: "status-icon pending-verification"
        })]) : _c('div', {
          staticClass: "d-flex text-danger"
        }, [_c('span', {
          staticClass: "status-text"
        }, [_vm._v("Not verified")]), _c('span', {
          staticClass: "status-icon not-verified"
        })])]), _c('div', {
          staticClass: "current-icon"
        }, [_c('ion-icon', {
          staticClass: "check-icon",
          attrs: {
            "name": "checkmark-done-outline"
          }
        })], 1)]), _vm._l(_vm.accounts, function (person) {
          return _c('div', {
            key: person.profileId,
            staticClass: "account-item account-item-switch d-flex align-items-center",
            on: {
              "click": function click($event) {
                return _vm.switchPerson(person.profileId);
              }
            }
          }, [_c('div', {
            staticClass: "avatar"
          }, [_c('span', [_vm._v(_vm._s(_vm.setUsername(person) ? _vm.setUsername(person).charAt(0) : '-'))])]), _c('div', {
            staticClass: "current-item-details align-self-center"
          }, [_c('div', {
            staticClass: "name"
          }, [_vm._v(" " + _vm._s(_vm.setUsername(person)) + " ")])]), _c('div', {
            staticClass: "verification-status align-self-center ml-auto"
          }, [person.verification === 'verified' ? _c('div', {
            staticClass: "d-flex text-success"
          }, [_c('span', {
            staticClass: "status-text"
          }, [_vm._v("Verified")]), _c('span', {
            staticClass: "status-icon verified"
          })]) : person.verification === 'pending' ? _c('div', {
            staticClass: "d-flex text-warning"
          }, [_c('span', {
            staticClass: "status-text"
          }, [_vm._v("Pending")]), _c('span', {
            staticClass: "status-icon pending-verification"
          })]) : _c('div', {
            staticClass: "d-flex text-danger"
          }, [_c('span', {
            staticClass: "status-text"
          }, [_vm._v("Not verified")]), _c('span', {
            staticClass: "status-icon not-verified"
          })])]), _c('div', {
            staticClass: "switch-arrow"
          }, [_c('span', {
            staticClass: "switch-text"
          }, [_vm._v("Select")])])]);
        })], 2)]) : _vm._e(), _vm.state == 'creation' ? _c('div', {
          staticClass: "switch-account-wrapper"
        }, [_c('h5', {
          staticClass: "mb-4"
        }, [_vm._v(" Create company account ")]), _c('form', [_c('b-row', [_c('b-col', {
          "class": {
            'input-invalid': _vm.errors.has('name')
          }
        }, [_c('label', [_vm._v("Name*")]), _c('b-form-input', {
          directives: [{
            name: "validate",
            rawName: "v-validate",
            value: 'required',
            expression: "'required'"
          }],
          key: "name",
          staticClass: "form-control",
          attrs: {
            "id": "name",
            "type": "text",
            "name": "name",
            "data-vv-validate-on": "change"
          },
          model: {
            value: _vm.data.name,
            callback: function callback($$v) {
              _vm.$set(_vm.data, "name", $$v);
            },
            expression: "data.name"
          }
        })], 1)], 1), _c('b-row', {
          staticClass: "mt-3"
        }, [_c('b-col', [_c('div', {
          staticClass: "custom-control custom-checkbox"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.data.initialCapital,
            expression: "data.initialCapital"
          }],
          staticClass: "custom-control-input",
          attrs: {
            "id": "initial-capital",
            "type": "checkbox",
            "name": "initialCapital"
          },
          domProps: {
            "checked": Array.isArray(_vm.data.initialCapital) ? _vm._i(_vm.data.initialCapital, null) > -1 : _vm.data.initialCapital
          },
          on: {
            "change": function change($event) {
              var $$a = _vm.data.initialCapital,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false;

              if (Array.isArray($$a)) {
                var $$v = null,
                    $$i = _vm._i($$a, $$v);

                if ($$el.checked) {
                  $$i < 0 && _vm.$set(_vm.data, "initialCapital", $$a.concat([$$v]));
                } else {
                  $$i > -1 && _vm.$set(_vm.data, "initialCapital", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                }
              } else {
                _vm.$set(_vm.data, "initialCapital", $$c);
              }
            }
          }
        }), _c('label', {
          staticClass: "custom-control-label pr-2",
          attrs: {
            "id": "initial-capital-tooltip",
            "for": "initial-capital"
          }
        }, [_vm._v("Initial capital "), _c('b-tooltip', {
          attrs: {
            "target": "initial-capital-tooltip",
            "triggers": "hover",
            "variant": "secondary",
            "placement": "right"
          }
        }, [_vm._v(" Account for introduction of share capital for a company that is not yet registered. ")])], 1)])])], 1), !_vm.data.initialCapital ? _c('b-row', {
          staticClass: "mt-3"
        }, [_c('b-col', {
          "class": {
            'input-invalid': _vm.errors.has('code')
          }
        }, [_c('label', [_vm._v("Legal entity code*")]), _c('b-form-input', {
          directives: [{
            name: "validate",
            rawName: "v-validate",
            value: 'required',
            expression: "'required'"
          }],
          key: "code",
          staticClass: "form-control",
          attrs: {
            "id": "code",
            "type": "text",
            "name": "code",
            "data-vv-validate-on": "change"
          },
          model: {
            value: _vm.data.registrationCode,
            callback: function callback($$v) {
              _vm.$set(_vm.data, "registrationCode", $$v);
            },
            expression: "data.registrationCode"
          }
        })], 1)], 1) : _vm._e(), !_vm.data.initialCapital ? _c('b-row', {
          staticClass: "mt-3"
        }, [_c('b-col', {
          "class": {
            'input-invalid': _vm.errors.has('registrationDate')
          }
        }, [_c('label', [_vm._v("Registration date*")]), _c('app-datepicker', {
          directives: [{
            name: "validate",
            rawName: "v-validate",
            value: 'required',
            expression: "'required'"
          }],
          attrs: {
            "name": "registrationDate"
          },
          model: {
            value: _vm.data.registrationDate,
            callback: function callback($$v) {
              _vm.$set(_vm.data, "registrationDate", $$v);
            },
            expression: "data.registrationDate"
          }
        })], 1)], 1) : _vm._e(), _c('b-row', {
          staticClass: "mt-3"
        }, [_c('b-col', {
          "class": {
            'input-invalid': _vm.errors.has('registrationCountryId')
          }
        }, [_c('label', [_vm._v("Country*")]), _c('v-multiselect', {
          directives: [{
            name: "validate",
            rawName: "v-validate",
            value: 'required',
            expression: "'required'"
          }],
          "class": {
            'is-invalid': _vm.errors.has('registrationCountryId')
          },
          attrs: {
            "options": _vm.countryOptions,
            "show-labels": false,
            "allow-empty": false,
            "name": "registrationCountryId",
            "label": "name",
            "placeholder": "Select country",
            "track-by": "countryId",
            "return": "countryId"
          },
          model: {
            value: _vm.data.registrationCountryId,
            callback: function callback($$v) {
              _vm.$set(_vm.data, "registrationCountryId", $$v);
            },
            expression: "data.registrationCountryId"
          }
        })], 1)], 1)], 1)]) : _vm._e()];
      }
    }, {
      key: "modal-footer",
      fn: function fn(_ref3) {
        var hide = _ref3.hide;
        return [_vm.state == 'selection' ? _c('b-button', {
          attrs: {
            "variant": "btn btn-info"
          },
          on: {
            "click": function click($event) {
              return hide('forget');
            }
          }
        }, [_vm._v(" Close ")]) : _vm._e(), _vm.state == 'selection' && _vm.access.addCompany ? _c('button', {
          staticClass: "btn btn-primary",
          on: {
            "click": function click($event) {
              return _vm.addNewUser();
            }
          }
        }, [_c('span', {
          directives: [{
            name: "new-badge",
            rawName: "v-new-badge",
            value: {
              expire: '2021-06-28',
              id: 'addcompany'
            },
            expression: "{expire: '2021-06-28', id: 'addcompany'}"
          }]
        }, [_vm._v(" Add company account ")])]) : _vm._e(), _vm.state == 'creation' ? _c('b-button', {
          attrs: {
            "variant": "btn btn-info"
          },
          on: {
            "click": function click($event) {
              _vm.state = 'selection';
            }
          }
        }, [_vm._v(" Back ")]) : _vm._e(), _vm.state == 'creation' ? _c('b-button', {
          attrs: {
            "variant": "btn btn-primary"
          },
          on: {
            "click": function click($event) {
              return _vm.createAccount();
            }
          }
        }, [_vm._v(" Add account ")]) : _vm._e()];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }