var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', [_c('form-error-field', {
    staticClass: "mb-0",
    attrs: {
      "name": ['phone_country_id', 'phone_suffix', 'phone'],
      "scope": _vm.scope
    }
  }, [!_vm.hideLabel ? _c('div', {
    staticClass: "form-group mb-0"
  }, [_c('label', {
    attrs: {
      "for": "phone_suffix"
    }
  }, [_vm._v(_vm._s(_vm.label))])]) : _vm._e(), _c('b-input-group', {
    staticClass: "mb-0"
  }, [_c('div', {
    staticClass: "phone-prefix"
  }, [_c('v-multiselect', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    "class": {
      'input-invalid': _vm.errors.has('phone_country_id', _vm.scope)
    },
    attrs: {
      "slot": "prepend",
      "id": "phone_country_id",
      "options": _vm.countryOptions,
      "show-labels": false,
      "allow-empty": false,
      "name": "phone_country_id",
      "custom-label": function customLabel(option) {
        return "".concat(option.name, " +").concat(option.phoneCode);
      },
      "placeholder": "Select Code",
      "track-by": "countryId",
      "return": "countryId",
      "data-phone": "prefix"
    },
    on: {
      "select": _vm.focusNext
    },
    slot: "prepend",
    scopedSlots: _vm._u([{
      key: "singleLabel",
      fn: function fn(props) {
        return [_vm._v(" +"), _c('span', {
          staticClass: "option__desc"
        }, [_c('span', {
          staticClass: "option__title"
        }, [_vm._v(_vm._s(props.option.phoneCode))])])];
      }
    }, {
      key: "option",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "option__desc"
        }, [_c('span', {
          staticClass: "option__title"
        }, [_vm._v(_vm._s(props.option.name))]), _c('span', {
          staticClass: "option__small"
        }, [_vm._v(" +" + _vm._s(props.option.phoneCode))])])];
      }
    }]),
    model: {
      value: _vm.data.phone_prefix_country_id,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "phone_prefix_country_id", $$v);
      },
      expression: "data.phone_prefix_country_id"
    }
  })], 1), _c('b-form-input', {
    directives: [{
      name: "filter-input",
      rawName: "v-filter-input",
      value: 'number',
      expression: "'number'"
    }, {
      name: "validate",
      rawName: "v-validate",
      value: _vm.phoneValidation,
      expression: "phoneValidation"
    }],
    ref: "input",
    staticClass: "phone-suffix",
    "class": {
      'is-invalid': _vm.errors.has('phone_suffix', _vm.scope)
    },
    attrs: {
      "id": "phone_suffix",
      "type": "text",
      "data-vv-as": "phone number",
      "name": "phone_suffix",
      "autocomplete": "off_phone",
      "data-phone": "suffix"
    },
    model: {
      value: _vm.data.phone_suffix,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "phone_suffix", $$v);
      },
      expression: "data.phone_suffix"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }