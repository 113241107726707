<template>
	<b-modal 
		v-cloak 
		id="user-switch-modal" 
		centered
	>
		<!-- eslint-disable-next-line vue/no-unused-vars -->
		<template #modal-header="{ close }"></template>
		<!-- eslint-disable-next-line vue/no-unused-vars -->
		<template #default="{ hide }">
			<div
				v-if="state == 'selection'"
				class="switch-account-wrapper"
			>
				<h5 class="mb-4">
					Select customer
				</h5>
				<div class="accounts-list">
					<div class="account-item account-item-current d-flex align-items-center">
						<div class="avatar">
							<span>{{ setUsername(user) ? setUsername(user).charAt(0) : '-' }}</span>
						</div>
						<div class="current-item-details align-self-center">
							<div class="name">
								{{ setUsername(user) }}
							</div>
						</div>
						<div class="verification-status align-self-center ml-auto">
							<div 
								v-if="user.verification === 'verified'"
								class="d-flex text-success"
							>
								<span class="status-text">Verified</span><span class="status-icon verified"></span>
							</div>
							<div 
								v-else-if="user.verification === 'pending'"
								class="d-flex text-warning"
							>
								<span class="status-text">Pending</span><span
									class="status-icon pending-verification"
								></span>
							</div>
							<div 
								v-else
								class="d-flex text-danger"
							>
								<span class="status-text">Not verified</span><span
									class="status-icon not-verified"
								></span>
							</div>
						</div>
						<div class="current-icon">
							<ion-icon
								class="check-icon"
								name="checkmark-done-outline"
							/>
						</div>
					</div>
					<div 
						v-for="person in accounts" 
						:key="person.profileId"
						class="account-item account-item-switch d-flex align-items-center"
						@click="switchPerson(person.profileId)"
					>
						<div class="avatar">
							<span>{{ setUsername(person) ? setUsername(person).charAt(0) : '-' }}</span>
						</div>
						<div class="current-item-details align-self-center">
							<div class="name">
								{{ setUsername(person) }}
							</div>
						</div>
						<div class="verification-status align-self-center ml-auto">
							<div 
								v-if="person.verification === 'verified'"
								class="d-flex text-success"
							>
								<span class="status-text">Verified</span><span class="status-icon verified"></span>
							</div>
							<div 
								v-else-if="person.verification === 'pending'"
								class="d-flex text-warning"
							>
								<span class="status-text">Pending</span><span
									class="status-icon pending-verification"
								></span>
							</div>
							<div 
								v-else
								class="d-flex text-danger"
							>
								<span class="status-text">Not verified</span><span
									class="status-icon not-verified"
								></span>
							</div>
						</div>
						<div class="switch-arrow">
							<span class="switch-text">Select</span>
						</div>
					</div>
				</div>
			</div>
			<div
				v-if="state == 'creation'"
				class="switch-account-wrapper"
			>
				<h5 class="mb-4">
					Create company account
				</h5>
				<form>
					<b-row>
						<b-col :class="{'input-invalid' : errors.has('name')}">
							<label>Name*</label>
							<b-form-input
								id="name"
								key="name"
								v-model="data.name"
								v-validate="'required'"
								type="text"
								name="name"
								class="form-control"
								data-vv-validate-on="change"
							/>
						</b-col>
					</b-row>
					<b-row class="mt-3">
						<b-col>
							<div class="custom-control custom-checkbox">
								<input
									id="initial-capital"
									v-model="data.initialCapital"
									type="checkbox"
									name="initialCapital"
									class="custom-control-input"
								/>
								<label
									id="initial-capital-tooltip"
									for="initial-capital"
									class="custom-control-label pr-2"
								>Initial capital
									<b-tooltip target="initial-capital-tooltip"
										triggers="hover"
										variant="secondary"
										placement="right"
									>
										Account for introduction of share capital for a company that is not yet registered.
									</b-tooltip>
								</label>
							</div>
						</b-col>
					</b-row>
					<b-row v-if="!data.initialCapital"
						class="mt-3"
					>
						<b-col :class="{'input-invalid' : errors.has('code')}">
							<label>Legal entity code*</label>
							<b-form-input
								id="code"
								key="code"
								v-model="data.registrationCode"
								v-validate="'required'"
								type="text"
								name="code"
								class="form-control"
								data-vv-validate-on="change"
							/>
						</b-col>
					</b-row>
					<b-row v-if="!data.initialCapital"
						class="mt-3"
					>
						<b-col :class="{'input-invalid' : errors.has('registrationDate')}">
							<label>Registration date*</label>
							<app-datepicker
								v-model="data.registrationDate"
								v-validate="'required'"
								name="registrationDate"
							/>
						</b-col>
					</b-row>
					<b-row class="mt-3">
						<b-col :class="{'input-invalid' : errors.has('registrationCountryId')}">
							<label>Country*</label>
							<v-multiselect
								v-model="data.registrationCountryId"
								v-validate="'required'"
								:options="countryOptions"
								:show-labels="false"
								:allow-empty="false"
								name="registrationCountryId"
								label="name"
								placeholder="Select country"
								track-by="countryId"
								return="countryId"
								:class="{'is-invalid' : errors.has('registrationCountryId')}"
							/>
						</b-col>
					</b-row>
				</form>
			</div>
		</template>

		<template #modal-footer="{ hide }">
			<b-button 
				v-if="state == 'selection'"
				variant="btn btn-info" 
				@click="hide('forget')"
			>
				Close
			</b-button>
			<button 
				v-if="state == 'selection' && access.addCompany"
				class="btn btn-primary" 
				@click="addNewUser()"
			>
				<span v-new-badge="{expire: '2021-06-28', id: 'addcompany'}">
					Add company account
				</span>
			</button>
			<b-button
				v-if="state == 'creation'"
				variant="btn btn-info"
				@click="state = 'selection'"
			>
				Back
			</b-button>
			<b-button
				v-if="state == 'creation'"
				variant="btn btn-primary"
				@click="createAccount()"
			>
				Add account
			</b-button>
		</template>
	</b-modal>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import AppDatepicker from '@/shared/AppDatepicker';

import { setUsername } from '~/helpers';
import { endpoints } from '~/config';
export default {
	components: {
		AppDatepicker,
	},
	data() {
		return {
			users: [],
			state: 'selection',
			data: {},
		};
	},
	computed: {
		...mapState({
			user: state => state.user.selected,
			wallet: state => state.wallet,
			log: state => state.log
		}),
		...mapState('countries', {
			countryOptions: state => state.list
		}),
		...mapGetters('user', [
			'isBusiness',
			'isVerified',
			'isVerificationFilled',
			'verificationLink',
			'isGranted'
		]),
		...mapGetters([
			'currenciesOptions',
		]),
		accounts: function() {
			return this.users.filter(u => u.profileId !== this.user.profileId);
		},
		access() {
			return {
				addCompany: this.isGranted('front_post_user.index.active.:id.user.company')
			};
		}
	},
	async mounted() {
		try {
			const users = await this.getUserList();
			if (this.countryOptions.length == 0) this.getCountries();
			this.users = users;
		} catch (err) {
			this.pushError(err);
		}
			
	},
	methods: {
		...mapActions('user', ['getUserList', 'selectUser']),
		...mapActions('notifications', ['pushError']),
		...mapActions('countries', ['getCountries']),
		setUsername,
		addNewUser() {
			this.state = 'creation';
			this.data = {};
		},
		switchPerson: async function (id) {
			try {
				await this.selectUser(id);			
				this.$bvModal.hide('user-switch-modal');
				this.$router.replace({ name: 'home' });
			} catch (err) {
				this.pushError(err);
			}
		},
		async createAccount() {
			try {
				const result = await this.$validator.validateAll();
				if (result) {
					const options = {
						method: 'POST',
						url: endpoints.user.newCompany,
						data: this.data
					};
					const response = await axios(options);
					const { data } = response;
					const userId = data.profileId;
					await this.selectUser(userId);
					this.$bvModal.hide('user-switch-modal');
					this.$router.replace({ name: 'verify-account' });
				}
				
			} catch (err) {
				this.pushError(err.message);
			}
		}
	}
};
</script>
