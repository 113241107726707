<template>
	<div class="file-upload">
		<div v-if="expandable">
			<div
				class="file-upload-dropzone"
				:class="dragover && 'file-upload-dropzone--dragover'"
			>
				<b-form-file
					ref="file-input"
					v-model="files"
					v-validate="required ? 'required' : ''"
					class="file-upload-input--expandable"
					plain
					:accept="accept"
					:name="name"
					:multiple="multiple"
					@change="onFileChange"
				/>
				<div class="mr-3 file-upload-icon">
					<i class="fas fa-file-import"></i>
				</div>
				<div class="text-center">
					<span v-if="dragover">{{ fieldDropPlaceholder }}</span>
					<span v-else>{{ fieldPlaceholder }}</span>
				</div>
			</div>
		</div>
		<div v-else>
			<b-form-file
				v-show="!fileList || (fileList && (!files || files?.length == 0))"
				ref="file-input"
				v-model="files"
				v-validate="required ? 'required' : ''"
				class="file-upload-input"
				:accept="accept"
				:placeholder="fieldPlaceholder"
				:drop-placeholder="fieldDropPlaceholder"
				:name="name"
				:multiple="multiple"
				@change="onFileChange"
			>
				<template slot="file-name">
					<b-badge variant="primary"
						class="file-badge"
					>
						{{ trimFileName(fileNames[0]) }}
					</b-badge>
					<b-badge v-if="fileNames[1]"
						variant="primary"
						class="file-badge ml-1"
					>
						{{ trimFileName(fileNames[1]) }}
					</b-badge>
					<b-badge v-if="fileNames.length > 2"
						variant="primary"
						class="file-badge ml-1"
					>
						{{ `+${fileNames.length - 2} more file${fileNames.length == 3 ? '' : 's'}` }}
					</b-badge>
				</template>
			</b-form-file>
			<div v-if="fileList && files?.length > 0">
				<b-list-group>
					<b-list-group-item v-for="(file, i) in files"
						:key="i"
					>
						<b-row>
							<b-col>
								<small>{{ file.name }}</small>
							</b-col>
							<b-col class="col-auto">
								<b-button size="sm"
									variant="danger"
									@click.prevent="removeFile(file, i)"
								>
									Remove
								</b-button>
							</b-col>
						</b-row>
					</b-list-group-item>
				</b-list-group>
			</div>
			<div v-if="addMoreButton && files?.length > 0"
				class="mt-2"
			>
				<div class="d-inline-block position-relative">
					<b-button variant="primary"
						size="sm"
						@click="$refs['extra-file-input'].$el.click()"
					>
						Add additional files
					</b-button>
					<b-form-file
						ref="extra-file-input"
						v-model="extraFile"
						class="extra-file-input"
						name="extra-file"
						:accept="accept"
						:multiple="multiple"
						plain
						@change="onExtraFileChange"
					/>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapActions } from 'vuex';
import { fileUploadSizeLimit } from '~/config';
export default {
	name: 'FileUpload',
	props: {
		accept: {
			type: String,
			default: 'image/*, .pdf',
			required: false
		},
		placeholder: { 
			type: String,
			default: 'Choose a file or drop it here...',
			required: false
		},
		dropPlaceholder: { 
			type: String,
			default: 'Drop a file here',
			required: false
		},
		readAsText: {
			type: Boolean,
			default: false
		},
		expandable: {
			type: Boolean,
			default: false,
			required: false
		},
		name: {
			type: String,
			default: ''
		},
		required: {
			type: Boolean,
			default: false
		},
		multiple: {
			type: Boolean,
			default: false
		},
		addMoreButton: {
			type: Boolean,
			default: false
		},
		fileList: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			files: null,
			extraFile: null,
			dragover: false,
		};
	},
	computed: {
		fieldPlaceholder() {
			return this.multiple ? this.placeholder.replace('a file', 'files') : this.placeholder;
		},
		fieldDropPlaceholder() {
			return this.multiple ? this.dropPlaceholder.replace('a file', 'files') : this.dropPlaceholder;
		},
		fileNames() {
			if (Array.isArray(this.files)) {
				return this.files?.map(file => file.name) || [];
			} else {
				return [this.files?.name];
			}
		}
	},
	mounted() {
		const ref = this.$refs['file-input'];
		if (ref && ref.dropAllowed) {
			const el = ref.$el;
			el.ondragenter = () => {
				this.dragover = true;
			};
			
			el.ondragleave = () => {
				this.dragover = false;
			};			
			
			el.ondrop = () => {
				this.dragover = false;
			};
		}
	},
	methods: {
		...mapActions('notifications', ['pushError']),
		onFileChange(e) {
			this.$refs['extra-file-input']?.reset();
			const files = files || e.target.files || e.dataTransfer.files;
			this.prepareFiles(this.validateSize(files));
		},
		onExtraFileChange(e) {
			const files = e.target.files;
			if (!files.length) return;
			this.prepareFiles(this.validateSize([...this.files, ...files]));
		},
		async prepareFiles(files) {
			if (!files.length) return this.reset();
			this.$nextTick(() => this.$refs['file-input']?.setFiles(files));
			const promises = [];
			files.forEach(file => {
				const reader = new FileReader();
				promises.push(new Promise(resolve => {
					reader.onload = async (e) => {
						resolve({
							name: file.name,
							content: e.target.result
						});
					};

					if (this.readAsText) {
						reader.readAsText(file);	
					} else {
						reader.readAsDataURL(file);
					}
				}));
			});
			this.$emit('onChange', await Promise.all(promises));
		},
		trimFileName(name) {
			if (!name) return null;
			const charCount = 20;
			return name.split('').splice(0, charCount).join('') + (name.split('').length > charCount ? '...' : '');
		},
		reset() {
			this.$emit('onChange', []);
			this.$refs['file-input'].reset();
		},
		validateSize(files) {
			return [...files].filter(file => {
				if (file.size > fileUploadSizeLimit) {
					this.pushError(
						`File "${file.name}" size is too big. Try to upload a smaller one.`
					);
				} else {
					return true;
				}
			});
		},
		removeFile(file, index) {
			this.files = this.files.filter((f, i) => i !== index);
			return this.prepareFiles(this.files);
		}
	}
};
</script>
<style lang="scss">
.file-upload {
	.file-upload-dropzone {
		width: 100%;
		height: 150px;
		border: 1px solid $primary;
		display: flex;
		justify-content: center;
		align-items: center;
		color: $primary;
		transition: all .3s ease;
		flex-direction: column;
		border-radius: 5px;
		padding: 1.25rem;

		&:hover {
			background-color: #fff;
			border-color: #fff;
		}

		.file-upload-icon {
			font-size: 2rem;
		}

		input {
			cursor: pointer;
		}

		&--dragover {
			height: 200px;
			background-color: $primary;
			color: #fff;
		}
	}
	.file-upload-input {
		&--expandable {
			opacity: 0;
			width: 100%;
			height: 100%;
			position: absolute;
		}
	}
	.file-badge {
		line-height: 14px;
	}
	.extra-file-input {
		opacity: 0;
		visibility: hidden;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		position: absolute;
		pointer-events: none;
	}
}
</style>