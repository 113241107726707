var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.hide ? _c('div', {
    staticClass: "info-popup"
  }, [_c('div', [_c('div', {
    staticClass: "info-popup__close",
    on: {
      "click": function click() {
        return _vm.close();
      }
    }
  }, [_vm._v(" ✖ ")]), _vm._m(0)])]) : _vm._e();
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v(" Dear Customer,"), _c('br'), _c('br'), _vm._v(" Please be informed that SEPA payments will not be processed during the Easter holiday period (29th of March - 1st of April, 2024). Our office will remain closed on 1st April, 2024."), _c('br'), _vm._v(" For this reason, we won't be available to respond to any messages. You can check out our FAQ section "), _c('a', {
    attrs: {
      "href": "https://verifo.zendesk.com/hc/en-us",
      "target": "_blank"
    }
  }, [_vm._v("https://verifo.zendesk.com/hc/en-us")]), _vm._v(" if you have any questions, but rest assured we'll get back to you as soon as we return."), _c('br'), _c('br'), _vm._v(" Thank you for your understanding, Verifo Team ")]);
}]

export { render, staticRenderFns }