var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', [_c('b-col', [_c('span', {
    staticClass: "card-order__subtitle"
  }, [_vm._v("Address")])])], 1), _c('b-row', [_vm.user.userProfile && _vm.userAddress ? _c('b-col', {
    staticClass: "card-order__account-info"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_vm._v(" Street: ")]), _c('b-col', {
    attrs: {
      "md": "9"
    }
  }, [_vm._v(" " + _vm._s(_vm.userAddress.street) + " " + _vm._s(_vm.userAddress.streetNo) + _vm._s(_vm.userAddress.flatNo ? "-".concat(_vm.userAddress.flatNo) : '') + " ")])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_vm._v(" Postal code: ")]), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_vm._v(" " + _vm._s(_vm.userAddress.zipCode) + " ")])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_vm._v(" City: ")]), _c('b-col', {
    attrs: {
      "md": "9"
    }
  }, [_vm._v(" " + _vm._s(_vm.userAddress.city) + " ")])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_vm._v(" State: ")]), _c('b-col', {
    attrs: {
      "md": "9"
    }
  }, [_vm._v(" " + _vm._s(_vm.userAddress.state) + " ")])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_vm._v(" Country of Residence: ")]), _c('b-col', {
    attrs: {
      "md": "9"
    }
  }, [_vm._v(" " + _vm._s(_vm.userCountryName) + " ")])], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }