<template>
	<!--<div>
        <b-pagination
            :class="{ 'd-none': totalPages === 0 }"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
        ></b-pagination>
    </div>-->
	<div
		:class="{ 'd-none': totalPages === 0 }"
		class="pages d-lg-flex justify-content-center"
	>
		<img
			:disabled="isInFirstPage"
			:class="{ 'disabled-page': isInFirstPage }"
			aria-label="Go to previous page"
			style="cursor:pointer;"
			src="images/img/Chevrron left.svg"
			alt
			@click="onClickPreviousPage"
		/>

		<p
			v-for="page in pages"
			:key="page.name"
			:disabled="page.isDisabled"
			:class="{
				'active-page': isPageActive(page.name),
				'd-none d-lg-block': !isPageActive(page.name)
			}"
			:aria-label="`Go to page number ${page.name}`"
			class="page"
			@click="onClickPage(page.name)"
		>
			{{ page.name }}
		</p>

		<img
			:disabled="isInLastPage"
			:class="{ 'disabled-page': isInLastPage }"
			aria-label="Go to next page"
			style="cursor:pointer;"
			src="images/img/Chevrron right.svg"
			alt
			@click="onClickNextPage"
		/>
	</div>
</template>

<script>
import debounce from 'lodash/debounce';

const DEBOUNCE_WAIT = 300;

export default {
	name: 'Pagination',
	props: {
		maxVisibleButtons: {
			type: Number,
			required: false,
			default: 5
		},
		total: {
			type: Number,
			default: 0,
			required: true
		},
		perPage: {
			type: String,
			default: '10',
			required: true
		},
		currentPage: {
			type: Number,
			default: 1,
			required: false
		}
	},
	data() {
		return {
			current: false
		};
	},
	computed: {
		startPage() {
			if (this.currentPage === 1) {
				return 1;
			}

			if (this.currentPage === this.totalPages) {
				return this.totalPages - this.maxVisibleButtons + 1;
			}

			return this.currentPage - 1;
		},
		endPage() {
			return Math.min(
				this.startPage + this.maxVisibleButtons - 1,
				this.totalPages
			);
		},
		pages() {
			const range = [];

			for (let i = this.startPage; i <= this.endPage; i += 1) {
				if (i < 1) {
					continue;
				}
				range.push({
					name: i,
					isDisabled: i === this.currentPage
				});
			}

			return range;
		},
		isInFirstPage() {
			return this.currentPage === 1;
		},
		isInLastPage() {
			return this.currentPage === this.totalPages;
		},
		totalPages() {
			return this.total ? Math.ceil(this.total / this.perPage) : 0;
		}
	},
	watch: {
		perPage: function() {
			// watch it
			this.$emit('pagechanged', 1);
		}
	},
	methods: {
		onClickFirstPage: debounce(function() {
			this.$emit('pagechanged', 1);
		}, DEBOUNCE_WAIT),
		onClickPreviousPage: debounce(function() {
			if (this.isInFirstPage) {
				return false;
			}

			this.$emit('pagechanged', this.currentPage - 1);
		}, DEBOUNCE_WAIT),
		onClickPage: debounce(function(page) {
			if (this.currentPage === page) {
				return false;
			}

			this.$emit('pagechanged', page);
		}, DEBOUNCE_WAIT),
		onClickNextPage: debounce(function() {
			if (this.isInLastPage) {
				return false;
			}

			this.$emit('pagechanged', this.currentPage + 1);
		}, DEBOUNCE_WAIT),
		onClickLastPage: debounce(function() {
			this.$emit('pagechanged', this.totalPages);
		}, DEBOUNCE_WAIT),
		isPageActive(page) {
			return this.currentPage === page;
		}
	}
};
</script>
<style lang="scss" scoped>
	@import './Pagination.scss';	
</style>