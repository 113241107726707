var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.visible ? _c('div', {
    staticClass: "cookies-notification"
  }, [_c('div', {
    staticClass: "cookies-notification__wrapper"
  }, [_c('div', {
    staticClass: "cookies-notification__text"
  }, [_vm._v(" We use cookies to help give you the best experience on our website. By continuing without changing your cookie settings, we assume you agree to this. Please read our cookie policy to find out more. "), _c('a', {
    attrs: {
      "href": _vm.cookiePolicyUrl
    }
  }, [_vm._v("Cookie policy")])]), _c('button', {
    staticClass: "cookies-notification__button",
    attrs: {
      "data-cookies": "agree"
    },
    on: {
      "click": function click($event) {
        return _vm.setCookie();
      }
    }
  }, [_vm._v(" I agree ")])])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }