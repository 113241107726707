<template>
	<div class="footer-copyright mt-auto">
		<span>Copyright © {{ currentYear }} {{ siteName }}. All rights reserved.</span><br>
		<span v-if="WHITELABEL == 'verifo'">{{ siteName }} is powered by <a
			href="https://verifiedpayments.com"
			target="_blank"
		>Verified Payments</a> white-label banking solution.</span>
	</div>
</template>
<script>
import { siteName } from '~/config';

export default {
	data() {
		return {
			WHITELABEL,
			siteName: siteName,
			currentYear: new Date().getFullYear()
		};
	}
};
</script>
<style lang="scss" scoped>
	@import './DashboardFooter.scss';
</style>