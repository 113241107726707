<template>
	<div 
		v-cloak 
		class="main-sidebar"
	>
		<div class="sidebar-wrapper d-flex flex-column">
			<DashboardHeader />

			<div class="switchers">
				<div 
					v-if="user" 
					class="switcher switcher-account d-flex align-items-center"
				>
					<div class="avatar">
						<span>{{ setUsername(user).charAt(0) }}</span>
					</div>
					<div class="current-item-details align-self-center">
						<div class="name">
							{{ setUsername(user) }} 
						</div>
					</div>
					<div class="verification-status align-self-center ml-auto">
						<div 
							v-if="user.verification === 'verified'"
							class="d-flex text-success"
						>
							<span class="status-text">Verified</span><span class="status-icon verified"></span>
						</div>
						<div 
							v-else-if="user.verification === 'pending'"
							class="d-flex text-warning"
						>
							<span class="status-text">Pending</span><span
								class="status-icon pending-verification"
							></span>
						</div>
						<div 
							v-else
							class="d-flex text-danger"
						>
							<span class="status-text">Not verified</span><span class="status-icon not-verified"></span>
						</div>
					</div>

					<div 
						v-b-modal.user-switch-modal 
						class="switch-arrow"
					>
						<span>
							<ion-icon
								class="drowdown-icon"
								name="chevron-down-outline"
							/>
						</span>
					</div>
				</div>
				<div 
					v-if="currentWallet"
					class="switcher switcher-wallet d-flex align-items-center"
				>
					<div class="avatar">
						<span>{{ currentWallet.name.charAt(0) }}</span>
					</div>
					<div class="current-item-details align-self-center">
						<div class="name">
							{{ currentWallet.name }}
						</div>
						<small class="number">{{ currentWallet.iban }}</small>
					</div>
					<div class="wallet-info align-self-center ml-auto">
						<div
							v-b-modal.acc-info-modal
							class="account-info-icon"
						>
							<ion-icon name="information-circle-outline" />
						</div>
					</div>
					<div 
						v-b-modal.wallet-switch-modal 
						class="switch-arrow"
					>
						<ion-icon
							class="drowdown-icon"
							name="chevron-down-outline"
						/>
					</div>
				</div>
			</div>

			<div class="current-balance">
				<div class="row align-items-center">
					<!--<div class="user-person total-balance-mobile" v-if="this.$store.state.authUser">
                    <div class="person-data">
                        <div class="user-image">
                            <h6 class="user-name-text">{{ this.$store.state.authUser.data.currentPerson.name.charAt(0) +
                                this.$store.state.authUser.data.currentPerson.surname.charAt(0) }}</h6>
                        </div>
                        <div class="user-name">
                            <span class="full-name">{{ this.$store.state.authUser.data.currentPerson.name + ' ' +  this.$store.state.authUser.data.currentPerson.surname}}</span>
                            <span class="balance">{{ currentBalance }} {{ this.$store.state.wallet }}</span>
                        </div>
                    </div>
                    <div class="verified-data">
                        <div class="verification-dropdown" v-b-modal.user-switch-modal>
                            <span class="dropdown-icon-black"></span>
                        </div>
                    </div>
                </div>-->
					<div class="col-12 col-sm-auto mr-auto total-balance total-balance-desktop">
						<div class="current-balance-text">
							<p>Current balance</p>
							<span 
								:class="{ 'show-balance': !showUserBalance }"
								class="hide-balance"
								@click="hideBalance()"
							></span>
						</div>
						<span 
							v-if="user && currentWallet"
							:class="{ blur: !showUserBalance }"
							class="balance"
							@click="hideBalance()"
						>{{ currentWalletAmount.balance }}
							<small>{{ currentWalletAmount.currencyId ? currentWalletAmount.currencyId.toUpperCase() : '' }}</small>
						</span>
					</div>
					<div
						v-if="currentWallet && currentWallet.status === 'active'"
						class="col-auto new-payment"
					>
						<div
							v-b-tooltip.hover.bottom="'Export statement'"
							v-b-modal.export-statements-modal
							class="export-wrapper mr-3 d-none d-sm-flex"
						>
							<ion-icon name="download-outline" />
						</div>
						<button
							v-if="isWalletGranted('initiate_transaction', currentWallet.walletId)"
							class="btn btn-primary px-4"
							exact
							@click.prevent="pushRefresh({ 
								name: 'transfer',
								query: { new: null }
							})"
						>
							New transfer
						</button>

						<button
							v-b-modal.export-statements-modal
							class="btn btn-light ml-3 px-4 d-sm-none"
						>
							Statement
						</button>
					</div>
				</div>
				<div
					v-if="parseFloat(currentWalletAmount?.debt) > 0"
					class="current-balance-debt"
				>
					<div class="current-balance-debt--label">
						Outstanding Debt:
					</div>
					<p
						class="current-balance-debt--text"
						:class="{ blur: !showUserBalance }"
					>
						{{ currentWalletAmount.debt }} <small>{{ currentWalletAmount.currencyId ? currentWalletAmount.currencyId.toUpperCase() : '' }}</small>
					</p>
				</div>
				<div
					v-if="parseFloat(currentWalletAmount?.block) > 0"
					class="current-balance-debt"
				>
					<div class="current-balance-debt--label">
						Reserved funds:
					</div>
					<p
						class="current-balance-debt--text"
						:class="{ blur: !showUserBalance }"
					>
						{{ currentWalletAmount.block }} <small>{{ currentWalletAmount.currencyId ? currentWalletAmount.currencyId.toUpperCase() : '' }}</small>
					</p>
				</div>
				<CardsMenu />
			</div>

			<Navigation />
			<DashboardFooter />
		</div>

		<UserSwitchModal />
		<WalletSwitchModal />
		<AccountInfoModal />
		<ExportStatements />
	</div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import DashboardHeader from './DashboardHeader';
import UserSwitchModal from './UserSwitchModal';
import WalletSwitchModal from './WalletSwitchModal';
import Navigation from './Navigation';
import ExportStatements from './ExportStatements';
import AccountInfoModal from './AccountInfoModal';
import DashboardFooter from './DashboardFooter';
import CardsMenu from './CardsMenu.vue';
import { setUsername } from '~/helpers';

export default {
	components: {
		DashboardHeader,
		UserSwitchModal,
		WalletSwitchModal,
		ExportStatements,
		//Notifications,
		Navigation,
		// AccountDropdown,
		// UserDropdown,
		AccountInfoModal,
		DashboardFooter,
		CardsMenu
	},
	data() {
		return {
			showUserBalance: false,
			loaded: false,
		};
	},
	computed: {
		...mapState({
			log: state => state.log
		}),
		...mapState({
			wallet: 'wallet'
		}),
		...mapState('user', { 
			user: 'selected' 
		}),
		...mapGetters('user', [
			'isBusiness',
			'isVerified',
			'verificationLink',
			'isWalletGranted',
		]),
		...mapGetters([
			'currenciesOptions',
			'currencySymbol',
		]),
		...mapGetters('wallet', [
			'currentWallet',
			'currentWalletAmount'
		]),
		currentBalance: function () {
			if (this.currentWalletAmount.currencyId) {
				return `${this.currentWalletAmount.balance} ${this.getCurrency(this.currentWalletAmount.currencyId)}`;
			} else {
				return null;
			}
		},
		checkIfUserIsActiveAndWithBalance: function () {
			if (this.isVerified && this.currentWalletAmount.balance > 0) {
				return true;
			}
			return false;
		}
	},
	created() {
		this.loadBlur();
	},
	methods: {
		setUsername,
		...mapActions('routes', [
			'pushRefresh'
		]),
		loadBlur: function () {
			let showBalance = localStorage.getItem('showBalance');
			this.showUserBalance =  showBalance !== 'no';
		},

		hideBalance: function () {
			if (this.showUserBalance) {
				this.showUserBalance = false;
				localStorage.setItem('showBalance', 'no');
			} else {
				this.showUserBalance = true;
				localStorage.removeItem('showBalance');
			}

			// let showBalance = localStorage.getItem('showBalance');
		},
		getCurrency: function (currencyId) {
			if (currencyId === 'eur') {
				return '€';
			} else if (currencyId === 'usd') {
				return '$';
			} else {
				return currencyId.charAt(0).toUpperCase() + currencyId.slice(1);
			}
		}
	}
};
</script>
<style lang="scss" scoped>
	@import './Sidebar.scss';
</style>