var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-container', [_c('b-row', [_c('b-col', [_c('div', {
    staticClass: "page-title-container"
  }, [_c('h2', [_vm._v("Referral Program")]), _c('p', [_vm._v("Join our referral program. It's easy. It's free. And there is no limit to what you can earn.")])])])], 1), _c('b-card', [_c('b-row', [_c('b-col', [_c('label', {
    staticClass: "w-100"
  }, [_c('h3', {
    staticClass: "mb-2 mb-lg-3 font-weight-bold"
  }, [_vm._v(" Referral link ")]), _c('div', {
    staticClass: "input-group mb-0"
  }, [_c('input', {
    ref: "linkInput",
    staticClass: "form-control",
    attrs: {
      "readonly": "",
      "type": "text"
    },
    domProps: {
      "value": _vm.link
    },
    on: {
      "click": function click() {
        return _vm.$refs.linkInput.select();
      }
    }
  }), _c('div', {
    staticClass: "input-group-append"
  }, [_c('button', {
    staticClass: "btn btn-light px-3 rounded-right border-top border-right border-bottom",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.copy(_vm.link);
      }
    }
  }, [_c('ion-icon', {
    staticClass: "copy-icon md hydrated d-block",
    attrs: {
      "data-v-107c216b": "",
      "name": "copy-outline",
      "role": "img",
      "aria-label": "copy outline"
    }
  })], 1)])])])])], 1), _c('b-row', [_c('b-col', [_c('button', {
    staticClass: "btn btn-light mt-3 mr-2",
    "class": {
      'btn-outline-primary': _vm.activeTab == 0
    },
    on: {
      "click": function click() {
        return _vm.activeTab = 0;
      }
    }
  }, [_vm._v(" Referral Program ")]), _c('button', {
    staticClass: "btn btn-light mt-3",
    "class": {
      'btn-outline-primary': _vm.activeTab == 1
    },
    on: {
      "click": function click() {
        return _vm.activeTab = 1;
      }
    }
  }, [_vm._v(" Referral Dashboard ")])])], 1)], 1), _c('div', {
    staticClass: "mt-4"
  }, [_vm.activeTab == 0 ? _c('div', [_c('referral-programme')], 1) : _vm._e(), _vm.activeTab == 1 ? _c('div', [_c('referral-dashboard')], 1) : _vm._e()])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }