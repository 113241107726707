import {
	CONFIRMATION_CREATE_SUCCESS,
	CONFIRMATION_FAILURE,
	CONFIRMATION_REQUEST,
	CONFIRMATION_RESET,
	CONFIRMATION_SUCCESS,
	LOGIN_FAILURE,
	LOGIN_REQUEST,
	LOGIN_SUCCESS,
	LOGOUT
} from '~/store/mutation-types'; 

export default {
	[LOGIN_REQUEST](state) {
		state.status.login = {
			...state.status.login,
			loading: true,
			failed: false,
			success: false
		};
		state.token = null;
	},
	[LOGIN_SUCCESS](state, token) {
		state.status.login = {
			...state.status.login,
			loading: false,
			failed: false,
			success: true
		};
		state.token = token;
	},
	[LOGIN_FAILURE](state) {
		state.status.login = {
			...state.status.login,
			loading: false,
			failed: true,
			success: false
		};
		state.token = null;
	},
	[LOGOUT](state) {
		state.status.login = {
			...state.status.login,
			loading: false,
			failed: false,
			success: false
		};
		state.token = null;
	},
	[CONFIRMATION_REQUEST](state) {
		state.status.confirm = {
			...state.status.confirm,
			loading: true,
			failed: false
		};
	},
	[CONFIRMATION_SUCCESS](state) {
		state.status.confirm = {
			...state.status.confirm,
			loading: false,
			failed: false,
			success: true,
			created: false
		};
	},
	[CONFIRMATION_FAILURE](state) {
		state.status.confirm = {
			...state.status.confirm,
			loading: false,
			failed: true,
			success: false,
		};
	},
	[CONFIRMATION_RESET](state) {
		state.status.confirm = {
			...state.status.confirm,
			loading: false,
			failed: false,
			success: false,
		};
	},
	[CONFIRMATION_CREATE_SUCCESS](state) {
		state.status.confirm.created = true;
	}
};