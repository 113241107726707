export default {
	currentWallet: state => {
		return state.currentId ? state.list?.find(wallet => wallet.walletId == state.currentId) : null;
	},
	currentFeeWallet: (state, getters) => {
		const walletData = getters.currentWallet.feeWallet;
		if (walletData) {
			return state.list.find(item => item.walletId == walletData.walletId);
		} else {
			return null;
		}
	},
	currentFeeWalletAmount: (state, getters) => {
		return getters.currentFeeWallet && getters.currentFeeWallet.walletCurrencyList[0]; //change if being used with multiple currencies
	}, 
	currentWalletAmount: (state, getters) => {
		return getters.currencies && getters.currencies[0]; //change if being used with multiple currencies
	},
	currencies: (state, getters) => {
		if (!getters.currentWallet) return;
		return getters.currentWallet.walletCurrencyList;
	},
	primaryWallet: state => {
		let wallet = state.list.find(wallet => !!parseInt(wallet.primary) === true);
		if (!wallet && state.list.length) {
			wallet = state.list[0];
		}
		return wallet;
	},
	walletCurrenciesOptions: (state, getters) => {
		const options = [];

		if (state.current && state.current.currencies) {
			getters.currentWallet.walletCurrencyList.forEach(({ currencyId, balance }) => options.push({
				value: currencyId,
				text: `${currencyId.toUpperCase()} (${balance})`,
			}));
		}

		return options;
	},
	currentWalletType: (state, getters) => {
		let WALLET_TYPES = {
			ind_commercial: 'Ind. Commercial',
			ind_personal: 'Ind. Personal',
			ind_introducer: 'Ind. Introducer',
			org_operational: 'Org. Operational',
			org_pooled: 'Org. Pooled',
			org_initial_capital: 'Org. Initial capital',
			org_settlement: 'Org. Settlement',
			org_respondent: 'Org. Respondent',
			org_introducer: 'Org. Introducer',
		};

		return WALLET_TYPES[getters.currentWallet.type];
	},
	provider: (state, getters) => getters.currentWallet && getters.currentWallet.provider
};
