var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "checkbox-list"
  }, _vm._l(_vm.items, function (item, i) {
    return _c('b-col', {
      key: i,
      staticClass: "checkbox-list__column",
      attrs: {
        "cols": _vm.cols
      }
    }, [_c('div', {
      staticClass: "checkbox-list__item",
      "class": {
        'checkbox-list__item--active': _vm.value.includes(item[_vm.optionValue])
      },
      on: {
        "click": function click() {
          return _vm.select(item[_vm.optionValue]);
        }
      }
    }, [_c('div', {
      staticClass: "checkbox-list__item__text"
    }, [_c('h3', [_c('span', [_c('i', {
      staticClass: "fas fa-check"
    })]), _vm._v(" " + _vm._s(item[_vm.optionTitle]) + " ")]), item[_vm.optionDescription] ? _c('p', [_vm._v(" " + _vm._s(item[_vm.optionDescription]) + " ")]) : _vm._e(), _c('div', {
      staticClass: "checkbox-list__item__extra"
    }, [_vm._t(item[_vm.optionValue])], 2)])])]);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }