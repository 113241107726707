var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card-order__card-img",
    "class": _vm.virtual ? 'card-order__card-img--virtual' : 'card-order__card-img--plastic'
  }, [_vm.showDetails && _vm.card && _vm.card.cardNumber ? _c('span', {
    staticClass: "card-order__card-number"
  }, [_vm._v(_vm._s(_vm.cardNumber))]) : _vm._e(), _vm.showDetails ? _c('span', {
    staticClass: "card-order__card-name"
  }, [_vm._v(_vm._s(_vm.cardHolderName))]) : _vm._e(), _vm.showDetails ? _c('span', {
    staticClass: "card-order__card-expiration"
  }, [_vm._v(_vm._s(_vm.expirationDate))]) : _vm._e(), _vm.virtual ? _c('img', {
    attrs: {
      "src": "/images/card-virtual.svg",
      "alt": "visa virtual debit",
      "width": _vm.width
    }
  }) : _vm._e(), !_vm.virtual ? _c('img', {
    attrs: {
      "src": "/images/card-plastic.svg",
      "alt": "visa debit",
      "width": _vm.width
    }
  }) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }