function formatter(url, data) {
	const errors = {
		dataFormat: (url) => {throw new Error(`[endpoints.js] You must pass an object with replaceable values for '${url}'`);},
		missingValue: (value, url) => {throw new Error(`[endpoints.js] Could not find '${value}' in url '${url}' to replace.`);},
		missingReplacer: (arr, url) => {throw new Error(`[endpoints.js] Missing values of ${arr.join(', ')} in url '${url}'.`);}
	};

	let result = url;
	if (data) {

		// If data is not an object, makes impossible to populate replacers.
		if (typeof data !== 'object') errors.dataFormat(url);
		Object.keys(data).forEach(v => {
			const replacer = `{${v}}`;
			const exists = result.includes(replacer);

			// If data includes unpopulated value
			if (!exists) errors.missingFormatter(v, url);
			result = exists ? result.replace(replacer, data[v]) : result;
		});
	}
	const unmatched = Array.from(result.matchAll(/{(\S+?)}/g)).map(v => `'${v[1]}'`);

	// If there are unreplaced values
	if (unmatched.length > 0) errors.missingReplacer(unmatched, url);

	return result;
}


export const formatEndpoints = function(urls = {}, api) {
	if (typeof urls !== 'object' || (!api)) throw new Error('[endpoints.js] Wrong type of urls or api is not defined.');
	const newObj = {};
	Object.keys(urls).forEach(key => {
		const value = urls[key];
		const isString = typeof value == 'string';
		const isObject = typeof value == 'object';
		let result;
		if (isString) {
			const fullUrl = `${api}${value}`;
			const isReplaceable = !!value.match(/{(\S+?)}/g);
			// Decide to return url string or function which would allow to replace data.
			if (isReplaceable) {
				result = function(data) {return formatter(fullUrl, data); };
			} else {
				result = fullUrl;
			}
		} else if (isObject) {
			result = formatEndpoints(value, api);
		} else {
			throw new Error(`[endpoints.js] Unexpected type ${typeof value} in the urls object.`);
		}
		newObj[key] = result;
	});
	return newObj;
};

export const handleApiFormErrors = (res, errorBag) => {
	errorBag.clear();
	Object.keys(res.fields).forEach(field => {
		let scope = null;
		if (field.match(/keyPersons\[[0-9]+\]/i)) {
			scope = 'person';
		}
		errorBag.add({
			field,
			msg: res.fields[field],
			scope
		});
	});
};