<template>
	<div>
		<modal
			name="privacy_policy"
			height="auto"
			:click-to-close="false"
			:width="1000"
			:adaptive="true"
		>
			<div class="customer-service-modal-wrapper">
				<h2>Privacy Policy</h2>
				<div
					v-if="current == 'privacy_policy'"
					class="text-center"
				>
					<transition 
						name="fade" 
						mode="out-in"
					>
						<b-spinner
							v-if="pdfLoading"
							class="my-4"
							label="Loading"
						/>
						<vue-pdf-app
							v-if="!pdfLoading" 
							:pdf="pdfUrls.privacy_policy"
							:config="{
								toolbar: false,
								sidebar: false
							}"
							page-scale="page-height"
							style="height: 500px; max-height: 70vh"
							theme="light"
						/>
					</transition>
					<div
						v-if="!pdfLoading"
						class="my-3"
					>
						<small class="text-center">
							Can't see the text? <a
								:href="pdfUrls.privacy_policy"
								target="_blank"
							>Open or download</a>.
						</small>
					</div>
				</div>
				<div class="text-center my-3">
					<b-button
						variant="primary"
						@click.prevent="serviceAgree('privacy_policy')"
					>
						<span v-if="!loading">Agree</span>
						<b-spinner
							v-if="loading"
							variant="light"
							small
							label="Loading"
						/>
					</b-button>
					<b-button
						variant="outline-primary"
						class="ml-3"
						@click.prevent="() => cancel('privacy_policy')"
					>
						{{ closeLabel }}
					</b-button>
				</div>
			</div>
		</modal>
		<modal
			:adaptive="true"
			name="service_agreement"
			height="auto"
			:click-to-close="false"
			:width="1000"
		>
			<div class="customer-service-modal-wrapper">
				<h2>Service Agreements</h2>
				<div
					v-if="current == 'service_agreement'"
					class="text-center"
				>
					<transition 
						name="fade" 
						mode="out-in"
					>
						<b-spinner
							v-if="pdfLoading"
							class="my-4"
							label="Loading"
						/>
						<vue-pdf-app
							v-if="!pdfLoading" 
							:pdf="pdfUrls.service_agreement"
							:config="{
								toolbar: false,
								sidebar: false
							}"
							page-scale="page-width"
							style="height: 500px; max-height: 70vh"
							theme="light"
						/>
					</transition>
					<div
						v-if="!pdfLoading"
						class="my-3"
					>
						<small class="text-center">
							Can't see the text? <a
								:href="pdfUrls.service_agreement"
								target="_blank"
							>Open or download</a>.
						</small>
					</div>
				</div>
				<div class="text-center my-3">
					<b-button
						variant="primary"
						data-agreements="agree"
						@click.prevent="serviceAgree('service_agreement')"
					>
						<span v-if="!loading">Agree</span>
						<b-spinner
							v-if="loading"
							variant="light"
							small
							label="Loading"
						/>
					</b-button>

					<b-button
						variant="outline-primary"
						class="ml-3"
						data-agreements="cancel"
						@click.prevent="() => cancel('service_agreement')"
					>
						{{ closeLabel }}
					</b-button>
				</div>
			</div>
		</modal>
	</div>
</template>
<script>
import { mapActions } from 'vuex';
import { endpoints } from '~/config';
import VuePdfApp from 'vue-pdf-app';
import 'vue-pdf-app/dist/icons/main.css';

export default {
	name: 'Agreements',
	components: {
		VuePdfApp
	},
	props: {
		closeLabel: {
			type: String,
			default: 'Close'
		},
		onClose: {
			type: Function,
		},
		onSuccess: {
			type: Function
		},
		submitOnClick: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			loading: false,
			pdfLoading: false,
			pdfUrls: {
				service_agreement: endpoints.verification.serviceAgreementPdf,
				privacy_policy: endpoints.verification.privacyPolicyPdf
			},
			queue: [],
			current: null
		};
	},
	methods: {
		...mapActions('user', [
			'setUserCustomerAgreement'
		]),
		...mapActions('notifications', ['pushError']),
		async cancel(value) {
			this.onClose && await this.onClose(value);
			this.close(value);
		},
		async serviceAgree(value) {
			const options = {
				method: 'POST',
				url: endpoints.verification.customerAgreement,
				data: { types: [value] },
			};
			try {
				this.loading = true;
				if (this.submitOnClick) {
					await axios(options);
				}
				this.close(value);
				this.loading = false;
				let showAgreementValue = {};
				showAgreementValue[value == 'service_agreement' ? 'showServiceAgreement' : 'showPrivacyPolicy'] = false;
				this.setUserCustomerAgreement(showAgreementValue);
				this.onSuccess && this.onSuccess(value);
			} catch (err) {
				this.pushError(err.message);
				this.loading = false;
			}
		},
		close(value) {
			this.$modal.hide(value);
			this.current = null;
			if (this.queue.length > 0) {
				const next = this.queue[0];
				this.current = next;
				this.$modal.show(next);
				this.queue = this.queue.filter(v => v !== next);
			}
		},
		async open(value) {
			if (this.pdfUrls[value]) {
				if (!this.current) {
					this.current = value;
					this.$modal.show(value);
				} else {
					this.queue = [...this.queue, value];
				}
			}
		}
	}
};
</script>
<style lang="scss">
.customer-service-modal-wrapper {
	padding: 20px;
	background-color: var(--bg);
}
</style>