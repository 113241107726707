import { main } from './default.whitelabel';

let api, paymentGateway, sentry;

if (process.env.NODE_ENV == 'development') {
	api = 'http://api.verifo.local';
	paymentGateway = 'https://gateway.verifo.local';
	sentry = 'https://c20c983066884fe69d72ab12d01936d6@o491125.ingest.sentry.io/5556181';
}

if (process.env.NODE_ENV == 'qa') {
	api = 'https://api-dev.emoneybank.net';
	paymentGateway = 'https://gateway.verifo.local';
	sentry = 'https://80bc495b79104511ac394a7a29d61f15@sentry.verifo.com/9';
}

if (process.env.NODE_ENV == 'production') {
	api = 'https://api.emoneybank.net';
	paymentGateway = 'https://gateway.verifo.local';
	sentry = 'https://3905e3bb4bcd40caaa54613f4f91d68a@sentry.verifo.com/10';
}

export default {
	...main,
	brand: 'emoney',
	name: 'eMoneyBank',
	style: 'emoney.scss',
	email: 'support@emoneybank.net',
	supportEmail: 'support@emoneybank.net',
	agreementUrl: 'https://emoneybank.net/general-service-agreement/',
	supportUrl: 'https://emoneybank.net/',
	privacyPolicyUrl: 'https://emoneybank.net/privacy-policy/',
	cookiePolicyUrl: 'https://emoneybank.net/cookie-policy/',
	api: api,
	paymentGateway: paymentGateway,
	sentry: sentry,
};