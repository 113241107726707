var render = function () {
  var _vm$typeData, _vm$typeData2, _vm$typeData3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "nano-transfer"
  }, [_c('b-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.currentStep == 1,
      expression: "currentStep == 1"
    }]
  }, [_c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "xl": "7",
      "cols": "12"
    }
  }, [_c('b-card', [_c('transfer-form', {
    ref: "transferForm",
    attrs: {
      "form-fields": _vm.formFields,
      "loading": _vm.loading,
      "data-vv-scope": "form"
    },
    on: {
      "change": _vm.formChange,
      "onBlur": _vm.onBlur
    }
  })], 1), _c('b-row', [_c('b-col', [_c('b-collapse', {
    attrs: {
      "id": "swift-message",
      "visible": ""
    }
  }, [_c('div', {
    staticClass: "nano-transfer__notification",
    "class": _vm.swiftMessageClass
  }, [!_vm.access.swift ? _c('span', [_c('ion-icon', {
    attrs: {
      "name": "globe-outline"
    }
  }), _c('span', [_vm._v(" SWIFT (International) payments disabled. "), _c('a', {
    attrs: {
      "href": "mailto:".concat(_vm.supportEmail, "?subject=Request Swift"),
      "target": "_blank"
    }
  }, [_c('strong', [_vm._v("Request swift")])])])], 1) : _vm._e(), _vm.access.swift ? _c('span', [_c('ion-icon', {
    attrs: {
      "name": "globe-outline"
    }
  }), _c('span', [_vm._v("SWIFT (International) payments enabled.")])], 1) : _vm._e(), _c('span', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.swift-message",
      modifiers: {
        "swift-message": true
      }
    }]
  }, [_c('ion-icon', {
    attrs: {
      "name": "close-outline"
    }
  })], 1)])])], 1)], 1)], 1), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "xl": "5",
      "cols": "12"
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', [_c('span', [_vm._v("Amount:")])]), _c('b-col', {
    staticClass: "text-right"
  }, [_c('span', [_vm._v(_vm._s(_vm.formData.amount ? parseFloat(_vm.formData.amount).toFixed(2) : '0.00') + " " + _vm._s(_vm.formData.currencyId && _vm.formData.currencyId.toUpperCase()))])])], 1), _c('b-row', [_c('b-col', [_c('span', [_vm._v("Transaction fee:")])]), _c('b-col', {
    staticClass: "text-right"
  }, [_c('span', [_vm._v(_vm._s(_vm.fee || '0.00') + " " + _vm._s(_vm.formData.currencyId && _vm.formData.currencyId.toUpperCase()))]), _vm.fee && _vm.currentFeeWalletAmount ? _c('div', [_c('b-badge', {
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(" Uses fee wallet ")])], 1) : _vm._e()])], 1), !_vm.fee ? _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', [_c('span', {
    staticClass: "notes"
  }, [_vm._v(" " + _vm._s(_vm.notes) + " ")])])], 1) : _vm._e(), _c('hr'), _c('b-row', [_c('b-col', [_c('strong', [_vm._v("Estimated total:")])]), _c('b-col', {
    staticClass: "text-right"
  }, [_c('strong', [_vm._v(_vm._s(_vm.estimatedTotal) + " " + _vm._s(_vm.formData.currencyId && _vm.formData.currencyId.toUpperCase()))])])], 1), _vm.insufficientFunds ? _c('b-row', [_c('b-col', {
    staticClass: "text-right"
  }, [_c('b-badge', {
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v(" Insufficient Funds ")])], 1)], 1) : _vm._e()], 1), _vm.user.role === 'user' ? [_c('b-row', {
    staticClass: "mt-3"
  }, [_c('b-col', {
    staticClass: "col-12"
  }, [_vm.isWalletGranted('approve_transaction', _vm.currentWallet.walletId) ? _c('button', {
    staticClass: "btn btn-primary btn-block",
    attrs: {
      "disabled": _vm.loading || _vm.insufficientFunds || _vm.fee == null || ((_vm$typeData = _vm.typeData) === null || _vm$typeData === void 0 ? void 0 : _vm$typeData.serviceId) == 'nano_swift_transfer' && !_vm.access.swift
    },
    on: {
      "click": function click($event) {
        return _vm.startTransfer('approve');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.loading === true ? 'Loading...' : 'Next') + " ")]) : _vm._e()])], 1)] : [_vm.isWalletGranted('initiate_transaction', _vm.currentWallet.walletId) ? _c('b-row', {
    staticClass: "mt-3"
  }, [_c('b-col', [_c('button', {
    staticClass: "btn btn-outline-primary btn-block",
    attrs: {
      "disabled": _vm.loading || _vm.insufficientFunds || _vm.fee == null || ((_vm$typeData2 = _vm.typeData) === null || _vm$typeData2 === void 0 ? void 0 : _vm$typeData2.serviceId) == 'nano_swift_transfer' && !_vm.access.swift
    },
    on: {
      "click": function click($event) {
        return _vm.startTransfer('initiate');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.loading === true ? 'Loading...' : 'Save for confirmation') + " ")])])], 1) : _vm._e(), _vm.isWalletGranted('approve_transaction', _vm.currentWallet.walletId) ? _c('b-row', {
    staticClass: "mt-3"
  }, [_c('b-col', [_c('button', {
    staticClass: "btn btn-primary btn-block",
    attrs: {
      "disabled": _vm.loading || _vm.insufficientFunds || _vm.fee == null || ((_vm$typeData3 = _vm.typeData) === null || _vm$typeData3 === void 0 ? void 0 : _vm$typeData3.serviceId) == 'nano_swift_transfer' && !_vm.access.swift
    },
    on: {
      "click": function click($event) {
        return _vm.startTransfer('approve');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.loading === true ? 'Loading...' : 'Next') + " ")])])], 1) : _vm._e()]], 2)], 1), _vm.currentStep == 2 ? _c('div', [_c('b-card', [_c('b-row', {
    staticClass: "form-group mb-lg-0 pt-3"
  }, [_c('label', {
    staticClass: "col-lg-5 col-xl-3 col-form-label"
  }, [_vm._v("Wallet send from:")]), _c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "lg": "7",
      "xl": "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.walletId) + " (Balance " + _vm._s(_vm.currentWalletAmount.balance) + " " + _vm._s(_vm.formData.currencyId.toUpperCase()) + " ) ")])], 1), _c('b-row', {
    staticClass: "form-group mb-lg-0"
  }, [_c('label', {
    staticClass: "col-lg-5 col-xl-3 col-form-label"
  }, [_vm._v("Wallet send to:")]), _c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "lg": "7",
      "xl": "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.formData.accountNumber) + " ")])], 1), _c('b-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !!_vm.formData.name,
      expression: "!!formData.name"
    }],
    staticClass: "form-group mb-lg-0"
  }, [_c('label', {
    staticClass: "col-lg-5 col-xl-3 col-form-label"
  }, [_vm._v("Recipient name:")]), _c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "lg": "7",
      "xl": "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.formData.name) + " " + _vm._s(_vm.formData.surname) + " ")])], 1), _c('b-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.formData.companyName !== '',
      expression: "formData.companyName !== ''"
    }],
    staticClass: "form-group mb-lg-0"
  }, [_c('label', {
    staticClass: "col-lg-5 col-xl-3 col-form-label"
  }, [_vm._v("Recipient:")]), _c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "lg": "7",
      "xl": "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.formData.companyName) + " ")])], 1), _c('b-row', {
    staticClass: "form-group mb-lg-0"
  }, [_c('label', {
    staticClass: "col-lg-5 col-xl-3 col-form-label"
  }, [_vm._v("Amount:")]), _c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "lg": "7",
      "xl": "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.formData.amount) + " " + _vm._s(_vm.formData.currencyId.toUpperCase()) + " ")])], 1), _c('b-row', {
    staticClass: "form-group mb-4"
  }, [_c('label', {
    staticClass: "col-lg-5 col-xl-3 col-form-label"
  }, [_vm._v("Description:")]), _c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "lg": "7",
      "xl": "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.formData.details) + " ")])], 1)], 1), _c('b-row', {
    staticClass: "mt-3"
  }, [_c('b-col', [_c('div', {
    staticClass: "card bg-white mb-4 mb-lg-5"
  }, [_c('div', {
    staticClass: "card-body py-4 py-sm-5"
  }, [_vm.isWalletGranted('approve_transaction', _vm.currentWallet.walletId) && _vm.transferAction === 'approve' ? _c('authenticator', {
    ref: "sms",
    attrs: {
      "data": _vm.formData,
      "resend-data": _vm.authenticatorData,
      "show-back-button": false,
      "on-back": _vm.handleBack,
      "on-submit": _vm.submit,
      "type": "transfer",
      "button-text": "Confirm transfer"
    }
  }) : _vm._e(), _vm.isWalletGranted('initiate_transaction', _vm.currentWallet.walletId) && _vm.transferAction === 'initiate' ? _c('authenticator', {
    ref: "sms",
    attrs: {
      "data": _vm.formData,
      "resend-data": _vm.authenticatorData,
      "show-back-button": false,
      "on-back": _vm.handleBack,
      "on-submit": _vm.submitInitiate,
      "type": "transfer",
      "button-text": "Save for confirmation"
    }
  }) : _vm._e()], 1)])])], 1), _c('b-row', [_c('b-col', {
    staticClass: "text-center"
  }, [_c('input', {
    staticClass: "btn btn-outline-dark px-5",
    attrs: {
      "type": "button",
      "value": "Edit transfer"
    },
    on: {
      "click": _vm.handleBack
    }
  })])], 1)], 1) : _vm._e(), _vm.currentStep === 3 ? _c('div', {
    staticClass: "justify-content-md-center"
  }, [_c('div', {
    staticClass: "text-center py-4"
  }, [_c('h3', {
    staticClass: "text-success mb-4"
  }, [_vm._v(" Your transfer #" + _vm._s(_vm.transactionId) + " has been sent ")]), _c('router-link', {
    staticClass: "btn btn-outline-dark px-5",
    attrs: {
      "to": {
        name: 'transactions'
      },
      "exact": ""
    }
  }, [_vm._v(" Check transactions ")])], 1)]) : _vm._e(), _vm.currentStep === 4 ? _c('div', {
    staticClass: "justify-content-md-center"
  }, [_c('div', {
    staticClass: "text-center py-4"
  }, [_c('h3', {
    staticClass: "text-success mb-4"
  }, [_vm._v(" Your transfer saved for confirmation later ")]), _c('div', {
    staticClass: "btn btn-outline-dark px-5",
    on: {
      "click": _vm.goToPending
    }
  }, [_vm._v(" Check for confirmation ")])])]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }