var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "nano-transfer"
  }, [_c('b-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.currentStep == 1,
      expression: "currentStep == 1"
    }]
  }, [_c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "xl": "7",
      "cols": "12"
    }
  }, [_c('b-card', [_c('payment-request-form', {
    ref: "transferForm",
    attrs: {
      "form-fields": _vm.formFields,
      "loading": _vm.loading,
      "data-vv-scope": "form"
    },
    on: {
      "change": _vm.formChange,
      "onBlur": _vm.onBlur
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "xl": "5",
      "cols": "12"
    }
  }, [_c('b-card', [_c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', [_c('span', {
    staticClass: "notes",
    domProps: {
      "innerHTML": _vm._s(_vm.notes)
    }
  })])], 1)], 1), _c('b-row', {
    staticClass: "mt-3"
  }, [_c('b-col', [_c('button', {
    staticClass: "btn btn-primary btn-block",
    on: {
      "click": _vm.startTransfer
    }
  }, [_vm._v(" " + _vm._s(_vm.loading === true ? 'Loading...' : 'Next') + " ")])])], 1)], 1)], 1), _vm.currentStep === 3 ? _c('div', {
    staticClass: "justify-content-md-center"
  }, [_c('div', {
    staticClass: "text-center py-4"
  }, [_c('h3', {
    staticClass: "text-success mb-4"
  }, [_vm._v(" Your payment request "), _c('b', [_vm._v(_vm._s(_vm.transactionId))]), _vm._v(" has been sent ")]), _c('hr'), _vm._v(" Checkout url:"), _c('br'), _c('br'), _vm._v(" " + _vm._s(_vm.redirectUrl) + " "), _c('br'), _c('br'), _c('router-link', {
    staticClass: "btn btn-outline-dark px-5",
    attrs: {
      "to": {
        name: 'payment-requests'
      },
      "exact": ""
    }
  }, [_vm._v(" Check payment requests ")])], 1)]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }