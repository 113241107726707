var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "code-input"
  }, [_c('div', {
    staticClass: "code-input__wrapper"
  }, _vm._l(_vm.digits, function (v, id) {
    return _c('div', {
      key: id,
      staticClass: "code-input__single",
      "class": {
        'code-input__single-disabled': _vm.disabled
      },
      attrs: {
        "data-code": "digit-".concat(id)
      },
      on: {
        "click": function click($event) {
          return _vm.$refs.input.focus();
        }
      }
    }, [_c('span', {
      "class": {
        'code-input__char-placeholder': !(_vm.code && _vm.code.toString().split('')[id]),
        'code-input__char-active': (_vm.codeString.split('').length == id || _vm.codeString.length == _vm.digits && _vm.codeString.length == id + 1) && _vm.focused
      },
      attrs: {
        "contenteditable": "true"
      },
      on: {
        "paste": function paste($event) {
          $event.preventDefault();
          return _vm.onPaste.apply(null, arguments);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.code && _vm.code.toString().split('')[id] || _vm.placeholder) + " ")])]);
  }), 0), _c('div', {
    staticClass: "code-input__hidden-field"
  }, [_c('input', {
    ref: "input",
    attrs: {
      "type": _vm.numbersOnly ? 'number' : 'text',
      "disabled": _vm.disabled
    },
    domProps: {
      "value": _vm.code
    },
    on: {
      "focus": function focus() {
        return _vm.focused = true;
      },
      "blur": function blur() {
        return _vm.focused = false;
      },
      "input": function input($event) {
        return _vm.setCode($event.target.value);
      }
    }
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }