<template v-cloak>
	<div>
		<div class="search-block transactions-search">
			<b-row>
				<b-col 
					sm="6" 
					md="12" 
					lg="12" 
					xl="5" 
					class="mb-4 mb-sm-0 mb-md-4 mb-xl-0"
				>
					<div class="form-group search-string mb-0">
						<b-form-input
							v-model="searchString"
							placeholder="Search transactions..."
							type="text"
							name="searchString"
							@input="getTransactionsWithDebounce()"
						/>
					</div>
				</b-col>
				<b-col 
					sm="6" 
					md="9" 
					lg="8" 
					xl="4"
				>
					<date-range-select 
						:date-range="dateRange" 
						@dateRangeChanged="dateRangeChanged"
					/>
				</b-col>
				<b-col 
					cols="3" 
					lg="4"
					xl="3"
					class="d-none d-md-block"
				>
					<div class="d-flex align-items-center">
						<p class="mr-3 mb-0 text-muted align-self-center text-nowrap items-per-page-label">
							Items <br> per page
						</p>
						<custom-select
							:items="pages"
							:hide-front="false"
							:modify-bg="true"
							@select="filterPages($event)"
						/>
					</div>
				</b-col>
			</b-row>
		</div>
		<h3 
			v-show="short" 
			class="mb-4 transactions-title"
		>
			Latest transactions
		</h3>
		<div
			v-if="total"
			class="transactions"
		>
			<div class="transactions-list-wrapper">
				<div class="transactions-list">
					<transaction-list-item
						v-for="(tr, i) in list"
						:id="'id' + i"
						:key="tr.id"
						:item="tr"
						:selected="selectedItem"
						@select="item => selectItem(item)"
					/>
				</div>
				<div class="d-none d-xl-block">
					<transaction-list-item-details
						:item="selectedItem"
						@close="() => selectItem(null)"
					/>
				</div>
			</div>
			<div 
				v-show="short && total > 2" 
				class="pt-4 text-center"
			>
				<router-link
					:to="{ name: 'transactions' }"
					class="btn btn-info allTransactions"
					exact
				>
					All transactions
				</router-link>
			</div>

			<div
				v-show="!short && limit * 1 < total"
			>
				<pagination
					:total="total"
					:per-page="limit"
					:current-page="currentPage"
					@pagechanged="onPageChange"
				/>
			</div>
		</div>

		<div
			v-else-if="!listLoaded"
			class="empty-list"
		>
			<preloader />
		</div>

		<div
			v-else
			class="empty-list"
		>
			<NoTransactions />

			<div 
				v-show="short" 
				class="dashboard-content-blocks"
			>
				<b-row>
					<b-col 
						cols="12" 
						sm="6" 
						class="mb-4 mb-sm-0"
					>
						<b-card>
							<h3 class="font-weight-bold">
								No funds in your account
							</h3>
							<p>
								You can add funds into {{ siteName }} account in any currency, using
								a manual transfer from other bank account.
							</p>
							<button 
								v-b-modal.acc-info-modal 
								class="btn btn-primary"
								:disabled="!currentWallet"
							>
								Account details
							</button>
						</b-card>
					</b-col>
					<b-col 
						cols="12" 
						sm="6"
					>
						<b-card>
							<h3 class="font-weight-bold">
								Have a Question?
							</h3>
							<p>
								Our team of dedicated support specialists work around the clock to ensure your queries, concerns and feedback are acknowledged and resolved effectively.
							</p>
							<router-link 
								:to="{ name: 'faq' }" 
								:active="$route.matched.some(({ name }) => name === 'faq')" 
								class="btn btn-secondary"
							>
								FAQ
							</router-link>
						</b-card>
					</b-col>
				</b-row>
			</div>
		</div>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import debounce from 'lodash/debounce';
import TransactionListItem from './TransactionListItem';
import DateRangeSelect from '@/utils/DateRangeSelect';
import NoTransactions from './NoTransactions';
import Preloader from '@/utils/Preloader';
import { endpoints, siteName } from '~/config';
import Pagination from '@/utils/Pagination';
import CustomSelect from '@/utils/CustomSelect';
import axios from 'axios';
import TransactionListItemDetails from './TransactionListItemDetails.vue';
import moment from 'moment';

export default {
	name: 'DashboardTransactions',
	components: {
		TransactionListItem,
		Preloader,
		DateRangeSelect,
		NoTransactions,
		Pagination,
		CustomSelect,
		TransactionListItemDetails
	},
	props: {
		short: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			transactionStatuses: {
				public: 'All',
				failed: 'Failed',
				completed: 'Completed',
				pending: 'Pending'
			},
			pages: {
				10: '10',
				20: '20',
				50: '50',
			},
			windowWidth: window.innerWidth,
			limit: '10',
			total: 0,
			allTransactions: false,
			currentPage: 1,
			selectedPage: 1,
			list: [],
			listLoaded: false,
			searchString: '',
			status: '',
			showSearch: true,
			dateRange: {
				startDate: '',
				endDate: new Date()
			},
			selectedItem: null,
			siteName,
		};
	},
	computed: {
		...mapGetters('user', [
			'isVerified'
		]),
		...mapGetters('wallet', [
			'currentWallet'
		])
	},
	watch: {
		currentWallet: function () {
			this.getTransactionList();
		},
	},
	created() {
		this.debouncedGetTransactions = debounce(this.getTransactionList, 500);
	},
	mounted() {
		this.getTransactionList();
		this.allTransactions = window.location.pathname.replace('/', '') === 'transactions';
	},
	methods: {
		...mapActions('notifications', [
			'pushError'
		]),
		selectItem(item) {
			this.selectedItem = this.selectedItem && item && this.selectedItem.id == item.id ? null : item;
		},
		dateRangeChanged(dateRange) {
			let startDate = new Date(dateRange.startDate);
			let endDate = new Date(dateRange.endDate);

			this.dateRange.startDate = startDate.toISOString();
			this.dateRange.endDate = endDate.toISOString();
			this.getTransactionList();
		},
		filterTransactionType(event) {
			if (event === 'public') {
				this.status = '';
			} else {
				this.status = event;
			}
			this.getTransactionList();
		},
		filterPages(event) {
			this.limit = event;
			this.getTransactionList();
		},
		getTransactionsWithDebounce() {
			this.debouncedGetTransactions();
		},
		onPageChange(page) {
			this.currentPage = page;
			this.getTransactionList();
		},
		getTransactionList() {
			this.selectedItem = null;
			if (this.currentWallet === null) {
				this.list = [];
				this.total = 0;
				this.listLoaded = true;
				return;
			}
			let url = endpoints.transactions.list({ walletId: this.currentWallet.walletId });

			let params = {
				createdFrom: this.dateRange.startDate 
					? moment(this.dateRange.startDate).format('YYYY-MM-DD 00:00:00')
					: moment().subtract(10, 'years').format('YYYY-MM-DD 00:00:00'),
				createdTo: moment(this.dateRange.endDate).format('YYYY-MM-DD 23:59:59'),
				search: this.searchString,
				orderBy: 'timestampCreated',
				page: this.currentPage,
				limit: this.limit,
				orderDirection: 'desc'
			};

			if (this.searchString.length) {
				params.search = this.searchString;
			}
			if (this.status.length) {
				params.status = this.status;
			}

			axios
				.get(url, {
					params: params
				})
				.then(response => {
					this.list = response.data.rows;
					this.total = response.data.count;
					this.listLoaded = true;
					if (!this.showSearch) {
						this.showSearch = this.total;
					}
				})
				.catch((error) => {
					this.listLoaded = true;
					this.pushError(error.message);
				});
		}
	}
};
</script>

<style lang="scss" scoped>
	@import './Transactions.scss';
</style>