<template>
	<div class="notifications-wrapper">
		<notifications 
			v-for="g in groups" 
			:key="g.group"
			:group="g.group"
			:position="g.position"
		/>
	</div>
</template>
<script>
import { mapState } from 'vuex';
export default {
	name: 'NotificationsWrapper',
	computed: {
		...mapState('notifications', ['groups'])
	},
};
</script>
<style lang="scss">
	@import './NotificationsWrapper.scss';
</style>
