<template>
	<div class="dashboard">
		<div class="page-title-container">
			<b-row class="justify-content-between align-items-center">
				<b-col cols="auto">
					<h2 class="username">
						Hi, {{ setUsername(user) }}!
					</h2>
					<p
						v-if="!log.lastlogLoaded"
						class="last-activity"
					>
						<preloader-text />
					</p>
					<p
						v-else
						class="last-activity"
					>
						<span class="mr-3">
							Last activity:
							{{
								log.lastLogdateCreated
									| timestampToLocalDateTime
							}}
							from {{ log.lastLog.ip }}
							{{ log.lastLog.browser }}</span>
						<router-link
							:to="{ name: 'log' }"
							exact
							class="text-nowrap"
						>
							View all activity
						</router-link>
					</p>
				</b-col>
				<b-col
					cols="auto"
					class="ml-auto"
				>
					<div class="logout d-none d-lg-block">
						<router-link
							:to="{ name: 'login' }"
							exact
						>
							<ion-icon name="log-out-outline" />
						</router-link>
					</div>
				</b-col>
			</b-row>
		</div>
		<div v-if="successRegistration">
			<b-alert
				class="text-center"
				show
				variant="success"
			>
				Registration complete for
				<strong>{{ $store.state.authUser.email }}</strong>!
			</b-alert>
		</div>
		<vueper-slides
			v-if="WHITELABEL == 'verifo'"
			:visible-slides="2"
			:gap="2"
			autoplay
			:dragging-distance="200"
			:breakpoints="{
				1200: { fixedHeight: '200px' },
				768: { gap: 0, fixedHeight: '210px' },
				650: { fixedHeight: '220px' },
				520: { visibleSlides: 1, bullets: false },
			}"
			:arrows="false"
			fixed-height="204px"
			class="no-shadow slider"
		>
			<vueper-slide
				v-for="(slide, i) in slides"
				:key="i"
				:image="slide.image"
				:title="slide.title"
				:content="slide.content"
				:link="slide.link"
				:target="slide.link && !slide.samePage &&'_blank'"
				:class="{
					'vueperslide--dark' : slide.dark
				}"
			>
				<template #content>
					<div class="vueperslide__content-wrapper">
						<div
							v-if="slide.new"
							class="vueperslide__new-badge"
						>
							New
						</div>
						<div
							class="vueperslide__title"
							v-html="slide.title"
						></div>
						<div
							v-if="slide.button && slide.action"
							class="vueperslide__content"
						>
							<b-button
								v-if="slide.button"
								size="sm"
								:variant="slide.buttonVariant ? slide.buttonVariant : 'primary'"
								@click="slide.action"
							>
								{{ slide.button }}
							</b-button>
						</div>
					</div>
				</template>
			</vueper-slide>
		</vueper-slides>
		<NewsHomepage :key="newsKey"
			:extra-fields="[...cardsNotifications, ...oddNotifications]"
		/>
		<verification-card />
		<transactions short />
	</div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { siteName } from '~/config';
import { VueperSlides, VueperSlide } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';
import Transactions from '@/LayoutDashboard/Transaction/Transactions';
import PreloaderText from '@/utils/PreloaderText';
import NewsHomepage from '@/LayoutDashboard/NewsHomepage/NewsHomepage';
import VerificationCard from '@/shared/VerificationCard';
import { setUsername } from '~/helpers';
import moment from 'moment';

export default {
	name: 'Dashboard',
	filters: {
		timestampToLocalDateTime: function (value) {
			return moment(value).format('DD/MM/YYYY, hh:mm:ss A');
		},
	},
	components: {
		Transactions,
		PreloaderText,
		VueperSlides,
		VueperSlide,
		NewsHomepage,
		VerificationCard
	},
	data() {
		return {
			siteName,
			tab: 0,
			list: [],
			searchString: '',
			status: '',
			balanceLoaded: false,
			slides: [
				{
					title: 'SWIFT PAYMENTS<p>Forget about unreasonably high international transfer fees. With SWIFT, you can improve your cross-border money transfers and save money for other essential things.</p>',
					dark: true,
					image: 'images/slides/swift-payments.jpg',
					action: () => this.$router.push({ name: 'transfer' }),
					button: 'Try now',
					new: true
				},
				{
					title: 'Merchant API<p>Embed our top-notch payments technology with our APIs to improve the way you collect clients\' money and manage digital payments. Simply integrate the APIs onto your website following the instructions, and enjoy the benefits of next-level customer engagement.</p>',
					image: 'images/slides/merchant-api.jpg',
					action: () => window.open('https://documenter.getpostman.com/view/12483031/TzY4fapo', '_blank'),
					button: 'More info',
					buttonVariant: 'secondary',
					new: true
				},
				{
					title: 'New feature: Connected accounts<p>Now you can open and manage multiple business IBANs from your personal account.  To add your first business account simply click on the green arrow next to your name on the top left of the screen. Then, follow the instructions.</p>',
					//content: '<button class="btn btn-secondary">Invite</button>',
					image: 'images/slides/block-connected.png',
				},
				{
					title: `White-label solution<p>${siteName} is powered by VerifiedPayments.com white-label banking solution. Starting your own fintech company can be as easy as getting in touch. White-label includes infrastructure, EMI license, and regulatory compliance.</p>`,
					//content: '<button class="btn btn-primary">Get in Touch</button>',
					image: 'images/slides/block-verified.png',
					dark: true,
					link: 'https://verifiedpayments.com'
				},
				{
					title: 'New feature: Bulk payments<p>We developed “Bulk payments” to make your money transfers even more convenient. Fill the details into .CSV file and make the payment. Template is provided.</p>',
					//content: '<button class="btn btn-secondary">Invite</button>',
					image: 'images/slides/block-bulk.png',
					dark: true
				},
				{
					title: 'New currency: UAE Dirham<p>Settle with your partners from United Arab Emirates even smoother by making a money transfer in their local currency - Dirham. Click on “SWIFT Transfer” tab, fill in the payment details, and select AED for Emirati Dirham.</p>',
					//content: '<button class="btn btn-secondary">Invite</button>',
					image: 'images/slides/block-uae.png'				},
				{
					title: 'SWIFT payments in 29 currencies<p>We developed SWIFT payment solution that allows you to transfer euros in 29 major currencies anywhere in the world. Simply start SWIFT transfer and fill in the details of the recipient. We will take care of the rest.</p>',
					//content: '<button class="btn btn-secondary">Invite</button>',
					image: 'images/slides/block-swift-bg.png',
					dark: true
				},
				{
					title: `Invite your business partners to ${siteName}<p>Invite your partners to join ${siteName} and send them instant transfers in just a few taps.</p>`,
					//content: '<button class="btn btn-secondary">Invite</button>',
					image: 'images/slides/block1-bg.jpg',
				},
			],
			WHITELABEL,
			newsKey: 0
		};
	},
	computed: {
		...mapState({
			user: (state) => state.user.selected,
			wallet: (state) => state.wallet,
			log: (state) => state.log,
			cardsList: state => state.cards.list,
			oddIncomplete: (state) => state.user.oddIncomplete
		}),
		...mapGetters('user', ['isBusiness', 'verificationLink', 'username']),
		...mapGetters(['currenciesOptions']),
		cardsNotifications: function() {
			const messages = [];
			const list = this.cardsList;
			const created = list.filter(card => card.status == 'created');
			const sent = list.filter(card => card.status == 'sent');
			if (created.length == 0 && sent.length == 0) return [];
			if (created.length > 0) {
				messages.push({
					id: 'createdcard',
					keep: true,
					html: `Click here to finish your card order. There ${created.length > 1 ? 'are' : 'is'} ${created.length} card${created.length > 1 ? 's' : ''} waiting for PIN.`,
					icon: '<i class="fas fa-credit-card"></i>',
					visible: true,
					link: '/cards',
					color: '#ffc107'
				});
			}
			if (sent.length > 0) {
				messages.push({
					id: 'sentcard',
					html: `There ${created.length > 1 ? 'are' : 'is'} ${sent.length} innactive card${created.length > 1 ? 's' : ''}. Click here or go to cards page to activate it.`,
					icon: '<i class="fas fa-credit-card"></i>',
					visible: true,
					link: '/cards',
					color: '#ffc107'
				});
			}
			return messages;
		},
		oddNotifications() {
			return this.oddIncomplete 
				? [{
					id: 'odd-incomplete',
					html: 'Click here to finish updating your information.',
					icon: '<i class="fas fa-user"></i>',
					visible: true,
					action: async () => { 
						try {
							await this.getUserProfile();
							this.setOddIncomplete(false);
							this.newsKey += 1;
						} catch (error) {
							this.pushError(error);
						}
					},
					color: '#ffc107',
					textColor: '#000',
					keep: true
				}]
				: [];
		},
		successRegistration: function () {
			return window.location.hash === '#registered';
		},
	},
	created() {
		this.getLastLog();
	},
	methods: {
		setUsername,
		...mapActions('log', ['getLastLog']),
		...mapActions('user', ['getUserProfile', 'setOddIncomplete']),
		...mapActions('notifications', ['pushError']),
		currencyName: function (currency_id) {
			let name = this.currenciesOptions.find(
				(currency) => currency.currency_id === currency_id
			);
			if (name) {
				return name.name;
			} else {
				return currency_id.toUpperCase();
			}
		},
	},
};
</script>
<style lang="scss">
@import "./Dashboard.scss";
</style>