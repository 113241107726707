<template>
	<div class="wrapper-verification">
		<verification-steps 
			v-if="isRegistration" 
			:steps="registrationSteps"
		/>

		<div class="main">
			<transition
				name="fade" 
				mode="out-in"
			>
				<router-view />
			</transition>
		</div>
	</div>
</template>

<script>
import VerificationSteps from './VerificationSteps/VerificationSteps.vue';
import { mapGetters } from 'vuex';

export default {
	name: 'Verification',
	components: {
		VerificationSteps,
	},
	computed: {
		...mapGetters('register', [
			'isRegistration',
			'registrationSteps',
			'nextStep'
		]),
		...mapGetters('user', [
			'isPersonal',
			'isBusiness',
			'isVerified',
		]),
		...mapGetters('routes', ['currentRouteName']),
		currentStep: {
			get() {
				let routes = {
						account: ['verify-account', 'profile.account'],
						personal: ['verify-person', 'profile.personal'],
						business: ['verify-business', 'profile.company'],
						documents: ['verify-business-documents', 'profile.documents'],
						photo: ['verify-photo', 'profile.documents'],
						personQuestionnaire: ['person-questionnaire', 'profile.questionnaire'],
						businessQuestionnaire: ['business-questionnaire', 'profile.questionnaire-business'],
					},
					currentStep = '';

				for (let key in routes) {
					if (routes[key].includes(this.currentRouteName)) {
						currentStep = key;
					}
				}

				return currentStep;
			}
		},
		currentForm: {
			get() {
				let forms = {
					account: 'verify-account-info-form',
					personal: 'verify-person-form',
					business: 'verify-business-form',
					documents: 'verify-business-documents-form',
					photo: 'verify-photo',
					personQuestionnaire: 'person-questionnaire-form',
					businessQuestionnaire: 'business-questionnaire-form',
				};

				return forms[this.currentStep];
			}
		},
		headerTitle: {
			get() {
				let titles = {
					personal: 'Personal information',
					personQuestionnaire: 'Additional information',
					photo: 'Identity verification'
				};

				if (this.isBusiness) {
					titles = {
						personal: 'Key persons',
						businessQuestionnaire: 'Use of account',
						business: 'Company info',
						documents: 'Documents',
						photo: 'Identity verification',
						account: 'Account Information'
					};
				}

				return titles[this.currentStep];
			}
		},
		isPhotoStep: {
			get() {
				return this.currentRouteName === 'verify-photo';
			}
		}
	},
	methods: {
		onSave() {
			if (this.isRegistration) {
				if(this.isPhotoStep) {
					window.location.push('/home');
					return;
				}
				let next = this.nextStep;
				this.$router.push({ name: next });
				return;
			} else {
				this.$store.dispatch('updateUserData');
			}

			this.$store.dispatch('showSuccessNotification', 'Data saved successfully');
		}
	}
};
</script>
<style lang="scss">
.fade-enter-active, .fade-leave-active {
	transition: opacity .2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}
</style>