<template>
	<div class="odd-form">
		<b-form>
			<b-row>
				<b-col>
					<h2>Update your personal questionnaire</h2>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<b-card v-for="(occupation, index) in formData.occupationList"
						:key="index"
						class="mb-2"
					>
						<form-error-field :name="`occupationList[${index}].value`">
							<label for="">What's your occupation?</label>
							<v-multiselect
								v-model="occupation.value"
								v-validate="'required'"
								:options="options.occupationTypes"
								:multiple="false"
								:allow-empty="false"
								:show-labels="false"
								:close-on-select="true"
								placeholder="Occupation"
								:name="`occupationList[${index}].value`"
								data-vv-as="Occupation"
								label="name"
								track-by="name"
								return="value"
								@input="onOccupationChange(index)"
							/>
						</form-error-field>
						<form-error-field v-if="occupation.value == 'other'"
							:name="`occupationList[${index}].otherValue`"
						>
							<b-form-input
								id="occupation-other"
								v-model="occupation.otherValue"
								v-validate="'required'"
								:name="`occupationList[${index}].otherValue`"
								data-vv-as="Occupation other"
								type="text"
								placeholder="Enter other occupation"
								:state="!errors.has('Other occupation') ? null : false"
							/>
						</form-error-field>
						<form-error-field v-if="getOccupationParams(index)?.companyActivity"
							:name="`occupationList[${index}].companyActivities.values`"
						>
							<label for="Industry">Industry</label>
							<v-multiselect
								v-model="occupation.companyActivities.values"
								v-validate="'required'"
								:multiple="true"
								:close-on-select="false"
								:options="options.companyActivityTypes"
								placeholder="Select industry"
								:name="`occupationList[${index}].companyActivities.values`"
								data-vv-as="Industry"
								return="value"
								label="name"
								track-by="name"
							/>
						</form-error-field>
						<form-error-field v-if="occupation.companyActivities?.values.includes('other')"
							:name="`occupationList[${index}].companyActivities.otherValue`"
						>
							<b-form-input
								id="activities-other"
								v-model="occupation.companyActivities.otherValue"
								v-validate="'required'"
								:name="`occupationList[${index}].companyActivities.otherValue`"
								data-vv-as="Other activities"
								type="text"
								placeholder="Enter other activities"
								:state="!errors.has('Other activities') ? null : false"
							/>
						</form-error-field>

						<form-error-field v-if="getOccupationParams(index)?.companyPosition"
							:name="`occupationList[${index}].companyPosition.value`"
						>
							<label for="Company Position">Your position in the Company</label>
							<v-multiselect
								v-model="occupation.companyPosition.value"
								v-validate="'required'"
								:multiple="false"
								:close-on-select="true"
								:options="options.companyPositions"
								placeholder="Select position"
								:name="`occupationList[${index}].companyPosition.value`"
								data-vv-as="Company position"
								return="value"
								label="name"
								track-by="name"
							/>
						</form-error-field>
						<form-error-field v-if="occupation.companyPosition?.value == 'other'"
							:name="`occupationList[${index}].companyPosition.otherValue`"
						>
							<b-form-input
								id="position-other"
								v-model="occupation.companyPosition.otherValue"
								v-validate="'required'"
								:name="`occupationList[${index}].companyPosition.otherValue`"
								data-vv-as="Other position"
								type="text"
								placeholder="Enter other position"
							/>
						</form-error-field>
						<form-error-field v-if="getOccupationParams(index)?.institutionPosition"
							:name="`institutionPosition[${index}]`"
						>
							<label for="Institution position">Your position in the Institution:</label>
							<b-form-input
								id="institution-position"
								v-model="occupation.institutionPosition"
								v-validate="'required'"
								:name="`institutionPosition[${index}]`"
								data-vv-as="Institution position"
								type="text"
								placeholder="Enter institution position"
							/>
						</form-error-field>
						<form-error-field v-if="getOccupationParams(index)?.companyName"
							:name="`occupationList[${index}].institutionName`"
						>
							<label for="Institution name">Name of the company/institution:</label>
							<b-form-input
								id="institution-name"
								v-model="occupation.institutionName"
								v-validate="'required'"
								:name="`occupationList[${index}].institutionName`"
								data-vv-as="Institution name"
								type="text"
								placeholder="Enter institution name"
							/>
						</form-error-field>
						<b-button v-if="index > 0"
							variant="danger"
							size="sm"
							class="my-2"
							@click="removeOccupation(index)"
						>
							Remove occupation
						</b-button>
					</b-card>
					<b-button variant="primary"
						size="sm"
						class="my-2"
						@click="addOccupation()"
					>
						Add occupation
					</b-button>
				</b-col>
			</b-row>

			<b-row>
				<b-col>
					<form-error-field name="sourceOfIncome.values">
						<label for="Source of income">Source of income</label>
						<v-multiselect
							v-model="formData.sourceOfIncome.values"
							v-validate="'required'"
							:options="options.sourceOfIncome"
							:multiple="true"
							:close-on-select="false"
							:clear-on-select="false"
							:preserve-search="true"
							:preselect-first="false"
							placeholder="Make selection"
							data-vv-as="Source of income"
							name="sourceOfIncome.values"
							label="name"
							track-by="name"
							return="value"
						/>
					</form-error-field>
					<form-error-field v-if="formData.sourceOfIncome.values.includes('other')"
						name="sourceOfIncome.otherValue"
					>
						<b-form-input
							id="source-of-income-other"
							v-model="formData.sourceOfIncome.otherValue"
							v-validate="'required'"
							data-vv-as="Other source of income"
							name="sourceOfIncome.otherValue"
							type="text"
							placeholder="Enter other source of income"
							:state="!errors.has('Other source of income') ? null : false"
						/>
					</form-error-field>
				</b-col>
			</b-row>

			<b-row>
				<b-col>
					<form-error-field name="accountOpeningPurposes.values">
						<label for="Account opening purposes">Account opening purposes</label>
						<v-multiselect
							v-model="formData.accountOpeningPurposes.values"
							v-validate="'required'"
							:options="options.purposeForOpeningTypes"
							:multiple="true"
							:close-on-select="false"
							:clear-on-select="false"
							:preserve-search="true"
							:preselect-first="false"
							placeholder="Make selection"
							data-vv-as="Account opening purposes"
							name="accountOpeningPurposes.values"
							label="name"
							track-by="name"
							return="value"
						/>
					</form-error-field>
					<form-error-field v-if="formData.accountOpeningPurposes.values.includes('other')"
						name="accountOpeningPurposes.otherValue"
					>
						<b-form-input
							id="account-opening-purpose-other"
							v-model="formData.accountOpeningPurposes.otherValue"
							v-validate="'required'"
							data-vv-as="Other account opening purpose"
							name="accountOpeningPurposes.otherValue"
							type="text"
							placeholder="Enter other account opening purpose"
							:state="!errors.has('Other account opening purpose') ? null : false"
						/>
					</form-error-field>
				</b-col>
			</b-row>
		</b-form>
		<odd-form-footer
			v-bind="properties"
		/>
	</div>
</template>
<script>
import { mapState } from 'vuex';
import OddFormFooter from '../OddFormFooter';
import options from '@/LayoutVerification/QuestionnairePerson/QuestionnairePersonOptions';

import FormErrorField from '@/shared/Form/FormErrorField';

export default {
	name: 'ODDPersonQuestionnaire',
	components: {
		OddFormFooter,
		FormErrorField
	},
	data() {
		return {
			formData: {
				occupationList: [],
				sourceOfIncome: {
					values: []
				},
				accountOpeningPurposes: {
					values: []
				}
			},
			options
		};
	},
	computed: {
		...mapState('user', {
			questionnaire: state => state.selected.userProfile?.questionnaire
		}),
		properties() {
			return {
				...this._props,
				formData: this.formData
			};
		}
	},
	watch: {

	},
	props: {
		onSuccess: {
			type: Function,
			default: () => {},
			required: true
		},
		onFailure: {
			type: Function,
			default: () => {},
		},
		onCancel: {
			type: Function,
			default: () => {}
		},
		submitUrl: {
			type: String,
			default: '',
			required: true
		}
	},
	methods: {
		getOccupationParams(index) {
			const v = this.formData.occupationList[index].value;
			return this.options.occupationTypes.find(option => option.value == v);
		},
		getOccupationType(v) {
			return this.options.occupationTypes.find(option => option.value == v);
		},
		addOccupation() {
			this.formData.occupationList.push({
				value: null,
				otherValue: null,
				institutionName: null,
				institutionPosition: null,
				companyActivities: null,
				companyPosition: null
			});
		},
		removeOccupation(index) {
			this.formData.occupationList.splice(index, 1);
		},
		onOccupationChange(index) {
			this.$set(this.formData.occupationList[index], 'companyActivities', this.getOccupationParams(index)?.companyActivity ? { 'values': [], otherValue: null } : null);
			this.$set(this.formData.occupationList[index], 'companyPosition', this.getOccupationParams(index)?.companyPosition ? { 'value': null, otherValue: null } : null);
			this.$set(this.formData.occupationList[index], 'institutionName', null);
			this.$set(this.formData.occupationList[index], 'institutionPosition', null);
		},
		async prefillForm () {
			const q = this.questionnaire;

			if (q.occupationList.length > 0) {
				await q.occupationList.forEach(occupation => {
					if (!this.getOccupationType(occupation.value)?.companyActivity) {
						delete delete occupation.companyActivities;
					}
					if (!this.getOccupationType(occupation.value)?.companyPosition) {
						delete delete occupation.companyPosition;
					}
				});

				await this.$set(this.formData, 'occupationList', q.occupationList);
			}

			if (q.accountOpeningPurposes.values.length > 0) this.$set(this.formData, 'accountOpeningPurposes', q.accountOpeningPurposes);
			if (q.sourceOfIncome.values.length > 0) this.$set(this.formData, 'sourceOfIncome', q.sourceOfIncome);
		}
	},
	created() {
		if (this.formData.occupationList?.length == 0) this.addOccupation();
	},
	mounted() {
		this.prefillForm();
	},
	provide() {
		return {
			$validator: this.$validator,
		};
	}
};
</script>
<style lang="scss">

</style>