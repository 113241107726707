<template>
	<b-modal
		v-cloak
		:id="id"
		size="sm"
		centered
		hide-header
		hide-footer
		no-close-on-backdrop
		shown
		@hidden="onHide"
	>
		<div class="text-center card-content-modal">
			<div>
				<slot />
			</div>
			<b-button
				variant="outline-primary"
				class="mt-4"
				@click="closeModal"
			>
				Close
			</b-button>
		</div>
	</b-modal>
</template>
<script>


export default {
	name: 'CardContentModal',
	props: {
		id: {
			type: String,
			default: 'card-content-modal'
		},
		onClose: {
			type: Function
		}
	},
	data() {
		return {
			// id: 'card-content-modal',
		};
	},
	methods: {
		closeModal() {
			this.$bvModal.hide(this.id);
		},
		onHide() {
			if (this.onClose) this.onClose();
		}
	}
};
</script>
<style lang="scss">
.card-prompt-modal {
	&__warning {
		color: $red
	}
}
</style>