<template>
	<div id="transfer-layout">
		<div class="page-title-container">
			<h2>Transfer</h2>
		</div>
		<tabs
			:key="key"
			class="mt-4"
		>
			<tab
				title="Transfer"
				:active="tab === 0"
				:disabled="!access.sepa"
				@click="() => $router.push('/transfer/simple')"
			>
				<div v-if="access.sepa">
					<nano-transfer
						:transfer-data="transactionData"
						:transaction-template="transactionTemplate"
						@go-to-pending="goToPending()"
					/>
				</div>
			</tab>
			<tab
				title="Multicurrency"
				:active="tab === 1"
				:disabled="!access.swift"
				@click="() => $router.push('/transfer/multicurrency')"
			>
				<div v-if="access.swift">
					<currency-cloud-transfer
						:transfer-data="transactionData"
						:transaction-template="transactionTemplate"
						@go-to-pending="goToPending()"
					/>
				</div>
			</tab>
			<!-- <tab
				v-if="access.swift && access.bank_swift"
				title="SWIFT Transfer"
				:active="transferType == 'swift-bank'"
				:disabled="!access.swift || !access.bank_swift"
				@click="() => $router.push('/transfer/swift-bank')"
			>
				<div v-if="access.swift && access.bank_swift">
					<bank-swift-transfer />
				</div>
			</tab> -->
			<tab
				title="Bulk Payments"
				:active="tab === 2"
				:disabled="!access.bulk"
				@click="() => $router.push('/transfer/bulk')"
			>
				<div v-if="access.bulk">
					<bulk-payments />
				</div>
			</tab>

			<tab
				v-if="user.role === 'business'"
				v-show="user.role === 'business' && access.pending"
				title="For confirmation"
				:active="tab === 3"
				:disabled="!access.pending"
				@click="() => $router.push('/transfer/pending')"
			>
				<div v-if="access.pending">
					<transaction-initiate-table />
				</div>
			</tab>

			<tab
				v-if="user.role === 'business'"
				v-show="user.role === 'business' && access.template"
				title="My template"
				:active="tab === 4"
				:disabled="!access.template"
				@click="() => $router.push('/transfer/template')"
			>
				<div v-if="access.template">
					<transaction-template-table
						@update-template="updateFromTemplate"
					/>
				</div>
			</tab>
		</tabs>
		<div
			v-if="!verified"
			class="my-4 text-center"
		>
			<verification-card />
		</div>
	</div>
</template>
<script>
import CurrencyCloudTransfer from './CurrencyCloudTransfer';
// import BankSwiftTransfer from './BankSwiftTransfer';
import BulkPayments from './BulkPayments';
import { mapGetters, mapState } from 'vuex';
import VerificationCard from '@/shared/VerificationCard';
import Tabs from '@/shared/Tabs';
import Tab from '@/shared/Tab';
import NanoTransfer from './NanoTransfer';
import TransactionInitiateTable from '@/LayoutDashboard/Transaction/TransactionInitiateTable';
import TransactionTemplateTable from '@/LayoutDashboard/Transaction/TransactionTemplateTable';

export default {
	name: 'TransferLayout',
	components: {
		TransactionInitiateTable,
		TransactionTemplateTable,
		CurrencyCloudTransfer,
		// BankSwiftTransfer,
		BulkPayments,
		VerificationCard,
		Tabs,
		Tab,
		NanoTransfer
	},
	props: {
		transferData: {
			type: Object,
			default: () => ({}),
			required: false
		},
		transferType: {
			type: String,
			default: null,
			required: false
		}
	},
	data() {
		return {
			tab: 0,
			key: null,
			transactionData: null,
			transactionTemplate: null,
		};
	},
	computed: {
		...mapGetters('user', [
			'isGranted',
			'isWalletGranted',
		]),
		...mapGetters('wallet', [
			'provider',
			'currentWallet'
		]),
		...mapState({
			user: (state) => state.user.selected
		}),
		verified() {
			return this.currentWallet && this.currentWallet.status === 'active' && this.user && this.user.verification == 'verified';
		},
		access: function() {
			return {
				sepa: this.verified
					&& this.isGranted('front_post_wallet.index.active-nano.:id.transaction.transfer-sepa')
					&& this.isWalletGranted('initiate_transaction', this.currentWallet.walletId)
					&& this.provider && this.provider === 'nano',
				swift: this.verified
					&& this.isGranted('front_post_wallet.index.active-nano.:id.transaction.transfer-swift')
					&& this.isWalletGranted('initiate_transaction', this.currentWallet.walletId)
					&& this.provider && this.provider === 'nano',
				bank_swift: this.verified
					&& this.isGranted('front_post_wallet.index.active-bank-swift.:id.transaction.transfer-bank')
					&& this.isWalletGranted('view_transaction', this.currentWallet.walletId)
					&& this.provider && this.provider === 'bank_swift',
				bulk: this.verified
					&& this.isGranted('front_post_wallet.index.active-nano.:id.payment.file')
					&& this.isWalletGranted('initiate_transaction', this.currentWallet.walletId),
				pending: this.verified
					&& this.isGranted('front_transaction_initiate_page')
					&& this.isWalletGranted('initiate_transaction', this.currentWallet.walletId),
				template: this.verified
					&& this.isGranted('front_transaction_template_page')
					&& this.isWalletGranted('initiate_transaction', this.currentWallet.walletId)
			};
		},
	},
	created() {
		this.transactionData = this.transferData;
		this.openTab();
		this.key = Date.now().toString();
	},
	methods: {
		updateFromTemplate(item){
			if (item) {
				if (item.serviceId === 'cc_local' || item.serviceId === 'cc_swift_sha') {
					this.tab = 1;
				} else {
					this.tab = 0;
				}
				this.transactionTemplate = item;
				this.key = Date.now().toString();
			}
		},
		goToPending(){
			this.tab = 3;
			this.key = Date.now().toString();
		},
		openTab() {
			if (this.transferType || (this.transferData && this.transferData.transferType)) {
				const type = this.transferType ?? this.transferData.transferType;
				// this.$nextTick(() => {
				if (type == 'swift' || type == 'multicurrency') {
					this.tab = 1;
				} else if (type == 'bulk') {
					this.tab = 2;
				} else if (type == 'pending') {
					this.tab = 3;
				} else {
					this.tab = 0;
				}

				// });
			}
			this.key = Date.now().toString();
		}
	}
};
</script>
