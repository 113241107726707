<template>
	<div class="nano-transfer">
		<b-row v-show="currentStep == 1">
			<b-col
				xl="7"
				cols="12"
				class="mb-3"
			>
				<b-card>
					<product-form
						ref="transferForm"
						:form-fields="formFields"
						:loading="loading"
						data-vv-scope="form"
						@change="formChange"
					/>
				</b-card>
			</b-col>
			<b-col
				xl="5"
				cols="12"
				class="mb-3"
			>
				<b-card>
					<b-row
						class="mt-2"
					>
						<b-col>
							<span
								class="notes"
								v-html="notes"
							/>
						</b-col>
					</b-row>
				</b-card>

				<b-row class="mt-3">
					<b-col>
						<button
							class="btn btn-primary btn-block"
							@click="startTransfer"
						>
							{{ loading === true ? 'Loading...' : 'Next' }}
						</button>
					</b-col>
				</b-row>
			</b-col>
		</b-row>
		<div
			v-if="currentStep === 2"
			class="justify-content-md-center"
		>
			<div class="text-center py-4">
				<h3 class="text-success mb-4">
					Choose payment method
				</h3>
				<hr />
				<form
					action=""
					@submit="submit($event)"
				>
					<fieldset>
						<b-row
							v-for="paymentMethod in paymentMethodList"
							:key="paymentMethod.id"
							class="form-group"
						>
							<b-col>
								<div style="background-color: rgb(235, 236, 239);color: rgb(33, 37, 41);">
									<img
										src="https://demo.kevin.eu/static/bank-payment.c7214bce.png"
										alt="Bank"
									/>
									<p>{{ paymentMethod.name }}</p>

									<b-form-checkbox
										type="text"
										@change="select(paymentMethod, $event)"
									/>
								</div>
							</b-col>
						</b-row>
						<b-row class="mt-3">
							<b-col>
								<button
									class="btn btn-primary btn-block"
								>
									Next
								</button>
							</b-col>
						</b-row>
					</fieldset>
				</form>
			</div>
		</div>
		<div
			v-if="currentStep === 3"
			class="justify-content-md-center"
		>
			<div class="text-center py-4">
				<h3 class="text-success mb-4">
					Wait confirmation. Product ID {{ productId }}
				</h3>
				<router-link
					:to="{ name: 'products' }"
					class="btn btn-outline-dark px-5"
					exact
				>
					Check products
				</router-link>
			</div>
		</div>
	</div>
</template>
<script>
import ProductForm from './ProductForm';
import fields from './FieldsData/fields';
import debounce from 'lodash/debounce';
import { endpointsGateway } from '~/config';
import { mapActions, mapGetters } from 'vuex';

export default {
	name: 'Product',
	components: {
		ProductForm,
	},
	props: {
		transferData: {
			type: Object,
			default: () => ({}),
			required: false
		},
	},
	data() {
		return {
			formFields: [],
			formData: {},
			loading: false,
			notes: 'Product creation may require some time and sometimes require more documents',
			currentStep: 1,
			productId: null,
			paymentMethodList: [],
			selectedPaymentMethod: [],
		};
	},
	computed: {
		...mapGetters('user', [
			'isGranted',
		]),
	},
	methods: {
		...mapActions('notifications', ['pushError']),
		formChange(value) {
			this.formData = { ...value };
		},
		handleBack() {
			this.currentStep = this.currentStep - 1;
		},
		//@todo paimti tik unikatu
		select(paymentMethod) {
			this.selectedPaymentMethod.push(paymentMethod);
		},
		submit(e) {
			e.preventDefault();

			this.selectedPaymentMethod.forEach(async (paymentMethod) => {
				const url = endpointsGateway.product.assign({ productId: this.productId, paymentMethodId: paymentMethod.id });
				const options = {
					method: 'PUT',
					url,
				};
				try {
					await axios(options);
					this.handleSuccess();
				} catch (err) {
					this.pushError(err.message);
				}
			});
		},
		async create() {
			const url = endpointsGateway.product.create;
			const options = {
				method: 'POST',
				url,
				data: {
					name: this.formData.name,
					websiteUrl: this.formData.websiteUrl,
					notificationUrl: this.formData.notificationUrl,
					ipWhiteList: [
						this.formData.ipWhiteList
					],
				}
			};
			try {
				const response = await axios(options);
				const { data } = response;
				this.productId = data.id;
				await this.getPaymentMethod();
				this.currentStep = 2;
			} catch (err) {
				this.pushError(err.message);
			}
		},
		async getPaymentMethod() {
			const url = endpointsGateway.paymentMethod.list;
			const options = {
				method: 'GET',
				url,
			};
			try {
				const response = await axios(options);
				const { data } = response;
				this.paymentMethodList = data;
			} catch (err) {
				this.pushError(err.message);
			}
		},
		handleSuccess() {
			this.currentStep = 3;
		},
		getDebounceAmount: debounce(function () {
			!!this.formData.amount && this.updateFormValue('amount', parseFloat(this.formData.amount).toFixed(2));
		}, 200),
		async startTransfer() {
			const result = await this.$validator.validate('*');
			if (result) {
				this.create();
			}
		},
		updateFormValue(name, value, fields) {
			this.$refs.transferForm.updateFormValue(name, value, fields);
		},
		setLockState(value) {
			if (this.$refs.transferForm) this.$refs.transferForm.lockState = value;
		},
		setFieldProperty(name, key, value) {
			this.formFields = this.formFields.map(f => {
				if (f.name == name) f[key] = value;
				return f;
			});
		}
	},
	mounted() {
		this.formFields = fields;
	},
};
</script>
<style lang="scss">
.nano-transfer {
	&__notification {
		background-color: #e3f3f4;
		padding: 16px 20px;
		display: flex;
		align-items: center;
		margin-top: 20px;
		border-radius: 3px;
		justify-content: space-between;

		> span {
			display: flex;
			align-items: center;
		}

		ion-icon {
			color: $primary;
			margin-right: 10px;
		}
	}
}
</style>