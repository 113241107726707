var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "odd-form"
  }, [_c('b-form', [_c('b-row', [_c('b-col', [_c('h2', [_vm._v("Update your personal questionnaire")])])], 1), _c('b-row', [_c('b-col', [_vm._l(_vm.formData.occupationList, function (occupation, index) {
    var _vm$getOccupationPara, _occupation$companyAc, _vm$getOccupationPara2, _occupation$companyPo, _vm$getOccupationPara3, _vm$getOccupationPara4;

    return _c('b-card', {
      key: index,
      staticClass: "mb-2"
    }, [_c('form-error-field', {
      attrs: {
        "name": "occupationList[".concat(index, "].value")
      }
    }, [_c('label', {
      attrs: {
        "for": ""
      }
    }, [_vm._v("What's your occupation?")]), _c('v-multiselect', {
      directives: [{
        name: "validate",
        rawName: "v-validate",
        value: 'required',
        expression: "'required'"
      }],
      attrs: {
        "options": _vm.options.occupationTypes,
        "multiple": false,
        "allow-empty": false,
        "show-labels": false,
        "close-on-select": true,
        "placeholder": "Occupation",
        "name": "occupationList[".concat(index, "].value"),
        "data-vv-as": "Occupation",
        "label": "name",
        "track-by": "name",
        "return": "value"
      },
      on: {
        "input": function input($event) {
          return _vm.onOccupationChange(index);
        }
      },
      model: {
        value: occupation.value,
        callback: function callback($$v) {
          _vm.$set(occupation, "value", $$v);
        },
        expression: "occupation.value"
      }
    })], 1), occupation.value == 'other' ? _c('form-error-field', {
      attrs: {
        "name": "occupationList[".concat(index, "].otherValue")
      }
    }, [_c('b-form-input', {
      directives: [{
        name: "validate",
        rawName: "v-validate",
        value: 'required',
        expression: "'required'"
      }],
      attrs: {
        "id": "occupation-other",
        "name": "occupationList[".concat(index, "].otherValue"),
        "data-vv-as": "Occupation other",
        "type": "text",
        "placeholder": "Enter other occupation",
        "state": !_vm.errors.has('Other occupation') ? null : false
      },
      model: {
        value: occupation.otherValue,
        callback: function callback($$v) {
          _vm.$set(occupation, "otherValue", $$v);
        },
        expression: "occupation.otherValue"
      }
    })], 1) : _vm._e(), (_vm$getOccupationPara = _vm.getOccupationParams(index)) !== null && _vm$getOccupationPara !== void 0 && _vm$getOccupationPara.companyActivity ? _c('form-error-field', {
      attrs: {
        "name": "occupationList[".concat(index, "].companyActivities.values")
      }
    }, [_c('label', {
      attrs: {
        "for": "Industry"
      }
    }, [_vm._v("Industry")]), _c('v-multiselect', {
      directives: [{
        name: "validate",
        rawName: "v-validate",
        value: 'required',
        expression: "'required'"
      }],
      attrs: {
        "multiple": true,
        "close-on-select": false,
        "options": _vm.options.companyActivityTypes,
        "placeholder": "Select industry",
        "name": "occupationList[".concat(index, "].companyActivities.values"),
        "data-vv-as": "Industry",
        "return": "value",
        "label": "name",
        "track-by": "name"
      },
      model: {
        value: occupation.companyActivities.values,
        callback: function callback($$v) {
          _vm.$set(occupation.companyActivities, "values", $$v);
        },
        expression: "occupation.companyActivities.values"
      }
    })], 1) : _vm._e(), (_occupation$companyAc = occupation.companyActivities) !== null && _occupation$companyAc !== void 0 && _occupation$companyAc.values.includes('other') ? _c('form-error-field', {
      attrs: {
        "name": "occupationList[".concat(index, "].companyActivities.otherValue")
      }
    }, [_c('b-form-input', {
      directives: [{
        name: "validate",
        rawName: "v-validate",
        value: 'required',
        expression: "'required'"
      }],
      attrs: {
        "id": "activities-other",
        "name": "occupationList[".concat(index, "].companyActivities.otherValue"),
        "data-vv-as": "Other activities",
        "type": "text",
        "placeholder": "Enter other activities",
        "state": !_vm.errors.has('Other activities') ? null : false
      },
      model: {
        value: occupation.companyActivities.otherValue,
        callback: function callback($$v) {
          _vm.$set(occupation.companyActivities, "otherValue", $$v);
        },
        expression: "occupation.companyActivities.otherValue"
      }
    })], 1) : _vm._e(), (_vm$getOccupationPara2 = _vm.getOccupationParams(index)) !== null && _vm$getOccupationPara2 !== void 0 && _vm$getOccupationPara2.companyPosition ? _c('form-error-field', {
      attrs: {
        "name": "occupationList[".concat(index, "].companyPosition.value")
      }
    }, [_c('label', {
      attrs: {
        "for": "Company Position"
      }
    }, [_vm._v("Your position in the Company")]), _c('v-multiselect', {
      directives: [{
        name: "validate",
        rawName: "v-validate",
        value: 'required',
        expression: "'required'"
      }],
      attrs: {
        "multiple": false,
        "close-on-select": true,
        "options": _vm.options.companyPositions,
        "placeholder": "Select position",
        "name": "occupationList[".concat(index, "].companyPosition.value"),
        "data-vv-as": "Company position",
        "return": "value",
        "label": "name",
        "track-by": "name"
      },
      model: {
        value: occupation.companyPosition.value,
        callback: function callback($$v) {
          _vm.$set(occupation.companyPosition, "value", $$v);
        },
        expression: "occupation.companyPosition.value"
      }
    })], 1) : _vm._e(), ((_occupation$companyPo = occupation.companyPosition) === null || _occupation$companyPo === void 0 ? void 0 : _occupation$companyPo.value) == 'other' ? _c('form-error-field', {
      attrs: {
        "name": "occupationList[".concat(index, "].companyPosition.otherValue")
      }
    }, [_c('b-form-input', {
      directives: [{
        name: "validate",
        rawName: "v-validate",
        value: 'required',
        expression: "'required'"
      }],
      attrs: {
        "id": "position-other",
        "name": "occupationList[".concat(index, "].companyPosition.otherValue"),
        "data-vv-as": "Other position",
        "type": "text",
        "placeholder": "Enter other position"
      },
      model: {
        value: occupation.companyPosition.otherValue,
        callback: function callback($$v) {
          _vm.$set(occupation.companyPosition, "otherValue", $$v);
        },
        expression: "occupation.companyPosition.otherValue"
      }
    })], 1) : _vm._e(), (_vm$getOccupationPara3 = _vm.getOccupationParams(index)) !== null && _vm$getOccupationPara3 !== void 0 && _vm$getOccupationPara3.institutionPosition ? _c('form-error-field', {
      attrs: {
        "name": "institutionPosition[".concat(index, "]")
      }
    }, [_c('label', {
      attrs: {
        "for": "Institution position"
      }
    }, [_vm._v("Your position in the Institution:")]), _c('b-form-input', {
      directives: [{
        name: "validate",
        rawName: "v-validate",
        value: 'required',
        expression: "'required'"
      }],
      attrs: {
        "id": "institution-position",
        "name": "institutionPosition[".concat(index, "]"),
        "data-vv-as": "Institution position",
        "type": "text",
        "placeholder": "Enter institution position"
      },
      model: {
        value: occupation.institutionPosition,
        callback: function callback($$v) {
          _vm.$set(occupation, "institutionPosition", $$v);
        },
        expression: "occupation.institutionPosition"
      }
    })], 1) : _vm._e(), (_vm$getOccupationPara4 = _vm.getOccupationParams(index)) !== null && _vm$getOccupationPara4 !== void 0 && _vm$getOccupationPara4.companyName ? _c('form-error-field', {
      attrs: {
        "name": "occupationList[".concat(index, "].institutionName")
      }
    }, [_c('label', {
      attrs: {
        "for": "Institution name"
      }
    }, [_vm._v("Name of the company/institution:")]), _c('b-form-input', {
      directives: [{
        name: "validate",
        rawName: "v-validate",
        value: 'required',
        expression: "'required'"
      }],
      attrs: {
        "id": "institution-name",
        "name": "occupationList[".concat(index, "].institutionName"),
        "data-vv-as": "Institution name",
        "type": "text",
        "placeholder": "Enter institution name"
      },
      model: {
        value: occupation.institutionName,
        callback: function callback($$v) {
          _vm.$set(occupation, "institutionName", $$v);
        },
        expression: "occupation.institutionName"
      }
    })], 1) : _vm._e(), index > 0 ? _c('b-button', {
      staticClass: "my-2",
      attrs: {
        "variant": "danger",
        "size": "sm"
      },
      on: {
        "click": function click($event) {
          return _vm.removeOccupation(index);
        }
      }
    }, [_vm._v(" Remove occupation ")]) : _vm._e()], 1);
  }), _c('b-button', {
    staticClass: "my-2",
    attrs: {
      "variant": "primary",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.addOccupation();
      }
    }
  }, [_vm._v(" Add occupation ")])], 2)], 1), _c('b-row', [_c('b-col', [_c('form-error-field', {
    attrs: {
      "name": "sourceOfIncome.values"
    }
  }, [_c('label', {
    attrs: {
      "for": "Source of income"
    }
  }, [_vm._v("Source of income")]), _c('v-multiselect', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "options": _vm.options.sourceOfIncome,
      "multiple": true,
      "close-on-select": false,
      "clear-on-select": false,
      "preserve-search": true,
      "preselect-first": false,
      "placeholder": "Make selection",
      "data-vv-as": "Source of income",
      "name": "sourceOfIncome.values",
      "label": "name",
      "track-by": "name",
      "return": "value"
    },
    model: {
      value: _vm.formData.sourceOfIncome.values,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.sourceOfIncome, "values", $$v);
      },
      expression: "formData.sourceOfIncome.values"
    }
  })], 1), _vm.formData.sourceOfIncome.values.includes('other') ? _c('form-error-field', {
    attrs: {
      "name": "sourceOfIncome.otherValue"
    }
  }, [_c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "id": "source-of-income-other",
      "data-vv-as": "Other source of income",
      "name": "sourceOfIncome.otherValue",
      "type": "text",
      "placeholder": "Enter other source of income",
      "state": !_vm.errors.has('Other source of income') ? null : false
    },
    model: {
      value: _vm.formData.sourceOfIncome.otherValue,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.sourceOfIncome, "otherValue", $$v);
      },
      expression: "formData.sourceOfIncome.otherValue"
    }
  })], 1) : _vm._e()], 1)], 1), _c('b-row', [_c('b-col', [_c('form-error-field', {
    attrs: {
      "name": "accountOpeningPurposes.values"
    }
  }, [_c('label', {
    attrs: {
      "for": "Account opening purposes"
    }
  }, [_vm._v("Account opening purposes")]), _c('v-multiselect', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "options": _vm.options.purposeForOpeningTypes,
      "multiple": true,
      "close-on-select": false,
      "clear-on-select": false,
      "preserve-search": true,
      "preselect-first": false,
      "placeholder": "Make selection",
      "data-vv-as": "Account opening purposes",
      "name": "accountOpeningPurposes.values",
      "label": "name",
      "track-by": "name",
      "return": "value"
    },
    model: {
      value: _vm.formData.accountOpeningPurposes.values,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.accountOpeningPurposes, "values", $$v);
      },
      expression: "formData.accountOpeningPurposes.values"
    }
  })], 1), _vm.formData.accountOpeningPurposes.values.includes('other') ? _c('form-error-field', {
    attrs: {
      "name": "accountOpeningPurposes.otherValue"
    }
  }, [_c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "id": "account-opening-purpose-other",
      "data-vv-as": "Other account opening purpose",
      "name": "accountOpeningPurposes.otherValue",
      "type": "text",
      "placeholder": "Enter other account opening purpose",
      "state": !_vm.errors.has('Other account opening purpose') ? null : false
    },
    model: {
      value: _vm.formData.accountOpeningPurposes.otherValue,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.accountOpeningPurposes, "otherValue", $$v);
      },
      expression: "formData.accountOpeningPurposes.otherValue"
    }
  })], 1) : _vm._e()], 1)], 1)], 1), _c('odd-form-footer', _vm._b({}, 'odd-form-footer', _vm.properties, false))], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }