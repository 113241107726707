<template>
	<div>
		<div
			v-show="errors.has('server')"
			class="alert alert-danger"
		>
			{{ errors.first('server') }}
		</div>
		<form
			method="post"
			@submit.prevent="onSubmit"
		>
			<b-container class="registration-content">
				<b-row class="justify-content-md-center form-group">
					<b-col md="12">
						<label>
							Password:
						</label>
						<password-input
							id="password"
							v-model="password"
							input-ref="password"
							name="password"
							:disabled="disabledPassword"
							data-password="password"
							@validated="validated"
						/>
					</b-col>
				</b-row>

				<b-row class="justify-content-md-center form-group">
					<b-col md="12">
						<label>
							Repeat password:
						</label>
						<password-input
							id="confirmPassword"
							v-model="confirmPassword"
							:req="false"
							:validation="false"
							:disabled="disabledPassword"
							name="confirmPassword"
							data-password="repeat"
							validation-rules="min:8|confirmed:password"
							@validated="confirmValidated"
						/>
					</b-col>
				</b-row>
				<b-row v-for="(option, i) in authenticator.list.filter(() => allowNext && !confirmed)"
					:key="`password-confirm-${i}`"
					class="mb-4"
				>
					<b-col>
						<button
							class="btn btn-primary btn-block"
							data-password="submit"
							@click="submitPassword(option.authenticator)"
						>
							Confirm 
							<span v-if="option.authenticator === 'sms'">by SMS</span>
							<span v-if="option.authenticator === 'whats_app'">by WhatsApp</span>
						</button>
					</b-col>
				</b-row>

				<b-row
					v-if="confirmed"
					class="justify-content-md-center"
				>
					<b-col
						md="12"
						class="mb-4 mt-4"
					>
						<authenticator
							ref="authenticator"
							:hash="data.hash"
							:show-back-button="false"
							:on-success="handleSuccess"
							:on-error="handleError"
							:on-resend="handleResend"
							type="registration"
							button-text="Next"
							action="register/confirmRegistration"
							data-password="authenticator"
						/>
					</b-col>
				</b-row>
				<b-row class="justify-content-md-center">
					<b-col md="12">
						<notification />
					</b-col>
				</b-row>
				<b-row class="justify-content-md-center">
					<b-col
						md="12"
						class="text-center mb-4 mt-4 border border-left-0 border-right-0 border-bottom-0"
					>
						<div class="textblock margintop30 infobox">
							<div class="textblock darktext mediumtext">
								<p class="mt-4">
									Please remember and keep this info safe.<br />
									We will never ask your password.
								</p>
							</div>
						</div>
					</b-col>
				</b-row>
			</b-container>
		</form>
	</div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import 'vue-awesome/icons/eye-slash';
import Authenticator from '@/shared/Authenticator';
import { endpoints } from '~/config';
import FormErrorField from '@/shared/Form/FormErrorField';

export default {
	name: 'LoginInfoForm',
	components: {
		Authenticator,
		FormErrorField
	},
	data() {
		return {
			data: {},
			accounts: [],
			password: '',
			confirmPassword: '',
			recaptchaResponse: null,
			allowNext: false,
			confirmed: false,
			isBusinessRegistration: false,
			disabledPassword: false,
			valid: {
				password: false,
				confirm: false
			},
			role: this.$route.params.role
		};
	},
	computed: {
		...mapGetters('user', [
			'isPersonal',
			'isBusiness',
			'isVerified'
		]),
		//@todo perziureti kam cia naudojama
		...mapState('user', ['selected', 'authenticator']),
		...mapState('register', ['registerId', 'email']),
		...mapState({
			errors: state => state.login.errors
		}),
		passwords() {
			return {
				password: this.password,
				confirm: this.confirmPassword
			};
		}
	},

	watch: {
		passwords(value) {
			if (value.password !== value.confirm && this.valid.password && this.valid.confirm) {
				this.allowNext = false;
			} else {
				if (this.valid.password && this.valid.confirm) {
					this.allowNext = true;
				}
			}
		}
	},

	methods: {
		...mapActions('notifications', ['pushError']),
		...mapActions('register', ['updateLoginPassword', 'resendConfirm']),
		...mapActions('user', ['getUserList', 'getUserProfile', 'setUserAuthenticator']),
		validated(value) {
			return this.valid.password = value;
		},
		confirmValidated(value) {
			return this.valid.confirm = value;
		},
		async submitPassword(provider) {
			await this.setUserAuthenticator(provider);
			const options = {
				method: 'POST',
				url: endpoints.register.init2fa({ hash: this.$route.params?.hash }),
				data: {
					clientId: 'frontend',
					provider: provider || this.authenticator.type
				}
			};
			try {
				await axios(options);
				this.confirmed = true;
				this.disabledPassword = true;
				this.updateLoginPassword(this.password);
			} catch (err) {
				this.pushError(err);
			}
		},
		goToVerification() {
			this.$router.replace({ name: this.role === 'business_unverified' ? 'verify-account' : 'verify-person' });
		},
		async handleSuccess() {
			try {
				await this.getUserProfile();
				this.$gtag.event(`register_password_confirmation_${this.isBusiness ? 'business' : 'personal'}`);
				this.goToVerification();
			} catch (err) {
				this.handleError(err);
			}

		},
		handleError(error) {
			this.nextEnabled = true;

			if (error.errors) {
				if (error.errors.password) {
					this.errors.add('password', error.errors.password[0], 'server');
				}

				if (error.errors.passwordConfirmation) {
					this.errors.add('password', error.errors.passwordConfirmation[0], 'server');
				}

				if (error.errors.code) {
					this.errors.add('code', error.errors.code[0], 'server');
				}

				if (error.message) {
					this.errors.add('server', error.message, 'server');
				}
			} else if (error.response && error.response.data && error.response.data.error) {
				if (error.response.data.error.message && error.response.data.error.error_details) {
					this.$store.dispatch('showErrorNotification',
						error.response.data.error.message + '. ' + error.response.data.error.error_details);
				} else {
					this.$store.dispatch('showErrorNotification', 'Something went wrong. Try again later');
				}
			}
		},
		onSubmit: function () {
			this.$validator.validateAll().then((result) => {
				if (result) {
					this.nextEnabled = false;
				}
			});
		},
		async handleResend(provider) {
			try {
				await this.resendConfirm({
					hash: this.$route.params.hash,
					provider
				});
			} catch (err) {
				this.pushError(err);
			}
			return;
		},
	},
	mounted() {
		if (!this.registerId && (this.$route.params && !this.$route.params.hash)) {
			this.$router.push({ name: 'register' });
			this.pushError({ title: 'Please use a link in your email to continue registration.' });
		} else {
			this.$gtag.event(`register_email_confirmation_${this.isBusiness ? 'business' : 'personal'}`);
		}
	},
	provide() {
		return {
			$refs: this.$refs
		};
	}
};

</script>

<style lang="scss">
.error-repeat {
	padding: 5px 15px;
	border-radius: 3px;
	background-color: #ff6161;
	font-size: 12px;
	color: #fff;
	margin-bottom: 10px;
}
</style>