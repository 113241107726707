import { endpoints } from '~/config';
import OddCompanyInformation from './ODD/Company/OddCompanyInformation';
import OddCompanyTurnover from './ODD/Company/OddCompanyTurnover';
import OddCompanyQuestionnaire from './ODD/Company/OddCompanyQuestionnaire';
import OddCompanyRelation from './ODD/Company/OddCompanyRelation';
import OddCompanyDocuments from './ODD/Company/OddCompanyDocuments';
import OddCompanyIdentity from './ODD/Company/OddCompanyIdentity';

import OddPersonInformation from './ODD/Person/OddPersonInformation';
import OddPersonQuestionnaire from './ODD/Person/OddPersonQuestionnaire';
import OddPersonTurnover from './ODD/Person/OddPersonTurnover';
import OddPersonIdentity from './ODD/Person/OddPersonIdentity';
import OddPersonDocuments from './ODD/Person/OddPersonDocuments';

export default {
	'odd_company_information': {
		component: OddCompanyInformation,
		url: endpoints.odd.companyInformation
	},
	'odd_company_questionnaire': {
		component: OddCompanyQuestionnaire,
		url: endpoints.odd.companyQuestionnaire
	},
	'odd_company_turnover': {
		component: OddCompanyTurnover,
		url: endpoints.odd.companyTurnover
	},
	'odd_company_relation': {
		component: OddCompanyRelation,
		url: endpoints.odd.companyRelation
	},
	'odd_company_document': {
		component: OddCompanyDocuments,
		url: endpoints.odd.companyDocument
	},
	'odd_company_identity': {
		component: OddCompanyIdentity,
	},

	'odd_person_information': {
		component: OddPersonInformation,
		url: endpoints.odd.personInformation
	},
	'odd_person_questionnaire': {
		component: OddPersonQuestionnaire,
		url: endpoints.odd.personQuestionnaire
	},
	'odd_person_turnover': {
		component: OddPersonTurnover,
		url: endpoints.odd.personTurnover
	},
	'odd_person_identity': {
		component: OddPersonIdentity,
	},
	'odd_person_document': {
		component: OddPersonDocuments,
		url: endpoints.odd.personDocument
	},
};
