export default {
	status: {
		loading: false,
		success: false,
		failed: false
	},
	errors: {
		email: null,
	},
	registerId: null,
	authenticator: null,
	authenticatorOptions: null,
	email: null,
	role: '',
	showSuccess: 0,
	showForm: 1,
	recaptcha: null,
	phone: {
		phone_prefix_country_id: null,
		phone: null
	},
	code: null,
	password: null,
	passwordConfirmation: null,
	personalSteps: {
		'verify-person': 'Personal info',
		'person-questionnaire': 'Primary account',
		'verify-photo': 'Identity verification'
	},

	businessSteps: {
		'verify-account': 'Account info',
		'verify-business': 'Company info',
		'verify-person': 'Key persons',
		'business-questionnaire': 'Use of account',
		'verify-business-documents': 'Documents',
		'verify-photo': 'Identity verification'
	}
};
