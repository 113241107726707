var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "transfer-layout"
    }
  }, [_vm._m(0), _c('div', [_c('product', {
    attrs: {
      "transfer-data": _vm.transferData
    }
  })], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-title-container"
  }, [_c('h2', [_vm._v("Product")])]);
}]

export { render, staticRenderFns }