<template>
	<div class="card-jwk-form">
		<img
			src="/images/icons/card-cvv.svg"
			alt="Card CVV"
		/>
		<h2 class="mt-4">
			Enter card CVV code to activate it
		</h2>
		<p class="mt-4 mb-4">
			You should find the code on the back of your card.
		</p>
		
		
		<label class="card-input-label">
			<input 
				ref="cvv"
				v-model="data.cvv"
				v-validate="`min:${inputLength}|required`"
				type="text"
				name="cvv"
				pattern="\d*"
				:maxlength="inputLength"
				data-vv-validate-on="blur"
				placeholder="Enter CVV code"
				:class="errors.has('cvv') ? 'card-input-field-error' : ''"
			>
			<div
				v-if="errors.has('cvv')"
				class="card-input-error"
			>
				{{ errors.first('cvv') }}
			</div>
		</label>
	</div>
</template>
<script>

export default {
	name: 'CardActivateForm',
	data() {
		return {
			data: {
				cvv: null
			},
			inputLength: 3
		};
	},
	watch: {
		data: {
			handler: function (value) {
				if (this.data.cvv && this.data.cvv.length == this.inputLength) {
					this.$emit('input', value);
				} else {
					this.$emit('input', null);
				}
			},
			deep: true
		},
		'data.cvv': {
			handler: function() {
				if (this.data.cvv) this.data.cvv = this.data.cvv.replace(/[^0-9]/g, '');
			}
		}
	},
	methods: {
		focus() {
			this.$nextTick(() => {
				this.$refs.cvv.focus();
			});
		},
		reset() {
			this.data.cvv = null;
		}
	}
};
</script>
<style lang="scss">
	
</style>