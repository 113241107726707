<template lang="">
	<div
		v-if="visible"
		class="cookies-notification"
	>
		<div class="cookies-notification__wrapper">
			<div class="cookies-notification__text">
				We use cookies to help give you the best experience on our website. By continuing without changing your cookie settings, we assume you agree to this. Please read our cookie policy to find out more. <a :href="cookiePolicyUrl">Cookie policy</a>
			</div>
			<button
				class="cookies-notification__button"
				data-cookies="agree"
				@click="setCookie()"
			>
				I agree
			</button>
		</div>
	</div>
</template>
<script>
import config from '~/config';

export default {
	name: 'CookiesNotification',
	data: () => ({
		cookieName: 'verifo',
		cookieValue: '1',
		visible: false,
		cookiePolicyUrl: config.cookiePolicyUrl
	}),
	mounted() {
		if (!document.cookie || (document.cookie && document.cookie.indexOf(this.cookieName + '=' + this.cookieValue) == -1)) this.visible = true;
	},
	methods: {
		setCookie () {
			this.visible = false;
			const days = 365;
			let d = new Date();
			d.setTime(d.getTime() + (days*24*60*60*1000));
			var expires = 'expires='+ d.toUTCString();
			document.cookie = this.cookieName + '=' + this.cookieValue + ';' + expires + ';path=/';
		},
	}
};
</script>
<style lang="scss" scoped>
	.cookies-notification {
		position: fixed;
		bottom: 20px;
		width: 100%;
		z-index: 9999;

		&__wrapper {
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 20px 40px;
			background: #F5F7FA;
			border: 1px solid #F5F7FA;
			box-shadow: 0 0 30px 0 rgba(0, 0, 0, .1);
			border-radius: 3px;
			width: 80%;
			margin: 0 auto;

			@media screen and (max-width: 576px) {
				flex-direction: column;
			}
		}

		&__button {
			cursor: pointer;
			color: #fff;
			background-color: var(--primary);
			border-color: var(--primary);
			padding: 5px 48px;
			border-radius: 800px;
			border: 0;
			margin: 0 20px;
			white-space: nowrap;
			line-height: 22px;

			@media screen and (max-width: 576px) {
				margin-top: 20px;
			}


			&:focus {
				outline: none;
				color: #fff;
			}
		}
	}
</style>