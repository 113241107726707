export default function errorHandler(error) {
	if (!error?.response) {
		return {
			message: error?.message ?? 'Network error.',
			fields: {}
		};
	}
	let { data } = error.response;
	if (data instanceof ArrayBuffer) {
		const converted = String.fromCharCode.apply(null, new Uint8Array(data));
		data = JSON.parse(converted);
	}
	let message, fields = {};
	if (data && data.error) {
		message = data.error.message;
	} else if (data.message) {
		message = data.message;
	}

	if (data.properties) {
		data.properties.forEach(p => {
			fields[p.property] = p.message;
		});
	} else if (data.error?.fields) {
		Object.entries(data.error.fields).forEach(([n, v]) => {
			fields[n] = Object.entries(v)[0][1];
		});
	}

	return {
		message,
		fields
	};
}