<template>
	<div class="card-settings">
		<div
			v-if="!card"
			class="text-center py-4"
		>
			<b-spinner variant="primary" />
		</div>
		<b-container v-if="card">
			<b-row>
				<b-col>
					<div class="page-title-container">
						<div
							v-if="'active' === currentWallet.status"
							class="d-flex justify-content-between"
						>
							<h2>Visa {{ card.isVirtual ? 'virtual' : 'debit' }} card {{ shortenedCardNumber }}</h2>
							<new-card-button />
						</div>
						<b-row
							class="my-4"
							align-content="center"
						>
							<b-col cols="auto">
								<div :class="['closed', 'suspended_client', 'suspended_system', 'suspended_admin'].includes(card.status) ? 'card-settings__card-closed' : ''">
									<card-image
										:card="card"
										:virtual="card.isVirtual"
									/>
								</div>
								<div
									v-if="['closed', 'suspended_admin', 'suspended_client', 'suspended_system'].includes(card.status)"
									class="card-settings__closed-card-overlay"
								>
									<strong v-if="card.status == 'closed'">Card is Blocked</strong>
									<strong v-if="card.status == 'suspended_client' || card.status == 'suspended_system' || card.status == 'suspended_admin'">Card is Temporary Blocked</strong>
								</div>
							</b-col>
						</b-row>
						<div
							v-if="loading"
							class="text-center py-4"
						>
							<div
								v-if="loadingMessage"
								class="mb-4"
							>
								{{ loadingMessage }}
							</div>
							<b-spinner variant="primary" />
						</div>
						<div v-if="!loading">
							<b-row>
								<b-col>
									<div
										v-if="(card.status == 'sent' || card.status == 'waiting_cvv') && permissions.activate" 
										class="card-settings__notification"
									>
										Card is ordered. Activate it using CVV when it arrives.
									</div>
								</b-col>
							</b-row>
							<b-row
								v-for="(entry, i) in Object.entries(menu)"
								:key="i"
								class="mb-4"
							>
								<b-col>
									<span class="card-settings__menu-title mb-2">{{ entry[0] }}</span>
									<ul class="card-menu">
										<li
											v-for="(item, ix) in entry[1].filter(e => !e.hidden)"
											:key="ix"
										>
											<a
												href=""
												@click.prevent="item.action"
											>
												<span class="icon"><ion-icon :name="item.icon" /></span>
												<span class="menu-text">{{ item.title }}</span>
											</a>
											<div
												v-if="item.children"
												class="card-settings-submenu"
											>
												<ul>
													<li
														v-for="(child, iy) in item.children.filter(e => !e.hidden)"
														:key="iy"
														:class="child.topDivider ? 'top-divider' : ''"
													>
														<a
															href="#"
															@click.prevent="child.action"
														>
															<ion-icon :name="child.icon" />
															<span class="menu-text">{{ child.title }}</span>
														</a>
													</li>
												</ul>
											</div>
										</li>
									</ul>
								</b-col>
							</b-row>
						</div>
					</div>
				</b-col>
			</b-row>
			<b-row v-if="list.length > 1">
				<b-col>
					<b-button
						variant="outline-primary"
						@click="() => $router.push( {name: 'cards'} )"
					>
						Back
					</b-button>
				</b-col>
			</b-row>
		</b-container>
		<!-- Modals -->
		<div v-if="card">
			<card-auth-modal 
				:id="detailsAuthModalId" 
				authenticator="sms"
				:refresh-fn="requestDetailsCode"
				:on-submit="getDetails"
			/>
			<card-auth-modal 
				:id="pinAuthModalId" 
				authenticator="sms"
				:refresh-fn="requestPinCode"
				:on-submit="getPin"
			/>
			<card-prompt-modal 
				ref="card-prompt-modal" 
				:on-close="getCard"
			/>
			<!--<card-jwk-modal -->
			<!--    :id="jwkModalId" -->
			<!--    ref="card-jwk-modal"-->
			<!--    :card-id="cardId" -->
			<!--/>-->
			<card-jwt-modal
				:id="jwtModalId"
				ref="card-jwt-modal"
				:card-id="cardId"
			/>
		</div>
	</div>
</template>
<script>
import NewCardButton from './Partials/NewCardButton';
import CardImage from './Partials/CardImage';
import CardAuthModal from './Settings/CardAuthModal';
import CardPromptModal from './Settings/CardPromptModal';
import CardJwkModal from './Settings/CardJwkModal';
import CardJwtModal from './Settings/CardJwtModal';

import { endpoints } from '~/config';
import moment from 'moment';
import { mapActions, mapGetters, mapState } from 'vuex';
export default {
	name: 'CardSettings',
	components: {
		NewCardButton,
		CardImage,
		CardAuthModal,
		CardPromptModal,
		CardJwkModal,
		CardJwtModal,
	},
	props: {
		cardId: {
			type: String,
			default: '',
			required: true,
		},
	},
	data() {
		return {
			loading: false,
			loadingMessage: '',
			card: null,
			detailsAuthModalId: 'auth-details-modal',

			pinAuthModalId: 'auth-pin-modal',
			giroUrlData: null,

			// jwkModalId: 'card-jwk-modal',
			jwtModalId: 'card-jwt-modal',

			promptOptions: {
				onConfirm: () => {}
			}
		};
	},
	computed: {
		menu() {
			if (!this.card) return {};
			const suspendedStatuses = ['suspended_client', 'suspended_admin', 'suspended_system'];

			const status = this.card.status;
			const isActive = status == 'active';
			const isSent = status == 'sent';
			const isSuspended = suspendedStatuses.includes(status);
			const isSuspendedByClient = status == 'suspended_client';
			const isClosed = status == 'closed';
			const isCreated = status == 'created';
			const isVirtual = this.card.isVirtual;
			const has3d = this.card.isCreated3DPassword;
			const waitingPin = status == 'waiting_pin';
			const waitingCvv = status == 'waiting_cvv';

			const list = {
				Actions: [
					{
						title: 'Card details',
						icon: 'card-outline',
						action: this.initDetails,
						hidden: !isActive || !this.permissions.cardDetails
					},
					{
						title: 'Activate card',
						icon: 'card-outline',
						action: this.openActivateModal,
						hidden: !(isSent || waitingCvv) || !this.permissions.activate
					},
					{
						title: 'Set PIN code',
						icon: 'apps-outline',
						action: this.openSetPinModal,
						hidden: !(waitingPin || isCreated) || !this.permissions.setPin
					},
					{
						title: 'Order New Card',
						icon: 'card-outline',
						action: () => this.$router.push({ name: 'new-card' }),
						hidden: 'active' !== this.currentWallet.status || !(isSuspended || isClosed) || !this.permissions.createCardOrder
					},
				],
				Safety: [
					{
						title: 'Temporary Block',
						icon: 'pause-circle-outline',
						action: this.tempBlock,
						hidden: !isActive
					},
					{
						title: 'Unblock',
						icon: 'play-circle-outline',
						action: this.unblock,
						hidden: !isSuspendedByClient || 'active' !== this.currentWallet.status
					},
					{
						title: 'Report Lost or Stolen',
						icon: 'shield-outline',
						action: () => {},
						hidden: !isActive,
						children: [
							{
								title: 'Card is Lost',
								icon: 'flame-outline',
								action: () => this.permanentBlock('lost'),
							},
							{
								title: 'Card is Stolen',
								icon: 'wallet-outline',
								action: () => this.permanentBlock('stolen'),
							},
						]
					},
					{
						title: 'Security Settings',
						icon: 'settings-outline',
						action: () => {},
						hidden: !isActive,
						children: [
							{
								title: 'Card PIN',
								icon: 'eye-outline',
								action: this.initPinView,
								hidden: isVirtual || !this.permissions.cardPinShow
							},
							{
								title: this.card && this.card.cardSecurityParametersResponse.allowContactless ? 'Disable Contactless Payments' : 'Allow Contactless Payments',
								icon: 'wifi-outline',
								action: () => this.setSecurityParam('allowContactless'),
								hidden: isVirtual /* || !has3d */
							},
							{
								title: this.card && this.card.cardSecurityParametersResponse.allowCashout ? 'Disable Cashout' : 'Allow Cashout',
								icon: 'cash-outline',
								action: () => this.setSecurityParam('allowCashout'),
								hidden: isVirtual /* || !has3d */
							},
							{
								title: this.card && this.card.cardSecurityParametersResponse.allowEcommerce ? 'Disable Ecommerce' : 'Allow Ecommerce',
								icon: 'pricetags-outline',
								action: () => this.setSecurityParam('allowEcommerce'),
								hidden: !has3d
							},
							{
								title: has3d ? 'Change Online Password' : 'Set Online Password',
								icon: 'key-outline',
								topDivider: true,
								action: this.openSet3dPassword
							},
						]
					},
				] 
			};
			//Filter hidden menu titles
			return Object.entries(list).filter(([, items]) => {
				return !!items.filter(item => !item.hidden).length;
			}).reduce((a, [name, items]) => ({ ...a, [name]: items }), {});
		},
		...mapGetters('user', [
			'isGranted'
		]),
		...mapState('cards', {
			list: state => state.list
		}),
		cardNumber() {
			if (!this.card) return '';
			const num = this.card.cardNumber;
			const arr = num.match(/.{1,4}/g);
			return arr.join(' ');
		},
		cardValidTo() {
			if (!this.card) return '';
			return moment(this.card.validTo).format('YY/MM');
		},
		shortenedCardNumber() {
			if (!this.card) return '';
			return `••${this.card.cardNumber.slice(-4)}`;
		},
		permissions() {
			return {
				createCardOrder: this.isGranted('front_create_card_order'),
				cardDetails: this.isGranted('front_card_details'),
				cardPinShow: this.isGranted('front_card_pin_show'),
				activate: this.isGranted('front_card_activate'),
				setPin: this.isGranted('front_card_set_pin')
			};
		},
		...mapGetters('wallet', [
			'currentWallet'
		])
	},
	methods: {
		...mapActions('notifications', [
			'pushError'
		]),
		// openJwkModal(key, token, type, onSubmit){
		// 	if (!key || !type) return false;
		// 	const modal = this.$refs['card-jwk-modal'];
		// 	modal.data = { key, token };
		// 	modal.type = type;
		// 	if (onSubmit) modal.onSubmit = onSubmit;
		// 	this.$bvModal.show(this.jwkModalId);
		// 	return true;
		// },
		async openJwtGiroModal(data, type, onSubmit){
			if (!data) return false;

			const modal = this.$refs['card-jwt-modal'];
			modal.data = data;
			modal.type = type;
			if (onSubmit) modal.onSubmit = onSubmit;
			this.$bvModal.show(this.jwtModalId);
			return true;
		},
		async getCard() {
			const options = {
				method: 'GET',
				url: endpoints.cards.cardInfo({ cardId: this.cardId })
			};
			try {
				const { data } = await axios(options);
				this.card = data;
				return data;
			} catch (err) {
				this.pushError(err.message || 'Could not get card information. Please try again later.');
				this.$router.push( { name: 'cards' } );
				return false;
			}
		},
		async getGiro() {
			const options = {
				method: 'GET',
				url: this.giroUrlData.url,
				headers: {
					'Authorization': `Bearer ${this.giroUrlData.authorizationHeader}`
				}
			};
			try {
				const { data } = await axios(options);
				this.giroUrlData = null;
				return data;
			} catch (err) {
				this.pushError('Could not update card information. Please try again later.');
				this.$router.push( { name: 'cards' } );
				return false;
			}
		},
		async postBody(data) {
			const options = {
				method: 'POST',
				url: this.giroUrlData.url,
				data: data,
				headers: {
					'Authorization': `Bearer ${this.giroUrlData.authorizationHeader}`,
					'Content-Type': 'raw'
				}
			};
			try {
				const { data } = await axios(options);
				this.giroUrlData = null;
				return data;
			} catch (err) {
				this.pushError('Could not update card information. Please try again later.');
				this.$router.push( { name: 'cards' } );
				return false;
			}
		},
		initStatusUpdate(type) {
			const options = {
				method: 'POST',
				url: endpoints.cards.jwtInitStatus({ cardId: this.cardId }),
				data: { type: type }
			};
			axios(options);
		},
		async waitStatusChange({ message, status, errorMsg, param }) {
			this.loading = true;
			this.loadingMessage = message;
			let i = 0;
			const interval = setInterval(async () => {
				await this.getCard();
				i++;
				if ( (status && this.card.status == status) || (!status && param && this.card[param])) {
					clearInterval(interval);
					this.loading = false;
				} else if (i == 10) {
					if (errorMsg) this.pushError(errorMsg);
					this.$router.push( { name: 'cards' } );
					clearInterval(interval);
				}
			}, 3000);
			
		},
		// async generateJWKKey() {
		// 	const options = {
		// 		method: 'GET',
		// 		url: endpoints.cards.jwkKey({ cardId: this.cardId })
		// 	};
		// 	try {
		// 		const { data } = await axios(options);
		// 		return data.key;
		// 	} catch (err) {
		// 		return false;
		// 	}
		// },
		async requestDetailsCode() {
			const options = {
				method: 'POST',
				url: endpoints.cards.detailsAuth({ cardId: this.cardId })
			};
			try {
				await axios(options);
				return true;
			} catch (err) {
				this.pushError(err.message || 'Something went wrong. Try again later.');
				return false;
			}
		},
		async initDetails() {
			const response = await this.requestDetailsCode();
			if (response) {
				this.$bvModal.show(this.detailsAuthModalId);
			}
		},
		async getDetails(code) {
			if (!code) return;
			const options = {
				method: 'POST',
				url: endpoints.cards.jwtDetailsUrl({ cardId: this.cardId }),
				data: { code }
			};
			try {
				const { data } = await axios(options);
				this.giroUrlData = data;
				const giroData = await this.getGiro();
				if (!giroData) {
					return false;
				}

				await this.openJwtGiroModal(giroData, 'details');
				return true;
			} catch (err) {
				return false;
			}
		},
		// async getDetails(code) {
		// 	if (!code) return;
		// 	const options = {
		// 		method: 'POST',
		// 		url: endpoints.cards.jwkShowDetails({ cardId: this.cardId }),
		// 		data: { code }
		// 	};
		// 	try {
		// 		const key = await this.generateJWKKey();
		// 		const { data } = await axios(options);
		// 		const token = data.token;
		// 		this.openJwkModal(key, token, 'details');
		// 		return true;
		// 	} catch (err) {
		// 		return false;
		// 	}
		// },
		async requestPinCode() {
			const options = {
				method: 'POST',
				url: endpoints.cards.pinAuth({ cardId: this.cardId })
			};
			try {
				await axios(options);
				return true;
			} catch (err) {
				this.pushError(err.message || 'Something went wrong. Try again later.');
				return false;
			}
		},
		// async initPinView() {
		// 	const response = await this.requestPinCode();
		// 	if (response) {
		// 		this.$bvModal.show(this.pinAuthModalId);
		// 	}
		// },
		// async getPin(code) {
		// 	if (!code) return;
		// 	const options = {
		// 		method: 'POST',
		// 		url: endpoints.cards.jwkShowPin({ cardId: this.cardId }),
		// 		data: { code }
		// 	};
		// 	try {
		// 		const key = await this.generateJWKKey();
		// 		const { data } = await axios(options);
		// 		const token = data.token;
		// 		this.openJwtGiroModal(key, token, 'pin');
		// 		return true;
		// 	} catch (err) {
		// 		return false;
		// 	}
		// },
		async initPinView() {
			const response = await this.requestPinCode();
			if (response) {
				this.$bvModal.show(this.pinAuthModalId);
			}
		},
		async getPin(code) {
			if (!code) return;
			const options = {
				method: 'POST',
				url: endpoints.cards.jwtPinUrl({ cardId: this.cardId }),
				data: { code }
			};
			try {
				const { data } = await axios(options);
				this.giroUrlData = data;
				const giroData = await this.getGiro();
				if (!giroData) {
					return false;
				}
				await this.openJwtGiroModal(giroData, 'pin');
				return true;
			} catch (err) {
				return false;
			}
		},
		setPromptParams({ onConfirm, onDecline, text, warningText, title }) {
			const params = this.$refs['card-prompt-modal'].params;
			if (onConfirm) params.onConfirm = onConfirm;
			if (onDecline) params.onDecline = onDecline;
			if (text) params.text = text;
			if (title) params.title = title;
			if (warningText) params.warningText = warningText;
		},
		async tempBlock() {
			const confirmTempBlock = async () => {
				const options = {
					method: 'PUT',
					url: endpoints.cards.block({ cardId: this.cardId })
				};
				try {
					await axios(options);
					this.getCard();
				} catch (err) {
					this.pushError(err.message || 'Something went wrong. Please try again later.');
				}
			};

			this.setPromptParams({
				title: 'Temporary block',
				onConfirm: confirmTempBlock,
				text: 'Do you want to temporary block your card?'
			});
			this.$bvModal.show('card-prompt-modal');
		},
		async unblock() {
			const confirmUnblock = async () => {
				const options = {
					method: 'PUT',
					url: endpoints.cards.unblock({ cardId: this.cardId })
				};
				try {
					await axios(options);
					this.getCard();
				} catch (err) {
					this.pushError(err.message || 'Something went wrong. Please try again later.');
				}
			};

			this.setPromptParams({
				title: 'Unblock card',
				onConfirm: confirmUnblock,
				text: 'Do you want to unblock your card?'
			});
			this.$bvModal.show('card-prompt-modal');
		},
		async permanentBlock(reason) {
			if (!['lost', 'stolen'].includes(reason)) return;
			const confirmPermanentBlock = async () => {
				const options = {
					method: 'PUT',
					url: endpoints.cards.close({ cardId: this.cardId }),
					data: {
						closeReason: reason
					}
				};
				try {
					await axios(options);
					this.getCard();
				} catch (err) {
					this.pushError(err.message || 'Something went wrong. Please try again later.');
				}
			};

			this.setPromptParams({
				title: `Report ${reason} card`,
				onConfirm: confirmPermanentBlock,
				text: `Do you want to permanently block your ${reason} card?`,
				warningText: 'You won\'t be able to undo this action!'
			});
			this.$bvModal.show('card-prompt-modal');
		},
		// async openSetPinModal() {
		// 	try {
		// 		const key = await this.getGiro();
		// 		this.openJwtGiroModal(key, null, 'setPin', this.setPin);
		// 	} catch (err) {
		// 		//
		// 	}
		// },
		// async setPin(token) {
		// 	const options = {
		// 		method: 'POST',
		// 		url: endpoints.cards.jwkSetPin({ cardId: this.cardId }),
		// 		data: { token }
		// 	};
		// 	try {
		// 		await axios(options);
		// 		this.waitStatusChange({
		// 			message: 'Please wait...',
		// 			status: 'sent',
		// 			errorMsg: 'Something went wrong. Please try again later.'
		// 		});
		// 		return Promise.resolve();
		// 	} catch (err) {
		// 		return Promise.reject();
		// 	}
		// },
		async openSetPinModal() {
			const options = {
				method: 'POST',
				url: endpoints.cards.jwtPinSetUrl({ cardId: this.cardId }),
			};
			try {
				const { data } = await axios(options);
				this.giroUrlData = data;
				await this.openJwtGiroModal(data, 'setPin', this.setPin);
				return true;
			} catch (err) {
				return false;
			}
		},
		async setPin(formData) {
			try {
				const response = await this.postBody(formData.pin);
				if (response) {
					await this.initStatusUpdate('pin');
					this.waitStatusChange({
						message: 'Please wait...',
						status: 'sent',
						errorMsg: 'Something went wrong. Please try again later.'
					});
				}
				return Promise.resolve();
			} catch (err) {
				return Promise.reject();
			}
		},
		// async openSet3dPassword() {
		// 	try {
		// 		const key = await this.generateJWKKey();
		// 		this.openJwkModal(key, null, 'set3d', this.set3d);
		// 	} catch (err) {
		// 		//
		// 	}

		// },
		// async set3d(token) {
		// 	const options = {
		// 		method: 'POST',
		// 		url: endpoints.cards.jwkSet3d( { cardId: this.cardId }),
		// 		data: { token }
		// 	};
		// 	try {
		// 		await axios(options);
		// 		this.waitStatusChange({
		// 			message: 'Please wait...',
		// 			param: 'isEnable3DSecure',
		// 			errorMsg: 'Something went wrong. Please try again later.'
		// 		});
		// 		return Promise.resolve();
		// 	} catch (err) {
		// 		return Promise.reject();
		// 	}
		// },
		async openSet3dPassword() {
			const options = {
				method: 'POST',
				url: endpoints.cards.jwt3dsUrl({ cardId: this.cardId }),
			};
			try {
				const { data } = await axios(options);
				this.giroUrlData = data;
				await this.openJwtGiroModal(data, 'set3d', this.set3d);
				return true;
			} catch (err) {
				return false;
			}
		},
		async set3d(formData) {
			try {
				const response = await this.postBody(formData.password);
				if (response) {
					await this.initStatusUpdate('3ds');
					this.waitStatusChange({
						message: 'Please wait...',
						param: 'isEnable3DSecure',
						errorMsg: 'Something went wrong. Please try again later.'
					});
				}
				return Promise.resolve();
			} catch (err) {
				return Promise.reject();
			}
		},
		// async openActivateModal() {
		// 	try {
		// 		const key = await this.generateJWKKey();
		// 		this.openJwkModal(key, null, 'activate', this.activate);
		// 	} catch (err) {
		// 		//
		// 	}
		// },
		// async activate(token) {
		// 	const options = {
		// 		method: 'POST',
		// 		url: endpoints.cards.jwkActivate( { cardId: this.cardId }),
		// 		data: { token }
		// 	};
		// 	try {
		// 		await axios(options);
		// 		this.waitStatusChange({
		// 			message: 'Please wait until card is activated.',
		// 			status: 'active',
		// 			errorMsg: 'Card did not activate. Please try again later.'
		// 		});
		// 		return Promise.resolve();
		// 	} catch (err) {
		// 		return Promise.reject(err);
		// 	}
		// },
		async openActivateModal() {
			const options = {
				method: 'POST',
				url: endpoints.cards.jwtActivateUrl({ cardId: this.cardId }),
			};
			try {
				const { data } = await axios(options);
				this.giroUrlData = data;
				await this.openJwtGiroModal(data, 'activate', this.activate);
				return true;
			} catch (err) {
				return false;
			}
		},
		async activate(formData) {
			try {
				const response = await this.postBody({
					cvv: formData.cvv
				});
				if (response) {
					await this.initStatusUpdate('cvv');
					this.waitStatusChange({
						message: 'Please wait until card is activated.',
						status: 'active',
						errorMsg: 'Card did not activate. Please try again later.'
					});
				}
				return Promise.resolve();
			} catch (err) {
				return Promise.reject();
			}
		},
		setSecurityParam(paramName) {
			if (!paramName) return;
			const currentParams = this.card.cardSecurityParametersResponse;
			const paramValue = currentParams[paramName];
			if (paramValue == undefined) return;

			const paramNames = {
				allowCashout: 'cashout',
				allowContactless: 'contactless payments',
				allowEcommerce: 'ecommerce'
			};

			const requestParamChange = async () => {
				const data = {
					...currentParams,
					[paramName]: !paramValue
				};
				try {
					const options = {
						method: 'PUT',
						url: endpoints.cards.securityParams( { cardId: this.cardId }),
						data
					};
					await axios(options);
				} catch (err) {
					//
				}
			};


			this.setPromptParams({
				onConfirm: requestParamChange,
				text: `Do you want to ${paramValue ? 'disable' : 'allow'} ${paramNames[paramName]} for your card?`,
			});
			this.$bvModal.show('card-prompt-modal');
		},
	},
	mounted() {
		if (this.cardId) {
			this.getCard();
		}
	}
};
</script>
<style lang="scss">
	@import "./CardSettings.scss";
</style>