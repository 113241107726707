var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c(_vm.wrapper, {
    tag: "component",
    "class": {
      'input-invalid': _vm.showError
    },
    style: {
      'margin-bottom': _vm.wrapper !== 'b-form-group' ? _vm.mb : ''
    },
    attrs: {
      "label": _vm.label,
      "label-for": _vm.labelFor
    }
  }, [_vm._t("default")], 2), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showError,
      expression: "showError"
    }],
    staticClass: "errormsg"
  }, [_c('span', [_vm._v(_vm._s(_vm.errorMessage))])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }