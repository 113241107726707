var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', [_c('form-error-field', {
    attrs: {
      "name": "businessActivities.values"
    }
  }, [_vm._t("label"), _c('v-multiselect', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "options": _vm.options,
      "multiple": true,
      "allow-empty": true,
      "close-on-select": false,
      "clear-on-select": true,
      "name": "businessActivities.values",
      "label": "name",
      "placeholder": "Select Your business activity",
      "return": "value",
      "track-by": "name",
      "max-height": 9999,
      "data-registration": "business-activity",
      "data-vv-as": "Business activity"
    },
    model: {
      value: _vm.innerValue.values,
      callback: function callback($$v) {
        _vm.$set(_vm.innerValue, "values", $$v);
      },
      expression: "innerValue.values"
    }
  })], 2), _vm.innerValue.values.includes('other') ? _c('form-error-field', {
    staticClass: "text-left mb-12",
    attrs: {
      "name": "businessActivities.otherValue"
    }
  }, [_vm._t("other-label"), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.innerValue.otherValue,
      expression: "innerValue.otherValue"
    }, {
      name: "validate",
      rawName: "v-validate",
      value: _vm.requiredBusinessActivity,
      expression: "requiredBusinessActivity"
    }],
    staticClass: "form-control",
    attrs: {
      "id": "business_activity_other",
      "type": "text",
      "name": "businessActivities.otherValue",
      "placeholder": "Specify other activity",
      "maxlength": "200",
      "data-registration": "business-activity-other",
      "data-vv-as": "Other"
    },
    domProps: {
      "value": _vm.innerValue.otherValue
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.innerValue, "otherValue", $event.target.value);
      }
    }
  })], 2) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }