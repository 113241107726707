<template>
	<div class="multi-address"
		:data-vv-scope="scope"
	>
		<div>
			<div class="form-group mb-0">
				<label>
					{{ label }}
				</label>
			</div>

			<address-input
				v-model="value.main"
				:scope="scope"
				type="addresses.main"
				:field-prefix="fieldPrefix"
			/>
		</div>
		<b-row>
			<b-col>
				<b-form-group class="text-left mb-3">
					<label>
						Correspondence address
					</label>
					<div class="custom-control custom-checkbox">
						<input
							:id="`same-address-${id}`"
							v-model="useAddressForCorrespondence"
							type="checkbox"
							:name="`Same address ${id}`"
							class="custom-control-input"
						/>
						<label
							class="custom-control-label"
							:for="`same-address-${id}`"
						><span>The same as registration address</span></label>
					</div>
				</b-form-group>
			</b-col>
		</b-row>
		<transition name="fade" 
			mode="out-in"
		>
			<div v-if="!useAddressForCorrespondence">
				<address-input
					v-model="value.correspondence"
					:scope="scope"
					type="addresses.correspondence"
					:field-prefix="fieldPrefix"
				/>
			</div>
		</transition>
	</div>
</template>
<script>
import AddressInput from './AddressInput';

import { v4 as uuidv4 } from 'uuid';

export default {
	name: 'MultiAddress',
	inject: ['$validator'],
	components: {
		AddressInput,
	},
	props: {
		addresses: {
			type: Object,
			default: () => ({ main: {}, correspondence: {} })
		},
		label: {
			type: String,
			default: 'Registration address'
		},
		scope: {
			type: String,
			default: undefined
		},
		fieldPrefix: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			id: uuidv4(),
			useAddressForCorrespondence: false,
			autocheck: false,
		};
	},
	computed: {
		value: {
			get() {
				return this.addresses;
			},
			set(value) {
				this.$emit('update:addresses', value);
			}
		},
		hasMainAddress() {
			return !!this.value?.main?.address;
		}
	},
	watch: {
		addresses: {
			handler() {
				this.checkForSameAddress();
			},
			deep: true
		},
		'addresses.main': {
			handler(newVal, oldVal) {
				if (this.useAddressForCorrespondence && this.hasMainAddress && oldVal) this.setSameAddress();
			},
			deep: true
		},
		'useAddressForCorrespondence': {
			handler(useSame) {
				if (useSame) this.setSameAddress();
			}
		},
		errors: {
			handler(value) {
				const addressFields = [
					'city',
					'street',
					'streetNo',
					'zipCode',
				];
				if (value?.items?.find(err => addressFields.map(f => `addresses.correspondence.${f}`).includes(err.field))) {
					this.useAddressForCorrespondence = false;
				}
			},
			deep: true
		}
	},
	methods: {
		setSameAddress() {
			this.$set(this.addresses, 'correspondence', { ...this.addresses.main });
		},
		checkForSameAddress() {
			if (!this.autocheck) {
				this.useAddressForCorrespondence = JSON.stringify(this.value.main) == JSON.stringify(this.value.correspondence);
				this.autocheck = true;
			}
		}
	},
	mounted() {
		if (this.hasMainAddress) {
			this.checkForSameAddress();
		}
	},
	provide() {
		return {
			$validator: this.$validator,
		};
	}
};
</script>
<style lang="scss">
	
</style>