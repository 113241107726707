const options = {
	accountInfoUseOptions: [
		{ 
			value: 'business_operation', 
			name: 'Corporate expenses', 
			description: 'Account for daily business operations. For example, settle with suppliers, collect payments for services, pay salaries, and similar.' },
		{ 
			value: 'segregated_client_funds', 
			name: 'Account for your client\'s funds collection',
			description: 'Account to collect and hold third-party funds and to execute payments on behalf of customers.'
		},
		{
			value: 'introducing_agent',
			name: 'Introducing agent',
			description: 'Account to collect commissions for introduced clients to Verifo.'
		},
		{
			value: 'initial_capital',
			name: 'Initial capital',
			description: 'Account for introduction of share capital for a company that is not yet registered.'
		},
		{
			value: 'correspondent_payments',
			name: 'Correspondent payments',
			description: 'Account for licenced payments institution, electronic money institution or bank to receive funds or perform payments on behalf of their customers.'
		},
		{
			value: 'settlement_payments',
			name: 'Settlement payments',
			description: 'Account for PSP which is collecting funds on behalf of it‘s customers (legal entities).'
		},
		{
			value: 'other',
			name: 'Other',
			description: 'There is no option above. I want to enter it myself.'
		},
	],
	accountInfoScopeOptions: [
		{ 
			value: 'eur_in_eea', 
			name: 'SEPA account',
			description: 'Dedicated European IBAN account in the name of the customer. Local and international payments in euros between EU and EEA countries.'
		},
		{ 
			value: 'swift_multi_currency', 
			name: 'SEPA account + global multi-currency outgoing payments',
			description: 'Dedicated European IBAN account in the name of the customer. Receive and hold funds in euros. Customers can initiate payments in 30 major currencies worldwide.'
		},
		{ 
			value: 'swift_dedicated_account', 
			name: 'SEPA account + SWIFT payments',
			description: 'Dedicated European IBAN account in the name of the customer. Can receive SWIFT payments. Initiated SWIFT payments are made in the name of a third party, using a reference code.'
		},
	],
	currencies: [
		{
			'country': 'au',
			'currencyId': 'aud',
			'name': 'Australian Dollar',
			'symbol': '$'
		},
		{
			'country': 'gb',
			'currencyId': 'gbp',
			'name': 'British Pounds',
			'symbol': '£'
		},
		// {
		// 	'country': 'bg',
		// 	'currencyId': 'bgn',
		// 	'name': 'Bulgarian Lev',
		// 	'symbol': 'лв'
		// },
		{
			'country': 'ca',
			'currencyId': 'cad',
			'name': 'Canadian Dollars',
			'symbol': '$'
		},
		{
			'country': 'cn',
			'currencyId': 'cny',
			'name': 'Chinese Yuan Renminbi',
			'symbol': '¥'
		},
		{
			'country': 'hr',
			'currencyId': 'hrk',
			'name': 'Croatian Kuna',
			'symbol': 'kn'
		},
		{
			'country': 'cz',
			'currencyId': 'czk',
			'name': 'Czech Koruna',
			'symbol': 'Kč'
		},
		{
			'country': 'dk',
			'currencyId': 'dkk',
			'name': 'Danish Krone',
			'symbol': 'kr.'
		},
		{
			'country': 'hk',
			'currencyId': 'hkd',
			'name': 'Hong Kong Dollar',
			'symbol': 'HK$'
		},
		{
			'country': 'in',
			'currencyId': 'inr',
			'name': 'Indian Rupee',
			'symbol': '₹'
		},
		{
			'country': 'id',
			'currencyId': 'idr',
			'name': 'Indonesian Rupiah',
			'symbol': 'Rp'
		},
		{
			'country': 'il',
			'currencyId': 'ils',
			'name': 'Israeli Shekel',
			'symbol': '₪'
		},
		{
			'country': 'jp',
			'currencyId': 'jpy',
			'name': 'Japanese Yen',
			'symbol': '¥'
		},
		{
			'country': 'my',
			'currencyId': 'myr',
			'name': 'Malaysian Ringgit',
			'symbol': 'RM'
		},
		{
			'country': 'mx',
			'currencyId': 'mxn',
			'name': 'Mexican Peso',
			'symbol': 'Mex$'
		},
		{
			'country': 'nz',
			'currencyId': 'nzd',
			'name': 'New Zealand Dollar',
			'symbol': '$'
		},
		{
			'country': 'no',
			'currencyId': 'nok',
			'name': 'Norwegian Krone',
			'symbol': 'kr'
		},
		{
			'country': 'ph',
			'currencyId': 'php',
			'name': 'Philippine Piso',
			'symbol': '₱'
		},
		{
			'country': 'pl',
			'currencyId': 'pln',
			'name': 'Poland Zloty',
			'symbol': 'zł'
		},
		{
			'country': 'ro',
			'currencyId': 'ron',
			'name': 'Romanian Leu',
			'symbol': 'lei'
		},
		{
			'country': 'ru',
			'currencyId': 'rub',
			'name': 'Russian Ruble',
			'symbol': '₽'
		},
		{
			'country': 'sg',
			'currencyId': 'sgd',
			'name': 'Singapore Dollar',
			'symbol': '$'
		},
		{
			'country': 'za',
			'currencyId': 'zar',
			'name': 'South African Rand',
			'symbol': 'R'
		},
		{
			'country': 'se',
			'currencyId': 'sek',
			'name': 'Swedish Krona',
			'symbol': 'kr'
		},
		{
			'country': 'ch',
			'currencyId': 'chf',
			'name': 'Swiss Franc',
			'symbol': 'CHF'
		},
		{
			'country': 'th',
			'currencyId': 'thb',
			'name': 'Thai Baht',
			'symbol': '฿'
		},
		{
			'country': 'tr',
			'currencyId': 'try',
			'name': 'Turkish Lira',
			'symbol': '₺'
		},
		{
			'country': 'hu',
			'currencyId': 'huf',
			'name': 'Hungarian Forint',
			'symbol': 'Ft'
		},
		{
			'country': 'ae',
			'currencyId': 'aed',
			'name': 'United Arab Emirates Dirham',
			'symbol': 'د.إ'
		},
		{
			'country': 'us',
			'currencyId': 'usd',
			'name': 'United States Dollars',
			'symbol': '$'
		}
		
	],
};

export const { accountInfoUseOptions, accountInfoScopeOptions, currencies } = options;