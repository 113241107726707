<template>
	<modal 
		id="session-end-modal" 
		:adaptive="true" 
		name="idle-message" 
		height="auto"
		@before-close="beforeClose" 
		@before-open="beforeOpen"
	>
		<div class="session-end-modal-container">
			<b-row class="justify-content-md-center">
				<b-col class="timeout-text text-right">
					{{ timeoutText1 }}<br>{{ timeoutText2 }}
				</b-col>
				<b-col class="timeout-time">
					{{ timeoutTime }}
				</b-col>
			</b-row>
			<b-row class="session-end-modal-buttons pt-5">
				<b-col>
					<button 
						class="btn btn-info btn-sm btn-block" 
						@click="conti"
					>
						Continue
					</button>
				</b-col>
				<b-col>
					<button 
						class="btn btn-primary btn-sm btn-block" 
						@click="logout"
					>
						Logout
					</button>
				</b-col>
			</b-row>
		</div>
	</modal>
</template>

<script>
import { mapActions } from 'vuex';
const timeout = 5;//min

export default {
	name: 'IdleNotification',
	data() {
		return {
			timer: null,
			endTime: new Date(),
			timeoutText1: '',
			timeoutText2: '',
			timeoutTime: '',
		};
	},
	onIdle() {
		this.$modal.show('idle-message');
	},
	onActive() {

	},
	methods: {
		...mapActions('auth', {
			setLogout: 'logout'
		}),
		startTimer() {
			this.endTime = new Date((new Date()).getTime() + timeout * 60000);
			this.timer = setInterval(this.fetchEventsList, 1000);
		},
		fetchEventsList: function () {
			let distance = this.endTime.getTime() - (new Date()).getTime();
			if (distance <= 1000) {
				this.logout();
			}
			let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
			let seconds = Math.floor((distance % (1000 * 60)) / 1000);
			if (seconds < 10) {
				seconds = '0' + seconds;
			}
			this.timeoutText1 = 'The session ';
			this.timeoutText2 = 'will end after ';
			this.timeoutTime = minutes + ':' + seconds;
		},
		stopTimer() {
			clearInterval(this.timer);
		},
		beforeClose() {
			this.stopTimer();
		},
		beforeOpen() {
			this.timeoutText = 'The session will end after ' + timeout + ':' + 0;
			this.startTimer();
		},
		conti() {
			this.$modal.hide('idle-message');
		},
		logout() {
			this.stopTimer();
			this.setLogout()
				.then(() => {
					this.$router.replace({ name: 'login' });
				});
		},
	},
};
</script>
<style lang="scss" scoped>
	@import './IdleNotification.scss';
</style>
