<template>
	<div>
		<div 
			id="body"
		>
			<div class="auth-container">
				<div class="auth-form">
					<div class="logo">
						<a :href="logoUri">{{ siteName }}</a>
					</div>
					<transition
						name="contract"
						mode="out-in"
					>
						<router-view />
					</transition>
					<div class="auth-footer">
						<span v-if="WHITELABEL == 'verifo'">&copy; 2017-{{ currentYear }}</span>
						<span v-if="WHITELABEL !== 'verifo'">&copy; {{ currentYear }}</span>
						{{ siteName }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { siteName } from '~/config';
export default {
	name: 'LayoutAuthentication',
	data: () => ({
		siteName,
		currentYear: (new Date).getFullYear(),
		WHITELABEL,
		logoUri: '/'
	}),
	mounted() {
		if (WHITELABEL == 'supersonicz') {
			this.logoUri = 'https://www.wisebanq.com';
		}
	}
};
</script>
<style lang="scss" scoped>
	@import './LayoutAuthentication.scss';
</style>