var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "bulk-payments"
  }, [_vm.step == 'transferSelection' ? _c('div', [_c('div', {
    staticClass: "bulk-items mt-3"
  }, [_c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "4",
      "lg": "4",
      "xl": "4"
    }
  }, [_c('div', {
    staticClass: "text-left"
  }, [_c('custom-select', {
    attrs: {
      "items": _vm.statuses,
      "hide-front": false,
      "modify-bg": true
    },
    on: {
      "select": function select($event) {
        return _vm.changeStatus($event);
      }
    }
  })], 1)]), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "5",
      "lg": "5",
      "xl": "5"
    }
  }), _c('b-col', {
    staticClass: "mt-3 mt-lg-0 mt-sm-0 mt-xl-0",
    attrs: {
      "sm": "12",
      "md": "3",
      "lg": "3",
      "xl": "3"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('p', {
    staticClass: "mr-3 mb-0 text-muted align-self-center text-nowrap items-per-page-label"
  }, [_vm._v(" Items "), _c('br'), _vm._v(" per page ")]), _c('custom-select', {
    attrs: {
      "items": _vm.pages,
      "hide-front": false,
      "modify-bg": true
    },
    on: {
      "select": function select($event) {
        return _vm.filterPages($event);
      }
    }
  })], 1)]), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "12",
      "xl": "12"
    }
  }, [_vm.status === 'created' && _vm.items.rows && _vm.items.rows.length > 0 && _vm.isWalletGranted('initiate_transaction', _vm.currentWallet.walletId) ? _c('div', {
    staticClass: "text-center mt-3 mt-lg-3 mt-sm-3 mt-xl-3 text-sm-right text-lg-right text-xl-right"
  }, [_c('button', {
    staticClass: "btn btn-primary mb-3",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.selectAll(true);
      }
    }
  }, [_vm._v(" Select all ")]), _c('button', {
    staticClass: "btn btn-outline-primary mb-3 ml-3",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.selectAll(false);
      }
    }
  }, [_vm._v(" Deselect all ")])]) : _vm._e()])], 1), _vm.items.loading ? _c('b-card', {
    staticClass: "bulk-item text-center"
  }, [_c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  })], 1) : _vm._e(), _vm._l(_vm.items.rows, function (item) {
    return _c('b-card', {
      key: item.id,
      staticClass: "mb-3 bulk-item",
      "class": "".concat(_vm.isItemSelected(item) && _vm.isWalletGranted('initiate_transaction', _vm.currentWallet.walletId) ? 'bulk-item--selected' : '', " ").concat(item.status !== 'created' ? 'bulk-item--completed' : ''),
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.selectItem(item);
        }
      }
    }, [_c('b-row', [_c('b-col', {
      staticClass: "check",
      attrs: {
        "md": "1",
        "cols": "12"
      }
    }, [item.status === 'created' && _vm.isWalletGranted('initiate_transaction', _vm.currentWallet.walletId) ? _c('div', [!_vm.isItemSelected(item) ? _c('i', {
      staticClass: "far fa-square"
    }) : _c('i', {
      staticClass: "far fa-check-square"
    })]) : _vm._e()]), _c('b-col', {
      attrs: {
        "md": "3",
        "cols": "12"
      }
    }, [_c('small', {
      staticClass: "item-label"
    }, [_vm._v("Beneficiary")]), item.transactionData.serviceId === 'cc_swift_sha' || item.transactionData.serviceId === 'cc_local' ? _c('div', [item.transactionData.beneficiaryRequest.type === 'company' ? _c('div', [_c('div', [_vm._v(_vm._s(item.transactionData.beneficiaryRequest.companyName) + " (Multicurrency)")])]) : _c('div', [_vm._v(" " + _vm._s(item.transactionData.beneficiaryRequest.name + ' ' + item.transactionData.beneficiaryRequest.surname) + " (Multicurrency) ")])]) : _c('div', [_vm._v(" " + _vm._s(item.transactionData.beneficiaryRequest.name) + " ")])]), _c('b-col', {
      attrs: {
        "md": "3",
        "cols": "12"
      }
    }, [_c('small', {
      staticClass: "item-label"
    }, [_vm._v("Account Number")]), _c('div', [_vm._v(_vm._s(item.transactionData.beneficiaryRequest.accountNumber))])]), _c('b-col', {
      attrs: {
        "md": "2",
        "cols": "12"
      }
    }, [_c('small', {
      staticClass: "item-label"
    }, [_vm._v("Amount")]), _c('div', {
      staticClass: "amount-label"
    }, [_c('b-badge', {
      staticClass: "amount-badge",
      attrs: {
        "variant": _vm.statusVariant('item', item.status)
      }
    }, [_vm._v(" " + _vm._s(item.transactionData.amount) + " "), _c('small', [_vm._v(" " + _vm._s(item.transactionData.currencyId) + " ")])])], 1)]), _c('b-col', {
      attrs: {
        "md": "3",
        "cols": "12"
      }
    }, [_c('small', {
      staticClass: "item-label"
    }, [_vm._v("Details")]), _c('div', [_vm._v(_vm._s(item.transactionData.details))])])], 1)], 1);
  }), !_vm.items.loading && (!_vm.items.rows || _vm.items.rows.length < 1) ? _c('div', {
    staticClass: "alert alert-info"
  }, [_vm._v(" No transactions ")]) : _vm._e(), _c('div', {
    staticClass: "mb-3 text-sm-left text-center"
  }, [_vm._m(0), _c('b-badge', {
    staticClass: "mr-1 status-badge my-1",
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(" Ready ")]), _c('b-badge', {
    staticClass: "mr-1 status-badge my-1",
    attrs: {
      "variant": "success"
    }
  }, [_vm._v(" Processed ")]), _c('b-badge', {
    staticClass: "status-badge my-1",
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v(" Failed or deleted ")])], 1), _c('div', {
    staticClass: "mb-3"
  }, [_vm.items.pages > 1 ? _c('pagination', {
    attrs: {
      "total": _vm.items.count,
      "per-page": _vm.filterPerPage,
      "current-page": _vm.items.currentPage
    },
    on: {
      "pagechanged": _vm.getTransferList
    }
  }) : _vm._e()], 1)], 2), _vm.items.rows && _vm.items.rows.length > 0 ? _c('div', {
    staticClass: "text-center"
  }, [_vm.isWalletGranted('approve_transaction', _vm.currentWallet.walletId) ? _c('button', {
    staticClass: "btn btn-primary ml-3",
    attrs: {
      "disabled": _vm.selectedItems.length == 0
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        _vm.step = 'confirmation';
      }
    }
  }, [_vm._v(" Next ")]) : _vm._e(), _vm.isGranted('front_transaction_initiate_delete') ? _c('button', {
    staticClass: "btn btn-danger ml-3",
    attrs: {
      "disabled": _vm.selectedItems.length == 0
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.deleteSelected.apply(null, arguments);
      }
    }
  }, [_vm._v(" Remove ")]) : _vm._e()]) : _vm._e()]) : _vm._e(), _vm.step == 'confirmation' || _vm.step == 'transfer' ? _c('div', {
    staticClass: "selected-items"
  }, [_c('b-card', [_vm._l(_vm.selectedItems, function (item) {
    return _c('b-row', {
      key: item.id,
      staticClass: "selected-item"
    }, [_c('b-col', {
      attrs: {
        "md": "3",
        "sm": "12"
      }
    }, [_c('small', {
      staticClass: "item-label"
    }, [_vm._v("Beneficiary")]), item.transactionData.serviceId === 'cc_swift_sha' || item.transactionData.serviceId === 'cc_local' ? _c('div', [item.transactionData.beneficiaryRequest.type === 'company' ? _c('div', [_c('div', [_vm._v(_vm._s(item.transactionData.beneficiaryRequest.companyName))])]) : _c('div', [_vm._v(" " + _vm._s(item.transactionData.beneficiaryRequest.name + ' ' + item.transactionData.beneficiaryRequest.surname) + " ")])]) : _c('div', [_vm._v(" " + _vm._s(item.transactionData.beneficiaryRequest.name) + " ")])]), _c('b-col', {
      attrs: {
        "md": "3",
        "sm": "12"
      }
    }, [_c('small', {
      staticClass: "item-label"
    }, [_vm._v("Account Number")]), _c('div', [_vm._v(_vm._s(item.transactionData.beneficiaryRequest.accountNumber))])]), _c('b-col', {
      attrs: {
        "md": "3",
        "sm": "12"
      }
    }, [_c('small', {
      staticClass: "item-label"
    }, [_vm._v("Amount")]), _c('div', [_vm._v(_vm._s(item.transactionData.amount)), _c('small', [_vm._v(_vm._s(item.transactionData.currencyId))])])]), _c('b-col', {
      attrs: {
        "md": "3",
        "sm": "12"
      }
    }, [_c('small', {
      staticClass: "item-label"
    }, [_vm._v("Details")]), _c('div', [_vm._v(_vm._s(item.transactionData.details ? item.transactionData.details : '-'))])])], 1);
  }), _c('b-row', {
    staticClass: "selected-item",
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('strong', [_vm._v("Number of payments: " + _vm._s(_vm.selectedItems.length))])]), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('strong', [_vm._v("Total: " + _vm._s(_vm.selectedTotal.sum) + _vm._s(_vm.selectedTotal.currency))]), _c('div', [_vm.exceededBalance(_vm.selectedTotal.sum) ? _c('b-badge', {
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v(" Insufficient Funds ")]) : _vm._e()], 1)])], 1)], 2), _vm.step == 'confirmation' ? _c('div', {
    staticClass: "text-center mt-3"
  }, [_c('button', {
    staticClass: "btn btn-outline-primary",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        _vm.step = 'transferSelection';
      }
    }
  }, [_vm._v(" Back ")]), _vm.isWalletGranted('approve_transaction', _vm.currentWallet.walletId) ? _c('button', {
    staticClass: "btn btn-primary ml-3",
    attrs: {
      "disabled": _vm.exceededBalance(_vm.selectedTotal.sum)
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.confirm.apply(null, arguments);
      }
    }
  }, [_vm._v(" Confirm ")]) : _vm._e()]) : _vm._e(), _vm.step == 'transfer' ? _c('div', {
    staticClass: "transfer"
  }, [_c('b-card', {
    staticClass: "mt-3"
  }, [_c('authenticator', {
    ref: "authenticator",
    attrs: {
      "show-back-button": false,
      "on-submit": _vm.transfer,
      "type": "transfer",
      "on-resend": _vm.confirm
    }
  })], 1), _c('div', {
    staticClass: "text-center mt-3"
  }, [_c('button', {
    staticClass: "btn btn-outline-primary",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        _vm.step = 'confirmation';
      }
    }
  }, [_vm._v(" Back ")])])], 1) : _vm._e()], 1) : _vm._e(), _vm.step == 'success' ? _c('div', [_c('b-card', [_vm._v(" Transfers approved successfully "), _c('button', {
    staticClass: "btn btn-primary ml-3",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        _vm.step = 'transferSelection';
      }
    }
  }, [_vm._v(" OK ")])])], 1) : _vm._e(), _vm.step == 'successDelete' ? _c('div', [_c('b-card', [_vm._v(" Transfers deleted successfully "), _c('button', {
    staticClass: "btn btn-primary ml-3",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        _vm.step = 'transferSelection';
      }
    }
  }, [_vm._v(" OK ")])])], 1) : _vm._e()]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('span', [_vm._v("Status:")])]);
}]

export { render, staticRenderFns }