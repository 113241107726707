import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import config from '~/config';
if (config.sentry && process.env.NODE_ENV !== 'development') {
	Sentry.init({
		Vue: Vue,
		dsn: config.sentry,
		environment: `${WHITELABEL}_${DEV ? 'dev' : 'prod'}`
	});
}
