import { UPDATE_IDENTITY, UPDATE_REMOTE_IDENTITY } from '~/store/mutation-types';

export default {
	[UPDATE_IDENTITY](state, payload) {
		for (let key in payload.data) {
			if (Object.prototype.hasOwnProperty.call(payload.data, key)) {
				if (state[key] !== payload.data[key]) {
					state[key] = payload.data[key];
				}
			}
		}
	},
	[UPDATE_REMOTE_IDENTITY](state, payload) {
		state.remoteUrl = payload.data.url;
	}
};