var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.title ? _c('div', {
    staticClass: "preloader"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]) : _c('div', {
    staticClass: "preloader"
  }, [_vm._m(0)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "preloader-inside"
  }, [_c('div'), _c('div'), _c('div'), _c('div')]);
}]

export { render, staticRenderFns }