import Vue from 'vue';

const defaultMessages = {
	error: 'Something went wrong.',
	success: 'Success.'
};

function formatErrorMessage(value) {
	if (typeof value == 'string') return value;
	if (value && value.error) {
		if (typeof value.error == 'string') return value.error;
		if (typeof value.error.message == 'string') {
			return value.error.message;
		}
	}
	return '';
}

export default {
	notify: ({ state }, { title, text, type, group = state.default }) => {
		const statedParams = state.groups.find(g => g.group == group);
		if (!statedParams) return;
		const params = { group, title, text, type, ...statedParams };
		return Vue.notify(params);
	},
    
	pushError: ({ dispatch }, data) => {
		let title = data, text;
		if (typeof data !== 'string' && (!!data.title || !!data.text)) {
			title = data.title; 
			text = data.text;	
		}
		if (typeof data !== 'string' && !!data.message) {
			title = data.message;
		}
		const message = {
			title: formatErrorMessage(title) ? formatErrorMessage(title) : defaultMessages.error,
			text: formatErrorMessage(text)
		};
		dispatch('notify', {
			title: message.title,
			text: message.text,
			type: 'error'
		});
	},
    
	pushSuccess: ({ dispatch }, data) => {
		let title = data, text;
		if (typeof data !== 'string' && (!!data.title || !!data.text)) title = data.title; text = data.text;
		dispatch('notify', {
			title,
			text,
			type: 'success'
		});
	},
};