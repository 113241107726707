var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    attrs: {
      "name": "privacy_policy",
      "height": "auto",
      "click-to-close": false,
      "width": 1000,
      "adaptive": true
    }
  }, [_c('div', {
    staticClass: "customer-service-modal-wrapper"
  }, [_c('h2', [_vm._v("Privacy Policy")]), _vm.current == 'privacy_policy' ? _c('div', {
    staticClass: "text-center"
  }, [_c('transition', {
    attrs: {
      "name": "fade",
      "mode": "out-in"
    }
  }, [_vm.pdfLoading ? _c('b-spinner', {
    staticClass: "my-4",
    attrs: {
      "label": "Loading"
    }
  }) : _vm._e(), !_vm.pdfLoading ? _c('vue-pdf-app', {
    staticStyle: {
      "height": "500px",
      "max-height": "70vh"
    },
    attrs: {
      "pdf": _vm.pdfUrls.privacy_policy,
      "config": {
        toolbar: false,
        sidebar: false
      },
      "page-scale": "page-height",
      "theme": "light"
    }
  }) : _vm._e()], 1), !_vm.pdfLoading ? _c('div', {
    staticClass: "my-3"
  }, [_c('small', {
    staticClass: "text-center"
  }, [_vm._v(" Can't see the text? "), _c('a', {
    attrs: {
      "href": _vm.pdfUrls.privacy_policy,
      "target": "_blank"
    }
  }, [_vm._v("Open or download")]), _vm._v(". ")])]) : _vm._e()], 1) : _vm._e(), _c('div', {
    staticClass: "text-center my-3"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.serviceAgree('privacy_policy');
      }
    }
  }, [!_vm.loading ? _c('span', [_vm._v("Agree")]) : _vm._e(), _vm.loading ? _c('b-spinner', {
    attrs: {
      "variant": "light",
      "small": "",
      "label": "Loading"
    }
  }) : _vm._e()], 1), _c('b-button', {
    staticClass: "ml-3",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return function () {
          return _vm.cancel('privacy_policy');
        }.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.closeLabel) + " ")])], 1)])]), _c('modal', {
    attrs: {
      "adaptive": true,
      "name": "service_agreement",
      "height": "auto",
      "click-to-close": false,
      "width": 1000
    }
  }, [_c('div', {
    staticClass: "customer-service-modal-wrapper"
  }, [_c('h2', [_vm._v("Service Agreements")]), _vm.current == 'service_agreement' ? _c('div', {
    staticClass: "text-center"
  }, [_c('transition', {
    attrs: {
      "name": "fade",
      "mode": "out-in"
    }
  }, [_vm.pdfLoading ? _c('b-spinner', {
    staticClass: "my-4",
    attrs: {
      "label": "Loading"
    }
  }) : _vm._e(), !_vm.pdfLoading ? _c('vue-pdf-app', {
    staticStyle: {
      "height": "500px",
      "max-height": "70vh"
    },
    attrs: {
      "pdf": _vm.pdfUrls.service_agreement,
      "config": {
        toolbar: false,
        sidebar: false
      },
      "page-scale": "page-width",
      "theme": "light"
    }
  }) : _vm._e()], 1), !_vm.pdfLoading ? _c('div', {
    staticClass: "my-3"
  }, [_c('small', {
    staticClass: "text-center"
  }, [_vm._v(" Can't see the text? "), _c('a', {
    attrs: {
      "href": _vm.pdfUrls.service_agreement,
      "target": "_blank"
    }
  }, [_vm._v("Open or download")]), _vm._v(". ")])]) : _vm._e()], 1) : _vm._e(), _c('div', {
    staticClass: "text-center my-3"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "data-agreements": "agree"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.serviceAgree('service_agreement');
      }
    }
  }, [!_vm.loading ? _c('span', [_vm._v("Agree")]) : _vm._e(), _vm.loading ? _c('b-spinner', {
    attrs: {
      "variant": "light",
      "small": "",
      "label": "Loading"
    }
  }) : _vm._e()], 1), _c('b-button', {
    staticClass: "ml-3",
    attrs: {
      "variant": "outline-primary",
      "data-agreements": "cancel"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return function () {
          return _vm.cancel('service_agreement');
        }.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.closeLabel) + " ")])], 1)])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }