import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

import Store from '~/store/index';
import { siteName, refreshCookieName } from '~/config';
import { cookie } from '~/helpers';

import authentication from './authentication';
import dashboard from './dashboard';
import verification from './verification';
import generateReferralCookie from '~/helpers/referral.helper';
import { twoFactorHelper } from '~/helpers/twofactor.helper';

const router = new VueRouter({
	mode: 'history',
	scrollBehavior(to) {
		return to.hash ? { selector: to.hash } : { x: 0, y: 0 };
	},
	routes: [
		authentication, 
		dashboard, 
		verification
	]
});

router.beforeEach(async (to, from, next) => {
	//Set page title
	document.title = siteName;
	//Check if route exists and if not redirect to home or login page depending if user logged in.
	if (to.matched.length === 0) {
		next(Store.state.auth.status.success ? { name: 'index' } : { name: 'login' });
		throw new Error('No route found');
	}

	generateReferralCookie();
	
	let authRequired = !!to.matched.find(value => value.meta.requiresAuth);	
	let guestRequired = !!to.matched.find(value => value.meta.requiresGuest);

	//Authorized access
	if (!Store.state.auth.status.login.success && authRequired) {
		//Checks if public refresh cookie has been set and redirects to login if not
		if (cookie.exists(refreshCookieName) && !!twoFactorHelper.isAauthenticated()) {
			await Store.dispatch('auth/refresh')
				.catch(() => next({ name: 'login' }));
			await Store.dispatch('user/getUserProfile')
				.catch(() => { return; });
		} else {
			next({ name: 'login' });
		}
	}

	//Guest access
	if (guestRequired) next(Store.state.auth.status.success ? { name: 'index' } : undefined);

	Store.dispatch('hideErrorNotification');
	Store.dispatch('hideSuccessNotification');
	Store.dispatch('routes/setRoute', { from, to });

	next();
});

export default router;


