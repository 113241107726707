var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.errors.has('server'),
      expression: "errors.has('server')"
    }],
    staticClass: "alert alert-danger"
  }, [_vm._v(" " + _vm._s(_vm.errors.first('server')) + " ")]), _c('form', {
    attrs: {
      "method": "post"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSubmit.apply(null, arguments);
      }
    }
  }, [_c('b-container', {
    staticClass: "registration-content"
  }, [_c('b-row', {
    staticClass: "justify-content-md-center form-group"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('label', [_vm._v(" Password: ")]), _c('password-input', {
    attrs: {
      "id": "password",
      "input-ref": "password",
      "name": "password",
      "disabled": _vm.disabledPassword,
      "data-password": "password"
    },
    on: {
      "validated": _vm.validated
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  })], 1)], 1), _c('b-row', {
    staticClass: "justify-content-md-center form-group"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('label', [_vm._v(" Repeat password: ")]), _c('password-input', {
    attrs: {
      "id": "confirmPassword",
      "req": false,
      "validation": false,
      "disabled": _vm.disabledPassword,
      "name": "confirmPassword",
      "data-password": "repeat",
      "validation-rules": "min:8|confirmed:password"
    },
    on: {
      "validated": _vm.confirmValidated
    },
    model: {
      value: _vm.confirmPassword,
      callback: function callback($$v) {
        _vm.confirmPassword = $$v;
      },
      expression: "confirmPassword"
    }
  })], 1)], 1), _vm._l(_vm.authenticator.list.filter(function () {
    return _vm.allowNext && !_vm.confirmed;
  }), function (option, i) {
    return _c('b-row', {
      key: "password-confirm-".concat(i),
      staticClass: "mb-4"
    }, [_c('b-col', [_c('button', {
      staticClass: "btn btn-primary btn-block",
      attrs: {
        "data-password": "submit"
      },
      on: {
        "click": function click($event) {
          return _vm.submitPassword(option.authenticator);
        }
      }
    }, [_vm._v(" Confirm "), option.authenticator === 'sms' ? _c('span', [_vm._v("by SMS")]) : _vm._e(), option.authenticator === 'whats_app' ? _c('span', [_vm._v("by WhatsApp")]) : _vm._e()])])], 1);
  }), _vm.confirmed ? _c('b-row', {
    staticClass: "justify-content-md-center"
  }, [_c('b-col', {
    staticClass: "mb-4 mt-4",
    attrs: {
      "md": "12"
    }
  }, [_c('authenticator', {
    ref: "authenticator",
    attrs: {
      "hash": _vm.data.hash,
      "show-back-button": false,
      "on-success": _vm.handleSuccess,
      "on-error": _vm.handleError,
      "on-resend": _vm.handleResend,
      "type": "registration",
      "button-text": "Next",
      "action": "register/confirmRegistration",
      "data-password": "authenticator"
    }
  })], 1)], 1) : _vm._e(), _c('b-row', {
    staticClass: "justify-content-md-center"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('notification')], 1)], 1), _c('b-row', {
    staticClass: "justify-content-md-center"
  }, [_c('b-col', {
    staticClass: "text-center mb-4 mt-4 border border-left-0 border-right-0 border-bottom-0",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "textblock margintop30 infobox"
  }, [_c('div', {
    staticClass: "textblock darktext mediumtext"
  }, [_c('p', {
    staticClass: "mt-4"
  }, [_vm._v(" Please remember and keep this info safe."), _c('br'), _vm._v(" We will never ask your password. ")])])])])], 1)], 2)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }