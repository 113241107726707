var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "auth-card"
  }, [_vm._m(0), _c('router-link', {
    staticClass: "btn btn-dark px-5",
    attrs: {
      "to": "/login"
    }
  }, [_vm._v(" Go to login ")])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h1', {
    staticClass: "auth-title text-success"
  }, [_vm._v(" Your password reset"), _c('br'), _vm._v(" has been completed. ")]);
}]

export { render, staticRenderFns }