export default {
	occupationTypes: [
		{ 
			name: 'Employee', 
			value: 'employee', 
			companyActivity: true,
			companyName: true,
			companyPosition: true
		},
		{
			name: 'Self Employed',
			value: 'self_employed',
			companyActivity: true
		},
		{ name: 'Student', value: 'student', companyActivity: false },
		{
			name: 'Civil Servant',
			value: 'civil_servant',
			institutionPosition: true,
			companyName: true
		},
		{ name: 'Unemployed', value: 'unemployed', companyActivity: false },
		{ name: 'Retired', value: 'retired', companyActivity: false },
		{ name: 'Other', value: 'other', companyActivity: false }
	],
	companyPositions: [
		{ name: 'Manager of the Company (Director)', value: 'manager_of_company' },
		{ name: 'Financial officer', value: 'financial_officer' },
		{ name: 'Business development officer', value: 'business_development_officer' },
		{ name: 'Head of sales', value: 'head_of_sales' },
		{ name: 'Head of marketing', value: 'head_of_marketing' },
		{ name: 'Head of legal', value: 'head_of_legal' },
		{ name: 'Head of accounting', value: 'head_of_accounting' },
		{ name: 'Non-managerial duties', value: 'non_managerial_duties' },
		{ name: 'Other (please specify)', value: 'other' },
	],
	sourceOfIncome: [
		{ name: 'Salary', value: 'salary' },
		{ name: 'Pension, allowance, stipend', value: 'pension_allowance_stipend' },
		{ name: 'Securities', value: 'securities' },
		{ name: 'Individual activity or business', value: 'individual_activity_or_business' },
		{ name: 'Income from rent', value: 'rent' },
		{ name: 'Inheritance', value: 'inheritance' },
		{ name: 'Savings', value: 'savings' },
		{ name: 'Other', value: 'other' },
	],
	companyActivityTypes: [
		{ name: 'Retail', value: 'retail' },
		{ name: 'Constructions', value: 'constructions' },
		{ name: 'Auto repair', value: 'auto_repair' },
		{ name: 'Advertising/Marketing', value: 'advertising' },
		{ name: 'Consulting', value: 'consulting' },
		{ name: 'Forex trading', value: 'forex_trading' },
		{ name: 'Education', value: 'education' },
		{ name: 'Insurance', value: 'insurance' },
		{ name: 'Legal', value: 'legal' },
		{ name: 'Sports', value: 'sports' },
		{ name: 'Real estate', value: 'real_estate' },
		{ name: 'Logistics', value: 'logistics' },
		{ name: 'Crypto trading/activities', value: 'crypto_trading' },
		{ name: 'Investment', value: 'investment' },
		{
			name: 'IT (Information technologies)',
			value: 'information_technologies'
		},
		{ name: 'Financial institution', value: 'financial_institution' },
		{ name: 'Tourism', value: 'tourism' },
		{ name: 'Gambling', value: 'gambling' },
		{ name: 'State Agency', value: 'state_agency' },
		{ name: 'Arms and other military equipment', value: 'arms_and_other_military_equipment' },
		{ name: 'Trade in tobacco products, alcohol, prescription drugs', value: 'trade_in_tobacco_alcohol_drugs' },
		{ name: 'Other', value: 'other' }
	],
	purposeForOpeningTypes: [
		{ name: 'Daily banking', value: 'daily_banking' },
		{ name: 'For savings', value: 'saving' },
		{ name: 'To receive salary', value: 'receive_salary' },
		{ name: 'To receive scholarship', value: 'receive_scholarship' },
		{ name: 'Investment', value: 'investment' },
		{
			name: 'To receive social benefits',
			value: 'receive_social_benefits'
		},
		{ name: 'To receive pensions', value: 'receive_pensions' },
		{
			name: 'To receive income from individual business activities',
			value: 'receive_from_business_activities'
		},
		{
			name: 'To receive/send funds from cryptocurrency activities',
			value: 'send_receive_from_cryptocurrency'
		},
		{
			name: 'To receive/send funds from forex trading activities',
			value: 'send_receive_from_forex_trading'
		},
		{
			name: 'To receive/send funds from gambling activities',
			value: 'send_receive_from_gambling'
		},
		{ name: 'Other', value: 'other' }
	],
	turnoverAmountOptionTypes: [
		{ value: 'lt_500', name: 'Up to 500 EUR' },
		{ value: 'lt_1k', name: '501 to 1000 EUR' },
		{ value: 'lt_3k', name: '1 001 - 3 000 EUR' },
		{ value: 'lt_5k', name: '3 001 - 5 000 EUR' },
		{ value: 'gt_5k', name: 'Over 5 000 EUR' },
	],
	turnoverCountOptionTypes: [
		{ value: 'lt_10', name: '0 - 10' },
		{ value: 'lt_30', name: '11 - 30' },
		{ value: 'lt_60', name: '31 - 60' },
		{ value: 'gt_60', name: 'Over 60' }
	],
	cashOptionTypes: [
		{ value: 'lt_1k', name: '0 - 1000 EUR' },
		{ value: 'lt_5k', name: '1001 - 5000 EUR' },
		{ value: 'lt_10k', name: '5001 - 10 000 EUR' },
		{ value: 'lt_75k', name: '10 001 - 75 000 EUR' },
	],
	politicalExposureTypes: [
		{ value: 'none', name: 'I am not a politically exposed person' },
		{ value: 'exposed', name: 'I am a politically exposed person' },
		{
			value: 'family_member',
			name: 'I am immediate family member of a politically exposed person'
		},
		{
			value: 'associate',
			name: 'I am a close associate of a politically exposed person'
		}
	],
	serviceToUseOptionTypes: [
		{ value: 'sepa', name: 'SEPA payments' },
		{ value: 'swift', name: 'SWIFT payments' }
	],
};