import { UPDATE_ROUTE, SET_ROUTE_REFRESH_KEY } from '~/store/mutation-types';

export default {
	[UPDATE_ROUTE](state, { from, to }) {
		if (from) {
			state.history.unshift(from);
		}
		state.current = to;
	},
	[SET_ROUTE_REFRESH_KEY](state) {
		state.refreshKey = Date.now();
	}
};