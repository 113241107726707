let wlcfg;
if (WHITELABEL == 'verifo') {
	wlcfg = require('./config/verifo.whitelabel');
} else if (WHITELABEL == 'orunpay') {
	wlcfg = require('./config/orunpay.whitelabel');
} else if (WHITELABEL == 'nomadrem') {
	wlcfg = require('./config/nomadrem.whitelabel');
} else if (WHITELABEL == 'bainal') {
	wlcfg = require('./config/bainal.whitelabel');
} else if (WHITELABEL == 'settledesk') {
	wlcfg = require('./config/settledesk.whitelabel');
} else if (WHITELABEL == 'complexpay') {
	wlcfg = require('./config/complexpay.whitelabel');
} else if (WHITELABEL == 'wirezee') {
	wlcfg = require('./config/wirezee.whitelabel');
} else if (WHITELABEL == 'emoney') {
	wlcfg = require('./config/emoney.whitelabel');
} else if (WHITELABEL == 'interbanx') {
	wlcfg = require('./config/interbanx.whitelabel');
} else if (WHITELABEL == 'verifiedpayments') {
	wlcfg = require('./config/verifiedpayments.whitelabel');
} else if (WHITELABEL == 'egos') {
	wlcfg = require('./config/egos.whitelabel');
}

export default wlcfg.default;