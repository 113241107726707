import { CLEAR_WALLET_LIST, SET_CURRENT_WALLET, SET_WALLET_LIST_LOADING, SET_WALLET_NEXT_ID, UPDATE_CREATE_WALLET_ID, UPDATE_CREATE_WALLET_SHOW_FORM, UPDATE_CREATE_WALLET_SHOW_SUCCESS, UPDATE_WALLET_LIST } from '~/store/mutation-types';

export default {
	[SET_CURRENT_WALLET](state, walletId) {
		state.currentId = walletId;
	},
	[UPDATE_WALLET_LIST](state, data) {
		state.listLoaded = true;
		state.listLoading = false;
		state.list = data;
	},
	[CLEAR_WALLET_LIST](state) {
		state.listLoaded = false;
		state.listLoading = false;
		state.list = [];
		state.currentId = null;
		state.total = {
			balance: 0,
			currencyId: 'EUR',
		};
		state.currencies = [];
	},
	[SET_WALLET_LIST_LOADING](state) {
		state.listLoading = true;
	},
	[SET_WALLET_NEXT_ID](state, id) {
		state.nextWalletId = id;
	},
	[UPDATE_CREATE_WALLET_ID](state, walletId) {
		state.create.walletId = walletId;
	},
	[UPDATE_CREATE_WALLET_SHOW_FORM](state, status) {
		state.create.showForm = status;
	},
	[UPDATE_CREATE_WALLET_SHOW_SUCCESS](state, status) {
		state.create.showSuccess = status;
	},
};