<template>
	<div id="transfer-layout">
		<div class="page-title-container">
			<h2>Payment Request</h2>
		</div>
		<div>
			<payment-request :transfer-data="transferData" />
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import PaymentRequest from './PaymentRequest.vue';

export default {
	name: 'PaymentRequestLayout',
	components: {
		PaymentRequest,
	},
	props: {
		transferData: {
			type: Object,
			default: () => ({}),
			required: false
		},
		transferType: {
			type: String,
			default: null,
			required: false
		}
	},
	data() {
		return {
			// tab: 0,
		};
	},
	computed: {
		...mapGetters('user', [
			'isGranted',
		]),
		...mapGetters('wallet', [
			'provider'
		]),
	},
};
</script>
