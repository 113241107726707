<template>
	<div>
		<b-list-group>
			<b-list-group-item v-for="(item, i) in list"
				:key="i"
				href="#"
				@click="select(item)"
			>
				Send code by {{ AuthenticatorHelper.name[item.authenticator] }}
			</b-list-group-item>
		</b-list-group>
		<div class="my-4">
			<a :href="`mailto:${config.supportEmail}`"
				target="_blank"
			>Did you lose an access to your device? Contact us.</a>
		</div>
	</div>
</template>
<script>
import AuthenticatorHelper from '~/helpers/authenticator.helper';
import config from '~/config/main';
export default {
	name: 'AuthenticatorSelector',
	data() {
		return {
			AuthenticatorHelper,
			config
		};
	},
	props: {
		list: {
			type: Array,
			default: () => [],
			required: true
		}
	},
	methods: {
		select(value) {
			this.$emit('onSelect', value);
		}
	}
};
</script>
<style lang="scss">
	
</style>