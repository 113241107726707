<template>
	<div 
		id="profile-password-change" 
		class="profile-password-change p-0"
	>
		<div class="page-title-container">
			<h2>Change your password</h2>
		</div>
		<b-card class="mb-4">
			<b-row>
				<b-col
					sm="12"
					md="6"
					lg="6"
				>
					<b-row>
						<b-col>
							<small class="mr-2 d-inline-block">
								Current user:
							</small>
							<small class="user-wrap">
									
								<span>{{ email }}</span>
							</small>
						</b-col>
					</b-row>
				</b-col>
			</b-row>
		</b-card>
		<b-card>
			<notification />
			<b-row>
				<b-col md="6"
					sm="12"
				>
					<form 
				
						autocomplete="disable" 
						@submit.prevent="updatePassword()"
					>
						<fieldset :disabled="submitted || success">
							<b-row>
								<b-col
									sm="12"
								>
									<b-row>
										<b-col>
											<form-error-field name="oldPassword">
												<label>Current password</label>
					
												<b-form-input
													id="current-password"
													v-model="currentPassword"
													v-validate="'required'"
													:class="{'is-invalid' : errors.has('oldPassword')}"
													type="password"
													name="oldPassword"
													placeholder="Current password"
													data-vv-as="Current password"
												/>
											</form-error-field>
										</b-col>
									</b-row>
									<b-row>
										<b-col>
											<form-error-field name="newPassword">
												<label>New password</label>
												<b-form-input
													id="newPassword"
													ref="newPassword"
													v-model="newPassword"
													v-validate="'required|verify_password|min:8'"
													:class="{'is-invalid' : errors.has('newPassword')}"
													type="password"
													name="newPassword"
													placeholder="New password"
													data-vv-as="New password"
												/>
											</form-error-field>
										</b-col>
									</b-row>

									<b-row>
										<b-col>
											<form-error-field name="confirmNewPassword">
												<label>Confirm new password</label>
												<b-form-input
													id="confirm-new-password"
													v-model="confirmNewPassword"
													v-validate="'required|min:8|confirmed:newPassword'"
													:class="{'is-invalid' : errors.has('confirmNewPassword')}"
													type="password"
													name="confirmNewPassword"
													data-vv-as="Confirm new password"
													placeholder="Confirm new password"
												/>
											</form-error-field>
										</b-col>
									</b-row>
								</b-col>
							</b-row>
							<b-row v-if="!(submitted || success)"
								class="form-group mt-4"
							>
								<b-col>
									<b-button variant="primary"
										type="submit"
										class="mr-2"
									>
										Submit
									</b-button>
			
									<b-button variant="outline-primary"
										@click="$router.push('/home')"
									>
										Cancel
									</b-button>
								</b-col>
							</b-row>
						</fieldset>
					</form>
				</b-col>
				<b-col 
					v-if="submitted && !success"
					md="6"
					sm="12" 
				>
					<div 
						class="password-change-confirm"
					>
						<div class="page-title-container text-center">
							<h2>Confirm password change</h2>
						</div>
						<div class="text-center">
							<authenticator
								:show-back-button="true"
								:on-back="handleBack"
								:on-submit="handleCode"
								type="change_password"
							/>
						</div>
					</div>
				</b-col>
			</b-row>
		</b-card>

		<div 
			v-if="success" 
			class="step3"
		>
			<div class="page-title-container">
				<div class="password-change__success">
					<span>Password changed successfully</span>
					<b-button variant="outline-primary"
						@click="$router.push({name: 'home'})"
					>
						Back
					</b-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Authenticator from '@/shared/Authenticator';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import { endpoints } from '~/config';
import FormErrorField from '@/shared/Form/FormErrorField';
import { handleApiFormErrors } from '~/helpers';

export default {
	name: 'ProfilePasswordChange',
	components: {
		Authenticator,
		FormErrorField
	},
	data() {
		return {
			currentPassword: null,
			newPassword: null,
			confirmNewPassword: null,
			code: null,
			submitted: false,
			success: false
		};
	},
	computed: {
		...mapGetters('user', ['email'])
	},
	methods: {
		...mapActions('notifications', [
			'pushError'
		]),
		async updatePassword() {
			const result = await this.$validator.validateAll();
			if (result) {
				const options = {
					method: 'PUT',
					url: endpoints.user.passwordChange,
					data: {
						oldPassword: this.currentPassword,
						newPassword: this.newPassword,
						newPasswordConfirm: this.confirmNewPassword
					}
				};
				try {
					await axios(options);
					this.submitted = true;
				} catch (err) {
					handleApiFormErrors(err, this.errors);
					this.pushError(err.message);
				}
			}
		},
		async handleCode(code) {
			if (code.length == 6) {
				const options = {
					method: 'PUT',
					url: endpoints.user.passwordChangeConfirm,
					data: { code }
				};
				try {
					await axios(options);
					this.success = true;
				} catch (err) {
					this.submitted = false;
					this.pushError(err.message);
				}
			}
		},
		handleBack() {
			this.submitted = false;
		},
		clearForm() {
			this.code = null;
			this.currentPassword = null;
			this.newPassword = null;
			this.confirmNewPassword = null;
		}
	},
};
</script>
<style lang="scss">
	.password-change {
		&__success {
			background-color: #e3f3f4;
			padding: 16px 20px;
			display: flex;
			align-items: center;
			margin-top: 20px;
			border-radius: 3px;
			justify-content: space-between;

			> span {
				display: flex;
				align-items: center;
			}
		}
	}

	.user-wrap {
		background-color: #fff;
		border-radius: 3px;
		display: inline-block;
		padding: 5px 10px;
		box-shadow: 0px 1px 4px 1px rgba(0,0,0,.1);

		span {
			display: inline-block;
		}
	}
</style>