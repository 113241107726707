var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "list-row"
  }, [_c('b-row', {
    staticClass: "align-items-center"
  }, [_c('b-col', {
    attrs: {
      "cols": "auto",
      "md": "2",
      "order-md": "2"
    }
  }, [_c('span', {
    staticClass: "action text-capitalize"
  }, [_vm._v(_vm._s(_vm._f("normalizeActionText")(_vm.tableItem.action)))])]), _c('b-col', {
    staticClass: "ml-auto ml-md-0",
    attrs: {
      "cols": "auto",
      "md": "3",
      "order-md": "1"
    }
  }, [_c('span', {
    staticClass: "date"
  }, [_vm._v(_vm._s(_vm._f("timestampToLocalDateTime")(_vm.tableItem.dateCreated)))])]), _c('b-col', {
    attrs: {
      "md": "2",
      "order-md": "3"
    }
  }, [_c('span', {
    staticClass: "ip"
  }, [_vm._v(_vm._s(_vm.tableItem.ip))])]), _c('b-col', {
    attrs: {
      "md": "3",
      "order-md": "4"
    }
  }, [_c('span', {
    staticClass: "browser"
  }, [_vm._v(_vm._s(_vm.tableItem.browser))])]), _c('b-col', {
    attrs: {
      "md": "2",
      "order-md": "5"
    }
  }, [_c('span', {
    staticClass: "country"
  }, [_vm._v(_vm._s(_vm.getCountry(_vm.tableItem.countryId)))])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }