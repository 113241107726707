var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h4', [_vm._v("Security")]), _c('div', {
    attrs: {
      "role": "tablist"
    }
  }, _vm._l(_vm.dataJson, function (faq, index) {
    return _c('b-card', {
      key: faq.title + faq.key
    }, [_c('b-card-header', {
      staticClass: "accHeader",
      attrs: {
        "header-tag": "header",
        "role": "tab"
      }
    }, [_c('h5', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle",
        value: faq.title + index,
        expression: "faq.title + index"
      }]
    }, [_vm._v(" " + _vm._s(faq.question) + " ")])]), _c('b-collapse', {
      attrs: {
        "id": faq.title + index,
        "visible": "",
        "accordion": "my-accordion",
        "role": "tabpanel"
      }
    }, [_c('b-card-body', {
      staticClass: "inner"
    }, [_c('p', {
      staticClass: "card-text",
      domProps: {
        "innerHTML": _vm._s(faq.text)
      }
    })])], 1)], 1);
  }), 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }