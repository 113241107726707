<template>
	<div v-if="!hide"
		class="info-popup"
	>
		<div>
			<div class="info-popup__close"
				@click="() => close()"
			>
				✖
			</div>
			<p>
				Dear Customer,<br /><br />
				Please be informed that SEPA payments will not be processed
				during the Easter holiday period (29th of March - 1st of April,
				2024). Our office will remain closed on 1st April, 2024.<br />
				For this reason, we won't be available to respond to any
				messages. You can check out our FAQ section
				<a href="https://verifo.zendesk.com/hc/en-us"
					target="_blank"
				>https://verifo.zendesk.com/hc/en-us</a>
				if you have any questions, but rest assured we'll get back to
				you as soon as we return.<br /><br />
				Thank you for your understanding, Verifo Team
			</p>
		</div>
	</div>
</template>
<script>
import moment from 'moment';
export default {
	data() {
		return {
			hide:
                !!localStorage.getItem('hideapril') ||
                moment().diff(moment('2024-04-01')) > 0,
		};
	},
	methods: {
		close() {
			this.hide = true;
			localStorage.setItem('hideapril', 1);
		},
	},
};
</script>

<style lang="scss">
@keyframes popupIn {
    from {
        transform: translateY(-30px);
        opacity: 0;
    }
    to {
        transform: translateY(0px);
        opacity: 1;
    }
}
.info-popup {
    position: fixed;
    bottom: 50px;
    padding: 15px;
    width: 100%;
    z-index: 100;
    display: flex;
    justify-content: center;

    &__close {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
            color: var(--primary);
        }
    }

    & > div {
        padding: 30px 15px 15px;
        background-color: #fff;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
        max-width: 400px;
        animation: popupIn 1s ease;
        position: relative;
        border-bottom: 10px solid var(--warning);
		border-radius: 12px;
		
    }
    p {
        font-size: 12px;
        margin: 0;
    }
}
</style>