var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "transaction-item-container",
    "class": _vm.showCollapse ? 'expanded' : ''
  }, [_c('div', {
    staticClass: "transaction-item",
    "class": _vm.currentItem ? 'transaction-item--selected' : '',
    on: {
      "click": function click($event) {
        return _vm.selectItem(_vm.item);
      }
    }
  }, [_c('b-row', {
    staticClass: "align-items-center",
    attrs: {
      "align-h": "between"
    }
  }, [_c('b-col', [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "avatar d-flex align-items-center justify-content-center"
  }, [!['disabled', 'rejected'].includes(_vm.item.status) ? _c('img', {
    attrs: {
      "src": "images/img/income.svg"
    }
  }) : _vm._e(), ['rejected'].includes(_vm.item.status) ? _c('ion-icon', {
    staticStyle: {
      "color": "red"
    },
    attrs: {
      "name": "close-outline"
    }
  }) : _vm._e()], 1), _c('div', [_c('h4', [_vm._v(" " + _vm._s(_vm.item.id) + " ")]), _c('div', {
    staticClass: "date"
  }, [_vm._v(" " + _vm._s(_vm._f("timestampToLocalDateTime")(_vm.item.dateCreated)) + " ")])])])]), _c('b-col', {
    staticClass: "d-none d-xl-block"
  }, [_c('div', {
    staticClass: "summary"
  }, [_vm._v(" " + _vm._s(_vm.item.name) + " ")])]), _c('b-col', {
    staticClass: "d-none d-xl-block"
  }, [_c('div', {
    staticClass: "summary"
  }, [_vm._v(" " + _vm._s(_vm.item.status) + " ")])]), _c('b-col', {
    staticClass: "d-none d-xl-block"
  }, [_c('div', {
    staticClass: "summary"
  }, [_vm._v(" " + _vm._s(_vm.item.website) + " ")])])], 1), _vm._m(0)], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "current-item-arrow d-none d-xl-flex"
  }, [_c('i', {
    staticClass: "fas fa-arrow-right"
  })]);
}]

export { render, staticRenderFns }