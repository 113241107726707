import {
	UNSET_USER,
	SET_USER,
	SET_USER_LIST,
	UPDATE_PROFILE,
	UPDATE_COMPANY,
	SET_USER_VERIFIED,
	UPDATE_QUESTIONNAIRE,
	SET_USER_PERMISSIONS,
	SET_USER_AUTHENTICATOR,
	SET_USER_AUTHENTICATOR_LIST,
	SET_USER_CUSTOMER_AGREEMENT,
	SET_USER_POPUPS,
	SET_ODD_INCOMPLETE,
	SET_WALLET_PERMISSIONS
} from '~/store/mutation-types';

export default {
	[SET_USER](state, user) {
		state.selected = user;
		state.oddIncomplete = false;
	},
	[SET_USER_LIST](state, list) {
		state.list = list;
	},
	[SET_USER_PERMISSIONS](state, permissions) {
		state.permissions = permissions;
	},
	[SET_WALLET_PERMISSIONS](state, permissions) {
		state.walletPermissions = permissions;
	},
	[SET_USER_AUTHENTICATOR](state, value) {
		state.authenticator.type = value;
	},
	[SET_USER_AUTHENTICATOR_LIST](state, list) {
		state.authenticator.list = list;
	},
	[UNSET_USER](state) {
		state.list = [];
		state.selected = null;
		state.permissions = [];
	},
	[UPDATE_PROFILE](state, data) {
		state.selected.userProfile = { 
			...state.selected.userProfile,
			...data.userProfile
		};
	},	
	[UPDATE_COMPANY](state, data) {
		state.selected.companyProfile = {
			...state.selected.companyProfile,
			...data
		};
	},
	[SET_USER_VERIFIED](state, data) {
		state.selected = {
			...state.selected,
			verified: data
		};
	},
	[UPDATE_QUESTIONNAIRE](state, value) {
		if (state && state.selected) {
			state.selected.questionnaire = {
				...state.selected.questionnaire,
				...value
			};
		}
	},
	[SET_USER_CUSTOMER_AGREEMENT](state, value) {
		state.customerAgreement = {
			...state.customerAgreement,
			...value
		};
	},
	[SET_USER_POPUPS](state, value) {
		state.popups = value;
	},
	[SET_ODD_INCOMPLETE](state, value) {
		state.oddIncomplete = value;
	}
};