var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "bulk-payments"
  }, [_vm.step == 'fileSelection' ? _c('div', [_c('b-row', {
    staticClass: "mb-4",
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', [_c('small', {
    staticClass: "notes text-wrap"
  }, [_vm._v("CSV file for bulk payments must contain the name of the beneficiary, IBAN, currency, amount, and description. We recommend using the example.csv file as a template. Please note that only EUR payments are currently supported.")])]), _c('b-col', {
    staticClass: "text-md-right text-center",
    attrs: {
      "md": "auto"
    }
  }, [_c('b-button', {
    staticClass: "my-3 text-nowrap",
    attrs: {
      "size": "sm",
      "variant": "outline-primary",
      "href": "/files/example.csv"
    }
  }, [_c('i', {
    staticClass: "fas fa-download mr-3"
  }), _vm._v("Download example.csv ")])], 1)], 1), !_vm.files.loading ? _c('b-row', {
    staticClass: "mb-4"
  }, [_c('b-col', [_vm.isWalletGranted('initiate_transaction', _vm.currentWallet.walletId) ? _c('file-upload', {
    ref: "file-upload",
    attrs: {
      "accept": '.csv',
      "expandable": "",
      "placeholder": "Press to choose CSV file or drop it here"
    },
    on: {
      "onChange": _vm.uploadFile
    }
  }) : _vm._e()], 1)], 1) : _vm._e(), _vm.files.loading ? _c('b-card', {
    staticClass: "file-item text-center mt-3"
  }, [_c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "mb-3"
  }, _vm._l(_vm.files.rows, function (file) {
    return _c('b-card', {
      key: file.id,
      staticClass: "file-item mt-3",
      "class": {
        'file-item--deleted': file.status == 'deleted'
      },
      on: {
        "click": function click($event) {
          return _vm.openFile(file);
        }
      }
    }, [_c('b-row', {
      attrs: {
        "align-v": "center"
      }
    }, [_c('b-col', {
      staticClass: "text-sm-left text-center",
      attrs: {
        "md": "10",
        "cols": "12"
      }
    }, [_c('b-row', {
      attrs: {
        "align-v": "center"
      }
    }, [_c('b-col', {
      staticClass: "py-1",
      attrs: {
        "sm": "5",
        "cols": "12"
      }
    }, [_vm._v(" " + _vm._s(_vm.moment(file.dateCreated).format('YYYY-MM-DD HH:mm')) + " ")]), _c('b-col', {
      staticClass: "py-1",
      attrs: {
        "sm": "7",
        "cols": "12"
      }
    }, [_c('b-badge', {
      staticClass: "file-item__filename mr-2",
      attrs: {
        "pill": "",
        "variant": _vm.statusVariant('file', file.status)
      }
    }, [_c('i', {
      "class": _vm.statusVariant('icon', file.status)
    })]), _c('span', [_vm._v(_vm._s(file.fileName))])], 1)], 1)], 1), _c('b-col', {
      staticClass: "text-center text-sm-right my-sm-0 my-3"
    }, [_c('b-button', {
      staticClass: "delete-button",
      attrs: {
        "disabled": !_vm.isWalletGranted('initiate_transaction', _vm.currentWallet.walletId) || !['created', 'imported'].includes(file.status),
        "size": "sm",
        "variant": "outline-primary"
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          if ($event.target !== $event.currentTarget) { return null; }
          return _vm.deleteFile(file);
        }
      }
    }, [_c('i', {
      staticClass: "fas fa-trash-alt"
    }), _vm._v(" Delete ")])], 1)], 1)], 1);
  }), 1), !_vm.files.loading && _vm.files.rows && _vm.files.rows.length > 0 ? _c('div', {
    staticClass: "mb-3 text-sm-left text-center"
  }, [_vm._m(0), _c('b-badge', {
    staticClass: "mr-1 status-badge my-1",
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(" Imported ")]), _c('b-badge', {
    staticClass: "mr-1 status-badge my-1",
    attrs: {
      "variant": "warning"
    }
  }, [_vm._v(" Processing ")]), _c('b-badge', {
    staticClass: "mr-1 status-badge my-1",
    attrs: {
      "variant": "success"
    }
  }, [_vm._v(" Processed ")]), _c('b-badge', {
    staticClass: "status-badge my-1",
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v(" Deleted ")])], 1) : _vm._e(), _vm.files.pages > 1 ? _c('pagination', {
    attrs: {
      "total": _vm.files.count,
      "per-page": _vm.filterPerPage,
      "current-page": _vm.files.currentPage
    },
    on: {
      "pagechanged": _vm.getFileList
    }
  }) : _vm._e()], 1) : _vm._e(), _vm.step == 'transferSelection' ? _c('div', [_c('div', {
    staticClass: "bulk-items mt-3"
  }, [_c('b-row', {
    staticClass: "mb-3",
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "9",
      "lg": "9",
      "xl": "9"
    }
  }), _c('b-col', {
    staticClass: "mt-3 mt-lg-0 mt-sm-0 mt-xl-0",
    attrs: {
      "sm": "12",
      "md": "3",
      "lg": "3",
      "xl": "3"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('p', {
    staticClass: "mr-3 mb-0 text-muted align-self-center text-nowrap items-per-page-label"
  }, [_vm._v(" Items "), _c('br'), _vm._v(" per page ")]), _c('custom-select', {
    attrs: {
      "items": _vm.pages,
      "hide-front": false,
      "modify-bg": true
    },
    on: {
      "select": function select($event) {
        return _vm.filterPages($event);
      }
    }
  })], 1)]), _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "text-center text-md-left mt-3 mt-lg-0 mt-sm-0 mt-xl-0"
  }, [_c('b-badge', {
    staticClass: "file-item__filename mr-2",
    attrs: {
      "pill": "",
      "variant": _vm.statusVariant('file', _vm.selectedFile.status)
    }
  }, [_c('i', {
    "class": _vm.statusVariant('icon', _vm.selectedFile.status)
  })]), _c('span', [_vm._v(_vm._s(_vm.selectedFile.fileName))]), _vm.isWalletGranted('initiate_transaction', _vm.currentWallet.walletId) && _vm.selectedFile.status === 'imported' ? _c('b-button', {
    staticClass: "ml-3",
    attrs: {
      "size": "sm",
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        if ($event.target !== $event.currentTarget) { return null; }
        return _vm.deleteFile(_vm.selectedFile);
      }
    }
  }, [_c('i', {
    staticClass: "fas fa-trash-alt"
  }), _vm._v(" Delete ")]) : _vm._e()], 1)]), _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_vm.items.rows && _vm.items.rows.length > 0 && _vm.selectedFile.status === 'imported' && _vm.isWalletGranted('initiate_transaction', _vm.currentWallet.walletId) ? _c('div', {
    staticClass: "text-center text-md-right mt-3 mt-lg-3 mt-sm-3 mt-xl-3"
  }, [_c('button', {
    staticClass: "btn btn-primary mb-3",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.selectAll(true);
      }
    }
  }, [_vm._v(" Select all ")]), _c('button', {
    staticClass: "btn btn-outline-primary mb-3 ml-3",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.selectAll(false);
      }
    }
  }, [_vm._v(" Deselect all ")])]) : _vm._e()])], 1), _vm.items.loading ? _c('b-card', {
    staticClass: "bulk-item text-center"
  }, [_c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  })], 1) : _vm._e(), _vm._l(_vm.items.rows, function (item) {
    return _c('b-card', {
      key: item.id,
      staticClass: "mb-3 bulk-item",
      "class": "".concat(_vm.isItemSelected(item) ? 'bulk-item--selected' : '', " ").concat(item.status !== 'created' ? 'bulk-item--completed' : ''),
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.selectItem(item);
        }
      }
    }, [_c('b-row', [_vm.selectedFile.status == 'imported' ? _c('b-col', {
      staticClass: "check",
      attrs: {
        "md": "1",
        "cols": "12"
      }
    }, [_c('div', [!_vm.isItemSelected(item) ? _c('i', {
      staticClass: "far fa-square"
    }) : _c('i', {
      staticClass: "far fa-check-square"
    })])]) : _vm._e(), _c('b-col', {
      attrs: {
        "md": "3",
        "cols": "12"
      }
    }, [_c('small', {
      staticClass: "item-label"
    }, [_vm._v("Beneficiary")]), _c('div', [_vm._v(_vm._s(item.beneficiary.accountHolder))])]), _c('b-col', {
      attrs: {
        "md": "3",
        "cols": "12"
      }
    }, [_c('small', {
      staticClass: "item-label"
    }, [_vm._v("Account Number")]), _c('div', [_vm._v(_vm._s(item.beneficiary.accountNumber))])]), _c('b-col', {
      attrs: {
        "md": "2",
        "cols": "12"
      }
    }, [_c('small', {
      staticClass: "item-label"
    }, [_vm._v("Amount")]), _c('div', {
      staticClass: "amount-label"
    }, [_c('b-badge', {
      staticClass: "amount-badge",
      attrs: {
        "variant": _vm.statusVariant('item', item.status)
      }
    }, [_vm._v(" " + _vm._s(item.amount)), _c('small', [_vm._v(_vm._s(item.currencyId))])])], 1)]), _c('b-col', {
      attrs: {
        "md": _vm.selectedFile.status == 'imported' ? 3 : 4,
        "cols": "12"
      }
    }, [_c('small', {
      staticClass: "item-label"
    }, [_vm._v("Details")]), _c('div', [_vm._v(_vm._s(item.details ? item.details : '-'))])])], 1)], 1);
  }), !_vm.items.loading && _vm.items.rows && _vm.items.rows.length > 0 ? _c('div', {
    staticClass: "mb-3 text-sm-left text-center"
  }, [_vm._m(1), _c('b-badge', {
    staticClass: "mr-1 status-badge my-1",
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(" Ready ")]), _c('b-badge', {
    staticClass: "mr-1 status-badge my-1",
    attrs: {
      "variant": "info"
    }
  }, [_vm._v(" Skipped ")]), _c('b-badge', {
    staticClass: "mr-1 status-badge my-1",
    attrs: {
      "variant": "success"
    }
  }, [_vm._v(" Processed ")]), _c('b-badge', {
    staticClass: "status-badge my-1",
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v(" Failed or deleted ")])], 1) : _vm._e(), _c('div', {
    staticClass: "mb-3"
  }, [_vm.items.pages > 1 ? _c('pagination', {
    attrs: {
      "total": _vm.items.count,
      "per-page": _vm.filterPerPage,
      "current-page": _vm.items.currentPage
    },
    on: {
      "pagechanged": _vm.getTransferList
    }
  }) : _vm._e()], 1)], 2), _vm.items.rows && _vm.items.rows.length > 0 ? _c('div', {
    staticClass: "text-center"
  }, [_c('button', {
    staticClass: "btn btn-outline-primary",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        _vm.step = 'fileSelection';
      }
    }
  }, [_vm._v(" Back ")]), _vm.isWalletGranted('approve_transaction', _vm.currentWallet.walletId) && _vm.selectedFile.status === 'imported' ? _c('button', {
    staticClass: "btn btn-primary ml-3",
    attrs: {
      "disabled": _vm.selectedItems.length == 0
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        _vm.step = 'confirmation';
      }
    }
  }, [_vm._v(" Next ")]) : _vm._e(), _vm.isGranted('front_payment_file_delete_row') ? _c('button', {
    staticClass: "btn btn-danger ml-3",
    attrs: {
      "disabled": _vm.selectedItems.length == 0
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.deleteSelected.apply(null, arguments);
      }
    }
  }, [_vm._v(" Remove ")]) : _vm._e()]) : _vm._e()]) : _vm._e(), _vm.step == 'confirmation' || _vm.step == 'transfer' ? _c('div', {
    staticClass: "selected-items"
  }, [_c('b-card', [_vm._l(_vm.selectedItems, function (item) {
    return _c('b-row', {
      key: item.id,
      staticClass: "selected-item"
    }, [_c('b-col', {
      attrs: {
        "md": "3",
        "sm": "12"
      }
    }, [_c('small', {
      staticClass: "item-label"
    }, [_vm._v("Beneficiary")]), _c('div', [_vm._v(_vm._s(item.beneficiary.accountHolder))])]), _c('b-col', {
      attrs: {
        "md": "3",
        "sm": "12"
      }
    }, [_c('small', {
      staticClass: "item-label"
    }, [_vm._v("Account Number")]), _c('div', [_vm._v(_vm._s(item.beneficiary.accountNumber))])]), _c('b-col', {
      attrs: {
        "md": "3",
        "sm": "12"
      }
    }, [_c('small', {
      staticClass: "item-label"
    }, [_vm._v("Amount")]), _c('div', [_vm._v(_vm._s(item.amount)), _c('small', [_vm._v(_vm._s(item.currencyId))])])]), _c('b-col', {
      attrs: {
        "md": "3",
        "sm": "12"
      }
    }, [_c('small', {
      staticClass: "item-label"
    }, [_vm._v("Details")]), _c('div', [_vm._v(_vm._s(item.details ? item.details : '-'))])])], 1);
  }), _c('b-row', {
    staticClass: "selected-item",
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('strong', [_vm._v("Number of payments: " + _vm._s(_vm.selectedItems.length))])]), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('strong', [_vm._v("Total: " + _vm._s(_vm.selectedTotal.sum) + _vm._s(_vm.selectedTotal.currency))]), _c('div', [_vm.exceededBalance(_vm.selectedTotal.sum) ? _c('b-badge', {
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v(" Insufficient Funds ")]) : _vm._e()], 1)])], 1)], 2), _vm.step == 'confirmation' ? _c('div', {
    staticClass: "text-center mt-3"
  }, [_c('button', {
    staticClass: "btn btn-outline-primary",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        _vm.step = 'transferSelection';
      }
    }
  }, [_vm._v(" Back ")]), _vm.isWalletGranted('approve_transaction', _vm.currentWallet.walletId) ? _c('button', {
    staticClass: "btn btn-primary ml-3",
    attrs: {
      "disabled": _vm.exceededBalance(_vm.selectedTotal.sum)
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.confirm.apply(null, arguments);
      }
    }
  }, [_vm._v(" Confirm ")]) : _vm._e()]) : _vm._e(), _vm.step == 'transfer' ? _c('div', {
    staticClass: "transfer"
  }, [_c('b-card', {
    staticClass: "mt-3"
  }, [_c('authenticator', {
    ref: "authenticator",
    attrs: {
      "show-back-button": false,
      "on-submit": _vm.transfer,
      "type": "transfer",
      "on-resend": _vm.confirm
    }
  })], 1), _c('div', {
    staticClass: "text-center mt-3"
  }, [_c('button', {
    staticClass: "btn btn-outline-primary",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        _vm.step = 'confirmation';
      }
    }
  }, [_vm._v(" Back ")])])], 1) : _vm._e()], 1) : _vm._e(), _vm.step == 'success' ? _c('div', [_c('b-card', [_vm._v(" Transfer from file " + _vm._s(_vm.successData ? "".concat(_vm.successData.fileName, " ") : '') + "successful. "), _c('button', {
    staticClass: "btn btn-primary ml-3",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        _vm.step = 'fileSelection';
      }
    }
  }, [_vm._v(" OK ")])])], 1) : _vm._e(), _vm.step == 'successDelete' ? _c('div', [_c('b-card', [_vm._v(" Transfers deleted successfully "), _c('button', {
    staticClass: "btn btn-primary ml-3",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        _vm.step = 'transferSelection';
      }
    }
  }, [_vm._v(" OK ")])])], 1) : _vm._e()]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('span', [_vm._v("Status:")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('span', [_vm._v("Status:")])]);
}]

export { render, staticRenderFns }