import { endpoints } from '~/config';

export default {
	// eslint-disable-next-line no-unused-vars
	async submitBusinessInformation({ state, dispatch }, data) {
		const options = {
			method: 'POST',
			url: endpoints.user.companyDocuments,
			data
		};
		try {
			const response = await axios(options);
			const { data } = response;
			return Promise.resolve(data);
		} catch (err) {
			return Promise.reject(err.message);
		}
	},
};