var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "notifications-wrapper"
  }, _vm._l(_vm.groups, function (g) {
    return _c('notifications', {
      key: g.group,
      attrs: {
        "group": g.group,
        "position": g.position
      }
    });
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }