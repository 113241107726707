<template>
	<div 
		v-if="showSteps()" 
		class="sidebar d-flex align-items-start flex-column"
	>
		<div class="steps-header w-100">
			<b-row class="align-items-center justify-content-between">
				<b-col cols="auto">
					<a 
						href=""
						class="logo" 
						@click.prevent="$router.push({name: 'home'})"
					></a>
				</b-col>
			</b-row>
		</div>
		<div class="steps-wrapper mb-auto w-100">
			<ul class="steps director d-none d-lg-block">
				<li 
					v-for="(step, key, index) in steps" 
					:key="key"
					:class="calculateComplete(key)"
					@click="calculateComplete(key) === 'complete' && $router.push({name: key})"
				>
					<div class="circle">
						{{ (index+1) }}
					</div>
					<div 
						class="steptext" 
						v-html="step"
					></div>
				</li>
			</ul>

			<div 
				v-for="(step, key, index) in steps"
				v-show="calculateComplete(key) === 'next'"
				:key="key"
			>
				<div class="d-lg-none steps-mobile d-flex align-items-center w-100">
					<div class="steps-progress d-flex align-items-center justify-content-center flex-shrink-1">
						<span>{{ (index+1) }} step <!-- of X --></span>
					</div>
					<div class="steps-info w-100">
						<strong>{{ step }}</strong>
						<!--<br><small>Next: [Need to add]</small>-->
					</div>
				</div>
			</div>
		</div>
		<VerificationTimer ref="timer" />
		<div
			v-if="history[0].name !== 'registration'"
			class="back"
		>
			<a 
				class="btn btn-outline-default btn-block" 
				@click="$router.go(-1)"
			><span class="backarrow"></span>Back</a>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import VerificationTimer from '../VerificationTimer/VerificationTimer';
export default {
	name: 'VerificationSteps',
	components: {
		VerificationTimer
	},
	props: {
		steps: {
			type: Object,
			default: () => {}
		}
	},
	computed: {
		...mapGetters('routes', [
			'currentRouteName'
		]),
		...mapState('routes', ['history'])
	},
	watch: {
		currentRouteName() {
			this.$refs.timer?.reset();
		}
	},
	methods: {
		...mapActions('auth', [
			'logout'
		]),
		showSteps() {
			for (let step in this.steps) {
				if (step == this.$router.history.current.name) {
					return true;
				}
			}
			return false;
		},
		calculateComplete(current) {
			let name = 'complete';


			for (let step in this.steps) {
				if (step == this.currentRouteName) {
					name = 'next';
				}

				if (step == current) {
					return name;
				}

				if (step == this.currentRouteName) {
					name = 'step';
				}
			}

			return name;
		}
	}
};
</script>
<style lang="scss" scoped>
	@import './VerificationSteps.scss';
</style>