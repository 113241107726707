var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "export-statements-modal",
      "centered": "",
      "hide-header": "",
      "hide-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hide = _ref.hide;
        return [_c('h5', {
          staticClass: "mb-4"
        }, [_vm._v(" Export statement ")]), _c('statements')];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }