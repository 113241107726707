var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "auth-card"
  }, [!_vm.initialized ? _c('div', [_c('h1', {
    staticClass: "auth-title"
  }, [_vm._v(" Recover account password ")]), _c('p', {
    staticClass: "text-center mb-5"
  }, [_vm._v(" Begin the password reset process by requesting an email to the account email address. You will receive an email with details for completing the reset. ")]), _c('recover-password-form')], 1) : _c('div', [_c('h1', {
    staticClass: "auth-title text-success"
  }, [_vm._v(" Your password reset request has been initialized. ")]), _c('hr'), _vm._m(0)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pt-4"
  }, [_c('p', [_c('strong', [_vm._v("The recovery email was sent to your email.")])]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" Click the link in your email to continue password reset process. ")])]);
}]

export { render, staticRenderFns }