var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "odd-form"
  }, [_c('b-form', [_c('b-row', [_c('b-col', [_c('h2', [_vm._v("Update your personal information")])])], 1), _c('b-row', [_c('b-col', [_c('form-error-field', {
    attrs: {
      "id": "name",
      "name": "name",
      "label": "First name",
      "label-for": "name-input"
    }
  }, [_c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "id": "name-input",
      "data-vv-as": "First name",
      "name": "name",
      "type": "text",
      "placeholder": "First name"
    },
    model: {
      value: _vm.formData.name,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "name", $$v);
      },
      expression: "formData.name"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('form-error-field', {
    attrs: {
      "id": "surname",
      "name": "surname",
      "label": "Last name",
      "label-for": "name-input"
    }
  }, [_c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "id": "surname-input",
      "data-vv-as": "Last name",
      "name": "surname",
      "type": "text",
      "placeholder": "Last name"
    },
    model: {
      value: _vm.formData.surname,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "surname", $$v);
      },
      expression: "formData.surname"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('div', {
    staticClass: "form-group mb-0"
  }, [_c('phone-input', {
    attrs: {
      "label": "Phone number"
    },
    model: {
      value: _vm.formData.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "phone", $$v);
      },
      expression: "formData.phone"
    }
  })], 1)])], 1), _c('b-row', [_c('b-col', [_c('form-error-field', {
    attrs: {
      "id": "email",
      "name": "email",
      "label": "Email",
      "label-for": "email-input"
    }
  }, [_c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'email|required',
      expression: "'email|required'"
    }],
    attrs: {
      "id": "email-input",
      "data-vv-as": "Email",
      "name": "email",
      "type": "text",
      "placeholder": "Email"
    },
    model: {
      value: _vm.formData.email,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "email", $$v);
      },
      expression: "formData.email"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('form-error-field', {
    attrs: {
      "id": "country-for-tax",
      "name": "countryForTax"
    }
  }, [_c('label', {
    attrs: {
      "for": "country-for-tax-purpose"
    }
  }, [_vm._v("Country of residence for tax purpose")]), _c('v-multiselect', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "id": "country-for-tax-purpose",
      "options": _vm.countryOptions,
      "show-labels": false,
      "data-vv-as": "Country for tax purpose",
      "name": "countryForTax",
      "label": "name",
      "placeholder": "Select country for tax purpose",
      "track-by": "countryId",
      "return": "countryId"
    },
    model: {
      value: _vm.formData.countryForTax,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "countryForTax", $$v);
      },
      expression: "formData.countryForTax"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('form-error-field', {
    attrs: {
      "name": "politicalExposure"
    }
  }, [_c('label', {
    attrs: {
      "for": "political-exposure"
    }
  }, [_vm._v("Political exposure")]), _c('v-multiselect', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "id": "political-exposure",
      "options": _vm.options.politicalExposureTypes,
      "show-labels": false,
      "allow-empty": false,
      "data-vv-as": "Political exposure",
      "name": "politicalExposure",
      "label": "name",
      "placeholder": "Select Your political exposure",
      "track-by": "name",
      "return": "value"
    },
    model: {
      value: _vm.formData.politicalExposure,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "politicalExposure", $$v);
      },
      expression: "formData.politicalExposure"
    }
  })], 1)], 1)], 1), _c('multi-address', {
    attrs: {
      "addresses": _vm.formData.addresses
    }
  })], 1), _c('odd-form-footer', _vm._b({}, 'odd-form-footer', _vm.properties, false))], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }