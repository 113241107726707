<template>
	<div class="odd-form">
		<b-form>
			<h2>Update your company information</h2>
			<div class="my-4">
				<small>Verified Payments JSC is a licensed electronic money institution based in Lithuania and according to Lithuanian AML laws, we are legally obliged to perform Ongoing Due Diligence (hereinafter - ODD) procedures on our clients.</small>
			</div>
			<form-error-field name="name"
				label="Company name:"
				label-for="name-input"
			>
				<b-form-input 
					id="name-input"
					v-model="formData.name"
					v-validate="'required|min:1|max:100'"
					data-vv-as="Company name"
					name="name"
					type="text"
					placeholder="Company name"
					aria-describedby="name-input-feedback"
				/>
			</form-error-field>
			<form-error-field name="website"
				label="Official website:"
				label-for="website-input"
			>
				<b-form-input 
					id="website-input"
					v-model="formData.website"
					v-validate="'required|max:200'"
					data-vv-as="Website"
					name="website"
					type="text"
					placeholder="Official website"
					aria-describedby="website-input-feedback"
				/>
			</form-error-field>
			
			<form-error-field
				name="numberOfEmployees"
				label="Number of employees:"
				label-for="employees-input"
			>
				<b-form-input 
					id="employees-input"
					v-model="formData.numberOfEmployees"
					v-validate="'required|min_value:1'"
					data-vv-as="Number of employees"
					name="numberOfEmployees"
					type="number"
					placeholder="Number of employees"
					aria-describedby="employees-input-feedback"
				/>
			</form-error-field>
			<multi-address :addresses="formData.addresses" />
		</b-form>
		<odd-form-footer 
			v-bind="properties"
		/>
	</div>
</template>
<script>
import OddFormFooter from '../OddFormFooter';
import MultiAddress from '@/shared/MultiAddress';
import AddressInput from '@/shared/AddressInput';
import { mapState } from 'vuex';
import FormErrorField from '@/shared/Form/FormErrorField';

export default {
	name: 'ODDCompanyInformation',
	components: {
		OddFormFooter,
		MultiAddress,
		AddressInput,
		FormErrorField
	},
	data() {
		return {
			formData: {
				addresses: { }
			}
		};
	},
	computed: {
		...mapState('user', {
			company: state => state.selected.companyProfile
		}),
		properties() {
			return {
				...this._props, 
				formData: this.formData
			};
		}
	},
	props: {
		onSuccess: {
			type: Function,
			default: () => {},
			required: true
		},
		onFailure: {
			type: Function,
			default: () => {},
		},
		onCancel: {
			type: Function,
			default: () => {}
		},
		submitUrl: {
			type: String,
			default: '',
			required: true
		}
	},
	mounted() {
		if (!this.company) return;
		this.$set(this.formData, 'name', this.company.name);
		this.$set(this.formData, 'website', this.company.contacts?.website);
		this.$set(this.formData, 'numberOfEmployees', this.company.numberOfEmployees);
		this.$set(this.formData, 'addresses', { ...this.formData.addresses, ...this.company.addresses });

	},
};
</script>
<style lang="scss">
	
</style>