import { UPDATE_REMOTE_IDENTITY } from '~/store/mutation-types';
import { endpoints } from '~/config/endpoints';

export default {
	async getIframeUrl({ dispatch }) {
		const options = {
			method: 'GET',
			url: endpoints.identity.userIframeUrl
		};
		try {
			const response = await axios(options);
			const { data } = response;
			dispatch('submitRemoteIdentity', data);
			return Promise.resolve(data);
		} catch (err) {
			return Promise.reject(err.message);
		}
	},
	async publicGetIframeUrl({ dispatch }, hash) {
		const options = {
			method: 'GET',
			url: endpoints.identity.getIframeUrl({ hash })
		};
		try {
			const response = await axios(options);
			const { data } = response;
			dispatch('submitRemoteIdentity', data);
			return Promise.resolve(data);
		} catch(err) {
			return Promise.reject(err.message);
		}
	},
	async checkStatus() {
		const options = {
			method: 'GET',
			url: endpoints.user.current
		};
		try {
			const response = await axios(options);
			const { data } = response;
			return Promise.resolve(data);
		} catch(err) {
			return Promise.reject(err.message);
		}
	},
	// eslint-disable-next-line no-empty-pattern
	async publicCheckStatus({}, hash) {
		const options = {
			method: 'GET',
			url: endpoints.identity.getUnverified({ hash })
		};
		try {
			const response = await axios(options);
			const { data } = response;
			return Promise.resolve(data);
		} catch(err) {
			return Promise.reject(err.message);
		}
	},
	submitRemoteIdentity: ({ commit }, body) => {
		if (body.url) {
			commit(UPDATE_REMOTE_IDENTITY, { data: body });
		}
	},
};