<template>
	<div>
		<div class="recovery-codes">
			<div v-if="loading">
				<b-spinner />
			</div>
			<div v-else>
				<strong
					v-for="code in codes"
					:key="code"
				>{{ code }}<br /></strong>
			</div>
		</div>
		<div class="mt-3">
			<b-button
				variant="primary" 
				class="mx-1" 
				@click="saveToFile(codes.reduce((a, b) => `${a}\n${b}`), 'Recovery Codes')"
			>
				<i class="fas fa-download mr-2"></i> Download
			</b-button>
		</div>
	</div>
</template>
<script>
export default {
	name: 'GoogleRecoveryCodes',
	props: {
		codes: { 
			type: Array,
			default: () => [],
			required: true
		},
		loading: {
			type: Boolean,
			default: false,
			required: false
		}
	},
	created () {
	},
	methods: {
		saveToFile(content, filename) {
			try {
				const fileUrl = window.URL.createObjectURL(new Blob([content], { type: 'text/plain;charset=utf=8' }));
				const fileLink = document.createElement('a');
				fileLink.href = fileUrl;
				fileLink.setAttribute('download', `${filename}.txt`);
				document.body.appendChild(fileLink);
				fileLink.click();
			} catch (err) {
				this.pushError(err);
			}
		}
	}
};
</script>
<style lang="scss">
	.recovery-codes {
		padding: 10px;
		border: 1px solid #ddd;
		border-radius: 5px;
	}
</style>