var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.user && _vm.user.verification !== 'verified' ? _c('div', {
    staticClass: "text-left"
  }, [_vm.user.verification === 'pending' ? _c('b-card', {
    staticClass: "card-verification pending",
    attrs: {
      "border-variant": "warning"
    }
  }, [_c('b-card-text', [_c('b-row', {
    staticClass: "align-items-center"
  }, [_c('b-col', {
    staticClass: "pr-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/img/pending-verification.svg",
      "width": "54",
      "height": "54"
    }
  })]), _c('b-col', [_c('h3', [_vm._v("Pending verification check!")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" You can update your data while verification is pending. ")])]), _c('b-col', {
    staticClass: "ml-sm-auto pt-3 pt-sm-0 text-center text-sm-left",
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('router-link', {
    staticClass: "btn btn-warning",
    attrs: {
      "to": _vm.verificationLink,
      "exact": ""
    }
  }, [_vm._v(" Review your data ")])], 1)], 1)], 1)], 1) : _c('b-card', {
    staticClass: "card-verification not-verified",
    attrs: {
      "border-variant": "danger"
    }
  }, [_c('b-card-text', [_c('b-row', {
    staticClass: "align-items-center"
  }, [_c('b-col', {
    staticClass: "pr-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/img/not-verified.svg",
      "width": "54",
      "height": "54"
    }
  })]), _c('b-col', [_c('h3', [_vm._v("You're not verified yet!")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" Please verify your identity to access all features. ")])]), _c('b-col', {
    staticClass: "ml-sm-auto pt-3 pt-sm-0 text-center text-sm-left",
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('router-link', {
    staticClass: "btn btn-danger",
    attrs: {
      "to": _vm.verificationLink,
      "exact": ""
    }
  }, [_vm._v(" Verify now ")])], 1)], 1)], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }