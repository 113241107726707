<template>
	<div>
		<b-row>
			<b-col>
				<span class="card-order__subtitle">Ordering Card for Account</span>
			</b-col>
		</b-row>
		<b-row>
			<b-col class="card-order__account-info">
				<b-row>
					<b-col md="3">
						Profile:
					</b-col>
					<b-col md="9">
						{{ user.fullName }}
					</b-col>
				</b-row>
				<b-row>
					<b-col md="3">
						Account Name:
					</b-col>
					<b-col md="9">
						{{ currentWallet && (currentWallet.name || currentWallet.walletId) }}
					</b-col>
				</b-row>
				<b-row>
					<b-col md="3">
						IBAN:
					</b-col>
					<b-col
						md="9"
						class="card-order__iban"
					>
						{{ formatIban(currentWallet && currentWallet.iban) }}
					</b-col>
				</b-row>
			</b-col>
		</b-row>
	</div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
export default {
	name: 'CardAccountDetails',
	computed: {
		...mapState('user', {
			user: state => state.selected
		}),
		...mapGetters('wallet', [
			'currentWallet'
		])
	},
	methods: {
		formatIban(iban) {
			if (!iban) return '';
			const arr = iban.match(/.{1,4}/g);
			return arr.join(' ');
		}
	}
};
</script>
<style lang="scss">
	.card-order {
		&__account-info {
			margin-bottom: 50px;
			> .row {
				padding: 10px 0;

				> div {
					border-bottom: 1px solid rgba(0, 0, 0, .03);

					&:first-child {
						font-size: 13px;
						color: #6B7280;
						font-weight: normal;
					}

					font-size: 16px;
					color: #212529;
					font-weight: 500;
				}
			}
		}

		&__iban {
			font-family: Monospace;
		}
		
		&__subtitle {
			color: #6B7280;
			font-weight: 600;
			margin-bottom: 22px;
			display: inline-block;
		}
	}
</style>