var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "odd-form"
  }, [_c('b-form', [_c('b-row', [_c('b-col', [_c('h2', [_vm._v("Update your identity")])])], 1), !_vm.identified ? _c('b-row', [_c('b-col', [_c('div', {
    staticClass: "text-center my-4"
  }, [!_vm.iframeLoaded ? _c('b-spinner') : _vm._e()], 1), _vm.iframeLoaded && _vm.isDesktop ? _c('iframe', {
    staticStyle: {
      "width": "100%",
      "height": "800px",
      "border": "none"
    },
    attrs: {
      "id": "iframe",
      "src": _vm.iframeUrl,
      "data-registration": "iframe",
      "allow": "camera;microphone"
    }
  }) : _vm._e(), _vm.iframeLoaded && !_vm.isDesktop ? _c('div', {
    staticClass: "text-center my-4"
  }, [_c('b-button', {
    staticClass: "mt-4 mb-4",
    attrs: {
      "variant": "primary",
      "href": _vm.iframeUrl,
      "target": "_blank"
    }
  }, [_vm._v(" Verify documents and photo ")])], 1) : _vm._e()])], 1) : _vm._e(), _vm.identified ? _c('b-row', [_c('b-col', {
    staticClass: "text-center my-4"
  }, [_c('b-badge', {
    attrs: {
      "variant": "success"
    }
  }, [_vm._v(" Identification complete ")])], 1)], 1) : _vm._e()], 1), _c('odd-form-footer', _vm._b({
    ref: "footer",
    attrs: {
      "skip-submit": true,
      "cancel-text": "I will do this later",
      "submit-text": "Continue",
      "submit-hidden": !_vm.identified,
      "cancel-hidden": _vm.identified
    }
  }, 'odd-form-footer', _vm.properties, false))], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }