var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "profile-accounts"
    }
  }, [_c('div', [_c('modal', {
    attrs: {
      "width": '40%',
      "height": '275px',
      "name": "deleteAcc"
    }
  }, [_c('div', {
    staticClass: "faq-modal"
  }, [_c('span', {
    staticClass: "close-btn",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('deleteAcc');
      }
    }
  }, [_vm._v("×")]), _c('div', {
    staticClass: "modalw-content"
  }, [_c('p', [_vm._v("Sorry, you can only delete an empty account!")]), _c('div', {
    staticClass: "text-center buttons"
  }, [_c('button', {
    staticClass: "white text-uppercase",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('deleteAcc');
      }
    }
  }, [_vm._v(" Cancel ")]), _c('button', {
    staticClass: "text-uppercase",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('deleteAcc');
      }
    }
  }, [_vm._v(" Transfer funds ")])])])])])], 1), _c('div', {
    staticClass: "account-settings-list"
  }, _vm._l(_vm.walletList, function (account) {
    return _c('account-wallet-item', {
      key: account.wallet_id,
      attrs: {
        "total": _vm.walletList.length,
        "data": account
      }
    });
  }), 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }