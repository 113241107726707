<template>
	<div class="faq-nav">
		<router-link 
			active-class="active-item" 
			to="/faq/gettingstarted" 
			@click.native="clearSearch"
		>
			Getting Started
		</router-link>
		<router-link 
			active-class="active-item" 
			to="/faq/account" 
			@click.native="clearSearch"
		>
			Account
		</router-link>
		<router-link 
			active-class="active-item" 
			to="/faq/profile" 
			@click.native="clearSearch"
		>
			Profile
		</router-link>
		<router-link 
			active-class="active-item" 
			to="/faq/topup" 
			@click.native="clearSearch"
		>
			Top Up
		</router-link>
		<!--            <router-link @click.native="clearSearch" active-class="active-item"  to="/faq/exchange">EXCHANGE</router-link>-->
		<router-link 
			active-class="active-item" 
			to="/faq/security" 
			@click.native="clearSearch"
		>
			Security
		</router-link>
		<router-link 
			active-class="active-item" 
			to="/faq/banktransfer" 
			@click.native="clearSearch"
		>
			Bank Transfer
		</router-link>
	</div>
</template>

<script>
export default {
	name: 'FaqNav',
	props: {
		clickCallback: {
			type: Function,
			default: undefined,
		},
	},
	methods: {
		clearSearch() {
			if (typeof this.clickCallback !== 'undefined') {
				this.clickCallback();
			}
		}
	}
};
</script>