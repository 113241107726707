var render = function () {
  var _vm$edittablePerson, _vm$edittablePerson$t;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "odd-form"
  }, [_c('b-form', [_c('b-row', [_c('b-col', [_c('h2', [_vm._v("Update your company relation.")])])], 1), _c('b-card', {
    staticClass: "mb-4"
  }, [_vm.persons.length > 0 ? _c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', [_vm._v("Key persons:")])], 1) : _vm._e(), _vm._l(_vm.filteredPersons, function (person, index) {
    var _person$types, _person$types2;

    return _c('b-row', {
      key: index,
      staticClass: "mb-4"
    }, [_c('b-col', [_c('div', {
      staticClass: "odd-person d-flex justify-content-between"
    }, [_c('div', {
      staticClass: "d-flex"
    }, [_c('b-avatar', {
      staticClass: "mr-2",
      attrs: {
        "variant": "primary",
        "text": person.name ? person.name.charAt(0) : ''
      }
    }), _c('div', [_c('div', [_vm._v(_vm._s(person.name) + " " + _vm._s(person.surname))]), _c('div', {
      staticClass: "odd-person__type"
    }, [_vm._v(" " + _vm._s((_person$types = person.types) === null || _person$types === void 0 ? void 0 : _person$types.join(', ')) + " ")])])], 1), _c('div', {
      staticClass: "odd-person__buttons d-flex"
    }, [person._new ? _c('b-button', {
      staticClass: "mx-1",
      attrs: {
        "variant": "primary",
        "size": "sm"
      },
      on: {
        "click": function click($event) {
          return _vm.edit(person);
        }
      }
    }, [_c('i', {
      staticClass: "fas fa-user-edit"
    })]) : _vm._e(), !((_person$types2 = person.types) !== null && _person$types2 !== void 0 && _person$types2.includes('owner')) ? _c('b-button', {
      staticClass: "mx-1",
      attrs: {
        "variant": "danger",
        "size": "sm"
      },
      on: {
        "click": function click($event) {
          return _vm.remove(index);
        }
      }
    }, [_c('i', {
      staticClass: "fas fa-user-minus"
    })]) : _vm._e()], 1)])])], 1);
  }), _c('b-row', [_c('b-col', [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.add();
      }
    }
  }, [_vm._v(" Add person ")])], 1)], 1)], 2), _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.edittablePerson ? _c('div', [_c('b-row', {
    staticClass: "mb-4"
  }, [_c('b-col', [_c('b-card', [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "person-types"
    }
  }, [_vm._v(" Select person types ")]), _c('v-multiselect', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "options": _vm.types,
      "close-on-select": false,
      "clear-on-select": false,
      "label": "name",
      "track-by": "name",
      "return": "value",
      "name": "person-types",
      "placeholder": "Select person types",
      "multiple": true
    },
    model: {
      value: _vm.edittablePerson.types,
      callback: function callback($$v) {
        _vm.$set(_vm.edittablePerson, "types", $$v);
      },
      expression: "edittablePerson.types"
    }
  })], 1)], 1)], 1)], 1), ((_vm$edittablePerson = _vm.edittablePerson) === null || _vm$edittablePerson === void 0 ? void 0 : (_vm$edittablePerson$t = _vm$edittablePerson.types) === null || _vm$edittablePerson$t === void 0 ? void 0 : _vm$edittablePerson$t.length) > 0 ? _c('b-row', [_c('b-col', [_c('b-card', [_c('person-input', {
    ref: "person",
    attrs: {
      "person-type-options": _vm.edittablePerson.types,
      "full-width": "",
      "allowtypes": "",
      "proof-of-residence": "",
      "i-am": false,
      "field-prefix": "keyPersons"
    },
    model: {
      value: _vm.edittablePerson,
      callback: function callback($$v) {
        _vm.edittablePerson = $$v;
      },
      expression: "edittablePerson"
    }
  }), _c('div', {
    staticClass: "mt-4"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.save();
      }
    }
  }, [_vm._v(" Save person ")]), _c('b-button', {
    staticClass: "ml-2",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.cancel();
      }
    }
  }, [_vm._v(" Cancel ")])], 1)], 1)], 1)], 1) : _vm._e()], 1) : _vm._e()]), _c('div', [_c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    "class": {
      'is-invalid': _vm.errors.has('All types included')
    },
    attrs: {
      "hidden": "",
      "value": _vm.allTypesIncluded,
      "name": "All types included"
    }
  }), _vm.errors.has('All types included') ? _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" You must include all " + _vm._s(_vm.types.length) + " types (" + _vm._s(_vm.types.map(function (t) {
    return t.name;
  }).join(', ')) + ") for key persons. ")]) : _vm._e()], 1)], 1), _c('odd-form-footer', _vm._b({}, 'odd-form-footer', _vm.properties, false)), _c('modal', {
    attrs: {
      "adaptive": true,
      "name": "phone",
      "height": "auto"
    }
  }, [_c('div', {
    staticClass: "close",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('phone');
      }
    }
  }), _c('div', {
    staticClass: "ml-4 mr-4 mt-4 mb-4"
  }, [_vm._v(" Person will receive SMS to this phone number to verify their identity. ")])]), _c('modal', {
    attrs: {
      "adaptive": true,
      "name": "political_exposure",
      "height": "auto"
    }
  }, [_c('div', {
    staticClass: "close",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('political_exposure');
      }
    }
  }), _c('div', {
    staticClass: "ml-4 mr-4 mt-4 mb-4"
  }, [_vm._v(" Politically exposed person – private individual who is or has been entrusted with prominent public functions mentioned below including the functions in the Republic of Lithuania, European Union, international or foreign state institutions no more than one year ago, and immediate family members or persons known to be close associates of such person: "), _c('br'), _c('br'), _vm._v("1) Head of State, Heads of Government, minister, vice minister and deputy minister, secretary of the State, chancellor of Parliament, Government or Ministry; 2) Member of parliament; "), _c('br'), _vm._v("3) Member of the Supreme Court, of the Constitutional Court or of other high-level judicial bodies whose decisions are not subject to further appeal; "), _c('br'), _vm._v("4) Member of the managing body of the Lithuanian Chamber of Auditors or of the board of the central bank; "), _c('br'), _vm._v("5) The ambassador, chargés d’affaires and high-ranking officer in the armed forces; "), _c('br'), _vm._v("6) Member of the management or supervisory body of the publicly administered undertaking; "), _c('br'), _vm._v("7) Director, deputy director or member of the management or supervisory body of the international intergovernmental organization; :sunglasses: Head, deputy head or member of the management body of political party. ")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }