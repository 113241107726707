var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pages d-lg-flex justify-content-center",
    "class": {
      'd-none': _vm.totalPages === 0
    }
  }, [_c('img', {
    "class": {
      'disabled-page': _vm.isInFirstPage
    },
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "disabled": _vm.isInFirstPage,
      "aria-label": "Go to previous page",
      "src": "images/img/Chevrron left.svg",
      "alt": ""
    },
    on: {
      "click": _vm.onClickPreviousPage
    }
  }), _vm._l(_vm.pages, function (page) {
    return _c('p', {
      key: page.name,
      staticClass: "page",
      "class": {
        'active-page': _vm.isPageActive(page.name),
        'd-none d-lg-block': !_vm.isPageActive(page.name)
      },
      attrs: {
        "disabled": page.isDisabled,
        "aria-label": "Go to page number ".concat(page.name)
      },
      on: {
        "click": function click($event) {
          return _vm.onClickPage(page.name);
        }
      }
    }, [_vm._v(" " + _vm._s(page.name) + " ")]);
  }), _c('img', {
    "class": {
      'disabled-page': _vm.isInLastPage
    },
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "disabled": _vm.isInLastPage,
      "aria-label": "Go to next page",
      "src": "images/img/Chevrron right.svg",
      "alt": ""
    },
    on: {
      "click": _vm.onClickNextPage
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }