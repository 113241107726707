import Register from '@/LayoutAuthentication/Register/Register.vue';
import RegisterWizard from '@/LayoutAuthentication/Register/RegisterWizard.vue';

export default [
	{
		path: 'register',
		name: 'register',
		component: Register,
	},
	{
		path: 'registration',
		name: 'registration',
		component: RegisterWizard
	},
];