export default [
	{
		name: 'companyName',
		type: 'text',
		label: 'Beneficiary',
		validation: 'min:3',
		required: true,
	},
	{
		name: 'accountNumber',
		type: 'text',
		label: 'Account number (IBAN)',
		validation: 'min:6',
		required: true,
	},
	{
		name: 'countryId',
		type: 'countrySelector',
		label: 'Country',
		placeholder: 'Select bank country',
		required: true,
		hidden: true,
	},
	{
		name: 'bic',
		type: 'text',
		label: 'BIC/SWIFT code',
		required: true,
		hidden: true
	},
	{
		name: 'sortCode',
		type: 'text',
		label: 'Sort code',
		required: true,
		hidden: true
	},
	{
		name: 'beneficiaryAddress',
		type: 'text',
		label: 'Beneficiary address',
		required: true,
		hidden: true
	},
	{
		name: 'bankAddress',
		type: 'text',
		label: 'Bank address',
		required: true,
		hidden: true
	},
	{
		name: 'bankName',
		type: 'text',
		label: 'Bank name',
		required: true,
		hidden: true
	},
	{
		name: 'bankIdentifier',
		type: 'text',
		label: 'Bank identifier',
		required: true,
		hidden: true
	},
	{
		name: 'aba',
		type: 'text',
		label: 'Aba routing code',
		required: true,
		hidden: true
	},
	{
		name: 'bankCode',
		type: 'text',
		label: 'Bank code',
		required: true,
		hidden: true
	},
	{
		name: 'branchCode',
		type: 'text',
		label: 'Branch code',
		required: true,
		hidden: true
	},
	{
		name: 'bsbCode',
		type: 'text',
		label: 'BSB code',
		required: true,
		hidden: true
	},
	{
		name: 'state',
		type: 'text',
		label: 'State',
		required: true,
		hidden: true
	},
	{
		name: 'city',
		type: 'text',
		label: 'City',
		required: true,
		hidden: true
	},
	{
		name: 'address',
		type: 'text',
		label: 'Address',
		required: true,
		hidden: true
	},
	{
		name: 'postcode',
		type: 'text',
		label: 'Postcode/zipcode',
		required: true,
		hidden: true
	},
	{
		name: 'amount',
		type: 'amount',
		label: 'Amount',
		validation: 'min_value:0.01',
		placeholder: '0.00',
		required: true,
		currencyKey: 'currencyId',
		currencyName: 'currency',
		currencyPlaceholder: 'Select currency',
	},
	{
		name: 'details',
		type: 'textarea',
		label: 'Payment details',
		required: true,
		maxLength: 124
	},
	{
		name: 'files',
		type: 'file',
		label: 'Files',
		multiple: true,
		addMoreButton: true,
		fileList: true
	},
	{
		name: 'changeTemplate',
		type: 'checkbox',
		label: 'Add to my templates',
		required: false,
	},
	{
		name: 'templateName',
		type: 'text',
		label: 'Template name',
		required: true,
		hidden: true,
		maxLength: 128
	},
];