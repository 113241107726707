var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "multi-address",
    attrs: {
      "data-vv-scope": _vm.scope
    }
  }, [_c('div', [_c('div', {
    staticClass: "form-group mb-0"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.label) + " ")])]), _c('address-input', {
    attrs: {
      "scope": _vm.scope,
      "type": "addresses.main",
      "field-prefix": _vm.fieldPrefix
    },
    model: {
      value: _vm.value.main,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "main", $$v);
      },
      expression: "value.main"
    }
  })], 1), _c('b-row', [_c('b-col', [_c('b-form-group', {
    staticClass: "text-left mb-3"
  }, [_c('label', [_vm._v(" Correspondence address ")]), _c('div', {
    staticClass: "custom-control custom-checkbox"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.useAddressForCorrespondence,
      expression: "useAddressForCorrespondence"
    }],
    staticClass: "custom-control-input",
    attrs: {
      "id": "same-address-".concat(_vm.id),
      "type": "checkbox",
      "name": "Same address ".concat(_vm.id)
    },
    domProps: {
      "checked": Array.isArray(_vm.useAddressForCorrespondence) ? _vm._i(_vm.useAddressForCorrespondence, null) > -1 : _vm.useAddressForCorrespondence
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.useAddressForCorrespondence,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.useAddressForCorrespondence = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.useAddressForCorrespondence = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.useAddressForCorrespondence = $$c;
        }
      }
    }
  }), _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": "same-address-".concat(_vm.id)
    }
  }, [_c('span', [_vm._v("The same as registration address")])])])])], 1)], 1), _c('transition', {
    attrs: {
      "name": "fade",
      "mode": "out-in"
    }
  }, [!_vm.useAddressForCorrespondence ? _c('div', [_c('address-input', {
    attrs: {
      "scope": _vm.scope,
      "type": "addresses.correspondence",
      "field-prefix": _vm.fieldPrefix
    },
    model: {
      value: _vm.value.correspondence,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "correspondence", $$v);
      },
      expression: "value.correspondence"
    }
  })], 1) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }