var render = function () {
  var _vm$selectedPropertie;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "authenticator"
  }, [_c('form', {
    staticClass: "form-body",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_vm.error ? _c('div', {
    staticClass: "alert alert-danger",
    on: {
      "click": function click($event) {
        return _vm.hideErrorNotification();
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.error))])]) : _vm._e(), _c('b-form-group', {
    staticClass: "text-center"
  }, [_vm.title ? _c('label', {
    staticClass: "d-block",
    attrs: {
      "for": "code"
    }
  }, [_vm._v(_vm._s(_vm.title))]) : _c('label', {
    staticClass: "d-block",
    attrs: {
      "for": "code"
    }
  }, [_vm._v("Code from " + _vm._s(_vm.AuthenticatorHelper.name[_vm.currentAuthenticator]) + " "), _vm.phoneNumberUsed && (_vm$selectedPropertie = _vm.selectedProperties) !== null && _vm$selectedPropertie !== void 0 && _vm$selectedPropertie.phoneNumber ? _c('span', [_vm._v(" sent to " + _vm._s(_vm.selectedProperties.phoneNumber))]) : _vm._e()]), _c('CodeInput', {
    ref: "code",
    attrs: {
      "name": "code",
      "numbers-only": _vm.phoneNumberUsed,
      "digits": 6,
      "disabled": _vm.verifying
    },
    model: {
      value: _vm.code,
      callback: function callback($$v) {
        _vm.code = $$v;
      },
      expression: "code"
    }
  })], 1), _vm.type ? [_c('p', {
    staticClass: "text-center text-muted"
  }, [!_vm.resending ? [!_vm.showSelector ? _c('div', [_vm.phoneNumberUsed ? _c('span', [_vm._v("Did not get " + _vm._s(_vm.AuthenticatorHelper.name[_vm.currentAuthenticator]) + " with confirmation code?")]) : _vm._e(), !_vm.phoneNumberUsed ? _c('span', [_vm._v("Code did not work?")]) : _vm._e(), !_vm.hideResend ? _c('b-link', {
    staticClass: "ml-1",
    on: {
      "click": _vm.Resend
    }
  }, [_vm.phoneNumberUsed ? _c('span', [_vm._v("Resend")]) : _vm._e(), !_vm.phoneNumberUsed ? _c('span', [_vm._v("Refresh")]) : _vm._e()]) : _vm._e(), _vm.hideResend ? _c('b-link', {
    staticClass: "ml-1",
    attrs: {
      "target": "_blank",
      "href": "mailto:".concat(_vm.supportEmail)
    }
  }, [_c('span', [_vm._v("Contact support")])]) : _vm._e()], 1) : _vm._e(), _vm.showSelector ? _c('div', [_vm.loading ? _c('b-spinner') : _vm._e(), !_vm.loading ? _c('authenticator-selector', {
    attrs: {
      "list": _vm.authenticatorList
    },
    on: {
      "onSelect": _vm.selectResendMethod
    }
  }) : _vm._e()], 1) : _vm._e()] : _c('preloader', {
    attrs: {
      "title": "Sending..."
    }
  })], 2)] : _vm._e(), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.fields.code && _vm.fields.code.dirty && _vm.errors.has('code'),
      expression: "fields.code && fields.code.dirty && errors.has('code')"
    }],
    staticClass: "errormsg"
  }, [_c('span', [_vm._v(_vm._s(_vm.errors.first('code')))])]), _vm.showRecaptcha ? _c('b-row', [_c('b-col', [_c('vue-recaptcha', {
    ref: "recaptcha",
    attrs: {
      "id": "recap",
      "sitekey": _vm.sitekey,
      "size": "invisible"
    },
    on: {
      "verify": _vm.onRecaptchaVerify
    }
  })], 1)], 1) : _vm._e(), _c('b-row', {
    staticClass: "justify-content-md-center"
  }, [_c('b-col', {
    staticClass: "text-center mt-3 mt-sm-4",
    attrs: {
      "md": "8"
    }
  }, [_vm.action ? _c('button', {
    staticClass: "btn btn-primary btn-block",
    attrs: {
      "disabled": _vm.verifying,
      "type": "submit"
    }
  }, [_vm.loading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "variant": "light",
      "type": "grow",
      "label": "Spinning"
    }
  }) : _c('span', [_vm._v(_vm._s(_vm.buttonText))])], 1) : _vm._e(), _vm.showBackButton ? _c('div', {
    staticClass: "mt-2"
  }, [_vm._v(" or "), _c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.handleBack();
      }
    }
  }, [_vm._v("Go back")])]) : _vm._e()])], 1)], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }