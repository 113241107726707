var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "new-card-button",
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'new-card'
        });
      }
    }
  }, [_vm._v(" New Card "), _c('span', {
    staticClass: "new-card-button__icon"
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }