var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "affiliate-dashboard"
  }, [_c('div', {
    staticClass: "d-sm-flex justify-content-between align-items-center mb-4 mb-lg-4"
  }, [_c('h3', {
    staticClass: "mb-3 mb-sm-0 font-weight-bold"
  }, [_vm._v(" Selected period: ")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('label', {
    staticClass: "mb-0 mr-3"
  }, [_vm._v("Period:")]), _c('div', [_c('v-multiselect', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "options": _vm.periodOptions,
      "show-labels": false,
      "allow-empty": false,
      "searchable": false,
      "name": "period",
      "label": "text",
      "open-direction": "bottom",
      "return": "value",
      "track-by": "value",
      "placeholder": "Select period",
      "preselect-first": true
    },
    model: {
      value: _vm.period,
      callback: function callback($$v) {
        _vm.period = $$v;
      },
      expression: "period"
    }
  })], 1)])]), _c('div', {
    staticClass: "card mb-5"
  }, [!_vm.graphData ? _c('div', {
    staticClass: "text-center my-2"
  }, [_c('b-spinner', {
    attrs: {
      "variant": "primary"
    }
  })], 1) : _vm._e(), _vm.graph.options && _vm.graph.series ? _c('apexchart', {
    attrs: {
      "height": "300",
      "options": _vm.graph.options,
      "series": _vm.graph.series,
      "type": "line"
    }
  }) : _vm._e()], 1), !_vm.reportData ? _c('div', {
    staticClass: "text-center my-2"
  }, [_c('b-spinner', {
    attrs: {
      "variant": "primary"
    }
  }), _c('hr')], 1) : _vm._e(), _vm.reportData ? _c('div', {
    staticClass: "mb-5"
  }, [_c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table mb-0"
  }, [_vm._l(_vm.reportData.currencies, function (data) {
    return _c('tbody', {
      key: data.currencyId
    }, [_c('tr', [_c('td', {
      attrs: {
        "width": "40%"
      }
    }, [_vm._v(" Commission ")]), _c('td', [_vm._v(_vm._s(data.totalAmount) + " "), _c('span', {
      staticClass: "uppercase"
    }, [_vm._v(_vm._s(data.currencyId))])])]), _c('tr', [_c('td', [_vm._v("Standard commission")]), _c('td', [_vm._v(_vm._s(data.standardAmount) + " "), _c('span', {
      staticClass: "uppercase"
    }, [_vm._v(_vm._s(data.currencyId))])])]), _c('tr', [_c('td', [_vm._v("Markup commission")]), _c('td', [_vm._v(_vm._s(data.markupAmount) + " "), _c('span', {
      staticClass: "uppercase"
    }, [_vm._v(_vm._s(data.currencyId))])])])]);
  }), _c('tbody', [_c('tr', [_c('td', [_vm._v("Referrals started registration")]), _c('td', [_vm._v(_vm._s(_vm.reportData.registrationCount))])]), _c('tr', [_c('td', [_vm._v("Referrals completed registration")]), _c('td', [_vm._v(_vm._s(_vm.reportData.referredIbanCount))])]), _c('tr', [_c('td', [_vm._v("Eligible referrals")]), _c('td', [_vm._v(_vm._s(_vm.reportData.eligibleReferrerCount))])])])], 2)])]) : _vm._e(), !_vm.walletData ? _c('div', {
    staticClass: "text-center my-2"
  }, [_c('b-spinner', {
    attrs: {
      "variant": "primary"
    }
  }), _c('hr')], 1) : _vm._e(), _vm.walletData && _vm.walletData.length ? _c('div', {
    staticClass: "mb-5"
  }, [_c('h3', {
    staticClass: "mb-3 mb-lg-4 font-weight-bold"
  }, [_vm._v(" Since the last payout: ")]), _vm._l(_vm.walletData, function (row) {
    return _c('div', {
      key: row.id,
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col-md-4 col-lg-12 col-xl-4 mb-4 mb-md-0 mb-lg-4 mb-xl-0"
    }, [_c('div', {
      staticClass: "card h-100"
    }, [_c('div', {
      staticClass: "card-body text-center d-flex justify-content-center align-items-center flex-column"
    }, [_c('p', {
      staticClass: "mb-2"
    }, [_vm._v(" Balance ")]), _c('p', {
      staticClass: "lead font-weight-bold mb-0"
    }, [_vm._v(" " + _vm._s(row.balance) + " " + _vm._s(row.currencyId) + " ")])])])]), _c('div', {
      staticClass: "col-md-4 col-lg-12 col-xl-4 mb-4 mb-md-0 mb-lg-4 mb-xl-0"
    }, [_c('div', {
      staticClass: "card h-100"
    }, [_c('div', {
      staticClass: "card-body text-center d-flex justify-content-center align-items-center flex-column"
    }, [_c('p', {
      staticClass: "w-100 d-flex justify-content-between mb-0"
    }, [_vm._v(" Standard "), _c('span', [_vm._v(_vm._s(row.standardTotal) + " " + _vm._s(row.currencyId))])]), _c('hr', {
      staticClass: "w-100 border-top"
    }), _c('p', {
      staticClass: "w-100 d-flex justify-content-between mb-0"
    }, [_vm._v(" Markup "), _c('span', [_vm._v(_vm._s(row.markupTotal) + " " + _vm._s(row.currencyId))])])])])]), _c('div', {
      staticClass: "col-md-4 col-lg-12 col-xl-4"
    }, [_c('div', {
      staticClass: "card h-100"
    }, [_c('div', {
      staticClass: "card-body text-center d-flex justify-content-center align-items-center flex-column"
    }, [_c('p', {
      staticClass: "mb-2"
    }, [_vm._v(" Eligible referrals since the last payout ")]), _c('p', {
      staticClass: "lead font-weight-bold mb-0"
    }, [_vm._v(" " + _vm._s(row.eligibleReferrer) + " ")])])])])]);
  }), _vm.walletData && !_vm.walletData.length ? _c('div', {
    staticClass: "row"
  }, [_vm._m(0), _vm._m(1), _c('div', {
    staticClass: "col-md-4 col-lg-12 col-xl-4"
  }, [_c('div', {
    staticClass: "card h-100"
  }, [_c('div', {
    staticClass: "card-body text-center d-flex justify-content-center align-items-center flex-column"
  }, [_c('p', {
    staticClass: "mb-2"
  }, [_vm._v(" Number of eligible referrals ")]), _vm.reportData ? _c('p', {
    staticClass: "lead font-weight-bold mb-0"
  }, [_vm._v(" " + _vm._s(_vm.reportData.walletIssuedCount) + " ")]) : _vm._e(), !_vm.reportData ? _c('p', {
    staticClass: "lead font-weight-bold mb-0"
  }, [_c('b-spinner', {
    attrs: {
      "small": "",
      "variant": "primary"
    }
  })], 1) : _vm._e()])])])]) : _vm._e(), _vm.walletData && _vm.walletData.length ? _c('div', {
    staticClass: "text-center pt-4"
  }, [_c('button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.payout-modal",
      modifiers: {
        "payout-modal": true
      }
    }],
    staticClass: "btn btn-primary px-5"
  }, [_vm._v(" Payout ")])]) : _vm._e(), _c('b-modal', {
    attrs: {
      "id": "payout-modal",
      "size": "sm",
      "centered": "",
      "hide-header": "",
      "ok-only": true
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('p', [_vm._v(" Contact "), _c('a', {
    attrs: {
      "href": "mailto:".concat(_vm.supportEmail),
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.supportEmail))]), _vm._v(" for commission payouts ")])])])], 2) : _vm._e(), !_vm.commissionData ? _c('div', {
    staticClass: "text-center my-2"
  }, [_c('b-spinner', {
    attrs: {
      "variant": "primary"
    }
  }), _c('hr')], 1) : _vm._e(), _vm.commissionData && _vm.commissionData.rows && _vm.commissionData.rows.length ? _c('div', {
    staticClass: "mb-5"
  }, [_c('h3', {
    staticClass: "mb-3 mb-lg-4 font-weight-bold"
  }, [_vm._v(" Payout history ")]), _c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table mb-0"
  }, [_vm._m(2), _c('tbody', _vm._l(_vm.commissionData.rows, function (row) {
    return _c('tr', {
      key: row.id
    }, [_c('td', {
      staticClass: "align-middle"
    }, [_vm._v(" " + _vm._s(_vm.moment(row.dateComplete).format('YYYY-MM-DD')) + " ")]), _c('td', {
      staticClass: "align-middle"
    }, [_vm._v(" " + _vm._s(row.totalAmount) + " " + _vm._s(row.currencyId) + " ")])]);
  }), 0)])])]) : _vm._e(), _c('div', {
    staticClass: "mb-0"
  }, [_c('h3', {
    staticClass: "mb-3 mb-lg-4 font-weight-bold"
  }, [_vm._v(" Referrals ")]), _c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table mb-0"
  }, [_vm._m(3), _c('tbody', [!_vm.referralsLoaded ? _c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "3"
    }
  }, [_c('b-spinner', {
    attrs: {
      "variant": "primary"
    }
  }), _c('hr')], 1)]) : _vm._e(), _vm.referralsLoaded && _vm.referrals.length == 0 ? _c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v(" There are no referrals to show. ")])]) : _vm._e(), _vm._l(_vm.referrals, function (ref) {
    return _c('tr', {
      key: ref.id
    }, [_c('td', [_vm._v(_vm._s(ref.fullName))]), _c('td', [_vm._v(_vm._s(_vm.formatReferralDate(ref.dateCreated)))]), _c('td', [_c('span', {
      staticClass: "badge",
      "class": _vm.getStatus(ref.verification, ref.status)["class"]
    }, [_vm._v(_vm._s(_vm.getStatus(ref.verification, ref.status).label))])])]);
  })], 2)])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-md-4 col-lg-12 col-xl-4 mb-4 mb-md-0 mb-lg-4 mb-xl-0"
  }, [_c('div', {
    staticClass: "card h-100"
  }, [_c('div', {
    staticClass: "card-body text-center d-flex justify-content-center align-items-center flex-column"
  }, [_c('p', {
    staticClass: "mb-2"
  }, [_vm._v(" Balance ")]), _c('p', {
    staticClass: "lead font-weight-bold mb-0"
  }, [_vm._v(" - ")])])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-md-4 col-lg-12 col-xl-4 mb-4 mb-md-0 mb-lg-4 mb-xl-0"
  }, [_c('div', {
    staticClass: "card h-100"
  }, [_c('div', {
    staticClass: "card-body text-center d-flex justify-content-center align-items-center flex-column"
  }, [_c('p', {
    staticClass: "w-100 d-flex justify-content-between mb-0"
  }, [_vm._v(" Standard "), _c('span', [_vm._v("-")])]), _c('hr', {
    staticClass: "w-100 border-top"
  }), _c('p', {
    staticClass: "w-100 d-flex justify-content-between mb-0"
  }, [_vm._v(" Markup "), _c('span', [_vm._v("-")])])])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', [_vm._v("Date")]), _c('th', [_vm._v("Amount")])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', [_vm._v("Name")]), _c('th', [_vm._v("Registration date")]), _c('th', [_vm._v("Status")])])]);
}]

export { render, staticRenderFns }