<template>
	<button
		class="new-card-button"
		@click="$router.push({ name: 'new-card'})"
	>
		New Card
		<span class="new-card-button__icon"></span>
	</button>
</template>
<script>
export default {
	name: 'NewCardButton'
};
</script>
<style lang="scss">
	.new-card-button {
		padding: 0;
		border: 0;
		background: transparent;
		color: $primary;
		font-weight: bold;
		display: inline-flex;
		align-items: center;

		&__icon {
			height: 18px;
			width: 18px;
			background-color: $primary;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			color: #fff;
			border-radius: 20px;
			margin-left: 5px;

			&::before,
			&::after {
				content: '';
				display: block;
				position: absolute;
				background-color: #fff;
			}

			&::after {
				width: 2px;
				height: 8px;
			}

			&::before {
				width: 8px;
				height: 2px;
			}
		}
	}
</style>
