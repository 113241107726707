<template>
	<div class="odd-form">
		<b-form>
			<b-row>
				<b-col>
					<h2>Update your personal information</h2>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<form-error-field id="name"
						name="name"
						label="First name"
						label-for="name-input"
					>
						<b-form-input
							id="name-input"
							v-model="formData.name"
							v-validate="'required'"
							data-vv-as="First name"
							name="name"
							type="text"
							placeholder="First name"
						/>
					</form-error-field>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<form-error-field id="surname"
						name="surname"
						label="Last name"
						label-for="name-input"
					>
						<b-form-input
							id="surname-input"
							v-model="formData.surname"
							v-validate="'required'"
							data-vv-as="Last name"
							name="surname"
							type="text"
							placeholder="Last name"
						/>
					</form-error-field>
				</b-col>
			</b-row>

			<b-row>
				<b-col>
					<div class="form-group mb-0">
						<phone-input 
							v-model="formData.phone"
							label="Phone number"
						/>
					</div>
				</b-col>
			</b-row>

			<b-row>
				<b-col>
					<form-error-field
						id="email"
						name="email"
						label="Email"
						label-for="email-input"
					>
						<b-form-input
							id="email-input"
							v-model="formData.email"
							v-validate="'email|required'"
							data-vv-as="Email"
							name="email"
							type="text"
							placeholder="Email"
						/>
					</form-error-field>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<form-error-field
						id="country-for-tax"
						name="countryForTax"
					>
						<label for="country-for-tax-purpose">Country of residence for tax purpose</label>
						<v-multiselect
							id="country-for-tax-purpose"
							v-model="formData.countryForTax"
							v-validate="'required'"
							:options="countryOptions"
							:show-labels="false"
							data-vv-as="Country for tax purpose"
							name="countryForTax"
							label="name"
							placeholder="Select country for tax purpose"
							track-by="countryId"
							return="countryId"
						/>
					</form-error-field>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<form-error-field name="politicalExposure">
						<label for="political-exposure">Political exposure</label>

						<v-multiselect
							id="political-exposure"
							v-model="formData.politicalExposure"
							v-validate="'required'"
							:options="options.politicalExposureTypes"
							:show-labels="false"
							:allow-empty="false"
							data-vv-as="Political exposure"
							name="politicalExposure"
							label="name"
							placeholder="Select Your political exposure"
							track-by="name"
							return="value"
						/>
					</form-error-field>
				</b-col>
			</b-row>
			<multi-address :addresses="formData.addresses" />
		</b-form>
		<odd-form-footer 
			v-bind="properties"
		/>
	</div>
</template>
<script>
import OddFormFooter from '../OddFormFooter';
import OddField from '../Fields/OddInput';
import MultiAddress from '@/shared/MultiAddress';
import PhoneInput from '@/shared/PhoneInput';
import options from '@/LayoutVerification/QuestionnairePerson/QuestionnairePersonOptions';
import FormErrorField from '@/shared/Form/FormErrorField';

import { mapState } from 'vuex';

export default {
	name: 'ODDPersonInformation',
	components: {
		OddFormFooter,
		OddField,
		MultiAddress,
		PhoneInput,
		FormErrorField
	},
	data() {
		return {
			formData: { addresses: { } },
			options
		};
	},
	computed: {
		...mapState('user', {
			user: state => state.selected.userProfile
		}),
		...mapState('countries', {
			countryOptions: state => state.list
		}),
		properties() {
			return {
				...this._props, 
				formData: this.formData
			};
		}
	},
	props: {
		onSuccess: {
			type: Function,
			default: () => {},
			required: true
		},
		onFailure: {
			type: Function,
			default: () => {},
		},
		onCancel: {
			type: Function,
			default: () => {}
		},
		submitUrl: {
			type: String,
			default: '',
			required: true
		}
	},
	mounted() {
		const userData = { ...this.user };
		const contacts = this.user.contacts;
		const q = this.user.questionnaire;

		userData.email = contacts.contactEmail ? contacts.contactEmail : contacts.mainEmail;
		userData.phone = contacts.contactPhone ? contacts.contactPhone : contacts.mainPhone;

		userData.politicalExposure = q.politicalExposure.value;

		this.formData = userData;

		this.$validator.reset();
	}
};
</script>
<style lang="scss">
	
</style>