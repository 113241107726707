var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    "class": _vm.alignClass
  }, [_c('transition', {
    attrs: {
      "name": "fade",
      "mode": "in-out"
    }
  }, [_vm.type != 'bootstrap' && _vm.error ? _c('div', {
    staticClass: "alert alert-danger",
    "class": _vm.alignClass,
    on: {
      "click": function click($event) {
        return _vm.hideErrorNotification();
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.error))])]) : _vm._e(), _vm.type != 'bootstrap' && _vm.success ? _c('div', {
    staticClass: "alert alert-success successmsg",
    "class": _vm.alignClass,
    on: {
      "click": function click($event) {
        return _vm.hideSuccessNotification();
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.success))])]) : _vm._e(), _vm.type == 'bootstrap' && (_vm.success || _vm.info || _vm.error) ? _c('div', {
    staticClass: "notification"
  }, [_vm.error ? _c('div', {
    staticClass: "alert alert-danger",
    on: {
      "click": function click($event) {
        return _vm.hideErrorNotification();
      }
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_vm._v(" " + _vm._s(_vm.error) + " ")])]) : _vm._e(), _vm.success ? _c('div', {
    staticClass: "alert alert-success",
    on: {
      "click": function click($event) {
        return _vm.hideSuccessNotification();
      }
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_vm._v(" " + _vm._s(_vm.success) + " ")])]) : _vm._e(), _vm.info ? _c('div', {
    staticClass: "alert alert-info",
    on: {
      "click": function click($event) {
        return _vm.hideInfoNotification();
      }
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_vm._v(" " + _vm._s(_vm.info) + " ")])]) : _vm._e()]) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }