<template>
	<div class="card-order">
		<b-container>
			<b-row>
				<b-col>
					<div class="page-title-container">
						<h2>New Card</h2>
					</div>
				</b-col>
			</b-row>
			<card-account-details />
			<div v-if="profileCountryAllowed && countriesLoaded">
				<b-row>
					<b-col>
						<span class="card-order__type-title">Select card type</span>
					</b-col>
				</b-row>
				<b-row v-if="permissions.createCardOrder">
					<b-col
						cols="auto"
						class="text-center card-order__selection mb-4"
					>
						<div class="mb-4">
							<card-image />
						</div>
						<b-button
							variant="primary"
							@click="$router.push({name: 'new-plastic-card'})"
						>
							New Visa Debit card
						</b-button>
					</b-col>
					<b-col
						cols="auto"
						class="text-center card-order__selection mb-4"
					>
						<div class="mb-4">
							<card-image virtual />
						</div>
						<b-button
							variant="primary"
							@click="$router.push({name: 'new-virtual-card'})"
						>
							New Visa Debit Virtual card
						</b-button>
					</b-col>
				</b-row>
			</div>
			<b-row v-if="!permissions.createCardOrder || (!profileCountryAllowed && countriesLoaded)">
				<b-col class="text-center">
					<b-card>
						You're not allowed to order the card.
					</b-card>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>
<script>
import moment from 'moment';
import CardAccountDetails from './Partials/CardAccountDetails';
import CardImage from './Partials/CardImage';
import { mapGetters, mapState } from 'vuex';

export default {
	name: 'CardOrder',
	components: {
		CardAccountDetails,
		CardImage
	},
	data() {
		return {
			loading: true,
			selectedType: null,
		};
	},
	computed: {
		...mapState('user', {
			user: state => state.selected
		}),
		...mapState('cards', [
			'countries', 'countriesLoaded'
		]),
		...mapGetters('wallet', [
			'currentWallet'
		]),
		...mapGetters('user', [
			'isGranted'
		]),
		permissions() {
			return {
				createCardOrder: this.isGranted('front_create_card_order'),
			};
		},
		profileCountryAllowed() {
			if (!this.countries.length || !this.user.userProfile) return false;
			return this.countries.map(c => c.countryId).includes(this.user.userProfile.addresses.main.countryId);
		},
		expirationDate() {
			return moment().add(4, 'years').format('MM/YY');
		}
	}
};

</script>
<style lang="scss" scoped>
	@import './CardOrder.scss';
</style>