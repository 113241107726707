var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": _vm.id,
      "size": "sm",
      "centered": "",
      "hide-header": "",
      "hide-footer": "",
      "no-close-on-backdrop": "",
      "shown": ""
    },
    on: {
      "hidden": _vm.onHide
    }
  }, [_c('div', {
    staticClass: "text-center card-content-modal"
  }, [_c('div', [_vm._t("default")], 2), _c('b-button', {
    staticClass: "mt-4",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.closeModal
    }
  }, [_vm._v(" Close ")])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }