<template>
	<div class="edit-info pt-4">
		<div v-if="step==1">
			<a 
				href="#" 
				class="" 
				@click="step=2"
			>Want to edit info?</a>
		</div>
		<div 
			v-if="step==2" 
			class="step2 d-flex flex-column"
		>
			<p>
				In order to edit your personal information, please contact our support center. You will be asked to submit documents,
				as a proof of your new personal information.
			</p>
			<div class="d-flex">
				<button 
					class="btn btn-info mr-3" 
					@click="step=1"
				>
					Cancel
				</button>
				<!-- Todo: add whitelabel support link -->
				<a 
					class="btn btn-primary" 
					target="_blank" 
					:href="supportUrl"
				>Contact support</a>
			</div>
		</div>
	</div>
</template>

<script>
import config from '~/config';
export default {
	name: 'EditInfo',
	data() {
		return {
			step: 1,
			WHITELABEL,
			supportUrl: config.supportUrl
		};
	},
	methods: {
		openZendesk() {
			// eslint-disable-next-line no-undef
			$zopim.livechat.window.show();
		},
	},
};
</script>
