<template>
	<div class="bulk-payments">
		<div v-if="step == 'transferSelection'">
			<div class="bulk-items mt-3">
				<b-row
					class="mb-2"
				>
					<b-col
						sm="12"
						md="4"
						lg="4"
						xl="4"
					>
						<div class="text-left">
							<custom-select
								:items="statuses"
								:hide-front="false"
								:modify-bg="true"
								@select="changeStatus($event)"
							/>
						</div>
					</b-col>
					<b-col
						sm="12"
						md="5"
						lg="5"
						xl="5"
					/>
					<b-col
						sm="12"
						md="3"
						lg="3"
						xl="3"
						class="mt-3 mt-lg-0 mt-sm-0 mt-xl-0"
					>
						<div class="d-flex align-items-center">
							<p class="mr-3 mb-0 text-muted align-self-center text-nowrap items-per-page-label">
								Items <br> per page
							</p>
							<custom-select
								:items="pages"
								:hide-front="false"
								:modify-bg="true"
								@select="filterPages($event)"
							/>
						</div>
					</b-col>
					<b-col
						sm="12"
						md="12"
						lg="12"
						xl="12"
					>
						<div
							v-if="status=== 'created' && items.rows && items.rows.length > 0 && isWalletGranted('initiate_transaction', currentWallet.walletId)"
							class="text-center mt-3 mt-lg-3 mt-sm-3 mt-xl-3 text-sm-right text-lg-right text-xl-right"
						>
							<button
								class="btn btn-primary mb-3"
								@click.prevent="selectAll(true)"
							>
								Select all
							</button>
							<button
								class="btn btn-outline-primary mb-3 ml-3"
								@click.prevent="selectAll(false)"
							>
								Deselect all
							</button>
						</div>
					</b-col>
				</b-row>
				<b-card
					v-if="items.loading"
					class="bulk-item text-center"
				>
					<b-spinner
						label="Loading..."
					/>
				</b-card>
				<b-card
					v-for="item in items.rows"
					:key="item.id"
					class="mb-3 bulk-item"
					:class="`${isItemSelected(item) && isWalletGranted('initiate_transaction', currentWallet.walletId) ? 'bulk-item--selected' : ''} ${item.status !== 'created' ? 'bulk-item--completed' : ''}`"
					@click.prevent="selectItem(item)"
				>
					<b-row>
						<b-col
							md="1"
							cols="12"
							class="check"
						>
							<div
								v-if="item.status === 'created' && isWalletGranted('initiate_transaction', currentWallet.walletId)"
							>
								<i
									v-if="!isItemSelected(item)"
									class="far fa-square"
								></i>
								<i
									v-else
									class="far fa-check-square"
								></i>
							</div>
						</b-col>
						<b-col
							md="3"
							cols="12"
						>
							<small class="item-label">Beneficiary</small>
							<div
								v-if="item.transactionData.serviceId === 'cc_swift_sha' || item.transactionData.serviceId === 'cc_local'"
							>
								<div v-if="item.transactionData.beneficiaryRequest.type === 'company'">
									<div>{{ item.transactionData.beneficiaryRequest.companyName }} (Multicurrency)</div>
								</div>
								<div v-else>
									{{ item.transactionData.beneficiaryRequest.name + ' ' + item.transactionData.beneficiaryRequest.surname }} (Multicurrency)
								</div>
							</div>
							<div v-else>
								{{ item.transactionData.beneficiaryRequest.name }}
							</div>
						</b-col>
						<b-col
							md="3"
							cols="12"
						>
							<small class="item-label">Account Number</small>
							<div>{{ item.transactionData.beneficiaryRequest.accountNumber }}</div>
						</b-col>
						<b-col
							md="2"
							cols="12"
						>
							<small class="item-label">Amount</small>
							<div class="amount-label">
								<b-badge
									class="amount-badge"
									:variant="statusVariant('item', item.status)"
								>
									{{ item.transactionData.amount }}
									<small>
										{{ item.transactionData.currencyId }}
									</small>
								</b-badge>
							</div>
						</b-col>

						<b-col
							md="3"
							cols="12"
						>
							<small class="item-label">Details</small>
							<div>{{ item.transactionData.details }}</div>
						</b-col>
					</b-row>
				</b-card>
				<div
					v-if="!items.loading && (!items.rows || items.rows.length < 1)"
					class="alert alert-info"
				>
					No transactions
				</div>
				<div
					class="mb-3 text-sm-left text-center"
				>
					<div>
						<span>Status:</span>
					</div>
					<b-badge
						variant="primary"
						class="mr-1 status-badge my-1"
					>
						Ready
					</b-badge>
					<b-badge
						variant="success"
						class="mr-1 status-badge my-1"
					>
						Processed
					</b-badge>
					<b-badge
						variant="danger"
						class="status-badge my-1"
					>
						Failed or deleted
					</b-badge>
				</div>
				<div class="mb-3">
					<pagination
						v-if="items.pages > 1"
						:total="items.count"
						:per-page="filterPerPage"
						:current-page="items.currentPage"
						@pagechanged="getTransferList"
					/>
				</div>
			</div>
			<div
				v-if="items.rows && items.rows.length > 0"
				class="text-center"
			>
				<button
					v-if="isWalletGranted('approve_transaction', currentWallet.walletId)"
					class="btn btn-primary ml-3"
					:disabled="selectedItems.length == 0"
					@click.prevent="step = 'confirmation'"
				>
					Next
				</button>
				<button
					v-if="isGranted('front_transaction_initiate_delete')"
					class="btn btn-danger ml-3"
					:disabled="selectedItems.length == 0"
					@click.prevent="deleteSelected"
				>
					Remove
				</button>
			</div>
		</div>

		<!-- Confirmation step -->
		<div
			v-if="step == 'confirmation' || step == 'transfer'"
			class="selected-items"
		>
			<b-card>
				<b-row
					v-for="item in selectedItems"
					:key="item.id"
					class="selected-item"
				>
					<b-col
						md="3"
						sm="12"
					>
						<small class="item-label">Beneficiary</small>
						<div
							v-if="item.transactionData.serviceId === 'cc_swift_sha' || item.transactionData.serviceId === 'cc_local'"
						>
							<div v-if="item.transactionData.beneficiaryRequest.type === 'company'">
								<div>{{ item.transactionData.beneficiaryRequest.companyName }}</div>
							</div>
							<div v-else>
								{{ item.transactionData.beneficiaryRequest.name + ' ' + item.transactionData.beneficiaryRequest.surname }}
							</div>
						</div>
						<div v-else>
							{{ item.transactionData.beneficiaryRequest.name }}
						</div>
					</b-col>
					<b-col
						md="3"
						sm="12"
					>
						<small class="item-label">Account Number</small>
						<div>{{ item.transactionData.beneficiaryRequest.accountNumber }}</div>
					</b-col>
					<b-col
						md="3"
						sm="12"
					>
						<small class="item-label">Amount</small>
						<div>{{ item.transactionData.amount }}<small>{{ item.transactionData.currencyId }}</small></div>
					</b-col>
					<b-col
						md="3"
						sm="12"
					>
						<small class="item-label">Details</small>
						<div>{{ item.transactionData.details ? item.transactionData.details : '-' }}</div>
					</b-col>
				</b-row>
				<b-row
					class="selected-item"
					align-v="center"
				>
					<b-col
						md="6"
						sm="12"
					>
						<strong>Number of payments: {{ selectedItems.length }}</strong>
					</b-col>
					<b-col
						md="6"
						sm="12"
					>
						<strong>Total: {{ selectedTotal.sum }}{{ selectedTotal.currency }}</strong>
						<div>
							<b-badge
								v-if="exceededBalance(selectedTotal.sum)"
								variant="danger"
							>
								Insufficient Funds
							</b-badge>
						</div>
					</b-col>
				</b-row>
			</b-card>
			<div
				v-if="step == 'confirmation'"
				class="text-center mt-3"
			>
				<button
					class="btn btn-outline-primary"
					@click.prevent="step = 'transferSelection'"
				>
					Back
				</button>
				<button
					v-if="isWalletGranted('approve_transaction', currentWallet.walletId)"
					class="btn btn-primary ml-3"
					:disabled="exceededBalance(selectedTotal.sum)"
					@click.prevent="confirm"
				>
					Confirm
				</button>
			</div>
			<div
				v-if="step == 'transfer'"
				class="transfer"
			>
				<b-card class="mt-3">
					<authenticator
						ref="authenticator"
						:show-back-button="false"
						:on-submit="transfer"
						type="transfer"
						:on-resend="confirm"
					/>
				</b-card>
				<div class="text-center mt-3">
					<button
						class="btn btn-outline-primary"
						@click.prevent="step = 'confirmation'"
					>
						Back
					</button>
				</div>
			</div>
		</div>

		<!-- Success step -->
		<div v-if="step == 'success'">
			<b-card>
				Transfers approved successfully
				<button
					class="btn btn-primary ml-3"
					@click.prevent="step = 'transferSelection'"
				>
					OK
				</button>
			</b-card>
		</div>

		<div v-if="step == 'successDelete'">
			<b-card>
				Transfers deleted successfully
				<button
					class="btn btn-primary ml-3"
					@click.prevent="step = 'transferSelection'"
				>
					OK
				</button>
			</b-card>
		</div>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Authenticator from '~/components/shared/Authenticator';
import { endpoints } from '~/config';
import moment from 'moment';
import Pagination from '~/components/utils/Pagination';
import CustomSelect from '@/utils/CustomSelect';

export default {
	name: 'TransactionInitiateTable',
	components: {
		CustomSelect,
		Authenticator,
		Pagination,
	},
	data() {
		return {
			moment,
			pages: {
				10: '10',
				20: '20',
				50: '50',
				100: '100',
			},
			filterPerPage: '10',
			fileContent: null,
			items: {},
			files: {},
			fileId: null,
			step: 'transferSelection',
			successData: null,
			selectedItems: [],
			currentPage: 1,
			status: 'created',
			statuses: {
				'created': 'Created',
				'processed': 'Processed',
				'approved': 'Approved',
				'deleted': 'Deleted',
				'failed': 'Failed',
			},
		};
	},
	computed: {
		...mapGetters('user', [
			'isGranted',
			'isWalletGranted',
		]),
		...mapGetters('wallet', [
			'currentWallet',
			'currentWalletAmount'
		]),
		selectedIds() {
			return this.selectedItems.map(i => i.id);
		},
		selectedTotal() {
			if (this.selectedItems.length > 0) {
				const currency = this.selectedItems[0].transactionData.currencyId;
				const sum = this.selectedItems.map(i => i.transactionData.amount).reduce((a, b) => parseFloat(a) + parseFloat(b), 0).toFixed(2);
				return { sum, currency };
			} else {
				return null;
			}
		}
	},
	watch: {
		currentWallet: function () {
			this.getTransferList();
		},
		step(step) {
			if (step === 'transferSelection') {
				this.getTransferList();
			}
		}
	},
	methods: {
		...mapActions('notifications', [
			'pushError'
		]),
		...mapActions('auth', ['init2fa']),
		statusVariant(type = 'file', status) {
			if (type == 'item') {
				switch (status) {
				case 'created':
					return 'primary';
				case 'approved':
					return 'primary';
				case 'processed':
					return 'success';
				case 'failed':
					return 'danger';
				case 'deleted':
					return 'danger';
				default:
					return 'primary';
				}
			}

			return 'primary';
		},
		async getTransferList(page = 1) {
			this.items = {
				currentPage: page,
				loading: true
			};
			if (this.currentWallet === null) {
				this.items = [];
				this.total = 0;
				this.loading = true;
				return;
			}
			const options = {
				method: 'GET',
				url: endpoints.transactions.initiateList({ walletId: this.currentWallet.walletId }),
				params: {
					page: page,
					limit: this.filterPerPage,
					orderBy: 'timestampCreated',
					orderDirection: 'desc',
					statusIn: [this.status]
				}
			};
			try {
				const response = await axios(options);
				this.items = {
					...this.items,
					...response.data,
					loading: false
				};
				return this.items;
			} catch (err) {
				this.items.loading = false;
				this.pushError(err.message);
			}
		},
		filterPages(event) {
			this.filterPerPage = event.toString();
			this.limit = event;
			this.getTransferList(1);
		},
		selectItem(item) {
			const selectedItem = this.selectedItems.find(i => i.id == item.id);
			if (selectedItem) {
				this.selectedItems = this.selectedItems.filter(i => i.id !== item.id && i.status == 'created');
			} else if (item.status == 'created') {
				this.selectedItems.push(item);
			}
		},
		isItemSelected(item) {
			const id = item.id;
			return !!this.selectedItems.find(i => i.id == id);
		},
		selectAll(select) {
			const items = this.items.rows;
			if (select) {
				items.forEach(i => {
					if (!this.selectedItems.find(si => si.id == i.id) && i.status === 'created') {
						this.selectedItems.push(i);
					}
				});
			} else {
				this.selectedItems = this.selectedItems.filter(si => {
					const found = items.find(i => si.id == i.id && i.status === 'created');
					return !found;
				});
			}
		},
		async confirm() {
			try {
				await this.init2fa({
					type: 'transfer',
					data: {
						fileId: this.fileId
					},
				});
				this.step = 'transfer';
			} catch (err) {
				this.pushError(err.message);
			}
		},
		async transfer(code) {
			this.successData = null;
			const options = {
				method: 'POST',
				url: endpoints.transfer.initiateApprove({ walletId: this.currentWallet.walletId }),
				data: {
					code,
					ids: this.selectedIds
				}
			};
			try {
				const response = await axios(options);
				const { data } = response;
				this.successData = data;
				this.step = 'success';
				this.selectedItems = [];
				await this.getTransferList();
			} catch (err) {
				this.$refs.authenticator.reset();
				this.pushError(err.message);
			}
		},
		async deleteSelected() {
			this.successData = null;
			const options = {
				method: 'DELETE',
				url: endpoints.transfer.initiateDelete({ walletId: this.currentWallet.walletId }),
				data: {
					ids: this.selectedIds
				}
			};
			try {
				const response = await axios(options);
				const { data } = response;
				this.successData = data;
				this.step = 'successDelete';
				this.selectedItems = [];
				this.getTransferList();
			} catch (err) {
				this.$refs.authenticator.reset();
				this.pushError(err.message);
			}
		},
		exceededBalance(amount) {
			const currentBalance = parseFloat(this.currentWalletAmount.balance);
			return parseFloat(amount) > currentBalance;
		},
		changeStatus(event) {
			this.status = event;
			this.getTransferList();
		},
	},
	mounted() {
		this.getTransferList();
	},

};
</script>
<style scoped lang="scss" src="./InitiateTransactions.scss">
</style>
