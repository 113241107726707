<template>
	<div
		:tabindex="searchable ? 0 : tabindex"
		:class="{ 'multiselect--active': isOpen, 'multiselect--disabled': disabled, 'multiselect--above': isAbove }"
		class="multiselect"
		@focus="activate()"
		@blur="searchable ? false : deactivate()"
		@keydown.self.down.prevent="pointerForward()"
		@keydown.self.up.prevent="pointerBackward()"
		@keydown.enter.tab.stop.self="addPointerElement($event)"
		@keyup.esc="deactivate()"
	>
		<slot 
			:toggle="toggle" 
			name="caret"
		>
			<div 
				class="multiselect__select" 
				@mousedown.prevent.stop="toggle()"
			></div>
		</slot>
		<slot 
			:search="search" 
			name="clear"
		></slot>
		<div 
			ref="tags" 
			class="multiselect__tags"
		>
			<span v-if="disableTags">
				<span
					v-show="!isOpen && internalValue.length > 0"
					class="multiselect__single"
				>{{ internalValue.length }} options selected</span>
			</span>
			<div 
				v-if="!disableTags"
				v-show="visibleValues.length > 0" 
				class="multiselect__tags-wrap"
			>
				<template 
					v-for="option of visibleValues"
				>
					<slot 
						:option="option" 
						:search="search" 
						:remove="removeElement" 
						name="tag"
					>
						<span 
							:key="returnKey ? option[returnKey] : option" 
							class="multiselect__tag"
						>
							<span v-text="getOptionLabel(option)"></span>
							<i 
								aria-hidden="true" 
								tabindex="1" 
								class="multiselect__tag-icon" 
								@keydown.enter.prevent="removeElement(option)" 
								@mousedown.prevent="removeElement(option)"
							></i>
						</span>
					</slot>
				</template>
			</div>

			<template v-if="internalValue && internalValue.length > limit">
				<slot name="limit">
					<strong 
						class="multiselect__strong" 
						v-text="limitText(internalValue.length - limit)"
					/>
				</slot>
			</template>
			<transition name="multiselect__loading">
				<slot name="loading">
					<div 
						v-show="loading" 
						class="multiselect__spinner"
					/>
				</slot>
			</transition>
			<input
				v-show="isOpen && searchable"
				:id="id"
				ref="search"
				:name="name"
				:autocomplete="name"
				:placeholder="placeholder"
				:style="inputStyle"
				:value="search"
				:disabled="disabled"
				:tabindex="tabindex"
				type="text"
				class="multiselect__input"
				@input="updateSearch($event.target.value)"
				@focus.prevent="activate()"
				@blur.prevent="deactivate()"
				@keyup.esc="deactivate()"
				@keydown.down.prevent="pointerForward()"
				@keydown.up.prevent="pointerBackward()"
				@keydown.enter.prevent.stop.self="addPointerElement($event)"
				@keydown.delete.stop="removeLastElement()"
			/>
			<span
				v-if="isSingleLabelVisible"
				class="multiselect__single"
				@mousedown.prevent="toggle"
			>
				<slot 
					:option="singleValue" 
					name="singleLabel"
				>
					<div>{{ currentOptionLabel }}</div>
				</slot>
			</span>
			<span 
				v-if="isPlaceholderVisible" 
				@mousedown.prevent="toggle"
			>
				<slot name="placeholder">
					<span class="multiselect__single">
						{{ placeholder }}
					</span>
				</slot>
			</span>
		</div>
		<transition name="multiselect">
			<div
				v-show="isOpen"
				ref="list"
				:style="{ maxHeight: optimizedHeight + 'px' }"
				class="multiselect__content-wrapper"
				@focus="activate"
				@mousedown.prevent
			>
				<ul 
					:style="contentStyle" 
					class="multiselect__content"
				>
					<slot name="beforeList"></slot>
					<li v-if="multiple && max === internalValue.length">
						<span class="multiselect__option">
							<slot name="maxElements">Maximum of {{ max }} options selected. First remove a selected option to select another.</slot>
						</span>
					</li>
					<template v-if="!max || internalValue.length < max">
						<li 
							v-for="(option, index) of filteredOptions" 
							:key="index" 
							class="multiselect__element"
						>
							<span
								v-if="!(option && (option.$isLabel || option.$isDisabled))"
								:class="optionHighlight(index, option)"
								:data-select="option && option.isTag ? tagPlaceholder : selectLabelText"
								:data-selected="selectedLabelText"
								:data-deselect="deselectLabelText"
								class="multiselect__option"
								@click.stop="select(option)"
								@mouseenter.self="pointerSet(index)"
							>
								<slot 
									:option="option" 
									:search="search" 
									name="option"
								>
									<span>{{ getOptionLabel(option) }}</span>
								</slot>
							</span>
							<span
								v-if="option && (option.$isLabel || option.$isDisabled)"
								:data-select="groupSelect && selectGroupLabelText"
								:data-deselect="groupSelect && deselectGroupLabelText"
								:class="groupHighlight(index, option)"
								class="multiselect__option"
								@mouseenter.self="groupSelect && pointerSet(index)"
								@mousedown.prevent="selectGroup(option)"
							>
								<slot 
									:option="option" 
									:search="search" 
									name="option"
								>
									<span>{{ getOptionLabel(option) }}</span>
								</slot>
							</span>
						</li>
					</template>
					<li v-show="showNoResults && (filteredOptions.length === 0 && search && !loading)">
						<span class="multiselect__option">
							<slot name="noResult">No elements found. Consider changing the search query.</slot>
						</span>
					</li>
					<slot name="afterList"></slot>
				</ul>
			</div>
		</transition>
	</div>
</template>
<script>
import Multiselect from 'vue-multiselect';

function deepClone (obj) {
	if (Array.isArray(obj)) {
		return obj.map(deepClone);
	} else if (obj && typeof obj === 'object') {
		var cloned = {};
		var keys = Object.keys(obj);
		for (var i = 0, l = keys.length; i < l; i++) {
			var key = keys[i];
			cloned[key] = deepClone(obj[key]);
		}
		return cloned;
	} else {
		return obj;
	}
}
export default {
	mixins: [Multiselect],
	props: {
		return: {
			type: String,
			default: null
		},
		disableTags: {
			type: Boolean,
			default: false
		}
	},
	data () {
		return {
			currentValue: [],
			returnKey: this.return,
		};
	},

	computed: {
		internalValue: {
			get() {
				return this.currentValue;
			},
			set(value) {
				this.currentValue = value;
			}
		}
	},

	watch: {
		value() {
			this.setInitialValue();
		},
		options: {
			handler() {
				this.setInitialValue();

			},
			deep: true
		}
	},

	created() {
		this.setInitialValue();
	},

	methods: {
		/**
             * Converts the external value to the internal value
             * @returns {Array} returns the internal value
             */
		getInternalValue (value) {
			if (value === null || value === undefined) {
				return [];
			} else {
				if (this.return) {
					if (!this.multiple) {
						value = this.options.find((option) => String(option[this.return]) === String(value)) || '';
					} else {
						value = this.options.filter(o => value.indexOf(o[this.return]) !== -1 || value.find(v => v[this.return] == o[this.return]));

					}
				}
				return this.multiple
					? deepClone(value)
					: deepClone([value]);
			}
		},
		getValue () {
			return this.multiple
				? this.internalValue
				: this.internalValue.length === 0
					? null
					: this.internalValue[0];
		},
		/**
             * Add the given option to the list of selected options
             * or sets the option as the selected option.
             * If option is already selected -> remove it from the results.
             *
             * @param  {Object||String||Integer} option to select/deselect
             * @param  {Boolean} block removing
             */
		select (option, key) {
			/* istanbul ignore else */
			if (this.blockKeys.indexOf(key) !== -1 || this.disabled || option.$isLabel) return;
			/* istanbul ignore else */
			if (this.max && this.multiple && this.internalValue.length === this.max) return;
			if (option.isTag) {
				this.$emit('tag', option.label, this.id);
				this.search = '';
				if (this.closeOnSelect && !this.multiple) this.deactivate();
			} else {
				const isSelected = this.isSelected(option);
				if (isSelected) {
					if (key !== 'Tab') this.removeElement(option);
					return;
				} else if (this.multiple) {
					if (this.return) {
						if (option[this.return]) {
							this.internalValue.push(option[this.return]);
						}
					} else {
						this.internalValue.push(option);
					}
				} else {
					this.internalValue = [option];
				}
				this.$emit('select', deepClone(option), this.id);
				let value = this.getValue();
				if (!this.multiple) {
					value = this.return ? value[this.return] : value;
				} else {
					value = this.return ? value.map(v => typeof v == 'object' ? v[this.return] : v) : value;
				}
				this.$emit('input', value, this.id);

				/* istanbul ignore else */
				if (this.clearOnSelect) this.search = '';
			}
			/* istanbul ignore else */
			if (this.closeOnSelect) this.deactivate();
		},
		removeElement (option, shouldClose = true) {
			/* istanbul ignore else */
			if (this.disabled) return;
			/* istanbul ignore else */
			if (option.$isDisabled) return;
			/* istanbul ignore else */
			if (!this.allowEmpty && this.internalValue.length <= 1) {
				this.deactivate();
				return;
			}

			const index = typeof option === 'object'
				? this.valueKeys.indexOf(option[this.trackBy])
				: this.valueKeys.indexOf(option);

			this.$emit('remove', option, this.id);
			if (this.multiple) {
				let newValue = this.internalValue.slice(0, index).concat(this.internalValue.slice(index + 1));
				newValue = newValue.map(v => this.return ? v[this.return] : v);
				this.$emit('input', newValue, this.id);
			} else {
				this.$emit('input', null, this.id);
			}

			/* istanbul ignore else */
			if (this.closeOnSelect && shouldClose) this.deactivate();
		},
		setInitialValue() {
			if (typeof this.value !== 'undefined' && this.value !== null && this.value !== '') {
				this.currentValue = this.getInternalValue(this.value);
			}
		}
	}
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
	@import './Select.scss';
</style>