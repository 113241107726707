var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "transfer-layout"
    }
  }, [_vm._m(0), _c('tabs', {
    key: _vm.key,
    staticClass: "mt-4"
  }, [_c('tab', {
    attrs: {
      "title": "Transfer",
      "active": _vm.tab === 0,
      "disabled": !_vm.access.sepa
    },
    on: {
      "click": function click() {
        return _vm.$router.push('/transfer/simple');
      }
    }
  }, [_vm.access.sepa ? _c('div', [_c('nano-transfer', {
    attrs: {
      "transfer-data": _vm.transactionData,
      "transaction-template": _vm.transactionTemplate
    },
    on: {
      "go-to-pending": function goToPending($event) {
        return _vm.goToPending();
      }
    }
  })], 1) : _vm._e()]), _c('tab', {
    attrs: {
      "title": "Multicurrency",
      "active": _vm.tab === 1,
      "disabled": !_vm.access.swift
    },
    on: {
      "click": function click() {
        return _vm.$router.push('/transfer/multicurrency');
      }
    }
  }, [_vm.access.swift ? _c('div', [_c('currency-cloud-transfer', {
    attrs: {
      "transfer-data": _vm.transactionData,
      "transaction-template": _vm.transactionTemplate
    },
    on: {
      "go-to-pending": function goToPending($event) {
        return _vm.goToPending();
      }
    }
  })], 1) : _vm._e()]), _c('tab', {
    attrs: {
      "title": "Bulk Payments",
      "active": _vm.tab === 2,
      "disabled": !_vm.access.bulk
    },
    on: {
      "click": function click() {
        return _vm.$router.push('/transfer/bulk');
      }
    }
  }, [_vm.access.bulk ? _c('div', [_c('bulk-payments')], 1) : _vm._e()]), _vm.user.role === 'business' ? _c('tab', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.user.role === 'business' && _vm.access.pending,
      expression: "user.role === 'business' && access.pending"
    }],
    attrs: {
      "title": "For confirmation",
      "active": _vm.tab === 3,
      "disabled": !_vm.access.pending
    },
    on: {
      "click": function click() {
        return _vm.$router.push('/transfer/pending');
      }
    }
  }, [_vm.access.pending ? _c('div', [_c('transaction-initiate-table')], 1) : _vm._e()]) : _vm._e(), _vm.user.role === 'business' ? _c('tab', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.user.role === 'business' && _vm.access.template,
      expression: "user.role === 'business' && access.template"
    }],
    attrs: {
      "title": "My template",
      "active": _vm.tab === 4,
      "disabled": !_vm.access.template
    },
    on: {
      "click": function click() {
        return _vm.$router.push('/transfer/template');
      }
    }
  }, [_vm.access.template ? _c('div', [_c('transaction-template-table', {
    on: {
      "update-template": _vm.updateFromTemplate
    }
  })], 1) : _vm._e()]) : _vm._e()], 1), !_vm.verified ? _c('div', {
    staticClass: "my-4 text-center"
  }, [_c('verification-card')], 1) : _vm._e()], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-title-container"
  }, [_c('h2', [_vm._v("Transfer")])]);
}]

export { render, staticRenderFns }