var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    staticClass: "form-body margintop30 account-info-form",
    attrs: {
      "action": "#",
      "autocomplete": "disable",
      "disabled": _vm.details.verification == 'pending'
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onsubmit();
      }
    }
  }, [_c('h2', {
    staticClass: "page-title"
  }, [_vm._v(" Account Information ")]), _c('form-error-field', {
    staticClass: "position-relative",
    attrs: {
      "name": "accountInfoUse.values",
      "wrapper": "div"
    }
  }, [_c('verify-account-info-use', {
    staticClass: "mb-4",
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('p', {
          staticClass: "label"
        }, [_c('b', [_vm._v("Use of Account")])])];
      },
      proxy: true
    }, {
      key: "other-label",
      fn: function fn() {
        return [_c('p', {
          staticClass: "label"
        }, [_c('b', [_vm._v("Other use of Account")])])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.data.accountInfoUse,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "accountInfoUse", $$v);
      },
      expression: "data.accountInfoUse"
    }
  })], 1), _c('b-row', [_c('b-col', [_c('p', {
    staticClass: "label"
  }, [_c('b', [_vm._v("Scope of services")])])])], 1), _c('form-error-field', {
    attrs: {
      "name": "accountInfoScope.values",
      "wrapper": "div"
    }
  }, [_c('checkbox-list', {
    attrs: {
      "items": _vm.accountInfoScopeOptions,
      "value": _vm.data.accountInfoScope.values,
      "data-registration": "scope-of-services"
    },
    on: {
      "change": function change(v) {
        return _vm.toggleScopeValue(v);
      }
    },
    scopedSlots: _vm._u([{
      key: "swift_multi_currency",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.currencies",
            modifiers: {
              "currencies": true
            }
          }],
          attrs: {
            "href": ""
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              $event.stopPropagation();
            }
          }
        }, [_c('small', [_vm._v("List of supported currencies")])])];
      },
      proxy: true
    }])
  })], 1), _c('b-row', {
    staticClass: "mt-4"
  }, [_c('b-col', {
    staticClass: "text-center"
  }, [_c('b-collapse', {
    attrs: {
      "id": "currencies"
    }
  }, [_c('b-card', {
    staticClass: "mt-4"
  }, [_c('b-row', _vm._l(_vm.currencies, function (c) {
    return _c('b-col', {
      key: c.currencyId,
      staticClass: "flag-image",
      attrs: {
        "cols": "3"
      }
    }, [_c('img', {
      attrs: {
        "src": _vm.flagImg(c.country),
        "alt": c
      }
    }), _c('div', [_vm._v(" " + _vm._s(c.name) + " (" + _vm._s(c.currencyId.toUpperCase()) + ") ")])]);
  }), 1), _c('b-row', [_c('b-col', [_c('i', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.currencies",
      modifiers: {
        "currencies": true
      }
    }],
    staticClass: "fas fa-chevron-up close-icon"
  })])], 1)], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "mt-4 mb-4 text-center"
  }, [_c('notification'), _c('input', {
    "class": _vm.buttonClass,
    attrs: {
      "disabled": !_vm.formFilled,
      "type": "submit",
      "data-registration": "next"
    },
    domProps: {
      "value": _vm.buttonText
    }
  })], 1)], 1), _vm.allowSkip ? _c('b-row', [_c('b-col', {
    staticClass: "text-center"
  }, [_c('router-link', {
    staticClass: "framelink skiplink",
    attrs: {
      "to": {
        name: 'home'
      },
      "tag": "a",
      "exact": ""
    }
  }, [_vm.details.verification !== 'pending' ? _c('span', [_vm._v("Skip verification")]) : _vm._e(), _vm.details.verification == 'pending' ? _c('span', [_vm._v("Back to main page")]) : _vm._e()])], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }