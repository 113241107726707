var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "account-details "
  }, [_vm.data.address ? _c('div', {
    staticClass: "account-details profile-business-registration-address",
    attrs: {
      "id": "profile-business-registration-address"
    }
  }, [_c('h3', {
    staticClass: "mb-4 mb-lg-5 font-weight-bold"
  }, [_vm._v(" Business Registration address ")]), _vm.data.addresses.main.address ? _c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('label', {
    staticClass: "col-6 col-sm-5 col-xl-4 col-form-label"
  }, [_vm._v("Address")]), _c('div', {
    staticClass: "col-6 col-sm-7 col-xl-6"
  }, [_c('strong', [_vm._v(_vm._s(_vm.data.addresses.main.address))])])])]) : _vm._e(), _vm.data.addresses.main.city ? _c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('label', {
    staticClass: "col-6 col-sm-5 col-xl-4 col-form-label"
  }, [_vm._v("City")]), _c('div', {
    staticClass: "col-6 col-sm-7 col-xl-6"
  }, [_c('strong', [_vm._v(_vm._s(_vm.data.addresses.main.city))])])])]) : _vm._e(), _vm.data.addresses.main.state ? _c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('label', {
    staticClass: "col-6 col-sm-5 col-xl-4 col-form-label"
  }, [_vm._v("State")]), _c('div', {
    staticClass: "col-6 col-sm-7 col-xl-6"
  }, [_c('strong', [_vm._v(_vm._s(_vm.data.addresses.main.state))])])])]) : _vm._e(), _vm.data.addresses.main.countryId ? _c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('label', {
    staticClass: "col-6 col-sm-5 col-xl-4 col-form-label"
  }, [_vm._v("Country")]), _c('div', {
    staticClass: "col-6 col-sm-7 col-xl-6"
  }, [_c('strong', {
    staticClass: "text-uppercase"
  }, [_vm._v(_vm._s(_vm.data.addresses.main.countryId))])])])]) : _vm._e(), _vm.data.addresses.main.zipCode ? _c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('label', {
    staticClass: "col-6 col-sm-5 col-xl-4 col-form-label"
  }, [_vm._v("ZIP code")]), _c('div', {
    staticClass: "col-6 col-sm-7 col-xl-6"
  }, [_c('strong', [_vm._v(_vm._s(_vm.data.addresses.main.zipCode))])])])]) : _vm._e()]) : _vm._e(), _vm.data.addresses.correspondence ? _c('div', {
    staticClass: "account-details profile-business-correspondence-address",
    attrs: {
      "id": "profile-business-correspondence-address"
    }
  }, [_c('h3', {
    staticClass: "mb-4 mb-lg-5 font-weight-bold"
  }, [_vm._v(" Business Correspondence address ")]), _vm.data.addresses.correspondence.address ? _c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('label', {
    staticClass: "col-6 col-sm-5 col-xl-4 col-form-label"
  }, [_vm._v("Address")]), _c('div', {
    staticClass: "col-6 col-sm-7 col-xl-6"
  }, [_c('strong', [_vm._v(_vm._s(_vm.data.addresses.correspondence.address))])])])]) : _vm._e(), _vm.data.addresses.correspondence.city ? _c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "row form-group align-items-center"
  }, [_c('label', {
    staticClass: "col-6 col-sm-5 col-xl-4 col-form-label"
  }, [_vm._v("City")]), _c('div', {
    staticClass: "col-6 col-sm-7 col-xl-6"
  }, [_c('strong', [_vm._v(_vm._s(_vm.data.addresses.correspondence.city))])])])]) : _vm._e(), _vm.data.addresses.correspondence.state ? _c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('label', {
    staticClass: "col-6 col-sm-5 col-xl-4 col-form-label"
  }, [_vm._v("State")]), _c('div', {
    staticClass: "col-6 col-sm-7 col-xl-6"
  }, [_c('strong', [_vm._v(_vm._s(_vm.data.addresses.correspondence.state))])])])]) : _vm._e(), _vm.data.addresses.correspondence.countryId ? _c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('label', {
    staticClass: "col-6 col-sm-5 col-xl-4 col-form-label"
  }, [_vm._v("Country")]), _c('div', {
    staticClass: "col-6 col-sm-7 col-xl-6"
  }, [_c('strong', {
    staticClass: "text-uppercase"
  }, [_vm._v(_vm._s(_vm.data.addresses.correspondence.countryId))])])])]) : _vm._e(), _vm.data.addresses.correspondence.zipCode ? _c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('label', {
    staticClass: "col-6 col-sm-5 col-xl-4 col-form-label"
  }, [_vm._v("ZIP code")]), _c('div', {
    staticClass: "col-6 col-sm-7 col-xl-6"
  }, [_c('strong', [_vm._v(_vm._s(_vm.data.addresses.correspondence.zipCode))])])])]) : _vm._e()]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }