<template>
	<div class="list-row">
		<b-row class="align-items-center">
			<b-col 
				cols="auto" 
				md="2" 
				order-md="2"
			>
				<span class="action text-capitalize">{{ tableItem.action | normalizeActionText }}</span>
			</b-col>
			<b-col 
				cols="auto" 
				md="3" 
				order-md="1" 
				class="ml-auto ml-md-0"
			>
				<span class="date">{{ tableItem.dateCreated | timestampToLocalDateTime }}</span>
			</b-col>
			<b-col 
				md="2" 
				order-md="3"
			>
				<span class="ip">{{ tableItem.ip }}</span>
			</b-col>
			<b-col 
				md="3" 
				order-md="4"
			>
				<span class="browser">{{ tableItem.browser }}</span>
			</b-col>
			<b-col 
				md="2" 
				order-md="5"
			>
				<span class="country">{{ getCountry(tableItem.countryId) }}</span>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';

const REPLACE_UNDERSCORE_REGEX = /_/g;
const TIME_FORMAT = 'Y-MM-DD H:mm:ss';

export default {
	name: 'ActivityTableItem',
	filters: {
		normalizeActionText: function (value) {
			return value.replace(REPLACE_UNDERSCORE_REGEX, ' ');
		},
		timestampToLocalDateTime: function (value) {
			return moment(value).format(TIME_FORMAT);
		},
	},
	props: {
		tableItem: {
			default: null,
			type: Object,
		},
	},
	computed: {
		...mapState('countries', {
			countryList: state => state.list
		})
	},
	methods: {
		getCountry(countryId) {
			return this.countryList.find(country => country.countryId == countryId)?.name;
		}
	}
};
</script>
<style lang="scss" scoped>
	@import './ActivityTableItem.scss';
</style>