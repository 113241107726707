var render = function () {
  var _vm$currentWalletAmou, _vm$currentWalletAmou2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-sidebar"
  }, [_c('div', {
    staticClass: "sidebar-wrapper d-flex flex-column"
  }, [_c('DashboardHeader'), _c('div', {
    staticClass: "switchers"
  }, [_vm.user ? _c('div', {
    staticClass: "switcher switcher-account d-flex align-items-center"
  }, [_c('div', {
    staticClass: "avatar"
  }, [_c('span', [_vm._v(_vm._s(_vm.setUsername(_vm.user).charAt(0)))])]), _c('div', {
    staticClass: "current-item-details align-self-center"
  }, [_c('div', {
    staticClass: "name"
  }, [_vm._v(" " + _vm._s(_vm.setUsername(_vm.user)) + " ")])]), _c('div', {
    staticClass: "verification-status align-self-center ml-auto"
  }, [_vm.user.verification === 'verified' ? _c('div', {
    staticClass: "d-flex text-success"
  }, [_c('span', {
    staticClass: "status-text"
  }, [_vm._v("Verified")]), _c('span', {
    staticClass: "status-icon verified"
  })]) : _vm.user.verification === 'pending' ? _c('div', {
    staticClass: "d-flex text-warning"
  }, [_c('span', {
    staticClass: "status-text"
  }, [_vm._v("Pending")]), _c('span', {
    staticClass: "status-icon pending-verification"
  })]) : _c('div', {
    staticClass: "d-flex text-danger"
  }, [_c('span', {
    staticClass: "status-text"
  }, [_vm._v("Not verified")]), _c('span', {
    staticClass: "status-icon not-verified"
  })])]), _c('div', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.user-switch-modal",
      modifiers: {
        "user-switch-modal": true
      }
    }],
    staticClass: "switch-arrow"
  }, [_c('span', [_c('ion-icon', {
    staticClass: "drowdown-icon",
    attrs: {
      "name": "chevron-down-outline"
    }
  })], 1)])]) : _vm._e(), _vm.currentWallet ? _c('div', {
    staticClass: "switcher switcher-wallet d-flex align-items-center"
  }, [_c('div', {
    staticClass: "avatar"
  }, [_c('span', [_vm._v(_vm._s(_vm.currentWallet.name.charAt(0)))])]), _c('div', {
    staticClass: "current-item-details align-self-center"
  }, [_c('div', {
    staticClass: "name"
  }, [_vm._v(" " + _vm._s(_vm.currentWallet.name) + " ")]), _c('small', {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.currentWallet.iban))])]), _c('div', {
    staticClass: "wallet-info align-self-center ml-auto"
  }, [_c('div', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.acc-info-modal",
      modifiers: {
        "acc-info-modal": true
      }
    }],
    staticClass: "account-info-icon"
  }, [_c('ion-icon', {
    attrs: {
      "name": "information-circle-outline"
    }
  })], 1)]), _c('div', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.wallet-switch-modal",
      modifiers: {
        "wallet-switch-modal": true
      }
    }],
    staticClass: "switch-arrow"
  }, [_c('ion-icon', {
    staticClass: "drowdown-icon",
    attrs: {
      "name": "chevron-down-outline"
    }
  })], 1)]) : _vm._e()]), _c('div', {
    staticClass: "current-balance"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col-12 col-sm-auto mr-auto total-balance total-balance-desktop"
  }, [_c('div', {
    staticClass: "current-balance-text"
  }, [_c('p', [_vm._v("Current balance")]), _c('span', {
    staticClass: "hide-balance",
    "class": {
      'show-balance': !_vm.showUserBalance
    },
    on: {
      "click": function click($event) {
        return _vm.hideBalance();
      }
    }
  })]), _vm.user && _vm.currentWallet ? _c('span', {
    staticClass: "balance",
    "class": {
      blur: !_vm.showUserBalance
    },
    on: {
      "click": function click($event) {
        return _vm.hideBalance();
      }
    }
  }, [_vm._v(_vm._s(_vm.currentWalletAmount.balance) + " "), _c('small', [_vm._v(_vm._s(_vm.currentWalletAmount.currencyId ? _vm.currentWalletAmount.currencyId.toUpperCase() : ''))])]) : _vm._e()]), _vm.currentWallet && _vm.currentWallet.status === 'active' ? _c('div', {
    staticClass: "col-auto new-payment"
  }, [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.bottom",
      value: 'Export statement',
      expression: "'Export statement'",
      modifiers: {
        "hover": true,
        "bottom": true
      }
    }, {
      name: "b-modal",
      rawName: "v-b-modal.export-statements-modal",
      modifiers: {
        "export-statements-modal": true
      }
    }],
    staticClass: "export-wrapper mr-3 d-none d-sm-flex"
  }, [_c('ion-icon', {
    attrs: {
      "name": "download-outline"
    }
  })], 1), _vm.isWalletGranted('initiate_transaction', _vm.currentWallet.walletId) ? _c('button', {
    staticClass: "btn btn-primary px-4",
    attrs: {
      "exact": ""
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.pushRefresh({
          name: 'transfer',
          query: {
            "new": null
          }
        });
      }
    }
  }, [_vm._v(" New transfer ")]) : _vm._e(), _c('button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.export-statements-modal",
      modifiers: {
        "export-statements-modal": true
      }
    }],
    staticClass: "btn btn-light ml-3 px-4 d-sm-none"
  }, [_vm._v(" Statement ")])]) : _vm._e()]), parseFloat((_vm$currentWalletAmou = _vm.currentWalletAmount) === null || _vm$currentWalletAmou === void 0 ? void 0 : _vm$currentWalletAmou.debt) > 0 ? _c('div', {
    staticClass: "current-balance-debt"
  }, [_c('div', {
    staticClass: "current-balance-debt--label"
  }, [_vm._v(" Outstanding Debt: ")]), _c('p', {
    staticClass: "current-balance-debt--text",
    "class": {
      blur: !_vm.showUserBalance
    }
  }, [_vm._v(" " + _vm._s(_vm.currentWalletAmount.debt) + " "), _c('small', [_vm._v(_vm._s(_vm.currentWalletAmount.currencyId ? _vm.currentWalletAmount.currencyId.toUpperCase() : ''))])])]) : _vm._e(), parseFloat((_vm$currentWalletAmou2 = _vm.currentWalletAmount) === null || _vm$currentWalletAmou2 === void 0 ? void 0 : _vm$currentWalletAmou2.block) > 0 ? _c('div', {
    staticClass: "current-balance-debt"
  }, [_c('div', {
    staticClass: "current-balance-debt--label"
  }, [_vm._v(" Reserved funds: ")]), _c('p', {
    staticClass: "current-balance-debt--text",
    "class": {
      blur: !_vm.showUserBalance
    }
  }, [_vm._v(" " + _vm._s(_vm.currentWalletAmount.block) + " "), _c('small', [_vm._v(_vm._s(_vm.currentWalletAmount.currencyId ? _vm.currentWalletAmount.currencyId.toUpperCase() : ''))])])]) : _vm._e(), _c('CardsMenu')], 1), _c('Navigation'), _c('DashboardFooter')], 1), _c('UserSwitchModal'), _c('WalletSwitchModal'), _c('AccountInfoModal'), _c('ExportStatements')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }