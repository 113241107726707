<template>
	<b-form-group
		:id="id"
		:label="label"
		:label-for="`${id}-input`"
	>
		<b-form-input
			:id="`${id}-input`"
			v-model="inputValue"
			v-validate="vValidate"
			:name="name"
			:type="type"
			:placeholder="placeholder || label"
			:state="!errors.has(name) ? null : false"
			:class="{'input-invalid': errors.has(name)}"
		/>
		<b-form-invalid-feedback :id="`${id}-input-feedback`">
			{{ errors.first(name) }}
		</b-form-invalid-feedback>
	</b-form-group>
</template>
<script>
export default {
	name: 'OddInput',
	props: {
		id: { type: String, default: `${parseInt(Math.random() * 10000)}` },
		name: { type: String, default: `${parseInt(Math.random() * 10000)}` },
		label: { type: String, default: '' },
		value: { type: String, default: null },
		vValidate: { type: String, default: '' },
		type: { type: String, default: 'text' },
		placeholder: { type: String, default: null }
	},
	computed: {
		inputValue: {
			get() { return this.value; },
			set(inputValue) { this.$emit('input', inputValue);}
		}
	}
};
</script>
<style lang="scss">
	
</style>