import { endpoints } from '~/config';
import { SET_COUNTRIES_PROMISE, SET_COUNTRIES } from '~/store/mutation-types';

export default {
	async getCountries({ state, commit }) {
		const type = 'list';
		if (state[type].length > 0) return Promise.resolve(state[type]);
		if (state.promises[type]) return state.promises[type];
		const options = {
			method: 'GET',
			url: endpoints.countries.list
		};
		const promise = axios(options)
			.then(async response => {
				const { data } = response;
				await commit(SET_COUNTRIES, { value: data, type });
				Promise.resolve(state.list);
			})
			.catch(err => {
				Promise.reject(err.message);
			});
		commit(SET_COUNTRIES_PROMISE, { value: promise, type });
		return promise;
	},
	async getSwiftCountries({ state, commit }) {
		const type = 'listSwift';
		if (state[type].length > 0) return Promise.resolve(state[type]);
		if (state.promises[type]) return state.promises[type];
		const options = {
			method: 'GET',
			url: endpoints.countries.listSwift
		};
		const promise = axios(options)
			.then(async response => {
				const { data } = response;
				await commit(SET_COUNTRIES, { value: data, type });
				Promise.resolve(state.list);
			})
			.catch(err => {
				Promise.reject(err.message);
			});
		commit(SET_COUNTRIES_PROMISE, { value: promise, type });
		return promise;
	},
	async getPhoneCountries({ state, commit }) {
		const type = 'listPhone';
		if (state[type].length > 0) return Promise.resolve(state[type]);
		if (state.promises[type]) return state.promises[type];
		const options = {
			method: 'GET',
			url: endpoints.countries.listPhone
		};
		const promise = axios(options)
			.then(async response => {
				const { data } = response;
				await commit(SET_COUNTRIES, { value: data, type });
				Promise.resolve(state.list);
			})
			.catch(err => {
				Promise.reject(err.message);
			});
		commit(SET_COUNTRIES_PROMISE, { value: promise, type });
		return promise;
	},
};