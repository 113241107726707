import {
	UNSET_USER,
	SET_USER,
	SET_USER_LIST,
	UPDATE_PROFILE,
	UPDATE_COMPANY,
	SET_USER_VERIFIED,
	UPDATE_QUESTIONNAIRE,
	SET_USER_PERMISSIONS,
	SET_WALLET_PERMISSIONS,
	SET_USER_AUTHENTICATOR,
	SET_USER_AUTHENTICATOR_LIST,
	SET_USER_CUSTOMER_AGREEMENT,
	SET_USER_POPUPS,
	SET_ODD_INCOMPLETE,
} from '~/store/mutation-types';
import * as Sentry from '@sentry/vue';

import { endpoints } from '~/config';

export default {
	async setUserData({ commit, dispatch }, user) {
		commit(SET_USER, user);
		try {
			await dispatch('wallet/getWalletList', null, { root: true });
			await dispatch('cards/initCardData', true, { root: true });
		} catch (err) {
			throw new Error(err);
		}
		return user;
	},
	async getUserList({ commit }) {
		const options = {
			method: 'GET',
			url: endpoints.user.list
		};
		try {
			const response = await axios(options);
			const { data } = response;
			commit(SET_USER_LIST, data);
			return Promise.resolve(data);
		} catch (err) {
			return Promise.reject(err.message);
		}
	},
	async selectUser({ dispatch }, profileId) {
		const options = {
			method: 'PUT',
			url: endpoints.user.switch,
			data: { profileId }
		};
		try {
			const response = await axios(options);
			const { data } = response;
			await dispatch('getUserProfile');
			return Promise.resolve(data);
		} catch (err) {
			return Promise.reject(err.message);
		}
	},
	// Set current user
	async getUserProfile({ dispatch, getters }) {
		const options = {
			method: 'GET',
			url: endpoints.user.current
		};
		try {
			const response = await axios(options);
			const { data } = response;
			await dispatch('getSetting', data);
			Sentry.setUser({
				'id': data.profileId,
				'email': getters.currentPerson?.contacts?.mainEmail,
			});

			return Promise.resolve(data);
		} catch (err) {
			return Promise.reject(err.message);
		}
	},
	// Set current user
	async getCurrentProfile({ dispatch }) {
		const options = {
			method: 'GET',
			url: endpoints.user.current
		};
		try {
			const response = await axios(options);
			const { data } = response;
			await dispatch('setUserData', data);
			return Promise.resolve(data);
		} catch (err) {
			return Promise.reject(err.message);
		}
	},
	async getSetting({ commit, dispatch }, userData) {
		const options = {
			method: 'GET',
			url: endpoints.user.setting
		};
		try {
			const response = await axios(options);
			const { data } = response;
			commit(SET_USER_PERMISSIONS, data.permissions);
			commit(SET_WALLET_PERMISSIONS, data.walletPermissions);
			commit(SET_USER_AUTHENTICATOR, data.authenticator);
			commit(SET_USER_AUTHENTICATOR_LIST, data.availableAuthenticatorList);
			if (userData) {
				await dispatch('setUserData', { ...userData });
			}
			await dispatch('setPopups', data);
			return data;
		} catch (err) {
			return Promise.reject(err.message);
		}
	},
	setAuthenticatorList({ commit }, list) {
		commit(SET_USER_AUTHENTICATOR_LIST, list);
	},
	setPopups({ commit }, data) {
		const popups = data.popups.map(p => {
			return typeof p == 'string' ? p : p.type;
		});

		// const popups = ['odd_company_relation', 'odd_company_questionnaire', 'odd_company_information', 'odd_company_turnover', 'odd_company_document', 'odd_company_identity'];
		// const popups = ['odd_company_turnover'];
		commit(SET_USER_POPUPS, popups);
		commit(SET_USER_CUSTOMER_AGREEMENT, {
			showPrivacyPolicy: popups.includes('privacy_policy'),
			showServiceAgreement: popups.includes('service_agreement'),
		});
	},
	async setUserCustomerAgreement({ commit }, value) {
		commit(SET_USER_CUSTOMER_AGREEMENT, value);
	},
	async setUserAuthenticator({ commit }, method) {
		commit(SET_USER_AUTHENTICATOR, method);

	},
	remove({ commit }) {
		commit(UNSET_USER);
	},
	//Verification
	updateProfile({ commit }, value) {
		commit(UPDATE_PROFILE, value);
	},
	updateCompany({ commit }, value) {
		commit(UPDATE_COMPANY, value);
	},
	async submitAccountInfo({ commit }, value) {
		const options = {
			method: 'POST',
			url: endpoints.user.accountInfo,
			data: value
		};
		try {
			const response = await axios(options);
			const { data } = response;
			commit(UPDATE_COMPANY, data.companyProfile);
			return Promise.resolve(data);
		} catch (err) {
			return Promise.reject(err);
		}
	},	
	async submitCompanyInfo({ commit }, value) {
		const options = {
			method: 'POST',
			url: endpoints.user.companyInfo,
			data: value
		};
		try {
			const response = await axios(options);
			const { data } = response;
			commit(UPDATE_COMPANY, data.companyProfile);
			return Promise.resolve(data);
		} catch (err) {
			return Promise.reject(err);
		}
	},
	async submitVerifyPerson({ getters, dispatch }, value) {
		const options = {
			method: 'POST',
			url: getters.isBusiness ? endpoints.user.companyProfile : endpoints.user.userProfile,
			data: value
		};
		try {
			const response = await axios(options);
			const { data } = response;
			await dispatch('setUserData', { ...data });
			return Promise.resolve(data);
		} catch (err) {
			return Promise.reject(err);
		}
	},
	setVerified({ commit }, value) {
		commit(SET_USER_VERIFIED, value);
	},
	updateQuestionnaire({ commit }, value) {
		commit(UPDATE_QUESTIONNAIRE, value);
	},
	async submitBusinessQuestionnaireData({ dispatch }, data) {
		const options = {
			method: 'POST',
			url: endpoints.user.companyQuestionnaire,
			data
		};

		try {
			const response = await axios(options);
			const { data } = response;
			await dispatch('setUserData', { ...data });
			return Promise.resolve(data);
		} catch (err) {
			return Promise.reject(err);
		}
	},
	async submitUserQuestionnaireData({ dispatch }, data) {
		const options = {
			method: 'POST',
			url: endpoints.user.userQuestionnaire,
			data
		};
		try {
			const response = await axios(options);
			const { data } = response;
			await dispatch('setUserData', { ...data });
			return Promise.resolve(data);
		} catch (err) {
			return Promise.reject(err);
		}
	},
	setOddIncomplete({ commit }, value) {
		commit(SET_ODD_INCOMPLETE, value);
	}
};
