import { whitelabel } from '~/config/whitelabel';

const config = {
	api: whitelabel.api,
	paymentGateway: whitelabel.paymentGateway,
	siteName: whitelabel.name || 'Verifo',
	email: whitelabel.email || 'welcome@verifo.com',
	supportEmail: whitelabel.supportEmail || 'support@verifo.com',
	sentry: whitelabel.sentry,
	agreementUrl: whitelabel.agreementUrl || 'https://verifo.com/agreement/',
	supportUrl: whitelabel.supportUrl || 'https://www.verifo.com/contacts/',
	privacyPolicyUrl: whitelabel.privacyPolicyUrl || 'https://verifo.com/privacy-policy/',
	cookiePolicyUrl: whitelabel.cookiePolicyUrl || 'https://verifo.com/cookie-policy/',
	registration: {
		roles: [
			{
				title: 'Business', 
				value: 'business_unverified',
				description: 'Register account as company. It will require to add representatives.'
			}, 
			{
				title: 'Personal', 
				value: 'user_unverified',
				description: 'Register personal account for personal banking usage.'
			}
		]
	},
	refreshCookieName: (whitelabel.brand || 'verifo')+'_frontend_public_refresh_token',
	recaptchaSitekey: '6LcAFtwUAAAAAD5Dnq_f8jTuFG5w_BUO0uEI-k5i',
	fileUploadSizeLimit: 26214400, // 25Mb
	referral: {
		commission: whitelabel.referral && whitelabel.referral.commission || {
			value: '20%',
			hidden: false
		},
		commissionSwift: whitelabel.referral && whitelabel.referral.commissionSwift || {
			value: '10%',
			hidden: false
		},
		markup:  whitelabel.referral && whitelabel.referral.markup || {
			value:'50%',
			hidden: false
		}
	}
};
export const siteName = config.siteName;
export const supportEmail = config.supportEmail;
export const api = config.api;
export const paymentGateway = config.paymentGateway;
export const refreshCookieName = config.refreshCookieName;
export const fileUploadSizeLimit = config.fileUploadSizeLimit;
export { config as default };
