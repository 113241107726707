<template>
	<div>
		<h4>Security</h4>
		<div role="tablist">
			<b-card 
				v-for="(faq, index) in dataJson" 
				:key="faq.title + faq.key"
			>
				<b-card-header 
					class="accHeader" 
					header-tag="header" 
					role="tab"
				>
					<h5 v-b-toggle="faq.title + index">
						{{ faq.question }}
					</h5>
				</b-card-header>
				<b-collapse 
					:id="faq.title + index" 
					visible 
					accordion="my-accordion" 
					role="tabpanel"
				>
					<b-card-body class="inner">
						<p 
							class="card-text" 
							v-html="faq.text"
						></p>
					</b-card-body>
				</b-collapse>
			</b-card>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		data: Array
	},
	data () {
		return {
			dataJson: this.data.filter(d => d.title == 'SECURITY')
		};
	}
};
</script>
