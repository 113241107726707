<template>
	<b-row>
		<b-col>
			<form-error-field name="businessActivities.values">
				<slot name="label"></slot>
				<v-multiselect
					v-model="innerValue.values"
					v-validate="'required'"
					:options="options"
					:multiple="true"
					:allow-empty="true"
					:close-on-select="false"
					:clear-on-select="true"
					name="businessActivities.values"
					label="name"
					placeholder="Select Your business activity"
					return="value"
					track-by="name"
					:max-height="9999"
					data-registration="business-activity"
					data-vv-as="Business activity"
				/>
			</form-error-field>
		



			<form-error-field v-if="innerValue.values.includes('other')"
				name="businessActivities.otherValue"
				class="text-left mb-12"
			>
				<slot name="other-label">
				</slot>
				<input
					id="business_activity_other"
					v-model="innerValue.otherValue"
					v-validate="requiredBusinessActivity"
					type="text"
					class="form-control"
					name="businessActivities.otherValue"
					placeholder="Specify other activity"
					maxlength="200"
					data-registration="business-activity-other"
					data-vv-as="Other"
				>
			</form-error-field>
		</b-col>
	</b-row>
</template>
<script>
import options from './QuestionnaireBusinessOptions';
import FormErrorField from '@/shared/Form/FormErrorField';

export default {
	name: 'QuestionnaireBusinessActivities',
	inject: ['$validator'],
	components: { FormErrorField },
	props: {
		value: {
			type: Object,
			required: true
		},
		odd: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		options() {
			return options.businessActivitiesTypes
				.sort((a, b) => a.name < b.name ? -1 : 1)
				.sort((a, b) => b.value == 'other' ? -1 : 1);
		},
		requiredBusinessActivity() {
			return this.innerValue.values.includes('other') ? 'required' : '';
		},
		innerValue() {
			return this.value;
		}
	},
	mounted() {
		this.$validator.pause();
		this.$nextTick(() => this.$validator.resume());
	}
};
</script>
<style lang="scss">
	
</style>