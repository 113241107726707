<template>
	<div id="layout-bank">
		<idle-notification />
		<div class="main-content-wrapper">
			<div class="sidebar-spacer">
				<sidebar />
			</div>
			<main class="main">
				<transition 
					name="fade" 
					mode="out-in"
				>
					<router-view :key="refreshKey" />
				</transition>
			</main>
			<popups />
		</div>
		<agreements
			ref="agreements"
			:on-close="() => $router.push({name: 'login'})"
			close-label="Logout"
		/>
	</div>
</template>
<script>
import { mapState } from 'vuex';
import Agreements from '../Agreements/Agreements';
import Sidebar from './Sidebar/Sidebar';
import Popups from './Popups/Popups';
export default {
	name: 'LayoutBank',
	components : {
		Sidebar,
		Agreements,
		Popups,
	},
	data() {
		return {
			serviceAgreementPopup: false,
			privacyPolicyPopup: false,
			loading: false,
		};
	},
	computed: {
		...mapState('routes', [
			'refreshKey'
		]),
		...mapState('user', [
			'customerAgreement'
		]),
		serviceAgreementValues() {
			const ca = this.customerAgreement;
			return [ca.showServiceAgreement, ca.showPrivacyPolicy];
		}
	},
	watch: {
		serviceAgreementValues: {
			deep: true,
			handler: function() {
				this.checkForAgreement();
			}
		}
	},
	mounted() {
		this.checkForAgreement();
	},
	methods: {
		async checkForAgreement() {
			const ca = this.customerAgreement;
			if (ca.showServiceAgreement) {
				await this.$refs.agreements.open('service_agreement');
			}
			if (ca.showPrivacyPolicy) {
				await this.$refs.agreements.open('privacy_policy');
			}
		}
	}
};
</script>

<style lang="scss">
.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to {
	opacity: 0;
}
.pdf-app.light {
	background-color: transparent;

	.page {
		border-image: none;
	}
}
</style>
