var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', [_c('b-col', [_c('span', {
    staticClass: "card-order__subtitle"
  }, [_vm._v("Ordering Card for Account")])])], 1), _c('b-row', [_c('b-col', {
    staticClass: "card-order__account-info"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_vm._v(" Profile: ")]), _c('b-col', {
    attrs: {
      "md": "9"
    }
  }, [_vm._v(" " + _vm._s(_vm.user.fullName) + " ")])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_vm._v(" Account Name: ")]), _c('b-col', {
    attrs: {
      "md": "9"
    }
  }, [_vm._v(" " + _vm._s(_vm.currentWallet && (_vm.currentWallet.name || _vm.currentWallet.walletId)) + " ")])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_vm._v(" IBAN: ")]), _c('b-col', {
    staticClass: "card-order__iban",
    attrs: {
      "md": "9"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatIban(_vm.currentWallet && _vm.currentWallet.iban)) + " ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }