var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.listLoaded ? _c('div', {
    staticClass: "d-flex justify-content-center align-items-center py-4"
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  })], 1) : _vm._e(), _vm.listLoaded && _vm.ordersListLoaded ? _c('div', [_c('router-view')], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }