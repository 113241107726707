<template>
	<div>
		<h2>Enter online transaction password</h2>
		<img
			src="/images/icons/card-password.svg"
			alt="Card password"
		>
		<p class="my-4">
			Create password for online transactions
		</p>
		<label class="card-input-label">
			<span>Password</span>
			<input 
				v-model="data.password"
				v-validate="'min:6|max:32|required'"
				type="password"
				name="password"
				pattern="\d*"
				minlength="6"
				placeholder="Enter min. 6 symbol password"
				:class="errors.has('password') ? 'card-input-field-error' : ''"
			>
			<div
				v-if="errors.has('password')"
				class="card-input-error"
			>
				{{ errors.first('password') }}
			</div>
		</label>

		<label class="card-input-label">
			<span>Password</span>
			<input 
				v-model="data.confirmPassword"
				v-validate="'min:6|max:32|required'"
				type="password"
				name="confirmPassword"
				pattern="\d*"
				minlength="6"
				placeholder="Repeat password"
				:class="errors.has('confirmPassword') ? 'card-input-field-error' : ''"
			>
			<div
				v-if="errors.has('confirmPassword')"
				class="card-input-error"
			>
				{{ errors.first('confirmPassword') }}
			</div>
		</label>
		<div
			v-if="passwordsMatchError"
			class="match-error"
		>
			{{ passwordsMatchError }}
		</div>
	</div>
</template>
<script>

export default {
	name: 'Card3dPasswordForm',
	data() {
		return {
			data: {
				password: null,
				confirmPassword: null
			}
		};
	},
	computed: {
		passwordsMatchError() {
			const match = [this.data.password, this.data.confirmPassword];
			if (match[0] && match[1]) {
				if (match[0] !== match[1]) {
					return 'Passwords do not match.';
				}
			}
			return false;
		}
	},
	watch: {
		data: {
			handler: async function (value) {
				if (!this.data.confirmPassword || !this.data.confirmPassword) return this.$emit('input', null);
				if (this.data.password == this.data.confirmPassword) {
					const valid = await this.$validator.validate();
					if (valid) {
						this.$emit('input', value);						
					}
				} else {
					this.$emit('input', null);
				}
			},
			deep: true
		}
	}
};
</script>
<style lang="scss" scoped>
	.match-error {
		color: $red;
		font-size: 12px;
	}
</style>