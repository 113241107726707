var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card', [_c('h3', {
    staticClass: "mb-3"
  }, [_vm._v(" Incoming transaction complete ")]), _c('hr'), _vm.personList.length < 1 ? _c('div', [_vm._v(" There are no users to select from. ")]) : _vm._e(), _vm._l(_vm.personList, function (person) {
    return _c('b-input-group', {
      key: person.id,
      staticClass: "radioblock text-left mb-3"
    }, [_c('b-form-checkbox', {
      attrs: {
        "name": "transaction_complete",
        "value": person.id
      },
      model: {
        value: _vm.data.incomingTransactionComplete,
        callback: function callback($$v) {
          _vm.$set(_vm.data, "incomingTransactionComplete", $$v);
        },
        expression: "data.incomingTransactionComplete"
      }
    }, [_vm._v(" " + _vm._s("".concat(person.name, " ").concat(person.surname, " (").concat(person.email, ")")) + " ")])], 1);
  }), _c('hr'), _c('b-row', {
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', [_c('b-button', {
    staticClass: "w-100",
    attrs: {
      "disabled": _vm.personList.length == 0,
      "variant": "primary"
    },
    on: {
      "click": _vm.saveIncomingTransactionComplete
    }
  }, [!_vm.loading ? _c('span', [_vm._v("Save")]) : _vm._e(), _vm.loading ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e()], 1)], 1)], 1)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }