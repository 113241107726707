import {
	RECOVER_PASSWORD_LOADING,
	RECOVER_PASSWORD_SUCCESS,
	RECOVER_PASSWORD_FAILURE,
	SET_RECOVER_PASSWORD_DATA,
	RECOVER_PASSWORD_INITIALIZED
} from '~/store/mutation-types'; 


export default {
	[SET_RECOVER_PASSWORD_DATA](state, data) {
		state.email = data.email;
		state.phone = data.phone;
	},
	[RECOVER_PASSWORD_LOADING](state, payload) {
		state.status = {
			...state.status,
			loading: payload,
			success: false,
			failed: false,
			initialized: false
		};
	},
	[RECOVER_PASSWORD_FAILURE](state) {
		state.status = {
			...state.status,
			loading: false,
			success: false,
			failed: true,
			initialized: false
		};
	},
	[RECOVER_PASSWORD_SUCCESS](state) {
		state.status = {
			...state.status,
			loading: false,
			success: true,
			failed: false,
			initialized: false
		};
	},
	[RECOVER_PASSWORD_INITIALIZED](state) {
		state.status = {
			...state.status,
			loading: false,
			success: false,
			failed: false,
			initialized: true
		};
	}
};