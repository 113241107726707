<template>
	<b-modal 
		v-cloak 
		id="wallet-switch-modal" 
		centered
	>
		<!-- eslint-disable-next-line vue/no-unused-vars -->
		<template #modal-header="{ close }"></template>
		<!-- eslint-disable-next-line vue/no-unused-vars -->
		<template #default="{ hide }">
			<h5 class="mb-4">
				Select account
			</h5>
			<div class="switch-wallet-wrapper">
				<div class="wallets-list">
					<div class="wallet-item wallet-item-current d-flex align-items-center">
						<div class="avatar">
							<span>{{ currentWallet.name ? currentWallet.name.charAt(0) :
								currentWallet.walletId.charAt(0) }}</span>
						</div>
						<div class="current-item-details align-self-center">
							<div class="name">
								{{ currentWallet.name }}
							</div>
							<small class="number">{{ currentWallet.iban }}</small>
						</div>
						<div class="wallet-balance align-self-center ml-auto">
							{{ currentWalletAmount.balance }}
							<small>{{ currentWalletAmount.currencyId ? currentWalletAmount.currencyId.toUpperCase() : '' }}</small>
						</div>
						<div class="current-icon">
							<ion-icon
								class="check-icon"
								name="checkmark-done-outline"
							/>
						</div>
					</div>
					<div 
						v-for="wallet in walletList" 
						:key="wallet.walletId"
						class="wallet-item wallet-item-switch d-flex align-items-center"
						@click="switchWallet(wallet.walletId)"
					>
						<div class="avatar">
							<span>{{ wallet.name ? wallet.name.charAt(0) :
								wallet.walletId.charAt(0) }}</span>
						</div>
						<div class="current-item-details align-self-center">
							<div class="name">
								{{ wallet.name }}
							</div>
							<small class="number">{{ wallet.iban }}</small>
						</div>
						<div class="wallet-balance align-self-center ml-auto">
							{{ getWalletAmount(wallet).balance }}
							<small>{{ getWalletAmount(wallet).currencyId ? getWalletAmount(wallet).currencyId.toUpperCase() : '' }}</small>
						</div>
						<div class="switch-arrow">
							<span 
								class="switch-text" 
								@click="switchWallet(wallet.walletId)"
							>Select</span>
						</div>
					</div>
				</div>
			</div>
		</template>

		<template #modal-footer="{ hide }">
			<b-button 
				variant="btn btn-info" 
				@click="hide('forget')"
			>
				Close
			</b-button>
		</template>
	</b-modal>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
export default {
	data() {
		return {};
	},
	computed: {
		walletList: function () {
			return this.list.filter(wallet =>
				wallet.walletId !== this.currentWallet.walletId
			);
		},
		...mapState('wallet', [
			'list'
		]),
		...mapGetters('wallet', [
			'currentWallet',
			'currentWalletAmount'
		])
	},
	methods: {
		switchWallet: function (walletId) {
			this.$store.dispatch('wallet/changeCurrentWallet', walletId);
			this.$bvModal.hide('wallet-switch-modal');
		},
		getWalletAmount(wallet) {
			if (!wallet) return {};
			return wallet.walletCurrencyList[0];
		}
	}
};
</script>
