export default [
	{
		name: 'name',
		type: 'text',
		label: 'Product name',
		placeholder: 'Product name',
		required: true,
		maxLength: 128
	},
	{
		name: 'websiteUrl',
		type: 'text',
		label: 'Website',
		placeholder: 'Website',
		required: true,
		maxLength: 255
	},
	{
		name: 'notificationUrl',
		type: 'text',
		label: 'Notification URL',
		placeholder: 'Notification URL',
		required: false,
		maxLength: 255
	},
	{
		name: 'ipWhiteList',
		type: 'text',
		label: 'IP white list (for API)',
		placeholder: 'IP white list (for API)',
		required: false,
		maxLength: 512
	},
];