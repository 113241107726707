var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "odd-form"
  }, [_c('b-form', [_c('b-row', [_c('b-col', [_c('h2', [_vm._v("Update your company questionnaire")])])], 1), _c('b-form-group', [_c('questionnaire-business-activities', {
    attrs: {
      "odd": ""
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('label', {
          attrs: {
            "for": "business_activity"
          }
        }, [_vm._v(" Main business activity ")])];
      },
      proxy: true
    }, {
      key: "other-label",
      fn: function fn() {
        return [_c('label', {
          attrs: {
            "for": "business_activity_other"
          }
        }, [_vm._v(" Please specify other activities ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.formData.businessActivities,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "businessActivities", $$v);
      },
      expression: "formData.businessActivities"
    }
  })], 1), _c('b-form-group', [_c('verify-account-info-use', {
    attrs: {
      "full-width": "",
      "dropdown": ""
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('label', [_vm._v("Use of account")])];
      },
      proxy: true
    }, {
      key: "other-label",
      fn: function fn() {
        return [_c('label', [_vm._v(" Other use of account ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.formData.accountInfoUse,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "accountInfoUse", $$v);
      },
      expression: "formData.accountInfoUse"
    }
  })], 1), _c('b-row', [_c('b-col', _vm._l(_vm.commentFields, function (field, index) {
    return _c('form-error-field', {
      key: 'comment-' + index,
      attrs: {
        "name": field.value,
        "label": field.label,
        "label-for": _vm.formatFieldId(field.name)
      }
    }, [_c('b-form-textarea', {
      directives: [{
        name: "validate",
        rawName: "v-validate",
        value: 'required|max:10000',
        expression: "'required|max:10000'"
      }],
      attrs: {
        "id": _vm.formatFieldId(field.name),
        "rows": "2",
        "max-rows": "10",
        "type": "text",
        "name": field.value,
        "data-vv-as": field.name,
        "placeholder": field.name
      },
      model: {
        value: _vm.formData[field.value],
        callback: function callback($$v) {
          _vm.$set(_vm.formData, field.value, $$v);
        },
        expression: "formData[field.value]"
      }
    })], 1);
  }), 1)], 1), _c('b-form-group', [_c('label', [_vm._v("Attach registration document")]), _c('file-upload', {
    attrs: {
      "accept": ".jpg, .jpeg, .gif, .png, .pdf, .eml"
    },
    on: {
      "onChange": function onChange(e) {
        return _vm.setRegistrationDocument(e);
      }
    }
  })], 1)], 1), _c('odd-form-footer', _vm._b({}, 'odd-form-footer', _vm.properties, false))], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }