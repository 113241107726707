var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "faq-nav"
  }, [_c('router-link', {
    attrs: {
      "active-class": "active-item",
      "to": "/faq/gettingstarted"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.clearSearch.apply(null, arguments);
      }
    }
  }, [_vm._v(" Getting Started ")]), _c('router-link', {
    attrs: {
      "active-class": "active-item",
      "to": "/faq/account"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.clearSearch.apply(null, arguments);
      }
    }
  }, [_vm._v(" Account ")]), _c('router-link', {
    attrs: {
      "active-class": "active-item",
      "to": "/faq/profile"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.clearSearch.apply(null, arguments);
      }
    }
  }, [_vm._v(" Profile ")]), _c('router-link', {
    attrs: {
      "active-class": "active-item",
      "to": "/faq/topup"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.clearSearch.apply(null, arguments);
      }
    }
  }, [_vm._v(" Top Up ")]), _c('router-link', {
    attrs: {
      "active-class": "active-item",
      "to": "/faq/security"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.clearSearch.apply(null, arguments);
      }
    }
  }, [_vm._v(" Security ")]), _c('router-link', {
    attrs: {
      "active-class": "active-item",
      "to": "/faq/banktransfer"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.clearSearch.apply(null, arguments);
      }
    }
  }, [_vm._v(" Bank Transfer ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }