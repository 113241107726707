
import {
	RECOVER_PASSWORD_LOADING,
	RECOVER_PASSWORD_SUCCESS,
	RECOVER_PASSWORD_FAILURE,
	RECOVER_PASSWORD_INITIALIZED,
	SET_RECOVER_PASSWORD_DATA
} from '~/store/mutation-types'; 
import { endpoints } from '~/config';


export default {
	recoverPassword,
	recoverPasswordConfirm,
	recoverPasswordVerify,
	recoverPasswordChange,
	recoverPasswordResend,
	setRecoverData
};

async function recoverPassword({ commit, dispatch }, recoveryData) {
	commit(RECOVER_PASSWORD_LOADING, true);
	const options = {
		method: 'POST',
		url: endpoints.auth.recoverPass,
		data: {
			...recoveryData,
			clientId: 'frontend',
		}
	};
	try {
		const response = await axios(options);
		const { data } = response;
		dispatch('user/setUserAuthenticator', data.authenticator, { root: true });
		commit(SET_RECOVER_PASSWORD_DATA, recoveryData);
		commit(RECOVER_PASSWORD_LOADING, false);
		return Promise.resolve(response);
	} catch (err) {
		commit(RECOVER_PASSWORD_LOADING, false);
		return Promise.reject(err.message);
	}
}
async function recoverPasswordConfirm({ commit, state }, data) {
	commit(RECOVER_PASSWORD_LOADING, true);
	const options = {
		method: 'PUT',
		url: endpoints.auth.recoverPassConfirm,
		data: {
			...data,
			clientId: 'frontend',
			email: state.email,
			phone: state.phone
		}
	};
	try {
		const response = await axios(options);
		commit(RECOVER_PASSWORD_INITIALIZED);
		return Promise.resolve(response);
	} catch (err) {
		commit(RECOVER_PASSWORD_FAILURE);
		return Promise.reject(err.message);
	}
}
async function recoverPasswordVerify({ commit }, data) {
	commit(RECOVER_PASSWORD_LOADING, true);
	const options = {
		method: 'GET',
		url: endpoints.auth.recoverPassVerify({ hash: data })
	};
	try {
		const response = await axios(options);
		commit(RECOVER_PASSWORD_INITIALIZED);
		return Promise.resolve(response);
	} catch (err) {
		commit(RECOVER_PASSWORD_FAILURE);
		return Promise.reject(err.message);
	}
}
async function recoverPasswordChange({ commit }, data) {
	commit(RECOVER_PASSWORD_LOADING, true);
	const options = {
		method: 'PUT',
		url: endpoints.auth.recoverPassChange,
		data: {
			...data,
			clientId: 'frontend',
		}
	};
	try {
		const response = await axios(options);
		commit(RECOVER_PASSWORD_SUCCESS);
		return Promise.resolve(response);
	} catch (err) {
		commit(RECOVER_PASSWORD_FAILURE);
		return Promise.reject(err.message);
	}
}
async function recoverPasswordResend({ commit }, data) {
	commit(RECOVER_PASSWORD_LOADING, true);
	const options = {
		method: 'POST',
		url: endpoints.auth.recoverPassInit,
		data: {
			...data,
			clientId: 'frontend',
		}
	};
	try {
		const response = await axios(options);
		commit(RECOVER_PASSWORD_LOADING, false);
		return Promise.resolve(response);
	} catch (err) {
		commit(RECOVER_PASSWORD_FAILURE);
		return Promise.reject(err.message);
	}
}

function setRecoverData({ commit }, data) {
	commit(SET_RECOVER_PASSWORD_DATA, data);
}