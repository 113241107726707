<template>
	<div>
		<div 
			:class="showCollapse ? 'expanded' : ''" 
			class="transaction-item-container"
		>
			<div 
				:class="currentItem ? 'transaction-item--selected' : ''"
				class="transaction-item" 
				@click="selectItem(item)"
			>
				<b-row
					class="align-items-center"
					align-h="between"
				>
					<b-col>
						<div class="d-flex align-items-center">
							<div class="avatar d-flex align-items-center justify-content-center">
								<img
									v-if="!['disabled', 'rejected'].includes(item.status)"
									src="images/img/income.svg"
								/>
								<ion-icon
									v-if="['rejected'].includes(item.status)"
									name="close-outline"
									style="color: red"
								/>
							</div>
							<div>
								<h4>
									{{ item.id }}
								</h4>
								<div class="date">
									{{ item.dateCreated | timestampToLocalDateTime }}
								</div>
							</div>
						</div>
					</b-col>
					<b-col class="d-none d-xl-block">
						<div class="summary">
							{{ item.name }}
						</div>
					</b-col>
					<b-col class="d-none d-xl-block">
						<div class="summary">
							{{ item.status }}
						</div>
					</b-col>
					<b-col class="d-none d-xl-block">
						<div class="summary">
							{{ item.website }}
						</div>
					</b-col>
				</b-row>
				<div class="current-item-arrow d-none d-xl-flex">
					<i class="fas fa-arrow-right"></i>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import moment from 'moment';

export default {
	name: 'ProductListItem',
	filters: {
		normalizeAmount: function (value) {
			return Math.round(value * 100) / 100;
		},
		timestampToLocalDateTime: function (value) {
			return moment(value).utcOffset(value).format('YYYY-MM-DD HH:mm');
		},
		timestampToDay: function (value) {
			let day = new Date(value * 1000).getDate();
			return ('0' + day).slice(-2);
		},
		timestampToYear: function (value) {
			let day = new Date(value * 1000).getFullYear();
			return ('0' + day).slice(-2);
		},
		timestampToShortMonth: function (value) {
			var monthNames = [
				'Jan',
				'Feb',
				'Mar',
				'Apr',
				'May',
				'Jun',
				'Jul',
				'Aug',
				'Sep',
				'Oct',
				'Nov',
				'Dec'
			];
			return monthNames[new Date(value * 1000).getMonth()];
		},
		timestampToHourMin: function (value) {
			let d = new Date(value * 1000);
			return (
				('0' + d.getHours()).slice(-2) +
					':' +
					('0' + d.getMinutes()).slice(-2)
			);
		}
	},
	props: {
		item: {
			type: Object,
			default: () => {}
		},
		id: {
			type: String,
			default: ''
		},
		selected: {
			type: Object,
			default: null
		}
	},
	data() {
		return {
			showCollapse: false
		};
	},
	computed: {
		currentItem: function() {
			return !!(this.selected && this.selected.id == this.item.id);
		}
	},
	methods: {
		selectItem(item) {
			this.$emit('select', item,);
		},
	},
};
</script>

<style lang="scss" scoped>
	@import './ProductListItem.scss';

	.darkBg {
		background: var(--bg);
	}

	.redStatus {
		color: var(--red);
	}

	.grayStatus {
		color: var(--gray);
	}

	.greenStatus {
		color: var(--success);
	}
</style>
