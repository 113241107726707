var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._m(0), _c('b-card', [_c('b-row', [_vm.walletList.length > 0 ? _c('b-col', {
    staticClass: "mb-4 mb-md-0",
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('div', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.account-wallet-settings",
      modifiers: {
        "account-wallet-settings": true
      }
    }],
    staticClass: "settings-button",
    "class": {
      'settings-button__active': _vm.visibleTab[0]
    }
  }, [_c('span', {
    staticClass: "icon icon__cash"
  }), _c('span', [_vm._v("Account Wallet Settings")])])]) : _vm._e(), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('div', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.email-settings",
      modifiers: {
        "email-settings": true
      }
    }],
    staticClass: "settings-button",
    "class": {
      'settings-button__active': _vm.visibleTab[1]
    }
  }, [_c('span', {
    staticClass: "icon icon__mail"
  }), _vm._v(" Email Settings ")])])], 1)], 1), _c('b-card', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.visible,
      expression: "visible"
    }],
    staticClass: "mt-4"
  }, [_c('b-collapse', {
    attrs: {
      "id": "account-wallet-settings",
      "accordion": "account-settings",
      "role": "tabpanel"
    },
    model: {
      value: _vm.visibleTab[0],
      callback: function callback($$v) {
        _vm.$set(_vm.visibleTab, 0, $$v);
      },
      expression: "visibleTab[0]"
    }
  }, [_c('account-wallet-name-settings')], 1), _c('b-collapse', {
    attrs: {
      "id": "email-settings",
      "accordion": "account-settings",
      "role": "tabpanel"
    },
    model: {
      value: _vm.visibleTab[1],
      callback: function callback($$v) {
        _vm.$set(_vm.visibleTab, 1, $$v);
      },
      expression: "visibleTab[1]"
    }
  }, [_c('account-email-settings')], 1)], 1)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-title-container"
  }, [_c('h2', [_vm._v("Account settings")])]);
}]

export { render, staticRenderFns }