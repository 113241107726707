<template>
	<select 
		v-model="value" 
		class="custom-select inputselect" 
		@change="select(value)"
	>
		<option 
			v-for="(v, key) in items" 
			:key="key"
			:value="key"
		>
			{{ v }}
		</option>
	</select>
</template>
<script>
export default {
	name: 'CustomSelect',
	props : {
		items: {
			type: Object,
			default: () => {}
		},
	},
	data() {
		return {
			value: null,
			current : false
		};
	},
	watch : {
		items(){
			if(Array.isArray(this.items)){
				this.select(0);
			}else {
				this.select(Object.keys(this.items)[0]);
			}
		}
	},
	created(){
		if(Array.isArray(this.items)){
			this.select(0, true);
		}else {
			this.select(Object.keys(this.items)[0], true);
		}
	},
	methods : {
		select(key, initial){
			if (!initial) {
				this.$emit('select', key);
				this.$emit('change');
			}
			this.current = this.items[key];
			this.value = key;
		}
	},
};
</script>
