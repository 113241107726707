export default {
	listLoading: false,
	listLoaded: false,
	currencies: [],
	list: [],
	currentId: null,
	nextWalletId: null,
	create: {
		currency: 'eur',
		walletId: null,
		showForm: true,
		showSuccess: false,
	}
};