var render = function () {
  var _vm$item$beneficiary, _vm$item$sender;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('transition', {
    attrs: {
      "name": "fade",
      "mode": "out-in"
    }
  }, [_vm.item ? _c('div', {
    staticClass: "transactions-list-item-details"
  }, [_c('i', {
    staticClass: "fas fa-times transactions-list-item-details__close",
    on: {
      "click": _vm.close
    }
  }), _c('b-card', {
    staticClass: "transactions-list-item-details__card"
  }, [_c('div', {
    staticClass: "transactions-list-item-details__icon"
  }, [!['declined', 'failed', 'refund'].includes(_vm.item.publicStatus) ? _c('img', {
    attrs: {
      "src": _vm.item.direction === 'outgoing' ? 'images/img/outcome.svg' : 'images/img/income.svg'
    }
  }) : _vm._e(), ['declined', 'failed'].includes(_vm.item.publicStatus) ? _c('ion-icon', {
    staticStyle: {
      "color": "red"
    },
    attrs: {
      "name": "close-outline"
    }
  }) : _vm._e(), ['refund'].includes(_vm.item.publicStatus) ? _c('ion-icon', {
    attrs: {
      "name": "refresh-outline"
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "transactions-list-item-details__amount",
    "class": {
      'transactions-list-item-details__amount--failed': ['declined', 'failed'].includes(_vm.item.publicStatus)
    }
  }, [_c('span', {
    "class": {
      'greenStatus': _vm.item.publicStatus === 'completed' && _vm.item.direction === 'incoming',
      'redStatus': _vm.item.publicStatus === 'completed' && _vm.item.direction === 'outgoing'
    }
  }, [_vm._v(" " + _vm._s(_vm.item.publicStatus == 'completed' ? _vm.item.direction === 'outgoing' ? '-' : '+' : '') + _vm._s(_vm.item.amount) + " "), _c('span', {
    staticClass: "transactions-list-item-details__currency"
  }, [_vm._v(_vm._s(_vm.item.currencyId))])])]), parseFloat(_vm.item.fee) > 0 ? _c('div', {
    staticClass: "transactions-list-item-details__fees"
  }, [_c('span', [_vm._v("Fees: " + _vm._s(_vm.item.fee))]), _c('span', {
    staticClass: "transactions-list-item-details__currency"
  }, [_vm._v(_vm._s(_vm.item.currencyId))])]) : _vm._e(), _c('div', {
    staticClass: "transactions-list-item-details__recipient"
  }, [_vm._v(" " + _vm._s(_vm.item.direction === 'outgoing' ? (_vm$item$beneficiary = _vm.item.beneficiary) === null || _vm$item$beneficiary === void 0 ? void 0 : _vm$item$beneficiary.accountHolder : (_vm$item$sender = _vm.item.sender) === null || _vm$item$sender === void 0 ? void 0 : _vm$item$sender.accountHolder) + " ")]), _c('hr'), _c('div', {
    staticClass: "transactions-list-item-details__additional-data"
  }, [_c('div', [_vm._v("Payment details:")]), _c('span', [_vm._v(_vm._s(_vm.item.details))]), _c('hr'), _vm.item.id ? _c('div', [_vm._l(_vm.list.common, function (details) {
    return _c('div', {
      key: details.label
    }, [details.value(_vm.item) ? _c('div', {
      staticClass: "transactions-list-item-details__row"
    }, [_c('span', [_vm._v(_vm._s(details.label) + ":")]), _c('span', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover.v-info.left",
        modifiers: {
          "hover": true,
          "v-info": true,
          "left": true
        }
      }, {
        name: "clipboard",
        rawName: "v-clipboard",
        value: details.value(_vm.item),
        expression: "details.value(item)"
      }, {
        name: "clipboard",
        rawName: "v-clipboard:success",
        value: function value() {
          return _vm.pushSuccess({
            title: 'Copied to clipboard:',
            text: details.value(_vm.item)
          });
        },
        expression: "() => pushSuccess({title: 'Copied to clipboard:', text: details.value(item)})",
        arg: "success"
      }],
      attrs: {
        "title": "Click to copy"
      }
    }, [_vm._v(_vm._s(details.value(_vm.item)))])]) : _vm._e()]);
  }), _vm._l(_vm.list[_vm.item.direction], function (details, i) {
    return _c('div', {
      key: "details-".concat(i)
    }, [details.value(_vm.item) ? _c('div', {
      staticClass: "transactions-list-item-details__row"
    }, [_c('span', [_vm._v(_vm._s(details.label) + ":")]), _c('span', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover.v-info.left",
        modifiers: {
          "hover": true,
          "v-info": true,
          "left": true
        }
      }, {
        name: "clipboard",
        rawName: "v-clipboard",
        value: details.value(_vm.item),
        expression: "details.value(item)"
      }, {
        name: "clipboard",
        rawName: "v-clipboard:success",
        value: function value() {
          return _vm.pushSuccess({
            title: 'Copied to clipboard:',
            text: details.value(_vm.item)
          });
        },
        expression: "() => pushSuccess({title: 'Copied to clipboard:', text: details.value(item)})",
        arg: "success"
      }],
      attrs: {
        "title": "Click to copy"
      }
    }, [_vm._v(_vm._s(details.value(_vm.item)))])]) : _vm._e()]);
  })], 2) : _vm._e(), _c('hr'), _c('div', {
    staticClass: "transactions-list-item-details__button-wrap"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": function click($event) {
        return _vm.pdfClick(_vm.item.id);
      }
    }
  }, [_c('i', {
    staticClass: "fas fa-download"
  }), _vm._v(" PDF download ")])]), _vm.isWalletGranted('initiate_transaction', _vm.currentWallet.walletId) && _vm.item.direction === 'outgoing' && _vm.transferType && _vm.currentWallet && _vm.currentWallet.status === 'active' ? _c('div', {
    staticClass: "transactions-list-item-details__button-wrap"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": function click($event) {
        return _vm.copyTransfer(_vm.item);
      }
    }
  }, [_vm._v(" Copy payment ")])]) : _vm._e()])]), _c('span', {
    staticClass: "transactions-list-item-details__id"
  }, [_vm._v("Transaction ID: " + _vm._s(_vm.item.id))])], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }