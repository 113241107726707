var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "edit-info pt-4"
  }, [_vm.step == 1 ? _c('div', [_c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        _vm.step = 2;
      }
    }
  }, [_vm._v("Want to edit info?")])]) : _vm._e(), _vm.step == 2 ? _c('div', {
    staticClass: "step2 d-flex flex-column"
  }, [_c('p', [_vm._v(" In order to edit your personal information, please contact our support center. You will be asked to submit documents, as a proof of your new personal information. ")]), _c('div', {
    staticClass: "d-flex"
  }, [_c('button', {
    staticClass: "btn btn-info mr-3",
    on: {
      "click": function click($event) {
        _vm.step = 1;
      }
    }
  }, [_vm._v(" Cancel ")]), _c('a', {
    staticClass: "btn btn-primary",
    attrs: {
      "target": "_blank",
      "href": _vm.supportUrl
    }
  }, [_vm._v("Contact support")])])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }