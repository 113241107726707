<template>
	<transition 
		name="fade" 
		mode="out-in"
	>
		<div
			v-if="item"
			class="transactions-list-item-details"
		>
			<i
				class="fas fa-times transactions-list-item-details__close"
				@click="close"
			></i>
			<b-card class="transactions-list-item-details__card">
				<div class="transactions-list-item-details__icon">
					<img
						v-if="!['declined', 'failed', 'refund'].includes(item.status)"
						src="images/img/income.svg"
					/>
					<ion-icon
						v-if="['declined', 'failed'].includes(item.status)"
						name="close-outline"
						style="color: red"
					/>
					<ion-icon
						v-if="['refund'].includes(item.status)"
						name="refresh-outline"
					/>
				</div>
				<div 
					class="transactions-list-item-details__amount"
					:class="{
						'transactions-list-item-details__amount--failed': ['declined', 'failed'].includes(item.status)
					}"
				>
					<span
						:class="{
							'greenStatus': item.status === 'completed',
						}"
					>
						{{ item.status == 'completed' ? '+' : '' }}{{ item.amount }}
						<span class="transactions-list-item-details__currency">{{ item.currencyId }}</span>
					</span>
				</div>
				<hr>
				<div class="transactions-list-item-details__additional-data">
					<div>Payment details:</div>
					<span>{{ item.details }}</span>
					<hr>
					<div v-if="item.id">
						<div
							v-for="details in list.common"
							:key="details.label"
						>
							<div
								v-if="details.value(item)"
								class="transactions-list-item-details__row"
							>
								<span>{{ details.label }}:</span>
								<span
									v-b-tooltip.hover.v-info.left
									v-clipboard="details.value(item)"
									v-clipboard:success="() => pushSuccess({title: 'Copied to clipboard:', text: details.value(item)})"
									title="Click to copy"
								>{{ details.value(item) }}</span>
							</div>
						</div>
					</div>
				</div>
			</b-card>
			<span class="transactions-list-item-details__id">Payment Request ID: {{ item.id }}</span>
		</div>
	</transition>
</template>
<script>
import * as moment from 'moment';
import { mapActions } from 'vuex';

export default {
	name: 'TransactionListItemDetails',
	props: {
		item: {
			type: Object,
			default: () => ({})
		}
	},
	data() {
		return {
			moment,
			list: {
				common: [
					{
						label: 'Date',
						value: item => moment(item.dateCreated).utcOffset(item.dateCreated).format('YYYY-MM-DD HH:mm')
					},
					{
						label: 'Status',
						value: item => item.status.charAt(0).toUpperCase() + item.status.slice(1)
					},
					{
						label: 'Product',
						value: item => item.product.name
					},
					{
						label: 'Payment method',
						value: item => item.paymentMethodType?.name ? item.paymentMethodType.name : null
					},
					{
						label: 'Payment country',
						value: item => item.paymentMethodType?.countryId ? item.paymentMethodType.countryId.toLocaleUpperCase() : null
					},
					{
						label: 'Remote identifier',
						value: item => item.remoteIdentifier
					}
				],
			}
		};
	},
	methods: {
		...mapActions('notifications', ['pushSuccess', 'pushError']),
		close() {
			this.$emit('close');
		},
	}
};
</script>
<style lang="scss">
	@import './PaymentRequestListItemDetails.scss';
</style>