<template>
	<b-row>
		<b-col>
			<form-error-field :name="['phone_country_id', 'phone_suffix', 'phone']"
				:scope="scope"
				class="mb-0"
			>
				<div v-if="!hideLabel"
					class="form-group mb-0"
				>
					<label for="phone_suffix">{{ label }}</label>
				</div>
				<b-input-group class="mb-0">
					<div class="phone-prefix">
						<v-multiselect
							id="phone_country_id"
							slot="prepend"
							v-model="data.phone_prefix_country_id"
							v-validate="'required'"
							:options="countryOptions"
							:show-labels="false"
							:allow-empty="false"
							:class="{'input-invalid': errors.has('phone_country_id', scope)}"
							name="phone_country_id"
							:custom-label="(option) => `${option.name} +${option.phoneCode}`"
							placeholder="Select Code"
							track-by="countryId"
							return="countryId"
							data-phone="prefix"
							@select="focusNext"
						>
							<template 
								slot="singleLabel" 
								slot-scope="props"
							>
								+<span class="option__desc"><span
									class="option__title"
								>{{ props.option.phoneCode }}</span></span>
							</template>
							<template 
								slot="option" 
								slot-scope="props"
							>
								<div class="option__desc">
									<span
										class="option__title"
									>{{ props.option.name }}</span><span class="option__small"> +{{ props.option.phoneCode }}</span>
								</div>
							</template>
						</v-multiselect>
					</div>
					<b-form-input
						id="phone_suffix"
						ref="input"
						v-model="data.phone_suffix"
						v-filter-input="'number'"
						v-validate="phoneValidation"
						class="phone-suffix"
						type="text" 
						data-vv-as="phone number"
						name="phone_suffix"
						autocomplete="off_phone"
						data-phone="suffix"
						:class="{'is-invalid': errors.has('phone_suffix', scope)}"
					/>
				</b-input-group>
			</form-error-field>
		</b-col>
	</b-row>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import FormErrorField from '@/shared/Form/FormErrorField';

export default {
	name: 'PhoneInput',
	inject: ['$validator'],
	components: { FormErrorField },
	props: {
		value: {
			type: String,
			default: ''
		},
		scope: {
			type: String,
			default: undefined
		},
		label: {
			type: String,
			default: 'Phone number'
		},
		hideLabel: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			phone: '',
			data: {
				phone_prefix_country_id: '',
				phone_suffix: '',
			},
			phoneValidation: {
				required: true
			}
		};
	},
	computed: {
		...mapState('countries', {
			countryOptions: state => state.listPhone
		})
	},
	methods: {
		...mapActions('countries', ['getPhoneCountries']),
		focusNext() {
			this.$refs.input.focus();
		},
		setPhone(v) {
			if (v && v.length > 3) {
				const codeChars = v.slice(0, 4);
				const codes = [];
				for (let i = 0; i < codeChars.length; i++) {
					i == 0 ? codes.push(codeChars) : codes.push(codeChars.slice(0, 0 - i));
				}
				const country = this.countryOptions.find(o => {
					return codes.includes(`${o.phoneCode}`);
				});
				if (country?.phoneCode) {
					const countryId = country.countryId;
					const number = v.split('').splice(country.phoneCode.toString().length, v.length).join('');
					this.$set(this.data, 'phone_prefix_country_id', countryId);
					this.$set(this.data, 'phone_suffix', number);
				}
			}
		}
	},
	watch: {
		value(nval, oval) {
			if (!oval) {
				this.setPhone(nval);
			}
		},
		data: {
			handler: function () {
				const country = this.countryOptions.find(c => c.countryId == this.data.phone_prefix_country_id);
				if (country && country.countryId == 'gb') {
					this.phoneValidation = {
						required: true,
						max: 10,
						regex: /^(?![0])\d+/
					};
				} else {
					this.phoneValidation = { 
						required: true 
					};
				}
				this.$set(this.data, 'phone_suffix', this.data.phone_suffix.replace(/\D/g, ''));
				this.phone = (country ? country.phoneCode : '') + this.data.phone_suffix;
				this.$emit('input', this.phone);
			},
			deep: true
		}
	},
	async mounted() {
		await this.getPhoneCountries();
		if (this.value && this.value.length > 2) {
			this.setPhone(this.value);
		}

	}
};
</script>