import axios from 'axios';
import { endpoints } from '~/config';

export const currency = {
	async getList() {
		const params = {
			method: 'GET',
			url: endpoints.currency.list
		};
		try {
			const { data } = await axios(params);
			return Promise.resolve(data);
		} catch (err) {
			return Promise.reject(err);
		}
	},
	async getSwiftList() {
		const params = {
			method: 'GET',
			url: endpoints.currency.listSwift
		};
		try {
			const { data } = await axios(params);
			return Promise.resolve(data);
		} catch (err) {
			return Promise.reject(err);
		}
	}
};
