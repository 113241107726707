import { render, staticRenderFns } from "./ReferralProgramme.vue?vue&type=template&id=5b8bbc9c&scoped=true&"
import script from "./ReferralProgramme.vue?vue&type=script&lang=js&"
export * from "./ReferralProgramme.vue?vue&type=script&lang=js&"
import style0 from "./AffiliateProgramme.scss?vue&type=style&index=0&id=5b8bbc9c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b8bbc9c",
  null
  
)

export default component.exports