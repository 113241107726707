<template>
	<div class="mb-4 password-input">
		<form-error-field :name="name || id">
			<b-input-group>
				<b-form-input 
					:id="id"
					:ref="inputRef"
					v-model="password"
					v-validate="validationRules"
					:name="name || id"
					:type="type"
					:disabled="disabled"
					class="form-control-password"
					data-vv-as="password"
					@input="updateValue()"
				/>
				<b-input-group-append>
					<b-button 
						variant="outline-primary" 
						@mousedown="toggleContent()"
						@mouseup="toggleContent()"
						@mouseout="toggleContentOff()"
					>
						<icon 
							v-if="type == 'text'" 
							name="eye-slash"
						/>
						<icon 
							v-else 
							name="eye"
						/>
					</b-button>
				</b-input-group-append>
			</b-input-group>
		</form-error-field>
		<div
			v-show="password && req"
			class="password-requirements"
		>
			<span class="password-requirements__title">Requirements:</span>
			<b-badge 
				:variant="requirements.length" 
			>
				min 8 characters
			</b-badge>
			<b-badge 
				:variant="requirements.lowercase" 
			>
				min 1 lowercase letter
			</b-badge>
			<b-badge 
				:variant="requirements.capital" 
			>
				min 1 capital letter
			</b-badge>
			<b-badge 
				:variant="requirements.digit" 
			>
				min 1 digit
			</b-badge>
		</div>
	</div>
</template>

<script>
import 'vue-awesome/icons/eye-slash';
import 'vue-awesome/icons/eye';
import FormErrorField from '@/shared/Form/FormErrorField';

export default {
	name: 'PasswordInput',
	components: {
		FormErrorField
	},
	inject: ['$refs'],
	props: {
		value: {
			type: String,
			default: ''
		},
		req: {
			type: Boolean,
			default: true
		},
		validation: {
			type: Boolean,
			default: true
		},
		id: {
			type: String,
			default: 'password'
		},
		disabled: {
			type: Boolean,
			default: false
		},
		name: {
			type: String
		},
		validationRules: {
			type: String,
			default: 'verify_password|min:8'
		},
		inputRef: {
			type: String,
			default: null
		}
	},
	data() {
		return {
			password: '',
			type: 'password',
			requirements: {
				length: 'light',
				lowercase: 'light',
				capital: 'light',
				digit: 'light',
			}
		};
	},
	computed: {
		valid: function() {
			return {
				length: this.password && this.password.length >= 8,
				lowercase: this.password && /[a-z]/.test(this.password),
				capital: this.password && /[A-Z]/.test(this.password),
				digit: this.password && /[\d]/.test(this.password)
			};
		},
		validated: function() {
			return Object.values(this.valid).reduce((a, b) => a && b, true);
		}
	},
	mounted() {
		this.password = this.value;
	},
	methods: {
		updateValue() {
			this.$emit('input', this.password);
			this.$emit('validated', this.validated);

			this.requirements.length = this.valid.length ? 'success' : 'light';
			this.requirements.lowercase = this.valid.lowercase ? 'success' : 'light';
			this.requirements.capital = this.valid.capital ? 'success' : 'light';
			this.requirements.digit = this.valid.digit ? 'success' : 'light';
		},
		toggleContent() {
			this.type = (this.type === 'password') ? 'text': 'password';
		},
		toggleContentOff() {
			this.type = 'password';
		}
	},

};
</script>

<style lang="scss">
    @import './PasswordInput.scss';
    #registration-layout .registration-content .form-control-password {
        margin-bottom: 0;
    }
</style>
