var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-4 password-input"
  }, [_c('form-error-field', {
    attrs: {
      "name": _vm.name || _vm.id
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: _vm.validationRules,
      expression: "validationRules"
    }],
    ref: _vm.inputRef,
    staticClass: "form-control-password",
    attrs: {
      "id": _vm.id,
      "name": _vm.name || _vm.id,
      "type": _vm.type,
      "disabled": _vm.disabled,
      "data-vv-as": "password"
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue();
      }
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "mousedown": function mousedown($event) {
        return _vm.toggleContent();
      },
      "mouseup": function mouseup($event) {
        return _vm.toggleContent();
      },
      "mouseout": function mouseout($event) {
        return _vm.toggleContentOff();
      }
    }
  }, [_vm.type == 'text' ? _c('icon', {
    attrs: {
      "name": "eye-slash"
    }
  }) : _c('icon', {
    attrs: {
      "name": "eye"
    }
  })], 1)], 1)], 1)], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.password && _vm.req,
      expression: "password && req"
    }],
    staticClass: "password-requirements"
  }, [_c('span', {
    staticClass: "password-requirements__title"
  }, [_vm._v("Requirements:")]), _c('b-badge', {
    attrs: {
      "variant": _vm.requirements.length
    }
  }, [_vm._v(" min 8 characters ")]), _c('b-badge', {
    attrs: {
      "variant": _vm.requirements.lowercase
    }
  }, [_vm._v(" min 1 lowercase letter ")]), _c('b-badge', {
    attrs: {
      "variant": _vm.requirements.capital
    }
  }, [_vm._v(" min 1 capital letter ")]), _c('b-badge', {
    attrs: {
      "variant": _vm.requirements.digit
    }
  }, [_vm._v(" min 1 digit ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }