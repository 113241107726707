<template>
	<div>
		<div class="page-title-container">
			<h2>Profile Activity</h2>
		</div>
		<activity-table />
	</div>
</template>

<script>
import ActivityTable from './ActivityTable.vue';
export default {
	name: 'ActivityTableLayout',
	components: {
		'activity-table': ActivityTable
	}
};
</script>
