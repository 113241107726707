export const generatePassword = function () {
	var length =Math.floor(Math.random() * 12) + 8;
	var g=function(){
		var c='0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
		var p='';
		for(var i=0;i<length;i++)
		{
			p+=c.charAt(Math.floor(Math.random()*c.length));
		}

		return p;
	};

	var p=g();
	while(!/[A-Z]/.test(p)||!/[0-9]/.test(p)||!/[a-z]/.test(p)){
		p=g();
	}

	return p;
};