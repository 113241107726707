let api, paymentGateway, sentry;

if (process.env.NODE_ENV == 'development') {
	api = 'https://api.verifo.local';
	paymentGateway = 'https://gateway.verifo.local';
	sentry = 'https://c20c983066884fe69d72ab12d01936d6@o491125.ingest.sentry.io/5556181';
}

if (process.env.NODE_ENV == 'qa') {
	api = 'https://api.verifes.com';
	paymentGateway = 'https://gateway.verifes.com';
	sentry = 'https://80bc495b79104511ac394a7a29d61f15@sentry.verifo.com/9';
}

if (process.env.NODE_ENV == 'staging') {
	api = 'https://api-stage.verifes.com';
	paymentGateway = 'https://gateway-stage.verifes.com';
	sentry = 'https://80bc495b79104511ac394a7a29d61f15@sentry.verifo.com/9';
}

if (process.env.NODE_ENV == 'production') {
	api = 'https://api.verifo.com';
	paymentGateway = 'https://gateway.verifo.com';
	sentry = 'https://3905e3bb4bcd40caaa54613f4f91d68a@sentry.verifo.com/10';
}

export default {
	brand: 'verifo',
	name: 'Verifo',
	style: 'verifo.scss',
	email: 'welcome@verifo.com',
	supportEmail: 'support@verifo.com',
	agreementUrl: 'https://verifo.com/agreement/',
	supportUrl: 'https://www.verifo.com/contacts/',
	privacyPolicyUrl: 'https://verifo.com/privacy-policy/',
	cookiePolicyUrl: 'https://verifo.com/cookie-policy/',
	api: api,
	paymentGateway: paymentGateway,
	sentry: sentry,
};