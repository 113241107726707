<template>
	<div class="odd-form">
		<b-form>
			<b-row>
				<b-col>
					<h2>Update your company turnover information</h2>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<form-error-field v-for="(field, i) in formFields"
						:id="field.id"
						:key="'field-' + i"
						:name="field.valuePath"
						:label="field.type !== 'hidden' ? field.label : ''"
						:label-for="`${field.id}-input`"
					>
						<b-form-input
							v-if="['number', 'text'].includes(field.type)"
							:id="`${field.id}-input`"
							v-validate="field.validation"
							:value="getValue(field.valuePath)"
							:data-vv-as="field.name"
							:name="field.valuePath"
							:type="field.type"
							:placeholder="field.label"
							:aria-describedby="`${field.id}-feedback`"
							@input="setValue(field.valuePath, $event)"
						/>
						<v-multiselect 
							v-if="field.type == 'countries' && countryOptions?.length"
							:id="field.id"
							v-validate="field.validation"
							:value="getValue(field.valuePath)"
							:options="countryOptions"
							:multiple="true"
							:close-on-select="false"
							:clear-on-select="false"
							:preserve-search="true"
							:preselect-first="false"
							:data-vv-as="field.name"
							:name="field.valuePath"
							:placeholder="field.label"
							label="name"
							track-by="name"
							return="countryId"
							class="countries-select"
							@input="setValue(field.valuePath, $event)"
						/>
					</form-error-field>
				</b-col>
			</b-row>
			<b-row v-if="formData.transactionPartnerList">
				<b-col>
					<label>Biggest transaction partners</label>
					<b-row v-for="(partner, index) in formData.transactionPartnerList"
						:key="index"
						:class="{'mt-4' : index > 0}"
					>
						<b-col>
							<form-error-field :name="`transactionPartnerList[${index}][countryId]`">
								<label>Country of partner</label>
								<v-multiselect 
									v-model="partner.countryId"
									v-validate="'required'"
									:options="countryOptions"
									:allow-empty="true"
									:show-labels="false"
									:multiple="false"
									:clear-on-select="false"
									:preselect-first="false"
									:placeholder="'Country of partner'"
									label="name"
									:name="`transactionPartnerList[${index}][countryId]`"
									data-vv-as="Country"
									track-by="name"
									return="countryId"
									class="countries-select"
								/>
							</form-error-field>
						</b-col>
						<b-col>
							<form-error-field :name="`transactionPartnerList[${index}][name]`">
								<label>Name of partner</label>
								<b-form-input v-model="partner.name"
									v-validate="'required'"
									type="text"
									data-vv-as="Partner name"
									:name="`transactionPartnerList[${index}][name]`"
									placeholder="Name of partner"
								/>
							</form-error-field>
						</b-col>
					</b-row>
					<div
						class="mt-4 d-flex justify-content-between"
					>
						<b-button v-if="lastPartnerFilled"
							size="sm"
							variant="primary"
							@click="addPartner()"
						>
							<i class="fas fa-plus-circle"></i>
							Add another partner
						</b-button>
						<b-button v-if="formData.transactionPartnerList.length > 1"
							size="sm"
							class="ml-2"
							variant="danger"
							@click="removePartner()"
						>
							<i class="fas fa-minus-circle"></i>
							Remove last partner
						</b-button>
					</div>
				</b-col>
			</b-row>
		</b-form>
		<odd-form-footer 
			v-bind="properties"
		/>
	</div>
</template>
<script>
import { mapState } from 'vuex';
import OddFormFooter from '../OddFormFooter';
import FormErrorField from '@/shared/Form/FormErrorField';

export default {
	name: 'ODDCompanyTurnover',
	inject: ['$validator'],
	components: {
		OddFormFooter,
		FormErrorField
	},
	data() {
		return {
			formData: {},
		};
	},
	computed: {
		...mapState('user', {
			company: state => state.selected.companyProfile
		}),
		...mapState('countries', {
			countryOptions: state => state.list
		}),
		properties() {
			return {
				...this._props, 
				formData: this.formData
			};
		},
		formFields() { 
			return [
				{
					id: 'incoming-count',
					label: 'Monthly planned number of incoming transactions',
					valuePath: 'turnovers.count.incomingMonthly',
					name: 'Incoming monthly count',
					type: 'number',
					validation: 'required|min_value:1|max_value:2147483647'
				},
				{
					id: 'incoming-amount',
					label: 'Monthly planned turnover of incoming transactions, EUR',
					valuePath: 'turnovers.amount.incomingMonthly',
					name: 'Incoming monthly amount',
					type: 'number',
					validation: 'required|min_value:1|max_value:2147483647'
				},
				{
					id: 'countries-originate-from',
					label: 'Countries, where funds are received from',
					valuePath: 'countriesFundsOriginateFrom',
					name: 'Countries where funds are received from',
					type: 'countries',
					validation: 'required'
				},
				{
					id: 'outgoing-count',
					label: 'Monthly planned number of outgoing transactions',
					valuePath: 'turnovers.count.outgoingMonthly',
					name: 'Outgoing monthly count',
					type: 'number',
					validation: 'required|min_value:1|max_value:2147483647'
				},
				{
					id: 'outgoing-amount',
					label: 'Monthly planned turnover of outgoing transactions, EUR',
					valuePath: 'turnovers.amount.outgoingMonthly',
					name: 'Outgoing monthly amount',
					type: 'number',
					validation: 'required|min_value:1|max_value:2147483647'
				},
				{
					id: 'countries-originate-to',
					label: 'Countries, where funds are sent to',
					valuePath: 'countriesFundsOriginateTo',
					name: 'Countries where funds are sent to',
					type: 'countries',
					validation: 'required'
				},
				{
					id: 'outgoing-cash',
					label: 'The sum of monthly planned cash withdrawals, EUR',
					valuePath: 'turnovers.cash.outgoingMonthly',
					name: 'Outgoing monthly cash',
					type: 'hidden',
					validation: 'required|min_value:0|max_value:2147483647'
				},
			];
		},
		lastPartnerFilled() {
			const list = this.formData.transactionPartnerList;
			const last = list.length - 1;
			return !!list[last].name && !!list[last].countryId;
		}
	},
	props: {
		onSuccess: {
			type: Function,
			default: () => {},
			required: true
		},
		onFailure: {
			type: Function,
			default: () => {},
		},
		onCancel: {
			type: Function,
			default: () => {}
		},
		submitUrl: {
			type: String,
			default: '',
			required: true
		}
	},
	methods: {
		getValue(valuePath) {
			const splitted = valuePath.split('.');
			return splitted.reduce((a, b, index) => {
				if (!a[b]) a[b] = splitted.length == index +1 ? null : {};
				return a[b];
			}, this.formData);
		},
		setValue(valuePath, value) {
			const splitted = valuePath.split('.');
			const data = splitted.slice(-1);
			const path = splitted.slice(0, -1).reduce((a, b) => {
				return a[b];
			}, this.formData);
			this.$set(path, data, value);			
		},
		addPartner() {
			this.formData.transactionPartnerList.push({
				countryId: '',
				name: ''
			});
		},
		removePartner() {
			return this.formData.transactionPartnerList = this.formData.transactionPartnerList.slice(0, -1);
		},
	},
	mounted() {
		this.$nextTick(() => {
			const v = this.company?.questionnaire;
			if (v) {
				this.formData = {
					...this.formData,
					turnovers: v.turnovers,
					transactionPartnerList: v.transactionPartnerList || [],
					countriesFundsOriginateFrom: v.countriesFundsOriginateFrom,
					countriesFundsOriginateTo: v.countriesFundsOriginateTo
				};
			} else {
				this.formData = {};
			}

			if (!this.formData.transactionPartnerList?.length) {
				if (!this.formData.transactionPartnerList) this.$set(this.formData, 'transactionPartnerList', []);
				this.formData.transactionPartnerList.push({ countryId: '', name: '' });
			}
		});
	},
	provide() {
		return {
			$validator: this.$validator
		};
	}
};
</script>
<style lang="scss">
	.countries-select.is-invalid {
		.multiselect__tags {
			border-color: #c95353 !important
		}
	}
</style>