<template>
	<div>
		<div class="page-title-container">
			<h2>Profile</h2>
		</div>
		<b-row>
			<b-col lg="9">
				<profile-account-owner 
					v-if="user.userProfile" 
					:data="user.userProfile"
				/>
				<profile-business-info 
					v-if="isBusiness" 
					:data="user.companyProfile"
				/>
				<profile-business-adresses 
					v-if="isBusiness" 
					:data="user.companyProfile"
				/>
				<edit-info />
			</b-col>
		</b-row>
	</div>
</template>
<script>
import ProfileAccountOwner from './ProfileAccountOwner.vue';
import ProfileBusinessInfo from './ProfileBusinessInfo.vue';
import ProfileBusinessAdresses from './ProfileBusinessAdresses.vue';
import EditInfo from './EditInfo.vue';
import {
	mapGetters, mapState
} from 'vuex';
export default {
	name: 'ProfileLayout',
	components: {
		ProfileAccountOwner,
		ProfileBusinessInfo,
		ProfileBusinessAdresses,
		'edit-info': EditInfo
	},
	computed: {
		...mapGetters('user', [
			'isPersonal',
			'isBusiness',
			'isVerified'
		]),
		...mapState('user', {
			user: state => state.selected
		}),
		...mapState('wallet', {
			wallet: state => state
		})
	},
	mounted(){
		if(this.$route.hash) {
			setTimeout(() => location.href = this.$route.hash, 1);
		}
	},

};
</script>
