var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "odd-form"
  }, [_c('b-form', [_c('h2', [_vm._v("Update your company information")]), _c('div', {
    staticClass: "my-4"
  }, [_c('small', [_vm._v("Verified Payments JSC is a licensed electronic money institution based in Lithuania and according to Lithuanian AML laws, we are legally obliged to perform Ongoing Due Diligence (hereinafter - ODD) procedures on our clients.")])]), _c('form-error-field', {
    attrs: {
      "name": "name",
      "label": "Company name:",
      "label-for": "name-input"
    }
  }, [_c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|min:1|max:100',
      expression: "'required|min:1|max:100'"
    }],
    attrs: {
      "id": "name-input",
      "data-vv-as": "Company name",
      "name": "name",
      "type": "text",
      "placeholder": "Company name",
      "aria-describedby": "name-input-feedback"
    },
    model: {
      value: _vm.formData.name,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "name", $$v);
      },
      expression: "formData.name"
    }
  })], 1), _c('form-error-field', {
    attrs: {
      "name": "website",
      "label": "Official website:",
      "label-for": "website-input"
    }
  }, [_c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|max:200',
      expression: "'required|max:200'"
    }],
    attrs: {
      "id": "website-input",
      "data-vv-as": "Website",
      "name": "website",
      "type": "text",
      "placeholder": "Official website",
      "aria-describedby": "website-input-feedback"
    },
    model: {
      value: _vm.formData.website,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "website", $$v);
      },
      expression: "formData.website"
    }
  })], 1), _c('form-error-field', {
    attrs: {
      "name": "numberOfEmployees",
      "label": "Number of employees:",
      "label-for": "employees-input"
    }
  }, [_c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|min_value:1',
      expression: "'required|min_value:1'"
    }],
    attrs: {
      "id": "employees-input",
      "data-vv-as": "Number of employees",
      "name": "numberOfEmployees",
      "type": "number",
      "placeholder": "Number of employees",
      "aria-describedby": "employees-input-feedback"
    },
    model: {
      value: _vm.formData.numberOfEmployees,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "numberOfEmployees", $$v);
      },
      expression: "formData.numberOfEmployees"
    }
  })], 1), _c('multi-address', {
    attrs: {
      "addresses": _vm.formData.addresses
    }
  })], 1), _c('odd-form-footer', _vm._b({}, 'odd-form-footer', _vm.properties, false))], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }