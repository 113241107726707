<template>
	<div class="sidebar-navigation">
		<div class="sidebar-navigation-link">
			<router-link
				:to="{ name: 'home' }"
				:active="$route.matched.some(({ name }) => name === 'home')"
				exact
			>
				<span class="mobile-home-icon  mobile-icon"></span>
				Home
				<i
					v-if="$route.matched.some(({ name }) => name === 'home')"
					class="fas fa-long-arrow-alt-right"
				></i>
			</router-link>
		</div>
		<div
			v-if="currentWallet && currentWallet.status === 'active'
				&& isWalletGranted('initiate_transaction', currentWallet.walletId)"
			class="sidebar-navigation-link"
		>
			<router-link
				:to="{ name: 'transfer' }" 
				:active="$route.matched.some(({ name }) => name === 'transfer' || name === 'transfer-type')"
			>
				<span class="mobile-transfer-icon mobile-icon"></span>
				<span>Transfer</span>
				<i
					v-if="$route.matched.some(({ name }) => name === 'transfer' || name === 'transfer-type')"
					class="fas fa-long-arrow-alt-right"
				></i>
			</router-link>
		</div>
		<div
			v-if="currentWallet"
			class="sidebar-navigation-link"
		>
			<router-link
				:to="{ name: 'transactions' }"
				:active="$route.matched.some(({ name }) => name === 'transactions')"
				exact
			>
				<span class="mobile-transactions-icon mobile-icon"></span>
				Transactions
				<i
					v-if="$route.matched.some(({ name }) => name === 'transactions')"
					class="fas fa-long-arrow-alt-right"
				></i>
			</router-link>
		</div>
		<div
			v-if="currentWallet && cardsEnabled"
			class="sidebar-navigation-link"
		>
			<router-link
				:to="{ name: 'cards' }"
				:active="$route.matched.some(({ path }) => path.includes('/cards'))"
				exact
			>
				<span class="mobile-icon"><ion-icon name="card-outline" /></span>
				<span style="position: relative">Cards
					<span v-if="waitingCardsUpdate"
						class="sidebar-navigation__notification"
					>!</span>
				</span>

				<i class="fas fa-long-arrow-alt-right"></i>
			</router-link>
		</div>
		<div
			v-if="paymentRequestEnabled"
			class="sidebar-navigation-link"
		>
			<router-link
				:to="{ name: 'payment-requests' }"
				:active="$route.matched.some(({ path }) => path.includes('/payment-requests'))"
				exact
			>
				<span class="mobile-icon"><ion-icon name="card-outline" /></span>
				<span style="position: relative">Payment request</span>

				<i class="fas fa-long-arrow-alt-right"></i>
			</router-link>
		</div>
		<div
			v-if="paymentRequestCreateEnabled"
			class="sidebar-navigation-link"
		>
			<router-link
				:to="{ name: 'payment-request-create' }"
				:active="$route.matched.some(({ name }) => name === 'payment-request-create')"
			>
				<span class="mobile-transfer-icon mobile-icon"></span>
				<span>Payment request create</span>
				<i
					v-if="$route.matched.some(({ name }) => name === 'payment-request-create')"
					class="fas fa-long-arrow-alt-right"
				></i>
			</router-link>
		</div>
		<div
			v-if="productEnabled"
			class="sidebar-navigation-link"
		>
			<router-link
				:to="{ name: 'products' }"
				:active="$route.matched.some(({ path }) => path.includes('/products'))"
				exact
			>
				<span class="mobile-icon"><ion-icon name="card-outline" /></span>
				<span style="position: relative">Products</span>

				<i class="fas fa-long-arrow-alt-right"></i>
			</router-link>
		</div>
		<div
			v-if="productCreateEnabled"
			class="sidebar-navigation-link"
		>
			<router-link
				:to="{ name: 'product-create' }"
				:active="$route.matched.some(({ name }) => name === 'product-create')"
			>
				<span class="mobile-transfer-icon mobile-icon"></span>
				<span>Product create</span>
				<i
					v-if="$route.matched.some(({ name }) => name === 'product-create')"
					class="fas fa-long-arrow-alt-right"
				></i>
			</router-link>
		</div>
		<div
			v-if="referralEnabled"
			class="sidebar-navigation-link"
		>
			<router-link
				:to="{ name: 'referrals' }"
				:active="$route.matched.some(({ name }) => name === 'referrals')"
			>
				<span class="mobile-icon"><ion-icon name="people-circle-outline" /></span>
				<span v-new-badge="{expire: '2022-03-02', id: 'referral'}">Referral <span class="d-none d-sm-inline-block">Program</span></span>
				<i
					v-if="$route.matched.some(({ name }) => name === 'referrals')"
					class="fas fa-long-arrow-alt-right"
				></i>
			</router-link>
		</div>
		<div class="sidebar-navigation-link">
			<router-link
				v-if="WHITELABEL !== 'verifo'"
				:to="{ name: 'faq' }"
				:active="$route.matched.some(({ name }) => name === 'faq')"
			>
				<span class="mobile-faq-icon mobile-icon"></span>
				FAQ
				<i
					v-if="$route.matched.some(({ name }) => name === 'faq')"
					class="fas fa-long-arrow-alt-right"
				></i>
			</router-link>			
			<a
				v-if="WHITELABEL == 'verifo'"
				href="https://verifo.zendesk.com/hc/en-us"
				target="_blank"
			>
				<span class="mobile-faq-icon mobile-icon"></span>
				FAQ
			</a>
		</div>
	</div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
export default {
	name: 'Navigation',
	components: {
	},
	data() {
		return {
			WHITELABEL
		};
	},
	computed: {
		...mapState('cards', {
			cardList: state => state.list
		}),
		...mapGetters('user', [
			'isGranted',
			'isWalletGranted',
		]),
		...mapGetters('wallet', [
			'currentWallet'
		]),
		waitingCardsUpdate() {
			return this.cardList.filter(c => ['created', 'sent'].includes(c.status)).length > 0;
		},
		referralEnabled() {
			return this.isGranted('front_referral_page');
		},
		cardsEnabled() {
			return this.isGranted('front_card_list');
		},
		paymentRequestEnabled() {
			return this.isGranted('gateway_front_payment_request_page');
		},
		paymentRequestCreateEnabled() {
			return this.isGranted('gateway_front_payment_request_create');
		},
		productCreateEnabled() {
			return this.isGranted('gateway_front_product_create');
		},
		productEnabled() {
			return this.isGranted('gateway_front_product_page');
		}
	},
};
</script>
<style lang="scss" scoped>
	@import './Navigation.scss';
</style>