var render = function () {
  var _vm$data$contacts, _vm$data$contacts2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "account-details profile-account-owner",
    attrs: {
      "id": "profile-account-owner"
    }
  }, [_c('h3', {
    staticClass: "mb-4 mb-lg-5 font-weight-bold"
  }, [_vm._v(" Account owner information ")]), _c('b-card', [_c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('label', {
    staticClass: "col-6 col-sm-5 col-xl-4 col-form-label"
  }, [_vm._v("Name Surname")]), _c('div', {
    staticClass: "col-6 col-sm-7 col-xl-6"
  }, [_c('strong', [_vm._v(_vm._s(_vm.data.name) + " " + _vm._s(_vm.data.surname))])])])]), _c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('label', {
    staticClass: "col-6 col-sm-5 col-xl-4 col-form-label"
  }, [_vm._v("Phone number")]), _c('div', {
    staticClass: "col-6 col-sm-7 col-xl-6"
  }, [(_vm$data$contacts = _vm.data.contacts) !== null && _vm$data$contacts !== void 0 && _vm$data$contacts.mainPhone ? _c('strong', [_vm._v("+" + _vm._s((_vm$data$contacts2 = _vm.data.contacts) === null || _vm$data$contacts2 === void 0 ? void 0 : _vm$data$contacts2.mainPhone))]) : _c('strong', [_vm._v("-")])])])]), _c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('label', {
    staticClass: "col-6 col-sm-5 col-xl-4 col-form-label"
  }, [_vm._v("Address")]), _c('div', {
    staticClass: "col-6 col-sm-7 col-xl-6"
  }, [_c('strong', [_vm._v(_vm._s(_vm.userAddress.address))])])])]), _c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('label', {
    staticClass: "col-6 col-sm-5 col-xl-4 col-form-label"
  }, [_vm._v("City")]), _c('div', {
    staticClass: "col-6 col-sm-7 col-xl-6"
  }, [_c('strong', [_vm._v(_vm._s(_vm.userAddress.city))])])])]), _c('div', {
    staticClass: "form-group"
  }, [_vm.userAddress.state ? _c('div', {
    staticClass: "row align-items-center"
  }, [_c('label', {
    staticClass: "col-6 col-sm-5 col-xl-4 col-form-label"
  }, [_vm._v("State")]), _c('div', {
    staticClass: "col-6 col-sm-7 col-xl-6"
  }, [_c('strong', [_vm._v(_vm._s(_vm.userAddress.state))])])]) : _vm._e()]), _c('div', {
    staticClass: "form-group"
  }, [_vm.userAddress.state ? _c('div', {
    staticClass: "row align-items-center"
  }, [_c('label', {
    staticClass: "col-6 col-sm-5 col-xl-4 col-form-label"
  }, [_vm._v("County of residence")]), _c('div', {
    staticClass: "col-6 col-sm-7 col-xl-6"
  }, [_c('strong', {
    staticClass: "text-uppercase"
  }, [_vm._v(_vm._s(_vm.data.addresses.main.countryId))])])]) : _vm._e()])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }