export default {
	businessActivitiesTypes: [
		{ value: 'legal_services', name: 'Legal services' },
		{ value: 'audit', name: 'Audit' },
		{ value: 'information_technologies', name: 'Information technologies' },
		{ value: 'telecommunications', name: 'Telecommunications' },
		{ value: 'charity', name: 'Charity' },
		{ value: 'financial_services', name: 'Business consultancy' },
		{
			value: 'real_estate_construction',
			name: 'Real estate, construction'
		},
		{ value: 'logistics', name: 'Logistics' },
		{
			value: 'farming_agricultural_activity',
			name: 'Farming and agricultural activity'
		},
		{
			value: 'travel_agencies_activities',
			name: 'Tourism and travel agencies activities'
		},
		{
			value: 'trading_of_petroleum_products',
			name: 'Trading of petroleum products'
		},
		{
			value: 'virtual_currencies_related_activities',
			name: 'Virtual (crypto) currencies related activities'
		},
		{ value: 'health_care', name: 'Health care' },
		{ value: 'pharmaceutical_business', name: 'Pharmaceutical business' },
		{ value: 'payment_operations', name: 'Payment services' },
		{
			value: 'organizing_arrangement_auctions',
			name: 'Organizing and arrangement of auctions'
		},
		{
			value: 'gambling_activities',
			name: 'Gambling and betting activities'
		},
		{
			value: 'electronic_money_institution',
			name: 'Regulated financial institutions'
		},
		{
			value: 'trade_in_means_of_transport',
			name: 'Trade in means of transport'
		},
		{
			value: 'trade_in_arms_ammunition',
			name: 'Trade in arms and ammunition'
		},
		{ value: 'trade_by_internet', name: 'Factoring' },
		{ value: 'currency_exchange', name: 'Currency exchange' },
		{
			value: 'metals_stones_activities',
			name:
				'Activities related to ferrous, non-ferrous or precious metals, precious stones, pieces of art'
		},
		{ value: 'fx_dealer', name: 'Forex broker' },
		{ value: 'online_merchant', name: 'Online Merchant' },
		{ value: 'e_gaming', name: 'E-gaming' },
		{ value: 'advertising', name: 'Advertising' },
		{ value: 'affiliate', name: 'Affiliate ' },
		{ value: 'trade_in_other_goods', name: 'Trade in other goods' },
		{ value: 'other_financial_services', name: 'Other financial services' },
		{ value: 'other', name: 'Other' }
	],
	plannedNumberOfTransactions: [
		{ value: 'lt_30', name: 'Up to 30' },
		{ value: 'lt_60', name: '31 - 60' },
		{ value: 'lt_120', name: '61 - 120' },
		{ value: 'gt_120', name: '121 - more' }
	],
	sourceOfFundsTypes: [
		{
			value: 'virtual_currencies_activities',
			name: 'Business settlements'
		},
		{ value: 'owner_capital', name: 'Direct clients funds' },
		{ value: 'loans', name: 'Loans' },
		{ value: 'investment_income', name: 'Payment Service Provider' },
		{ value: 'other', name: 'Other' }
	],
	turnoverOptionsBusinessTypes: [
		{ value: 'lt_50k2', name: '0 - 50 000 EUR' },
		{ value: 'lt_100k', name: '50 001 - 100 000 EUR' },
		{ value: 'lt_500k', name: '100 001 - 500 000 EUR' },
		{ value: 'lt_2500k', name: '500 001 EUR - 2 500 000 EUR' },
		{ value: 'lt_10m', name: '2 500 001 EUR - 10 000 000 EUR' },
		{ value: 'gt_10m', name: '10 000 001 EUR - more' }
	]
};
