<template>
	<div>
		<div 
			:class="showCollapse ? 'expanded' : ''" 
			class="transaction-item-container"
		>
			<div 
				:class="currentItem ? 'transaction-item--selected' : ''"
				class="transaction-item" 
				@click="selectItem(item)"
			>
				<b-row
					class="align-items-center"
					align-h="between"
				>
					<!-- <div :title="item.status">
                    <img
                        :src="'/images/payment_' + setStatusClass() + '.png'"
                    />
                </div> -->
					<b-col>
						<div class="d-flex align-items-center">
							<div class="avatar d-flex align-items-center justify-content-center">
								<img
									v-if="!['declined', 'failed', 'refund'].includes(item.publicStatus)"
									:src="
										item.direction === 'outgoing'
											? 'images/img/outcome.svg'
											: 'images/img/income.svg'
									"
								/>
								<ion-icon
									v-if="['declined', 'failed'].includes(item.publicStatus)"
									name="close-outline"
									style="color: red"
								/>
								<ion-icon
									v-if="['refund'].includes(item.publicStatus)"
									name="refresh-outline"
								/>
							</div>
							<div>
								<h4>
									{{
										item.direction === 'outgoing'
											? item.beneficiary?.accountHolder
											: item.sender?.accountHolder
									}}
								</h4>
								<div class="date">
									{{ item.dateCreated | timestampToLocalDateTime }}
								</div>
							</div>
						</div>
					</b-col>
					<b-col class="d-none d-xl-block">
						<div class="summary">
							{{ item.details }}
						</div>
					</b-col>
					<b-col class="text-right">
						<div 
							v-if="item.publicStatus === 'completed'" 
							:class="setAmountClass()" 
							class="amount"
						>
							{{
								item.direction === 'outgoing'
									? '-'
									: '+'
							}}{{ item.amount | normalizeAmount }}
							{{ item.currencyId.toUpperCase() }}
						</div>
						<div 
							v-else 
							class="amount"
							:class="{'amount--failed': ['declined', 'failed'].includes(item.publicStatus)}"
						>
							{{ item.amount | normalizeAmount }}
							{{ item.currencyId.toUpperCase() }}
						</div>
					</b-col>
				</b-row>
				<div class="current-item-arrow d-none d-xl-flex">
					<i class="fas fa-arrow-right"></i>
				</div>
			</div>

		<!-- <b-collapse 
			:id="id" 
			v-model="showCollapse" 
			accordion="my-accordion"
		>
			<transaction-table-item-details
				:add-class="`list-dashboard`"
				:item="item"
			/>
		</b-collapse> -->
		</div>
		<div
			v-if="selected && selected.id == item.id"
			class="d-block d-xl-none "
		>
			<transaction-list-item-details
				:item="selected"
				@close="selectItem(null)"
			/>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import TransactionListItemDetails from './TransactionListItemDetails.vue';
import moment from 'moment';

export default {
	name: 'TransactionListItem',
	components: {
		TransactionListItemDetails
	},
	filters: {
		normalizeAmount: function (value) {
			return Math.round(value * 100) / 100;
		},
		timestampToLocalDateTime: function (value) {
			return moment(value).utcOffset(value).format('YYYY-MM-DD HH:mm');
		},
		timestampToDay: function (value) {
			let day = new Date(value * 1000).getDate();
			return ('0' + day).slice(-2);
		},
		timestampToYear: function (value) {
			let day = new Date(value * 1000).getYear();
			return ('0' + day).slice(-2);
		},
		timestampToShortMonth: function (value) {
			var monthNames = [
				'Jan',
				'Feb',
				'Mar',
				'Apr',
				'May',
				'Jun',
				'Jul',
				'Aug',
				'Sep',
				'Oct',
				'Nov',
				'Dec'
			];
			return monthNames[new Date(value * 1000).getMonth()];
		},
		timestampToHourMin: function (value) {
			let d = new Date(value * 1000);
			return (
				('0' + d.getHours()).slice(-2) +
                    ':' +
                    ('0' + d.getMinutes()).slice(-2)
			);
		}
	},
	props: {
		item: {
			type: Object,
			default: () => {}
		},
		id: {
			type: String,
			default: ''
		},
		selected: {
			type: Object,
			default: null
		}
	},
	data() {
		return {
			showCollapse: false
		};
	},
	computed: {
		...mapGetters('wallet', [
			'currentWallet'
		]),
		currentItem: function() {
			return !!(this.selected && this.selected.id == this.item.id);
		}
	},
	methods: {
		selectItem(item) {
			this.$emit('select', item,);
		},
		setAmountClass() {
			if (this.item.direction === 'incoming') {
				return 'greenStatus';
			} else {
				return 'redStatus';
			}
		},
		setStatusClass() {
			if (
				this.item.status === 'declined' ||
                    this.item.status === 'failed'
			) {
				return 'failed';
			} else if (this.item.status === 'completed') {
				return 'completed';
			} else {
				return 'pending';
			}
		}
	},
};
</script>

<style lang="scss" scoped>
	@import './TransactionListItem.scss';

    .darkBg {
        background: var(--bg);
    }

    .redStatus {
        color: var(--red);
    }

    .grayStatus {
        color: var(--gray);
    }

    .greenStatus {
        color: var(--success);
    }
</style>
