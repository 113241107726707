<template>
	<div id="transfer-layout">
		<div class="page-title-container">
			<h2>Product</h2>
		</div>
		<div>
			<product :transfer-data="transferData" />
		</div>
	</div>
</template>
<script>
import Product from './Product';

export default {
	name: 'ProductLayout',
	components: {
		Product,
	},
	props: {
		transferData: {
			type: Object,
			default: () => ({}),
			required: false
		},
		transferType: {
			type: String,
			default: null,
			required: false
		}
	},
};
</script>
