<template>
	<div>
		<form 
			action="#" 
			class="form-body margintop30" 
			@submit.prevent="onSubmit()"
		>
			<h2 class="page-title">
				Primary account information
			</h2>
			<fieldset :disabled="details.verification == 'pending'">
				<div>
					<questionnaire-business-activities v-model="data.businessActivities">
						<template #label>
							<label>
								Main business activity
							</label>
						</template>
						<template #other-label>
							<label>
								Please specify other activities
							</label>
						</template>
					</questionnaire-business-activities>
				</div>
				<div>
					<b-row>
						<b-col>
							<form-error-field name="sourceOfFunds.values">
								<label>
									Source of customer's funds <i
										class="fa fa-question-circle-o pull-right"
										aria-hidden="true"
										@click.prevent="$modal.show('source_of_funds')"
									></i>
								</label>
								<v-multiselect
									v-model="data.sourceOfFunds.values"
									v-validate="'required'"
									:options="options.sourceOfFundsTypes"
									:multiple="true"
									:allow-empty="false"
									:clear-on-select="true"
									:close-on-select="false"
									name="sourceOfFunds.values"
									label="name"
									placeholder="Select Your source of customer's funds"
									track-by="name"
									return="value"
									data-registration="source-of-funds"
									data-vv-as="Source of customer's funds"
								>
									<template slot="selection">
										<span class="multiselect__single">options selected</span>
									</template>
								</v-multiselect>
							</form-error-field>
							<form-error-field v-show="data.sourceOfFunds.values.includes('other')"
								name="sourceOfFunds.otherValue"
								class="pt-2 mb-12"
							>
								<label>
									Please specify other sources of funds
								</label>
						
								<input
									id="source_of_funds_other"
									v-model="data.sourceOfFunds.otherValue"
									v-validate="requiredSourceOfFunds"
									type="text"
									class="form-control"
									name="sourceOfFunds.otherValue"
									placeholder="Specify source of funds"
									maxlength="200"
									data-registration="source-of-funds-other"
									data-vv-as="Other source of funds"
								>
							</form-error-field>
						</b-col>
					</b-row>
				</div>

				<div>
					<b-row>
						<b-col>
							<form-error-field name="turnovers.count.incomingMonthly">
								<label>
									Monthly planned number of incoming transactions
								</label>
								<input
									id="planned_number_of_incoming_transactions"
									v-model="data.turnovers.count.incomingMonthly"
									v-validate="'required'"
									data-vv-as="Monthly planned number of incoming transactions"
									type="number"
									class="form-control"
									name="turnovers.count.incomingMonthly"
									placeholder="Enter Your monthly planned number of incoming transactions"
									min="1"
									max="2147483647"
									data-registration="monthly-incoming-transactions"
								>
							</form-error-field>
						</b-col>
					</b-row>
				</div>

				<div>
					<b-row>
						<b-col>
							<form-error-field name="turnovers.amount.incomingMonthly">
								<label>
									Monthly planned turnover of incoming transactions, EUR
								</label>
								<input
									id="planned_monthly_incoming_turnover"
									v-model="data.turnovers.amount.incomingMonthly"
									v-validate="'required'"
									data-vv-as="Monthly planned turnover of incoming transactions"
									type="number"
									class="form-control"
									name="turnovers.amount.incomingMonthly"
									placeholder="Enter Your planned incoming monthly turnover"
									min="1"
									max="2147483647"
									data-registration="monthly-incoming-amount"
								>
							</form-error-field>
						</b-col>
					</b-row>
				</div>
				<div>
					<b-row>
						<b-col>
							<form-error-field name="countriesFundsOriginateFrom"
								class="text-left mb-12"
							>
								<label>
									Countries, where funds are received from
								</label>
								<v-multiselect
									v-model="data.countriesFundsOriginateFrom"
									v-validate="'required'"
									:options="countryOptions"
									:multiple="true"
									:close-on-select="false"
									:clear-on-select="false"
									:preserve-search="true"
									:preselect-first="false"
									name="countriesFundsOriginateFrom"
									placeholder="Make selection"
									label="name"
									track-by="name"
									return="countryId"
									data-registration="origin-country"
									data-vv-as="Countries, where funds are received from"
								/>
							</form-error-field>
						</b-col>
					</b-row>
				</div>

				<div>
					<b-row>
						<b-col>
							<form-error-field name="turnovers.count.outgoingMonthly">
								<label>
									Monthly planned number of outgoing transactions
								</label>
								<input
									id="planned_number_of_outgoing_transactions"
									v-model="data.turnovers.count.outgoingMonthly"
									v-validate="'required'"
									data-vv-as="Monthly planned number of outgoing transactions"
									type="number"
									class="form-control"
									name="turnovers.count.outgoingMonthly"
									placeholder="Enter Your monthly planned number of outgoing transactions"
									min="1"
									max="2147483647"
									data-registration="monthly-outgoing-transactions"
								>
							</form-error-field>
						</b-col>
					</b-row>
				</div>
				<div>
					<b-row>
						<b-col>
							<form-error-field name="turnovers.amount.outgoingMonthly">
								<label>
									Monthly planned turnover of outgoing transactions, EUR
								</label>
							
								<input
									id="planned_monthly_outgoing_turnover"
									v-model="data.turnovers.amount.outgoingMonthly"
									v-validate="'required'"
									data-vv-as="Monthly planned turnover of outgoing transactions"
									type="number"
									class="form-control"
									name="turnovers.amount.outgoingMonthly"
									placeholder="Enter Your planned outgoing monthly turnover"
									min="1"
									max="2147483647"
									data-registration="monthly-outgoing-amount"
								>
							</form-error-field>
						</b-col>
					</b-row>
				</div>
				<div>
					<b-row>
						<b-col>
							<form-error-field name="countriesFundsOriginateTo"
								class="text-left mb-12"
							>
								<label>
									Countries, where funds are sent to
								</label>
								<v-multiselect 
									v-model="data.countriesFundsOriginateTo"
									v-validate="'required'"
									:options="countryOptions"
									:multiple="true"
									name="countriesFundsOriginateTo"
									:close-on-select="false"
									:clear-on-select="false"
									:preserve-search="true"
									:preselect-first="false"
									placeholder="Make selection"
									label="name"
									track-by="name"
									return="countryId"
									data-registration="destination-country"
									data-vv-as="Countries, where funds are sent to"
								/>
							</form-error-field>
						</b-col>
					</b-row>
				</div>
				<div>
					<b-row>
						<b-col>
							<form-error-field name="turnovers.cash.outgoingMonthly">
								<label>
									The sum of monthly planned cash withdrawals, EUR
								</label>
								<b-form-input
									v-model="data.turnovers.cash.outgoingMonthly"
									v-validate="'required'"
									name="turnovers.cash.outgoingMonthly"
									type="number"
									min="0"
									max="2147483647"
									placeholder="Enter Your planned monthly cash withdrawals"
									data-registration="planned-cash"
									data-vv-as="The sum of monthly planned cash withdrawals, EUR"
								/>
							</form-error-field>
						</b-col>
					</b-row>
				</div>

				<div class="registration-section-banks">
					<div class="form-group mb-0">
						<label>
							Accounts in other banks or payment/electronic money institutions
						</label>
					</div>
					<div 
						v-for="(bank, index) in data.otherBankList" 
						:key="index"
					>
						<b-row>
							<b-col sm="6">
								<form-error-field :name="`otherBankList[${index}][name]`">
									<label class="label">Name of institution</label>
									<b-form-input
										:id="bank.name"
										v-model="bank.name"
										:name="`otherBankList[${index}][name]`"
										type="text"
										autocomplete="off_name"
										placeholder="Enter name of institution"
										:data-registration="`bank-name-${index}`"
										data-vv-as="Name of institution"
									/>
								</form-error-field>
							</b-col>
							<b-col sm="6">
								<form-error-field :name="`otherBankList[${index}][countryId]`">
									<label class="label">Country of institution</label>
									<v-multiselect
										v-model="bank.countryId"
										:name="`otherBankList[${index}][countryId]`"
										:options="countryOptions"
										:show-labels="false"
										:allow-empty="true"
										label="name"
										placeholder="Select country"
										track-by="countryId"
										return="countryId"
										:data-registration="`bank-country-${index}`"
										data-vv-as="Country of institution"
									/>
								</form-error-field>
							</b-col>
						</b-row>
					</div>

					<div 
						class="btn btn-dark btn-sm px-5" 
						data-registration="add-bank"
						@click="addInput('otherBankList')"
					>
						Add
					</div>
					<div 
						v-if="data.otherBankList?.length > 0" 
						class="btn btn-outline-danger btn-sm px-3 ml-2"
						data-registration="remove-bank"
						@click="removeInput('otherBankList')"
					>
						Remove
					</div>
				</div>
				<div class="registration-section-partners mt-4">
					<div class="form-group mb-0">
						<label>
							Biggest transaction partners
						</label>
					</div>
					<div 
						v-for="(partner, i) in data.transactionPartnerList" 
						:key="i"
					>
						<b-row>
							<b-col sm="6">
								<form-error-field :name="`transactionPartnerList[${i}][name]`">
									<label class="label">Name of partner</label>
									<b-form-input
										:id="partner.name"
										v-model="partner.name"
										v-validate="'required'"
										:name="`transactionPartnerList[${i}][name]`"
										type="text"
										autocomplete="off_name"
										placeholder="Enter name of institution"
										:data-registration="`partner-name-${i}`"
										data-vv-as="Name of partner"
									/>
								</form-error-field>
							</b-col>
							<b-col sm="6">
								<form-error-field :name="`transactionPartnerList[${i}][countryId]`">
									<label class="label">Country of partner</label>
									<v-multiselect
										v-model="partner.countryId"
										v-validate="'required'"
										:name="`transactionPartnerList[${i}][countryId]`"
										:options="countryOptions"
										:show-labels="false"
										:allow-empty="true"
										label="name"
										placeholder="Select country"
										track-by="countryId"
										return="countryId"
										:data-registration="`partner-country-${i}`"
										data-vv-as="Country of partner"
									/>
								</form-error-field>
							</b-col>
						</b-row>
					</div>
					<form-error-field name="transactionPartnerList"
						class="pb-3 position-relative"
					>
						<div 
							class="btn btn-sm px-5"
							:class="errorTransactionPartners && data.transactionPartnerList?.length == 0 ? 'btn-danger' : 'btn-dark'" 
							data-registration="add-partner"
							@click="addInput('transactionPartnerList')"
						>
							Add
						</div>
						<div 
							v-if="data.transactionPartnerList?.length > 1"
							class="btn btn-outline-danger btn-sm px-3 ml-2"
							data-registration="remove-partner"
							@click="removeInput('transactionPartnerList')"
						>
							Remove
						</div>
					</form-error-field>
				</div>
			</fieldset>
			<div>
				<b-row>
					<b-col class="mt-4 mb-4  text-center">
						<notification />
						<input 
							:class="buttonClass"
							:value="buttonText"
							:disabled="nextEnabled == false"
							type="submit"
							class="btn btn-primary btn-rounded btn-upper w-100"
							data-registration="next"
						/>
					</b-col>
				</b-row>
				<b-row v-if="allowSkip">
					<b-col class="text-center">
						<router-link 
							:to="{name: 'home'}" 
							tag="a" 
							class="framelink skiplink" 
							exact
							data-registration="skip"
						>
							<span v-if="details.verification !== 'pending'">Skip verification</span>
							<span v-if="details.verification == 'pending'">Back to main page</span>
						</router-link>
					</b-col>
				</b-row>
			</div>
		</form>
		<modal
			:adaptive="true"
			name="source_of_funds"
			height="auto"
		>
			<div
				class="close"
				@click="$modal.hide('source_of_funds')"
			></div>
			<div class="ml-4 mr-4 mt-4 mb-4">
				Please note that:<br />
				<strong>Direct clients funds</strong> - Money deposited by the client to their account that remains their property. For example, deposited to a trading account. <br /><br />
				<strong>Business settlements</strong> - Daily operational settlements, fees, and payments implemented by your clients and/or partners. 
			</div>
		</modal>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import config from '~/config';
import QuestionnaireBusinessActivities from './QuestionnaireBusinessActivities';
import options from './QuestionnaireBusinessOptions';
import { handleApiFormErrors } from '~/helpers';

import FormErrorField from '@/shared/Form/FormErrorField';

export default {
	name: 'BusinessQuestionnaireForm',
	components: {
		QuestionnaireBusinessActivities,
		FormErrorField
	},
	props: {
		buttonClass: {
			type: String,
			default: ''
		},
		buttonText: {
			type: String,
			default: 'Next'
		},
		allowSkip: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			data: {
				businessActivities: {
					values: [],
					otherValue: ''
				},
				sourceOfFunds: {
					values: [],
					otherValue: ''
				},
				otherBankList: [],
				turnovers: {
					amount: { incomingMonthly: '', outgoingMonthly: '' },
					count: { incomingMonthly: '', outgoingMonthly: '' },
					cash: { outgoingMonthly: '0' },
				},
				transactionPartnerList: []
			},
			nextEnabled: true,
			otherActivity: '',
			errorCheckBoxesSourceOfFunds: false,
			options: {
				...options,
				businessActivitiesTypes: options.businessActivitiesTypes
					.sort((a, b) => b.value == 'other' ? -1 : a.name < b.name ? -1 : 1)
			},
			agreementUrl: config.agreementUrl,
			errorTransactionPartners: false
		};
	},
	computed: {
		...mapState('countries', {
			countryOptions: state => state.list
		}),
		...mapState('user', {
			questionnaire: state => state.selected.companyProfile.questionnaire,
			company: state => state.selected.companyProfile,
			details: state => state.selected
		}),
		...mapGetters('user', ['isPersonal', 'isBusiness', 'owner']),
		requiredSourceOfFunds() {
			return this.data.sourceOfFunds.values.includes('other') ? 'required' : '';
		},
		requiredBusinessActivity() {
			return this.data.businessActivities.values.includes('other') ? 'required' : '';
		},
	},
	watch: {
		data: {
			handler(value) {
				this.updateQuestionnaire(value);
			},
			deep: true
		},
		'data.transactionPartnerList': {
			handler() {
				this.errorTransactionPartners = false;
			},
			deep: true
		},
		activities: {}
	},

	async mounted() {
		this.$validator.pause();
		await this.getCountries();
		this.$gtag.event(`register_verification_questionnaire_${this.isBusiness ? 'business' : 'personal'}`);
		this.data = { ...this.data, ...this.questionnaire, };
		this.$nextTick(() => {
			this.$validator.reset();
			this.$validator.resume();
		});
	},
	methods: {
		...mapActions('countries', ['getCountries']),
		...mapActions('user', ['updateQuestionnaire', 'submitBusinessQuestionnaireData']),
		...mapActions('notifications', ['pushError']),
		addInput(field) {
			if (this.details.verification == 'pending') return;
			this.data[field].push({
				name: null,
				countryId: null,
			});
		},
		removeInput(field) {
			if (this.details.verification == 'pending') return;
			this.data[field].splice(-1, 1);
		},
		async onSubmit() {
			if (this.details.verification == 'pending') {
				this.$router.push({ name: 'verify-business-documents' });
				return;
			}

			this.errorCheckBoxesSourceOfFunds = this.data.sourceOfFunds.values.length === 0;
			this.errorTransactionPartners = !this.data.transactionPartnerList?.length || !this.data.transactionPartnerList[0]?.name || !this.data.transactionPartnerList[0]?.countryId; 

			const result = await this.$validator.validateAll();
			if (this.errorCheckBoxesSourceOfFunds || !this.data.transactionPartnerList || !result) {
				this.pushError('Please check the red fields');
				return;
			}

			if (result) {
				this.nextEnabled = false;
				try {
					await this.submitBusinessQuestionnaireData(this.data);
					this.nextEnabled = true;
					this.onSuccess();
				} catch (err) {
					this.nextEnabled = true;
					this.$nextTick(() => {
						handleApiFormErrors(err, this.$validator.errors);
					});
					// if (err.fields) {
					// 	Object.entries(err.fields).forEach(([field, msg]) => {
					// 		this.errors.add({
					// 			field, msg
					// 		});
					// 	});
					// }
					this.pushError(err.message);
				}
				return;
			}
			this.pushError('Please check the red fields');
		},
		async onSuccess() {
			this.$router.push({ name: 'verify-business-documents' });
		},
		handleError(error) {
			this.nextEnabled = true;
			this.pushError(error);
		}
	},
	provide() {
		return {
			$validator: this.$validator
		};
	}
};
</script>

<style lang="scss" scoped>
	.label {
		i {
			cursor: pointer;
		}
	}
</style>