var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card-list"
  }, [_c('b-container', ['active' === _vm.currentWallet.status ? _c('b-row', [_c('b-col', [_c('div', {
    staticClass: "page-title-container"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('h2', [_vm._v("Cards")]), _c('new-card-button')], 1)])])], 1) : _vm._e(), _c('b-row', [_vm._l(_vm.activeCards, function (card, i) {
    return _c('b-col', {
      key: "active-".concat(i),
      staticClass: "card-list__card mb-5",
      attrs: {
        "md": "3",
        "sm": "6"
      },
      on: {
        "click": function click($event) {
          return _vm.$router.push({
            path: "/cards/view/".concat(card.id)
          });
        }
      }
    }, [_c('b-card', {
      staticClass: "card-list__item-wrap",
      attrs: {
        "no-body": ""
      }
    }, [_c('div', [_c('card-image', {
      attrs: {
        "virtual": card.isVirtual,
        "show-details": false,
        "width": "30%"
      }
    }), _c('div', {
      staticClass: "mt-2"
    }, [_vm._v(" " + _vm._s("".concat(card.isVirtual ? 'Virtual' : 'Plastic', " ").concat(_vm.formatShortCardNumber(card.cardNumber))) + " ")]), _c('div', {
      staticClass: "mb-4"
    }, [card.status == 'active' ? _c('small', [_vm._v("Expires on " + _vm._s(_vm.formatExpirationDate(card.validTo)))]) : _vm._e(), ['created', 'waiting_pin'].includes(card.status) ? _c('small', {
      staticClass: "card-list__pending"
    }, [_vm._v("Waiting for PIN")]) : _vm._e(), ['sent', 'waiting_cvv'].includes(card.status) ? _c('small', {
      staticClass: "card-list__pending"
    }, [_vm._v("Shipped, ready for activation")]) : _vm._e(), ['suspended_client', 'suspended_system', 'suspended_admin'].includes(card.status) ? _c('small', {
      staticClass: "card-list__pending"
    }, [_vm._v(" Temporary blocked "), 'suspended_client' !== card.status || 'active' !== _vm.currentWallet.status ? _c('span', [_vm._v("(System)")]) : _vm._e()]) : _vm._e()])], 1), _c('div', {
      staticClass: "card-list__link"
    }, [card.status === 'active' ? _c('span', [_vm._v("Settings")]) : _vm._e(), ['created', 'waiting_pin'].includes(card.status) ? _c('span', [_vm._v("Enter PIN")]) : _vm._e(), card.status === 'suspended_client' && 'active' === _vm.currentWallet.status ? _c('span', [_vm._v("Unblock")]) : _vm._e(), ['sent', 'waiting_cvv'].includes(card.status) ? _c('span', [_vm._v("Activate")]) : _vm._e(), ['active', 'waiting_pin', 'suspended_client', 'waiting_cvv', 'sent'].includes(card.status) && 'active' === _vm.currentWallet.status ? _c('span', {
      staticClass: "card-list__arrow"
    }) : _vm._e()])])], 1);
  }), _vm._l(_vm.ordersList, function (card, i) {
    return _c('b-col', {
      key: "order-".concat(i),
      staticClass: "card-list__card card-list__card--nolink mb-5",
      attrs: {
        "md": "3",
        "sm": "6"
      }
    }, [_c('b-card', {
      staticClass: "card-list__item-wrap",
      attrs: {
        "no-body": ""
      }
    }, [_c('div', [_c('card-image', {
      staticClass: "card-list__innactive-img",
      attrs: {
        "virtual": card.isVirtual,
        "show-details": false,
        "width": "30%"
      }
    }), _c('div', {
      staticClass: "mt-2"
    }, [_vm._v(" " + _vm._s("".concat(card.isVirtual ? 'Virtual' : 'Plastic')) + " ")]), _c('div', {
      staticClass: "mb-4"
    }, [_c('small', [_vm._v("Card order in progress")])])], 1)])], 1);
  })], 2), !!_vm.innactiveCards.length ? _c('div', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.innactive-cards",
      modifiers: {
        "innactive-cards": true
      }
    }],
    staticClass: "d-flex align-items-center mb-5 card-list__dropdown-button"
  }, [_c('ion-icon', {
    staticClass: "card-list__dropdown-arrow icon-closed",
    attrs: {
      "name": "chevron-down-outline"
    }
  }), _c('ion-icon', {
    staticClass: "card-list__dropdown-arrow icon-opened",
    attrs: {
      "name": "chevron-up-outline"
    }
  }), _c('strong', {
    staticClass: "ml-1"
  }, [_vm._v("Innactive cards")]), _c('span', {
    staticClass: "ml-1"
  }, [_vm._v("(" + _vm._s(_vm.innactiveCards.length) + ")")])], 1) : _vm._e(), _c('b-collapse', {
    attrs: {
      "id": "innactive-cards"
    }
  }, [_c('b-row', [_vm._l(_vm.innactiveCards, function (card, i) {
    return _c('b-col', {
      key: "innactive-".concat(i),
      staticClass: "card-list__card mb-5",
      attrs: {
        "md": "3",
        "sm": "6"
      },
      on: {
        "click": function click($event) {
          return _vm.$router.push({
            path: "/cards/view/".concat(card.id)
          });
        }
      }
    }, [_c('b-card', {
      staticClass: "card-list__item-wrap",
      attrs: {
        "no-body": ""
      }
    }, [_c('div', [_c('card-image', {
      staticClass: "card-list__innactive-img",
      attrs: {
        "virtual": card.isVirtual,
        "show-details": false,
        "width": "30%"
      }
    }), _c('div', {
      staticClass: "mt-2"
    }, [_vm._v(" " + _vm._s("".concat(card.isVirtual ? 'Virtual' : 'Plastic', " ").concat(_vm.formatShortCardNumber(card.cardNumber))) + " ")]), _c('div', {
      staticClass: "mb-4"
    }, [card.status == 'closed' ? _c('small', {
      staticClass: "card-list__warning"
    }, [_vm._v("Blocked or expired")]) : _vm._e()])], 1), _c('div', {
      staticClass: "card-list__link"
    }, [card.status == 'closed' ? _c('span', [_vm._v("View")]) : _vm._e(), _c('span', {
      staticClass: "card-list__arrow"
    })])])], 1);
  }), _vm._l(_vm.failedOrdersList, function (card, i) {
    return _c('b-col', {
      key: "failed-".concat(i),
      staticClass: "card-list__card card-list__card--nolink mb-5",
      attrs: {
        "md": "3",
        "sm": "6"
      }
    }, [_c('b-card', {
      staticClass: "card-list__item-wrap",
      attrs: {
        "no-body": ""
      }
    }, [_c('div', [_c('card-image', {
      staticClass: "card-list__innactive-img",
      attrs: {
        "virtual": card.isVirtual,
        "show-details": false,
        "width": "30%"
      }
    }), _c('div', {
      staticClass: "mt-2"
    }, [_vm._v(" " + _vm._s("".concat(card.isVirtual ? 'Virtual' : 'Plastic')) + " ")]), _c('div', {
      staticClass: "mb-4"
    }, [_c('small', {
      staticClass: "card-list__warning"
    }, [_vm._v("Card order rejected.")])])], 1)])], 1);
  })], 2)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }