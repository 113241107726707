<template>
	<div class="auth-card">
		<h1 class="auth-title">
			Select customer
		</h1>
		<b-list-group class="mb-0">
			<b-list-group-item 
				v-for="user in list" 
				v-show="user.profileId" 
				:key="user.profileId" 
				href="#"
				class="content-item" 
				@click.prevent="select(user)"
			>
				{{ setUsername(user) }}
			</b-list-group-item>
		</b-list-group>
	</div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { setUsername } from '~/helpers';
export default {
	computed: {
		...mapState('user', ['list'])
	},
	methods: {
		...mapActions('user', ['selectUser']),
		setUsername,
		async select(user) {
			try {
				await this.selectUser(user.profileId);
				return this.$router.replace({ name: 'home' });
			} catch (err) {
				throw new Error(err);
			}
		}
	},
	beforeMount() {
		if (this.list.length == 0) {
			return this.$router.push({ name: 'login' });
		}
	}
};
</script>
