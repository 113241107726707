<template>
	<div
		v-if="isActive && !disabled"
		class="tab"
	>
		<slot></slot>
	</div>
</template>
<script>
export default {
	name: 'Tab',
	props: {
		title: {
			type: String,
			default: '',
			required: true
		},
		disabled: {
			type: Boolean,
			default: false,
			required: false
		},
		active: {
			type: Boolean,
			default: false,
			required: false
		}
	},
	data() {
		return {
			isActive: true
		};
	},
};
</script>
<style lang="scss">
	
</style>