<template>
	<div class="odd-form">
		<b-form>
			<b-row>
				<b-col>
					<h2>Update your company documents.</h2>
				</b-col>
			</b-row>
			<b-row v-for="(doc, index) in documents"
				:key="index"
			>
				<b-col>
					<b-form-group>
						<label>
							{{ doc.name }}
						</label>
						<file-upload :name="doc.value"
							accept=".jpg, .jpeg, .gif, .png, .pdf, .eml"
							@onChange="e => setDocument(e, doc.value)"
						/>
					</b-form-group>
				</b-col>
			</b-row>
		</b-form>
		<odd-form-footer 
			v-bind="properties"
			:submit-disabled="!filesUploaded"
		/>
	</div>
</template>
<script>
import OddFormFooter from '../OddFormFooter';
import FileUpload from '~/components/shared/FileUpload';

export default {
	name: 'ODDCompanyDocuments',
	components: {
		OddFormFooter,
		FileUpload
	},
	data() {
		return {
			formData: { files: [] },
			documents: [
				{ value:'registration_document', name: 'Registration Document' },
				{ value: 'proof_of_address_document', name: 'Proof of address Document' },
				{ value: 'company_register_extract_document', name: 'Company Registration extract Document' }
			]
		};
	},
	computed: {
		properties() {
			return {
				...this._props, 
				formData: this.formData
			};
		},
		filesUploaded() {
			return this.formData.files.length == this.documents.length;
		}
	},
	props: {
		onSuccess: {
			type: Function,
			default: () => {},
			required: true
		},
		onFailure: {
			type: Function,
			default: () => {},
		},
		onCancel: {
			type: Function,
			default: () => {}
		},
		submitUrl: {
			type: String,
			default: '',
			required: true
		}
	},
	methods: {
		setDocument(file, value) {
			const index = this.formData.files.findIndex(file => file.type == value);
			if (index >= 0) this.formData.files.splice(index, 1);
			this.formData.files.push(
				{
					type: value,
					fileName: file[0].name,
					file: file[0].content
				}
			);
		}
	}
};
</script>
<style lang="scss">
	
</style>