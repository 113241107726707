import { formatEndpoints } from '~/helpers';
import { api, paymentGateway } from '~/config';

const urls = {
	auth: {
		login: 				'/v2/authentication/login',
		refreshToken: 		'/v2/authentication/token',
		recoverPass:		'/v2/password-recover/create',
		recoverPassConfirm:	'/v2/password-recover/confirm',
		recoverPassResend:	'/v2/password-recover/resend',
		recoverPassVerify:	'/v2/password-recover/{hash}',
		recoverPassChange:	'/v2/password-recover/change',
		recoverPassInit:	'/v2/password-recover/2fa/init',
		logout: 			'/v2/authentication/logout',
		confirm: 			'/v2/authentication/2fa',
		create: 			'/front/user/authentication/create-confirm',
		init2fa:			'/v2/authentication/2fa/init'
	},
	authenticator: {
		change:				'/v2/setting/authenticator/change',
		confirm:			'/v2/setting/authenticator/confirm',
		regenerateGoogle:	'/v2/setting/authenticator/regenerate',
	},
	cards: {
		countryList:	'/v2/country/list/card',
		prepare:		'/v2/authentication/2fa/init',
		order:			'/v2/wallet/{walletId}/card/order',
		cardsList:		'/v2/cards/list',
		cardsOrdersList:'/v2/card/order/list',
		cardInfo:		'/v2/cards/{cardId}',
		setPin:			'/v2/card/{cardId}/pin/set',
		activate:		'/v2/card/{cardId}/activate',
		block:			'/v2/cards/{cardId}/block',
		unblock:		'/v2/cards/{cardId}/unblock',
		close:			'/v2/cards/{cardId}/close',
		detailsAuth:	'/v2/card/{cardId}/details/authenticator',
		pinAuth:		'/v2/card/{cardId}/pin/authenticator',
		set3d:			'/v2/card/{cardId}/3d/set',
		securityParams:	'/v2/cards/{cardId}/security-parameters/set',

		jwkKey:			'/v2/card/{cardId}/encrypted/key/generate',
		jwkShowPin:		'/v2/card/{cardId}/encrypted/pin/show',
		jwkShowDetails:	'/v2/card/{cardId}/encrypted/details/show',
		jwkSet3d:		'/v2/card/{cardId}/encrypted/3d/set',
		jwkSetPin:		'/v2/card/{cardId}/encrypted/pin/set',
		jwkActivate:	'/v2/card/{cardId}/encrypted/activate',

		jwtInitStatus: '/v2/card/{cardId}/jwt/status',
		jwtDetailsUrl: '/v2/card/{cardId}/jwt/details/show',
		jwtPinUrl: '/v2/card/{cardId}/jwt/pin/show',
		jwt3dsUrl: '/v2/card/{cardId}/jwt/3d/set',
		jwtPinSetUrl: '/v2/card/{cardId}/jwt/pin/set',
		jwtActivateUrl: '/v2/card/{cardId}/jwt/activate',
	},
	countries: {
		list:				'/v2/country/list',
		listSwift:			'/v2/country/list/currency-cloud',
		listPhone:			'/v2/country/list/phone/registration',
	},
	currency: {
		list:			'/v2/currency/list',
		listSwift:		'/v2/currency/list/currency-cloud',
	},
	email: {
		incomingTransactionComplete:	'/v2/setting/email'
	},
	identity: {
		userIframeUrl: 			'/v2/registration/identity/create',
		getIframeUrl: 			'/v2/identity/create/{hash}',
		getUnverified:			'/v2/identity/status/{hash}'
	},
	log: {
		userLog:				'/v2/profile/log/page'
	},
	user: {
		current: 				'/v2/profile/current',
		list: 					'/v2/profile/list',
		setting: 				'/v2/profile/setting',
		switch: 				'/v2/profile/switch',
		accountInfo:			'/v2/registration/company/account-information',
		userProfile:			'/v2/registration/person/information',
		userQuestionnaire:		'/v2/registration/person/questionnaire',
		companyInfo:			'/v2/registration/company/information',
		companyProfile:			'/v2/registration/company/relation',
		companyQuestionnaire: 	'/v2/registration/company/questionnaire',
		companyDocuments:		'/v2/registration/company/document',
		passwordChange:			'/v2/user/password-change',
		passwordChangeConfirm:	'/v2/user/password-confirm',

		newCompany:				'/v2/registration/company',
	},
	referral: {
		referralList: 	'/v2/referral/page',
		commission:		'/v2/referral/commission/page',
		wallet:			'/v2/referral/wallet/list',
		report:			'/v2/referral/commission/report',
		graph:			'/v2/referral/commission/graphic/list'
	},
	register: {
		register:			'/v2/registration/register',
		confirmRegister:	'/v2/registration/{hash}/confirm',
		resend:				'/v2/registration/resend-email',
		verify:				'/v2/registration/{hash}',
		init2fa:			'/v2/registration/{hash}/2fa/init',
		screenshot:			'/v2/registration/screenshot'
	},
	transfer: {
		sepa:					'/v2/transaction/{walletId}/sepa',
		sepaPrepare:			'/v2/transaction/{walletId}/sepa/prepare',
		swift: 					'/v2/transaction/{walletId}/swift',
		swiftPrepare: 			'/v2/transaction/{walletId}/swift/prepare',
		bulkUpload:				'/v2/payment/file/{walletId}/upload',
		bulkFileList:			'/v2/payment/file/page',
		bulkItemsList:			'/v2/payment/file/{fileId}/page',
		bulkTransfer:			'/v2/payment/file/{fileId}/process',
		bulkFileDelete:			'/v2/payment/file/{fileId}/delete',
		bulkFileDeleteRow:		'/v2/payment/file/{fileId}/delete/row',
		type:					'/v2/transaction/{walletId}/type',
		fee:					'/v2/transaction/{walletId}/fee',
		nanoSwiftTransfer:		'/v2/transaction/{walletId}/swift',
		currencyCloudRate:		'/v2/transaction/{walletId}/rate/currency-cloud',
		currencyCloud:			'/v2/transaction/{walletId}/currency-cloud',
		currencyCloudPrepare:	'/v2/transaction/{walletId}/currency-cloud/prepare',
		initiateSepa:			'/v2/transaction/{walletId}/initiate/sepa',
		initiateSwift:			'/v2/transaction/{walletId}/initiate/swift',
		initiateCurrencyCloud:	'/v2/transaction/{walletId}/initiate/currency-cloud',
		initiateApprove:		'/v2/transaction/{walletId}/initiate/approve',
		initiateDelete:			'/v2/transaction/{walletId}/initiate/delete',
		templateDelete:			'/v2/transaction/{walletId}/template/delete'
	},
	transactions: {
		statementSingle:	'/v2/transaction/statement/{transactionId}/download',
		statementCreate: 	'/v2/transaction/statement/{walletId}/create',
		statementFeeCreate: '/v2/transaction/fee/statement/{walletId}/create',
		statementInfo: 		'/v2/transaction/statement/{statementId}',
		statementPage: 		'/v2/transaction/statement/{walletId}/page',
		statementMulti:		'/v2/transaction/statement/{statementId}/download-list',
		list:				'/v2/transaction/{walletId}/page',
		initiateList:		'/v2/transaction/{walletId}/initiate/page',
		templateList:		'/v2/transaction/{walletId}/template/page'
	},
	wallet: {
		update:					'/v2/wallet/{walletId}/update',
		balance:				'/v2/wallet/{walletId}',
		list:					'/v2/wallet/list'
	},
	verification: {
		customerAgreement:		'/v2/customer-agreement',
		serviceAgreementPdf:	'/v2/customer-agreement/service-agreement',
		privacyPolicyPdf:		'/v2/customer-agreement/privacy-policy',
	},
	odd: {
		companyInformation: 	'/v2/company/information',
		companyQuestionnaire: 	'/v2/company/questionnaire',
		companyRelation:	 	'/v2/company/relation',
		companyTurnover:	 	'/v2/company/turnover',
		companyIdentity:	 	'/v2/company/identity',
		companyDocument:	 	'/v2/company/document',
		personInformation:		'/v2/person/information',
		personQuestionnaire:	'/v2/person/questionnaire',
		personTurnover:			'/v2/person/turnover',
		personDocument:			'/v2/person/document',
		personIdentity:			'/v2/person/identity',
	}
};

const gatewayUrls = {
	paymentRequests: {
		page: '/payment-request/page',
		create: '/product/{productId}/payment-request'
	},
	product: {
		page: '/product/page',
		create: '/product',
		update: '/product/{productId}',
		disable: '/product/{productId}',
		key: '/product/{productId}/key',
		assign: '/product/{productId}/payment-method/{paymentMethodId}/assign',
		list: '/product/{productId}/payment-method/list',
		disableProductMethod: '/product/{productId}/payment-method/{productPaymentMethod}/disable',
	},
	paymentMethod: {
		list: '/payment-method/list',
	},
};

/*
*	Converts urls with API domain in front.
*	Maps urls with brackets "{}" to functions which returns url with replaced value.
*	For example formatted url "/user/{hash}/" returns function({hash: value}).
*/

export const endpoints = formatEndpoints(urls, api);
export const endpointsGateway = formatEndpoints(gatewayUrls, paymentGateway);
