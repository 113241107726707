var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.iframeLoaded && _vm.isDesktop && _vm.isVerified === false ? _c('iframe', {
    staticStyle: {
      "width": "100%",
      "height": "800px",
      "border": "none"
    },
    attrs: {
      "id": "iframe",
      "src": _vm.iframeUrl,
      "data-registration": "iframe",
      "allow": "camera;microphone"
    }
  }) : _vm._e(), _vm.iframeLoaded && !_vm.isDesktop && _vm.isVerified === false ? _c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "verify-photo__notification"
  }, [_c('ion-icon', {
    attrs: {
      "name": "camera-outline"
    }
  }), _c('span', [_vm._v("Press on a button to finish your verification.")])], 1), _c('div', [_c('b-button', {
    staticClass: "mt-4 mb-4",
    attrs: {
      "variant": "primary",
      "href": _vm.iframeUrl,
      "target": "_blank"
    }
  }, [_vm._v(" Verify documents and photo ")])], 1)]) : _vm._e(), _c('b-container', [_c('b-row', {
    staticClass: "first-button mt-2"
  }, [_c('b-col', {
    attrs: {
      "cols": "10",
      "offset": "1"
    }
  }, [_c('notification'), _c('input', {
    staticClass: "btn btn-primary w-100",
    "class": _vm.buttonClass,
    attrs: {
      "disabled": _vm.finishedEnable === false,
      "type": "submit",
      "data-registration": "next"
    },
    domProps: {
      "value": _vm.buttonText
    },
    on: {
      "click": function click($event) {
        return _vm.finishedRoute();
      }
    }
  })], 1)], 1), _vm.allowSkip ? _c('b-row', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('b-col', {
    staticClass: "mt-3 align-middle",
    attrs: {
      "cols": "10",
      "offset": "1"
    }
  }, [_c('div', [_c('div', {
    staticClass: "mb-2"
  }, [_vm._v(" or ")]), _c('router-link', {
    staticClass: "framelink skiplink",
    attrs: {
      "to": {
        name: 'home'
      },
      "tag": "a",
      "exact": "",
      "data-registration": "skip"
    },
    on: {
      "click": function click($event) {
        return _vm.$forceUpdate();
      }
    }
  }, [_vm._v(" Skip verification ")])], 1)])], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }