<template>
	<div class="bulk-payments">
		<div v-if="step == 'transferSelection'">
			<div class="bulk-items mt-3">
				<b-row
					class="mb-2"
				>
					<b-col
						sm="12"
						md="12"
						lg="12"
						xl="12"
					>
						<div
							v-if="items.rows && items.rows.length > 0 && isWalletGranted('initiate_transaction', currentWallet.walletId)"
							class="text-center mt-3 mt-lg-0 mt-sm-0 mt-xl-0 text-sm-right text-lg-right text-xl-right"
						>
							<button
								class="btn btn-primary mb-3"
								@click.prevent="selectAll(true)"
							>
								Select all
							</button>
							<button
								class="btn btn-outline-primary mb-3 ml-3"
								@click.prevent="selectAll(false)"
							>
								Deselect all
							</button>
						</div>
					</b-col>
				</b-row>
				<b-card
					v-if="items.loading"
					class="bulk-item text-center"
				>
					<b-spinner
						label="Loading..."
					/>
				</b-card>
				<b-card
					v-for="item in items.rows"
					:key="item.id"
					class="mb-3 bulk-item"
					:class="`${isItemSelected(item) && isWalletGranted('initiate_transaction', currentWallet.walletId) ? 'bulk-item--selected' : ''} 'bulk-item--completed'`"
				>
					<b-row>
						<b-col
							md="1"
							cols="12"
							class="check"
						>
							<div
								v-if="isWalletGranted('initiate_transaction', currentWallet.walletId)"
								@click.prevent="selectItem(item)"
							>
								<i
									v-if="!isItemSelected(item)"
									class="far fa-square"
								/>
								<i
									v-else
									class="far fa-check-square"
								/>
							</div>
						</b-col>
						<b-col
							md="2"
							cols="12"
						>
							<small class="item-label">Name</small>
							<div>
								{{ item.name }}
							</div>
						</b-col>
						<b-col
							md="2"
							cols="12"
						>
							<small class="item-label">Beneficiary</small>
							<div v-if="item.transactionData.serviceId === 'cc_swift_sha' || item.transactionData.serviceId === 'cc_local'">
								<div v-if="item.transactionData.beneficiaryRequest.type === 'company'">
									<div>{{ item.transactionData.beneficiaryRequest.companyName }} (Multicurrency)</div>
								</div>
								<div v-else>
									{{ item.transactionData.beneficiaryRequest.name + ' ' + item.transactionData.beneficiaryRequest.surname }} (Multicurrency)
								</div>
							</div>
							<div v-else>
								{{ item.transactionData.beneficiaryRequest.name }}
							</div>
						</b-col>
						<b-col
							md="2"
							cols="12"
						>
							<small class="item-label">Account Number</small>
							<div>{{ item.transactionData.beneficiaryRequest.accountNumber }}</div>
						</b-col>
						<b-col
							md="2"
							cols="12"
						>
							<small class="item-label">Amount</small>
							<div class="amount-label">
								<b-badge
									class="amount-badge"
									variant="primary"
								>
									{{ item.transactionData.amount }}<small>{{ item.transactionData.currencyId }}</small>
								</b-badge>
							</div>
						</b-col>

						<b-col
							md="2"
							cols="12"
						>
							<small class="item-label">Details</small>
							<div>{{ item.transactionData.details }}</div>
						</b-col>
						<b-col
							md="1"
							cols="12"
							class="d-flex justify-content-center align-items-center"
							style="padding: 0 10px;"
						>
							<!-- <button
								class="btn btn-outline-primary"
								style="margin-top: 5px;"
								@click="copyTransfer(item)"
							>
								O
							</button> -->
							<ion-icon
								class="copy-transfer-icon"
								name="arrow-forward-circle-outline"
								style="color: var(--primary)"
								size="large"
								@click="copyTransfer(item)"
							/>
						</b-col>
					</b-row>
				</b-card>
				<div
					v-if="!items.loading && (!items.rows || items.rows.length < 1)"
					class="alert alert-info"
				>
					No templates
				</div>
				<div class="mb-3">
					<pagination
						v-if="items.pages > 1"
						:total="items.count"
						:per-page="perPage"
						:current-page="items.currentPage"
						@pagechanged="getTransferList"
					/>
				</div>
			</div>
			<div
				v-if="items.rows && items.rows.length > 0"
				class="text-center"
			>
				<button
					v-if="isGranted('front_transaction_template_delete')"
					class="btn btn-danger ml-3"
					:disabled="selectedItems.length == 0"
					@click.prevent="deleteSelected"
				>
					Remove
				</button>
			</div>
		</div>

		<div v-if="step == 'successDelete'">
			<b-card>
				Template deleted successfully
				<button
					class="btn btn-primary ml-3"
					@click.prevent="step = 'transferSelection'"
				>
					OK
				</button>
			</b-card>
		</div>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { endpoints } from '~/config';
import moment from 'moment';
import Pagination from '~/components/utils/Pagination';

export default {
	name: 'TransactionTemplateTable',
	components: {
		Pagination,
	},
	data() {
		return {
			moment,
			fileContent: null,
			items: {},
			files: {},
			fileId: null,
			step: 'transferSelection',
			successData: null,
			selectedItems: [],
			currentPage: 1,
			perPage: '10',
			services: {
				sepa: [
					'nano_transfer',
					'nano_inner_transfer',
					'nano_inside_transfer',
					'nano_swift_transfer',
				],
				swift: [
					'cc_swift_sha',
					'cc_local',
				]
			}
		};
	},
	computed: {
		...mapGetters('user', [
			'isGranted',
			'isWalletGranted',
		]),
		...mapGetters('wallet', [
			'currentWallet',
			'currentWalletAmount'
		]),
		selectedIds() {
			return this.selectedItems.map(i => i.id);
		},
		selectedTotal() {
			if (this.selectedItems.length > 0) {
				const currency = this.selectedItems[0].transactionData.currencyId;
				const sum = this.selectedItems.map(i => i.transactionData.amount).reduce((a, b) => parseFloat(a) + parseFloat(b), 0).toFixed(2);
				return { sum, currency };
			} else {
				return null;
			}
		}
	},
	watch: {
		currentWallet: function () {
			this.getTransferList();
		},
		step(step) {
			if (step === 'transferSelection') {
				this.getTransferList();
			}
		}
	},
	methods: {
		...mapActions('notifications', [
			'pushError'
		]),
		transferType(item) {
			return (this.services.sepa.find(s => item.serviceId === s) ? 'sepa' : null)
				|| (this.services.swift.find(s => item.serviceId === s) ? 'swift' : null);
		},
		async getTransferList(page = 1) {
			this.items = {
				currentPage: page,
				loading: true
			};
			if (this.currentWallet === null) {
				this.items = [];
				this.total = 0;
				this.loading = true;
				return;
			}
			const options = {
				method: 'GET',
				url: endpoints.transactions.templateList({ walletId: this.currentWallet.walletId }),
				params: {
					page: page,
					limit: this.perPage,
					orderBy: 'timestampCreated',
					orderDirection: 'desc',
				}
			};
			try {
				const response = await axios(options);
				this.items = {
					...this.items,
					...response.data,
					loading: false
				};
				return this.items;
			} catch (err) {
				this.items.loading = false;
				this.pushError(err.message);
			}
		},
		filterPages(event) {
			this.perPage = event.toString();
			this.limit = event;
			this.getTransferList(1);
		},
		selectItem(item) {
			const selectedItem = this.selectedItems.find(i => i.id == item.id);
			if (selectedItem) {
				this.selectedItems = this.selectedItems.filter(i => i.id !== item.id);
			} else {
				this.selectedItems.push(item);
			}

		},
		isItemSelected(item) {
			const id = item.id;
			return !!this.selectedItems.find(i => i.id == id);
		},
		selectAll(select) {
			const items = this.items.rows;
			if (select) {
				items.forEach(i => {
					if (!this.selectedItems.find(si => si.id == i.id)) {
						this.selectedItems.push(i);
					}
				});
			} else {
				this.selectedItems = this.selectedItems.filter(si => {
					const found = items.find(i => si.id == i.id);
					return !found;
				});
			}
		},
		async deleteSelected() {
			this.successData = null;
			const options = {
				method: 'DELETE',
				url: endpoints.transfer.templateDelete( { walletId: this.currentWallet.walletId } ),
				data: {
					ids: this.selectedIds
				}
			};
			try {
				const response = await axios(options);
				const { data } = response;
				this.successData = data;
				this.step = 'successDelete';
				this.selectedItems = [];
				this.getTransferList();
			} catch (err) {
				this.$refs.authenticator.reset();
				this.pushError(err.message);
			}
		},
		copyTransfer(item) {
			let transferType = this.transferType(item);
			this.$router.push({
				name: 'transfer',
				params: {
					transferData: { ...item.transactionData, transferType: transferType },
					transferType: transferType,
					transactionTemplate: item
				}
			});

			this.$emit('update-template', item);
		},
	},
	mounted() {
		this.getTransferList();
	},

};
</script>
<style scoped lang="scss" src="./InitiateTransactions.scss">
</style>
