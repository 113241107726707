import { endpoints } from '~/config';
import { CLEAR_WALLET_LIST, SET_CURRENT_WALLET, SET_WALLET_LIST_LOADING, SET_WALLET_NEXT_ID, UPDATE_CREATE_WALLET_ID, UPDATE_CREATE_WALLET_SHOW_FORM, UPDATE_CREATE_WALLET_SHOW_SUCCESS, UPDATE_TABLE, UPDATE_WALLET_LIST } from '~/store/mutation-types';

export default {
	getTotalBalance({ commit, state }) {
		return new Promise((resolve, reject) => {
			
			let url = endpoints.wallet.balance({ walletId: state.current.walletId });
			axios.get(url)
				.then(response => {
					commit(SET_CURRENT_WALLET, response.data.walletId);
					resolve();
				})
				.catch(error => {
					reject(error);
				})
			;
		});
	},
	changeCurrentWallet({ dispatch, commit, state }, wallet_id) {
		let newWallet = state.list.find(function (wallet) {
			return wallet.walletId === wallet_id;
		});
		if (!newWallet) {
			return;
		}

		localStorage.setItem('selectedWalletId', newWallet.walletId);

		commit(SET_CURRENT_WALLET, newWallet.walletId);
		dispatch('getTotalBalance')
			.catch(() => {});
	},
	loadWallets({ dispatch, commit, state, getters }, data) {
		if (data) {
			commit(UPDATE_WALLET_LIST, data);

			let selectedValetId = localStorage.getItem('selectedWalletId');
			let selectedWallet = data.find(function (wallet) {
				return wallet.walletId === selectedValetId;
			});

			if (selectedValetId && selectedWallet) {
				dispatch('changeCurrentWallet', selectedValetId);
			} else if (getters.primaryWallet) {
				dispatch('changeCurrentWallet', getters.primaryWallet.walletId);
			}

			commit(UPDATE_TABLE, { table: 'walletListTable', data: state.list }, { root: true });
		}
	},
	updateWalletList({ dispatch }) {
		return new Promise((resolve, reject) => {
			axios.get(endpoints.wallet.list)
				.then(response => {
					if (response.data) {
						dispatch('loadWallets', response.data);
						resolve(response.data);
						return;
					}
					reject('No response when getting wallet list');
					throw new Error('No response when getting wallet list');
				})
				.catch(error => {
					dispatch('clearWallets');
					global.$router.replace({ name: '500' });
					reject(error);
					throw new Error('unexpected getWalletList error ', error);
				});
		});
	},
	getWalletList({ state, commit, dispatch }) {
		if (state.listLoading) {
			return;
		}

		commit(SET_WALLET_LIST_LOADING);

		if (state.listLoaded) {
			commit(CLEAR_WALLET_LIST);
		}

		dispatch('updateWalletList');
	},
	clearWallets({ commit }) {
		commit(CLEAR_WALLET_LIST);
	},
	resetWalletCreate({ commit }) {
		commit(UPDATE_CREATE_WALLET_SHOW_FORM, true);
		commit(UPDATE_CREATE_WALLET_SHOW_SUCCESS, false);
	},
	createWalletSuccess: ({ commit, dispatch }, data) => {
		commit(UPDATE_WALLET_LIST, data.items);
		commit(SET_WALLET_NEXT_ID, null);
		commit(UPDATE_CREATE_WALLET_ID, data.name);
		commit(UPDATE_CREATE_WALLET_SHOW_FORM, false);
		commit(UPDATE_CREATE_WALLET_SHOW_SUCCESS, true);
		dispatch('getNextWalletId');
	},
	removeWalletFromList: ({ state, commit }, id) => {
		let list = state.list.filter(wallet => wallet.walletId !== id);

		commit(UPDATE_WALLET_LIST, list);
		commit(UPDATE_TABLE, { table: 'walletListTable', data: list }, { root: true });
	},
	updateWalletName: ({ state, commit }, data) => {
		state.list.forEach((elem, idx, list) => {
			if (elem.walletId === data.id) {
				list[idx].name = data.name;
			}
		});

		commit(UPDATE_WALLET_LIST, state.list);
		commit(UPDATE_TABLE, { table: 'walletListTable', data: state.list }, { root: true });
	}
};
