<template>
	<div>
		<div class="page-title-container">
			<h2>Transactions History</h2>
		</div>
		<div class="transactions-full">
			<transactions />
		</div>
	</div>
</template>

<script>
import Transactions from './Transactions.vue';
export default {
	name: 'TransactionTableLayout',
	components: {
		Transactions
	}
};
</script>
