var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "footer-copyright mt-auto"
  }, [_c('span', [_vm._v("Copyright © " + _vm._s(_vm.currentYear) + " " + _vm._s(_vm.siteName) + ". All rights reserved.")]), _c('br'), _vm.WHITELABEL == 'verifo' ? _c('span', [_vm._v(_vm._s(_vm.siteName) + " is powered by "), _c('a', {
    attrs: {
      "href": "https://verifiedpayments.com",
      "target": "_blank"
    }
  }, [_vm._v("Verified Payments")]), _vm._v(" white-label banking solution.")]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }