<template>
	<div>
		<h2 class="page-title">
			Documents
		</h2>
		<div class="registration-content">
			<form
				class="form-body"
				@submit.prevent="onsubmit"
			>
				<div
					class="justify-content-md-center"
				>
					<p>
						<b>To ensure a quick onboarding process, please upload the majority of legalized or notarized
							documents in English below. You may also submit additional documents via email to
							{{ email }} later.</b>
						<br />
						<br />
						If the company is incorporated in a country with public registry, we can purchase the documents from
						official source at your expense. Please indicate if you wish to do so to {{ email }}.
						<br />
						<br />
						Allowed file types: <b>PDF, PNG, JPEG, GIF</b>
						<!--                    <br/>-->
						<!--                    <br/>-->
						<!--                    If documents are already supplied,&nbsp;-->
						<!--                    <router-link :to="{name: 'verify-photo'}" tag="a" class="framelink skiplink" exact>-->
						<!--                        you can skip this step-->
						<!--                    </router-link>-->
					</p>
					<br />
					<br />
				</div>
				<fieldset :disabled="details.verification == 'pending'">
					<b-row
						class="justify-content-md-center"
						style="text-align: center;"
					>
						<b-col>
							<table>
								<tr>
									<th class="first">
										File
									</th>
									<th class="second">
										Document type
									</th>
								</tr>
								<tr
									v-for="(item, index) in documents"
									v-show="item.description"
									:key="item.description"
									class="content-item"
								>
									<td class="first">
										<div v-if="item.name !== ''">
											{{ item.name }}

											<button
												class="btn btn-danger btn-sm"
												:data-registration="`remove-document-${index}`"
												@click.prevent="removeItem(item)"
											>
												x
											</button>
										</div>
										<div v-else-if="company[item.suppliedId]">
											Supplied
										</div>
										<div v-else-if="details.verification == 'pending'">
											Pending
										</div>
										<div v-else>
											<b-form-group :class="{'input-invalid' : errors.has(item.id)}">
												<input
													:id="item.id"
													:name="item.id"
													class="hidden"
													accept=".jpg, .jpeg, .gif, .png, .pdf, .eml"
													type="file"
													:data-registration="`document-${index}`"
													@change="onFileChange"
												/>
												<label
													:for="item.id"
													class="btn btn-primary btn-rounded btn-sm btn-documents"
													:data-registration="`browse-document-${index}`"
												><span>Browse</span></label>
											</b-form-group>
										</div>
									</td>
									<td class="second">
										{{ item.description }}
										<div
											v-show="data[item.id + '_id']"
											class="success_mesagge"
										>
											<br />
											<span>Supplied</span>
										</div>
										<div
											v-show="errors.has(item.id)"
											class="error_mesagge"
										>
											<br />
											<span>{{ errors.first(item.id) }}</span>
										</div>
									</td>
								</tr>
							</table>
						</b-col>
					</b-row>
				</fieldset>
				<br />
				<br />
				<br />
				<notification />
				<b-row class="mb-3">
					<b-col class="text-center">
						<input
							:class="buttonClass"
							:value="buttonText"
							:disabled="nextEnabled === false"
							type="submit"
							class="btn btn-primary w-100 btn-rounded btn-upper"
							data-registration="next"
						/>
					</b-col>
				</b-row>
				<b-row v-if="allowSkip">
					<b-col class="text-center">
						<router-link
							:to="{ name: 'home' }"
							tag="a"
							class="framelink skiplink"
							exact
							data-registration="skip"
							@click="$forceUpdate()"
						>
							<span v-if="details.verification !== 'pending'">Skip verification</span>
							<span v-if="details.verification == 'pending'">Back to main page</span>
						</router-link>
					</b-col>
				</b-row>
			</form>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import config, { fileUploadSizeLimit } from '~/config';
export default {
	name: 'BusinessDocumentsForm',
	props: {
		buttonClass: {
			type: String,
			default: ''
		},
		buttonText: {
			type: String,
			default: 'Next'
		},
		allowSkip: {
			type: Boolean,
			default: true
		},
		onSuccess: {
			type: Function,
			default: () => {
				return false;
			}
		}
	},
	data: function () {
		return {
			email: config.email,
			nextEnabled: true,
			data: {}, // set from $store on mounted() event
			documents: [
				{
					img: '',
					name: '',
					description: 'Registration document',
					id: 'registration_document',
					suppliedId: 'registrationDocumentId'
				},
				{
					img: '',
					name: '',
					description: 'Incorporation document',
					id: 'incorporation_document',
					suppliedId: 'incorporationDocumentId'
				},
				{
					img: '',
					name: '',
					description: 'Articles of association / Memorandum of association / Company bylaws',
					id: 'articles_of_association_document',
					suppliedId: 'articlesOfAssociationDocumentId'
				},
				{
					img: '',
					name: '',
					description: 'Proof of address (if not already mentioned in above documents)',
					id: 'proof_of_address_document',
					suppliedId: 'proofOfAddressDocumentId'
				},
				{
					img: '',
					name: '',
					description: 'Dated company register extract issued within the last 3 months',
					id: 'company_register_extract_document',
					suppliedId: 'companyRegisterExtractDocumentId'
				},
				{
					img: '',
					name: '',
					description: 'Register of members/shareholders or other documents showing the ownership structure of the company',
					id: 'company_structure_document',
					suppliedId: 'companyStructureDocumentId'
				},
				{
					img: '',
					name: '',
					description: 'Power of attorney for the user if not an authorized director',
					id: 'power_of_attorney_document',
					required: false,
					suppliedId: 'powerOfAttorneyDocumentId'
				}
			],
		};
	},
	computed: {
		...mapState('user', {
			userId: state => state.selected.userId,
			company: state => state.selected.companyProfile,
			details: state => state.selected
		}),
		...mapGetters('user', ['isPersonal', 'isBusiness', 'owner']),
	},
	mounted() {
		this.$gtag.event(`register_verification_documents_${this.isBusiness ? 'business' : 'personal'}`);
		let state = this.$store.state;
		this.data = Object.assign({}, state.businessInfo);
	},
	beforeDestroy() {
		for (let i = 0; i < this.documents.length; i++) {
			this.$store.state.businessInfo[this.documents[i].id] = null;
		}
	},
	methods: {
		...mapActions('notifications', ['pushError']),
		...mapActions('businessInfo', ['submitBusinessInformation']),
		onsubmit() {
			if (this.details.verification == 'pending') {
				this.$router.push({ name: 'home' });
				return;
			}

			this.$validator.validateAll().then(async (result) => {
				if (result) {
					const missingRequired = this.documents.find(d => d.required && !d.img && !this.company[d.suppliedId]);
					if (missingRequired) return this.pushError(`Missing file for ${missingRequired.description}`);
					
					const submitData = this.documents
						.filter(d => d.img)
						.map(d => ({
							type: d.id,
							fileName: d.name,
							file: d.img
						}));

					if (submitData.length == 0) return this.$router.push({ name: 'verify-photo' });

					this.nextEnabled = false;
					try {
						await this.submitBusinessInformation({ 'files': submitData });
						this.nextEnabled = true;

						this.$router.push({ name: 'verify-photo' });
					} catch (err) {
						this.nextEnabled = true;
						this.pushError(err);
					}
					setTimeout(() => {
						this.nextEnabled = true;
					}, 5000);
					return;
				}
				this.pushError('Please check the red fields');
			});
		},
		handleError(error) {
			if (error && error.error) {
				this.pushError(error.error);
			} else {
				this.pushError('Unknown error occurred. It might be that files are too large. Please try uploading files one by one, or contact support');
			}
		},
		onFileChange(e) {
			let files = e.target.files || e.dataTransfer.files,
				name = e.target.name;
			if (!files.length)
				return;
			if(files[0].size > fileUploadSizeLimit) {
				this.handleError({ 'error' : 'File size is too big. Try to upload a smaller one.' });
			}
			this.$set(this.data, name, files[0]);
			this.createFile(files[0], name);

		},
		createFile(file, name) {
			let reader = new FileReader();

			reader.onload = (e) => {
				for (let i = 0; i < this.documents.length; i++) {
					if (this.documents[i].id === name) {
						this.$set(this.documents, i, {
							img: e.target.result,
							name: file.name,
							description: this.documents[i].description,
							id: this.documents[i].id,
							required: this.documents[i].required,
						});
						break;
					}
				}
			};
			reader.readAsDataURL(file);
		},
		removeItem(item) {
			for (let i = 0; i < this.documents.length; i++) {
				if (this.documents[i].name === item.name && this.documents[i].id === item.id) {
					this.$set(this.documents, i, {
						img: '',
						name: '',
						description: this.documents[i].description,
						id: this.documents[i].id,
						required: this.documents[i].required,
					});
					this.data[item.id] = null;
					break;
				}
			}
		}
	},
};
</script>

<style lang="scss" scoped>
    table {
        width: 100%;
    }

    th {
        height: 50px;
    }

    td,
    th {
        padding: 10px;
    }

    .first {
        width: 40%;
    }

    .second {
        width: 60%;
    }

    .error_mesagge {
        color: #da0d0d;
    }

    .success_mesagge {
        color: #28a745;
    }

    tr {
        border-bottom: 1px solid #dcdcdc;
    }
	.btn-documents {
		color: #fff;
	}
</style>
