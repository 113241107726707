var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": _vm.id,
      "size": "sm",
      "centered": "",
      "hide-header": "",
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.resetActions
    }
  }, [_c('div', {
    staticClass: "text-center card-prompt-modal card-modal"
  }, [_vm.params.title ? _c('h2', [_vm._v(" " + _vm._s(_vm.params.title) + " ")]) : _vm._e(), _c('div', [_vm._v(" " + _vm._s(_vm.params.text) + " ")]), _vm.params.warningText ? _c('div', {
    staticClass: "card-prompt-modal__warning"
  }, [_c('small', [_vm._v(_vm._s(_vm.params.warningText))])]) : _vm._e(), _c('div', {
    staticClass: "mt-4"
  }, [_c('b-button', {
    staticClass: "mr-4",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.confirm
    }
  }, [_vm._v(" Yes ")]), _c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.decline
    }
  }, [_vm._v(" No ")])], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }