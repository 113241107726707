var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "auth-card"
  }, [_c('h1', {
    staticClass: "auth-title"
  }, [_vm._v(" Select customer ")]), _c('b-list-group', {
    staticClass: "mb-0"
  }, _vm._l(_vm.list, function (user) {
    return _c('b-list-group-item', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: user.profileId,
        expression: "user.profileId"
      }],
      key: user.profileId,
      staticClass: "content-item",
      attrs: {
        "href": "#"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.select(user);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.setUsername(user)) + " ")]);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }