import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth';
import businessInfo from './modules/businessInfo';
import cards from './modules/cards';
import countries from './modules/countries';
import identity from './modules/identity';
import log from './modules/log';
import register from './modules/register';
import transfer from './modules/transfer';
import user from './modules/user';
import routes from './modules/routes';
import wallet from './modules/wallet';
import notifications from './modules/notifications';
import passwordRecovery from './modules/passwordRecovery';

import notification from './modules/notification';
import profile from './modules/profile';

import currencies from './modules/currencies';
import { UPDATE_TABLE } from './mutation-types';

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		auth,
		businessInfo,
		cards,
		countries,
		identity,
		transfer,
		user,
		routes,
		wallet,
		notifications,
		passwordRecovery,

		notification,
		register,
		profile,
		log,
		currencies,
	},
	mutations: {
		[UPDATE_TABLE] (state, data) {
			if (typeof state[data.table] !== 'undefined') {
				state[data.table].data = data.data;
			}
		},
	},
	strict: false
});
