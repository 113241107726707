<template>
	<b-modal
		v-cloak
		:id="id"
		size="sm"
		centered
		hide-header
		hide-footer
		no-close-on-backdrop
		@shown="$refs.codeInput.focus()"
	>
		<div class="card-modal text-center">
			<span
				class="card-settings__modal-close"
				@click="closeModal"
			></span>
			<h2>{{ title }}</h2>
			Enter code from {{ authType[authenticator || userAuthenticator] }}
			<code-input
				ref="codeInput"
				v-model="code"
				:disabled="loading"
			/>
			<div v-if="refreshFn">
				Code did not work? <a
					href=""
					@click.prevent="refreshCode"
				>Refresh</a>
			</div>
		</div>
	</b-modal>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import CodeInput from '~/components/shared/CodeInput';
export default {
	name: 'CardAuthModal',
	components: {
		CodeInput
	},
	props: {
		id: {
			type: String,
			default: 'card-auth-modal'
		},
		authenticator: {
			validator: function(value) {
				return ['sms', 'google'].includes(value);
			}
		},
		refreshFn: {
			type: Function,
		},
		title: {
			type: String,
			default: 'Authenticate to continue'
		},
		onSubmit: {
			type: Function,
			required: true
		}
	},
	data() {
		return {
			code: '',
			loading: false,
			authType: {
				sms: 'SMS',
				google: 'Google Authenticator'
			}
		};
	},
	watch: {
		code(v) {
			if (v) this.submitCode(v);
		}
	},
	computed: {
		...mapState('user', {
			userAuthenticator: state => state.authenticator
		})
	},
	methods: {
		...mapActions('notifications', [
			'pushError'
		]),
		closeModal() {
			this.$bvModal.hide(this.id);
		},
		async refreshCode() {
			if (!this.refreshFn) return;
			this.loading = true;
			await this.refreshFn();
			this.loading = false;
		},
		async submitCode(code) {
			this.loading = true;
			const response = await this.onSubmit(code);
			if (response) {
				this.closeModal();
			} else {
				if (this.$refs.codeInput) {
					this.$refs.codeInput.reset();
					this.pushError('Wrong code. Try again.');
				}
			}
			this.code = '';
			this.loading = false;
		}
	}
};
</script>
<style lang="scss" scoped>
	.card-auth-modal {
		&__title {
			font-size: 22px;
		}
	}
</style>