<template>
	<div>
		<b-container>
			<b-row>
				<b-col>
					<div class="page-title-container">
						<h2>Referral Program</h2>
						<p>Join our referral program. It's easy. It's free. And there is no limit to what you can earn.</p>
					</div>				
				</b-col>
			</b-row>
			<b-card>
				<b-row>
					<b-col>
						<label class="w-100">
							<h3 class="mb-2 mb-lg-3 font-weight-bold"> Referral link </h3>
							<div class="input-group mb-0">
								<input
									ref="linkInput"
									readonly
									type="text"
									class="form-control"
									:value="link"
									@click="() => $refs.linkInput.select()"
								>
								<div class="input-group-append">
									<button
										class="btn btn-light px-3 rounded-right border-top border-right border-bottom"
										type="button"
										@click="copy(link)"
									>
										<ion-icon
											data-v-107c216b=""
											name="copy-outline"
											class="copy-icon md hydrated d-block"
											role="img"
											aria-label="copy outline"
										/>
									</button>
								</div>
							</div>
						</label>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<button
							class="btn btn-light mt-3 mr-2"
							:class="{'btn-outline-primary': activeTab == 0}"
							@click="() => activeTab = 0"
						>
							Referral Program
						</button>
						<button
							class="btn btn-light mt-3"
							:class="{'btn-outline-primary': activeTab == 1}"
							@click="() => activeTab = 1"
						>
							Referral Dashboard
						</button>
					</b-col>
				</b-row>
			</b-card>
			<div class="mt-4">
				<div v-if="activeTab == 0">
					<referral-programme />
				</div>
				<div v-if="activeTab == 1">
					<referral-dashboard />
				</div>
			</div>
		</b-container>
	</div>
</template>

<script>
import ReferralProgramme from '@/LayoutDashboard/Referrals/ReferralProgramme';
import ReferralDashboard from '@/LayoutDashboard/Referrals/ReferralDashboard';
import copyToClipboard from 'copy-to-clipboard';
import { mapActions, mapState } from 'vuex';

export default {
	components: {
		ReferralProgramme,
		ReferralDashboard
	},
	data() {
		return {
			activeTab: 0,
			link: ''
		};
	},
	computed: {
		...mapState('user', ['selected'])
	},
	methods: {
		...mapActions('notifications', ['pushSuccess']),
		copy(text) {
			copyToClipboard(text);
			this.pushSuccess('Link copied');
		}
	},
	mounted() {
		const profileId = this.selected.profileId;
		const origin = window.location.origin;
		this.link = `${origin}/register/#ref=${profileId}`;
	}
};
</script>
<style lang="scss" scoped>
	input[readonly] {
		background-color: #fff !important;
	}
</style>