export default {
	registration_country_id: null,
	registration_state: null,
	registration_city: null,
	registration_zip_code: null,
	registration_address: null,
	registration_street_no: null,
	registration_street: null,
	registration_flat_no: null,

	correspondence_country_id: null,
	correspondence_state: null,
	correspondence_city: null,
	correspondence_zip_code: null,
	correspondence_address: null,
	correspondence_street_no: null,
	correspondence_street: null,
	correspondence_flat_no: null,

	same_as_director_address: 0,
	same_as_registration_address: 0,

	name: null,
	website: null,
	number_of_employees: null,
	vat_number: null,
	currency_id: 'eur',
	code: null,
	country_of_incorporation: null,
	date_of_incorporation: null,

	legal_form: null,

	// documents
	registration_document: null,
	incorporation_document: null,
	articles_of_association_document: null,
	memorandum_of_association_document: null,
	proof_of_address_document: null,
	company_by_laws_document: null,
	company_register_extract_document: null,
	company_structure_document: null,
	power_of_attorney_document: null,
	// Zero step registration
	account_info_use: [],
	account_info_scope: [],
};
