var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "auth-card"
  }, [_c('h1', {
    staticClass: "auth-title"
  }, [_vm._v(" Checking registration link... ")]), _c('p', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.registerId,
      expression: "!registerId"
    }]
  }, [_c('b-spinner', {
    attrs: {
      "small": "",
      "variant": "dark",
      "type": "grow",
      "label": "Spinning"
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }