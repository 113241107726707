var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card-order"
  }, [_c('b-container', [_c('b-row', [_c('b-col', [_c('div', {
    staticClass: "page-title-container"
  }, [_c('h2', [_vm._v("Card details")])])])], 1), _c('b-row', [_c('b-col', [_c('card-image', {
    attrs: {
      "virtual": _vm.isVirtual
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "card-order__page-subtitle"
  }, [!_vm.isVirtual ? _c('span', [_vm._v("New Visa Debit card")]) : _vm._e(), _vm.isVirtual ? _c('span', [_vm._v("New Visa Virtual Debit card")]) : _vm._e()])], 1), _c('b-row', [_c('b-col', [_vm.ordersCount > 0 ? _c('div', {
    staticClass: "card-order__notification"
  }, [_vm.ordersCount == 1 ? _c('span', [_vm._v("There is "), _c('strong', [_vm._v("1")]), _vm._v(" " + _vm._s(_vm.isVirtual ? 'virtual' : 'plastic') + " card order in progress.")]) : _vm._e(), _vm.ordersCount > 1 ? _c('span', [_vm._v("There are "), _c('strong', [_vm._v(_vm._s(_vm.ordersCount))]), _vm._v(" " + _vm._s(_vm.isVirtual ? 'virtual' : 'plastic') + " card orders in progress.")]) : _vm._e()]) : _vm._e()])], 1), _c('card-account-details'), _c('card-personal-details'), _c('card-address-details'), !_vm.isVirtual ? _c('div', [_c('b-row', {
    staticClass: "mb-4"
  }, [_c('b-col', [_c('b-card', {
    staticClass: "card-order__address-switch",
    on: {
      "click": function click() {
        return _vm.params.useProfileAddress = !_vm.params.useProfileAddress;
      }
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "d-flex justify-content-center align-items-center",
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "card-order__checkbox",
    "class": _vm.params.useProfileAddress ? 'card-order__checkbox--checked' : ''
  })]), _c('b-col', [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('strong', [_vm._v("Use the address above for delivery")]), _c('small', [_vm._v("The card will always be registered to the verified address of your account.")])])])], 1)], 1)], 1)], 1), !_vm.params.useProfileAddress ? _c('div', {
    staticClass: "mb-4"
  }, [_c('address-input', {
    model: {
      value: _vm.params.deliveryAddress,
      callback: function callback($$v) {
        _vm.$set(_vm.params, "deliveryAddress", $$v);
      },
      expression: "params.deliveryAddress"
    }
  })], 1) : _vm._e(), !_vm.countryAllowed ? _c('div', {
    staticClass: "card-order__warning mb-4"
  }, [_c('small', [_vm._v("You can't order a card to this country.")])]) : _vm._e()], 1) : _vm._e(), _c('b-row', [_c('b-col', [_c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "primary",
      "disabled": !_vm.countryAllowed
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v(" Order Card ")]), _c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v(" Cancel ")])], 1)], 1)], 1), _c('card-auth-modal', {
    attrs: {
      "id": _vm.confirmModalId,
      "refresh-fn": _vm.prepare,
      "on-submit": _vm.confirm
    }
  }), _c('card-content-modal', {
    attrs: {
      "id": _vm.successModalId,
      "on-close": function onClose() {
        return _vm.$router.push({
          name: 'cards'
        });
      }
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('img', {
    staticClass: "mb-4",
    attrs: {
      "src": "/images/icons/card-success.png",
      "width": "78"
    }
  }), _c('h2', [_c('strong', [_vm._v("Congratulations")])]), !_vm.isVirtual ? _c('span', [_vm._v("Your physical card has been ordered and will be shipped as soon as you set it up.")]) : _vm._e(), _vm.isVirtual ? _c('span', [_vm._v("Your virtual card has been ordered.")]) : _vm._e()])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }