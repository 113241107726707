var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h2', {
    staticClass: "page-title"
  }, [_vm._v(" Documents ")]), _c('div', {
    staticClass: "registration-content"
  }, [_c('form', {
    staticClass: "form-body",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onsubmit.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "justify-content-md-center"
  }, [_c('p', [_c('b', [_vm._v("To ensure a quick onboarding process, please upload the majority of legalized or notarized documents in English below. You may also submit additional documents via email to " + _vm._s(_vm.email) + " later.")]), _c('br'), _c('br'), _vm._v(" If the company is incorporated in a country with public registry, we can purchase the documents from official source at your expense. Please indicate if you wish to do so to " + _vm._s(_vm.email) + ". "), _c('br'), _c('br'), _vm._v(" Allowed file types: "), _c('b', [_vm._v("PDF, PNG, JPEG, GIF")])]), _c('br'), _c('br')]), _c('fieldset', {
    attrs: {
      "disabled": _vm.details.verification == 'pending'
    }
  }, [_c('b-row', {
    staticClass: "justify-content-md-center",
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('b-col', [_c('table', [_c('tr', [_c('th', {
    staticClass: "first"
  }, [_vm._v(" File ")]), _c('th', {
    staticClass: "second"
  }, [_vm._v(" Document type ")])]), _vm._l(_vm.documents, function (item, index) {
    return _c('tr', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: item.description,
        expression: "item.description"
      }],
      key: item.description,
      staticClass: "content-item"
    }, [_c('td', {
      staticClass: "first"
    }, [item.name !== '' ? _c('div', [_vm._v(" " + _vm._s(item.name) + " "), _c('button', {
      staticClass: "btn btn-danger btn-sm",
      attrs: {
        "data-registration": "remove-document-".concat(index)
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.removeItem(item);
        }
      }
    }, [_vm._v(" x ")])]) : _vm.company[item.suppliedId] ? _c('div', [_vm._v(" Supplied ")]) : _vm.details.verification == 'pending' ? _c('div', [_vm._v(" Pending ")]) : _c('div', [_c('b-form-group', {
      "class": {
        'input-invalid': _vm.errors.has(item.id)
      }
    }, [_c('input', {
      staticClass: "hidden",
      attrs: {
        "id": item.id,
        "name": item.id,
        "accept": ".jpg, .jpeg, .gif, .png, .pdf, .eml",
        "type": "file",
        "data-registration": "document-".concat(index)
      },
      on: {
        "change": _vm.onFileChange
      }
    }), _c('label', {
      staticClass: "btn btn-primary btn-rounded btn-sm btn-documents",
      attrs: {
        "for": item.id,
        "data-registration": "browse-document-".concat(index)
      }
    }, [_c('span', [_vm._v("Browse")])])])], 1)]), _c('td', {
      staticClass: "second"
    }, [_vm._v(" " + _vm._s(item.description) + " "), _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.data[item.id + '_id'],
        expression: "data[item.id + '_id']"
      }],
      staticClass: "success_mesagge"
    }, [_c('br'), _c('span', [_vm._v("Supplied")])]), _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.errors.has(item.id),
        expression: "errors.has(item.id)"
      }],
      staticClass: "error_mesagge"
    }, [_c('br'), _c('span', [_vm._v(_vm._s(_vm.errors.first(item.id)))])])])]);
  })], 2)])], 1)], 1), _c('br'), _c('br'), _c('br'), _c('notification'), _c('b-row', {
    staticClass: "mb-3"
  }, [_c('b-col', {
    staticClass: "text-center"
  }, [_c('input', {
    staticClass: "btn btn-primary w-100 btn-rounded btn-upper",
    "class": _vm.buttonClass,
    attrs: {
      "disabled": _vm.nextEnabled === false,
      "type": "submit",
      "data-registration": "next"
    },
    domProps: {
      "value": _vm.buttonText
    }
  })])], 1), _vm.allowSkip ? _c('b-row', [_c('b-col', {
    staticClass: "text-center"
  }, [_c('router-link', {
    staticClass: "framelink skiplink",
    attrs: {
      "to": {
        name: 'home'
      },
      "tag": "a",
      "exact": "",
      "data-registration": "skip"
    },
    on: {
      "click": function click($event) {
        return _vm.$forceUpdate();
      }
    }
  }, [_vm.details.verification !== 'pending' ? _c('span', [_vm._v("Skip verification")]) : _vm._e(), _vm.details.verification == 'pending' ? _c('span', [_vm._v("Back to main page")]) : _vm._e()])], 1)], 1) : _vm._e()], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }