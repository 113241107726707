<template>
	<div>
		<div class="auth-card">
			<h1 class="auth-title">
				Login information
			</h1>
			<div 
				v-show="showForm" 
				class="form-body"
			>
				<b-row class="justify-content-md-center">
					<b-col 
						md="12" 
						class="mb-4"
					>
						<div class="textblock lightext">
							<p>
								Please create your password
							</p>
						</div>
					</b-col>
				</b-row>


				<login-info-form 
					@success="onSuccess()" 
					@failure="onFailure()"
				/>
			</div>
		</div>
	</div>
</template>
<script>
import LoginInfoForm from './LoginInfoForm.vue';
export default {
	components: {
		LoginInfoForm
	},
	data() {
		return {
			showForm: true
		};
	},
};
</script>
