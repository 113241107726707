<template>
	<div>
		<b-container>
			<b-row>
				<b-col>
					<div class="page-title-container">
						<h2>Two-factor Authentication</h2>
					</div>				
				</b-col>
			</b-row>
			<b-row v-if="step === 0">
				<b-col cols="12">
					<b-card class="mb-3">
						<b-row>
							<b-col>
								<p>Two-factor authentication is an extra layer of security. It requires two separate, distinct forms of identification to access {{ siteName }} internet banking. Current method of two-factor authentication for your account is <strong>{{ currentTitle }}</strong>.</p>
								<p>Both SMS and Google authentication methods provide a high level of protection for your account. Although, we recommend using Google Authenticator. It is fast and does not require internet connection.</p>
							</b-col>
							<b-col>
								<div class="image-wrapper">
									<div class="image-wrapper__layer image-wrapper__layer--1st">
										<span class="image-password">Your password</span>
										<div class="image-wrapper__layer image-wrapper__layer--2nd">
											<strong class="image-2step">2-Step verification</strong>
											<div class="image-wrapper__layer image-wrapper__layer--3rd">
												<span class="image-data">Your account data</span>
											</div>
										</div>
									</div>
								</div>
							</b-col>
						</b-row>
					</b-card>
					<b-card>
						<b-row>
							<b-col>
								<h3 class="authenticator-subtitle">
									How does it work?
								</h3>
							</b-col>
						</b-row>
						<b-row
							v-for="(option, i) in shownOptions"
							:key="i"
							class="my-3"
						>
							<b-col>
								<strong>{{ option.title }}</strong>
								<b-row>
									<b-col>
										<p v-html="option.description"></p>
									</b-col>
									<b-col
										v-if="option.value === 'google'"
										cols="auto"
									>
										<div class="authenticator-appstores mb-3">
											<a
												href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"
												target="_blank"
											><img
												alt="Get it on Google Play"
												src="/images/google-play-store.svg"
											/></a>
											<a
												href="https://apps.apple.com/us/app/google-authenticator/id388497605"
												target="_blank"
											><img
												src="/images/apple-store.svg"
												alt="apple store"
											></a>
										</div>
									</b-col>
								</b-row>
								<b-button
									variant="primary"
									class="mt-1"
									:disabled="currentValue == option.value"
									@click="select(option.value)"
								>
									<span v-if="option.iconClass">
										<i
											:class="option.iconClass"
											class="mr-3"
										></i>
									</span>
									<span v-new-badge="{expire: '2021-06-28', hidden: option.value !== 'google', id: 'gauth'}">{{ option.title }}</span>
								</b-button>
							</b-col>
						</b-row>
					</b-card>
				</b-col>
				<b-col
					v-if="currentValue == 'google'"
					cols="12"
					class="mt-3 text-center"
				>
					<b-card class="text-center">
						<b-button
							variant="primary"
							@click="regenerateGoogle()"
						>
							Regenerate google recovery codes
						</b-button>
						<div
							v-if="google.recoveryCodes && google.recoveryCodes.length > 0"
							class="mt-3"
						>
							<div class="mb-3">
								Copy or download recovery codes. You won't be able to see it after leaving this page.
							</div>
							<google-recovery-codes
								:codes="google.recoveryCodes"
								:loading="loading"
							/>
						</div>
					</b-card>
				</b-col>
			</b-row>
			<b-row
				v-if="step === 1"
				class="mb-3 text-center"
			>
				<b-col>
					<h3>{{ currentSelectionTitle }}</h3>
				</b-col>
			</b-row>
			<b-row
				v-if="step === 1"
				align-v="stretch"
			>
				<b-col
					v-if="selection == 'google'"
					cols="12"
					class="mb-3"
					:md="!google.manual ? 'auto' : '12'"
				>
					<b-card class="text-center card-content">
						<b-card-body>
							<b-row>
								<b-col
									v-if="!google.manual"
									align-self="center"
								>							
									<div class="qr-wrapper">
										<div v-if="!loading">
											<img
												v-if="google.qrImage"
												:src="google.qrImage"
												alt="Google QR"
											>
											<div class="mt-3">
												<div class="mb-3">
													Unable to scan QR code?
												</div>
												<b-button
													variant="primary"
													@click="toggleGoogleManual"
												>
													Click here
												</b-button>
											</div>
										</div>
										<b-spinner v-if="!google.qrImage || loading" />
									</div>
								</b-col>
								<b-col v-if="google.manual">
									<div class="mb-3 text-left">
										<ol>
											<li>
												Download the Google Authenticator app from <a
													href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"
													target="_blank"
												>Play Store</a> or <a
													href="https://apps.apple.com/us/app/google-authenticator/id388497605"
													target="_blank"
												>App Store</a>.
											</li>
											<li>
												Open the app and click “+” at the bottom of your smartphone screen.
											</li>
											<li>
												Select “Enter a setup key”.
											</li>
											<li>
												Fill in the following fields and click “Add”.
											</li>
											<li>
												The app will generate a 6-figure code for your {{ siteName }} account.
											</li>
											<li>
												Enter the code from Google Authenticator.
											</li>
										</ol>
										<div class="text-center">
											<b-button
												variant="primary"
												@click="toggleGoogleManual"
											>
												Show QR code
											</b-button>
										</div>
									</div>
								</b-col>
								<b-col v-if="google.manual">
									<div class="mb-3">
										<div class="mb-2">
											<small>Account name:</small><br /><strong>{{ owner.email }}</strong>
										</div>
										<div class="mb-2">
											<small>Your key:</small><br />
											<strong v-if="!loading">{{ google.authenticatorCode }}</strong>
											<b-spinner
												v-if="loading"
												small
											/>
										</div>
										<div class="mb-2">
											<small>Time based:</small><br /><strong>Yes</strong>
										</div>
									</div>
									<div>
										<b-button
											variant="outline-primary"
											target="_blank"
											href="https://support.google.com/accounts/answer/1066447"
										>
											<i class="fab fa-google mr-2"></i> Support
										</b-button>
									</div>
								</b-col>
							</b-row>
						</b-card-body>
					</b-card>
				</b-col>
				<b-col class="mb-3">
					<b-card
						no-body
						class="card-content card-content--code"
					>
						<b-row align-v="center">
							<b-col>
								<div class="mb-3">
									<div
										v-if="selection !== 'google'"
										class="text-center"
									>
										Enter code from {{ AuthenticatorHelper.name[selection] }}
										<span v-if="authenticatorProperties?.phoneNumber">
											sent to {{ authenticatorProperties.phoneNumber }}</span>
										<!-- <pre>{{ authenticatorList }}</pre> -->
									</div>
									<div v-if="selection == 'google' && !google.manual">
										<ol>
											<li>
												Download the Google Authenticator app from <a
													href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"
													target="_blank"
												>Play Store</a> or <a
													href="https://apps.apple.com/us/app/google-authenticator/id388497605"
													target="_blank"
												>App Store</a>.
											</li>
											<li>
												Open the app and click “+” at the bottom of your smartphone screen.
											</li>
											<li>
												Scan the QR code on this screen.
											</li>
											<li>
												The app will generate a 6-figure code for your {{ siteName }} account.
											</li>
											<li>
												Enter the code from Google Authenticator.
											</li>
										</ol>
									</div>
								</div>
								<CodeInput
									ref="code"
									v-model="code"
									name="code"
									:digits="6"
									:disabled="loading"
								/>
							</b-col>
						</b-row>
						

						<div class="mt-3">
							<div
								v-if="selection !== 'google'"
								class="text-center"
							>
								Did not get {{ AuthenticatorHelper.name[selection] }} with confirmation code?
							</div>
							<div
								v-if="selection == 'google'"
								class="text-center"
							>
								If the code did not work, click refresh and repeat the process. In an unlikely event that the problem persists, please contact our support team: <a
									:href="'mailto:' + supportEmail"
									target="_blank"
								>{{ supportEmail }}</a>.
							</div>
						</div>
						<div class="mt-3 text-center">
							<b-button
								variant="outline-primary"
								class="mx-1"
								@click.prevent="select(selection)"
							>
								<span v-if="selection !== 'google'">Resend</span>
								<span v-if="selection == 'google'">Refresh</span>
							</b-button>
							<b-button
								variant="outline-primary"
								class="mx-1"
								@click="restart"
							>
								Cancel
							</b-button>
						</div>
					</b-card>
				</b-col>
			</b-row>
			<b-row v-if="step === 3">
				<b-col>
					<b-card class="text-center mb-3">
						<div>
							<span>
								Authenticator successfully changed to <strong>{{ currentSelectionTitle }}</strong>
							</span>
						</div>
						<div v-if="selection == 'google'">
							<div class="mt-3 mb-3">
								<span>Copy or download recovery codes. You won't be able to see it after leaving this page.</span>
							</div>
							<google-recovery-codes
								:codes="google.recoveryCodes"
								:loading="loading"
							/>
						</div>
					</b-card>
					<div class="text-center">
						<b-button
							variant="outline-primary"
							class="mx-1"
							@click="restart"
						>
							Back
						</b-button>
					</div>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { endpoints } from '~/config';
import CodeInput from '@/shared/CodeInput';
import GoogleRecoveryCodes from './GoogleRecoveryCodes';
import { siteName, supportEmail } from '~/config';
import AuthenticatorHelper from '~/helpers/authenticator.helper';

export default {
	name: 'AuthenticatorSettings',
	components: {
		CodeInput,
		GoogleRecoveryCodes,
	},

	data() {
		return {
			loading: false,
			step: 0,
			selection: null,
			code: '',
			google: {},
			siteName,
			supportEmail,
			AuthenticatorHelper,
			options: [
				{
					title: 'SMS Authenticator',
					value: 'sms',
					iconClass: 'fas fa-sms',
					description: 'When you are logging in at <a href="/login">login page</a> you will be sent a code to your phone via SMS. Enter the code to access your account.'
				},
				{
					title: 'WhatsApp Authenticator',
					value: 'whats_app',
					iconClass: 'fab fa-whatsapp',
					description: 'When you are logging in at <a href="/login">login page</a> you will be sent a code to your phone via WhatsApp messages. Enter the code to access your account.'
				},
				{
					title: 'Google Authenticator',
					value: 'google',
					iconClass: 'fab fa-google',
					description: 'It works similarly to authentication procedure via SMS. The major difference is that the code will be generated by the Google Authenticator app on your smartphone. You can download it from the official app store.'
				}
			]
		};
	},
	computed: {
		...mapGetters('user', {
			currentValue: 'authenticatorType',
			authenticatorList: 'authenticatorList'
		}),
		...mapGetters('user', [
			'owner'
		]),
		currentTitle() {
			const currentOption = this.options.find(o => this.currentValue == o.value);
			return currentOption ? currentOption.title : null;
		},
		currentSelectionTitle() {
			const currentSelection = this.options.find(o => this.selection == o.value);
			return currentSelection ? currentSelection.title : null;
		},
		authenticatorProperties: (vm) => vm.authenticatorList.find(v => v.authenticator == vm.selection),
		shownOptions() {
			return this.options.filter(option => this.authenticatorList.find(auth => auth.authenticator == option.value) || option.value == 'google');
		}
	},
	methods: {
		...mapActions('notifications', [
			'pushError'
		]),
		...mapActions('user', [
			'setUserAuthenticator'
		]),
		restart() {
			this.google = {};
			this.selection = null;
			this.step = 0;
		},
		async select(value) {
			let qrUrl;
			this.selection = value;
			this.loading = true;
			const options = {
				method: 'POST',
				url: endpoints.authenticator.change,
				data: {
					authenticator: value
				}
			};
			try {
				const response = await axios(options);
				const { data } = response;
				this.google.authenticatorCode = data.authenticatorCode;
				qrUrl = data.authenticatorUrl;
				this.step = 1;
			} catch (err) {
				this.pushError(err.message);
			}
			if (value == 'google') {
				const image = new Image;
				image.onload = () => {
					this.google.qrImage = qrUrl;
					this.loading = false;
				};
				image.src = qrUrl;
			} else {
				this.loading = false;
			}
		},
		async confirm() {
			this.loading = true;
			const options = {
				method: 'POST',
				url: endpoints.authenticator.confirm,
				data: {
					authenticator: this.selection,
					code: this.code
				}
			};
			try {
				const response = await axios(options);
				const { data } = response;
				if (data.recoveryCodes && data.recoveryCodes.length > 0) {
					this.$set(this.google, 'recoveryCodes', data.recoveryCodes);
				}
				this.setUserAuthenticator(this.selection);
				this.step = 3;
			} catch (err) {
				this.$refs.code.reset();
				this.pushError(err.message);
			}
			this.loading = false;
		},
		saveToFile(content, filename) {
			try {
				const fileUrl = window.URL.createObjectURL(new Blob([content], { type: 'text/plain;charset=utf=8' }));
				const fileLink = document.createElement('a');
				fileLink.href = fileUrl;
				fileLink.setAttribute('download', `${filename}.txt`);
				document.body.appendChild(fileLink);
				fileLink.click();
			} catch (err) {
				this.pushError(err);
			}
		},
		async regenerateGoogle() {
			this.loading = true;
			const options = {
				method: 'POST',
				url: endpoints.authenticator.regenerateGoogle
			};
			try {
				const response = await axios(options);
				const { data } = response;
				if (data.recoveryCodes && data.recoveryCodes.length > 0) {
					this.$set(this.google, 'recoveryCodes', data.recoveryCodes);
				}
			} catch (err) {
				this.pushError(err.message);
			}
			this.loading = false;
		},
		toggleGoogleManual() {
			this.$set(this.google, 'manual', !this.google.manual);
		}
	},
	watch: {
		code: function(v) {
			!!v && v.length == 6 && this.confirm(); 
		}
	}
};
</script>
<style lang="scss" scoped>
	.qr-wrapper {
		min-height: 200px;
		min-width: 200px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.card-content {
		height: 100%;

		&--code {
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 20px;
		}
	}

	.authenticator-appstores {
		text-align: center;

		img {
			margin: 5px;
			height: 40px;
		}
	}

	.authenticator-subtitle {
		margin-bottom: 0;
	}

	.image-wrapper {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		overflow: hidden;
		height: 225px;
		padding-top: 100px;

		&__layer {
			width: 250px;
			height: 250px;
			border-radius: 300px;
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;


			&--1st {
				background-color: lighten($gray, 25);
			}

			&--2nd {
				width: 200px;
				height: 200px;
				background-color: $gray;
			}

			&--3rd {
				width: 120px;
				height: 120px;
				background-color: $primary;
			}

			& > span,
			& > strong {
				position: absolute;
				top: -100px;
				left: 0;
				white-space: nowrap;

				&.image-2step {
					left: 5px;
					top: -95px;

					&::after {
						height: 130px;
					}

					&::before {
						bottom: -137px;
					}
				}

				&.image-password {
					&::after {
						height: 150px;
					}

					&::before {
						bottom: -157px;
					}
				}

				&::before {
					content: '';
					display: block;
					position: absolute;
					width: 7px;
					height: 7px;
					border: 1px solid #000;
					border-radius: 5px;
					bottom: -117px;
					left: 17px;
				}

				&::after {
					content: '';
					display: block;
					position: absolute;
					background-color: #000;
					height: 110px;
					width: 1px;
					left: 20px;
					top: 100%;
				}
			}
		}
	}
</style>