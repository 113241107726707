export default {
	ae: [
		{ value: 'ACM', name: 'Agency Commission' },
		{ value: 'AES', name: 'Advance payment against EOS' },
		{ value: 'AFA', name: 'Receipts or payments from personal residents bank account or deposits abroad' },
		{ value: 'AFL', name: 'Receipts or payments from personal non-resident bank account in the UAE' },
		{ value: 'ALW', name: 'Allowances' },
		{ value: 'ATS', name: 'Air transport' },
		{ value: 'BON', name: 'Bonus' },
		{ value: 'CCP', name: 'Corporate Card Payment' },
		{ value: 'CEA', name: '"Equity and investment fund shares for the establishment of new company from residents abroad, equity of merger or acquisition of companies abroad from residents and participation to capital increase of related companies abroad"' },
		{ value: 'CEL', name: '"Equity and investment fund shares for the establishment of new company in the UAE from non-residents, equity of merger or acquisition of companies in the UAE from non-residents and participation to capital increase of related companies from non-residents in the UAE"' },
		{ value: 'CHC', name: 'Charitable Contributions' },
		{ value: 'CIN', name: 'Commercial Investments' },
		{ value: 'COM', name: 'Commission' },
		{ value: 'COP', name: 'Compensation' },
		{ value: 'CRP', name: 'Credit Card Payments' },
		{ value: 'DCP', name: 'Pre-Paid Reloadable & Personalized Debit Card Payments' },
		{ value: 'DIV', name: 'Dividend Payouts' },
		{ value: 'DLA', name: 'Purchases and sales of foreign debt securities in not related companies – More than a year' },
		{ value: 'DLF', name: '"Debt instruments intragroup loans, deposits foreign"' },
		{ value: 'DLL', name: 'Purchases and sales of securities issued by residents in not related companies – More than a year' },
		{ value: 'DOE', name: 'Dividends on equity not intra group' },
		{ value: 'DSA', name: 'Purchases and sales of foreign debt securities in not related companies – Less than a year' },
		{ value: 'DSF', name: 'Debt instruments intragroup foreign securities' },
		{ value: 'DSL', name: 'Purchases and sales of securities issued by residents in not related companies – Less than a year' },
		{ value: 'EDU', name: 'Educational Support' },
		{ value: 'EMI', name: 'Equated Monthly Instalments' },
		{ value: 'EOS', name: 'End of Service' },
		{ value: 'FAM', name: 'Family Support' },
		{ value: 'FDA', name: 'Financial derivatives foreign' },
		{ value: 'FDL', name: 'Financial derivatives in the UAE' },
		{ value: 'FIA', name: 'Investment fund shares foreign' },
		{ value: 'FIL', name: 'Investment fund shares in the UAE' },
		{ value: 'FIS', name: 'Financial services' },
		{ value: 'FSA', name: 'Equity other than investment fund shares in not related companies abroad' },
		{ value: 'FSL', name: 'Equity other than investment fund shares in not related companies in the UAE' },
		{ value: 'GDE', name: 'Goods Sold' },
		{ value: 'GDI', name: 'Goods bought' },
		{ value: 'GMS', name: 'Processing repair and maintenance services on goods' },
		{ value: 'GOS', name: 'Government goods and services embassies etc' },
		{ value: 'GRI', name: 'Government related income taxes tariffs capital transfers etc' },
		{ value: 'IFS', name: 'Information services' },
		{ value: 'IGD', name: 'Intra group dividends' },
		{ value: 'IGT', name: 'Inter group transfer' },
		{ value: 'IID', name: 'Intra group interest on debt' },
		{ value: 'INS', name: 'Insurance services' },
		{ value: 'IOD', name: 'Income on deposits' },
		{ value: 'IOL', name: 'Income on loans' },
		{ value: 'IPC', name: 'Charges for the use of intellectual property royalties' },
		{ value: 'IPO', name: 'IPO subscriptions' },
		{ value: 'IRP', name: 'Interest rate swap payments' },
		{ value: 'IRW', name: 'Interest rate unwind payments' },
		{ value: 'ISH', name: 'Income on investment funds share' },
		{ value: 'ISL', name: 'Interest on securities more than a year' },
		{ value: 'ISS', name: 'Interest on securities less than a year' },
		{ value: 'ITS', name: 'Computer services' },
		{ value: 'LAS', name: 'Leave salary' },
		{ value: 'LDL', name: '"Debt instruments intragroup loans, deposits in the UAE"' },
		{ value: 'LDS', name: 'Debt instruments intragroup securities in the UAE' },
		{ value: 'LEA', name: 'Leasing abroad' },
		{ value: 'LEL', name: 'Leasing in the UAE' },
		{ value: 'LIP', name: 'Loan interest payments' },
		{ value: 'LLA', name: 'Loans – Drawings or Repayments on loans extended to nonresidents – Long-term' },
		{ value: 'LLL', name: 'Loans – Drawings or Repayments on foreign loans extended to residents – Long-term' },
		{ value: 'LNC', name: 'Loan charges' },
		{ value: 'LND', name: 'Loan disbursements' },
		{ value: 'MCR', name: 'Monetary Claim Reimbursements Medical Insurance or Auto Insurance etc.' },
		{ value: 'MWI', name: 'Mobile wallet cash in' },
		{ value: 'MWO', name: 'Mobile wallet cash out' },
		{ value: 'MWP', name: 'Mobile wallet payments' },
		{ value: 'OAT', name: 'Own account transfer' },
		{ value: 'OTS', name: 'Other modes of transport' },
		{ value: 'OVT', name: 'Overtime' },
		{ value: 'PEN', name: 'Pension' },
		{ value: 'PIN', name: 'Personal Investments' },
		{ value: 'PIP', name: 'Profits on Islamic products' },
		{ value: 'PMS', name: 'Professional and management consulting services' },
		{ value: 'POR', name: 'Refunds or Reversals on IPO subscriptions' },
		{ value: 'POS', name: 'POS Merchant Settlement' },
		{ value: 'PPA', name: 'Purchase of real estate abroad from residents' },
		{ value: 'PPL', name: 'Purchase of real estate in the UAE from non-residents' },
		{ value: 'PRP', name: 'Profit rate swap payments' },
		{ value: 'PRR', name: 'Profits or rents on real estate' },
		{ value: 'PRS', name: 'Personal cultural audio visual and recreational services' },
		{ value: 'PRW', name: 'Profit rate unwind payments' },
		{ value: 'RDS', name: 'Research and development services' },
		{ value: 'RFS', name: 'Repos on foreign securities' },
		{ value: 'RLS', name: 'Repos on securities issued by residents' },
		{ value: 'RNT', name: 'Rent payments' },
		{ value: 'SAA', name: 'Salary advance' },
		{ value: 'SAL', name: 'Salary' },
		{ value: 'SCO', name: 'Construction' },
		{ value: 'SLA', name: 'Loans – Drawings or Repayments on loans extended to nonresidents – Short-term' },
		{ value: 'SLL', name: 'Loans – Drawings or Repayments on foreign loans extended to residents – Short-term' },
		{ value: 'STR', name: 'Travel' },
		{ value: 'STS', name: 'Sea transport' },
		{ value: 'SVI', name: 'Stored value card cash-in' },
		{ value: 'SVO', name: 'Stored value card cash-out' },
		{ value: 'SVP', name: 'Stored value card payments' },
		{ value: 'TCP', name: 'Trade credits and advances payable' },
		{ value: 'TCR', name: 'Trade credits and advances receivable' },
		{ value: 'TCS', name: 'Telecommunication services' },
		{ value: 'TKT', name: 'Tickets' },
		{ value: 'TOF', name: 'Transfer of funds between persons Normal and Juridical' },
		{ value: 'TTS', name: 'Technical trade-related and other business services' },
		{ value: 'UTL', name: 'Utility Bill Payments' },
	],
	au: [
		{ value: 'transfer', name: 'Transfer to own account' },
		{ value: 'education', name: 'Education-related student expenses' },
		{ value: 'hotel', name: 'Hotel Accommodation' },
		{ value: 'tax', name: 'Tax Payment' },
		{ value: 'property_purchase', name: 'Purchase of Residential Property' },
		{ value: 'insurance_premium', name: 'Insurance Premium' },
		{ value: 'insurance_claims', name: 'Insurance Claims Payment' },
		{ value: 'fund_investment', name: 'Mutual Fund Investment' },
		{ value: 'donations', name: 'Donations' },
		{ value: 'travel', name: 'Travel' },
		{ value: 'advertising', name: 'Advertising and Public relations-related expenses' },
		{ value: 'other_fees', name: 'Fees for brokers front end fee commitment fee guarantee fee and custodian fee' },
		{ value: 'advisor_fees', name: 'Salary or Wages or Fees for advisors technical assistance and academic knowledge including remuneration for specialists' },
		{ value: 'office', name: 'Representative office expenses' },
		{ value: 'goods', name: 'Transportation fees for goods' },
	]
};
