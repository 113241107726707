<template>
	<div class="auth-card">
		<h1 class="auth-title text-success">
			Your password reset<br> has been completed.
		</h1>

		<router-link 
			to="/login"
			class="btn btn-dark px-5"
		>
			Go to login
		</router-link>
	</div>
</template>

<script>

export default {
	name: 'RecoverPasswordStep2'
};
</script>
