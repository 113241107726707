import moment from 'moment';

export default function generateReferralCookie() {
	const hashPrefix = '#ref=';
	const hash = window.location.hash;
	if (hash && hash.includes(hashPrefix)) {
		const referralId = hash.replace(hashPrefix, '');
		const expirationDate = moment().add(14, 'd').utc().toString();
		const cookieValue = `referral_id=${referralId}; expires=${expirationDate}; path=/`;
		document.cookie = cookieValue;
	}
}