<template>
	<div class="sidebar-header">
		<div class="sidebar-header-logo">
			<router-link
				:to="{ name: 'home' }"
				class="logo"
				exact
			>
				{{ siteName }}
			</router-link>
			<!--            <div-->
			<!--                class="user-icon-wrap theme-switcher-desktop ml-4"-->
			<!--                @click="switchTheme"-->
			<!--            >-->
			<!--                <ion-icon name="moon-outline"/>-->
			<!--            </div>-->
		</div>
		<div class="sidebar-header-user">
			<div
				id="dropdownMenuButton"
				class="user-icon-wrap dropdown"
				data-toggle="dropdown"
				aria-haspopup="true"
				aria-expanded="false"
			>
				<span>
					<ion-icon name="settings-outline" />
				</span>
				<div
					class="dropdown-menu dropdown-menu-right"
					aria-labelledby="dropdownMenuButton"
				>
					<router-link
						:to="{ name: 'profile' }"
						class="dropdown-item"
						exact
					>
						Profile Information
					</router-link>
					<router-link
						:to="{ name: 'profile-accounts-page' }"
						class="dropdown-item"
						exact
					>
						Account Settings
					</router-link>
					<router-link
						:to="{ name: 'authenticator-settings' }"
						class="dropdown-item logout-menu-item"
						exact
					>
						Two-factor Authentication
					</router-link>

					<router-link
						:to="{ name: 'log' }"
						class="dropdown-item"
						exact
					>
						Profile Activity
					</router-link>
					<router-link
						:to="{ name: 'change-password'}"
						class="dropdown-item"
						exact
					>
						Change Password
					</router-link>
					<router-link
						:to="{ name: 'login' }"
						class="dropdown-item logout-menu-item"
						exact
					>
						Logout
						<ion-icon
							class="logout-icon"
							name="log-out-outline"
						/>
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { siteName } from '~/config';
export default {
	components: {
	},
	computed: {
		...mapState({
			siteName: () => siteName
		}),
		...mapGetters('user', [
			'isBusiness',
			'isGranted'
		]),
		referralEnabled() {
			return this.isGranted('front_referral_page');
		}
	},
	created() {
		this.loadTheme();
	},
	methods: {
		loadTheme: function () {
			let currentTheme = localStorage.getItem('currentTheme');
			if (currentTheme) {
				this.setTheme(currentTheme);
			}
		},
		switchTheme: function () {
			let currentTheme = localStorage.getItem('currentTheme');
			if (currentTheme) {
				currentTheme = currentTheme === 'light' ? 'dark' : 'light';
			} else {
				currentTheme = 'light';
			}
			this.transition();
			localStorage.setItem('currentTheme', currentTheme);
			this.setTheme(currentTheme);
		},
		setTheme: function (theme = 'light') {
			document.documentElement.setAttribute('data-theme', theme);
		},
		transition: function () {
			document.documentElement.classList.add('transition');
			window.setTimeout(() => {
				document.documentElement.classList.remove('transition');
			}, 1000);
		}
	}
};
</script>
<style lang="scss" scoped>
	@import './DashboardHeader.scss';	
</style>