var render = function () {
  var _vm$files, _vm$files2, _vm$files3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "file-upload"
  }, [_vm.expandable ? _c('div', [_c('div', {
    staticClass: "file-upload-dropzone",
    "class": _vm.dragover && 'file-upload-dropzone--dragover'
  }, [_c('b-form-file', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: _vm.required ? 'required' : '',
      expression: "required ? 'required' : ''"
    }],
    ref: "file-input",
    staticClass: "file-upload-input--expandable",
    attrs: {
      "plain": "",
      "accept": _vm.accept,
      "name": _vm.name,
      "multiple": _vm.multiple
    },
    on: {
      "change": _vm.onFileChange
    },
    model: {
      value: _vm.files,
      callback: function callback($$v) {
        _vm.files = $$v;
      },
      expression: "files"
    }
  }), _vm._m(0), _c('div', {
    staticClass: "text-center"
  }, [_vm.dragover ? _c('span', [_vm._v(_vm._s(_vm.fieldDropPlaceholder))]) : _c('span', [_vm._v(_vm._s(_vm.fieldPlaceholder))])])], 1)]) : _c('div', [_c('b-form-file', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.fileList || _vm.fileList && (!_vm.files || ((_vm$files = _vm.files) === null || _vm$files === void 0 ? void 0 : _vm$files.length) == 0),
      expression: "!fileList || (fileList && (!files || files?.length == 0))"
    }, {
      name: "validate",
      rawName: "v-validate",
      value: _vm.required ? 'required' : '',
      expression: "required ? 'required' : ''"
    }],
    ref: "file-input",
    staticClass: "file-upload-input",
    attrs: {
      "accept": _vm.accept,
      "placeholder": _vm.fieldPlaceholder,
      "drop-placeholder": _vm.fieldDropPlaceholder,
      "name": _vm.name,
      "multiple": _vm.multiple
    },
    on: {
      "change": _vm.onFileChange
    },
    model: {
      value: _vm.files,
      callback: function callback($$v) {
        _vm.files = $$v;
      },
      expression: "files"
    }
  }, [_c('template', {
    slot: "file-name"
  }, [_c('b-badge', {
    staticClass: "file-badge",
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.trimFileName(_vm.fileNames[0])) + " ")]), _vm.fileNames[1] ? _c('b-badge', {
    staticClass: "file-badge ml-1",
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.trimFileName(_vm.fileNames[1])) + " ")]) : _vm._e(), _vm.fileNames.length > 2 ? _c('b-badge', {
    staticClass: "file-badge ml-1",
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(" " + _vm._s("+".concat(_vm.fileNames.length - 2, " more file").concat(_vm.fileNames.length == 3 ? '' : 's')) + " ")]) : _vm._e()], 1)], 2), _vm.fileList && ((_vm$files2 = _vm.files) === null || _vm$files2 === void 0 ? void 0 : _vm$files2.length) > 0 ? _c('div', [_c('b-list-group', _vm._l(_vm.files, function (file, i) {
    return _c('b-list-group-item', {
      key: i
    }, [_c('b-row', [_c('b-col', [_c('small', [_vm._v(_vm._s(file.name))])]), _c('b-col', {
      staticClass: "col-auto"
    }, [_c('b-button', {
      attrs: {
        "size": "sm",
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.removeFile(file, i);
        }
      }
    }, [_vm._v(" Remove ")])], 1)], 1)], 1);
  }), 1)], 1) : _vm._e(), _vm.addMoreButton && ((_vm$files3 = _vm.files) === null || _vm$files3 === void 0 ? void 0 : _vm$files3.length) > 0 ? _c('div', {
    staticClass: "mt-2"
  }, [_c('div', {
    staticClass: "d-inline-block position-relative"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.$refs['extra-file-input'].$el.click();
      }
    }
  }, [_vm._v(" Add additional files ")]), _c('b-form-file', {
    ref: "extra-file-input",
    staticClass: "extra-file-input",
    attrs: {
      "name": "extra-file",
      "accept": _vm.accept,
      "multiple": _vm.multiple,
      "plain": ""
    },
    on: {
      "change": _vm.onExtraFileChange
    },
    model: {
      value: _vm.extraFile,
      callback: function callback($$v) {
        _vm.extraFile = $$v;
      },
      expression: "extraFile"
    }
  })], 1)]) : _vm._e()], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mr-3 file-upload-icon"
  }, [_c('i', {
    staticClass: "fas fa-file-import"
  })]);
}]

export { render, staticRenderFns }