<template>
	<div>
		<b-row>
			<b-col>
				<span class="card-order__subtitle">Address</span>
			</b-col>
		</b-row>
		<b-row>
			<b-col
				v-if="user.userProfile && userAddress"
				class="card-order__account-info"
			>
				<b-row>
					<b-col md="3">
						Street:
					</b-col>
					<b-col md="9">
						{{ userAddress.street }} {{ userAddress.streetNo }}{{ userAddress.flatNo ? `-${userAddress.flatNo}` : '' }}
					</b-col>
				</b-row>
				<b-row>
					<b-col md="3">
						Postal code:
					</b-col>
					<b-col md="3">
						{{ userAddress.zipCode }}
					</b-col>
				</b-row>
				<b-row>
					<b-col md="3">
						City:
					</b-col>
					<b-col md="9">
						{{ userAddress.city }}
					</b-col>
				</b-row>
				<b-row>
					<b-col md="3">
						State:
					</b-col>
					<b-col md="9">
						{{ userAddress.state }}
					</b-col>
				</b-row>
				<b-row>
					<b-col md="3">
						Country of Residence:
					</b-col>
					<b-col md="9">
						{{ userCountryName }}
					</b-col>
				</b-row>
			</b-col>
		</b-row>
	</div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
export default {
	name: 'CardAddressDetails',
	computed: {
		...mapState('user', {
			user: state => state.selected
		}),
		...mapState('countries', {
			countries: state => state.list
		}),
		...mapGetters('user', [
			'owner',
			'userAddress',
		]),
		...mapGetters('wallet', [
			'currentWallet'
		]),
		userCountryName() {
			const c = this.countries.find(c => c.countryId == this.userAddress.countryId);
			if (!c) return '';
			return c.name;
		}
	},
	methods: {
	}
};
</script>
<style lang="scss">
	.card-order {
		&__account-info {
			margin-bottom: 50px;
			> .row {
				padding: 10px 0;

				> div {
					border-bottom: 1px solid rgba(0, 0, 0, .03);

					&:first-child {
						font-size: 13px;
						color: #6B7280;
						font-weight: normal;
					}

					font-size: 16px;
					color: #212529;
					font-weight: 500;
				}
			}
		}
		
		&__subtitle {
			color: #6B7280;
			font-weight: 600;
			margin-bottom: 22px;
			display: inline-block;
		}

		&__details-note {
			font-size: 14px;
		}
	}
</style>