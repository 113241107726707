<template>
	<b-modal
		v-cloak
		id="acc-info-modal"
		centered
		hide-header
		size="sm"
	>
		<!-- eslint-disable-next-line vue/no-unused-vars -->
		<template #default="{ hide }">
			<div class="account-info-wrapper">
				<div class="account-info-header">
					<div>
						<h5>Account information</h5>
					</div>
				</div>

				<div
					ref="accountInfo"
					class="account-information-items"
				>
					<div class="account-information-item">
						<h5 class="account-information-title">
							Beneficiary
						</h5>
						<div class="account-information-text-wrapper">
							<h4 class="account-information-text">
								{{ username }}
							</h4>
							<ion-icon
								class="copy-icon"
								name="copy-outline"
								@click="copy(username)"
							/>
						</div>
					</div>
					<div class="account-information-item">
						<h5 class="account-information-title">
							IBAN
						</h5>
						<div class="account-information-text-wrapper">
							<h4 class="account-information-text">
								{{ currentWallet.iban }}
							</h4>
							<ion-icon
								class="copy-icon"
								name="copy-outline"
								@click="copy(currentWallet.iban)"
							/>
						</div>
					</div>
					<div class="account-information-item">
						<h5 class="account-information-title">
							BIC
						</h5>
						<div class="account-information-text-wrapper">
							<h4 class="account-information-text">
								{{ bic }}
							</h4>
							<ion-icon
								class="copy-icon"
								name="copy-outline"
								@click="copy(bic)"
							/>
						</div>
					</div>
					<div class="account-information-item">
						<h5 class="account-information-title">
							Account type
						</h5>
						<div class="account-information-text-wrapper">
							<h4 class="account-information-text">
								{{ currentWalletType }}
							</h4>
							<ion-icon
								class="copy-icon"
								name="copy-outline"
								@click="copy(currentWalletType)"
							/>
						</div>
					</div>
				</div>
			</div>
		</template>

		<template #modal-footer="{ hide }">
			<b-button
				variant="btn btn-info"
				@click="hide('forget')"
			>
				Close
			</b-button>
		</template>
	</b-modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex';

import Vue from 'vue';
import VueClipboard from 'vue-clipboard2';

VueClipboard.config.autoSetContainer = true; // add this line
Vue.use(VueClipboard);

export default {
	data() {
		return {
			bic: 'VEPALT2X',
		};
	},
	computed: {
		...mapState({
			user: state => state.user.selected,
			persons: state => state.user.list,
			log: state => state.log
		}),
		...mapState('wallet', [
			'wallet'
		]),
		...mapGetters('user', [
			'username',
			'isBusiness',
			'verificationLink'
		]),
		...mapGetters([
			'currenciesOptions',
		]),
		...mapGetters('wallet', [
			'currentWallet',
			'currentWalletType'
		]),
	},
	created() {

	},
	methods: {
		async copy(text) {
			try {
				const e = await this.$copyText(text, this.$refs.accountInfo);
				alert('You just copied: ' + e.text);
			} catch (err) {
				alert('Failed to copy texts');
			}
		}
	}
};
</script>
<style lang="scss" scoped>
	@import './AccountInfoModal.scss';
</style>