<template>
	<b-modal
		v-cloak
		:id="id"
		size="sm"
		centered
		hide-header
		hide-footer
		no-close-on-backdrop
		@hidden="resetActions"
	>
		<div class="text-center card-prompt-modal card-modal">
			<h2 v-if="params.title">
				{{ params.title }}
			</h2>
			<div>
				{{ params.text }}
			</div>
			<div
				v-if="params.warningText"
				class="card-prompt-modal__warning"
			>
				<small>{{ params.warningText }}</small>
			</div>
			<div class="mt-4">
				<b-button
					variant="primary"
					class="mr-4"
					@click="confirm"
				>
					Yes
				</b-button>
				<b-button
					variant="outline-primary"
					@click="decline"
				>
					No
				</b-button>
			</div>
		</div>
	</b-modal>
</template>
<script>

const defaultParams = {
	onConfirm: () => {},
	onDecline: null,
	text: 'Are you sure?',
	warningText: '',
	title: ''
};
export default {
	name: 'CardPromptModal',
	props: {
		onClose: {
			type: Function
		}
	},
	data() {
		return {
			id: 'card-prompt-modal',
			params: { ...defaultParams }
		};
	},
	methods: {
		async confirm() {
			await this.params.onConfirm();
			this.closeModal();
		},
		async decline() {
			if (this.params.onDecline) await this.params.onDecline();
			this.closeModal();
		},
		closeModal() {
			this.$bvModal.hide(this.id);
		},
		resetActions() {
			this.params = { ...defaultParams };
			if (this.onClose) this.onClose();
		}
	},
};
</script>
<style lang="scss">
.card-prompt-modal {
	&__warning {
		color: $red
	}
}
</style>