import {
	CONFIRMATION_CREATE_SUCCESS,
	CONFIRMATION_FAILURE,
	CONFIRMATION_REQUEST,
	CONFIRMATION_RESET,
	CONFIRMATION_SUCCESS,
	LOGIN_FAILURE,
	LOGIN_REQUEST,
	LOGIN_SUCCESS,
	LOGOUT,
} from '~/store/mutation-types';

import { cookie } from '~/helpers';
import { endpoints, refreshCookieName } from '~/config';
import loginCounter from '~/helpers/loginCounter.helper';
import { twoFactorHelper } from '~/helpers/twofactor.helper';

export default {
	login,
	setAuthorized,
	confirm,
	createConfirm,
	refresh,
	logout,
	confirmationReset,
	init2fa
};

function setAuthHeader(AUTH_TOKEN) {
	axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
}

function handleAuthResponse(data) {
	setAuthHeader(`${data.tokenType} ${data.accessToken}`);
}


// Login request with username and password
async function login({ commit, dispatch }, data) {
	commit(LOGIN_REQUEST);
	const options = {
		method: 'POST',
		url: endpoints.auth.login,
		data
	};
	try {
		const response = await axios(options);
		const { data } = response;
		dispatch('setAuthorized', data);
		return Promise.resolve(response);
	} catch (err) {
		commit(LOGIN_FAILURE);

		return Promise.reject(err.message);
	}
}
function setAuthorized({ commit }, data) {
	handleAuthResponse(data);
	return commit(LOGIN_SUCCESS, data.accessToken);
}
// SMS code request to get user data
async function confirm({ commit }, payload) {
	const counter = new loginCounter();
	commit(CONFIRMATION_REQUEST);
	const options = {
		method: 'PUT',
		url: endpoints.auth.confirm,
		data: payload
	};
	try {
		const response = await axios(options);
		commit(CONFIRMATION_SUCCESS);
		counter.addCount();
		twoFactorHelper.setAuthenticated(1);
		return Promise.resolve(response);
	} catch (err) {
		commit(CONFIRMATION_FAILURE);
		return Promise.reject(err.message);
	}
}

async function createConfirm({ commit }) {
	const options = {
		method: 'PUT',
		url: endpoints.auth.create
	};
	try {
		const response = await axios(options);
		commit(CONFIRMATION_CREATE_SUCCESS);
		return Promise.resolve(response);
	} catch (err) {
		commit(CONFIRMATION_FAILURE);
		return Promise.reject(err.message);
	}
}

async function init2fa(props, { type, provider, data }) {
	const options = {
		method: 'POST',
		url: endpoints.auth.init2fa,
		data: {
			...{
				type,
				provider,
			},
			...data,
		}
	};
	try {
		const response = await axios(options);
		return Promise.resolve(response);
	} catch (err) {
		return Promise.reject(err.message);
	}
}

// Send refresh token to receive auth token
async function refresh({ commit }) {
	commit(LOGIN_REQUEST);
	const options = {
		method: 'POST',
		url: endpoints.auth.refreshToken,
		data: {
			'clientId': 'frontend',
		}
	};
	try {
		const response = await axios(options);
		const { data } = response;
		commit(LOGIN_SUCCESS, data.accessToken);
		handleAuthResponse(data);
		return Promise.resolve(response);
	} catch (err) {
		commit(LOGIN_FAILURE);
		return Promise.reject(err.message);
	}
}
async function logout({ commit, dispatch }) {
	commit(LOGOUT);
	commit(CONFIRMATION_RESET);
	await dispatch('user/remove', null, { root: true });
	await dispatch('wallet/clearWallets', null, { root: true });
	await dispatch('cards/reset', null, { root: true });
	twoFactorHelper.unsetAuthenticated();
	const options = {
		method: 'GET',
		url: endpoints.auth.logout
	};
	try {
		if (cookie.exists(refreshCookieName)) {
			const L = window.location;
			cookie.unset(refreshCookieName, `${L.protocol}//${L.hostname}`);
			const response = await axios(options);
			return Promise.resolve(response);
		} else {
			return Promise.resolve({});
		}
	} catch (err) {
		return Promise.reject(err.message);
	}
}
function confirmationReset({ commit }) {
	return commit(CONFIRMATION_RESET);
}
