<template>
	<datepicker
		:id="id"
		v-model="selectedDate"
		:disabled-dates="disabledDates"
		:open-date="openDate"
		:monday-first="true"
		:name="name"
		input-class="form-control datepicker"
		type="text"
		format="yyyy-MM-dd"
		placeholder="Please select date"
		:disabled="disabled"
		@opened="openedCallback(id)"
	/>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import $ from 'jquery';
import debounce from 'lodash/debounce';
import moment from 'moment';

function setPosition(input, i, el) {
	let cal = $(el);

	if (input.offset().left + cal.width() > window.innerWidth) {
		cal.css('right', 0);
	}
}

function updatePosition(input) {
	if (!input || input.length === 0) {
		return;
	}

	let calendars = input
		.closest('.vdp-datepicker')
		.find('.vdp-datepicker__calendar');

	if (calendars.length === 0) {
		return;
	}

	calendars.each(setPosition.bind(null, input));
}

function focusWithTabHandler({ currentTarget }) {
	if (!$(currentTarget).attr('readonly')) {
		currentTarget.click();
	}
}

export default {
	name: 'AppDatepicker',
	components: {
		Datepicker,
	},
	props: {
		name: {
			default: null,
			type: String,
		},
		value: {
			default: null,
			type: String,
		},
		adult: {
			default: false,
			type: Boolean,
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	data() {
		let adultDate = { 
			from: new Date((new Date()).getFullYear() - 18, (new Date()).getMonth(), (new Date()).getDate() + 1) 
		};
		return {
			id: null,
			selectedDate: this.value,
			disabledDates: this.adult ? adultDate : {},
			openDate: this.adult ? adultDate.from : null
		};
	},
	watch: {
		selectedDate(newValue) {
			this.$emit('input', moment(newValue).format('YYYY-MM-DD'));
		},
		value(newValue) {
			this.selectedDate = newValue;
		},
	},
	created() {
		this.id = 'app-dp-' + (new Date()).getTime();
	},
	mounted() {
		$(`#${this.id}`).on('focus', debounce(focusWithTabHandler, 400));
	},
	methods: {
		openedCallback(id) {
			let input = $(`#${id}`);
			input
				.attr('readonly', 'readonly')
				.one('blur', () => input.removeAttr('readonly'));

			updatePosition(input);
		},
	},
};
</script>
<style lang="scss">
	@import './AppDatepicker.scss';
</style>