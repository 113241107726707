<template>
	<div 
		id="profile-account-owner" 
		class="account-details profile-account-owner"
	>
		<h3 class="mb-4 mb-lg-5 font-weight-bold">
			Account owner information
		</h3>
		<b-card>
			<div class="form-group">
				<div class="row align-items-center">
					<label 
						class="col-6 col-sm-5 col-xl-4 col-form-label"
					>Name Surname</label>
					<div class="col-6 col-sm-7 col-xl-6">
						<strong>{{ data.name }} {{ data.surname }}</strong>
					</div>
				</div>
			</div>
			<div class="form-group">
				<div class="row align-items-center">
					<label 
						class="col-6 col-sm-5 col-xl-4 col-form-label"
					>Phone number</label>
					<div class="col-6 col-sm-7 col-xl-6">
						<strong v-if="data.contacts?.mainPhone">+{{ data.contacts?.mainPhone }}</strong>
						<strong v-else>-</strong>
					</div>
				</div>
			</div>
			<div class="form-group">
				<div class="row align-items-center">
					<label class="col-6 col-sm-5 col-xl-4 col-form-label">Address</label>
					<div class="col-6 col-sm-7 col-xl-6">
						<strong>{{ userAddress.address }}</strong>
					</div>
				</div>
			</div>
			<div class="form-group">
				<div class="row align-items-center">
					<label class="col-6 col-sm-5 col-xl-4 col-form-label">City</label>
					<div class="col-6 col-sm-7 col-xl-6">
						<strong>{{ userAddress.city }}</strong>
					</div>
				</div>
			</div>
			<div class="form-group">
				<div 
					v-if="userAddress.state" 
					class="row align-items-center"
				>
					<label class="col-6 col-sm-5 col-xl-4 col-form-label">State</label>
					<div class="col-6 col-sm-7 col-xl-6">
						<strong>{{ userAddress.state }}</strong>
					</div>
				</div>
			</div>
			<div class="form-group">
				<div 
					v-if="userAddress.state" 
					class="row align-items-center"
				>
					<label 
						class="col-6 col-sm-5 col-xl-4 col-form-label"
					>County of residence</label>
					<div class="col-6 col-sm-7 col-xl-6">
						<strong class="text-uppercase">{{ data.addresses.main.countryId }}</strong>
					</div>
				</div>
			</div>
		</b-card>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'ProfileAccountOwner',
	props: {
		data: {
			type: Object,
			default: () => {}
		}
	},
	computed: {
		...mapGetters('user', ['userAddress'])
	}
};
</script>
