var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": _vm.id,
      "size": "sm",
      "centered": "",
      "hide-header": "",
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.reset,
      "shown": _vm.focusForm
    }
  }, [_c('div', {
    staticClass: "text-center card-jwk-modal card-modal"
  }, [_c('span', {
    staticClass: "card-settings__modal-close",
    on: {
      "click": _vm.closeModal
    }
  }), _vm.type === 'setPin' ? _c('card-set-pin-form', {
    ref: "form",
    model: {
      value: _vm.formData,
      callback: function callback($$v) {
        _vm.formData = $$v;
      },
      expression: "formData"
    }
  }) : _vm._e(), _vm.type == 'activate' ? _c('card-activate-form', {
    ref: "form",
    model: {
      value: _vm.formData,
      callback: function callback($$v) {
        _vm.formData = $$v;
      },
      expression: "formData"
    }
  }) : _vm._e(), _vm.type === 'set3d' ? _c('card-3d-password-form', {
    ref: "form",
    model: {
      value: _vm.formData,
      callback: function callback($$v) {
        _vm.formData = $$v;
      },
      expression: "formData"
    }
  }) : _vm._e(), _vm.type === 'details' ? _c('card-details-view', {
    attrs: {
      "content": _vm.content
    }
  }) : _vm._e(), _vm.type === 'pin' ? _c('card-pin-view', {
    attrs: {
      "content": _vm.content
    }
  }) : _vm._e(), _vm.onSubmit ? _c('b-button', {
    staticClass: "mt-4",
    attrs: {
      "variant": "primary",
      "disabled": !_vm.formData
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v(" Submit ")]) : _vm._e()], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }