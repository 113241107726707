<template>
	<form @submit.prevent="register($event)">
		<div>
			<b-row
				align-v="stretch"
				class="mb-4"
			>
				<b-col
					v-for="{title, value, description} in roles"
					:key="value"
					sm="6"
					cols="12"
					class="role-option"
					data-registration="use-of-account"
					@click="() => selectRole(value)"
				>
					<div
						class="role-option__box"
						:class="{'role-option__box--active' : role == value}"
					>
						<div class="role-option__text">
							<h3><span class="radio"></span>{{ title }}</h3>
							<p
								v-if="!role"
								v-html="description"
							></p>
						</div>
					</div>
				</b-col>
			</b-row>

			<div v-show="role">
				<form-error-field name="email">
					<label for="email">Your Email Address</label>
					<b-form-input
						id="email"
						ref="email"
						v-model="email"
						v-validate="'email|required'"
						type="text"
						name="email"
						placeholder="Email Address"
						data-registration="email"
					/>
				</form-error-field>
				<div class="form-group">
					<phone-input
						v-model="phone"
						v-validate="'required'"
						name="phone"
						data-registration="phone"
					/>
				</div>
			</div>
			<notification />

			<b-row class="justify-content-md-center">
				<b-col
					md="12"
					class="text-center"
				>
					<div>Confirmation link will be sent to your email</div>
					<Transition name="fade">
						<div
							v-if="role == 'business_unverified'"
							class="alert alert-dark mt-4"
						>
							Business account can be opened only by business director
							(CEO) or person who has power of attorney.<br />
							Your company must have registration documents and
							working website.
						</div>
					</Transition>
				</b-col>
			</b-row>

			<b-row class="justify-content-center pt-4">
				<b-col
					md="8"
					class="text-center"
				>
					<input
						:disabled="loading"
						type="submit"
						class="btn btn-primary btn-block"
						value="Next"
						data-registration="next"
					/>
				</b-col>
			</b-row>
		</div>
		<b-row class="justify-content-md-center">
			<b-col
				md="6"
				class="pt-4 text-center"
			>
				Go back to
				<span>
					<router-link
						:to="{ name: 'login' }"
						exact
					>Login</router-link>
					<span v-if="WHITELABEL == 'supersonicz'">
						{{ ` / ` }}<a href="https://www.wisebanq.com"> Home</a>
					</span>
				</span>
			</b-col>
		</b-row>
		<vue-recaptcha
			ref="recaptcha"
			:sitekey="recapthaSitekey"
			:load-recaptcha-script="true"
			size="invisible"
			@verify="onVerify"
		/>
	</form>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Notification from '@/shared/Notification';
import config from '~/config';
import { VueRecaptcha } from 'vue-recaptcha';
import PhoneInput from '@/shared/PhoneInput';
import FormErrorField from '@/shared/Form/FormErrorField';

export default {
	name: 'RegisterForm',
	components: {
		Notification,
		VueRecaptcha,
		PhoneInput,
		FormErrorField
	},
	data() {
		return {
			phone: '',
			email: '',
			referralId: '',
			roles: config.registration.roles,
			recapthaSitekey: config.recaptchaSitekey,
			WHITELABEL
		};
	},
	computed: {
		...mapState('register', ['errors', 'role']),
		...mapState('register', {
			loading: (state) => state.status.loading,
		}),
	},
	watch: {
		email: function(v) {
			this.email = v && v.toLowerCase().replace(/\s/g, '');
		}
	},
	methods: {
		...mapActions('register', [
			'startRegistration',
			'clearErrors',
			'setRole',
		]),
		...mapActions('auth', ['logout']),
		...mapActions('notifications', ['pushError']),
		async register() {
			const valid = await this.$validator.validateAll();
			if (valid) {
				const id = this.email.replace('@', '.').split('.');
				const profileId = `${id[0]}.${id[1]}`;
				const roleName = this.role.split('_')[0];
				this.$gtag.event(`register_role_${roleName}`);
				this.$gtag.set({
					'user_id': profileId,
					'crm_id': profileId
				});
				this.$gtag.event('register_next');
				this.$refs.recaptcha.execute();
			}
		},
		async onVerify(recaptchaResponse) {
			const payload = {
				email: this.email,
				phone: this.phone,
				role: this.role,
				language: 'en', //todo: make dynamic
				recaptchaResponse,
				referredProfileId: this.referralId
			};
			try {
				await this.startRegistration(payload);
			} catch (err) {
				if (err.fields) {
					Object.entries(err.fields).forEach(([field, msg]) => {
						this.errors.add({
							field, msg
						});
					});
				}
				this.pushError(err.message);
				this.$refs?.recaptcha && this.$refs.recaptcha.reset();
			}

		},
		getReferralId() {
			function getCookie(cname) {
				let name = cname + '=';
				let decodedCookie = decodeURIComponent(document.cookie);
				let ca = decodedCookie.split(';');
				for(let i = 0; i <ca.length; i++) {
					let c = ca[i];
					while (c.charAt(0) == ' ') {
						c = c.substring(1);
					}
					if (c.indexOf(name) == 0) {
						return c.substring(name.length, c.length);
					}
				}
				return '';
			}
			return getCookie('referral_id');
		},
		selectRole(role) {
			this.setRole(role);
		}
	},

	mounted() {
		this.referralId = this.getReferralId() || '';
		this.logout();
	},
	created() {
		this.clearErrors();
	},
	provide() {
		return {
			$validator: this.$validator,
		};
	}
};
</script>

<style lang="scss" scoped>
.role-option {
	cursor: pointer;
	margin-bottom: 15px;
	
	&__box {
		background-color: #fff;
		padding: 15px;
		overflow: hidden;
		position: relative;
		transition: all .3s ease;
		height: 100%;
		display: flex;
		border: 1px solid var(--input-border);
		transition: all .3s ease;

		&--active {
			border: 1px solid var(--primary);

			.role-option__text {
				h3 {
					span.radio {
						border: 9px solid var(--primary);
					}
				}
			}
		}

		&:hover {
			box-shadow: 0 2px 7px #ddd;

			&:not(.role-option__box--active) {
				.role-option__text {
					h3 {
						span.radio {
							border: 3px solid var(--primary);
						}
					}
				}	
			}
		}
	}

	&__text {
		h3 {
			margin-bottom: 25px;
			display: flex;
			margin-bottom: 0;
			font-weight: 500;
			align-items: center;

			span.radio {
				margin-right: 20px;
				margin-left: 5px;
				display: block;
				width: 30px;
				height: 30px;
				border: 1px solid var(--input-border);
				border-radius: 9999px;
				transition: all .3s ease;
			}
		}

		p {
			padding-left: 55px;
			margin-bottom: 0;
			text-align: left;
		}
	}
}
.fade-enter-active,
.fade-leave-active {
	opacity: 1;
	transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

.input-error {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #c95353;
}
</style>