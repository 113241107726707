var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card-jwk-form"
  }, [_c('h2', [_vm._v("Create PIN code")]), _c('img', {
    attrs: {
      "src": "/images/icons/card-pin.svg",
      "alt": "Card PIN"
    }
  }), _vm._m(0), _c('label', {
    staticClass: "card-input-label"
  }, [!_vm.showRepeat ? _c('span', [_vm._v("Create PIN")]) : _vm._e(), _vm.showRepeat ? _c('span', [_vm._v("Repeat PIN")]) : _vm._e(), !_vm.showRepeat ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.data.pin,
      expression: "data.pin"
    }, {
      name: "validate",
      rawName: "v-validate",
      value: 'min:4|required',
      expression: "'min:4|required'"
    }],
    ref: "pin",
    "class": _vm.errors.has('pin') ? 'card-input-field-error' : '',
    attrs: {
      "type": "password",
      "name": "pin",
      "pattern": "\\d*",
      "maxlength": "4",
      "data-vv-validate-on": "blur",
      "placeholder": "Enter 4-digit PIN"
    },
    domProps: {
      "value": _vm.data.pin
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.data, "pin", $event.target.value);
      }
    }
  }) : _vm._e(), !_vm.showRepeat && _vm.errors.has('pin') ? _c('div', {
    staticClass: "card-input-error"
  }, [_vm._v(" " + _vm._s(_vm.errors.first('pin')) + " ")]) : _vm._e(), _vm.showRepeat ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.repeat,
      expression: "repeat"
    }, {
      name: "validate",
      rawName: "v-validate",
      value: 'min:4|required',
      expression: "'min:4|required'"
    }],
    ref: "repeat",
    "class": _vm.errors.has('repeat') ? 'card-input-field-error' : '',
    attrs: {
      "type": "password",
      "name": "repeat",
      "pattern": "\\d*",
      "maxlength": "4",
      "data-vv-validate-on": "blur",
      "placeholder": "Repeat 4-digit PIN"
    },
    domProps: {
      "value": _vm.repeat
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.repeat = $event.target.value;
      }
    }
  }) : _vm._e(), _vm.showRepeat && _vm.errors.has('repeat') ? _c('div', {
    staticClass: "card-input-error"
  }, [_vm._v(" " + _vm._s(_vm.errors.first('repeat')) + " ")]) : _vm._e()])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "mt-4 mb-4"
  }, [_vm._v(" Create a PIN code for your card."), _c('br'), _vm._v("Make sure to keep it secure. ")]);
}]

export { render, staticRenderFns }