import {
	REGISTER_LOADING,
	CLEAR_REGISTER_ERRORS,
	SET_REGISTER_ACCOUNT_ROLE,
	REGISTER_CHANGE_EMAIL,
	SET_REGISTER_DATA,
	UPDATE_LOGIN_INFO_PASSWORD,
	REGISTER_SUCCESS
} from '~/store/mutation-types';

import { endpoints } from '~/config';
import { twoFactorHelper } from '~/helpers/twofactor.helper';

export default {
	async startRegistration({ commit }, data) {
		commit(REGISTER_LOADING, true);
		const options = {
			method: 'POST',
			url: endpoints.register.register,
			data
		};
		try {
			const response = await axios(options);
			commit(REGISTER_SUCCESS, data);
			return Promise.resolve(response);
		} catch (err) {
			commit(REGISTER_LOADING, false);
			return Promise.reject(err);
		}
	},
	async resendEmail({ commit }, data) {
		commit(REGISTER_LOADING, true);
		const options = {
			method: 'POST',
			url: endpoints.register.resend,
			data
		};
		try {
			const response = await axios(options);
			commit(REGISTER_LOADING, false);
			return Promise.resolve(response);
		} catch (err) {
			commit(REGISTER_LOADING, false);
			return Promise.reject(err.message);
		}
	},
	async resendConfirm({ commit }, data) {
		commit(REGISTER_LOADING, true);
		const options = {
			method: 'POST',
			url: endpoints.register.init2fa({ hash: data.hash }),
			data: {
				provider: data.provider,
				clientId: 'frontend',
			}
		};
		try {
			const response = await axios(options);
			commit(REGISTER_LOADING, false);
			return Promise.resolve(response);
		} catch (err) {
			commit(REGISTER_LOADING, false);
			return Promise.reject(err.message);
		}
	},
	async verifyEmail({ commit }, hash) {
		const options = {
			method: 'GET',
			url: endpoints.register.verify({ hash })
		};
		try {
			const response = await axios(options);
			const { data } = response;
			commit(REGISTER_LOADING, false);
			commit(SET_REGISTER_DATA, data);
			return Promise.resolve(response);
		} catch (err) {
			commit(REGISTER_LOADING, false);
			return Promise.reject(err.message);
		}
	},
	async confirmRegistration({ commit, dispatch, state }, { code }) {
		commit(REGISTER_LOADING, true);
		const data = {
			clientId: 'frontend',
			password: state.password,
			confirmPassword: state.password,
			code
		};
		const hash = state.registerId;
		const options = {
			method: 'POST',
			url: endpoints.register.confirmRegister({ hash }),
			data
		};
		try {
			const response = await axios(options);
			const { data } = response;
			await dispatch('auth/setAuthorized', data, { root: true });
			commit(REGISTER_LOADING, false);
			twoFactorHelper.setAuthenticated(1);
			return Promise.resolve(response);
		} catch (err) {
			commit(REGISTER_LOADING, false);
			return Promise.reject(err.message);
		}
	},
	updateLoginPassword({ commit }, password) {
		commit(UPDATE_LOGIN_INFO_PASSWORD, password);
	},
	error: ({ dispatch }, payload) => {
		if (payload.error)
			dispatch('showErrorNotification', payload.error, { root: true });
	},
	clearErrors: ({ commit }) => {
		commit(CLEAR_REGISTER_ERRORS);
	},
	changeEmail: ({ commit }) => {
		commit(REGISTER_CHANGE_EMAIL);
	},
	submitSuccess: () => {
		return;
	},
	submitFailure: ({ dispatch }, body) => {
		if (body.error) {
			dispatch('showErrorNotification', body.error, { root: true });
		}
	},
	setRole: ({ commit }, role) => {
		commit(SET_REGISTER_ACCOUNT_ROLE, role);
	}
};
