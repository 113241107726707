var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "search-block transactions-search"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-4 mb-sm-0 mb-md-4 mb-xl-0",
    attrs: {
      "sm": "6",
      "md": "12",
      "lg": "12",
      "xl": "5"
    }
  }, [_c('div', {
    staticClass: "form-group mb-0"
  }, [_c('custom-select', {
    attrs: {
      "items": _vm.statuses,
      "hide-front": false,
      "modify-bg": true
    },
    on: {
      "select": function select($event) {
        return _vm.filterStatuses($event);
      }
    }
  })], 1)]), _c('b-col', {
    attrs: {
      "sm": "6",
      "md": "9",
      "lg": "8",
      "xl": "4"
    }
  }, [_c('date-range-select', {
    attrs: {
      "date-range": _vm.dateRange
    },
    on: {
      "dateRangeChanged": _vm.dateRangeChanged
    }
  })], 1), _c('b-col', {
    staticClass: "d-none d-md-block",
    attrs: {
      "cols": "3",
      "lg": "4",
      "xl": "3"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('p', {
    staticClass: "mr-3 mb-0 text-muted align-self-center text-nowrap items-per-page-label"
  }, [_vm._v(" Items "), _c('br'), _vm._v(" per page ")]), _c('custom-select', {
    attrs: {
      "items": _vm.pages,
      "hide-front": false,
      "modify-bg": true
    },
    on: {
      "select": function select($event) {
        return _vm.filterPages($event);
      }
    }
  })], 1)])], 1)], 1), _vm.total ? _c('div', {
    staticClass: "transactions"
  }, [_c('div', {
    staticClass: "transactions-list-wrapper"
  }, [_c('div', {
    staticClass: "transactions-list"
  }, _vm._l(_vm.list, function (tr, i) {
    return _c('payment-request-list-item', {
      key: tr.id,
      attrs: {
        "id": 'id' + i,
        "item": tr,
        "selected": _vm.selectedItem
      },
      on: {
        "select": function select(item) {
          return _vm.selectItem(item);
        }
      }
    });
  }), 1), _c('div', {
    staticClass: "d-none d-xl-block"
  }, [_c('payment-request-list-item-details', {
    attrs: {
      "item": _vm.selectedItem
    },
    on: {
      "close": function close() {
        return _vm.selectItem(null);
      }
    }
  })], 1)]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.limit * 1 < _vm.total,
      expression: "limit * 1 < total"
    }]
  }, [_c('pagination', {
    attrs: {
      "total": _vm.total,
      "per-page": _vm.limit,
      "current-page": _vm.currentPage
    },
    on: {
      "pagechanged": _vm.onPageChange
    }
  })], 1)]) : !_vm.listLoaded ? _c('div', {
    staticClass: "empty-list"
  }, [_c('preloader')], 1) : _c('div', {
    staticClass: "empty-list"
  }, [_c('NoPaymentRequests')], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }