var render = function () {
  var _vm$item$beneficiary, _vm$item$sender;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "transaction-item-container",
    "class": _vm.showCollapse ? 'expanded' : ''
  }, [_c('div', {
    staticClass: "transaction-item",
    "class": _vm.currentItem ? 'transaction-item--selected' : '',
    on: {
      "click": function click($event) {
        return _vm.selectItem(_vm.item);
      }
    }
  }, [_c('b-row', {
    staticClass: "align-items-center",
    attrs: {
      "align-h": "between"
    }
  }, [_c('b-col', [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "avatar d-flex align-items-center justify-content-center"
  }, [!['declined', 'failed', 'refund'].includes(_vm.item.publicStatus) ? _c('img', {
    attrs: {
      "src": _vm.item.direction === 'outgoing' ? 'images/img/outcome.svg' : 'images/img/income.svg'
    }
  }) : _vm._e(), ['declined', 'failed'].includes(_vm.item.publicStatus) ? _c('ion-icon', {
    staticStyle: {
      "color": "red"
    },
    attrs: {
      "name": "close-outline"
    }
  }) : _vm._e(), ['refund'].includes(_vm.item.publicStatus) ? _c('ion-icon', {
    attrs: {
      "name": "refresh-outline"
    }
  }) : _vm._e()], 1), _c('div', [_c('h4', [_vm._v(" " + _vm._s(_vm.item.direction === 'outgoing' ? (_vm$item$beneficiary = _vm.item.beneficiary) === null || _vm$item$beneficiary === void 0 ? void 0 : _vm$item$beneficiary.accountHolder : (_vm$item$sender = _vm.item.sender) === null || _vm$item$sender === void 0 ? void 0 : _vm$item$sender.accountHolder) + " ")]), _c('div', {
    staticClass: "date"
  }, [_vm._v(" " + _vm._s(_vm._f("timestampToLocalDateTime")(_vm.item.dateCreated)) + " ")])])])]), _c('b-col', {
    staticClass: "d-none d-xl-block"
  }, [_c('div', {
    staticClass: "summary"
  }, [_vm._v(" " + _vm._s(_vm.item.details) + " ")])]), _c('b-col', {
    staticClass: "text-right"
  }, [_vm.item.publicStatus === 'completed' ? _c('div', {
    staticClass: "amount",
    "class": _vm.setAmountClass()
  }, [_vm._v(" " + _vm._s(_vm.item.direction === 'outgoing' ? '-' : '+') + _vm._s(_vm._f("normalizeAmount")(_vm.item.amount)) + " " + _vm._s(_vm.item.currencyId.toUpperCase()) + " ")]) : _c('div', {
    staticClass: "amount",
    "class": {
      'amount--failed': ['declined', 'failed'].includes(_vm.item.publicStatus)
    }
  }, [_vm._v(" " + _vm._s(_vm._f("normalizeAmount")(_vm.item.amount)) + " " + _vm._s(_vm.item.currencyId.toUpperCase()) + " ")])])], 1), _vm._m(0)], 1)]), _vm.selected && _vm.selected.id == _vm.item.id ? _c('div', {
    staticClass: "d-block d-xl-none "
  }, [_c('transaction-list-item-details', {
    attrs: {
      "item": _vm.selected
    },
    on: {
      "close": function close($event) {
        return _vm.selectItem(null);
      }
    }
  })], 1) : _vm._e()]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "current-item-arrow d-none d-xl-flex"
  }, [_c('i', {
    staticClass: "fas fa-arrow-right"
  })]);
}]

export { render, staticRenderFns }