export default {
	registrationSteps: (state, getters, rootState, rootGetters) => {
		return (rootGetters['user/isPersonal']) ? state.personalSteps : state.businessSteps;
	},
	isRegistration: (state, getters, rootState, rootGetters) => {
		let steps = getters.registrationSteps;
		for (let step in steps) {
			if (step === rootGetters['routes/currentRouteName']) {
				return true;
			}
		}
		return false;
	},
	nextStep: (state, getters, rootState, rootGetters) => {
		let steps = Object.keys(getters.registrationSteps),
			currentStep = null;

		for (let key in steps) {
			if (currentStep) {
				return steps[key];
			}
			if (steps[key] === rootGetters['routes/currentRouteName']) {
				currentStep = steps[key];
			}
		}

		return 'home';
	}
};

