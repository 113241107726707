<template>
	<div>
		<form 
			action="#" 
			class="form-body margintop30" 
			@submit.prevent="onSubmit()"
		>
			<h2 class="page-title">
				Primary account information
			</h2>
			<fieldset :disabled="details.verification == 'pending'">
				<label class="registration-section-heading font-weight-bold mb-3">
					What is your occupation?
				</label>

				<div>
					<b-card v-for="(occupation, occupationIndex) in data.occupationList" 
						:key="occupationIndex"
						class="mb-4"
					>
						<div>
							<b-row>
								<b-col>
									<form-error-field :name="`occupationList[${occupationIndex}].value`"
										wrapper="b-row"
										mb="0"
									>
										<b-col
											v-for="(occupationItem, index) in options.occupationTypes"
											:key="occupationItem.value"
											sm="6"
											class="mb-2"
										>
											<b-form-radio
												v-model="occupation.value"
												v-validate="'required'"
												:name="`occupationList[${occupationIndex}].value`"
												:value="occupationItem.value"
												:data-registration="`occupation-${index}-${occupationIndex}`"
												@change="checkOccupationFields(occupationIndex)"
											>
												{{ occupationItem.name }}
											</b-form-radio>
										</b-col>
									</form-error-field>

									<form-error-field v-show="occupation.value === 'other'"
										:name="`occupationList[${occupationIndex}].otherValue`"
										class="pt-2"
									>
										<input
											id="occupation_other"
											v-model="occupation.otherValue"
											v-validate="requiredOtherOccupation(occupationIndex)"
											type="text"
											class="form-control"
											:name="`occupationList[${occupationIndex}].otherValue`"
											placeholder="Specify other occupation"
											maxlength="200"
											data-registration="occupation-other"
										>
									</form-error-field>
								</b-col>
							</b-row>
						</div>
						<div v-if="isRequiredCompanyActivity(occupationIndex)">
							<div>
								<b-row class="mt-4">
									<b-col>
										<div class="form-group mb-0">
											<label>
												Activity of the company/institution:
											</label>
										</div>
										<form-error-field :name="`occupationList[${occupationIndex}].companyActivities.values`"
											wrapper="b-row"
											mb="0"
										>
											<b-col
												v-for="(companyActivityItem, index) in options.companyActivityTypes"
												:key="companyActivityItem.value"
												sm="6"
												md="4"
												class="mb-2"
											>
												<b-form-checkbox
													v-model="occupation.companyActivities.values"
													v-validate="isRequiredCompanyActivity(occupationIndex) ?'required' : ''"
													:name="`occupationList[${occupationIndex}].companyActivities.values`"
													:value="companyActivityItem.value"
													:data-registration="`company-activity-${index}-${occupationIndex}`"
												>
													{{ companyActivityItem.name }}
												</b-form-checkbox>
											</b-col>
										</form-error-field>
										<form-error-field v-show="occupation.companyActivities.values.includes('other')"
											:name="`occupationList[${occupationIndex}].companyActivities.otherValue`"
										>
											<input
												id="company_activity_other"
												v-model="occupation.companyActivities.otherValue"
												v-validate="requiredOtherCompanyActivity(occupationIndex)"
												type="text"
												class="form-control"
												:name="`occupationList[${occupationIndex}].companyActivities.otherValue`"
												placeholder="Specify other company activity"
												maxlength="200"
												data-registration="company-activity-other"
											>
										</form-error-field>
									</b-col>
								</b-row>
							</div>
						</div>
						<div v-if="isRequiredCompanyPosition(occupationIndex)">
							<b-row class="mt-4">
								<b-col class="mb-4">
									<div class="form-group mb-0">
										<label>
											Your position in the Company:
										</label>
									</div>
									<form-error-field :name="`occupationList[${occupationIndex}].companyPosition.values`"
										wrapper="b-row"
										mb="0"
									>
										<b-col
											v-for="(companyPosition, index) in options.companyPositions"
											:key="companyPosition.value"
											sm="6"
											class="mb-2"
										>
											<b-form-radio
												v-model="occupation.companyPosition.value"
												v-validate="isRequiredCompanyPosition(occupationIndex) ? 'required' : ''"
												:name="`occupationList[${occupationIndex}].companyPosition.values`"
												:value="companyPosition.value"
												:data-registration="`position-${index}-${occupationIndex}`"
											>
												{{ companyPosition.name }}
											</b-form-radio>
										</b-col>
									</form-error-field>
									
									<form-error-field v-show="occupation.companyPosition.value === 'other'"
										:name="`occupationList[${occupationIndex}].companyPosition.otherValue`"
										class="pt-2"
									>
										<input
											id="occupation_other"
											v-model="occupation.companyPosition.otherValue"
											v-validate="requiredOtherPosition(occupationIndex)"
											type="text"
											class="form-control"
											:name="`occupationList[${occupationIndex}].companyPosition.otherValue`"
											placeholder="Specify other position"
											maxlength="200"
											data-registration="position-other"
										>
									</form-error-field>
								</b-col>
							</b-row>
							<b-row>
								<b-col>
									<div 
										v-show="errors.has(`position-${occupationIndex}`)" 
										class="errormsg"
									>
										<span>Please select your position in the Company</span>
									</div>
								</b-col>
							</b-row>
						</div>

						<div v-if="isRequiredInstitutionPosition(occupationIndex)">
							<div class="form-group mb-0">
								<label>
									Your position in the Institution:
								</label>
							</div>
							<div>
								<b-row>
									<b-col>
										<form-error-field :name="`occupationList[${occupationIndex}].institutionPosition`">
											<b-form-input
												id="position_of_the_institution"
												ref="autocomplete"
												v-model="occupation.institutionPosition"
												v-validate="'required'"
												type="text"
												:name="`occupationList[${occupationIndex}].institutionPosition`"
												placeholder="Position of the institution"
												data-registration="institution-position"
											/>
										</form-error-field>
									</b-col>
								</b-row>
							</div>
						</div>
						<div v-if="isRequiredCompanyName(occupationIndex)">
							<div class="form-group mb-0">
								<label>
									Name of the company/institution:
								</label>
							</div>
							<div>
								<b-row>
									<b-col>
										<form-error-field :name="`occupationList[${occupationIndex}].institutionName`">
											<b-form-input
												id="name_of_company"
												ref="autocomplete"
												v-model="occupation.institutionName"
												v-validate="'required'"
												type="text"
												:name="`occupationList[${occupationIndex}].institutionName`"
												placeholder="Name of the company/institution"
												data-registration="company-name"
											/>
										</form-error-field>
									</b-col>
								</b-row>
							</div>
						</div>
						<div v-if="occupationIndex > 0"
							class="occupation-remove-button mt-0"
						>
							<b-button variant="danger"
								@click="() => removeOccupation(occupationIndex)"
							>
								✕ Remove
							</b-button>
						</div>
					</b-card>
					<b-button variant="primary"
						class="mb-4"
						:disabled="!data.occupationList[0].value"
						@click="addOccupation"
					>
						Add extra occupation
					</b-button>
				</div>
				<div>
					<b-row>
						<b-col>
							<form-error-field class="text-left mb-12"
								name="sourceOfIncome.values"
							>
								<label>
									Source of income:
								</label>
								<v-multiselect 
									v-model="data.sourceOfIncome.values"
									v-validate="'required'"
									:options="options.sourceOfIncome"
									:multiple="true"
									:close-on-select="false"
									:clear-on-select="false"
									:preserve-search="true"
									:preselect-first="false"
									placeholder="Make selection"
									name="sourceOfIncome.values"
									label="name"
									track-by="name"
									return="value"
									data-registration="source-of-income"
								/>
							</form-error-field>
							<form-error-field v-show="data.sourceOfIncome.values.includes('other')"
								class="text-left mb-12"
								name="sourceOfIncome.otherValue"
							>
								<input
									id="sourceOfIncome.otherValue"
									v-model="data.sourceOfIncome.otherValue"
									v-validate="data.sourceOfIncome.values.includes('other') ? 'required' : ''"
									type="text"
									class="form-control"
									name="sourceOfIncome.otherValue"
									placeholder="Specify source of income"
									maxlength="200"
									data-registration="purpose-other"
								>
							</form-error-field>
						</b-col>
					</b-row>
				</div>
				<div>
					<b-row>
						<b-col>
							<form-error-field class="text-left mb-12"
								name="accountOpeningPurposes.values"
							>
								<label>
									What is the purpose of the account opening?
								</label>
								<v-multiselect 
									v-model="data.accountOpeningPurposes.values"
									v-validate="'required'"
									:options="options.purposeForOpeningTypes"
									:multiple="true"
									:close-on-select="false"
									:clear-on-select="false"
									:preserve-search="true"
									:preselect-first="false"
									placeholder="Make selection"
									name="accountOpeningPurposes.values"
									label="name"
									track-by="name"
									return="value"
									data-registration="purpose"
								/>
							</form-error-field>
							<form-error-field v-show="data.accountOpeningPurposes.values.includes('other')"
								class="text-left mb-12"
								name="accountOpeningPurposes.otherValue"
							>
								<input
									id="accountOpeningPurposes.otherValue"
									v-model="data.accountOpeningPurposes.otherValue"
									v-validate="requiredOtherPurposeForOpening"
									type="text"
									class="form-control"
									name="accountOpeningPurposes.otherValue"
									placeholder="Specify purpose for opening"
									maxlength="200"
									data-registration="purpose-other"
								>
							</form-error-field>
						</b-col>
					</b-row>
				</div>
				<div>
					<b-row>
						<b-col>
							<form-error-field name="turnovers.amount.incomingMonthly">
								<label>
									Monthly planned turnover, EUR (incoming transactions)
								</label>
								<b-form-input
									v-model="data.turnovers.amount.incomingMonthly"
									v-validate="'required'"
									data-vv-as="Monthly planned turnover"
									name="turnovers.amount.incomingMonthly"
									type="number"
									min="1"
									max="2147483647"
									placeholder="Enter Your planned monthly turnover"
									data-registration="planned-incoming-turnover"
								/>
							</form-error-field>
						</b-col>
					</b-row>
				</div>
				<div>
					<b-row>
						<b-col>
							<form-error-field name="turnovers.amount.outgoingMonthly">
								<label>
									Monthly planned turnover, EUR (outgoing transactions)
								</label>
								<b-form-input
									v-model="data.turnovers.amount.outgoingMonthly"
									v-validate="'required'"
									data-vv-as="Monthly planned turnover, EUR"
									name="turnovers.amount.outgoingMonthly"
									type="number"
									min="1"
									max="2147483647"
									placeholder="Enter Your planned monthly turnover"
									data-registration="planned-outgoing-turnover"
								/>
							</form-error-field>
						</b-col>
					</b-row>
				</div>
				<div>
					<b-row>
						<b-col>
							<form-error-field name="turnovers.cash.outgoingMonthly">
								<label>
									The sum of monthly planned cash withdrawals, EUR
								</label>
								<b-form-input
									v-model="data.turnovers.cash.outgoingMonthly"
									v-validate="'required'"
									data-vv-as="The sum of monthly planned cash withdrawals, EUR"
									name="planned_monthly_cash"
									type="number"
									min="0"
									max="2147483647"
									placeholder="Enter Your planned monthly cash withdrawals"
									data-registration="planned-cash"
								/>
							</form-error-field>
						</b-col>
					</b-row>
				</div>

				<div>
					<b-row>
						<b-col>
							<form-error-field name="turnovers.count.incomingMonthly">
								<label>
									Monthly planned number of incoming transactions
								</label>
								<b-form-input
									v-model="data.turnovers.count.incomingMonthly"
									v-validate="'required'"
									data-vv-as="Monthly planned number of incoming transactions"
									name="turnovers.count.incomingMonthly"
									type="number"
									min="1"
									max="2147483647"
									placeholder="Enter Your planned monthly turnover"
									data-registration="planned-incoming-transactions"
								/>
							</form-error-field>
						</b-col>
					</b-row>
				</div>

				<div>
					<b-row>
						<b-col>
							<form-error-field name="turnovers.count.outgoingMonthly">
								<label>
									Monthly planned number of outgoing transactions
								</label>
								<b-form-input
									v-model="data.turnovers.count.outgoingMonthly"
									v-validate="'required'"
									data-vv-as="Monthly planned number of outgoing transactions"
									name="turnovers.count.outgoingMonthly"
									type="number"
									min="1"
									max="2147483647"
									placeholder="Enter Your planned monthly turnover"
									data-registration="planned-outgoing-transactions"
								/>
							</form-error-field>
						</b-col>
					</b-row>
				</div>


				<div>
					<b-row>
						<b-col>
							<form-error-field name="countriesFundsOriginateFrom"
								class="text-left mb-12"
							>
								<label>
									Countries, where funds are received from
								</label>
								<v-multiselect 
									v-model="data.countriesFundsOriginateFrom"
									v-validate="'required'"
									:options="countryOptions"
									:multiple="true"
									:close-on-select="false"
									:clear-on-select="false"
									:preserve-search="true"
									:preselect-first="false"
									name="countriesFundsOriginateFrom"
									placeholder="Make selection"
									label="name"
									track-by="name"
									return="countryId"
									data-registration="origin-country"
								/>
							</form-error-field>
						</b-col>
					</b-row>
				</div>

				<div>
					<b-row>
						<b-col>
							<form-error-field name="countriesFundsOriginateTo"
								class="text-left mb-12"
							>
								<label>Countries, where funds are sent to</label>

								<v-multiselect 
									v-model="data.countriesFundsOriginateTo"
									v-validate="'required'"
									:options="countryOptions"
									:multiple="true"
									name="countriesFundsOriginateTo"
									:close-on-select="false"
									:clear-on-select="false"
									:preserve-search="true"
									:preselect-first="false"
									placeholder="Make selection"
									label="name"
									track-by="name"
									return="countryId"
									data-registration="destination-country"
								/>
							</form-error-field>
						</b-col>
					</b-row>
				</div>
				<div>
					<b-row>
						<b-col class="mb-4">
							<form-error-field name="typeOfPayments"
								wrapper="div"
								mb="0"
							>
								<div class="form-group mb-0">
									<label>What type of payments are you planning to make?</label>
								</div>
								<b-input-group
									v-for="paymentType in options.serviceToUseOptionTypes"
									:key="paymentType.value"
									class="radioblock text-left mb-12"
									data-registration="planned-payment-types"
								>
									<b-form-checkbox
										v-model="data.typeOfPayments"
										v-validate="'required'"
										name="typeOfPayments"
										:value="paymentType.value"
									>
										{{ paymentType.name }}
									</b-form-checkbox>
								</b-input-group>
							</form-error-field>
						</b-col>
					</b-row>
				</div>

				<div class="form-group mb-0">
					<label>Accounts in other banks or payment/electronic money institutions</label>
				</div>
				<div 
					v-for="(bank, index) in data.otherBankList" 
					:key="`bank-${index}`"
				>
					<b-row>
						<b-col sm="6">
							<form-error-field :name="`otherBankList[${index}][name]`">
								<label class="label">Name of institution</label>
								<b-form-input
									:id="bank.nameId"
									v-model="bank.name"
									v-validate="'required'"
									:name="`otherBankList[${index}][name]`"
									type="text"
									autocomplete="off_name"
									placeholder="Enter name of institution"
									:data-registration="`bank-name-${index}`"
								/>
							</form-error-field>
						</b-col>
						<b-col sm="6">
							<form-error-field :name="`otherBankList[${index}][countryId]`">
								<label class="label">Country of institution</label>
								<v-multiselect
									v-model="bank.countryId"
									v-validate="'required'"
									:name="`otherBankList[${index}][countryId]`"
									:options="countryOptions"
									:show-labels="false"
									:allow-empty="true"
									label="name"
									placeholder="Select country"
									track-by="countryId"
									return="countryId"
									:data-registration="`bank-country-${index}`"
								/>
							</form-error-field>
						</b-col>
					</b-row>
				</div>

				<div 
					class="btn btn-dark btn-sm px-5" 
					data-registration="add-bank"
					@click="addBankInput()"
				>
					Add
				</div>
				<div 
					v-if="data.otherBankList?.length > 0"
					class="btn btn-outline-danger btn-sm px-3 ml-2" 
					data-registration="remove-bank"
					@click="removeBankInput()"
				>
					Remove
				</div>

				<div class="mt-4">
					<b-row>
						<b-col>
							<form-error-field 
								name="politicalExposure.value"
							>
								<label>Political exposure</label>

								<v-multiselect
									v-model="data.politicalExposure.value"
									v-validate="'required'"
									:options="options.politicalExposureTypes"
									:show-labels="false"
									:allow-empty="false"
									name="politicalExposure.value"
									label="name"
									placeholder="Select Your political exposure"
									track-by="name"
									return="value"
									data-registration="political-exposure"
								/>
							</form-error-field>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<form-error-field v-show="['family_member', 'associate'].includes(data.politicalExposure.value)"
								name="politicalExposure.name"
								class="text-left mb-12"
							>
								<input
									id="political_exposure_name"
									v-model="data.politicalExposure.name"
									v-validate="requiredPoliticalExposureName"
									type="text"
									class="form-control"
									name="politicalExposure.name"
									placeholder="Specify full name"
									data-registration="political-exposure-name"
								>
							</form-error-field>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<form-error-field v-show="['family_member', 'associate', 'exposed'].includes(data.politicalExposure.value)"
								name="politicalExposure.position"
								class="text-left mb-12"
							>
								<input
									id="political_exposure_position"
									v-model="data.politicalExposure.position"
									v-validate="requiredPoliticalExposurePosition"
									type="text"
									class="form-control"
									name="politicalExposure.position"
									placeholder="Specify position"
									data-registration="political-exposure-position"
								>
							</form-error-field>
						</b-col>
					</b-row>
				</div>

				<div>
					<div class="form-group mb-0">
						<label>
							I confirm that I am the ultimate beneficial owner of the account
						</label>
					</div>
					<b-row>
						<b-col>
							<form-error-field name="isBeneficialOwner"
								wrapper="div"
								mb="0"
							>
								<b-input-group class="radioblock text-left mb-12">
									<b-form-checkbox
										v-model="data.isBeneficialOwner"
										name="isBeneficialOwner"
										value="yes"
										data-registration="account-owner-yes"
									>
										Yes
									</b-form-checkbox>
								</b-input-group>

								<b-input-group class="radioblock text-left mb-12">
									<b-form-checkbox
										v-model="data.isBeneficialOwner"
										name="isBeneficialOwner"
										value="no"
										data-registration="account-owner-no"
									>
										No
									</b-form-checkbox>
								</b-input-group>
							</form-error-field>
						</b-col>
					</b-row>
				</div>
			</fieldset>
			
			<div>
				<b-row>
					<b-col class="mt-4 mb-4  text-center">
						<notification />
						<input 
							:class="buttonClass" 
							:value="buttonText" 
							:disabled="nextEnabled == false"
							type="submit" 
							class="btn btn-primary btn-rounded btn-upper w-100"
							data-registration="next"
						/>
					</b-col>
				</b-row>
				<b-row v-if="allowSkip">
					<b-col class="text-center">
						<router-link 
							:to="{name: 'home'}" 
							tag="a" 
							class="framelink skiplink" 
							exact
							data-registration="skip"
						>
							<span v-if="details.verification !== 'pending'">Skip verification</span>
							<span v-if="details.verification == 'pending'">Back to main page</span>
						</router-link>
					</b-col>
				</b-row>
			</div>
		</form>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import config from '~/config';
import options from './QuestionnairePersonOptions';
import { handleApiFormErrors } from '~/helpers';
import FormErrorField from '@/shared/Form/FormErrorField';

export default {
	name: 'PersonQuestionnaireForm',
	components: {
		FormErrorField
	},
	props: {
		buttonClass: {
			type: String,
			default: ''
		},
		buttonText: {
			type: String,
			default: 'Next'
		},
		allowSkip: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			options,
			nextEnabled: true,
			data: {
				occupationList: [
					{
						value: '',
						otherValue: '',
						institutionName: '',
						institutionPosition: '',
						companyActivities: {
							values: [],
							otherValue: ''
						},
						companyPosition: {
							value: '',
							otherValue: ''
						},
					}
				],
				sourceOfIncome: {
					values: [],
					otherValue: ''
				},
				accountOpeningPurposes: {
					values: [],
					otherValue: ''
				},
				turnovers: {
					amount: {
						incomingMonthly: '',
						outgoingMonthly: ''
					},
					count: {
						incomingMonthly: '',
						outgoingMonthly: ''
					},
					cash: {
						outgoingMonthly: ''
					}
				},
				countriesFundsOriginateFrom: [],
				countriesFundsOriginateTo: [],
				typeOfPayments: [],
				otherBankList: [
				],
				politicalExposure: {
					value: '',
					position: '',
					name: ''
				},
				isBeneficialOwner: false
			}, // set from $store on created() event

			errorCheckBoxesPurpose: false,
			errorCheckBoxesReceivedMoneyCountries: false,
			errorCheckBoxesSendMoneyCountries: false,
			errorCheckBoxesBeneficialOwner: false,
			errorCheckBoxesAgreements: false,
			agreementUrl: config.agreementUrl
		};
	},
	computed: {
		...mapState('countries', {
			countryOptions: state => state.list
		}),
		...mapState('user', {
			questionnaire: state => state.selected?.userProfile?.questionnaire,
			details: (state) => state.selected
		}),
		...mapGetters('user', ['isPersonal', 'isBusiness', 'owner']),
		requiredOtherPurposeForOpening() {
			return this.data.accountOpeningPurposes.values.includes('other') ? 'required' : '';
		},
		requiredPoliticalExposurePosition() {
			return this.data.politicalExposure.value === 'exposed'
				|| this.data.politicalExposure.value === 'family_member'
				|| this.data.politicalExposure.value === 'associate'
				? 'required' : '';
		},
		requiredPoliticalExposureName() {
			return this.data.politicalExposure.value === 'family_member'
				|| this.data.politicalExposure.value === 'associate'
				? 'required' : '';
		},
	},
	watch: {
		data: {
			handler(value) {
				this.updateQuestionnaire(value);
			},
			deep: true
		},
	},
	async mounted() {
		this.$validator.pause();
		await this.getCountries();
		this.$gtag.event(`register_verification_questionnaire_${this.isBusiness ? 'business' : 'personal'}`);
		this.data = { 
			...this.data, 
			...this.questionnaire 
		};
		//Need to fix this array below
		if (this.data.occupationList.length == 0) {
			this.addOccupation();
		}
		this.$nextTick(() => this.$validator.resume());
	},
	methods: {
		...mapActions('countries', ['getCountries']),
		...mapActions('user', [
			'updateQuestionnaire',
			'submitUserQuestionnaireData'
		]),
		...mapActions('notifications', [
			'pushError'
		]),
		requiredOtherOccupation(i) {
			return this.data.occupationList[i].value === 'other' ? 'required' : '';
		},
		requiredOtherPosition(i) {
			return this.data.occupationList[i].companyPosition.value === 'other' ? 'required' : '';
		},
		requiredOtherCompanyActivity(i) {
			return this.data.occupationList[i].companyActivities.values.includes('other') ? 'required' : '';
		},
		isRequiredCompanyActivity(i) {
			const value = this.data.occupationList[i].value;
			const option = this.options.occupationTypes.find(type => type.value == value);
			return option?.companyActivity && this.data.occupationList[i].companyActivities?.values;
		},
		isRequiredCompanyPosition(i) {
			const value = this.data.occupationList[i].value;
			const option = this.options.occupationTypes.find(type => type.value == value);
			return option?.companyPosition && typeof this.data.occupationList[i].companyPosition?.value !== 'undefined';
		},
		isRequiredInstitutionPosition(i) {
			const value = this.data.occupationList[i].value;
			const option = this.options.occupationTypes.find(type => type.value == value);
			return option?.institutionPosition;
		},
		isRequiredCompanyName(i) {
			const value = this.data.occupationList[i].value;
			const option = this.options.occupationTypes.find(type => type.value == value);
			return option?.companyName;
		},
		checkOccupationFields(index) {
			this.$set(this.data.occupationList[index], 'companyActivities', { values: [], otherValue: '' });
			this.$set(this.data.occupationList[index], 'institutionName', '');
			this.$set(this.data.occupationList[index], 'companyPosition', { value: '', otherValue: '' });
			this.$set(this.data.occupationList[index], 'institutionPosition', '');
		},
		addOccupation() {
			this.data.occupationList.push({
				value: '',
				otherValue: '',
			});
		},
		removeOccupation(index) {
			if (confirm('Do you really want to remove?')) {
				this.data.occupationList.splice(index, 1);
			}
		},
		addBankInput() {
			if (this.details.verification == 'pending') return;

			this.data.otherBankList.push({
				name: null,
				countryId: null,
			});
		},
		removeBankInput() {
			if (this.details.verification == 'pending') return;
			this.data.otherBankList.splice(-1, 1);
		},
		async onSubmit() {
			if (this.details.verification == 'pending') {
				this.$router.push({ name: 'home' });
				return;
			}

			this.errorCheckBoxesPurpose = this.data.accountOpeningPurposes.values.length === 0;
			this.errorCheckBoxesReceivedMoneyCountries = this.data.countriesFundsOriginateFrom.length === 0;
			this.errorCheckBoxesSendMoneyCountries = this.data.countriesFundsOriginateTo.length === 0;
			this.errorCheckBoxesBeneficialOwner = this.data.isBeneficialOwner == false;
			this.errorCheckBoxesAgreements = parseInt(this.data.agreementsAccepted) === 0;

			const result = await this.$validator.validateAll();

			if (this.errorCheckBoxesBeneficialOwner) {
				this.pushError('Please check the red fields');
				return;
			}

			if (result) {
				this.nextEnabled = false;
				try {
					this.data.occupationList.forEach((item, index) => {
						if (!this.isRequiredCompanyActivity(index)) {
							delete item.companyActivities;
						}

						if (!this.isRequiredCompanyPosition(index)) {
							delete item.companyPosition;
						}

						if (!this.isRequiredInstitutionPosition(index)) {
							delete item.institutionPosition;
						}

						if (!this.isRequiredCompanyName(index)) {
							delete item.institutionName;
						}
					});

					await this.submitUserQuestionnaireData(this.data);
					this.nextEnabled = true;

					return this.$router.push({ name: 'verify-photo' });

				} catch (err) {
					this.nextEnabled = true;
					handleApiFormErrors(err, this.errors);
					return this.pushError(err.message);
				}
			}
			this.pushError('Please check the red fields');
		},
		handleError(error) {
			this.nextEnabled = true;
			this.pushError(error);
		},
	},
	provide() {
		return {
			$validator: this.$validator,
		};
	}
};
</script>
<style lang="scss" scoped>
	@import "./QuestionnairePerson.scss";
</style>
