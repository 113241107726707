var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "news-homepage"
  }, [_vm.shownItems.length > 0 ? _c('h3', {
    staticClass: "mb-4"
  }, [_vm._v(" Latest updates ")]) : _vm._e(), _vm._l(_vm.shownItems, function (item, i) {
    return _c('div', {
      key: i,
      staticClass: "news-item my-3",
      "class": {
        'news-item--clickable': item.link || item.action
      },
      style: {
        'background-color': item.color && (item.link || item.action) ? item.color : '',
        'border-color': item.color ? item.color : '',
        'color': item.color && item.link ? item.textColor || '#000' : item.textColor || ''
      },
      on: {
        "click": function click() {
          return item.link ? _vm.$router.push(item.link) : item.action ? item.action() : null;
        }
      }
    }, [item.icon ? _c('span', {
      staticClass: "news-item__icon",
      domProps: {
        "innerHTML": _vm._s(item.icon)
      }
    }) : _vm._e(), _c('span', {
      staticClass: "news-item__text",
      domProps: {
        "innerHTML": _vm._s(item.html)
      }
    }), !item.keep ? _c('span', {
      staticClass: "news-item__close",
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.hide(item);
        }
      }
    }, [_c('i', {
      staticClass: "fas fa-times"
    })]) : _vm._e()]);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }