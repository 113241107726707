var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "recovery-codes"
  }, [_vm.loading ? _c('div', [_c('b-spinner')], 1) : _c('div', _vm._l(_vm.codes, function (code) {
    return _c('strong', {
      key: code
    }, [_vm._v(_vm._s(code)), _c('br')]);
  }), 0)]), _c('div', {
    staticClass: "mt-3"
  }, [_c('b-button', {
    staticClass: "mx-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.saveToFile(_vm.codes.reduce(function (a, b) {
          return "".concat(a, "\n").concat(b);
        }), 'Recovery Codes');
      }
    }
  }, [_c('i', {
    staticClass: "fas fa-download mr-2"
  }), _vm._v(" Download ")])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }