<template>
	<div class="account-details ">
		<div 
			v-if="data.address"
			id="profile-business-registration-address" 
			class="account-details profile-business-registration-address"
		>
			<h3 class="mb-4 mb-lg-5 font-weight-bold">
				Business Registration address
			</h3>

			<div 
				v-if="data.addresses.main.address" 
				class="form-group"
			>
				<div class="row align-items-center">
					<label class="col-6 col-sm-5 col-xl-4 col-form-label">Address</label>
					<div class="col-6 col-sm-7 col-xl-6">
						<strong>{{ data.addresses.main.address }}</strong>
					</div>
				</div>
			</div>
			<div 
				v-if="data.addresses.main.city" 
				class="form-group"
			>
				<div class="row align-items-center">
					<label class="col-6 col-sm-5 col-xl-4 col-form-label">City</label>
					<div class="col-6 col-sm-7 col-xl-6">
						<strong>{{ data.addresses.main.city }}</strong>
					</div>
				</div>
			</div>
			<div 
				v-if="data.addresses.main.state" 
				class="form-group"
			>
				<div class="row align-items-center">
					<label class="col-6 col-sm-5 col-xl-4 col-form-label">State</label>
					<div class="col-6 col-sm-7 col-xl-6">
						<strong>{{ data.addresses.main.state }}</strong>
					</div>
				</div>
			</div>
			<div 
				v-if="data.addresses.main.countryId" 
				class="form-group"
			>
				<div class="row align-items-center">
					<label class="col-6 col-sm-5 col-xl-4 col-form-label">Country</label>
					<div class="col-6 col-sm-7 col-xl-6">
						<strong class="text-uppercase">{{ data.addresses.main.countryId }}</strong>
					</div>
				</div>
			</div>
			<div 
				v-if="data.addresses.main.zipCode" 
				class="form-group"
			>
				<div class="row align-items-center">
					<label class="col-6 col-sm-5 col-xl-4 col-form-label">ZIP code</label>
					<div class="col-6 col-sm-7 col-xl-6">
						<strong>{{ data.addresses.main.zipCode }}</strong>
					</div>
				</div>
			</div>
		</div>
		<div 
			v-if="data.addresses.correspondence"
			id="profile-business-correspondence-address" 
			class="account-details profile-business-correspondence-address"
		>
			<h3 class="mb-4 mb-lg-5 font-weight-bold">
				Business Correspondence address
			</h3>
			<div 
				v-if="data.addresses.correspondence.address" 
				class="form-group"
			>
				<div class="row align-items-center">
					<label class="col-6 col-sm-5 col-xl-4 col-form-label">Address</label>
					<div class="col-6 col-sm-7 col-xl-6">
						<strong>{{ data.addresses.correspondence.address }}</strong>
					</div>
				</div>
			</div>
			<div 
				v-if="data.addresses.correspondence.city" 
				class="form-group"
			>
				<div class="row form-group align-items-center">
					<label class="col-6 col-sm-5 col-xl-4 col-form-label">City</label>
					<div class="col-6 col-sm-7 col-xl-6">
						<strong>{{ data.addresses.correspondence.city }}</strong>
					</div>
				</div>
			</div>
			<div 
				v-if="data.addresses.correspondence.state" 
				class="form-group"
			>
				<div class="row align-items-center">
					<label class="col-6 col-sm-5 col-xl-4 col-form-label">State</label>
					<div class="col-6 col-sm-7 col-xl-6">
						<strong>{{ data.addresses.correspondence.state }}</strong>
					</div>
				</div>
			</div>
			<div 
				v-if="data.addresses.correspondence.countryId" 
				class="form-group"
			>
				<div class="row align-items-center">
					<label class="col-6 col-sm-5 col-xl-4 col-form-label">Country</label>
					<div class="col-6 col-sm-7 col-xl-6">
						<strong class="text-uppercase">{{ data.addresses.correspondence.countryId }}</strong>
					</div>
				</div>
			</div>
			<div 
				v-if="data.addresses.correspondence.zipCode" 
				class="form-group"
			>
				<div class="row align-items-center">
					<label class="col-6 col-sm-5 col-xl-4 col-form-label">ZIP code</label>
					<div class="col-6 col-sm-7 col-xl-6">
						<strong>{{ data.addresses.correspondence.zipCode }}</strong>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'ProfileBusinessAddresses',
	props: {
		data: {
			type: Object,
			default: () => {}
		}
	},
};
</script>
