import LayoutDashboard from '@/LayoutDashboard/LayoutDashboard.vue';

import Dashboard from '@/LayoutDashboard/Home/Dashboard.vue';
import TransferLayout from '@/LayoutDashboard/Transfer/TransferLayout.vue';
import TransactionTableLayout from '@/LayoutDashboard/Transaction/TransactionTableLayout.vue';
import Log from '@/LayoutDashboard/Log/ActivityTableLayout.vue';
import ProfileLayout from '@/LayoutDashboard/Profile/ProfileLayout.vue';
import FaqLayout from '@/LayoutDashboard/Faq/FaqLayout.vue';
import FaqGettingStarted from '@/LayoutDashboard/Faq/FaqGettingStarted.vue';
import FaqProfile from '@/LayoutDashboard/Faq/FaqProfile.vue';
import FaqAccount from '@/LayoutDashboard/Faq/FaqAccount.vue';
import FaqTopUp from '@/LayoutDashboard/Faq/FaqTopUp.vue';
import FaqSecurity from '@/LayoutDashboard/Faq/FaqSecurity.vue';
import FaqBankTransfer from '@/LayoutDashboard/Faq/FaqBankTransfer.vue';
import Idenfy from '@/LayoutDashboard/Idenfy/Idenfy';
import ChangePassword from '@/LayoutDashboard/Profile/ProfilePasswordChange';
import AccountSettings from '@/LayoutDashboard/AccountSettings/AccountSettings';
import AuthenticatorSettings from '@/LayoutDashboard/AuthenticatorSettings/AuthenticatorSettings';
import Referrals from '@/LayoutDashboard/Referrals/Referrals';
import CardsTemplate from '@/LayoutDashboard/Cards/CardsTemplate';
import CardSettings from '@/LayoutDashboard/Cards/CardSettings';
import CardOrder from '@/LayoutDashboard/Cards/CardOrder';
import CardOrderType from '@/LayoutDashboard/Cards/CardOrderType';
import CardList from '@/LayoutDashboard/Cards/CardList';
import PaymentRequestTableLayout from '@/LayoutDashboard/PaymentRequest/PaymentRequestTableLayout';
import PaymentRequestLayout from '@/LayoutDashboard/PaymentRequestCreate/PaymentRequestLayout';
import ProductLayout from '@/LayoutDashboard/ProductCreate/ProductLayout';
import ProductTableLayout from '@/LayoutDashboard/Product/ProductTableLayout';

export default {
	path: '/',
	redirect: '/home',
	name: 'index',
	component: LayoutDashboard,
	meta: {
		requiresAuth: true
	},
	children: [
		{
			path: '/home',
			name: 'home',
			component: Dashboard,
		},
		{
			path: '/idenfy',
			name: 'idenfy',
			component: Idenfy,
			meta: {
				requiresDashboardAllowance: true
			},
		},
		{
			path: '/faq',
			name: 'faq',
			redirect: '/faq/gettingstarted',
			component: FaqLayout,
			props: true,
			children: [
				{
					path: 'gettingstarted',
					component: FaqGettingStarted,
					meta: {
						requiresDashboardAllowance: true
					},
					props: true
				},
				{
					path: 'profile',
					meta: {
						requiresDashboardAllowance: true
					},
					component: FaqProfile
				},
				{
					path: 'account',
					meta: {
						requiresDashboardAllowance: true
					},
					component: FaqAccount
				},
				{
					path: 'topup',
					meta: {
						requiresDashboardAllowance: true
					},
					component: FaqTopUp
				},
				{
					path: 'security',
					meta: {
						requiresDashboardAllowance: true
					},
					component: FaqSecurity
				},
				{
					path: 'banktransfer',
					meta: {
						requiresDashboardAllowance: true
					},
					component: FaqBankTransfer
				},
			]
		},
		{
			path: '/transactions',
			name: 'transactions',
			meta: {
				requiresDashboardAllowance: true
			},
			component: TransactionTableLayout
		},
		{
			path: '/payment-requests',
			name: 'payment-requests',
			meta: {
				requiresDashboardAllowance: true
			},
			component: PaymentRequestTableLayout,
		},
		{
			path: '/payment-request-create',
			name: 'payment-request-create',
			meta: {
				requiresDashboardAllowance: true
			},
			component: PaymentRequestLayout,
		},
		{
			path: '/product-create',
			name: 'product-create',
			meta: {
				requiresDashboardAllowance: true
			},
			component: ProductLayout,
		},
		{
			path: '/products',
			name: 'products',
			meta: {
				requiresDashboardAllowance: true
			},
			component: ProductTableLayout,
		},
		{
			path: '/profile',
			name: 'profile',
			meta: {
				requiresDashboardAllowance: true
			},
			component: ProfileLayout
		},

		{
			path: '/profile-activity',
			name: 'log',
			meta: {
				requiresDashboardAllowance: true
			},
			component: Log
		},
		{
			path: '/cards',
			name: 'cards',
			meta: {
				requiresDashboardAllowance: true
			},
			component: CardsTemplate,
			children: [
				{
					path: 'new',
					name: 'new-card',
					meta: {
						requiresDashboardAllowance: true
					},
					component: CardOrder,
				},
				{
					path: 'list',
					name: 'cards-list',
					props: true,
					meta: {
						requiresDashboardAllowance: true
					},
					component: CardList
				},
				{
					path: 'view/:cardId',
					name: 'view-card',
					props: true,
					meta: {
						requiresDashboardAllowance: true
					},
					component: CardSettings
				},
				{
					path: 'new/virtual',
					name: 'new-virtual-card',
					meta: {
						requiresDashboardAllowance: true
					},
					component: CardOrderType
				},
				{
					path: 'new/plastic',
					name: 'new-plastic-card',
					meta: {
						requiresDashboardAllowance: true
					},
					component: CardOrderType
				}
			]
		},
		{
			path: '/transfer',
			name: 'transfer',
			props: true,
			meta: {
				requiresDashboardAllowance: true
			},
			component: TransferLayout,
		},
		{
			path: '/transfer/:transferType',
			name: 'transfer-type',
			props: true,
			meta: {
				requiresDashboardAllowance: true
			},
			component: TransferLayout,
		},
		{
			path: 'change-password',
			name: 'change-password',
			component: ChangePassword,
			meta: {
				requiresGuest: true
			}
		}, 
		{
			path: 'account-settings',
			name: 'profile-accounts-page',
			component: AccountSettings,
			meta: {
				requiresGuest: true
			}
		},
		{
			path: 'referrals',
			name: 'referrals',
			component: Referrals,
			meta: {
				requiresGuest: true
			}
		},
		{
			path: 'authenticator-settings',
			name: 'authenticator-settings',
			component: AuthenticatorSettings,
			meta: {
				requiresDashboardAllowance: true
			},
		}
	]
};