var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.register($event);
      }
    }
  }, [_c('div', [_c('b-row', {
    staticClass: "mb-4",
    attrs: {
      "align-v": "stretch"
    }
  }, _vm._l(_vm.roles, function (_ref) {
    var title = _ref.title,
        value = _ref.value,
        description = _ref.description;
    return _c('b-col', {
      key: value,
      staticClass: "role-option",
      attrs: {
        "sm": "6",
        "cols": "12",
        "data-registration": "use-of-account"
      },
      on: {
        "click": function click() {
          return _vm.selectRole(value);
        }
      }
    }, [_c('div', {
      staticClass: "role-option__box",
      "class": {
        'role-option__box--active': _vm.role == value
      }
    }, [_c('div', {
      staticClass: "role-option__text"
    }, [_c('h3', [_c('span', {
      staticClass: "radio"
    }), _vm._v(_vm._s(title))]), !_vm.role ? _c('p', {
      domProps: {
        "innerHTML": _vm._s(description)
      }
    }) : _vm._e()])])]);
  }), 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.role,
      expression: "role"
    }]
  }, [_c('form-error-field', {
    attrs: {
      "name": "email"
    }
  }, [_c('label', {
    attrs: {
      "for": "email"
    }
  }, [_vm._v("Your Email Address")]), _c('b-form-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'email|required',
      expression: "'email|required'"
    }],
    ref: "email",
    attrs: {
      "id": "email",
      "type": "text",
      "name": "email",
      "placeholder": "Email Address",
      "data-registration": "email"
    },
    model: {
      value: _vm.email,
      callback: function callback($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  })], 1), _c('div', {
    staticClass: "form-group"
  }, [_c('phone-input', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "name": "phone",
      "data-registration": "phone"
    },
    model: {
      value: _vm.phone,
      callback: function callback($$v) {
        _vm.phone = $$v;
      },
      expression: "phone"
    }
  })], 1)], 1), _c('notification'), _c('b-row', {
    staticClass: "justify-content-md-center"
  }, [_c('b-col', {
    staticClass: "text-center",
    attrs: {
      "md": "12"
    }
  }, [_c('div', [_vm._v("Confirmation link will be sent to your email")]), _c('Transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.role == 'business_unverified' ? _c('div', {
    staticClass: "alert alert-dark mt-4"
  }, [_vm._v(" Business account can be opened only by business director (CEO) or person who has power of attorney."), _c('br'), _vm._v(" Your company must have registration documents and working website. ")]) : _vm._e()])], 1)], 1), _c('b-row', {
    staticClass: "justify-content-center pt-4"
  }, [_c('b-col', {
    staticClass: "text-center",
    attrs: {
      "md": "8"
    }
  }, [_c('input', {
    staticClass: "btn btn-primary btn-block",
    attrs: {
      "disabled": _vm.loading,
      "type": "submit",
      "value": "Next",
      "data-registration": "next"
    }
  })])], 1)], 1), _c('b-row', {
    staticClass: "justify-content-md-center"
  }, [_c('b-col', {
    staticClass: "pt-4 text-center",
    attrs: {
      "md": "6"
    }
  }, [_vm._v(" Go back to "), _c('span', [_c('router-link', {
    attrs: {
      "to": {
        name: 'login'
      },
      "exact": ""
    }
  }, [_vm._v("Login")]), _vm.WHITELABEL == 'supersonicz' ? _c('span', [_vm._v(" " + _vm._s(" / ")), _c('a', {
    attrs: {
      "href": "https://www.wisebanq.com"
    }
  }, [_vm._v(" Home")])]) : _vm._e()], 1)])], 1), _c('vue-recaptcha', {
    ref: "recaptcha",
    attrs: {
      "sitekey": _vm.recapthaSitekey,
      "load-recaptcha-script": true,
      "size": "invisible"
    },
    on: {
      "verify": _vm.onVerify
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }