<template>
	<div>
		<b-row class="search-block mb-0"
			align-v="end"
		>
			<div class="col-md-12 d-flex mb-4">
				<date-range-select
					:date-range="dateRange"
					side="right"
					style="width: 100%;"
					@dateRangeChanged="dateRangeChanged"
				/>
			</div>
			<b-col md="12"
				class="mb-4"
			>
				<b-row align-v="end"
					align-h="between"
				>
					<b-col cols="auto"
						class="radio"
					>
						<div class="d-inline-block ml-3">
							<input 
								id="radio-statement-pdf" 
								v-model="type" 
								name="type" 
								type="radio" 
								value="pdf"
							>
							<label 
								for="radio-statement-pdf" 
								class="radio-label"
							>PDF</label>
						</div>
						<div class="d-inline-block ml-3">
							<input 
								id="radio-statement-xls" 
								v-model="type" 
								name="type" 
								type="radio" 
								value="xlsx"
							>
							<label 
								for="radio-statement-xls" 
								class="radio-label"
							>XLSX</label>
						</div>
						<div class="d-inline-block ml-3">
							<input
								id="radio-statement-csv"
								v-model="type"
								name="type"
								type="radio"
								value="csv"
							>
							<label
								for="radio-statement-csv"
								class="radio-label"
							>CSV</label>
						</div>
						<div
							v-if="isGranted('front_transaction_fee_statement_list_create')"
							class="d-inline-block ml-3"
						>
							<input
								id="radio-statement-fee"
								v-model="type"
								name="type"
								type="radio"
								value="fee"
							>
							<label
								for="radio-statement-fee"
								class="radio-label"
							>Fee</label>
						</div>
					</b-col>
					<b-col cols="auto">
						<button 
							class="btn btn-primary px-5 ml-3"
							:disabled="!dateRange.startDate || !dateRange.endDate || !currentWallet"
							@click="create"
						>
							Create
						</button>
					</b-col>
				</b-row>
			</b-col>
		</b-row>
		<b-row class="mb-4 statement-list">
			<b-col>
				<b-list-group>
					<b-list-group-item
						v-if="listLoading"
					>
						<b-row>
							<b-col class="text-center">
								<b-spinner variant="primary" />
							</b-col>
						</b-row>
					</b-list-group-item>

					<b-list-group-item v-for="item in statementList" 
						:key="item.id"
					>
						<b-row align-h="between"
							align-v="center"
						>
							<b-col cols="auto">
								<small class="statement-label">{{ item.fileName ? 'File name:' : 'File created:' }}</small>
								<div>{{ item.fileName && item.extension ? `${item.fileName}.${item.extension}` : moment(item.dateCreated).format('YYYY-MM-DD HH:mm') }}</div>
							</b-col>
							<b-col cols="auto">
								<b-button variant="primary"
									size="sm"
									:disabled="item.status !== 'completed'"
									@click="download(item)"
								>
									<div 
										class="px-4"
									>
										<b-spinner v-if="item.status !== 'completed'"
											small
										/>
									</div>
									<span v-if="item.status === 'completed'">Download</span>
								</b-button>
							</b-col>
						</b-row>
					</b-list-group-item>
				</b-list-group>
			</b-col>
		</b-row>
		<b-row>
			<b-col md="12"
				class="d-flex"
			>
				<b-button 
					variant="info" 
					@click="$bvModal.hide('export-statements-modal')"
				>
					Close
				</b-button>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DateRangeSelect from '@/utils/DateRangeSelect';
import { endpoints } from '~/config';
import moment from 'moment';

export default {
	name: 'StatementsTable',
	components: {
		DateRangeSelect
	},
	data() {
		return {
			dateRange: {
				startDate: '',
				endDate: ''
			},
			type: 'pdf',
			statementList: [],
			listLoading: false,
			moment,
			statusChecker: null
		};
	},
	computed: {
		...mapGetters('wallet', [
			'currentWallet'
		]),
		...mapGetters('user', [
			'isGranted'
		]),
	},
	methods: {
		...mapActions('notifications', ['pushError', 'pushSuccess']),
		dateRangeChanged: function(dateRange) {
			this.dateRange = { ...dateRange };
		},
		async create() {
			const dateFrom = moment(this.dateRange.startDate).format('YYYY-MM-DD');
			const dateTo = moment(this.dateRange.endDate).format('YYYY-MM-DD');
			let statementUrl = endpoints.transactions.statementCreate({ walletId: this.currentWallet.walletId });

			if (this.type === 'fee') {
				statementUrl = endpoints.transactions.statementFeeCreate({ walletId: this.currentWallet.walletId });
			}

			const options = {
				method: 'GET',
				url: statementUrl,
				params: {
					dateFrom,
					dateTo,
					type: this.type,
					orderBy: 'timestampCreated',
					page: 1,
					limit: 100,
					orderDirection: 'asc'
				}
			};

			try {
				await axios(options);
				// await this.getStatementInfo(data.id);
				this.pushSuccess('Statement file created successfully.');
				this.listLoading = true;
				await this.checkForStatusUpdates(true);
			} catch (err) {
				this.pushError(err);
			}
			this.listLoading = false;

		},
		async getStatementInfo(statementId) {
			const options = {
				method: 'GET',
				url: endpoints.transactions.statementInfo({ statementId })
			};
			try {
				const { data } = await axios(options);
				return data;
			} catch (err) {
				this.pushError(err);
			}
		},
		async getStatementPage() {
			const options = {
				method: 'GET',
				url: endpoints.transactions.statementPage({ walletId: this.currentWallet.walletId }),
				params: {
					orderBy: 'id',
					page: 1,
					limit: 100,
					orderDirection: 'desc'

				}
			};
			try {
				const { data } = await axios(options);
				this.statementList = data.rows;
			} catch (err) {
				this.pushError(err);
			}
		},
		async download(item) {
			const statementId = item.id;
			const dateCreated = moment(item.dateCreated).format('YYYYMMDD-HHmm');
			const options = {
				method: 'GET',
				url: endpoints.transactions.statementMulti({ statementId }),
				responseType: 'arraybuffer'
			};
			try {
				const { data } = await axios(options);
				const fileUrl = window.URL.createObjectURL(new Blob([data], { type: `application/${this.type}` }));
				const fileLink = document.createElement('a');
				fileLink.href = fileUrl;
				fileLink.setAttribute('download', item.fileName && item.extension ? `${item.fileName}.${item.extension}` : `statement-${dateCreated}.${this.type}`);
				document.body.appendChild(fileLink);
				fileLink.click();
			} catch (err) {
				this.pushError(err.message);
			}
		},
		async checkForStatusUpdates(force = false) {
			if (this.statementList?.length == 0 || force) await this.getStatementPage();
			if (this.statementList.filter(item => item.status !== 'completed').length > 0) {
				this.statusChecker = setTimeout(async () => {
					await this.getStatementPage();
					this.checkForStatusUpdates();
				}, 5000);
			}
		}
	},
	async mounted() {
		this.listLoading = true;
		await this.checkForStatusUpdates();
		this.listLoading = false;
	},
	destroyed() {
		clearTimeout(this.statusChecker);
	}
};
</script>

<style lang="scss" scoped>
	@import './Statements.scss';
	
	//@todo move to global style

	$color1: white;
	$color2: darkgrey;

	.radio {
		input[type="radio"] {
			position: absolute;
			opacity: 0;

			+ .radio-label {
				&:before {
					content: '';
					background: $color1;
					border-radius: 100%;
					border: 1px solid darken($color1, 25%);
					display: inline-block;
					width: 18px;
					height: 18px;
					position: relative;

					margin-right: 1em;
					vertical-align: top;
					cursor: pointer;
					text-align: center;
					transition: all 250ms ease;
				}
			}

			&:checked {
				+ .radio-label {
					&:before {
						background-color: $color2;
						box-shadow: inset 0 0 0 4px $color1;
					}
				}
			}

			&:focus {
				+ .radio-label {
					&:before {
						outline: none;
						border-color: $color2;
					}
				}
			}

			&:disabled {
				+ .radio-label {
					&:before {
						box-shadow: inset 0 0 0 4px $color1;
						border-color: darken($color1, 25%);
						background: darken($color1, 25%);
					}
				}
			}

			+ .radio-label {
				&:empty {
					&:before {
						margin-right: 0;
					}
				}
			}
		}
	}
</style>


