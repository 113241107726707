export const cookie = {
	get,
	set,
	unset,
	exists
};

function formatDomain(domain) {
	const arr = domain.split('.');
	return `.${arr.slice(arr.length - 2, arr.length).join('.')}`;
}

function get(name) {
	const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
	return v ? v[2] : null;
}

function set(name, value, days, domain) {
	const d = new Date;
	d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
	let cookieValue = name + '=' + value + ';path=/;expires=' + d.toGMTString();
	if (domain) cookieValue += `;domain=${formatDomain(domain)}`;
	document.cookie = cookieValue;
}

function exists(name) {
	return !!get(name);
}

function unset(name, domain) { set(name, '', -1, domain); }